import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgservicesbanner2Ar.jpg";
import assetimg from "../../assets/images/pages/services/portfolio.jpg";
import alternative from "../../assets/images/pages/services/alternative.jpg";
import pooled from "../../assets/images/pages/services/pooled.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const { Title } = Typography;

const AssetManagementAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Asset Management Solutions in Dubai - Noor Capital"
        description="If you need assistance with your investments, then delegate your portfolio management to our professionals."
      />

      <BgBeforeAr
        pageHeadingTitle="خدمة إدارة الأصول في نور كابيتال - حل استثماري شامل"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الخدمات"
        pageSubCategoryPath="#"
        pageName="إدارة الأصول"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            إدارة الأصول
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              توفر نور كابيتال لعملائها حلاً شامًلا لإدارة الأصول يتضمن مجموعة
              واسعة من استراتيجيات الاستثمار والاستشارات عبر جميع فئات الأصول
              الرئيسية. ويشمل ذلك الأسهم العالمية والدخل الثابت وأسواق المال
              والعملة ومنتجات الاستثمار البديلة (أدوات الاستثمار ذات الأهداف
              والاستراتيجيات الاستثمارية غير التقليدية).
            </p>

            <p className="parjustify">
              نحن نقدم حلًا سلسًا لإدارة الأصول للمؤسسات والأفراد ذوي الثروة
              المالية الكبيرة والمستثمرين الأفراد من خلال قنوات مباشرة وأطراف
              أخرى، حيث يشمل المؤسسات التي تستهدف صناديق الثروة السيادية (SWFs)،
              والشركات، وشركات التأمين، والمؤسسات، والأوقاف المالية.
            </p>
          </Col>
        </Row>

        <br />

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <Divider orientation="left">
              <Title level={3} style={{ color: "#1b75bc" }}>
                المنتجات
              </Title>
            </Divider>

            <div className="row text-right mt-5">
              <div className="col-12 col-sm-8 col-md-4 col-lg-3 m-sm-auto mr-md-auto ml-md-0">
                <img
                  alt="Pooled Investment Products"
                  className="img-fluid rounded"
                  src={pooled}
                  style={{ marginBottom: "18px" }}
                />
                <br />
                <Title style={{ color: "#1b75bc" }} level={4}>
                  منتجات الاستثمار المشتركة
                </Title>
                <ul>
                  <li className="list">صناديق الاستثمار</li>
                  <li className="list">صناديق الاستثمار العقاري (REITs)</li>
                  <li className="list">خطط الاستثمار</li>
                </ul>
              </div>
              <div className="col-12 col-sm-8 col-md-4 col-lg-3 m-sm-auto pt-5 pt-md-0">
                <img
                  alt="Alternative Investment Solutions"
                  className="img-fluid rounded"
                  src={alternative}
                  style={{ marginBottom: "18px" }}
                />
                <br />
                <Title style={{ color: "#1b75bc" }} level={4}>
                  حلول الاستثمار البديلة
                </Title>
                <ul>
                  <li className="list">المنتجات المهيكلة</li>
                  <li className="list">الملكية الخاصة</li>
                </ul>
              </div>
              <div className="col-12 col-sm-8 col-md-4 col-lg-3 m-sm-auto ml-md-auto mr-md-0 pt-5 pt-md-0">
                <img
                  alt="Segregated Portfolios"
                  className="img-fluid rounded"
                  src={assetimg}
                  style={{ marginBottom: "18px" }}
                />
                <br />
                <Title style={{ color: "#1b75bc" }} level={4}>
                  محافظ منفصلة
                </Title>
                <ul>
                  <li className="list">المحافظ المدارة</li>
                  <li className="list">استشارة استثمارية غير تقديرية</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }

            .list {
              list-style-type: disc !important;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                نوفر لك تجربة لا مثيل لها
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/ar/about/contact-us"
                >
                  اتصل بنا
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block">
        <div className="row">
          <div className={globalstyle.container}>
            <div className="col" style={{ padding: "15px" }}>
              <h1
                className="display-5 text-center"
                style={{ color: "#11131b" }}
              >
                خدمات أخرى
              </h1>
            </div>

            <div className="row text-right">
              <div className="col-12 col-md-8 m-auto col-lg-4">
                <div className="fdb-box fdb-touch">
                  <h4>الإستشارات المالية العالمية</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    مع تركيزنا على سوق الإمارات العربية المتحدة والمملكة العربية
                    السعودية، نجحنا في توسيع أجنحتنا الاستشارية لتشمل مناطق أخرى
                    في الشرق الأوسط وشمال
                  </p>
                  <p>
                    <Link to="/ar/services/global-financial-advisory">
                      قراءة المزيد
                    </Link>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>الاستثمار المباشر</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    نقدم مجموعة واسعة من الفرص المالية، بما في ذلك الاستثمارات
                    وإدارة الأصول والاستشارات المالية وإدارة الصناديق والوساطة
                    في الأسواق العالمية
                  </p>
                  <p>
                    <Link to="/ar/services/investment">قراءة المزيد</Link>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>إدارة صناديق الإستثمار</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      توفر لك نور كابيتال حلاً قويًا وسلسًا لإدارة صناديق
                      الاستثمار، خاصةً لشركة إدارة الأصول وصناديق الاستثمار. نحن
                      نقدم حلاً جاهزًا لحساب صافي قيمة الأصول
                    </p>
                  <p>
                    <Link to="/ar/services/funds-administration">
                      قراءة المزيد
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssetManagementAr;
