import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtradingbanner3.jpg";
import precious_metals from "../../assets/images/pages/instruments/precious-metals.jpg";
import Products from "./products.js";
import Instruments_Bullions_Table from "./trading_Instruments_Bullions_table";

const style = { padding: "50px 0" };
const { Title } = Typography;

const Instruments_PreciousMetals = () => {
  return (
    <div>
      <PageseMetaTags
        title="Precious Metals Trading - Start Trading Gold & Silver"
        description="Noor Capital allows trading the spot price for metals including Gold or Silver against the US Dollar or Euro and the metals Platinum or Palladium against the US Dollar."
      />

      <BgBefore
        pageHeadingTitle="Your Investment Is As Valuable As Precious Metals - Invest Now"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Trading"
        pageMainCategoryPath="#"
        pageSubCategoryName="Instruments"
        pageSubCategoryPath="#"
        pageName="Precious Metals"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Precious Metals
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Precious metals are as important for traders as they are for investors and jewelers. So what makes them the  center of attention for traders? The concept of using precious metals as a potential means of exchanging for trade and holding as a storehouse of wealth dates back to history. But today they are more popularly used as a hedge against inflation or an asset to secure a place in the business during times of financial uncertainty. Noor Capital brings you the best possible ways of trading in precious metals futures and options to make  profits.
            </p>

            <p className="parjustify">
            At Noor Capital, we aim at acting as a buffer between abrupt price movements and your assets. Therefore, we ensure smooth future contracts. These future contracts are far better than trading commodities themselves because they offer more financial leverage, flexibility, and financial integrity than trading commodities . We provide you with online webinars, market updates, technical analysis, and everything you need for proper trading and investment.
            </p>

            <p className="parjustify">
            Gold, platinum, and silver are among the top traded precious metals. Even commodity metals such as copper can also be traded, but they are less common. The price of gold depends on supply and demand, political conditions, and industrial use  and output. We value your assets so we have come up with secure precious metal trading options for you.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={precious_metals}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>


        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Specifications list
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Instruments_Bullions_Table />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              Start Trading Precious Metals Now 
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Sign-Up
                  </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Products />
    </div>
  );
};

export default Instruments_PreciousMetals;
