import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgnoortrader.jpg";
import { Link } from "react-router-dom";
import ntplatformmobile from "../../assets/images/pages/trading/ntplatformmobile.png";
import WhyNoor from "./whyNoor";


const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader_NoorTraderMobile = () => {
  return( <div>
          <PageseMetaTags
                title="NoorTrader Mobile App - Noor Capital"
                description="Buying and selling bullion has never been so easy and innovative."
            />
            <BgBefore 
            pageHeadingTitle="Smart Bullion Trading For Smart Investors"
            pageHeadingDescription="Start Your Bullion Trading Journey On The Go "
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="NoorTrader Mobile App"
      />



<div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Download NoorTrader App Now, And Start Trading in Physical Metals On The Go
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Buying and selling bullion has never been so easy and innovative. NoorTrader mobile app is a perfect combination of seamless technology, unique features, and a user-friendly interface.
            </p>
            <p className="parjustify">
            Now you can buy, sell, and manage bullion on the go with NoorTrader mobile app. Available on Google Playstore for Android and AppStore for iPhones, it will change the way you trade, making your trading easy to manage and secure.
            </p>
            
            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-android"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>Android</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://play.google.com/store/apps/details?id=com.noorindex.noortraderv1&hl=en&gl=US"
                    target="_blank"
                  >
                    Download
                  </a>
                </p>
              </div>
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 ml-sm-auto mr-sm-auto mt-5 mt-md-0">
                <i
                  class="fab fa-apple"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>IPhone</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://apps.apple.com/ae/app/noortrader/id1388986392"
                    target="_blank"
                  >
                    Download
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                Why Do You Need To Download NoorTrader Mobile App - Right Away?
                </Title>
                
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get instant access to the global bullion market.

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Start buying, selling, and managing your Bullion with just a click.

                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                 24/7 access to your investment.

                </p>
                <br />

               
                <br />
               

              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Compatible with all Android and Iphone.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Providing Storage & Delivery.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Available for download on Playstore and AppStore.
                </p>
                <br />


                <br />
                

              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="ntplatformmobile" className="img-fluid" src={ntplatformmobile} />
              </div>
            </div>
          </div>
        </section>
      </div>


      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Start Bullion Trading On The Go</Title>

              <p className="mt-5 mt-sm-4">
              <Link
                  className="btn btn-outline-primary"
                  to="/physical-trading-platforms/open-noor-trader-live"
                  
                >
                  Register Live Account
                </Link>

              </p>
            </div>
          </div>
        </div>
      </section>

  </div>
  )

};

export default NoorTrader_NoorTraderMobile;