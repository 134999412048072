import React from "react";
import { Row, Col } from 'antd';

import globalstyle from '../../style.module.css';

import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const FraudWarning = () => {
  return(
    
    <div>
          <PageseMetaTags
                title="Fraud Warning - Noor Capital"
                description="We encourage our customers to validate the identity of any institution or individual claiming to represent Noor Capital."
            />

            <BgBeforeAr
            pageHeadingTitle="تحذير من الإحتيال"
            pageHeadingDescription="احمِ نفسك وبياناتك من الأنشطة الاحتيالية."
            pageHeadingbg={img1}
            />
        <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="تحذير من الإحتيال"
      />

    
    <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>احمِ نفسك وبياناتك من الأنشطة الاحتيالية.</h4>
              <p className={globalstyle.parjustify}>
              أصبح الإنترنت من حياتنا اليومية ، يمكننا أن نشهد الاحتيال عبر الإنترنت ، خاصةً بالبيانات والمال. ستساعد الصفحة التالية عملائنا على حماية أنفسهم من الأنشطة الاحتيالية من قبل المؤسسات أو الأفراد الذين يدعون أنهم ممثلو نور كابيتال.
              </p>
              <p className={globalstyle.parjustify}>
              يمارس موظفو نور كابيتال أعمالهم من خلال خدمات البريد الإلكتروني المدارة من نور كابيتال تحت نفس المجال. لا نستخدم منصات التواصل الاجتماعي الشخصية أو رسائل البريد الإلكتروني الشخصية للاتصال بالعملاء أو إجراء الأعمال. لا يتواصل ممثل نور كابيتال مع العملاء عبر وسائل التواصل الاجتماعي الخاصة أو رسائل البريد الإلكتروني الخاصة ، ويطلب منهم الكشف عن بياناتهم أو تفاصيل حساباتهم.
              </p>

              <p className={globalstyle.parjustify}>
              نشجع عملائنا على التحقق من هوية أي مؤسسة أو فرد يدعي تمثيل نور كابيتال. يجب عليك المتابعة بحذر ، خاصة أثناء تحويل الأموال أو الكشف عن التفاصيل الشخصية. إذا شعرت أن المؤسسة أو الفرد الذي يقدم منتجات مالية مختلفة متورط في عملية احتيال ، فيجب عليك تنبيه السلطة المحلية على الفور.
              </p>

              <p className={globalstyle.parjustify}>
              احذر من المحتال باستخدام اسم نور كابيتال ، أو استنساخ الموقع الإلكتروني ، وادعي أنك موظف ، واطلب تفاصيل شخصية ، أو تفاصيل الحساب ، أو تحويل الأموال.
              </p>
              
              <p className={globalstyle.parjustify}>
              نور كابيتال ملتزمة بحماية بيانات عملائنا وأموالهم. على الرغم من أننا لا نتحمل أي مسؤولية عن وقوع الفرد في مخططات الاحتيال ، فإننا نتخذ كل خطوة ممكنة لتقليل الاحتيال.
              </p>

              <p className={globalstyle.parjustify}>
              تنبيه: إذا كنت تشك في تعرض بياناتك أو أموالك للخطر بسبب عمليات الاحتيال ، فيرجى الاتصال بنور كابيتال على الفور أو الاتصال بأقرب مركز شرطة
              </p>
              
            
              
            </div>
          </div>

      
        </section>






    </div>

  </div>
  )

};

export default FraudWarning;