import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgpartnershipbanner4Ar.jpg";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
 import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import WhyNoorAr from "./whyNoorAr";

const style = { padding: "50px 0" };

const notes = [
  "الحصول على مكافأة فريدة مقابل كل عميل يُوقع معنا.",
  "ستوفر لك نور كابيتال دعمًا كامًلا لبدء أعمالك وتوسيعها.",
  "الوصول إلى حلولنا الاستثمارية القوية.",
  "البرنامج سريع ويسهل الانضمام إليه.",
  "سيتم تحويل العمولة المكتسبة مباشرة إلى حساب برنامج الشراكة التسويقية الخاص بك.",
  "مدير مخصص لمساعدتك في أي معلومات تتعلق بمنتجاتنا.",
];
const { Title } = Typography;

const IBProgram_IbAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Marketing Partnership Program - Forex Trading | Noor Capital"
        description="Noor Capital Marketing Partnership Program is designed to remunerate introducers with no limits on the number of referred accounts or trading volumes"
      />

      <BgBeforeAr
        pageHeadingTitle="استغل الفرص التي لا نهاية لها - انضم إلى برنامج الشراكة التسويقية نور كابيتال "
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName = "الرئيسية"
        pageMainCategoryPath = "/"
        
        pageSubCategoryName = "الشراكة"
        pageSubCategoryPath = "#"

        pageName = "برنامج الشراكة التسويقية"
        />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            برنامج الشراكة التسويقية
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            تؤمن نور كابيتال بضرورة زيادة أرباح الشركات الذين يعملون معنا. ومراعاة لهذا الهدف، نشجعك على الانضمام إلى برنامج الشراكة التسويقية لدينا، حيث تحصل على مكافأة عندما تجذب عملاء جدد إلى منصة تداول الفوركس الخاصة بنا.
            </p>

            <p className="parjustify text-right">
            من خلال برنامج الشراكة التسويقية، ستتلقى دعمًا كامًلا من خلالنا يمكنك من البدء وتوسيع أعمالك والوصول إلى منتجاتنا الحائزة على جوائز وكذلك حلول الاستثمار التي يمكنك تقديمها لعملائك.
            </p>

            <p className="parjustify text-right">
            يُعد برنامج الشراكة التسويقية من نور كابيتال هو العملية الأكثر شفافية التي تعمل مباشرة دون الحاجة إلى الاستثمار المسبق حيث يستفيد المئات من الشركاء من هذا البرنامج المربح ويوسعون إمكانياتهم في جني الأرباح.
            </p>
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          المميزات والفوائد 
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              تقدم بطلب للحصول على برنامج نور كابيتال للوسطاء المعرفين
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/ib-signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoorAr />
    </div>
  );
};

export default IBProgram_IbAr;
