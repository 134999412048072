import React from "react";
import gtnvideo from "../../assets/images/pages/trading/gtn-mobileAr.mp4"


const GtnVideoAr = () => {
    return (
        <>
        
<video  id="video" className="fx-lazy loaded" style={{"height":"550px"}} autoPlay loop muted>
      <source src={gtnvideo} type="video/mp4"/>
  Your browser does not support the video tag.
</video>
<style jsx>
        {`
          @media all and (max-width: 600px) {
            #video {display: none;} 
        }
        `}
      </style>

            
</>
    );
};

export default GtnVideoAr;
