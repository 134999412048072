import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgtradingbanner3.jpg";
import forex from "../../assets/images/pages/instruments/forex.jpg";
import ProductsAr from "./productsAr.js";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import Instruments_Fx_Table from "./trading_Instruments_FX_table";

const { Title } = Typography;

const Instruments_ForexAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Online Forex Trading - Noor Capital"
        description="FX stands for Foreign Exchange. The Foreign Exchange Market is the biggest financial market in the world; with a daily turnover of over $5 trillion dollars."
      />
      <BgBeforeAr
        pageHeadingTitle="اقفز نحو الحرية المالية مع نور كابيتال لتداول الفوركس"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="التداول"
        pageMainCategoryPath="#"
        pageSubCategoryName="الأسواق"
        pageSubCategoryPath="#"
        pageName="العملات"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          تجارة الفوركس عبر الإنترنت
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify  text-right">
            العملات الأجنبية، المعروفة باسم الفوركس Forex- FX، هي أدوات التداول الأكثر شيوعًا والمستخدمة على نطاق واسع في جميع أنحاء العالم مع أكثر من 5 تريليون دولار من التداول اليومي.{" "}
            </p>

            <p className="parjustify text-right">
            يُعد الفوركس في دبي هو أكثر أشكال التداول ربحية، حيث تشتري وتبيع عملات متعددة في نفس الوقت من خلال وسيط أو متداول. مع تداول الفوركس، لا تشتري أو تبيع ماديًا، ولكن رقميًا بسعر صرف عملة معينة مقابل العملات الأخرى وهذا يعكس حالة الاقتصاد في البلد المحدد مقارنة باقتصاديات البلدان الأخرى.
            </p>

            <p className="parjustify text-right">
            عادة، يتم تداول العملات في شكل أزواج: اليورو مقابل الدولار الأمريكي، الدولار الأمريكي مقابل الين الياباني، الدولار الأمريكي مقابل الدولار الكندي، والمزيد. ويكون تداول العملات الأجنبية في سوق خارج البورصة (OTC)، مباشرة بين طرفين مُحددين.
            </p>

            <p className="parjustify text-right">
            سوق الفوركس متاح على مدار 24 ساعة يوميًا، 5 أيام في الأسبوع من الاثنين إلى الجمعة، حيث يعمل بنظام السوق بين البنوك وتتم عمليات التداول إلكترونيًا مما يسهل جميع العمليات.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={forex}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Online Forex Trading"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          قائمة المواصفات
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Instruments_Fx_Table />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              ابدأ حساب تداول الفوركس الخاص بك الآن
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ProductsAr />
    </div>
  );
};

export default Instruments_ForexAr;
