import React from "react";
import { Layout, Row, Col, Divider, Typography } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import OpenGTNLiveFormAr from "../forms/openGTNLiveFormAr";
import img1 from "../../assets/images/bggtnAr.jpg";

import mt4desktop from "../../assets/images/pages/trading/gtn.png";
import OpenFxDemoForm from "../forms/openFxDemoForm.js";
import GtnVideo from "./GtnVideo.js";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import WhyNoorAr from "./whyNoorAr";

const { Title } = Typography;

const GTN_OpenLiveAccountAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Open Live Account with Noor Capital Stocks Trading Platform (GTN)"
        description="A robust trading app that supports our active traders' daily activities, keeping them ahead of the game."
      />
      <BgBeforeAr
        pageHeadingTitle="طًور تجربتك في التداول وانتقل إلى المستوى التالي من خلال منصة الأكثر تقدمًا وقوة في العالم."
        pageHeadingDescription="انضم إلى منصة نور كابيتال لتداول الأسهم (GTN) وتحكم في تعاملاتك الآن."
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="حساب حقيقي لتداول الأسهم"
      />

      <div className={globalstyle.container} id="main">
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          سجل الآن لفتح  على حساب حقيقي
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            غيّر طريقة تداولك وتحكم في أسهمك . انضم إلى منصة نور كابيتال لتداول الأسهم (GTN)، المنصة الأكثر تقدمًا وقوة التي صُممت بتفاصيل دقيقة تمنحك تجربة تداول سهلة وسريعة.
            </p>
            <p className="parjustify">
            الآن يمكنك التداول من أي مكان في العالم بضغطة من اصبعك عبر منصتنا سهلة الاستخدام والتي يمكن الوصول إليها من أي مكان لتتمكن من الاطلاع على أحدث المستجدات، والاتجاهات، والأخبار، والتنبيهات، والتوصيات لتكون دائما في المقدمة أثناء التداول.
            </p>

            <p className="parjustify">
            سواء كنت متداولًا مبتدأ أو محترفًا، سوف تجد ما تبحث عنه في منصتنا، مما يجعلها واحد من أكثر المنصات تفضيلا للتداول في الأسهم.. منصتنا مؤمنة بالكامل ومتاحة على جميع الوسائط من الكمبيوتر إلى الهاتف الجوال .
            </p>

            <OpenGTNLiveFormAr />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <GtnVideo />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                التسجيل على منصتنا يمكنك من
                </Title>

                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  بدء التداول على الفور.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  شراء وبيع وإلغاء وتعديل الأوامر بضغطة واحدة.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الدخول إلى  أسواق دول مجلس التعاون الخليجي وأوروبا والولايات المتحدة.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تداول في أسهم الشركات الكبرى عالميا(مثل فيس بوك Facebook، ومايكروسوفت Microsoft وتيسلا Tesla وغيرها من الشركات العالمية).
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تجربة منصة التداول الأكثر أمانًا وسهولة في الاستخدام حول العالم.
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الحصول على أكثر من 60 ألف سهم.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تلقي البيانات والمخططات والتقارير والإعلانات في الوقت الفعلي.  
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  اختيار لغتك المفضلة (انجليزي / عربي).
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  إضافة وتتبع الأسهم المفضلة لديك في قائمة المراقبة الذكية.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  إدارة رصيد حسابك وحدود الشراء مع ملخص الحساب.
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4desktop" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
            <div
              style={{
                paddingTop: "35px",
                textAlign: "center",
                fontSize: "14.5px",
              }}
            ></div>
          </div>
        </section>
      </div>

      <br />

      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              ابدأ رحلتك الاستثمارية كمتداول على شبكة الإنترنت مع منصة نور كابيتال لتداول الأسهم  (GTN) – الآن
              </Title>

              <p className="mt-5 mt-sm-4">
                <a className="btn btn-outline-primary" href="#main">
                سجل الآن للحصول على حساب متداول 
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GTN_OpenLiveAccountAr;
