import React from "react";
import { Table } from "antd";

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text) => <a>{text}</a>,
    
  },
  {
    title: "Location/ Market",
    dataIndex: "location_market",
    key: "location_market",
    responsive: ["md"],

    filters: [
      {
        text: 'EU',
        value: 'EU',
      },
      {
        text: 'US',
        value: 'US',
      }
        ],

    // onFilter: (value, record) => record.name.indexOf(value) === 0,
    // sorter: (a, b) => a.name.length - b.name.length,
    // sortDirections: ['descend'],
    
  },
  {
    title: "NY4 Time – System Time",
    dataIndex: "system_time",
    key: "system_time",
    responsive: ["md"],
  },
  {
    title: "Margin %",
    dataIndex: "margin",
    key: "margin",
    
  },
  {
    title: "3 Day Fees",
    dataIndex: "three_day_fees",
    key: "three_day_fees",
    responsive: ["md"],
  },
  {
    title: "Max Volume( Lots )",
    dataIndex: "max_volume",
    key: "max_volume",
    responsive: ["md"],
  },
  {
    title: "Storage Fees",
    dataIndex: "management_fees",
    key: "management_fees",
    
  },
];

const data = [
  {
    key: "1",

    description: "Adidas AG",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "2",

    description: "Allianz SE",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "3",

    description: "Volkswagen AG",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "4",

    description: "Siemens AG",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "5",

    description: "Bayer AG",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "6",

    description: "Daimler AG",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "7",

    description: "Vivendi SA",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "8",

    description: "Peugeot SA",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "9",

    description: "Infineon Technologies AG",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "10",

    description: "Deutsche Telekom AG",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "11",

    description: "Orange SA",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "12",

    description: "Commerzbank AG",

    location_market: "EU",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "13",

    description: "AstraZeneca PLC",

    location_market: "UK",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 75,
  },

  {
    key: "14",

    description: "Easyjet PLC",

    location_market: "UK",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 15,
  },

  {
    key: "15",

    description: "Rolls-Royce Holdings PLC",

    location_market: "UK",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 15,
  },

  {
    key: "16",

    description: "BP PLC",

    location_market: "UK",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 15,
  },

  {
    key: "17",

    description: "Tesco PLC",

    location_market: "UK",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "18",

    description: "Vodafone Group PLC",

    location_market: "",

    system_time: "3:00-11:30",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "19",

    description: "Amazon.com Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 2.5,
  },

  {
    key: "20",

    description: "Google",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 2.5,
  },

  {
    key: "21",

    description: "Boeing Co",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 15,
  },

  {
    key: "22",

    description: "Netflix Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 15,
  },

  {
    key: "23",

    description: "Lockheed Martin Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 15,
  },

  {
    key: "24",

    description: "Tesla Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 15,
  },

  {
    key: "25",

    description: "Mcdonald’s Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "26",

    description: "Goldman Sachs Group Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "27",

    description: "Apple Inc.",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "28",

    description: "Visa Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "29",

    description: "Alibaba Group Holding Ltd",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "30",

    description: "NVIDIA Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 3,
  },

  {
    key: "31",

    description: "International Business Machines Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "32",

    description: "Marriott International Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "33",

    description: "Microsoft Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "34",

    description: "Wynn Resorts Ltd",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "35",

    description: "PepsiCo Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "36",

    description: "Johnson & Johnson",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "37",

    description: "Procter & Gamble Co",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "38",

    description: "Walmart Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "39",

    description: "Baidu Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "40",

    description: "Nike Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "41",

    description: "Qualcomm Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "42",

    description: "Exxon Mobil Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "43",

    description: "Best Buy Co Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "44",

    description: "Citigroup Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "45",

    description: "Oracle Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "46",

    description: "Cisco Systems Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "47",

    description: "Mondelez International Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "48",

    description: "Coca-Cola Co",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "49",

    description: "Micron Technology Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "50",

    description: "Pfizer Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "51",

    description: "SINA Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "52",

    description: "General Motors Co",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "53",

    description: "eBay Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "54",

    description: "Twitter Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "55",

    description: "AT&T Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "56",

    description: "JD.com Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "57",

    description: "Symantec Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "58",

    description: "Nuance Communications Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "59",

    description: "GoPro Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

  {
    key: "60",

    description: "Carnival Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 1,
  },

  {
    key: "61",

    description: "Walt Disney Co",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 5,
  },

  {
    key: "62",

    description: "Six Flags Entertainment Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 2,
  },

  {
    key: "63",

    description: "American Airlines Group Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 1,
  },

  {
    key: "64",

    description: "Spirit Airlines Incorporated",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 1,
  },

  {
    key: "65",

    description: "Electrameccanica Vehicles Corp",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 1,
  },

  {
    key: "66",

    description: "Riot Blockchain Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 1,
  },

  {
    key: "67",

    description: "Transocean LTD",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 1,
  },

  {
    key: "68",

    description: "Marathon Patent Group Inc",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 1,
  },

  {
    key: "69",

    description: "Wish",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 1,
  },

  {
    key: "70",

    description: "Rhood",

    location_market: "US",

    system_time: "9:30-16:00",

    margin: 10,

    three_day_fees: "Friday",

    max_volume: 10,

    management_fees: 9.5,
  },

];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const Instruments_SharesCfds_Table = () => {
  return (
    <div>
      <Table 
      columns={columns} 
      dataSource={data} 
      onChange={onChange}
      pagination={{ position: 'topLeft' }}
      />

      <style jsx>{`
        .ant-table-thead > tr > th {
          font-size: 15px;
        }
        .ant-table-thead > tr > td {
        }
      `}</style>
    </div>
  );
};

export default Instruments_SharesCfds_Table;
