import React from "react";
import globalstyle from "../../style.module.css";
import leadinginvestment from "../../assets/images/pages/whynoor/leading-investment.svg";
import license from "../../assets/images/pages/whynoor/license.svg";
import funding from "../../assets/images/pages/whynoor/funding.svg";
import execution from "../../assets/images/pages/whynoor/execution.svg";
import platform from "../../assets/images/pages/whynoor/platform.svg";
import instruments from "../../assets/images/pages/whynoor/instruments.svg";
import research from "../../assets/images/pages/whynoor/research.svg";
import support from "../../assets/images/pages/whynoor/support.svg";

const WhyNoor = () => {
  return (
    <div>
      {/* Feature 22 */}
      <section className="fdb-block" style={{ backgroundColor: "#fff" }}>
        <div className={globalstyle.container}>
          <div className="row text-left">
            <div className="col-12">
              <h3 style={{ color: "#1b75bc" }}>Why Noor Capital?</h3>
            </div>
          </div>

          <div className="row text-left mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <div
                    className="image-aspect-ratio-container"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                      width: "70px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="One of the leading investment and brokerage firm"
                      className="fdb-icon"
                      src={leadinginvestment}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                </div>

                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    One of the leading investment and brokerage firm
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <div
                    className="image-aspect-ratio-container"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                      width: "70px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Regulated by the Securities and Commodities Authority (SCA)"
                      className="fdb-icon"
                      src={license}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    Regulated by the Securities and Commodities Authority (SCA)
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <div
                    className="image-aspect-ratio-container"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                      width: "70px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Local deposits and withdrawals with reputable UAE banks"
                      className="fdb-icon"
                      src={funding}
                      width="70px"
                      style={{
                        marginBottom: "9px",
                        backgroundColor: "#fbfbfb",
                        padding: "13px",
                        border: "1px solid #d8d8d5",
                      }}
                    />
                  </div>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    Local deposits and withdrawals with reputable UAE banks
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <div
                    className="image-aspect-ratio-container"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                      width: "70px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Instant execution through tier-1 interbank liquidity"
                      className="fdb-icon"
                      src={execution}
                      width="70px"
                      style={{
                        marginBottom: "9px",
                        backgroundColor: "#fbfbfb",
                        padding: "13px",
                        border: "1px solid #d8d8d5",
                      }}
                    />
                  </div>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    Instant execution through tier-1 interbank liquidity
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-left mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <div
                    className="image-aspect-ratio-container"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                      width: "70px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Ultimate and advanced trading platforms"
                      className="fdb-icon"
                      src={platform}
                      width="70px"
                      style={{
                        marginBottom: "9px",
                        backgroundColor: "#fbfbfb",
                        padding: "13px",
                        border: "1px solid #d8d8d5",
                      }}
                    />
                  </div>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    Ultimate and advanced trading platforms
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <div
                    className="image-aspect-ratio-container"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                      width: "70px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Wide range of global financial instruments"
                      className="fdb-icon"
                      src={instruments}
                      width="70px"
                      style={{
                        marginBottom: "9px",
                        backgroundColor: "#fff",
                        padding: "13px",
                        border: "1px solid #d8d8d5",
                      }}
                    />
                  </div>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    Wide range of global financial instruments
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <div
                    className="image-aspect-ratio-container"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                      width: "70px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Specialized education tutorials and trading tools"
                      className="fdb-icon"
                      src={research}
                      width="70px"
                      style={{
                        marginBottom: "9px",
                        backgroundColor: "#fbfbfb",
                        padding: "13px",
                        border: "1px solid #d8d8d5",
                      }}
                    />
                  </div>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    Specialized education tutorials and trading tools
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <div
                    className="image-aspect-ratio-container"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                      width: "70px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="24/5 multilingual customer support"
                      className="fdb-icon"
                      src={support}
                      width="70px"
                      style={{
                        marginBottom: "9px",
                        backgroundColor: "#fbfbfb",
                        padding: "13px",
                        border: "1px solid #d8d8d5",
                      }}
                    />
                  </div>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    24/5 multilingual customer support
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <style jsx>
        {`
          .image-aspect-ratio-container {
            display: flex;
            justifycontent: center;
            alignitems: center;
          }
        `}
      </style>
    </div>
  );
};

export default WhyNoor;
