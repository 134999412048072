import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import { Link } from "react-router-dom";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgpartnershipbanner4Ar.jpg"
import investmentimg from "../../assets/images/pages/services/investment.jpg";
 import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import WhyNoorAr from "./whyNoorAr";

const style = {padding: '50px 0' };

const { Title } = Typography;

const Institutional_AssetManagersAr = () => {
  return( <div>
            <PageseMetaTags
                title="Asset Managers (MAM/PAMM) - Forex Trading | Noor Capital"
                description="Noor Capital MAM/PAMM accounts allow money managers to make use of flexible allocation methods and real-time reporting of commissions and performance."
            />

            <BgBeforeAr 
            pageHeadingTitle="طًور عملك مع مدير الحسابات المتعددة وإدارة تخصيص أموال النسبة المئوية (MAM-PAMM)  من نور كابيتال لمديري الأصول "
            pageHeadingDescription=""
            pageHeadingbg={img1}

            />
        <BreadcrumbMenuAr
        pageMainCategoryName = "الرئيسية"
        pageMainCategoryPath = "/"
        
        pageSubCategoryName = "الشراكة"
        pageSubCategoryPath = "#"

        pageName = "برامج مدراء الأصول"
        />

<div className={globalstyle.container}>
        <br />
        <br />
        <Row>
        <Title style={{ color: "#1b75bc" }} level={3}>برامج مدراء الأصول (MAM/PAMM)</Title>
          
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
           xs={24}
           sm={24}
           md={24}
           lg={24}
           xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            في نور كابيتال، نتفهم أنه من أجل توسيع قاعدة العملاء، فإن مديري الأصول يطلبون أفضل سعر لحسابهم التقديري، فمن خلال منصتنا القوية، يمكن لمديري الأصول تنفيذ تلك الاستراتيجيات بسلاسة.
            </p>

            <p className="parjustify text-right">
            تجعل حلول الشركاء لدينا لمديري الأصول كل شيء سريعًا وسهلاً وآمنًا، بدءًا من تخصيص التجارة وإعداد التقارير والإدارة. نحن نقدم لك خدمات تداول فعالة من حيث التكلفة وفرصة للوصول إلى السوق العالمية، بما في ذلك العملات الأجنبية والعقود مقابل الفروقات والعقود الآجلة وصناديق الاستثمار المتداولة والأسهم.
            </p>

            <p className="parjustify text-right">

            لدينا فريق مخصص لتزويدك بحل مُصمم خصيصًا لك ليناسب أهداف عملك، مما يوفر حلاً جاهزًا لمديري الأصول. مع نور كابيتال، لا تقلق فيما يخص انخفاض أسعار الصرف حيث يتوفر لدينا منصة قوية وخدمة مباشرة.
            </p>

            

          </Col>

          
        </Row>

<br/>
      


      
      <style jsx>{
            `
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd){
              background-color: #f1f1f1;
          }
            `
        }
        </style>

      </div>  


      <section className="fdb-block" style={{"backgroundColor":"#f2f4f7"}}>
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
            

              <Title style={{"color":"#11131b"}} level={3}>
              اتصل بنا الآن
                </Title>
              <p className="mt-5 mt-sm-4">
                <Link className="btn btn-outline-primary" to="/ar/about/contact-us">تواصل معنا</Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoorAr />

  </div>
  )

};

export default Institutional_AssetManagersAr;