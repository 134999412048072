import React from "react";
import { Row, Col , Typography } from 'antd';
import { Link } from "react-router-dom";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bggtnAr.jpg";
import mt4desktop from "../../assets/images/pages/trading/gtn.png";
 import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import WhyNoorAr from "./whyNoorAr";

const { Title } = Typography;

const style = {padding: '50px 0' };

const GTN_GTNWebtraderAr = () => {
  return( <div>
            <PageseMetaTags
                title="Noor Capital Stocks Trading Platform (GTN)"
                description="A robust trading app that supports our active traders' daily activities, keeping them ahead of the game."
            />
            <BgBeforeAr
            pageHeadingTitle="منصة نور كابيتال لتداول الأسهم (GTN) مسؤولة عن الأسهم الخاصة بك حيث تتيح لك التداول أينما كنت. "
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
       
       
       <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="تداول الأسهم باستخدام متصفح الويب"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          قم بالوصول إلى حساب الأسهم العالمية الخاص بك من متصفح الويب
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            مع منصة نور كابيتال لتداول الأسهم (GTN)، ستجد مستوى غير مسبوق من الراحة والسهولة أثناء التداول . فنحن نقدم لك تطبيق تداول فعال يدعم الأنشطة اليومية للمتداولين النشطين، مما يجعلهم في صدارة اللعبة.
            </p>
            <p className="parjustify text-right">
            منصة نور كابيتال لتداول الأسهم (GTN)، هي منصة فريدة تتيح لك شراء/ بيع الأسهم من أي مكان وتلقي التنبيهات والأخبار والتقارير المهمة مما يمنحك اليد العليا في عالم تداول سريع الخطى.
            </p>

            <p className="parjustify text-right">
            يوفر لك تطبيق نور كابيتال لتداول الأسهم (GTN) واجهة سهلة الاستخدام للغاية يمكن الوصول إليها من أجهزة كمبيوتر سطح المكتب والهاتف الجوال  (متوفر في البلاي ستور  والاب ستور ).
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-12 col-md-8 col-lg-4 m-sm-auto">
                <p className="mt-3">
                  <a
                    className="btn btn-primary btn-lg"
                    href="https://noorcapital.globaltradingnetwork.com/web/login"
                    target="_blank"
                    
                  >
                    إستخدام متصفح الويب
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                الميزات 
                </Title>
                <h5>
                غير طريقة التداول - من أي مكان، وفي أي وقت ومن أي جهاز.
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  ابدأ التداول على الفور من أي متصفح إنترنت.

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  متوافق مع جميع أنظمة التشغيل الرئيسية.

                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                 تداول في أكثر من 60 ألف سهم.

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  ادخل فورا إلى أسواق دول مجلس التعاون الخليجي وأوروبا والولايات المتحدة.

                </p>
                <br />
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  
                  تداول أسهم  شركات عالمية  (فيس بوك Facebook، ومايكروسوفت Microsoft وتيسلا Tesla وما إلى ذلك).

                </p>
                <br />
                

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  جرب منصة التداول الأكثر أمانًا وسهولة في الاستخدام حول العالم.

                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  شراء وبيع وإلغاء وتعديل الأوامر بضغطة واحدة.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تلقي البيانات والمخططات والتقارير والإعلانات في الوقت الفعلي.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  يمكنك اختيار لغتك المفضلة (انجليزي / عربي).
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  إضافة وتتبع الأسهم المفضلة لديك في قائمة المراقبة الذكية.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                 إدارة محافظ متعددة.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  إدارة رصيد حسابك وحدود الشراء مع ملخص الحساب.
                  
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={4}>
              التداول لأول مرة مع منصة نور كابيتال لتداول الأسهم (GTN)؟
              </Title>

              <p className="mt-5 mt-sm-4">
              <Link
                  className="btn btn-outline-primary"
                  to="/stocks-trading-platforms/open-gtn-live"
                >
                  تسجيل حساب حقيقي
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>


  </div>
  )

};

export default GTN_GTNWebtraderAr;