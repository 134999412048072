import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import alsayegh from "../../assets/images/board/alsayegh.jpg";
import zamzam from "../../assets/images/board/zamzam.jpg";
import alhasawi from "../../assets/images/board/hasawi.jpg";
import almeshaal from "../../assets/images/board/meshaal.jpg";
import faisalgaladari from "../../assets/images/board/mr-faisalgaladari.jpg";
import jaberalsuwaidi from "../../assets/images/board/suwaidi.jpg";
import afaleq from "../../assets/images/board/saleh-hassan-al-afaleq.jpg";
import badran from "../../assets/images/board/tariq-khalil-badran.jpg";
import allboard from "../../assets/images/board/allboard.jpg";
import abdullahalsaadi from "../../assets/images/board/abdullahalsaadi.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };

const BoardMembers = () => {
  return (
    <div>
      <PageseMetaTags
        title="Board Members  - Noor Capital"
        description="Noor Capital Board Members"
      />
      <BgBefore
        pageHeadingTitle="Board Members"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Board Members"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div
            className="row text-center justify-content-center"
            style={{ marginBottom: "60px" }}
          >
            <div className="col-10">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
                Noor Capital Board Members
              </h2>
              
                  <img
                    alt="Noor Capital Board Members"
                    className="img-fluid rounded imgshadow boardimage"
                    // className="img-fluid rounded-circle imgshadow"
                    src={allboard}
                    
                  />
                
            </div>
          </div>

          {/* 111111111111 */}

          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "10px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Abdul Jabbar Al-Sayegh"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={alsayegh}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 0px 10px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>
                    Mr. Abdul Jabbar Al-Sayegh{" "}
                  </h3>
                  <p className="lead">Chairman</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                   A visionary and prominent industry leader, Mr. Abdul Jabbar Al-Sayegh, Chairman and one of the Founders of Noor Capital has a massive contribution in making us one of the top-most investment companies locally and internationally. A dedicated and outstanding businessman, he also plays a crucial role as a Chairman and CEO at Al-Sayegh Brothers Group of Companies, which has more than 4,500 employees in the UAE. He specializes in Engineering & Construction, Electronics, Civil Contracting, Solar Systems, and Managing the Language Centres of Berlitz UAE and Mayoor and Al Muneera Private Schools. With years of expertise and sheer brilliance, Mr. Abdul Jabbar Al-Sayegh played a vital role in his previous positions at multiple prestigious institutions, right from the Abu Dhabi Investment Authority (ADIA), Abu Dhabi Investment Company (ADIC) to the Abu Dhabi Commercial Bank (ADCB). An influential businessman, a prominent figure in the UAE, and a passionate traveler, Mr. Abdul Jabbar Al-Sayegh, who holds a Bachelor of Science Degree in Business Administration from the University of Denver, Colorado, brings his years of experience and knowledge for the overall development of Noor Capital.
                   </p>
                </Col>
              </Row>
            </div>
          </div>

          <hr width="30%" />

          {/* 222222222222 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Dr. Hamad Al-Hasawi"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={alhasawi}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Dr. Hamad Al-Hasawi</h3>
                  <p className="lead">Vice-Chairman</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    A highly professional and well-known name in the Financial and Investment sector, Dr. Hamad Al-Hasawi, Secretary-General of The Kuwait Banking Association, has immense experience with prominent brands like Gulf Investment Company, KMCO, Gulf Holding Company, and Rasameel Structured Finance Company. Dr. Hamad Al-Hasawi, who recently served as a Visiting Professor at the American University of Kuwait, has also assisted the Kuwait Institute for Scientific Research, the Partnerships Technical Bureau of the Ministry of Defense as a Consultant with his impeccable experience and knowledge. Holding a Ph.D. in Science, Engineering, and Management, Dr. Hamad Al-Hasawi has positively contributed to the Finance and Investment sectors with his studies and various researches.
                    </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />

          {/* 333333333333 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Dr. Abdulla Essa Zamzam"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={zamzam}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Dr. Abdulla Essa Zamzam </h3>
                  <p className="lead">Board Member</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    With more than 30 years of robust experience in various sectors, including Oil and Gas, Academia, and Real Estate, Dr. Abdulla Essa Zamzam, serving as an Assistant Secretary-General of Operations at the Environment Agency – Abu Dhabi (EAD), has contributed immensely with his work and knowledge. A highly intellectual leader, Dr. Abdulla Essa Zamzam holds a Ph.D. in Sustainable Economic Development Strategies from Coventry University, UK, and a Master’s Degree in Business Administration in Management from Woodbury University.
                    </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />

          {/* 44444444444 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Faisal Galadari"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={faisalgaladari}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Mr. Faisal Galadari</h3>
                  <p className="lead">Board Member</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    Mr. Faisal Galadari, the previous CEO of Al Masraf Bank, is also a member of a highly successful and renowned group - Galadari Brothers Group of Dubai. Mr. Faisal Galadari, who is also well-known for his vision and businessman skills, runs various successful ventures in the UAE and worldwide.
                    </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 55555555555 */}
          
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Rashed Saif Jabr Al Suwaidi"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={jaberalsuwaidi}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>
                    Mr. Rashed Saif Jabr Al Suwaidi
                  </h3>
                  <p className="lead">Board Member</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    Mr. Rashed Saif Jabr Al Suwaidi has marked his name among the industry leaders with his sheer dedication and years of expertise. From being the Director of Exploration and Production in Abu Dhabi National Oil Company (ADNOC) to serving as Chairman of National Drilling Company (NDC), Abu Dhabi Drilling Chemicals & Products Ltd. (ADDCAP) and Abu Dhabi Petroleum Ports Operating Company (ADPPOC), he has taken up every role and challenge with courage and dignity. He has come a long way and has unmatched experience in the Oil and Gas sector. He was also the founding shareholder and a board member of Aabar Investments. Mr. Rasheed Saif Jaber Al Suwaidi is an extraordinary member of Noor Capital who brings on his years of experience and advice for the company's betterment.
                    </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 6666666666 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Saleh Hassan Al-Afaleq"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={afaleq}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Mr. Saleh Hassan Al-Afaleq </h3>
                  <p className="lead">Board Member</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                   A very notable name in UAE and Saudi Arabia, Mr. Saleh Hassan Al-Afaleq, Board Member of Ibdar Bank and Noor Capital, is also the Managing Director of AlKifah Holding Company and its subsidiaries. With immense experience, he leads several ventures like Al-Ahsa Amusement & Tourism Company (AHSANA), where he acts as a Chairman and Vice Chairman at Arbah Capital. Holding a Master’s Degree in Human Resource Development from Seattle University, the U.S., Mr. Saleh Hassan Al-Afaleq is a prominent personality in Saudi Arabia. He is also a Member of the Saudi Majlis Al-Shura (Saudi Parliament) and the Chairman of Al-Ahsa Chamber of Commerce.
                   </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 77777777777777 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Tariq Khalil Badran"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={badran}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Mr. Tariq Khalil Badran </h3>
                  <p className="lead">Board Member</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    With more than 10 years of local and international experience in the finance sector, Mr. Tariq Khalil Badran, the CEO of Al Ain Capital, brings innovative strategies with his tremendous market knowledge and expertise. A Certified Public Accountant (CPA) in the United Kingdom, Ireland, and the U.S., Mr. Tariq Khalil Badran has served as a General Manager at Abu Dhabi Financial Services and National Bank of Abu Dhabi and Executive Manager at Commercial Bank of Dubai.
                    </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 88888888888 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Eng. Abdulmajed Almeshaal"
                    className="img-fluid rounded imgshadow"
                    // // className="img-fluid rounded-circle imgshadow"
                    src={almeshaal}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>
                    Eng. Abdulmajed Almeshaal
                  </h3>
                  <p className="lead">Board Member</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    With more than 10 years of experience and expertise in the Financial sector, Mr. Eng Abdulmajed Almeshaal is a Board Member of several Real Estate and Investment Companies. Holding a Bachelor’s Degree in Computer Engineering from the University of Missouri, USA, Mr. Eng Abdulmajed Almeshaal has a firm grip and background in Investment, Hedge Funding, Private Equity, and Real Estate Funding.
                  </p>
                </Col>
              </Row>
            </div>
          </div>



          <hr width="30%" />
          {/* 88888888888 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Eng. Abdulmajed Almeshaal"
                    className="img-fluid rounded imgshadow"
                    // // className="img-fluid rounded-circle imgshadow"
                    src={abdullahalsaadi}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>
                    Mr. Abdullah Al Saadi
                  </h3>
                  <p className="lead">Board Member</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
With over 40 years of experience in the financial and investment sector, Mr. Abdulla Ali Al Saadi is a great addition to Noor Capital Board Members. He has shown an impressive impact from very well-known companies like Abu Dhabi Fund for Development, The Higher Consultative Committee for Development, and National Investment Corporation. He holds a BA Major in Business Admin and is one of the board members from Finance House and Emirates Insurance Company.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          
          {/* <div className="row-70" /> */}
        </section>
      </div>

      <style jsx>
        {`
          .imgshadow {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19);
          }
          .boardimage{
            width: 50%;
          }
          @media only screen and (max-width: 600px) {
            .boardimage{
              width: 100%;
            }
        
            
            
          }
        `}
      </style>
   
    </div>
  );
};

export default BoardMembers;
