import React from "react";
import { Row, Col } from 'antd';
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgothers.jpg"

import sitemapfile from "../../assets/ext/sitemap.txt";

const style = {padding: '50px 0' };

const SiteMap = () => {
  return( <div>
            <PageseMetaTags 
            title="Title"
            description="description"
            />
        <BgBefore 
        pageHeadingbg={img1}
        />
        <BreadcrumbMenu />

    <div className={globalstyle.container}>

        <Row>
            <Col span={24} className="gutter-row">

            <div style={style}>

            <div className="col-9" style={{ margin: "auto" }}>
                 <h6>
                    <a href={sitemapfile} target="_blank">
                    site map file
                    </a>
                  </h6> 
                </div>

            <div>
         </div>

 
      </div>


            </Col>
        </Row>

    </div>

  </div>
  )

};

export default SiteMap;