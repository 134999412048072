import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgnoortrader.jpg";

import noortreaderdesktop from "../../assets/images/pages/trading/noortreaderdesktop.png";
import WhyNoor from "./whyNoor";
import OpenNoorTraderLiveForm from "../forms/openNoorTraderLiveForm.js";
import NtVideo from "./ntVideo.js";

const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader_OpenLiveAccount = () => {
  return( <div>
          <PageseMetaTags
                title="NoorTrader Live Account - Noor Capital"
                description="When you sign up with NoorTrader, you will unlock the most significant investment opportunity in Bullion."
            />

            <BgBefore 
            pageHeadingTitle="Your Successful Investment Starts Here"
            pageHeadingDescription="Sign-Up For A Live NoorTrader Account And Start Bullion Trading Right Away"
            pageHeadingbg={img1}
           />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="NoorTrader Live Account"
      />



<div className={globalstyle.container} id="main">
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
           Register For NoorTrader Live Account - Physical Gold & Silver
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center" >
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            
            <p className="parjustify">
            When you sign up with NoorTrader, you will unlock the most significant investment opportunity in Bullion. We offer you 24/7 access to the global bullion marketplace. 
            </p>

            <p className="parjustify">
            NoorTrader allows you to buy, sell, and manage investments from anywhere in the world. To keep on top of your game, you can access the latest bullion news, events, and updates covering significant events and analysis related to bullion.
            </p>

            <OpenNoorTraderLiveForm />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <NtVideo />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                Why Do You Need To Open A NoorTrader Live Account - Right Away?
                </Title>
                
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Providing Storage & Delivery

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get instant access to the global bullion market
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Start buying, selling, and managing your bullion with just a click
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
Deal in any size (Kg, Oz & Grams)
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
24/7 access to your investment
                </p>

               
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get unmatched on the spot market price with an option for partial deposit
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
Platform Compatible with all web browsers and smart phones
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Spot prices of physical trading in Gold, Silver, etc
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Flexibility by using Multi-Functional System
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4desktop" className="img-fluid" src={noortreaderdesktop} />
              </div>
            </div>
            
          </div>
        </section>
      </div>

      <br />

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Start Your Bullion Trading Journey With NoorTrader
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="#main"
                  
                >
                  Sign-Up Now
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>




  </div>
  )

};

export default NoorTrader_OpenLiveAccount;