import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtradingbanner3.jpg";
import Instruments_SharesCfds_Table from "./trading_Instruments_SharesCfds_table.js";
import SharesCFDs from "../../assets/images/pages/instruments/SharesCFDs.jpg";
import Products from "./products.js";

const { Title } = Typography;

const notes = [
  " Minimum trade volume is 0.01 Lot / 1 Share",
  " Storage Fees applies on the above-listed instruments starting from the day 1 of holding a position, with no grace period",
  " Terms and schedules above are subject to change without prior notice",
  " Splits/dividends/corporate actions may apply",
];

const Instruments_SharesCfds = () => {
  return (
    <div>
      <PageseMetaTags
        title="Shares CFDs | Noor Capital"
        description="Noor Capital offers the ability for all clients to trade Shares based on CFD contracts, to benefit from global markets accessibility by trading the most popular shares CFDs through your trading account and using one platform."
      />

      <BgBefore
        pageHeadingTitle="Trade with Confidence Only with Noor Capital"
        pageHeadingDescription="Start CFD Trading Now"
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Trading"
        pageMainCategoryPath="#"
        pageSubCategoryName="Instruments"
        pageSubCategoryPath="#"
        pageName="Shares CFDs"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Shares CFDs
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            The three letters in CFD stand for “Contract for difference”. This type of financial tool is  an agreement between a buyer and a seller. The terms of a CFD mainly include that the buyer agrees on paying a price equal to the difference in the value of an underlying asset at present and at the time of contract. It is a great trading strategy because it allows investors and traders to earn margins without buying an underlying asset.
            </p>

            <p className="parjustify">
            If you are looking for options to expand your investment portfolios, CFDs are the flexible option you need. If you choose Noor Capital for CFDs trading you can trade both in falling and rising markets with the least possible risk . It's not everything,  you can also choose a variety of assets and several tools for better trading.
            </p>

            <p className="parjustify">
            Noor Capital provides you with real-time and updated market values so that you can proceed with your trades accordingly. We send regular updates to our traders to make sure that you trade at appreciable margins and do not let any opportunity pass.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={SharesCFDs}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Specifications list
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Instruments_SharesCfds_Table />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Trading Terms
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(1) {
              background-color: #f1f1f1;
            }

            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(3) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              CFD Trading on Shares - Get Started Today
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Sign-Up
                  </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Products />
    </div>
  );
};

export default Instruments_SharesCfds;
