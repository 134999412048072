import React from "react";
import "./mainPage.css";
import { Link } from "react-router-dom";
import platform from "../../assets/images/home/platform.png";
import mt4 from "../../assets/images/home/mt4.png";
import gtn from "../../assets/images/home/gtn.png";
import noortrader from "../../assets/images/home/noortrader.png";

const MainPage3Ar = () => {
  return ( 
    <>
      {/* START ROW 33333333 */}
      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">

            <div className="row align-items-center pb-xl-5">
              <div className="col-12 col-md-7 col-xl-5 text-right"> 
                <h1 style={{ color: "#1b75bc" }}>منصات متعددة مبتكرة </h1>
                <p className="lead">
                  نقدم لعملائنا مجموعة واسعة من منصات التداول للإستخدام على سطح
                  المكتب والهواتف الذكية والويب ليكونوعلى إتصال دائم باستثمارتهم
                </p>
              </div>

              <div className="col-12 col-sm-6 col-md-6 m-sm-auto mr-md-0 ml-md-auto pt-4 pt-md-0">
                <img alt="Platforms" className="img-fluid img-fluid-onmobile" src={platform} />
              </div>
              
            </div>
            <div className="row pt-5" style={{ textAlign: "center" }}>
              <div className="col-12 col-sm-6 col-md-4">
                <img alt="Platforms" className="img-fluid" src={mt4} />
                <Link
                  to="/ar/trading-platforms/metatrader4-platform"
                  className="effect-underline"
                >
                  <h3 style={{ color: "#1b75bc" }}>
                  <i
                      class="fas fa-angle-left"
                      style={{ fontSize: "16px", marginLeft: "10px" }}
                    ></i>

                    تداول العملات والسلع
                    
                  </h3>{" "}
                </Link>
              </div>

              <div className="col-12 col-sm-6 col-md-4 pt-4 pt-sm-0">
                <img alt="Platforms" className="img-fluid" src={gtn} />
                <Link
                  to="/ar/stocks-trading-platforms/gtn"
                  className="effect-underline"
                >
                  <h3 style={{ color: "#1b75bc" }}>
                  <i
                      class="fas fa-angle-left"
                      style={{ fontSize: "16px", marginLeft: "10px" }}
                    ></i>

                    تداول الأسهم 
                    
                  </h3>
                </Link>
              </div>
              <div className="col-12 col-sm-6 col-md-4 pt-4 pt-md-0">
                <img alt="Platforms" className="img-fluid" src={noortrader} />
                <a
                  href="https://noorindex.com/"
                  className="effect-underline"
                  target="_blank"
                >
                  <h3 style={{ color: "#1b75bc" }}>
                  <i
                      class="fas fa-angle-left"
                      style={{ fontSize: "16px", marginLeft: "10px" }}
                    ></i>
                    حلول تداول السبائك الفعلي
                    
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* End ROW 33333333 */}
    </>
  );
};

export default MainPage3Ar;
