import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtradingbanner3.jpg";
import bullion from "../../assets/images/pages/instruments/bullion.jpg";
import Products from "./products.js";
import Instruments_Bullions_Table from "./trading_Instruments_Bullions_table";

const { Title } = Typography;

const Instruments_Bullion = () => {
  return (
    <div>
      <PageseMetaTags
        title="Bullion Trading - Noor Capital"
        description="Bullion Trading is among the top profit-making trades these days. It is a marketplace where gold and silver are traded."
      />
      <BgBefore
        pageHeadingTitle="Invest in Gold, Silver and more"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Trading"
        pageMainCategoryPath="#"
        pageSubCategoryName="Instruments"
        pageSubCategoryPath="#"
        pageName="Bullion"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Bullion
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Bullion Trading is among the top profit-making trades these days. It is a marketplace where gold and silver are traded. When you trade gold and silver with Noor Capital, we make sure you get competitive levels of commission and profit. For that, we offer some of the best prices in the global market. We provide you with the simplest trading procedures so that you spend more time trading.
            </p>

            <p className="parjustify">
            Noor Capital provides its investors and traders with advanced data aggregation services and the most competitive gold price fluctuation analysis to accommodate different investing and trading goals. 
            </p>

            <p className="parjustify">
            At Noor Capital, we not only provide you with advanced trading platforms but we also educate you through our webinars and video tutorials, so that you be well-informed and take calculated steps while trading bullion.
            </p>
           
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={bullion}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Online Forex Trading"
            />
          </Col>
        </Row>


        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Specifications list
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Instruments_Bullions_Table />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              Start Trading Gold and Silver Today
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Sign-Up
                  </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Products />
    </div>
  );
};

export default Instruments_Bullion;
