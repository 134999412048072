import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import { Link } from "react-router-dom";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgpartnershipbanner4.jpg"
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import WhyNoor from "./whyNoor.js";

const style = {padding: '50px 0' };

const { Title } = Typography;

const Institutional_AssetManagers = () => {
  return( <div>
            <PageseMetaTags
                title="Asset Managers (MAM/PAMM) - Forex Trading | Noor Capital"
                description="Noor Capital MAM/PAMM accounts allow money managers to make use of flexible allocation methods and real-time reporting of commissions and performance."
            />

            <BgBefore 
            pageHeadingTitle="Nourish your business with Noor Capital MAM and PAMM Accounts for Asset Management"
            pageHeadingDescription=""
            pageHeadingbg={img1}

            />
        <BreadcrumbMenu
        pageMainCategoryName = "Home"
        pageMainCategoryPath = "/"
        
        pageSubCategoryName = "Partnership"
        pageSubCategoryPath = "#"

        pageName = "Asset Managers (MAM/PAMM)"
        />

<div className={globalstyle.container}>
        <br />
        <br />
        <Row>
        <Title style={{ color: "#1b75bc" }} level={3}>Asset Managers (MAM/PAMM)</Title>
          
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
           xs={24}
           sm={24}
           md={24}
           lg={24}
           xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            At Noor Capital, we understand that to expand the client base, Asset Managers require the best price for their discretionary account. With our user-friendly platform, Asset Managers can execute strategies quickly.
            </p>
            <p className="parjustify">
            Our partner solution for the Asset Managers makes everything quick, easy, and secure, right from trade allocation, reporting, and administration. We provide you with cost-effective trading services and a chance to access the global market, including FX, CFDs, Futures, ETFs, and Stocks.
            </p>
            <p className="parjustify">
            We have a dedicated team to provide you with a tailormade solution to fit your business goals, providing a turnkey solution for Asset Managers.
            </p>

            

          </Col>

          
        </Row>

<br/>
      


      
      <style jsx>{
            `
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd){
              background-color: #f1f1f1;
          }
            `
        }
        </style>

      </div>  


      <section className="fdb-block" style={{"backgroundColor":"#f2f4f7"}}>
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
            

              <Title style={{"color":"#11131b"}} level={3}>
              Call us now 
                </Title>
              <p className="mt-5 mt-sm-4">
                <Link className="btn btn-outline-primary" to="/about/contact-us">Contact Us</Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoor />

  </div>
  )

};

export default Institutional_AssetManagers;