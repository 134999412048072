import React from "react";
import { Row, Col, Typography, Divider, List, Collapse } from "antd";
import { RightOutlined } from "@ant-design/icons"; // Import if you need custom arrow
import "antd/dist/antd.css";
import "antd/dist/antd.less";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgmt5.jpg";
import mt5desktop from "../../assets/images/pages/trading/mt5desktop.png";
import WhyNoor from "./whyNoor";
import OpenCeUkDemoForm from "../forms/openCeUkDemoForm.js";
import Mt5ceVideo from "./mt5cevideo.js";
// import StockPrices from "../home/mainPage_StockPrices.js";
import PriceTickerStocks from "../home/priceTickerStocks";
import investmentimg from "../../assets/images/pages/trading/Cash-Equities-Direclty.png";

const { Panel } = Collapse; // Destructure Panel from Collapse

const CEfeatures = ["U.S. Market Access", "Fractional Shares", "Algo Trading"];

const items1 = [
  {
    key: "1",
    header:
      "How can I start trading U.S. Stocks on MT5 as a Noor Capital client?",
    children:
      "For Noor Capital Clients: If you're looking to start trading on U.S. Stocks directly via MT5, kindly navigate to your member area (MyNoor), and apply for a new “MT5 Multi Asset” Sub-Account. Alternatively, you may reach out to your relationship manager directly for additional information.",
    showArrow: true,
  },
  {
    key: "2",
    header:
      "How can I trade popular US stocks on NASDAQ,NYSE exchanges through Noor Capital?",
    children:
      "With Noor Capital, trading on premier exchanges like NYSE and NASDAQ becomes effortless, and you have the opportunity to trade on different industry stocks including AI, tech, health, and finance. Our MetaTrader 5 Multi-Asset trading platform allows clients to directly engage with high-demand stocks such as Tesla (TSLA), Apple (AAPL), Amazon (AMZN), Nvidia (NVDA), and Adobe (ADBE) and other US Stocks",
  },
  {
    key: "3",
    header: "What are the commission rates for trading on MT5?",
    children:
      "Noor Capital offers low commission rates for trading, with rates starting from just $0.01 per share for U.S. stocks, ensuring cost-effective trading for all our clients.",
  },

  {
    key: "4",
    header: "Can I trade U.S. stocks directly on MT5 with Noor Capital?",
    children:
      "Yes, with Noor Capital, you can directly trade U.S. stocks on the MT5 platform. We offer competitive rates starting as low as $0.01/share, giving you access to major U.S. stock exchanges.",
  },

  {
    key: "5",
    header: "What is MetaTrader 5 (MT5)?",
    children:
      "MT5 is a multi-asset trading platform that offers advanced financial trading functions, as well as superior tools for technical and fundamental analysis. It allows trading in stocks, cash equities, forex, precious metals, indices, crypto and much more from one platform.",
  },

  {
    key: "6",
    header:
      "What does 'multi-asset trading platform' mean in relation to MetaTrader 5?",
    children:
      "The term 'multi-asset trading platform' refers to the capability of the MetaTrader platform to support trading across various asset classes. This means traders can access and trade a wide range of financial instruments, including stocks, forex, futures, and cryptocurrencies, all from a single platform. MetaTrader, renowned for its advanced analytical technologies and trading tools, offers traders the flexibility to diversify their portfolio and engage with global financial markets through one integrated interface.",
  },

  {
    key: "7",
    header: "How can I access Noor Capital's Smart Trader Tools?",
    children:
      "Smart Trader Tools are available to all Noor Capital clients on the MT5 platform. These tools provide advanced market analysis and help enhance your trading strategy.",
  },
  {
    key: "8",
    header:
      "Is it possible to customize the MT5 platform according to my trading needs?",
    children:
      "Absolutely. MT5 allows for advanced platform customization options. You can choose from 21 timeframes, apply various analytical tools, and customize the look and feel of the platform to suit your trading preferences.",
  },
];

const { Title } = Typography;

const Metatrader5 = (props) => {
  const { location } = props;
  return (
    <div>
      <PageseMetaTags
        title="Trade US Stocks with Low Fees on MT5 | Noor Capital"
        description="Discover Noor Capital: Trade US stocks directly with commissions as low as $0.01/share. Experience the power of the MT5 multi-asset platform for a comprehensive trading journey. Start with competitive rates and access a world of investment opportunities"
      />
      <BgBefore
        pageHeadingTitle="Trade U.S. Stocks on Multi-Asset Trading Platform"
        pageHeadingDescription="Competitive rates as low as $0.01/share"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="MetaTrader 5"
      />

      <PriceTickerStocks />

      {/* Under Price Ticker Section */}

      <section className={globalstyle.container}>
        <Row>
          <Title style={{ color: "#1b75bc" }} level={4}>
            Noor Capital Cash Equities: Where Technology Meets Investing
          </Title>
        </Row>

        <Row className="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={13}
            lg={13}
            xl={13}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "17px",
            }}
          >
            <p>
              Embark on a transformative trading journey with Noor Capital,
              where cutting-edge technology meets unmatched affordability in the
              realm of stock trading. Delve into the capabilities of the
              MetaTrader 5 (MT5) platform, meticulously crafted for the
              discerning investor seeking a comprehensive suite of multi-asset
              solutions and unrivaled access to global markets. At Noor Capital,
              we're not just about low commission rates; we're about opening
              doors to a plethora of investment opportunities, starting from as
              low as $0.01 per share. Discover the advantages of cash equities
              trading on multi-asset trading platform with us and set sail on a
              voyage where potential meets possibility.
            </p>

            <List
              size="large"
              className="text-left"
              bordered
              dataSource={CEfeatures}
              renderItem={(item) => (
                <List.Item>
                  <i
                    className="fas fa-angle-double-right"
                    style={{
                      fontSize: "18px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
            style={{ paddingTop: "0px" }}
          >
            {/* <img
                src={investmentimg}
                id="directlyimg"
                className="img-fluid rounded mx-auto d-block bgim"
                alt="Trade US Stocks"
              /> */}

            {/* <div className="col-7 col-sm-6  m-auto pt-5 pt-lg-0 order-lg-1"> */}

            

              
              <div
                className="image-container-1"
              
              >
                <center><img
                  src={investmentimg}
                  alt="Trade US Stocks"
                  id="directlyimg"
                /></center>
                

              
            </div>
          </Col>
        </Row>

        <br />
      </section>

      {/* Live CTA */}

      <section className="fdb-block section-wrapper" style={{ backgroundColor: "#F6FAFE" }}>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
            <Title style={{ color: "#11131b" }} level={3}>
              Launch Your Direct U.S. Stock Trading Journey Now with Noor
              Capital
            </Title>
            <p className="mt-5 mt-sm-4">
              <a
                className="btn btn-outline-primary"
                href="https://mynoor.noorcap.ae/en/mynoor-v2/register?P=MT5eq"
                target="_blank"
                rel="noreferrer"
              >
                Open Live Account
              </a>
            </p>
          </div>
        </div>
      </section>

      {/* Demo Section */}

      <div className={`${globalstyle.container}`} id="open-demo">

        
        <Row style={{ paddingTop: "25px", paddingBottom: "5px" }}>
          <Title style={{ color: "#1b75bc" }} level={4}>
            Open an MT5 Demo account, Experience Trading Risk-Free
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              Explore the capabilities of the MT5 platform with Noor Capital's
              demo account. Practice trading US stocks directly and other
              financial instruments, with zero risk and zero cost. It's the
              perfect starting point for refining your strategy and gaining
              confidence.
            </p>

            <OpenCeUkDemoForm location={location} />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Mt5ceVideo />
          </Col>
        </Row>
      </div>

      <br />

      {/* MT5 Fetures Section */}

      <div style={{ backgroundColor: "#F6FAFE" }} className="section-wrapper">
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                  MetaTrader 5:{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Your Comprehensive Trading Solution
                  </span>
                </Title>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "15.8px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Multi-asset Trading
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Algorithmic Trading Capabilities
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Extensive Financial Insights
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Premier Trading Environment
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Enhanced Order Execution
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  User-Friendly Interface
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Robust Charting Tools
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Cutting-Edge Analytical Tools
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Comprehensive Market Analysis
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Customizable Trading Experience
                </p>
              </div>

              <div className="col-7 col-sm-6  m-auto pt-5 pt-lg-0 order-lg-1">
                <div class="image-container">
                  <img
                    alt="MT5desktop"
                    class="img-fluid rounded mx-auto d-block bgim"
                    src={mt5desktop}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <br />

      {/* Download the paltform Section */}

      <div className={globalstyle.container}>
        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <Divider orientation="left">
              <Title level={3} style={{ color: "#1b75bc" }}>
                Get Your Platform Now
              </Title>
            </Divider>

            <p className="parjustify text-center" style={{ fontSize: "20px" }}>
              Leverage the full potential of MT5 paired with your Noor Capital
              Trading account to maximize your trading benefits!
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-android"
                  style={{
                    fontSize: "140px",
                    color: "#1b75bc",
                    paddingBottom: "15px",
                  }}
                ></i>
                <h3>Android</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-dark sl-1"
                    href="https://download.mql5.com/cdn/mobile/mt5/android?server=NoorCapital2-Multi%20Assets,NoorCapital2-Multi%20Assets%20Demo"
                    target="_blank"
                    style={{ fontSize: "18px" }}
                  >
                    Download
                  </a>
                </p>
              </div>

              <div className="col-12 col-sm-10 col-md-6 col-lg-4 ml-sm-auto mr-sm-auto mt-5 mt-md-0">
                <i
                  class="fab fa-apple"
                  style={{
                    fontSize: "140px",
                    color: "#1b75bc",
                    paddingBottom: "15px",
                  }}
                ></i>

                <h3>IPhone</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-dark sl-1"
                    href="https://download.mql5.com/cdn/mobile/mt5/ios?server=NoorCapital2-Multi%20Assets,NoorCapital2-Multi%20Assets%20Demo"
                    target="_blank"
                    style={{ fontSize: "18px" }}
                  >
                    Download
                  </a>
                </p>
              </div>

              <div className="col-12 col-sm-10 col-md-6 col-lg-4 ml-sm-auto mr-sm-auto mt-5 mt-md-0">
                <i
                  class="fa fa-desktop"
                  style={{
                    fontSize: "135px",
                    color: "#1b75bc",
                    paddingBottom: "15px",
                  }}
                ></i>

                <h3>Desktop</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-dark sl-1"
                    href="https://download.mql5.com/cdn/web/22841/mt5/noorcapital2setup.exe"
                    target="_blank"
                    style={{ fontSize: "18px" }}
                  >
                    Download
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      {/* How do you Section */}

      <section className="fdb-block section-wrapper" style={{ backgroundColor: "#F6FAFE" }}>
        <div
          className="container my-5 text-center dark-grey-text"
          style={{ backgroundColor: "#F6FAFE" }}
        >
          <h2 className="font-weight-bold pb-2 mb-4">
            How do you initiate your Investment Path with Noor Capital?
          </h2>
          <p
            className="text-muted w-responsive mx-auto mb-5"
            style={{ fontSize: "18px" }}
          >
            To begin your investment journey on a multi-asset trading platform,
            follow these simple actions
          </p>
          <div className="row text-center">
            <div className="col-md-4 mb-4">
              <div className="card mx-auto" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="mb-4">
                    <span className="badge badge-primary">1</span>
                  </h5>
                  <div className="mb-4">
                    <i className="fas fa-user-plus fa-3x blue-text"></i>
                  </div>
                  <h5
                    className="font-weight-bold mb-4"
                    style={{ color: "#1b75bc", fontSize: "26px" }}
                  >
                    Register
                  </h5>
                  <p className="grey-text">
                    Quickly set up your account and submit the required
                    documents
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card mx-auto" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="mb-4">
                    <span className="badge badge-primary">2</span>
                  </h5>
                  <div className="mb-4">
                    <i className="fas fa-dollar-sign fa-3x blue-text"></i>
                  </div>
                  <h5
                    className="font-weight-bold mb-4"
                    style={{ color: "#1b75bc", fontSize: "26px" }}
                  >
                    Fund
                  </h5>
                  <p className="grey-text">
                    Make deposit to your Noor Capital Account via various
                    deposit options
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card mx-auto" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="mb-4">
                    <span className="badge badge-primary">3</span>
                  </h5>
                  <div className="mb-4">
                    <i className="fas fa-chart-line fa-3x blue-text"></i>
                  </div>
                  <h5
                    className="font-weight-bold mb-4"
                    style={{ color: "#1b75bc", fontSize: "26px" }}
                  >
                    Trade
                  </h5>
                  <p className="grey-text">
                    Download MT5 platform on your device of choice and begin
                    trading
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p className="mt-5 mt-sm-4">
            <a
              className="btn btn-primary btn-md"
              href="https://mynoor.noorcap.ae/en/mynoor-v2/register?P=MT5eq"
              target="_blank"
              rel="noreferrer"
            >
              Start Trading Now
            </a>
          </p>
        </div>
      </section>

      <WhyNoor />

      <section className="fdb-block " style={{ backgroundColor: "#F3F6F9" }}>
        <div className="container ">
          <h6 className="font-weight-normal text-uppercase font-small grey-text mb-4 text-center">
            FAQ
          </h6>
          <h3
            className="font-weight-bold black-text mb-4 pb-2 text-center"
            style={{ color: "#1b75bc" }}
          >
            Frequently Asked Questions
          </h3>
          <hr className="w-header" />
          <p
            className="lead text-muted mx-auto mt-4 pt-2 mb-5 text-center"
            style={{ fontSize: "17px" }}
          >
            Do you have any questions? We're here to provide the answers you
            need. Should you have further inquiries, feel free to reach out to
            us
          </p>

          <Collapse accordion defaultActiveKey={["1"]}>
            {items1.map((item) => (
              <Panel
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i
                      className="fas fa-angle-double-right"
                      style={{
                        fontSize: "16px",
                        marginRight: "10px",
                        color: "#1b75bc",
                      }}
                    ></i>
                    <div className="custom-collapse-header">{item.header}</div>
                  </div>
                }
                key={item.key}
              >
                <div className="custom-collapse-content">
                  <p>{item.children}</p>
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </section>

      <style jsx>
        {`
          .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
            background-color: #f1f1f1;
          }

          .image-container {
            position: relative;
            width: 100%;
            padding-top: 75%; /* for 4:3 aspect ratio images */
            overflow: hidden;
          }

          .image-container img {
            position: absolute;
            top: 26px;
            left: 0;
            width: 100%;
            height: 80%;
          }

          .image-container-1 img {
            
            width: 750px;
            height: 393px;
          }

         

          .custom-collapse-header {
            font-size: 18px;
            line-height: 50px;
          }

          .custom-collapse-content {
            font-size: 16px;
            line-height: 35px;
            text-align: justify;
            text-justify: inter-word;
          }

          @media all and (max-width: 700px) {
            #directlyimg {
              padding-top: 35px;
              padding-right: 30px;
            }
          }

          .section-wrapper {
            border-bottom: 6px solid rgba(24, 140, 236, 0.5);
            // border-top: 6px solid #188cec; 
            background-color: #F6FAFE;
            box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.1);
        }

        `}
      </style>
    </div>
  );
};

export default Metatrader5;
