import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from "rmwc";

// not done we need to change Demo_Noor_Clearing to API Module

import { BASE_URL, COUNTRIES, Demo_Noor_Clearing } from "../../constants";

import {interests} from "./options";

export default function OpenNoorClearingDemoForm() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    country: "AE",
    interest: "",
    agree: false,
  };

  const [state, setState] = React.useState(initialState);

  const [countries, setCountries] = React.useState([{ name: "", code: "" }]);


  const [requestSent, setRequestSent] = React.useState(false);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  };

  React.useEffect(() => {
    axios
      .all([
        axios.get(BASE_URL + COUNTRIES, { headers: headers }),
      ])
      .then(
        axios.spread((countries) => {
          setCountries(countries.data);
        })
      )
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleCountryChange(event) {
    const newState = { ...state, countries: event.target.value };
    setState(newState);
  }


  function handleFormChange(event) {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  }

  function submitOpenNoorClearingDemo(event) {
    event.preventDefault();
    
    const data = {
      first_name: state.firstName,
      last_name: state.lastName,
      phone: state.phoneNumber,
      email: state.email,
      companyName: state.companyName,
      country: state.country,
      interest: state.interest,
      agree: state.agree,
    };
    axios
      .post(BASE_URL + Demo_Noor_Clearing, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          setRequestSent(true);
          setState(initialState);
        }
      });
  }

  return (
    <div>
      <form onSubmit={submitOpenNoorClearingDemo}>
        <div className="row">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="First name"
              name="firstName"
              value={state.firstName}
              onChange={handleFormChange}
            />
          </div>

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Last name"
              name="lastName"
              value={state.lastName}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Company Name"
              name="companyName"
              value={state.companyName}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="email"
              required
              className="form-control"
              placeholder="Email"
              name="email"
              value={state.email}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Phone"
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleFormChange}
            />
            <small  className="form-text text-muted">
              Example: +97150xxxxxxx
            </small>
          </div>
        </div>

       

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              onChange={handleCountryChange}
              value={state.country}
            >
              {countries.map((country) => (
                <option value={country.code}>{country.name}</option>
              ))}
            </select>
            
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="emirate"
              // onChange={handleCountryChange}
              // value={state.country}
            >
              {interests.map((interest) => (
                <option value={interest.interest_code}>
                  {interest.interest_name}
                </option>
              ))}
            </select>
            
          </div>
        </div>


        <div className="row mt-4">
          <div className="col"  style={{
            
            padding: "0px 0px 0px 35px",
            
          }}>
            <input
              type="checkbox"
              className="form-check-input"
              name="agree"
            ></input>
            <small  className="form-text text-muted form-check-label" for="gridCheck">
            I agree to receive information from Noor Capital about its products, services.
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>

      <Dialog
        preventOutsideDismiss
        open={requestSent}
        onClose={(event) => {
          setRequestSent(false);
        }}
      >
        <DialogTitle>Success!</DialogTitle>

        <DialogContent>
          Your request is sent successfully. <br />
          We'll contact you soon.
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">Close</DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
