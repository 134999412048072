import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgtoolsAr.jpg";
import tcimage from "../../assets/images/pages/tools/tc-indicator.png";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  "وجهات نظر المحللين - الاتجاهات والمستويات الرئيسية في متناول يدك",
  "التكيف الشمعدانات - وهناك طريقة سريعة لمعرفة التغييرات الهامة في خطوط العرض والطلب يظهر على شكل أنماط الشمعدانات.",
  "تقارب الاختلاف التكيفي (ADC) - تحليلات مفيدة وفي الوقت المناسب للتداول قصير الأجل مع الحماية من التحركات الجانبية",
  "يمكن الوصول إلى إشارات التداول من أي مكان في العالم .",
];
const { Title } = Typography;

const Tools_TradingSignalsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Trading signals for forex, stocks and indices - Noor Capital"
        description="Trading Signals: Get actionable 'buy' and 'sell' suggestions, based on technical or fundamental analysis."
      />

      <BgBeforeAr
        pageHeadingTitle="لن تعرف الخسارة طريقك عند استخدامك لإشارات تداول نور كابيتال."
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="أدوات التداول"
        pageSubCategoryPath="#"
        pageName="إشارات التداول"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            تحديثات سوق الفوركس
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
              <b>
                لا يهم أين أنت، يمكنك التداول بسهولة وثقة من خلال إشارات التداول
                الخاصة بنا
              </b>
            </p>

            <p className="parjustify text-right">
              في نور كابيتال، نعلم أهمية مؤشر الأسعار المنتظم والثابت
              لمتداولينا، مما دفعنا إلى تزويدك بأداة فريدة ومحدثة للغاية ستساعدك
              على متابعة أحدث اتجاهات السوق واتخاذ القرار الصحيح.
            </p>

            <p className="parjustify text-right">
              تم تصميم مؤشر نور كابيتال TC MT4 لاكتشاف فرص التداول الجديدة ونقاط
              الدخول / الخروج التي ستساعدك على تحسين أداء التداول الخاص بك. هناك
              ثلاثة مؤشرات مبتكرة تمثل أفضل تمثيل لعمل السوق وقد حسنت تجربة
              المستخدم والتركيز على التحليلات وتحديثات البرامج التلقائية.
            </p>

            <Divider orientation="left">
              <Title level={3} style={{ color: "#1b75bc" }}>
                الميزات والفوائد
              </Title>
            </Divider>

            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={tcimage}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div class="alert alert-warning text-right" role="alert">
              لا تشكل المعلومات المقدمة، بأيِّ شكل من الأشكال، التماسًا أو
              إغراءً لشراء أوراق مالية ومنتجات مماثلة أو بيعها. تعكس التعليقات
              والتحليلات وجهات نظر شركة Trading Central في أي وقت محدد، وتخضع
              للتغيير في أي وقت. علاوة على ذلك، لا يمكن أن تشكل التزامًا أو
              ضمانًا من جانب Trading Central. يقر المتلقي ويوافق على أن أي
              استثمار في أداة مالية يكون بطبيعته عشوائيّا، ولهذا ينطويأيُّ
              استثمار على مخاطر تقع مسؤوليتها فقط على عاتق المتلقي وحده دون
              سواه.‏ من المعلوم أن أداء المنتج المالي في الماضي لا يقدم بأي
              طريقة حُكمًا مسبقًا على أدائه المستقبلي.‏ كما تنطوي سوق الصرف
              الأجنبي والمشتقات المالية، مثل عقود الفروقات أو الضمانات أو
              التوربينيات المالية أو الشهادات، على درجة عالية من المخاطر. إذ
              أنها تتطلب مستوى جيدًا من المعرفة والخبرة المالية. وتوصي شركة
              Trading Central بالتشاور مع أخصائي مالي لديه معرفة كاملة بالوضع
              المالي والذمة المالية لمتلقي هذه الرسالة، ولديه القدرة على التحقق
              من أن المنتجات المالية المذكورة تتكيف مع الوضع المذكور والأهداف
              المالية المتبعة. كما توصي شركة Trading Central بقراءة قسم "عوامل
              المخاطر" من نشرة الإصدار لأي منتج مالي مذكور.
            </div>
          </Col>
        </Row>

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              افتح حساب تداول وقم بالوصول إلى مؤشرات التداول
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  فتح حساب حقيقي
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Tools_TradingSignalsAr;
