import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from "rmwc";

import { BASE_URL, COUNTRIES, DEPARTMENTS, CONTACT_US } from "../../constants";

export default function ContactUsForm() {
  const initialState = {
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    country: "AE",
    department: "",
    message: "",
  };

  const [state, setState] = React.useState(initialState);

  const [countries, setCountries] = React.useState([{ name: "", code: "" }]);

  const [departments, setDepartments] = React.useState([]);

  const [requestSent, setRequestSent] = React.useState(false);
  
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  };

  React.useEffect(() => {
    axios
      .all([
        axios.get(BASE_URL + COUNTRIES, { headers: headers }),
        axios.get(BASE_URL + DEPARTMENTS, { headers: headers }),
      ])
      .then(
        axios.spread((countries, departments) => {
          setCountries(countries.data);
          setDepartments(departments.data);
        })
      )
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleCountryChange(event) {
    const newState = { ...state, country: event.target.value };
    setState(newState);
  }

  function handleDepartmentChange(event) {
    const newState = { ...state, department: event.target.value };
    setState(newState);
  }

  function handleFormChange(event) {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  }

  function submitContactUs(event) {
    event.preventDefault();
    if (state.department === "") {
      const departmentsEl = document.getElementById("departments");
      departmentsEl.autofocus();
      departmentsEl.style.borderColor = "#bb0000";
      return;
    }

    const data = {
      first_name: state.firstName,
      last_name: state.lastName,
      company_name: state.companyName,
      department_id: state.department,
      country: state.country,
      phone: state.phoneNumber,
      message: state.message,
      email: state.email,
    };
    axios
      .post(BASE_URL + CONTACT_US, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          setRequestSent(true);
          setState(initialState);
        }
      });
  }

  return (
    <div>
      <form onSubmit={submitContactUs}>
        <div className="row">

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="First name"
              name="firstName"
              value={state.firstName}
              onChange={handleFormChange}
            />
          </div>

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Last name"
              name="lastName"
              value={state.lastName}
              onChange={handleFormChange}
            />
          </div>

        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Company Name"
              name="companyName"
              value={state.companyName}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="email"
              required
              className="form-control"
              placeholder="Email"
              name="email"
              value={state.email}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Phone"
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              onChange={handleCountryChange}
              value={state.country}
            >
              {countries.map((country) => (
                <option value={country.code}>{country.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              id="departments"
              onChange={handleDepartmentChange}
              value={state.department}
            >
              <option value="">Select Department</option>
              {departments.map((department) => (
                <option value={department.id}>{department.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <textarea
              className="form-control"
              name="message"
              required
              rows={5}
              placeholder="How can we help?"
              value={state.message}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>

      </form>

      <Dialog
        preventOutsideDismiss
        open={requestSent}
        onClose={(event) => {
          setRequestSent(false);
        }}
      >
        <DialogTitle>Success!</DialogTitle>

        <DialogContent>
          Your request is sent successfully. <br />
          We'll contact you soon.
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">Close</DialogButton>
        </DialogActions>

      </Dialog>

    </div>
  );
}
