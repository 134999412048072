import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bggtnAr.jpg";
import mt4mobile from "../../assets/images/pages/trading/gtnplatformmobile.png";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import WhyNoorAr from "./whyNoorAr";

const { Title } = Typography;

const style = { padding: "50px 0" };

const GTN_GTNMobileAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Noor Capital Stocks Trading Platform (GTN)"
        description="Noor Capital Stocks Trading Platform (GTN) Mobile is an advanced and innovative trading app that gives you the power to trade from anywhere in the world from your mobile."
      />
      <BgBeforeAr
        pageHeadingTitle="تطبيق سهل ومفيد لبدء التداول"
        pageHeadingDescription="مع تطبيق الهاتف المحمول الخاص بمنصة نور كابيتال لتداول الأسهم (GTN)، أصبح عالم التداول بين يديك."
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="منصة تداول الأسهم عبر الهاتف المتحرك"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          تحميل منصة نور كابيتال لتداول الأسهم على الهاتف المتحرك
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              ابق في صدارة اللعبة وابدأ التداول بثقة. تطبيق منصة نور كابيتال لتداول الأسهم (GTN) هو تطبيق تداول متقدم ومبتكر يمنحك القدرة على التداول من أي مكان في العالم من هاتفك المحمول.
            </p>
            <p className="parjustify">
            لقد أحدثنا ثورة في التداول عبر الهاتف المحمول، مما سهل على متداولينا القيام بأنشطتهم اليومية أثناء التنقل باستخدام منصة متطورة حيث يمكنهم بنقرة واحدة فقط على الهاتف المحمول البدء في شراء / بيع الأسهم والحصول على آخر أخبار الصناعة وتعيين الأسهم المفضلة لديك في قائمة المراقبة وغير ذلك الكثير.
            </p>
            <p className="parjustify">
            صُممت منصة نور كابيتال لتداول الأسهم (GTN) على الهاتف المحمول لجعل حياة المتداول أسهل حيث تتوافق مع الأندرويد  والايفون  ، ومتوفرة في البلاي ستور  والاب ستور.
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-android"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>أندرويد</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://play.google.com/store/apps/details?id=com.gtn.noor"
                    target="_blank"
                  >
                    تحميل
                  </a>
                </p>
              </div>
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 ml-sm-auto mr-sm-auto mt-5 mt-md-0">
                <i
                  class="fab fa-apple"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>ايفون</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://apps.apple.com/ae/app/noor-capital-gtn/id1521622979"
                    target="_blank"
                  >
                    تحميل
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                الميزات
                </Title>
                <h5>
                لماذا تحتاج إلى تحميل منصة الهاتف المحمول الخاصة بك - على الفور؟
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3 text-right">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  ابدأ التداول على هاتفك المحمول على الفور من أي مكان.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  متوافق مع جميع هواتف الاندرويد والايفون.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على أكثر من 60 ألف سهم.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على وصول فوري إلى أسواق دول مجلس التعاون الخليجي وأوروبا والولايات المتحدة.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تداول في شركات معروفة جيدًا (فيس بوك Facebook، ومايكروسوفت Microsoft وتيسلا Tesla وما إلى ذلك).
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                 جرب منصة التداول الأكثر أمانًا وسهولة في الاستخدام حول العالم.
                </p>

              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  شراء وبيع وإلغاء وتعديل الأوامر بضغطة واحدة.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تلقي البيانات والمخططات والتقارير والإعلانات في الوقت الفعلي.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  يمكنك اختيار لغتك المفضلة (انجليزي / عربي).
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  إضافة وتتبع الأسهم المفضلة لديك في قائمة المراقبة الذكية.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  إدارة رصيد حسابك وحدود الشراء مع ملخص الحساب.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  إدارة محافظ متعددة.
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4mobile} />
              </div>
            </div>
          </div>
        </section>
      </div>


      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              ابدأ التداول أينما كنت مع منصة تداول أسهم نور كابيتال (GTN). 
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/stocks-trading-platforms/open-gtn-live"
                  
                >
                  سجل الآن
                </Link>

              </p>
            </div>
          </div>
        </div>
      </section>


   
    </div>
  );
};

export default GTN_GTNMobileAr;
