import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import { Link } from "react-router-dom";
import img404 from "../../assets/images/error-404.jpg";

export default function Page404(props) {
  const {
    location: { pathname },
    history,
  } = props;
  // Redirect old urls to new urls
  switch (pathname) {
    case "/about-us/contact-2/":
      history.push("/about/contact-us");
      break;
    case "/markets/en/energies/":
      history.push("/trading/instruments/energies");
      break;

    case "/markets/en/open-demo-account":
      history.push("/trading-platforms/mt4-open-forex-demo");
      break;

    case "/markets/en/open-demo-account/":
        history.push("/trading-platforms/mt4-open-forex-demo");
        break;

    case "/markets/en/open-demo-account/*":
        history.push("/trading-platforms/mt4-open-forex-demo");
        break;

    case "/markets/en/open-demo-account?":
        history.push("/trading-platforms/mt4-open-forex-demo");
        break;
    case "/markets/en/open-demo-account?$":
        history.push("/trading-platforms/mt4-open-forex-demo");
        break;
    
    case "/markets/en/blog/": // OLD
        history.push("/blog"); // NEW
        break;

    case "/markets/blog": // OLD
        history.push("/blog"); // NEW
        break;
    case "/markets/blog/": // OLD
        history.push("/blog"); // NEW
        break;
    case "/markets/blog/*": // OLD
        history.push("/blog"); // NEW
        break;
    case "/markets/blog/?": // OLD
        history.push("/blog"); // NEW
        break;
    case "/markets/blog/?$": // OLD
        history.push("/blog"); // NEW
        break;

    case "/markets/trading-tools/education/trading-tutorials": // OLD
        history.push("/education/noor-academy"); // NEW
        break;
    
    case "/markets/trading-tools/education/trading-tutorials/": // OLD
        history.push("/education/noor-academy"); // NEW
        break;
    
    case "/markets/trading-tools/education/trading-tutorials/*": // OLD
        history.push("/education/noor-academy"); // NEW
        break;
    
    case "/markets/trading-tools/education/trading-tutorials/?": // OLD
        history.push("/education/noor-academy"); // NEW
        break;
    
    case "/markets/trading-tools/education/trading-tutorials/?$": // OLD
        history.push("/education/noor-academy"); // NEW
        break;

    case "/about-us/company-profile/": // OLD
      history.push("/about/about-us"); // NEW
      break;
    case "/markets/en/": // OLD
      history.push("/"); // NEW
      break;
    case "/about-us/chairmans-message/": // OLD
      history.push("/about/chairman-message"); // NEW
      break;
    case "/about-us/management-team/": // OLD
      history.push("/about/management-team"); // NEW
      break;
    case "/about-us/license-and-regulations/": // OLD
      history.push("/about/license-regulations"); // NEW
      break;
    case "/about-us/board-members/": // OLD
      history.push("/about/board-members"); // NEW
      break;
    case "/global-financial-advisory/": // OLD
      history.push("/global-financial-advisory"); // NEW
      break;
    case "/asset-management/": // OLD
      history.push("/services/asset-management"); // NEW
      break;
    case "/investment/": // OLD
      history.push("/services/investment"); // NEW
      break;
    case "/funds-administration/": // OLD
      history.push("/services/funds-administration"); // NEW
      break;
    case "/markets/en/open-gtn-account/": // OLD
      history.push("/stocks-trading-platforms/open-gtn-live"); // NEW
      break;
    case "/markets/en/open-noor-trader-account/": // OLD
      history.push("/physical-trading-platforms/open-noor-trader-live"); // NEW
      break;
    case "/markets/en/partnership/white-label-solutions/": // OLD
      history.push("/partnership/white-label-solutions"); // NEW
      break;
    case "/markets/en/partnership/institutional-clients/": // OLD
      history.push("/partnership/institutional-clients"); // NEW
      break;
    case "/markets/en/partnership/asset-managers/": // OLD
      history.push("/partnership/asset-managers"); // NEW
      break;
    case "/markets/en/partnership/introducing-brokers/": // OLD
      history.push("/partnership/marketing-partnership-program"); // NEW
      break;
    case "/markets/en/the-best-forex-trading-education-become-a-certified-forex-trader/": // OLD
      history.push("/education/noor-academy"); // NEW
      break;
    case "/markets/en/trading-tools/noor-capital-video-tutorials/": // OLD
      history.push("/trading-tools/video-tutorials"); // NEW
      break;
    case "/markets/en/fund-account/": // OLD
      history.push("/trading/accounts/account-funding"); // NEW
      break;
    case "/markets/en/meta-trader-4-mt4-fx-cfd-mam-account-mobile-trader/": // OLD
      history.push("/trading-platforms/metatrader4-platform"); // NEW
      break;
    case "/markets/en/gtn-platform/": // OLD
      history.push("/stocks-trading-platforms/gtn"); // NEW
      break;

    case "/noor-capital-forms/": // OLD
    history.push("/trading/accounts/account-forms"); // NEW
    break;

    case "/markets/en/trading-conditions/": // OLD
    history.push("/trading/accounts/trading-conditions"); // NEW
    break;

    case "/markets/en/economic-calendar/": // OLD
    history.push("/trading-tools/economic-calendar"); // NEW
    break;

    // Arabic //////////////////////////////////////////
    case "/markets/ar/": // OLD
    history.push("/ar"); // NEW
    break;
    case "/ar/": // OLD
    history.push("/ar"); // NEW
    break;
    
    default:
      break;
  }

  return (
    <div>
      <PageseMetaTags title="404" description="" />

      <div className={globalstyle.container}>
        <br />
        <br />

        <Row>
          <Col span={24} className="gutter-row" style={{ textAlign: "center" }}>
            <Link to="/" class="btn btn-primary" btn-lg>
              Go to Home Page
            </Link>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="gutter-row">
            <img alt="404" className="img-fluid" src={img404} />
          </Col>
        </Row>

        <br />
        <br />
      </div>
    </div>
  );
}
