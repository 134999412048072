import React from "react";
import { Link } from "react-router-dom";
import "./mainPage.css";
import globalstyle from "../../style.module.css";
import fx from "../../assets/images/home/forex.svg";
import metal from "../../assets/images/home/metal.svg";
import energies from "../../assets/images/home/energies.svg";
import indices from "../../assets/images/home/indices.svg";
import share from "../../assets/images/home/shares.svg";

const MainPage2Ar = () => {
  return (
    <>
      {/* START ROW 2222222 */}

      <div>
        <section className="fdb-block">
          <div className="row">
            <div className={globalstyle.container}>
              <div className="row justify-content-center">
                <div className="col text-right">
                  <h2 className="display-5" style={{ color: "#1b75bc" }}>
                    لماذا عليك إختيار نور كابيتال؟
                  </h2>
                  <p className="lead">
                    نور كابيتال هي شركة مساهمة خاصة، تأسست عام 2005، مسجلة لدى
                    دائرة التنمية الاقتصادية في أبوظبي. نظرًا لكوننا مفوضين حسب
                    الأصول من قبل هيئة الأوراق المالية والسلع، فإننا ننفذ
                    أنشطتنا المتعلقة بإدارة الاستثمار والاستشارات المالية
                    والتداول عبر الإنترنت في بيئة آمنة...
                    <Link
                      to="/ar/about/why-noor-capital"
                      style={{ paddingRight: "8px" }}
                    >
                      اطلع على المزيد
                    </Link>
                  </p>
                </div>
              </div>

              <div className="col" style={{ padding: "15px" }}>
                <h1
                  className="display-5 text-center"
                  style={{ color: "#11131b" }}
                >
                  الخدمات
                </h1>
              </div>

              <div className="row text-right">
                <div className="col-12 col-md-8 m-auto col-lg-3">
                  <div className="fdb-box fdb-touch">
                    <h4>الإستشارات المالية العالمية</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      مع تركيزنا على سوق الإمارات العربية المتحدة والمملكة
                      العربية السعودية، نجحنا في توسيع أجنحتنا الاستشارية لتشمل
                      مناطق أخرى في الشرق الأوسط وشمال إفريقيا
                    </p>
                    <p>
                      <Link to="/ar/services/global-financial-advisory">
                        قراءة المزيد
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8 m-auto col-lg-3 pt-5 pt-lg-0">
                  <div className="fdb-box fdb-touch">
                    <h4>إدارة الأصول</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      نحن نقدم حلًا سلسًا لإدارة الأصول للمؤسسات والأفراد ذوي
                      الثروة المالية الكبيرة والمستثمرين الأفراد من خلال قنوات
                      مباشرة وأطراف أخرى
                    </p>
                    <p>
                      <Link to="/ar/services/asset-management">
                        قراءة المزيد
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8 m-auto col-lg-3 pt-5 pt-lg-0">
                  <div className="fdb-box fdb-touch">
                    <h4>الاستثمار المباشر</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      نقدم مجموعة واسعة من الفرص المالية، بما في ذلك الاستثمارات
                      وإدارة الأصول والاستشارات المالية وإدارة الصناديق والوساطة
                      في الأسواق العالمية
                    </p>
                    <p>
                      <Link to="/ar/services/investment">قراءة المزيد</Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8 m-auto col-lg-3 pt-5 pt-lg-0">
                  <div className="fdb-box fdb-touch">
                    <h4>إدارة صناديق الاستثمار</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      توفر لك نور كابيتال حلاً قويًا وسلسًا لإدارة صناديق
                      الاستثمار، خاصةً لشركة إدارة الأصول وصناديق الاستثمار. نحن
                      نقدم حلاً جاهزًا لحساب صافي قيمة الأصول
                    </p>
                    <p>
                      <Link to="/ar/services/funds-administration">
                        قراءة المزيد
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Feature 24 */}
        <section className="fdb-block" style={{ padding: "10px 0" }}>
          <div className={globalstyle.container}>
            <div className="row align-items-top">
              <div className="col-12 col-md-6 col-xl-6 m-auto">
                <div className="text-right">
                  <h2 className="display-4">عالم من الفرص الإستثمارية</h2>
                </div>
                <p
                  style={{ color: "#8892a0", fontSize: "18px" }}
                  className="text-right"
                >
                  تداول أكثر من 1000 أداة و مجموعة واسعة من فئات الأصول العالمية
                  من خلال منصات تداول متعددة
                </p>
              </div>

              <div className="col-12 col-md-6 pt-5 pt-md-0">
                <div className="row justify-content-right">
                  <div className="col-3 m-auto text-right">
                    <img
                      alt="Forex"
                      className="fdb-icon"
                      src={fx}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/forex">
                        <i className="fas fa-angle-left" /> العملات
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Precious Metals"
                      className="fdb-icon"
                      src={metal}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/precious-metals">
                        <i className="fas fa-angle-left" /> المعادن الثمينة
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Energies"
                      className="fdb-icon"
                      src={energies}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/energies">
                        <i className="fas fa-angle-left" /> الطاقة
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="row justify-content-right mt-4 mt-xl-5">
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Indices"
                      className="fdb-icon"
                      src={indices}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/indices">
                        <i className="fas fa-angle-left" /> المؤشرات
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Share CFDs"
                      className="fdb-icon"
                      src={share}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/shares-cfds">
                        <i className="fas fa-angle-left" /> أسواق الأسهم
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* END ROW 2222222 */}
    </>
  );
};

export default MainPage2Ar;
