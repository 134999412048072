
import React from "react";
import { Row, Col } from 'antd';

import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg"
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = {padding: '50px 0',alignSelf: 'justify' };

const AntiMoneyLaunderingPolicy = () => {
  return( <div>
            <PageseMetaTags 
            title="Anti-Money Laundering Policy For Forex Trading | Noor Capital"
            description="Anti Money Laundering Risk For Forex Trading - Noor Capital"
            />
        <BgBefore
        pageHeadingTitle="Anti-Money Laundering Policy "
        pageHeadingDescription=""
        pageHeadingbg={img1}
         />
        <BreadcrumbMenu />
    
    <div className={globalstyle.container}>

        <Row>
            <Col span={24} className="gutter-row">


        <div style={style}>



        </div>


            </Col>
        </Row>

    </div>

  </div>
  )

};

export default AntiMoneyLaunderingPolicy;