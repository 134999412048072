import React from "react";
import {Link} from "react-router-dom";
import "./mainPage.css";
import globalstyle from '../../style.module.css';
import mainpage5bg from "../../assets/images/home/bg5Ar.jpg";

const MainPage5Ar = () => {
  return (
    <>
      
        <section
          className="fdb-block fdb-viewport bg-dark imgbg "
          style={{ backgroundImage: `url(${mainpage5bg})` }}
        >
          <div className=" align-items-right justify-content-right d-flex">
          <div className={globalstyle.container}>
            <div className="row align-items-right text-right">
              <div className="col-12 col-sm-10 col-md-8 col-lg-8">
                <h1 style={{"color":"#1b75bc"}}>برامج نور كابيتال للشراكة</h1>
                <p className="lead">
                استفد من منتجاتنا السوقية المميزة وبرامج الشراكة المميزة. تسريع الربحية والمبيعات وتحويل عملك إلى مستوى جديد
                </p>
                <p className="mt-5">
                  <Link to="/ar/partnership/institutional-clients" className="btn btn-light ml-4" style={{"width":"150px"}}>
                  عملاء المؤسسات 
                  </Link>
                  <Link to="/ar/partnership/marketing-partnership-program" className="btn btn-light " style={{"width":"150px"}}>
                  برنامج الشراكة التسويقية
                                    </Link>
                </p>
              </div>
              </div>
            </div>
          </div>
        </section>
      
    </>
  );
};

export default MainPage5Ar;
