import React from "react";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import globalstyle from "../../style.module.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import a1 from "../../assets/images/a1.png";
import a2 from "../../assets/images/a2.png";
import a3 from "../../assets/images/a3.png";
import a4 from "../../assets/images/a4.png";
import a5 from "../../assets/images/a5.png";
import a6 from "../../assets/images/a6.png";
import "./footer.css";

const Footer = () => {
  return (
    <>
      {/* <div className={globalstyle.contaHiner}>
    <div className="footerBar">
      <span>Get in touch with the A Team</span>
    </div>
  </div> */}
      <footer className="site-footer">
        <div className={globalstyle.container}>
          <div className="row px-auto pt-3">
            <div className="col-md-12">
              <p>
                <strong>Risk Disclosure: </strong>
                <span style={{ fontSize: "12px" }}>
                  Foreign exchange (forex, FX) and Contracts for Difference
                  (CFDs) on Currencies, Commodities, Indices, or Equities are
                  all margin-traded products and thus inherently carry a
                  high-risk level that may not be suitable for all investors.
                  Before deciding to trade FX/ CFD instruments offered by Noor
                  Capital PSC, you should carefully consider your objectives,
                  financial situation, needs, and experience level. You should
                  not trade any margined product unless you fully understand all
                  the risks involved and possess sufficient resources in case of
                  adverse movement in a product's price. You will then be able
                  to meet the financial obligations required by you concerning
                  margin payments and losses,{" "}
                  <Link
                    to="/trading-tools/risk-disclosure"
                    style={{ textDecoration: "underline" }}
                  >
                    Risk Disclosure
                  </Link>{" "}
                  for further details.
                </span>
              </p>
            </div>
          </div>
          <div className="smallSeperator"></div>

          <hr />

          <div className="row mx-auto text-center">
            <div className="col-md-3">
              <h6>Services</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/services/global-financial-advisory">
                    Global Financial Advisory
                  </Link>
                </li>
                <li>
                  <Link to="/services/asset-management">Asset Management</Link>
                </li>
                <li>
                  <Link to="/services/investment">Investment</Link>
                </li>
                <li>
                  <Link to="/services/funds-administration">
                    Funds Administration
                  </Link>
                </li>
                <li>
                  <Link to="/about/why-noor-capital">Why Noor Capital?</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h6>Instruments</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/trading/instruments/forex">Forex</Link>
                </li>
                <li>
                  <Link to="/trading/instruments/precious-metals">
                    Precious Metals
                  </Link>
                </li>
                <li>
                  <Link to="/trading/instruments/energies">Energies</Link>
                </li>
                <li>
                  <Link to="/trading/instruments/indices">Indices</Link>
                </li>
                <li>
                  <Link to="/trading/instruments/shares-cfds">Shares CFDs</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h6>Trading Platform</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/trading-platforms/metatrader4-platform">
                    FX Trading{" "}
                  </Link>
                </li>

                <li>
                  <Link to="/stocks-trading-platforms/gtn">Cash Equities</Link>
                </li>
                <li>
                  <Link to="/institutional/request-demo">Institutional</Link>
                </li>
                <li>
                  <Link to="/trading/accounts/account-forms">
                    Account Forms
                  </Link>
                </li>

                <li>
                  <Link to="/trading/accounts/trading-conditions">
                    Trading Connditions
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h6>Trading Tools</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/trading-tools/economic-calendar">
                    Economic Calendar
                  </Link>
                </li>
                <li>
                  <Link to="/trading-tools/trading-signals">
                    Trading Signals
                  </Link>
                </li>
                <li>
                  <Link to="/trading-tools/traders-glossary">
                    Traders Glossary
                  </Link>
                </li>
                <li>
                  <Link to="/trading-tools/video-tutorials">
                    Video Tutorials
                  </Link>
                </li>
                <li>
                  <Link to="/trading-tools/risk-disclosure">
                    Risk Disclosure
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mx-auto">
            <div className="col-md-12">
              <br />
            </div>
          </div>
          <div className="row mx-auto">
            <div
              className="col-md-8"
              style={{
                textAlign: "left",
                paddingTop: "20px",
                lineHeight: "35px",
              }}
            >
              <b style={{ paddingRight: "15px" }}>Contact Us:</b>
              <span>
                Email:{" "}
                <a href="mailto:info@noorcapital.ae">info@noorcapital.ae</a>{" "}
                <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  |
                </span>{" "}
                Abu Dhabi: <a href="tel:+97126266888">+97126266888</a>{" "}
                <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  |
                </span>{" "}
                Dubai: <a href="tel:+97142795400">+97142795400</a>{" "}
              </span>
            </div>

            <div className="col-md-4">
              <div className="social-buttons">
                <b>Follow Us:</b>
                <a href="https://twitter.com/noor_capital" target="_blank">
                  <i className="fab fa-twitter" target="_blank"></i>
                </a>
                <a
                  href="https://www.facebook.com/noorcapitaluae"
                  target="_blank"
                >
                  <i className="fab fa-facebook" target="_blank"></i>
                </a>
                <a
                  href="https://www.instagram.com/noorcapital/"
                  target="_blank"
                >
                  <i className="fab fa-instagram" target="_blank"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/noorcapitaluae/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in" target="_blank"></i>
                </a>
                <a
                  href="https://www.youtube.com/NoorCapitaluae"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>

          <hr style={{ marginBottom: "20px" }} />

          <div className="smallSeperator"></div>

          <div className="row mx-auto">
            <div className="col-md-2  p-3">
              <Link to="/">
                {" "}
                <img src={logo} width="100px" />
              </Link>
            </div>

            <div className="col-md-4 p-2">
              <div
                id="blogCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                {/* <ol className="carousel-indicators">
                <li data-target="#blogCarousel" data-slide-to={0} className="active" />
                <li data-target="#blogCarousel" data-slide-to={1} />
              </ol> */}

                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-3">
                        <Link to="/awards/22">
                          <div className="aspect-ratio-box">
                            <img
                              src={a1}
                              alt="Awards 2021"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <Link to="/awards/21">
                        <div className="aspect-ratio-box">
                            <img
                              src={a2}
                              alt="Awards 2020"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                          
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <Link to="/awards/20">
                        <div className="aspect-ratio-box">
                            <img
                              src={a3}
                              alt="Awards 2019"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <Link to="/awards/19">
                        <div className="aspect-ratio-box">
                            <img
                              src={a4}
                              alt="Awards 2019"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-3">
                        <Link to="/awards/17">
                        <div className="aspect-ratio-box">
                            <img
                              src={a5}
                              alt="Awards 2017"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <Link to="/awards/16">
                        <div className="aspect-ratio-box">
                            <img
                              src={a6}
                              alt="Awards 2016"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <Link to="/awards/22">
                        <div className="aspect-ratio-box">
                            <img
                              src={a1}
                              alt="Awards 2023"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <Link to="/awards/21">
                        <div className="aspect-ratio-box">
                            <img
                              src={a2}
                              alt="Awards 2019"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 pt-6">
              <div
                style={{
                  textAlign: "right",
                  paddingTop: "26px",
                  fontSize: "12px",
                }}
              >
                <Link to="/about/license-regulations">
                  License and Regulations
                </Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>
                <Link to="/fraud-warning">Fraud Warning</Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>{" "}
                <Link to="/terms-conditions">Terms and Condition</Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>{" "}
                <Link to="/cookies-policy">Cookies Policy</Link>{" "}
                {/* <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>{" "}
                <Link to="/site-map">Sitemap</Link> */}
              </div>
            </div>
          </div>

          <hr style={{ marginTop: "20px" }} />
          <div className="row mx-auto">
            <div
              className="col-md-12 pt-12"
              style={{ textAlign: "center", fontSize: "12px" }}
            >
              <p>Copyright &copy; 2005-2021 All Rights Reserved</p>

              <a
                href="//www.dmca.com/Protection/Status.aspx?ID=6e7409fe-74b6-41e1-a5fe-1af3077b9d5d"
                title="DMCA.com Protection Status"
                class="dmca-badge"
              >
                <img
                  src="https://images.dmca.com/Badges/_dmca_premi_badge_5.png?ID=6e7409fe-74b6-41e1-a5fe-1af3077b9d5d"
                  alt="DMCA.com Protection Status"
                />
              </a>
              <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
                {" "}
              </script>
            </div>
          </div>
        </div>
      </footer>

      <style jsx>{`
        .blog .carousel-indicators {
          left: 0;
          top: auto;
          bottom: -40px;
        }

        /* The colour of the indicators */
        .blog .carousel-indicators li {
          background: #a3a3a3;
          border-radius: 50%;
          width: 8px;
          height: 8px;
        }

        .blog .carousel-indicators .active {
          background: #707070;
        }

        .carousel-control {
          padding-top: 10%;
          width: 5%;
        }

        .disclaimer {
          font-size: 13px;
          text-align: justify;
          background-color: #ffffff;
          color: #bbb;
          padding: 20px 0 0px 0px;
          line-height: 25px;
        }
        .icon-size {
          font-size: 2.5em;
        }

        .footer-links li a:hover {
          color: #1b75bc !importanr;
        }

        .footerBar {
          background-color: #1d8df0;
          padding: 11px;
          border-radius: 5px;
          margin-bottom: 15px;
          text-align: center;
          transition: top ease 0.8s;
          top: 0;
          position: relative;
        }

        .footerBar > span {
          color: #ffffff;
          font-size: 18px;
          padding: 10px;
        }

        .footerBar :hover {
          background-color: #0b68d4;
          cursor: pointer;
          top: -4px;
        }

        .site-footer {
          background-color: #11131b !important;
          padding: 45px 0 45px 0;
          color: #f6f6f6;
          text-align: left;
        }

        .footer-logo-section {
          display: flex;
          height: 90%;
          flex-direction: column;
          justify-content: space-around;
        }

        .site-footer hr {
          border-top-color: #bbb;
          opacity: 0.5;
        }

        .site-footer hr.small {
          margin: 20px 0;
        }

        .site-footer h6 {
          color: #f6f6f6;
          font-size: 17px;
          font-weight: 500;
          // text-transform:uppercase;
          margin-top: 5px;

          text-align: left;
        }

        .site-footer a {
          color: #f6f6f6;
        }

        .site-footer a:hover {
          color: #1a86da;
          text-decoration: none;
        }

        .footer-links {
          padding-left: 0;
          list-style: none;
          text-align: left;
        }

        .footer-links li {
          display: block;
          margin-top: 7px;
          font-size: 13px;
        }

        .footer-links a {
          color: #f6f6f6;
          text-align: left;
        }

        .footer-links a:active,
        .footer-links a:focus,
        .footer-links a:hover {
          color: #1b75bc !important;
          text-decoration: none;
        }

        .footer-links.inline li {
          display: inline-block;
        }

        .site-footer .social-icons {
          margin-top: 10px;
        }

        .site-footer .social-icons a {
          width: 40px;
          height: 40px;
          line-height: 40px;
          margin-left: 7px;
          margin-right: 0;
          border-radius: 30%;
          background-color: #f6f6f6;
        }

        .copyright-text {
          margin: 0;
          text-align: center;
        }

        @media (max-width: 991px) {
          .site-footer [className^="col-"] {
            margin-bottom: 30px;
          }
        }

        @media (max-width: 767px) {
          .site-footer {
            padding-bottom: 0;
          }

          .site-footer .copyright-text,
          .site-footer .social-icons {
            text-align: center;
          }
        }

        .social-icons {
          margin-bottom: 0;
          list-style: none;
          padding: 0;
          list-style-type: none;
        }

        .social-icons li {
          display: inline-block;
          margin-bottom: 4px;
        }

        .social-icons li.title {
          margin-right: 15px;
          text-transform: uppercase;
          color: #96a2b2;
          font-weight: 700;
          font-size: 13px;
        }

        .social-icons a {
          background-color: #eceeef;
          color: #818a91;
          font-size: 16px;
          display: inline-block;
          line-height: 44px;
          width: 44px;
          height: 44px;
          text-align: center;
          margin-right: 8px;
          border-radius: 30%;
          -webkit-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }

        .social-icons a:active,
        .social-icons a:focus,
        .social-icons a:hover {
          color: #fff;
          background-color: #29aafe;
        }

        .social-icons.size-sm a {
          line-height: 34px;
          height: 34px;
          width: 34px;
          font-size: 14px;
        }

        .social-icons a.facebook:hover {
          background-color: #3b5998;
        }

        .social-icons a.twitter:hover {
          background-color: #00aced;
        }

        .social-icons a.linkedin:hover {
          background-color: #007bb6;
        }

        .social-icons a.dribbble:hover {
          background-color: #ea4c89;
        }

        @media (max-width: 767px) {
          .social-icons li.title {
            display: block;
            margin-right: 0;
            font-weight: 600;
          }
        }

        .aspect-ratio-box {
          width: 70%; 
          overflow: hidden;
          position: relative;
        }
        
        .aspect-ratio-box::before {
          content: "";
          display: block;
          padding-top: (height / width * 100)% ;
        }

        
      `}</style>
    </>
  );
};

export default Footer;
