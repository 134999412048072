import React from "react";
import { Row, Col, Typography, Divider, Table } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaccountsAr.jpg";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";

const style = { padding: "50px 0" };
const { Title } = Typography;

const columnsTable1 = [
  {
    title: "العملة",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "رقم الحساب",
    dataIndex: "accountnumber",
    key: "accountnumber",
  },
  {
    title: "IBAN",
    dataIndex: "iban",
    key: "iban",
  },
  {
    title: "SWIFT",
    dataIndex: "swift",
    key: "swift",
  },
  {
    title: "العنوان",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Intermediary Bank",
    dataIndex: "intermediarybank",
    key: "intermediarybank",
  },
  {
    title: "Intermediary SWIFT",
    dataIndex: "intermediaryswift",
    key: "intermediaryswift",
  },
];

const columnsTable2 = [
  {
    title: "العملة",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "رقم الحساب",
    dataIndex: "accountnumber",
    key: "accountnumber",
  },
  {
    title: "IBAN",
    dataIndex: "iban",
    key: "iban",
  },
  {
    title: "SWIFT",
    dataIndex: "swift",
    key: "swift",
  },
  {
    title: "العنوان",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Intermediary/ Correspondent Bank",
    dataIndex: "intermediarybank",
    key: "intermediarybank",
  },
  {
    title: "Intermediary SWIFT",
    dataIndex: "intermediaryswift",
    key: "intermediaryswift",
  },
];

const dataSourceTable1 = [
  {
    key: "1",
    currency: "AED",
    accountnumber: "1002285961",
    iban: "AE810230000001002285961",
    swift: "CBDUAEADXXX",
    address: "Al Ittihad Street, Dubai, UAE",
    intermediarybank: "",
    intermediaryswift: "",
  },
  {
    key: "2",
    currency: "BHD",
    accountnumber: "1006654147",
    iban: "AE980230000001006654147",
    swift: "CBDUAEAD",
    address: "70 - CBD Al Islami - Main Branch",
    intermediarybank: "Ahli United Bank B.S.C, Bahrain",
    intermediaryswift: "AUBBBHBMXXX",
  },
  {
    key: "3",
    currency: "USD",
    accountnumber: "1008371450",
    iban: "AE810230000001008371450",
    swift: "CBDUAEADXXX",
    address: "70 - CBD Al Islami - Main Branch",
    intermediarybank: "J.P. Morgan",
    intermediaryswift: "",
  },
  {
    key: "4",
    currency: "GBP",
    accountnumber: "1001971314",
    iban: "AE960230000001001971314",
    swift: "CBDUAEADXXX",
    address: "70 - CBD Al Islami - Main Branch",
    intermediarybank: "",
    intermediaryswift: "",
  },
  {
    key: "5",
    currency: "EUR",
    accountnumber: "1001971587",
    iban: "AE970230000001001971587",
    swift: "CBDUAEADXXX",
    address: "70 - CBD Al Islami - Main Branch",
    intermediarybank: "",
    intermediaryswift: ""
  }
];

const dataSourceTable2 = [
  {
    key: "1",
    currency: "AED",
    accountnumber: "058520176422001",
    iban: "AE090240058520176422001",
    swift: "DUIBAEAD",
    address: "Main Branch Dubai, UAE",
    intermediarybank: "",
    intermediaryswift: "",
  },
  {
    key: "2",
    currency: "USD",
    accountnumber: "058521176422001",
    iban: "AE610240058521176422001",
    swift: "DUIBAEAD",
    address: "Main Branch Dubai, UAE",
    intermediarybank: "JP Morgan",
    intermediaryswift: "CHASUS33XXX",
  },
  {
    key: "3",
    currency: "GBP",
    accountnumber: "058521176422002",
    iban: "AE340240058521176422002",
    swift: "DUIBAEAD",
    address: "Main Branch Dubai, UAE",
    intermediarybank: "CREDIT SUISSE AG",
    intermediaryswift: "CRESCHZFARS",
  },
  {
    key: "4",
    currency: "EUR",
    accountnumber: "058521176422003",
    iban: "AE070240058521176422003",
    swift: "DUIBAEAD",
    address: "Main Branch Dubai, UAE",
    intermediarybank: "BARCLAYS BANK",
    intermediaryswift: "BARCGB22XXX",
  },
  {
    key: "5",
    currency: "",
    accountnumber: "",
    iban: "",
    swift: "",
    address: "",
    intermediarybank: "DEUTSCHE BANK FRANKFURT",
    intermediaryswift: "DEUTDEFFXXX",
  },

  {
    key: "6",
    currency: "KWD",
    accountnumber: "058521176422008",
    iban: "AE660240058521176422008",
    swift: "DUIBAEADXXX",
    address: "Dubai International City Branch",
    intermediarybank: "",
    intermediaryswift: "",
  },
  {
    key: "7",
    currency: "SAR",
    accountnumber: "058521176422009",
    iban: "AE390240058521176422009",
    swift: "DUIBAEADXXX",
    address: "Dubai International City Branch",
    intermediarybank: "",
    intermediaryswift: "",
  }
  
];

const Accounts_AccountFundingAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="طرق تمويل حساب التداول | نور كابيتال"
        description="Noor Capital offers multiple flexible funding options in 10 different base currencies."
      />
      <BgBeforeAr
        pageHeadingTitle="طرق تمويل حساب التداول"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="التداول"
        pageSubCategoryPath="#"
        pageName="تمويل الحساب"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            طرق تمويل الحساب
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              يُمكنك إرسال الأموال إلى حساب نور خاصتك عبر إجراء حوالة مصرفية إلى
              بنك دبي الاسلامي أو بنك دبي التجاري. يُرجى التحقق من معلومات
              الحساب المصرفي الخاص بنور كابيتال عبر تسجيل الدخول إلى خزانة ماي
              نور الخاصة لديك هنا. علمًا بأنه لا يمكن إجراء المعاملة نفسها عن
              طريق مساحة التداول، ويتعين عليك استخدام التسهيلات المصرفية خاصتك
              لتحويل الأموال. تستغرق الحوالات المصرفية المحلية والدولية فترة
              تتراوح ما بين 1 إلى 3 أيام عمل لتأكيدها، ومن ثم فإنه يتم اعتماد
              حساب التداول خاصتك بعد سداد الأموال إلى مصرفنا. يُرجى إرفاق رقم
              حساب التداول (TAN) خاصتك في نور كابيتال كرقم مرجعي لدى تحويل
              المدفوعات إلينا.
            </p>

            <p className="parjustify">
              في حالة الإيداع بعملة أخرى تختلف عن الدولار الأمريكي أو الدرهم
              الإماراتي، يُنصح بالتواصل مع المصرف خاصتك لتلقي المشور حول أسعار
              ورسوم التحويل التي قد تطرأ قبل تلقينا للأموال. وفي حال تلقينا
              للأموال بعملة تختلف عن عملة حساب التداول خاصتك، عندئذ سوف نستخدم
              أسعار الإغلاق خاصتنا لتخصيص الأموال. يُرجى الاتصال بنا عبر البريد
              الإلكتروني ops@noorcapital.ae، إذا كانت لديك أي أسئلة تتعلق
              بالمدفوعات خاصتك.
            </p>
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            حسابات نور كابيتال المصرفية
          </Title>
        </Divider>

        {/* <section className="fdb-block" style={{ padding: "30px 0" }}>
          <div className="container">
            <div className="row text-right">
              <div className="col-12 col-md-8 m-auto col-lg-6">
                <div className="fdb-box fdb-touch">
                  <Title level={4}>بنك دبي التجاري</Title>

                  <p>
                    <a href="mailto:ops@noorcapital.ae">
                      طلب تفاصيل الحساب المصرفي
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 m-auto col-lg-6 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <Title level={4}>بنك دبي الاسلامي</Title>

                  <p>
                    <a href="mailto:ops@noorcapital.ae">
                      طلب تفاصيل الحساب المصرفي
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Row className="text-right">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Title level={4} style={{ textAlign: "center" }}>
            بنك دبي التجاري
            </Title>
            <Title level={5} style={{ color: "#615d5d", textAlign: "center" }}>
              اسم الحساب : NOOR CAPITAL PSC
            </Title>
            <br />
            <Table dataSource={dataSourceTable1} columns={columnsTable1} />
          </Col>
        </Row>

        {/* <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Title level={4} style={{ textAlign: "center" }}>
            بنك دبي الإسلامي
            </Title>
            <Title level={5} style={{ color: "#615d5d", textAlign: "center" }}>
              اسم الحساب : NOOR CAPITAL PSC
            </Title>
            <br />
            <Table dataSource={dataSourceTable2} columns={columnsTable2} />
          </Col>
        </Row> */}

        <Row className="text-right">


       

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>

          <Title level={5} >
          الإيداعات:
          </Title>


          <div class="alert alert-warning" role="alert">
         	لن يتم قبول أي إيداع نقدي عبر أجهزة الصراف الآلي
        </div>

            <div class="alert alert-warning" role="alert">
            	سعر الصرف /التحويل (من درهم إماراتي إلى دولار أمريكي) لمدفوعات البطاقات (الائتمان / الخصم) هو 3.80
            </div>

            <div class="alert alert-warning" role="alert">
           	سعر الصرف /التحويل (من درهم إماراتي إلى دولار أمريكي) للتحويلات من بنك الى بنك هو 3.675
            </div>
            

            <br />

            <Title level={5} >
            السحوبات:
          </Title>
            

          <div class="alert alert-warning" role="alert">
          سعر الصرف /التحويل (من دولار أمريكي إلى درهم إماراتي) للتحويلات من بنك الى بنك هو 3.670
            </div>

          </Col>

          

        </Row>

    




        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            بطاقة الائتمان / الخصم
          </Title>
        </Divider>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{
            textAlign: "justify",
            padding: "0px 30px 0px 0px",
            fontSize: "16.5px",
          }}
        >
          <p>
            يُعتبر استخدام بطاقات الائتمان الطريقة الأسرع والأسهل لإيداع الأموال
            لدى نور كابيتال، حيث تتم إضافة المدفوعات المُصرح بها إلى حسابك على
            الفور. ويتطلب إيداع أموال في حسابك لدى نور كابيتال باستخدام بطاقة
            الائتمان، تسجيل الدخول إلى خزانة ماي نور الخاصة الآمنة وتحديد خيار
            بطاقة الائتمان. يُمكنك استخدام بطاقة مُسجلة باسمك ولحساب شخصي فقط،
            وليس لحساب تجاري أو حساب شركة. تُحظر مدفوعات الأطراف الخارجية، وهو
            ما يعني أيضًا عدم السماح لأفراد الأسرة أو الأصدقاء بإرسال الأموال
            نيابة عنك.
          </p>
        </Col>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            الإيداع
          </Title>
        </Divider>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{
            textAlign: "justify",
            padding: "0px 30px 0px 0px",
            fontSize: "16.5px",
          }}
        >
          <p>
            تمتثل نور كابيتال لمتطلبات مكافحة غسل الأموال (AML)، ورغبةً منا في
            حمايتك وحمايتنا من التعرض لأي عمليات احتيال، فقد نطالبك بتقديم
            معلومات إضافية مثل إثبات الهوية أو نسخة من كشف الحساب المصرفي أو غير
            ذلك من الوثائق وثيقة الصلة قبل معالجة أي مسحوبات. مع العلم بأنه
            دائما ما تتم إعادة أي وكل الإيداعات إلى مصدرها الأصلي الذي تلقت منه
            نور كابيتال هذه الإيداعات.
          </p>
          <p>
            {" "}
            <b>
            لسحب الأموال، يُرجى تسجيل الدخول على بوابة {" "}
              <a
                href="https://mynoor.noorcap.ae/en/traders/login"
                target="_blank"
              >
                ماي نور
              </a>{" "}
              خاصتك واتباع التعليمات:
            </b>
            <br/>
            {" "}
            <ul>
              <li>
                - قم بإجراء مسحوبات بطاقة الائتمان مباشرةً عن طريق البوابة
              </li>
              <li>
                - أرسل طلب الحوالة عن طريق البوابة، وسيتم تنفيذ العملية من خلال حسابنا المصرفي
              </li>
            </ul>
          </p>
        </Col>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default Accounts_AccountFundingAr;
