import React, {useEffect } from 'react';
import "./mainPage.css";
import globalstyle from "../../style.module.css";
import nooracademy from "../../assets/images/home/nooracademy.jpg";
import noortrends from "../../assets/images/home/noortrends.png";
import toolsimg from "../../assets/images/home/tools.jpg";
import AOS from "aos"
import sectionbg from "../../assets/images/bgeducationimg1.jpg";
// import "https://unpkg.com/aos@2.3.1/dist/aos.css"
// import "../../../node_modules/aos/dist/aos.css"

AOS.init(
  {duration: 3000,
    delay: 700,
  }
);

const MainPage4 = () => {
  // useEffect(() => {
  //   AOS.init(
  //     {duration: 3000,
  //       delay: 1000,
  //     }
  //       );
  // },);

  return (
    <>
      {/* <section className="fdb-block"> */}
    

      <section className="fdb-block" style={{ backgroundImage: `url(${sectionbg})` ,"height":"100%","backgroundPosition":"center","backgroundRepeat":"no-repeat","backgroundSize":"cover"}}>
        <div className={globalstyle.container}>
          <div
            className="row justify-content-center pb-5"
            style={{ paddingBottom: "0px !important" }}
          >
            <div className="col-12 text-center">
              <h2 className="display-6" style={{ color: "#1b75bc" }}>
                Maximize your Trading Potential with our Market and Educational
                Tools
              </h2>
            </div>
          </div>
          <div className="row text-left align-items-center pt-5 pb-md-5">
            <div className="col-10 col-md-5">
              <img
                alt="noor academy"
                className="img-fluid rounded mx-auto d-block bgim eimage"
                src={noortrends}
                style={{"opacity":"1"}}
                data-aos="fade-right"
                
              />
            </div>
            <div className="col-12 col-sm-6 col-md-5 m-md-auto">
              <h2 style={{ color: "#1b75bc" }}>Noor Trends</h2>
              <p className="lead" style={{ textAlign: "justify" }}>
                Noor Trends is the news and educational site of Noor Capital
                which provides real-time market updates, technical analysis,
                economic report, daily broadcasting and online webinars so you
                can stay ahead in the trading world. Get the latest market
                update now to begin your trading.
              </p>

              <p>
                <a href="/trading-tools/noor-trends-market-update">
                  Learn More <i className="fas fa-angle-right" />
                </a>
              </p>
            </div>
          </div>

          <div className="row text-left align-items-center pt-5 pb-md-5">
            <div className="col-10 col-sm-6 col-md-5 m-md-auto order-md-5">
              <img
                alt="noor academy"
                className="img-fluid rounded mx-auto d-block bgim eimage"
                src={nooracademy}
                data-aos="fade-left"
              />
            </div>
            <div className="col-12 col-md-5">
              <h2 style={{ color: "#1b75bc" }}>Noor Academy</h2>
              <p className="lead" style={{ textAlign: "justify" }}>
                Whether you are a complete beginner or an experienced trader,
                Noor Academy will transform you into a professional trader
                within a short period of time. Start learning the stocks market,
                forex trading and commodities today.
              </p>
              <p>
                <a href="/education/noor-academy">
                  Learn More <i className="fas fa-angle-right" />
                </a>
              </p>
            </div>
          </div>

          <div className="row text-left align-items-center pt-5 pb-md-5">
            <div className="col-10 col-md-5">
              <img
                alt="noor academy"
                className="img-fluid rounded mx-auto d-block bgim eimage"
                src={toolsimg}
                data-aos="fade-right"
              />
              {/* <Tool /> */}
            </div>
            <div className="col-12 col-sm-6 col-md-5 m-md-auto">
              <h2 style={{ color: "#1b75bc" }}>Trading Tools</h2>
              <p className="lead" style={{ textAlign: "justify" }}>
                At Noor Capital, we understand the vital part of the trader's
                success, primarily the ability to evaluate the patterns in
                trading data. To keep you updated with the latest market trends,
                we have a wide range of trading tools ranging from economic
                calendar, trading signals, technical analysis, to the daily
                market update.
              </p>
              <p>
                <a href="/trading-tools/economic-calendar">
                  Learn More <i className="fas fa-angle-right" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <style jsx>
        {`
          .pb-5,
          .py-5 {
            padding-bottom: 0px !important;
          }

          @media all and (max-width: 600px) {
            .eimage {
              padding-bottom: 20px !important;
            }
        }

        `}
      </style>
    </>
  );
};

export default MainPage4;
