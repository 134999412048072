import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import economicreport from "../../assets/images/pages/tools/economic-report.png";
import img1 from "../../assets/images/bgtoolsAr.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
'ستساعدك التقارير اليومية والأسبوعية والشهرية على اتخاذ قرارات دقيقة بشأن التطورات الحالية التي قد تؤثر على استثماراتك.',
'احصل على تحليلات اقتصادية متعمقة واستشارات دقيقة من بعض الخبراء الأكثر خبرة واعتمادًا في القطاع المالي.',
'قلل من المخاطر من خلال اتخاذ القرار الصحيح مع تقرير نور كابيتال الاقتصادي.',
'يمكن الوصول إليها من أي مكان في العالم.',
];
const { Title } = Typography;

const NoorTrends_EconomicReportAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Daily Economic Report - Noor Capital"
        description="The economic calendar provides useful information on upcoming macroeconomic events by means of pre-scheduled news announcements and government reports on economic indicators that influence the financial markets."
      />

      <BgBeforeAr
        pageHeadingTitle="ابق على اطلاع دائم ومستمر فقط مع تقرير نور كابيتال الاقتصادي"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="ادوات التداول"
        pageSubCategoryPath="#"
        pageName="التقارير الاقتصادية"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          التقارير الاقتصادية
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              <b>
              اتخذ قرارات سريعة ودقيقة بفضل آخر الأخبار والمعلومات من نور كابيتال
              </b>
            </p>

            <p className="parjustify text-right">
            انضم إلينا يوميًا للحصول على آخر الأخبار والاتجاهات والتطورات في الاقتصاد العالمي التي يمكن أن تؤثر بشكل مباشر على استثمارك وتداولك. سيساعدك تقرير نور كابيتال الاقتصادي على تحليل السوق واكتساب منظور فريد من خلال المعلومات المتعلقة بالاتجاهات الاقتصادية والعملات وآخر أخبار القطاع المالي في العالم.


            </p>

            <p className="parjustify text-right">
            مع تقرير نور كابيتال الاقتصادي، احصل على أحدث البيانات والتحليلات الفنية التي ستساعدك على التخطيط لتجربة تداول سلسة. تعرف على كيفية الاستفادة القصوى من تأرجح السوق والسياسات الحكومية والمؤشرات الاقتصادية والأحداث العالمية.
            </p>

            
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={economicreport}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{color: "#1b75bc"}}>
          الميزات والفوائد
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              احصل على إلى تقرير نور كابيتال الاقتصادي الآن 
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/en/category/economic-reports/"
                  target="_blank"
                >
                  انقر هنا
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default NoorTrends_EconomicReportAr;
