import React from "react";

import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";

const Tools_RiskDisclosureAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Risk Disclosure - Noor Capital"
        description="Trading on Margin carries a high level of risk to your capital, and you can lose more than your initial deposit. Please read the entire risk warning before opening an account with Noor Capital."
      />

      <BgBeforeAr
        pageHeadingTitle="تحذير المخاطر"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="تحذير المخاطر"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>الإفصاح عن المخاطر </h4>
              <p className={globalstyle.parjustify}>
              <b>يرجى الاطلاع على تحذيرات المخاطر المحتملة وشروط العمل التي تحتاج إلى معرفتها قبل فتح حساب مع نور كابيتال</b>
              </p>
              <p className={globalstyle.parjustify}>
              يتم تداول العملات الأجنبية (فوركس، سوق العملات الأجنبية) وعقود الفروقات (CFD) على العملات أو السلع أو المؤشرات أو الأسهم بنظام الهامش. وقد ينطوي التداول في هذه المنتجات على درجة عالية من المخاطرة التي قد تتناسب مع جميع المستثمرين. قبل أن تقرر البدء في تداول أدوات الفوركس / عقود الفروقات التي تقدمها شركة نور كابيتال ش.م.ع، يجب أن تضع في الاعتبار أهدافك ووضعك المالي الحالي واحتياجاتك ومستوى خبرتك.
              </p>
              <p className={globalstyle.parjustify}>
              يُصنف التداول بنظام الهامش كأحد الممارسات التي تنطوي على درجة عالية من المخاطرة التي يتعرض لها رأس المال الخاص بك حيث يمكن أن تخسر أكثر من ودائعك الأولية. يرجى قراءة تحذير المخاطر الكامل قبل فتح حساب مع نور كابيتال. الصفقات ذات الهامش هي عمليات تداول تستند إلى حركة السعر لمنتج مالي أساسي يتم تسويته على أساس الفرق بين سعر الافتتاح وسعر إغلاق الصفقة.
              </p>
              <p className={globalstyle.parjustify}>
              وتُعدالعملات الأجنبية (فوركس، سوق العملات الأجنبية) وعقود الفروقات (CFDs) على العملات أو السلع أو المؤشرات أو الأسهم من المنتجات التي يتم تداولها بنظام الهامش وبالتالي ينطوي التداول فيها على قدر كبير من المخاطرة التي قد لا يتناسب مع جميع المستثمرين. قبل اتخاذ قرار بتداول أدوات الفوركس / العقود مقابل الفروقات التي تقدمها نور كابيتال ش.م.ع، يجب أن تضع في الاعتبار أهدافك ووضعك المالي واحتياجاتك ومستوى خبرتك. يجب ألا تتداول في أي منتج بنظام الهامش ما لم تفهم تمامًا جميع المخاطر التي تنطوي عليها عملية التداول ومن الموارد ما يكفي لتغطية الصفقات حال حدوث أي حركة عكسية في سعر المنتج. ستتمكن بعد ذلك من الوفاء بالالتزامات المالية المطلوبة منك فيما يتعلق بمدفوعات الهامش والخسائر.
              </p>
              <p className={globalstyle.parjustify}>
              من المحتمل أن تتعرض لخسارة بعض أو كل أموالك المودعة. لذلك، لا ينبغي أن لا تخاطر برأس مال لا يمكنك تحمل خسارته. يجب أن تكون على دراية بجميع المخاطر المرتبطة بالتداول بنظام الهامش. توصي شركة نور كابيتال ش.م.ع بطلب المشورة من مستشار مالي منفصل / مستقل. يعتمد التداول بالهامش على الرافعة المالية التي تسمح لك بتنفيذ صفقات كبيرة من خلال استثمار مبلغ صغير فقط من المال كهامش. إذا تحرك السعر لصالحك، يمكنك زيادة أرباحك بشكل كبير. ومع ذلك، حتى تلك الحركة الصغيرة في السعر غير الملائم لك يمكن أن تؤدي إلى خسائر كبيرة. قد يُطلب منك بعد ذلك إيداع هامش إضافي على الفور لإبقاء مراكزك مفتوحة، أنت مسؤول عن أي خسائر قد تحدث إذا تم إغلاق مراكزك.
              </p>
              <p className={globalstyle.parjustify}>
              لا يمكن فتح أو إغلاق جميع التداولات على مدار 24 ساعة في اليوم حيث يخضع الكثير منها لمواعيد فتح وإغلاق صارمة قد تتغير حسب السوق. يمكنك العثور على هذه المعلومات في نظم معلومات السوق (MIS) المتوفرة على الإنترنت. تعتمد طلباتك / صفقاتك على أسعارنا، لا أسعار الصرف. واعتمادًا على السوق، تعتمد أسعارنا عادةً على سعر الصرف ولكن يمكن أن تتغير بعيدًا عن الرسوم الأساسية لأسباب مختلفة.
              </p>
              <p className={globalstyle.parjustify}>
              هناك مخاطر مرتبطة بأنظمة التداول عبر الإنترنت، على سبيل المثال لا الحصر، تعطل البرامج والأجهزة وانقطاع الإنترنت. لا تتحكم نور كابيتال في قوة الإشارة أو استقبالها أو توجيهها عبر الإنترنت أو تكوين أجهزتك أو موثوقية اتصالها، ولا يمكن أن تكون مسؤولة عن أعطال الاتصال أو الخلل أو التأخير عند التداول عبر الإنترنت.
              </p>
              <p className={globalstyle.parjustify}>
              يخضع محتوى هذا الموقع للتغيير في أي وقت دون سابق إنذار ويتم توفيره لغرض وحيد هو مساعدة المتداولين في اتخاذ قرارات استثمارية مستقلة. اتخذت نور كابيتال تدابير كافية لضمان دقة المعلومات الموجودة على موقعها الإلكتروني. ومع ذلك، لا يمكننا ضمان دقتها بشكل كامل. لن نتحمل المسؤولية عن أي خسارة أو ضرر قد ينشأ بشكل مباشر أو غير مباشر عن المحتوى أو عدم قدرتك على الوصول إلى الموقع.
              </p>
              <p className={globalstyle.parjustify}>
              يجب ألا تعتمد على أي من محتويات الموقع وأن تفسرها على أنها توصيات شخصية. أي أخبار أو آراء أو بحث أو تحليل أو أسعار أو غيرها من المعلومات الواردة على موقع نور كابيتال صُممت لتزويدك بتعليقات عامة عن السوق ولا تعتبر توصيات أو استشارات استثمارية. نحن لا نتحمل المسؤولية عن أي خسارة أو ضرر أو تراجع في الأرباح قد تنشأ بشكل مباشر أو غير مباشر من استخدام أو الاعتماد على هذه المعلومات.
              </p>


              
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Tools_RiskDisclosureAr;
