import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import aboutfiguresbg from "../../assets/images/pages/aboutfiguresbg.jpg";
import mission from "../../assets/images/pages/mission.svg";
import vision from "../../assets/images/pages/vision.svg";
import objectives from "../../assets/images/pages/objectives.svg";
import security from "../../assets/images/pages/security.svg";
import innovation from "../../assets/images/pages/innovation.svg";
import support from "../../assets/images/pages/support.svg";

import relationships from "../../assets/images/pages/relationship.svg";
import trust from "../../assets/images/pages/trust.svg";
import teamwork from "../../assets/images/pages/teamwork.svg";
import social  from "../../assets/images/pages/social.svg";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";

const AboutUsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="About Noor Capital - Leading Financial Broker in Dubai"
        description="About Noor Capital - Leading Financial Broker in Dubai"
      />

      <BgBeforeAr
        pageHeadingTitle="نحن نعزز الاستثمارات"
        pageHeadingDescription="نقدم لك الحلول الأكثر ابتكارًا وقوة لجميع احتياجاتك المالية "
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="نظرة عامة"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div className="row">
            <div className="col text-right">
              <h3 style={{ color: "#1b75bc" }}>حلول 360 درجة لاستثماراتك</h3>
              <p className={globalstyle.parjustify}>
                نور كابيتال هي شركة وساطة استثمارية معتمدة عالميًا. مع أحدث
                المنصات القوية، نقدم تسهيلات تداول سلسة للمستثمرين الأفراد
                والمؤسسات لدينا. تأسست نور كابيتال في البداية عام 2005، وهى
                منظمة من قبل هيئة
                الأوراق المالية والسلع. لدينا حضور عالمي مع فريق ذو خبرة عالية
                ومعتمد لمساعدتك في استثماراتك.
              </p>
              <p className={globalstyle.parjustify}>
                في نور كابيتال، نؤمن بتزويد عملائنا بالحلول المالية الأكثر
                موثوقية وتقدماً ومصممة خصيصاً والتي تعكس أهدافهم وتزودهم بمستقبل
                آمن. من خلال منهجنا الذي يركز على العملاء حيث نقدم لعملائنا خدمة
                عملاء مخصصة على مدار الساعة.
              </p>
              <p className={globalstyle.parjustify}>
                شركة نور كابيتال مسجلة لدى دائرة التنمية الاقتصادية في أبوظبي،
                شركة مساهمة خاصة منظمة من قبل هيئة الأوراق المالية والسلع؛ وهذا
                يجعلنا أكثر مؤسسات الاستثمار اعتمادًا وشريك التمويل المفضل
                لتنفيذ الخدمات المالية المختلفة وإدارة استثماراتك.
              </p>
            </div>
          </div>

                  
          <div className="row text-center justify-content-center mt-5">
            <div className="col-12 col-sm-4 col-xl-3 m-md-auto">
              <img
                alt="mission"
                className="fdb-icon"
                src={mission}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>الرؤية</strong>
              </h3>
              <p className="parjustify">
                تتمثل رؤيتنا في أن نكون الشركة الاستثمارية الأكثر ثقة والرائدة
                في دول مجلس التعاون الخليجي بحلول عام 2025 وأن نكون الأكثر
                تفضيلاً في الشرق الأوسط بحلول عام 2030. <br />
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="vision"
                className="fdb-icon"
                src={vision}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>الرسالة</strong>
              </h3>
              <p className="parjustify">
                مهمتنا هي خلق ثروة لعملائنا ومساهمينا من خلال توفير حلول
                استثمارية مبتكرة.
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="objectives"
                className="fdb-icon"
                src={objectives}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>الأهداف </strong>
              </h3>
              <p className="parjustify">
                أن نتميز بالمنتجات الإبداعية والخدمات عالية الجودة، تنمية
                الأعمال وتعزيز القيمة الحقيقية للشركة بنسبة 35٪ على الأقل خلال
                السنوات الخمس القادمة ، خلق قيمة للمساهمين الحاليين من خلال
                الحفاظ على مكاسب كبيرة في قيمة الشركة.
              </p>
            </div>
          </div>
        

        </section>
      </div>
      {/* 
      <section
        className="fdb-block bg-dark"
        style={{ backgroundImage: `url(${aboutfiguresbg})` }}
      >
        <div className="row">
          <div className="col text-center">
            <h1 style={{ color: "#1b75bc" }}>نور كابيتال</h1>
          </div>
        </div>

        <div className={globalstyle.container}>
          <div className="row text-center justify-content-sm-center no-gutters">
            <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto">
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}> 2005</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Establishment Year
                </p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Padding: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}> Capital</p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Padding: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Total Foreign Investment
                </p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Padding: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Trading Platforms
                </p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Padding: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  International Awards
                </p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Margin: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Renowned Brokers{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
 */}

     

      {/* <div className={globalstyle.container}>
        <section className="fdb-block">
          <div className="row">
            <div className="col text-right">
              <h2 style={{ color: "#1b75bc" }}>القيم الأساسية</h2>
              <p className={globalstyle.parjustify}>
                <span>
                  <b>العلاقات هي الأساس:</b>
                </span>{" "}
                <br />
                نحن نسعى باستمرار لتحديد فرص العلاقات الجديدة وتعزيز العلاقات
                القائمة مع عملائنا ومساهمينا. حيث تشكل العلاقات حجر الأساس لجميع
                أنشطة أعمالنا.
              </p>

              <p className={globalstyle.parjustify}>
                <span>
                  <b>ابتكار:</b>
                </span>{" "}
                <br />
                نسعى جاهدين لخلق فرص جديدة من خلال الابتكار المستمر في جميع
                مجالات عملنا. و تشجيع الأفراد والمؤسسات الذين يعملون معنا
                باستمرار على تحدي الوضع الراهن وإظهار الإبداع في كل ما نقوم به.
              </p>

              <p className={globalstyle.parjustify}>
                <span>
                  <b>الثقة:</b>
                </span>{" "}
                <br />
                نحن نقدر الثقة التي منحنا إياها عملائنا. ونحن كمنظمة نظهر أقصى
                درجات النزاهة ونعمل باستمرار على تعزيز هذه الثقة بكل طريقة ندير
                بها أعمالنا.
              </p>

              <p className={globalstyle.parjustify}>
                <span>
                  <b>العمل بروح الفريق الواحد:</b>
                </span>{" "}
                <br />
                نحن نولي أهمية كبيرة للعمل الجماعي كطريقة لممارسة الأعمال
                التجارية ، وبالتالي فإننا نتعاون باستمرار مع العملاء وشركاء
                الأعمال والموظفين لتحقيق أهداف ومكافآت مشتركة للجميع.
              </p>

              <p className={globalstyle.parjustify}>
                <span>
                  <b>المسؤولية اجتماعية:</b>
                </span>{" "}
                <br />
                نبحث باستمرار عن فرص للمساهمة في التنمية الاقتصادية وتحسين جودة
                الحياة لعملائنا ومساهمينا وكذلك المجتمع ككل.
              </p>
            </div>
          </div>
        </section>
      </div> */}

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>
          <div className="row text-center">
            <div className="col-12">
              <h2 style={{ color: "#1b75bc" }}>القيم الأساسية</h2>
            </div>
          </div>

          <div className="row text-center justify-content-center mt-5">
            <div className="col-12 col-sm-4 col-xl-3 m-md-auto">
              <img
                alt="mission"
                className="fdb-icon"
                src={relationships}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>العلاقات هي الأساس</strong>
              </h3>
              <p className="parjustify">
                نحن نسعى باستمرار لتحديد فرص العلاقات الجديدة وتعزيز العلاقات
                القائمة مع عملائنا ومساهمينا. حيث تشكل العلاقات حجر الأساس لجميع
                أنشطة أعمالنا.{" "}
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="vision"
                className="fdb-icon"
                src={innovation}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>الابتكار</strong>
              </h3>
              <p className="parjustify">
                نسعى جاهدين لخلق فرص جديدة من خلال الابتكار المستمر في جميع
                مجالات عملنا. و تشجيع الأفراد والمؤسسات الذين يعملون معنا
                باستمرار على تحدي الوضع الراهن وإظهار الإبداع في كل ما نقوم به.
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="objectives"
                className="fdb-icon"
                src={trust}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>الثقة</strong>
              </h3>
              <p className="parjustify">
                نحن نقدر الثقة التي منحنا إياها عملائنا. ونحن كمنظمة نظهر أقصى
                درجات النزاهة ونعمل باستمرار على تعزيز هذه الثقة بكل طريقة ندير
                بها أعمالنا.
              </p>
            </div>
          </div>

          <div className="row text-center justify-content-center mt-5">
            <div className="col-12 col-sm-4 col-xl-3 m-md-auto">
              <img
                alt="mission"
                className="fdb-icon"
                src={teamwork}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>العمل بروح الفريق الواحد</strong>
              </h3>
              <p className="parjustify">
                نحن نولي أهمية كبيرة للعمل الجماعي كطريقة لممارسة الأعمال
                التجارية ، وبالتالي فإننا نتعاون باستمرار مع العملاء وشركاء
                الأعمال والموظفين لتحقيق أهداف ومكافآت مشتركة للجميع.
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="objectives"
                className="fdb-icon"
                src={support}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>المساندة والمعرفة</strong>
              </h3>
              <p className="parjustify">
                نؤمن بأهمية الثقافة المالية الاقتصادي والتوعية بنشاط التداول
                لحماية المستثمرين من الأخطاء الشائعة، مزودين عملائنا بكافة أدوات
                الدعم على مدار الساعة من خلال مركز الأبحاث والتطوير الذي يقدم
                كافة الأدوات المتقدمة والتحليلات الفنية والتقارير الاقتصادية.
              </p>
            </div>

            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="vision"
                className="fdb-icon"
                src={social}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>المسؤولية اجتماعية</strong>
              </h3>
              <p className="parjustify">
              نبحث باستمرار عن فرص للمساهمة في التنمية الاقتصادية وتحسين جودة الحياة لعملائنا ومساهمينا وكذلك المجتمع ككل
              </p>
            </div>
            
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUsAr;
