import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgeducationbanner6.jpg";

import mt4 from "../../assets/images/mt4.png";
import stock from "../../assets/images/stock.png";
import chart from "../../assets/images/chart.png";
import WhyNoor from "./whyNoor";
import NoorAcademyEnrollFrom from "../forms/noorAcademyEnrollForm.js";

const style = { padding: "50px 0", alignSelf: "justify" };

const who = [
  "Beginners who wish to make it big in forex trading",
  "Experienced traders who want to enhance their knowledge",
  "Marketing Partnership Program",
  "People who wish to make a full-time career in Forex Trading",
];
const notes = [
  "Proven strategies to maximize profits and minimize risk while investing.",
  "The right time to buy and sell.",
  "How to profit in any market situation.",
  "How to diversify your portfolio to reduce risk.",
  "Strategies used by successful traders.",
  "Avoiding common mistakes of traders.",
];
const { Title } = Typography;

const NoorAcademy = () => {
  return (
    <div>
      <PageseMetaTags
        title="Noor Academy - Noor Capital"
        description="Enroll Now And Learn To Invest Skillfully, Smarter, and With Confidence Like The Pros"
      />
      <BgBefore
        pageHeadingTitle="Do You Want To Become A Professional Forex Trader?"
        pageHeadingDescription="Join Noor Capital's Forex Trading Course And Access The World Of Unlimited Opportunities"
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Education"
        pageSubCategoryPath="#"
        pageName="Noor Academy"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Join Now and Learn To Invest Skillfully, Smarter, and With
            Confidence Like a Professional
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}
          style={{
            textAlign: "justify",
            padding: "5px 30px 0px 0px",
            fontSize: "16.5px",
          }}>
            <p className="parjustify">
              Whether you are a beginner or an experienced trader, Noor Academy
              course will transform you into a professional trader within a
              short time.
            </p>

            <p className="parjustify">
              With our state of the art academy, you will learn from market
              leaders the best practice for investing in the stock market,
              trading forex, and commodities with confidence.
            </p>

            <Divider orientation="left">
              <Title level={4} style={{ color: "#1b75bc" }}>
                Who Should Join This Course?
              </Title>
            </Divider>

            <List
              size="small"
              bordered
              dataSource={who}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br/>

<p><b>Note:</b> Admission is FREE for clients that have a minimum deposit of $1,500 on their account.</p>

          </Col>

          <Col 
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            
          >
            <NoorAcademyEnrollFrom />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className={globalstyle.container}>
            <div className="row text-right align-items-center">
              <div className="col-12 col-lg-6 col-xl-5 m-lg-auto text-left">
                <Title level={3} style={{ color: "#1b75bc" }}>
                  Course Module
                </Title>
                <p className="h6 pb-xl-4">
                  Whether you are an experienced trader or a beginner, our
                  course is divided into two modules to help both. Here are the
                  two modules you can choose as per your knowledge and
                  understanding of Forex Trading.
                </p>
                <div className="row pt-5">
                  <div className="col-3">
                    <img alt="nt" className="img-fluid" src={stock} />
                  </div>
                  <div className="col-9">
                    <Title level={4} style={{ color: "#1b75bc" }}>
                      Module One: Introduction To Forex Trading
                    </Title>
                    <p className="parjustify">
                      This module is primarily for beginners who want to explore
                      the forex trading world. This course will teach the
                      fundamentals of Forex Trading, how the market operates, an
                      introduction to standard terms used in trading, and money
                      management. At the end of this module, you will master
                      forex trading and be ready to start immediately with
                      confidence.
                    </p>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-9">
                    <Title level={4} style={{ color: "#1b75bc" }}>
                      Module Two: Advanced Forex Trading
                    </Title>
                    <p className="parjustify">
                      Advanced Forex Trading is for experienced traders and
                      beginners who have completed the introductory course.
                      Module two will introduce you to the most advanced
                      strategies and techniques used in the trading industry
                      that will help you maximize profits and minimize risk.
                    </p>
                    <p className="parjustify">
                      The module will introduce you to successful traders' best
                      practices and familiarize you with the advanced trading
                      terms. This module's main idea is to equip you with the
                      best tools to help you stay ahead of your trading.
                    </p>
                  </div>
                  <div className="col-3">
                    <img
                      alt="gtn"
                      className="img-fluid"
                      src={chart}
                      style={{ paddingTop: "50px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-7 col-sm-4 m-auto pt-5 pt-md-0">
                <img alt="mt4" className="img-fluid" src={mt4} />
              </div>
            </div>
            <Divider orientation="left">
              <Title level={3} style={{ color: "#1b75bc" }}>
                What Will You Learn?
              </Title>
            </Divider>

            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
          </div>
        </section>
      </div>
      <br />

      <section className="fdb-block">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 style={{ color: "#1b75bc" }}>
                <strong>Noor Capital Academy</strong>
              </h1>
              <p className="parjustify">
                Once you are part of Noor Academy, we make sure you step out as
                a professional trader with all the required knowledge and tools
                to guide you throughout your trading journey. We have a complete
                range of tools during and after the course to keep you updated
                with the latest market news, information, strategies, and expert
                advice.
              </p>
            </div>
          </div>

          <div className="row text-left justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h3 style={{ color: "#1b75bc" }}>Nanodiploma</h3>
              <p className="lead">
                Whether you are a beginner or an experienced trader, the
                Nanodiploma course will transform you into a professional
                trader.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <h3 style={{ color: "#1b75bc" }}>Webinars</h3>
              <p className="lead">
                No matter where you are, our webinars are designed to help you
                learn online from the most accredited and experienced traders.
              </p>
            </div>
          </div>

          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>Workshops</h3>
              <p className="lead">
                We conduct regular workshops to help students have practical
                training with our world-renowned experts.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>Daily Broadcasting</h3>
              <p className="lead">
                You can access our daily, weekly, and monthly broadcasts that
                will help you stay up to date and make the right trading
                decisions.
              </p>
            </div>
          </div>

          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>Daily Market Updates</h3>
              <p className="lead">
                Our daily market updates contain the latest market news,
                technical analysis, and articles to help you trade with
                confidence.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>Economic Report</h3>
              <p className="lead">
                Stay updated with our regular economic report with the latest
                news from the UAE and worldwide that directly impacts financial
                market.
              </p>
            </div>
          </div>

          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>Trading Tools</h3>
              <p className="lead">
                You will never miss any market opportunity and will always ahead
                of your competitors
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>Glossary</h3>
              <p className="lead">
                Our glossary provides all the definitions you need of words used
                in the trading industry
              </p>
            </div>
          </div>
        </div>
      </section>

      <br />

      <style jsx>
        {`
          .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
            background-color: #dce0e4;
          }
        `}
      </style>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                If You Are Serious About Becoming A Professional Forex Trader,
                Your First Journey Starts Here
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Register Live Account
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoor />
    </div>
  );
};

export default NoorAcademy;
