import React, { useEffect } from "react";

const PriceTickerStocks = () => {
  useEffect(() => {
    if (document.getElementById("chart")) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [
          {
            "description": "TSLA",
            "proName": "NASDAQ:TSLA"
          },
          {
            "description": "AAPL",
            "proName": "NASDAQ:AAPL"
          },
          {
            "description": "NVDA",
            "proName": "NASDAQ:NVDA"
          },
          {
            "description": "AMZN",
            "proName": "NASDAQ:AMZN"
          },
          {
            "description": "MSFT",
            "proName": "NASDAQ:MSFT"
          },
          {
            "description": "META",
            "proName": "NASDAQ:META"
          },
          {
            "description": "GOOGL",
            "proName": "NASDAQ:GOOGL"
          },
          {
            "description": "ADBE",
            "proName": "NASDAQ:ADBE"
          },
          {
            "description": "PYPL",
            "proName": "NASDAQ:PYPL"
          },
          {
            "description": "AMD",
            "proName": "NASDAQ:AMD"
          }
        ],
        showSymbolLogo: true,
        colorTheme: "dark",
        isTransparent: false,
        displayMode: "adaptive",
        largeChartUrl: "https://mynoor.noorcap.ae/en/traders/login",
        locale: "en",
        


        
      });

      document.getElementById("chart").append(script);
    }
  }, []);

  return <div id="chart"></div>;
};

export default PriceTickerStocks;
