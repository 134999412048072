import React from "react";
import {Link} from "react-router-dom";
import { Tooltip } from 'antd';
import stickyButtons from "./stickyButtonsAr.module.css";

const tooltipposition = "right"

const StickyButtonsAr = () => {
  return( 

<div className={stickyButtons.stickyButtons} style={{"zIndex":"999","opacity":"0.8"}}>
    
<Tooltip placement={tooltipposition} title={"الدعم الفني"}><Link to="/ar/support/technical-support" className={stickyButtons.support}><i class="fa fa-question"></i></Link> </Tooltip>
<Tooltip placement={tooltipposition} title={"طلب معاودة الإتصال"}><Link to="/ar/support/call-back-request" className={stickyButtons.call}><i class="fa fa-phone"></i></Link> </Tooltip>
<Tooltip placement={tooltipposition} title={"المدونة"}><Link to="/ar/blog" className={stickyButtons.blog}><i class="fa fa-blog"></i></Link> </Tooltip>
</div>

    )
    
  };
  
  export default StickyButtonsAr;