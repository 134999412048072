import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL, POSTS } from "../../constants";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import ReactReadMoreReadLess from "react-read-more-read-less";
import img1 from "../../assets/images/bgblog.jpg";

export default function Blog() {
  const [posts, setPosts] = React.useState([{}]);
  const mainCat = 2;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  };

  React.useEffect(() => {
    // Note here we set the main_cat and sub_cat manually
    axios
      .get(
        `${BASE_URL}${POSTS}?main_cat=${mainCat || ""}`,
        {
          headers: headers,
        }
      )
      .then((posts) => {
        console.log(posts.data);
        setPosts(posts.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <PageseMetaTags
        title="Noor Capital / Blog"
        description="Noor Capital / Blog"
      />

      <BgBefore
        pageHeadingTitle="Noor Capital  / Blog"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Noor Capital"
        pageSubCategoryPath="#"
        pageName="Blog"
      />

      <br />

      <section className="fdb-block">
        <div className="container">
          {posts.map((post) => (
            <>
              <div className="row justify-content-center">
                <div className="row justify-content-center mb-5">
                  <div className="col-12">
                  <Link to={`/nc-blog/${post.id}`}>
                      <img
                        alt="Noor Capital Blog"
                        className="img-fluid mt-6"
                        // Note he we're using the backend url to get the images
                        src={`${BASE_URL}${post.featured_image}`}
                      />
                    </Link>
                  </div>
                </div>

                <div className="col text-left">
                  <h1 style={{ color: "#1b75bc" }}>{post.title}</h1>
   

                  {/* <p className="lead">

                  {post.description && post.description.substr(3, 300)} ...
                  
                  </p> */}



                  <p
                    className="lead"
                    style={{"textAlign":"justify"}}
                    dangerouslySetInnerHTML={{
                      __html: post.description && post.description.substr(0, 350) ,
                    }}
                  />

                  {/* <p
                    className="lead"
                    dangerouslySetInnerHTML={{
                      __html: post.description,
                    }}
                  /> */}

                  
                  {/* <ReactReadMoreReadLess
                    charLimit={200}
                  >
                    {post.description}
                  </ReactReadMoreReadLess> */}

                  <div className="row no-gutters">
                    <div className="col-12 col-sm-8 col-md-3 m-auto">
                      <p style={{ textAlign: "left" }}>By: {post.author}</p>
                    </div>
                    <div className="col-12 col-sm-8 col-md-3 m-auto pt-3 pt-md-0">
                      <p style={{ textAlign: "left" }}></p>
                    </div>
                    <div className="col-12 col-sm-8 col-md-3 m-auto pt-3 pt-md-0">
                      <p style={{ textAlign: "right" }}>
                        <Link to={`/nc-blog/${post.id}`}>
                          Read More <i className="fas fa-angle-right" />
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <br />
            </>
          ))}
        </div>


        <style jsx>
        {`
          .ref::after {
            font-weight: bold;
            color: navy;
            content: ".... ";
          }
        `}
      </style>

      </section>

      <br />
    </div>
  );
}
