import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgnoortrader.jpg";
import { Link } from "react-router-dom";

import noortreaderdesktop from "../../assets/images/pages/trading/noortreaderdesktop.png";
import WhyNoor from "./whyNoor";
import OpenFxDemoForm from "../forms/openFxDemoForm.js";
import NtVideo from "./ntVideo.js";

const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader = () => {
  return( <div>
          <PageseMetaTags
                title="NoorTrader - Noor Capital"
                description="NoorTrader platform allows you to buy, sell, store, and manage physical bullion in real-time in the global market place."
            />
            <BgBefore 
            pageHeadingTitle="We Revolutionized The Way You Trade Gold And Silver"
            pageHeadingDescription="NoorTrader Offers You 360° Bullion Trading Solution"
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="NoorTrader"
      />



<div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}></Title>
        </Row>
        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Gold and Silver are the most respected among metals across the world. These are the metals you can count on in tough times. With us, you can secure your future more confidently and trade physical bullion globally through NoorTrader platform.
            </p>
            <br/>
            <p className="parjustify">
            NoorTrader platform allows you to buy, sell, store, and manage bullion in real-time in the global market place. With 24/7 access to their investments, investors can be rest assured with their bullion's security, storage and insurance.
            </p>
            <br/>
            <p className="parjustify">
            Built in a highly secured and encrypted environment, you can access NoorTrader either from any web browser or you can download the NoorTrader app on your smartphones.
            </p>
           
            

            <br/>

            <Link
                  to="/physical-trading-platforms/open-noor-trader-demo"
                  className="btn btn-primary btn-lg ml-4"
                  style={{ width: "250px",     "marginRight": "35px" ,  marginTop: "25px" }}
                >
                 Open A Demo Account
                </Link>
              
              <Link
                  to="/physical-trading-platforms/open-noor-trader-live"
                  className="btn btn-success btn-lg"
                  style={{ width: "250px" ,  marginTop: "25px"}}
                >
                  Open A Live Account
                </Link>
                
              

          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <NtVideo />
          </Col>
        </Row>
      </div>
      <br />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={3}>
              Noortrader Allows You To Trade In Physical Metals With World-Class Storage And Delivery.
              </Title>
              
            </div>
          </div>
          <div className="row text-left justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h4 style={{ color: "#1b75bc" }}>
              User-Friendly Platform
              </h4>
              <p className="lead">
              NoorTrader is an ultimate platform that allows its investors to buy, sell, and manage bullion anytime and from  anywhere, with just a click.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
            <h4 style={{ color: "#1b75bc" }}>
            Accessible 
              </h4>
              <p className="lead">
              With NoorTrader, you can access your investments 24/7 from anywhere in the world; all you need is an internet connection.
              </p>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
            <h4 style={{ color: "#1b75bc" }}>
            Highly Compatible
              </h4>
              <p className="lead">
              NoorTrader is compatible with all operating systems (Windows, Mac, and Linux) and for those who like to access it on the go; you can download the mobile app that is highly compatible with all top Android and iPhones.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
            <h4 style={{ color: "#1b75bc" }}>
            Dedicate Customer Support
              </h4>
              <p className="lead">
              Dedicated team of Noor Capital is always available to support customers 24/5 and  help them make cost-effective and correct use of  bullion.</p>
            </div>
          </div>
        </div>
      </section>

      <br />

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Your Investments Is As Valuable As The Physical Metals - Take The Right Decision Only With NoorTrader
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/physical-trading-platforms/open-noor-trader-live"
                >
                  Sign-Up Now
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>


  </div>
  )

};

export default NoorTrader;