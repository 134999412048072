import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import globalimg from "../../assets/images/pages/services/global-financial-advisory.jpg";
import img1 from "../../assets/images/bgservicesbanner2.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  "Optimizing pricing and the covenants of new financing.",
  "Accelerating fund-raising process.",
  "Bridge financing or improve leverage on existing investment.",
  "Establishing connections with banks or financial institutions with whom the corporate has no established relationships without jeopardizing existing relationships.",
  "Secure funding from countries  (in the GCC or abroad), outside  home country.",
  "An exploration of several, parallel financing options, such as syndicated loan & bond offering, or pre-IPO placement & IPO.",
];
const { Title } = Typography;

const GlobalFinancialAdvisory = () => {
  return (
    <div>
      <PageseMetaTags
        title="Global Financial Advisory Service | Noor Capital"
        description="Noor Capital is the best financial advisory service that offers financial planning and investment management for a low fee."
      />

      <BgBefore
        pageHeadingTitle="Noor Capital's Global Financial Advisory Services -  Your guide to plan better financial life"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Services"
        pageSubCategoryPath="#"
        pageName="Global Financial Advisory"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Global Financial Advisory
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Noor Capital  is one of the leading global financial advisors in the UAE and across the MENA region. We provide you with a comprehensive range of strategic and financial advice in mergers, acquisitions, restructuring, and other corporate finance areas with our highly certified and experienced team.
            </p>

            <p className="parjustify">
            UAE and KSA are our main markets, but we have successfully widened our advisory wings to other MENA regions, making us one of the most trusted financial companies in the Gulf.
            </p>

            <p className="parjustify">
            We provide technical solutions and premium consultation  and management services for boards of directors of large and mid-cap companies to assist them in highly strategic and financially important transactions.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={globalimg}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Services
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Title style={{ color: "#11131b" }} level={4}>
              Debt Advisory
            </Title>

            <p className="parjustify">
            We  provide solution for raising debt financing for both conventional. Islamic companies, and individuals. Our debt advisory services are ideal for groups, a specific acquisitions, projects, refinancing of existing finance, and disposal of assets, companies, or stakes. We increase  targeted funding from banks or financial institutions, or government-related entities.</p>

            <p className="parjustify">
            Noor Capital provides corporates with swift debt advisory services, especially if you are looking for
            </p>

            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />

            <Title style={{ color: "#11131b" }} level={4}>
              Equity Capital Markets
            </Title>

            <p className="parjustify">
            Noor Capital aims at  leading the market in underwriting new terms of equity in the stock exchange markets. We strongly believe in the imperative to increase trading liquidity in the region and to attract capital. Our ambition to gain leadership in extensive initial public offerings is based on our commitment to developing unique expertise in complex transactions, strengthening our research protocols, and building track records and distribution capabilities. A critical factor in our equity underwriting effort will be to create a close working relationship between investment advisors, research analysts, and sales force coordinated by our investment banking department. 
            </p>
          </Col>
        </Row>
        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Let Professional People Guide you to Right Financial Decisions
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/about/contact-us"
                >
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>





      
      <section className="fdb-block">
          <div className="row">
            <div className={globalstyle.container}>
              

              <div className="col" style={{ padding: "15px" }}>
                <h1
                  className="display-5 text-center"
                  style={{ color: "#11131b" }}
                >
                  Other Services
                </h1>
              </div>

              <div className="row text-left">
                 
                
                <div className="col-12 col-md-8 m-auto col-lg-4">
                  <div className="fdb-box fdb-touch">
                    <h4>Asset Management</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    Noor Capital provides a solid asset management solution to institutions, high net worth individuals, and retail investors through direct.
                    </p>
                    <p>
                      <Link to="/services/asset-management">Read more</Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                  <div className="fdb-box fdb-touch">
                    <h4>Investment</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    Noor Capital offers exclusive investment opportunities to regional investors, helping them to create and manage their investments safely.
                    </p>
                    <p>
                      <Link to="/services/investment">Read more</Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                  <div className="fdb-box fdb-touch">
                    <h4>Fund Administration</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    Noor Capital provides you with a highly efficient and all-inclusive Fund Administration solution, especially for setting up asset management.
                    </p>
                    <p>
                      <Link to="/services/funds-administration">Read more</Link>
                    </p>
                  </div>
                </div>
            
            
              </div>
            
            </div>
          </div>
        </section>



    
    
    </div>
  );
};

export default GlobalFinancialAdvisory;
