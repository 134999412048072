import React, { useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import classnames from "classnames";
import logo from "../../assets/images/logo.png";
import { Tooltip } from "antd";
import menuStyle from "./menuBarAr.module.css";
import globalstyle from "../../style.module.css";
import img from "../../assets/images/home/tools.jpg";
import globalimg from "../../assets/images/pages/services/global-financial-advisory-menu.jpg";
import assetimg from "../../assets/images/pages/services/portfolio-menu.jpg";
import investmentimg from "../../assets/images/pages/services/investment-menu.jpg";
import fundsimg from "../../assets/images/pages/services/funds-administration-menu.jpg";
import mobile from "../../assets/images/cta.png";
import uaeflag from "../../assets/images/uae-flag.svg";
import ukflag from "../../assets/images/uk-flag.svg";

import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";

import LanguageContext from "../../language-context";
import MobileNavAr from "./mobileNavAr";

const tooltipposition = "top";

// const languageSegment = window.location.pathname.split("/").slice(-2);

const MenuBarAr = () => {
  const location = useLocation();

  const languageSegment = location.pathname;

  let enLink = languageSegment.replace("/ar/", "/");

  // const [lan, setLan] = React.useState("LTR");

  const { language, setLanguage } = useContext(LanguageContext);

  const [cookies, setCookie, removeCookie] = useCookies(["ar"]);

  const setLan = (value) => {
    setCookie("language", value, { path: "/" });
    setLanguage(value);
  };

  return (
    <div>
      <MobileNavAr />
      <div
        className={classnames(
          menuStyle["ncmenu-header"],
          menuStyle["header-shadow"],
          menuStyle["ncmenu-mega-menu"],
          menuStyle["mega-menu-fullwidth"]
        )}
      >
        <div className={globalstyle.container}>
          {/* header */}
          <div className={classnames(menuStyle["ncmenu-header-container"])}>
            {/*Logo*/}

            {/*Navigation menu*/}
            {/* menu-caret */}
            <nav
              className={classnames(
                menuStyle["ncmenu-menu"],
                menuStyle["separate-line"],
                menuStyle["submenu-top-border"]
              )}
            >
              <ul>
                {/* about */}
                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="/about/about-us">من نحن </Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* Column */}
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/about/about-us">نظرة عامة</Link>
                                </li>
                                <li>
                                  <Link to="/ar/about/chairman-message">
                                    رسالة رئيس مجلس الإدارة
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/about/board-members">
                                    أعضاء مجلس الإدارة
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/about/management-team">
                                    الإدارة التنفيذية
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/about/license-regulations">
                                    التراخيص واللوائح
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/about/why-noor-capital">
                                    لماذا تختارنا
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/terms-conditions">
                                    الشروط والأحكام
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-tools/risk-disclosure">
                                    تحذير المخاطر
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/about/careers">الوظائف</Link>
                                </li>
                                <li>
                                  <Link to="/ar/about/contact-us">
                                    تواصل معنا
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Services */}
                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="#">الخدمات</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* Column */}
                          <div className="col-md-6 col-lg-3">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <div className="mega-menu-thumbnail">
                                <Link to="/ar/services/global-financial-advisory">
                                  <img
                                    src={globalimg}
                                    alt="الإستشارات المالية العالمية"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </Link>
                              </div>
                              <h5
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                                style={{
                                  paddingTop: "15px",
                                  paddingRight: "15px",
                                }}
                              >
                                <Link to="/ar/services/global-financial-advisory">
                                  الإستشارات المالية العالمية
                                </Link>
                              </h5>
                            </div>
                          </div>
                          {/* Column */}
                          <div className="col-md-6 col-lg-3">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <div className="mega-menu-thumbnail">
                                <Link to="/ar/services/asset-management">
                                  <img
                                    src={assetimg}
                                    alt="إدارة الأصول"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </Link>
                              </div>
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                                style={{
                                  paddingTop: "15px",
                                  paddingRight: "15px",
                                }}
                              >
                                <Link to="/ar/services/asset-management">
                                  إدارة الأصول
                                </Link>
                              </h4>
                            </div>
                          </div>
                          {/* Column */}
                          <div className="col-md-6 col-lg-3">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <div className="mega-menu-thumbnail">
                                <Link to="/ar/services/investment">
                                  <img
                                    src={investmentimg}
                                    alt="الاستثمار المباشر"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </Link>
                              </div>
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                                style={{
                                  paddingTop: "15px",
                                  paddingRight: "15px",
                                }}
                              >
                                <Link to="/ar/services/investment">
                                  الاستثمار المباشر
                                </Link>
                              </h4>
                            </div>
                          </div>
                          {/* Column */}
                          <div className="col-md-6 col-lg-3">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <div className="mega-menu-thumbnail">
                                <Link to="/ar/services/funds-administration">
                                  <img
                                    src={fundsimg}
                                    alt="إدارة صناديق الإستثمار"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </Link>
                              </div>
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                                style={{
                                  paddingTop: "15px",
                                  paddingRight: "15px",
                                }}
                              >
                                <Link to="/ar/services/funds-administration">
                                  إدارة صناديق الإستثمار
                                </Link>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Trading */}
                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="#">التداول</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* instruments */}
                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">الأسواق</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/trading/instruments/forex">
                                    العملات
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading/instruments/precious-metals">
                                    المعادن الثمينة
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading/instruments/energies">
                                    الطاقة
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading/instruments/indices">
                                    المؤشرات
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading/instruments/shares-cfds">
                                    أسواق الأسهم
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading/instruments/bullion">
                                    السبائك
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading/instruments/cash-equities">
                                    الأسهم النقدية
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* mt4 */}
                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="/ar/trading-platforms/metatrader4-platform">
                                  تداول العملات والسلع
                                </Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <a
                                    href="https://mynoor.noorcap.ae/en/registration/signup"
                                    target="_blank"
                                  >
                                    فتح حساب حقيقي
                                  </a>
                                </li>
                                <li>
                                  <Link to="/ar/trading-platforms/mt4-open-forex-demo">
                                    فتح حساب تجريبي
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-platforms/metatrader4/mt4-mobile">
                                    منصة ميتاتريدر 4 لللهواتف
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-platforms/metatrader4/mt4-desktop">
                                    منصة ميتاتريدر 4 للحاسوب
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-platforms/metatrader4/mt4-mac">
                                    منصة ميتاتريدر 4 ماك
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-platforms/metatrader4/mt4-web-trader">
                                    ميتاتريدر للويب
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* GTN */}
                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="/ar/stocks-trading-platforms/gtn">
                                  تداول الأسهم العالمية
                                </Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/stocks-trading-platforms/open-gtn-live">
                                    فتح حساب حقيقي
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/ar/stocks-trading-platforms/open-stocks-demo">
                                    فتح حساب تجريبي
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/ar/stocks-trading-platforms/gtn-mobile">
                                    منصة GTN للهواتف
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/stocks-trading-platforms/gtn-web">
                                    منصة GTN للويب
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* NT */}
                          {/* <div className="col-md-6 col-lg-2">
                            <div className={classnames(menuStyle['mega-menu-box'])}>
                              <h4 className={classnames(menuStyle['mega-menu-heading'])}>
                                <Link to="/ar/physical-trading-platforms/noor-trader">التداول المادي</Link>
                              </h4>
                              <ul className={classnames(menuStyle['mega-menu-list'])}>
                                <li>
                                  <Link to="/ar/physical-trading-platforms/open-noor-trader-live">
                                  فتح حساب حقيقي
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/physical-trading-platforms/open-noor-trader-demo">
                                  فتح حساب تجريبي
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/physical-trading-platforms/noor-trader-mobile">
                                  منصة نور تريدر للهواتف

                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/physical-trading-platforms/noor-trader-web">
                                  نور تريدر للويب
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div> */}

                          {/* Institutional */}

                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">عملاء المؤسسات</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/institutional/request-demo">
                                    فتح حساب تجريبي
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/institutional/request-live">
                                    فتح حساب حقيقي
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* Accounts */}

                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">الحسابات</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/trading/accounts/account-funding">
                                    تمويل الحساب
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading/accounts/account-forms">
                                    مستندات فتح الحسابات
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading/accounts/trading-conditions">
                                    شروط التداول
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* img */}
                          {/* <div className="col-md-6 col-lg-3">
                            <div className={classnames(menuStyle['mega-menu-box'])}>
                              <div className="mega-menu-thumbnail">
                                <img
                                  src={mobile}
                                  alt=" Open account"
                                  style={{ maxHeight: "280px", "maxWidth":"250px" }}
                                />
                                <p>
                                  <Link
                                    className="btn btn-light"
                                    to="/ar/trading-platforms/mt4-open-forex-demo"
                                    style={{"backgroundColor":"rgb(15, 117, 188)","color":"#fff"}}
                                  >
                                    Open FX Demo account
                                  </Link>
                                </p>
                                <p>
                                  <Link
                                    className="btn btn-light"
                                    to="/ar/trading-platforms/mt4-open-forex-live"
                                    style={{"backgroundColor":"rgb(49, 166, 41)","color":"#fff"}}
                                  >
                                    Register FX Live Account
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        */}
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Partnership */}

                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="#">الشراكة</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* Column */}
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">عملاء المؤسسات</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/partnership/white-label-solutions">
                                    برنامج العلامة البيضاء
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/partnership/institutional-clients">
                                    عملاء المؤسسات
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/partnership/asset-managers">
                                    برامج مدراء الأصول
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">برنامج الشراكة </Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/partnership/marketing-partnership-program">
                                    برنامج الشراكة التسويقية
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Noor Acadmey */}
                <li>
                  <Link to="/ar/education/noor-academy">اكاديمية نور</Link>
                </li>

                {/* Trading Tools */}
                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="#">ادوات التداول</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* Column */}
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="">نور تريندز</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/trading-tools/noor-trends-online-webinar">
                                    النداوت التعليمية
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-tools/noor-trends-market-update">
                                    مستجدات الأسواق
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-tools/noor-trends-technical-analysis">
                                    التحليلات والتقارير الفنية
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-tools/noor-trends-economic-reports">
                                    التقارير الاقتصادية
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-tools/noor-trends-daily-broadcasting">
                                    غرفة البث المباشر
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">الأدوات</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/ar/trading-tools/economic-calendar">
                                    الأجندة الاقتصادية
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-tools/trading-signals">
                                    إشارات التداول
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-tools/traders-glossary">
                                    قاموس المصطحات
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/trading-tools/video-tutorials">
                                    فيديوهات تعليمية
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ar/support/faqs">
                                    الأسئلة المتكررة
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Media Center */}
                <li className="submenu-right">
                  <Link to="#">المركز الإعلامي</Link>
                  <ul>
                    <li>
                      <Link to="/ar/media-center/press-release">
                        الأخبار الصحفية
                      </Link>
                    </li>
                    <li>
                      <Link to="/ar/media-center/activities">الأنشطة</Link>
                    </li>
                    <li>
                      <Link to="/ar/media-center/awards">الجوائز</Link>
                    </li>
                  </ul>
                </li>

                {/* Blog */}
                <li>
                  <Link to="/ar/blog">المدونة</Link>
                </li>

                {/* Language */}
                <li
                  className={classnames(
                    menuStyle["nav-item"],
                    menuStyle["submenu-right"]
                  )}
                >
                  <Link
                    onClick={() => setLan("en")}
                    to={enLink}
                    style={{ color: "#1b75bc" }}
                  >
                    <span style={{ padding: "6px" }}>English</span>
                    <img
                      src={ukflag}
                      alt="EN"
                      style={{
                        borderRadius: "2px",
                        width: "25px",
                        verticalAlign: "middle",
                      }}
                    />
                  </Link>
                </li>
              </ul>
            </nav>

            <div
              className={classnames(menuStyle["logo"])}
              data-mobile-logo={logo}
              data-sticky-logo={logo}
            >
              <Link to="/ar">
                {/* <img src={logo} alt="logo" /> */}
                <img
                  src={logo}
                  alt="Noor Capital logo"
                  width="200" // The original width of your image
                  height="100" // The original height of your image
                  className="responsive-logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .responsive-logo {
            max-width: 100%; 
            height: auto; 
          }
        `}
      </style>
    </div>
  );
};

export default MenuBarAr;
