import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgtoolsAr.jpg";
import Tools_EconomicCalendarWidgetAr from "./tradingTools_Tools_EconomicCalendarWidgetAr.js";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
'ابقَ على اطلاع دائم بأحدث الاتجاهات والأخبار والتقنيات والاستراتيجيات من خلال الأحداث والندوات الالكترونية.',
'فرصة للتعلم من خبراء الصناعة ذوي الخبرة العالية والمعتمدين من خلال الندوات الالكترونية.',
'اتخاذ قرارات دقيقة مع تحديث المعلومات وخطط التداول والعروض الأسبوعية.',
'احصل على أحدث المعلومات المتعلقة بالسياسات العالمية التي قد تؤثر على السوق.',
'يمكن الوصول إليها من أي مكان في العالم.',

];
const { Title } = Typography;

const Tools_EconomicCalendarAr = () => {
  return( <div>
          <PageseMetaTags
                title="Economic Calendar - Noor Capital"
                description="Check our fast-updating economic calendar to get indicators in real-time as economic events are announced and see the immediate global market impact."
            />

            <BgBeforeAr 
            pageHeadingTitle="خطط لرحلتك الاستثمارية بأمان مع تقويم نور كابيتال الاقتصادي"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="ادوات التداول"
        pageSubCategoryPath="#"
        pageName="الأجندة الاقتصادية"
      />


      
<div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          الأجندة الاقتصادية
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right"><b>
            ابق على اطلاع دائم من خلال التقويم الاقتصادي القوي الخاص بنا
            </b></p>

            <p className="parjustify text-right">
            لكي  تصبح ذو خبرة كبيرة في السوق المالية، يجب أن تكون على أعلى مستوى من المهارة مع المعلومات الصحيحة لاتخاذ قرار مربح. مع التقويم الاقتصادي لشركة نور كابيتال، لن تفوتك أبدًا أي فرصة في السوق وستتقدم دائمًا على منافسيك.
            </p>

            <p className="parjustify text-right">
            يوفر لك التقويم الاقتصادي معلومات وأحداثًا شفافة حول العالم تؤثر على الأسهم والسلع والعملات. ابق في صدارة اللعبة مع آخر الأخبار والمؤشرات والتحليلات لمساعدتك على اتخاذ قرار التداول الصحيح.
            </p>

            <p className="parjustifytext-right">
            نقدم لكم الأخبار الاقتصادية العالمية والسياسات الحكومية والأحداث التي تؤثر بشكل مباشر على السوق، وذلك بداية من التصنيع والإنتاج إلى البطالة والتقلبات في أسعار الفائدة لتقارير إجمالي الناتج المحلي وأكثر من ذلك بكثير حيث تعتبر هذه المعلومات حيوية في تحديد استراتيجية التداول الخاصة بك.
            </p>
          </Col>

          
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          الميزات والفوائد
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>


        
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tools_EconomicCalendarWidgetAr />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              اتصل بنا إذا كان لديك سؤال حول التقويم الاقتصادي
              </Title>
              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/about/contact-us"
                  
                >
                  اتصل بنا
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>


  </div>
  )

};

export default Tools_EconomicCalendarAr;