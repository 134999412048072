import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgmt4Ar.jpg";
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import WhyNoorAr from "./whyNoorAr";

const { Title } = Typography;

const style = { padding: "50px 0" };

const Metatrader4_MetaTrader4DesktopAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Metatrader 4 for PC - Noor Capital"
        description="The MetaTrader 4 web version has all the advantages of the native solution since it is a regular part of the desktop platform. This ensures the web platform’s high reliability and compatibility with the entire MetaTrader 4 ecosystem. The application is safe to use – all transmitted data is securely encrypted."
      />

      <BgBeforeAr
        pageHeadingTitle="نقدم طرق بسيطة وصحيحة للتداول"
        pageHeadingDescription="تداول كمحترف مع ميتاتريدر 4 (MT4) من نور كابيتال - منصة سطح مكتب قوية مصممة لك"
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="ميتاتريدر4 لأجهزة سطح المكتب"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          قم بتنزيل MetaTrader 4 (MT4) من نور كابيتال على سطح مكتب Windows ، وابدأ التداول أثناء التنقل
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            ارتقِ بتجربة التداول الخاصة بك إلى أعلى مستوى مع منصة سطح المكتب ميتاتريدر MT4 من نور كابيتال، تُعد هذه المنصة مفضلة وموصى بها من قبل معظم المتداولين ذوي الخبرة العالية على مستوى العالم حيث تتوافق مع نظام الويندوز، وتعتبر هذه المنصة أيضًا القابلة للتحميل هي مزيج من الابتكار والتصميم لمنحك تجربة تداول مثالية.
            </p>
            <p className="parjustify text-right">
            المنصة سهلة التنزيل والاستخدام، ومزودة بجميع الميزات لتجعلك متداولًا أفضل وأكثر نجاحًا. تُعد ميتاتريدر MT4 من نور كابيتال التي تتيح للمتداولين الوصول إلى السوق العالمية من أي مكان، منصة سهلة الاستخدام حيث تمنحك التحكم الكامل في التداول لتقليل المخاطر وتحقيق أقصى ربح.
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-windows"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                
                <h3>Windows</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://download.mql5.com/cdn/web/noor.capital.psc/mt4/noorcapital4setup.exe"
                    target="_blank"
                  >
                    تحميل
                  </a>
                </p>
              </div>
              
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                الميزات 
                </Title>
                <h5>
                استمتع الآن بجميع ميزات ميتاتريدر 4 (MT4) الخاصة بشركة نور كابيتال مباشرة على سطح المكتب لديك.
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على وصول فوري إلى السوق العالمية باستخدام برنامج ميتاتريدر MT4 لسطح المكتب من نور كابيتال.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تداول العقود مقابل الفروقات والفوركس والعقود الآجلة من منصة واحدة.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على إشارات التداول من كبار المزودين.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تخصيص خبراء لمساعدتك على التداول بدون مخاطر.
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  خدمة عملاء مخصصة 24/5 متاحة لمساعدتك في أي استشارات أو نصائح.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  يُمكنك اختيار لغتك المفضلة (انجليزي / عربي).
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  جرب منصة التداول الخاصة بسطح المكتب الأكثر أمانًا وسهولة في الاستخدام حول العالم.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 dedicated customer service available to help you with any
                  concerns or advice.
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="fdb-block">
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={2}>
                Advanced Benefits
              </Title>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-3">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <Title style={{ color: "#1b75bc" }} level={3}>
                Interactive Charts
              </Title>

              <p className="parjustify">
                Interactive charts track currency rate changes in real time. The
                application provides three types of charts: bars, candlesticks
                and lines allowing you to trade directly on them. You can easily
                scale and scroll charts, as well as switch between nine
                timeframes — from one minute to one month.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <Title style={{ color: "#1b75bc" }} level={3}>
                Convenience
              </Title>

              <p className="parjustify">
                The MetaTrader 4 App for iOS provides outstanding features to
                enhance your trading activity. The One Tap Trading function
                allows you to trade on a chart with a single click. Open the
                quick trading panel and perform a deal in no time! The built-in
                chat allows you to communicate with any registered user of the
                MQL5 community — the largest community of traders.
              </p>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <Title style={{ color: "#1b75bc" }} level={3}>
                Orders and Trading Functions
              </Title>

              <p className="parjustify">
                All information regarding the amount of orders and open
                positions, open prices, volumes and account status can be found
                in the Trade window, while the History window allows you to view
                the detailed history of all previously performed trades. To
                implement any strategy you need a flexible MetaTrader 4 mobile
                trading system paired with a full set of orders and trading
                functions. Instant execution orders allow you to trade Forex
                continuously, while pending ones relieve you from the necessity
                to constantly manage your trading.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <Title style={{ color: "#1b75bc" }} level={3}>
                Trading Flexibility
              </Title>

              <p className="parjustify">
                Instant execution orders allow you to trade Forex at any time,
                while pending orders relieve you from the necessity to
                constantly check your device. Just place a pending order and
                switch to other things. The order will be executed even if the
                application is offline. Set Take Profit and Stop Loss levels to
                set your profit or minimize your losses. Comprehensive
                information such as track number, open prices, volumes, state of
                account of all orders and open positions, are always available
                in the Terminal window. Detailed history of all trades is
                available from the History window.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={4}>
              غير الطريقة التي تتداول بها، حمل منصة ميتاتريدرMT4  لسطح المكتب من نور كابيتال الآن
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://download.mql5.com/cdn/web/noor.capital.psc/mt4/noorcapital4setup.exe"
                  target="_blank"
                >
                  تحميل
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoorAr />

    </div>
  );
};

export default Metatrader4_MetaTrader4DesktopAr;
