import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { Menu, Dropdown, message, Space, Tooltip } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import Video from "./video";
import "./footer.css";
import globalstyle from "../../style.module.css";

const opendemo = (
  <Menu>
    <Menu.Item key="1">
      <Link to="/trading-platforms/mt4-open-forex-demo">FX Trading</Link>
    </Menu.Item>


    <Menu.Item key="2">
    <Link to="/stocks-trading-platforms/open-stocks-demo">Cash Equities</Link>
    </Menu.Item>


    {/* <Menu.Item key="2">
      <Link to="/physical-trading-platforms/open-noor-trader-demo">
        Physical Trading
      </Link>
    </Menu.Item> */}
    <Menu.Item key="3">
      <Link to="/institutional/request-demo">Institutional Liquidity</Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to="/about/contact-us">Contact Us</Link>
    </Menu.Item>
  </Menu>
);

const openlive = (
  <Menu>
    <Menu.Item key="1">
      <a
        href="https://mynoor.noorcap.ae/en/registration/signup"
        target="_blank"
      >
        FX Trading
      </a>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/stocks-trading-platforms/open-gtn-live">Cash Equities</Link>
    </Menu.Item>
    {/* <Menu.Item key="3">
      <Link to="/physical-trading-platforms/open-noor-trader-live">
        Physical Trading
      </Link>
    </Menu.Item> */}
    <Menu.Item key="4">
      <Link to="/institutional/request-live">Institutional Liquidity</Link>
    </Menu.Item>
    <Menu.Item key="5">
      <Link to="/about/contact-us">Contact Us</Link>
    </Menu.Item>
  </Menu>
);

const UpFooter = () => {
  return (
    <>
      <Row style={{ backgroundColor: "#1C74BC", color: "#fff" }} id="upfooter1">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <section className="fdb-block" style={{ padding: "30px 0" }} >
            <div className="container">
              <div className="row justify-content-center align-items-center pt-5">
                <div className="col-8 col-sm-3">
                  <Video />
                  {/* <img alt="Noor Capital" className="img-fluid" src={ctaimg} /> */}
                </div>

                <div className="col-12 col-sm-6 m-auto pt-4 pt-sm-0">
                  <h3>
                    <strong style={{ color: "#fff" }}>
                      Start your trading journey with Noor Capital
                    </strong>
                  </h3>
                  <p className="lead">
                    <ul class="list-group list-group-flush">
                      <li
                        className="list-group-item"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <i
                          class="far fa-check-circle"
                          style={{ paddingRight: "7px" }}
                        ></i>{" "}
                        Multiple Financial & Investment Solutions
                      </li>
                      <li
                        className="list-group-item ifontonmobile"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <i
                          class="far fa-check-circle "
                          style={{ paddingRight: "7px" }}
                        ></i>{" "}
                        Ultimate and Advanced Trading Platforms
                      </li>
                      <li
                        className="list-group-item"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <i
                          class="far fa-check-circle"
                          style={{ paddingRight: "7px" }}
                        ></i>{" "}
                        Excellent Investment Advice
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="col-12 col-sm-3 text-center pt-4 pt-sm-0">
                  <p>
                    <Dropdown overlay={opendemo} style={{ zIndex: "1000" }}>
                      <Button
                        className="btn btn-light"
                        style={{
                          color: "#212529",
                          backgroundColor: "#f8f9fa",
                          padding: "18px",
                          lineHeight: "2px",
                          fontWeight: "600",
                          minWidth: "185px",
                        }}
                      >
                        Try a Demo
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </p>
                  <p>
                    <Dropdown overlay={openlive} style={{ zIndex: "1000" }}>
                      <Button
                        className="btn btn-light"
                        style={{
                          color: "#212529",
                          backgroundColor: "#f8f9fa",
                          padding: "18px",
                          lineHeight: "2px",
                          fontWeight: "600",
                          minWidth: "185px",
                        }}
                      >
                    Open Real Account
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }} id="upfooter2">
        <div className={globalstyle.container}>
          <div className="row align-items-center justify-content-center">
            <div className="col-auto">
              <h3>
                Stay up to date with the latest Noor Capital news and blog
                articles{" "}
              </h3>
            </div>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col-auto mt-4 mt-sm-0 align-items-center justify-content-center">
            <Link
              className="btn btn-primary"
              to="/blog"
              style={{ marginRight: "30px", width: "135px" }}
            >
              Blog
            </Link>
            <Link
              className="btn btn-primary"
              to="/media-center/press-release"
              style={{ width: "135px" }}
            >
              Press Release
            </Link>
          </div>

          <div className="col-auto mt-4 mt-sm-0 align-items-center justify-content-center">
            <Link
              className="btn btn-primary"
              to="/media-center/activities"
              style={{ marginRight: "30px", width: "135px" }}
            >
              Activities
            </Link>
            <Link
              className="btn btn-primary"
              to="/media-center/awards"
              style={{ width: "135px" }}
            >
              Awards
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpFooter;
