import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import technical from "../../assets/images/pages/tools/technical.png";
import img1 from "../../assets/images/bgtools.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
'Technical Analysis by a highly accredited and certified team of experts from around the world.',
'Opportunity to connect with the senior analysts.',
'Trading Central Technical Analysis is an essential tool that allows you to identify original investment opportunities.',
'Exceptional analytical software',
'An opportunity to   make as much as possible profits and avoid  increasing risk in your trading process with our wide range of tools used for technical analysis.',
];
const { Title } = Typography;

const NoorTrends_TechnicalAnalysis = () => {
  return (
    <div>
      <PageseMetaTags
        title="Forex Technical Analysis - Noor Capital For Trading"
        description="The Noor Capital market research team of professionals provides clients with the latest updates and news on market price movements and trends by posting daily technical analysis and charts on the major currency pairs."
      />

      <BgBefore
        pageHeadingTitle="Get Market Insights from Accredited And Certified Analyst"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName=" Technical Analysis"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Technical Analysis
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Noor Capital is dedicated to providing  investors with the best possible tools to maximise their trading experience and get them ahead of the competition. With this in mind, we use Technical Analysis to evaluate investments and spot trading opportunities.
            </p>

            <p className="parjustify">
            Technical Analysis is the study of historical data of financial assets that predicts the future price movement.  You will have the opportunity to  maximize your profits and minimize risk.
            </p>

            
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={technical}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          Features & Benefits
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Get Access To Technical Analysis Now
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/en/category/tech-analysis/"
                  target="_blank"
                >
                  Click Here
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


    
    </div>
  );
};

export default NoorTrends_TechnicalAnalysis;
