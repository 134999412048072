import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import { Link } from "react-router-dom";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgnoortraderAr.jpg";

import noortreaderdesktop from "../../assets/images/pages/trading/noortreaderdesktop.png";
 import OpenFxDemoFormAr from "../forms/openFxDemoFormAr.js";
import NtVideo from "./ntVideo.js";

import mt4desktop from "../../assets/images/pages/trading/gtn.png";
import WhyNoorAr from "./whyNoorAr";

const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader_WebtraderAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="NoorTrader Webtrader - Noor Capital"
        description="You can access NoorTrader from any web browser and enjoy all the unique features that will escalate your trading experience to a different level."
      />

      <BgBeforeAr
        pageHeadingTitle="ابدأ تداول الذهب والفضة من أي مكان في العالم"
        pageHeadingDescription="يمكن الوصول إلى نور تريدر من أي متصفح ويب"
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="منصة نور تريدر على متصفح الويب"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          قم بالوصول إلى حساب التداول المادي الخاص بك من متصفح الويب
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            بغض النظر عن مكان وجودك، يمكنك الآن الوصول إلى نور تريدر من أي متصفح ويب والاستمتاع بجميع الميزات الفريدة التي ستصعد تجربتك في التداول إلى مستوى مختلف.
            </p>
            <p className="parjustify text-right">
            يمكنك الآن شراء وبيع وإدارة استثماراتك في المعادن الثمينة من منزلك أو مكتبك أو في أي مكان في العالم؛ كل ما تحتاجه هو اتصال بالإنترنت. يمكن الوصول إلى نور تريدر من جميع متصفحات الويب الرئيسية ومتوافق مع جميع أنظمة التشغيل ويندوز وماك ولينكس. 
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-12 col-md-8 col-lg-4 m-sm-auto">
                <p className="mt-3">
                  <a
                    className="btn btn-primary btn-lg"
                    href="https://tlive.noorcapital.ae/investor/Login.aspx"
                    target="_blank"
                  >
                    إستخدام نور تريدر على متصفح الويب
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                الميزات 
                </Title>
                <h5>
                يمكنك الوصول إلى نور تريدر من خلال أي متصفح ويب - كل ما تحتاجه هو اتصال بالإنترنت.
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  وصول آمن ومشفّر بنسبة 100٪.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  ابدأ في شراء وبيع وإدارة معادنك الثمينة بنقرة واحدة.
                </p>
                <br />

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تعلم التداول كمحترف على البيانات الفورية
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  متوافق مع جميع متصفحات الويب ونظم التشغيل المختلفة.
                </p>

              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الوصول الفوري إلى سوق السبائك العالمية.
                  
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على سعر لا مثيل له في السوق الفوري مع خيار الإيداع الجزئي.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  البرنامج متاح 24/7 حيث يمكنك الوصول إلى استثمارك طوال الوقت.
                  
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  متوافق مع جميع متصفحات الويب وأنظمة التشغيل
                </p>
                <br />
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid"  src={noortreaderdesktop}  />
              </div>
            </div>
          </div>
        </section>
      </div>

      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={4}>
              سواء كنت مبتدئ أو محترف، منصة نور تريدر للجميع
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/physical-trading-platforms/open-noor-trader-live"
                  
                >
                  سجل الآن 
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default NoorTrader_WebtraderAr;
