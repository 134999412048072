import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bggtn.jpg";

import WhyNoor from "./whyNoor";
import GtnVideo from "./GtnVideo.js";

const { Title } = Typography;
const style = { padding: "50px 0" };

const GTN = () => {
  return (
    <div>
      <PageseMetaTags
        title="Noor Capital Stocks Trading Platform (GTN)"
        description="Take Charge Of Your Stocks And Trade On The Go With Noor Capital Stocks Trading Platform (GTN)"
      />

      <BgBefore
        pageHeadingTitle="Take Charge Of Your Stocks And Trade On The Go With Noor Capital Stocks Trading Platform (GTN)"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="Stocks Trading (GTN)"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}></Title>
        </Row>
        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              With Noor Capital Stocks Trading Platform (GTN), trading has never
              been this comfortable and convenient. A robust trading app that
              supports our active traders' daily activities, keeping them ahead
              of the game.
            </p>
            <br />
            <p className="parjustify">
              Noor Capital Stocks Trading Platform (GTN), a unique platform that
              allows you to buy/sell stock from anywhere and receive important
              alerts, news, and reports giving you an upper hand in this
              fast-paced trading world.
            </p>
            <br />
            <p className="parjustify">
              Noor Capital Stocks Trading Platform (GTN) trading app provides
              you with an extremely user-friendly interface accessible from the
              web, desktop, and mobile (Available at PlayStore and AppStore).
            </p>

            <Link
              to="/stocks-trading-platforms/open-gtn-live"
              className="btn btn-success btn-lg"
              style={{ width: "250px" }}
            >
              Open A Live Account
            </Link>

            <div style={{ marginTop: "50px" }}>
              <p className="parjustify">
                <b>
                  The Noor Capital Stocks Trading Platform (GTN) now offers a
                  new desktop and mac app, your one-stop trading for all your
                  needs.{" "}
                </b>
              </p>

              <a
                className="btn btn-secondary ml-4"
                style={{ width: "200px", marginRight: "35px" }}
                href="https://download.globaltradingnetwork.com/wrappers/NOOR_WIN.zip"
                target="_blank"
              >
                Download for Windows
              </a>
              

              <a
                href="https://download.globaltradingnetwork.com/wrappers/NOOR_MAC.dmg.zip"
                className="btn btn-secondary "
                style={{ width: "200px" }}
                target="_blank"
              >
                Download for MacOS
              </a>
            </div>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <GtnVideo />
          </Col>
        </Row>
      </div>
      <br />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={3}>
                Change The Way You Trade Only With With Noor Capital Stocks
                Trading Platform (GTN)
              </Title>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h4 style={{ color: "#1b75bc" }}>Live Trading</h4>
              <p className="lead">
                With Noor Capital Stocks Trading Platform (GTN), you can
                instantly buy, sell, cancel, and amend orders.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <h4 style={{ color: "#1b75bc" }}>Real-Time Data</h4>
              <p className="lead">
                Receive real-time charts, market data, company information,
                announcements, and reports that will help you to stay ahead and
                trade confidently.
              </p>
            </div>
          </div>

          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>Bilingual</h4>
              <p className="lead">
                To help our customers use the platform more efficiently, the
                Noor Capital Stocks Trading Platform (GTN) is available in
                English and Arabic.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>Smart Watch List</h4>
              <p className="lead">
                Never miss out on your favorite stock. Smart Watch List allows
                you to keep track, analyze, compare, and place an order on your
                desired stock instantly.
              </p>
            </div>
          </div>

          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>Summary</h4>
              <p className="lead">
                You can access the summary of your account and portfolios that
                will help you analyze and manage your account balance and buying
                limits.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>Secured And User-Friendly</h4>
              <p className="lead">
                Noor Capital Stocks Trading Platform (GTN) is 100% secured with
                high-level one-time encryption that protects your account from
                any fraud. It is one of the most user-friendly platforms with
                access to real-time charts.
              </p>
            </div>
          </div>
        </div>
      </section>

      <br />

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                Trading for the first time with Noor Capital Stocks Trading
                Platform (GTN)?
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/stocks-trading-platforms/open-gtn-live"
                >
                  Sign-Up Now
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GTN;
