import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import { Link } from "react-router-dom";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgnoortrader.jpg";

import noortreaderdesktop from "../../assets/images/pages/trading/noortreaderdesktop.png";
import WhyNoor from "./whyNoor";
import OpenFxDemoForm from "../forms/openFxDemoForm.js";
import NtVideo from "./ntVideo.js";

import mt4desktop from "../../assets/images/pages/trading/gtn.png";

const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader_Webtrader = () => {
  return (
    <div>
      <PageseMetaTags
        title="NoorTrader Webtrader - Noor Capital"
        description="You can access NoorTrader from any web browser and enjoy all the unique features that will escalate your trading experience to a different level."
      />

      <BgBefore
        pageHeadingTitle="Start Trading Gold And Silver From Anywhere In The World"
        pageHeadingDescription="NoorTrader Is Accessible From Any Web Browser "
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="NoorTrader Webtrader"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Access your Physical Trading Account from WebTrader
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              No matter where you are, now you can access NoorTrader from any
              web browser and enjoy all the unique features that will escalate
              your trading experience to a different level.
            </p>
            <p className="parjustify">
              Now you can buy, sell, and manage your bullion investments
              from the comfort of your home, office, or anywhere in the world;
              all you need is an internet connection. NoorTrader is accessible
              from all major web browsers and compatible with all operating
              systems (Windows, Mac, and Linux).
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-12 col-md-8 col-lg-4 m-sm-auto">
                <p className="mt-3">
                  <a
                    className="btn btn-primary btn-lg"
                    href="https://tlive.noorcapital.ae/investor/Login.aspx"
                    target="_blank"
                  >
                    Open WebTrader
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                Access NoorTrader From Any Web Browser – All You Need Is An Internet Connection
                </Title>
                
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Start buying, selling, and managing your bullion with
                  just a click
                </p>
                <br />

               
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Learn trading like a professional with real-time data and companies
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Compatible with all web browsers and smart phones
                </p>

              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get instant access to the global bullion market from anywhere
                  in the world
                  
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get unmatched on the spot market price with an option for
                  partial deposit
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/7 access to your investment
                  
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Compatible with all web browsers and operating system
                </p>
                <br />
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid"  src={noortreaderdesktop}  />
              </div>
            </div>
          </div>
        </section>
      </div>

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={4}>
                Novice Or Pro-Trader, NoorTrader Platform Is For Everyone!!
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/physical-trading-platforms/open-noor-trader-live"
                  
                >
                  Register Live Account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default NoorTrader_Webtrader;
