import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgtradingbanner3.jpg";
import Instruments_SharesCfds_Table from "./trading_Instruments_SharesCfds_table.js";
import SharesCFDs from "../../assets/images/pages/instruments/SharesCFDs.jpg";
import Products from "./products.js";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import ProductsAr from "./productsAr";

const { Title } = Typography;

const notes = [
  " Minimum trade volume is 0.01 Lot / 1 Share",
  " Storage Fees applies on the above-listed instruments starting from the day 1 of holding a position, with no grace period",
  " Terms and schedules above are subject to change without prior notice",
  " Splits/dividends/corporate actions may apply",
];

const Instruments_SharesCfdsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Shares CFDs | Noor Capital"
        description="Noor Capital offers the ability for all clients to trade Shares based on CFD contracts, to benefit from global markets accessibility by trading the most popular shares CFDs through your trading account and using one platform."
      />

      <BgBeforeAr
        pageHeadingTitle="تداول بثقة فقط مع نور كابيتال"
        pageHeadingDescription="ابدأ تداول العقود مقابل الفروقات الآن"
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="التداول"
        pageMainCategoryPath="#"
        pageSubCategoryName="الأسواق"
        pageSubCategoryPath="#"
        pageName="أسواق الأسهم"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          أسواق الأسهم
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            تُعرف العقود مقابل الفروقات أيضًا باسم عقد الفرق، وهي اتفاقية بين المشتري والبائع حيث يتعين على المشتري أن يدفع للبائع الفرق بين القيمة الحالية للأصل والقيمة في وقت العقد.{" "}
            </p>

            <p className="parjustify text-right">
            بدون امتلاك الأصل الأساسي، تساعد العقود مقابل الفروقات المستثمرين في الحصول على فرصة لتحقيق ربح من حركة السعر من خلال العقد بين الوسيط والعميل الذي لا يستخدم العملات الأجنبية أو السلع أو الأسهم.
            </p>

            <p className="parjustify text-right">
            يسمح عقد الفروقات (CFD) للمستثمرين بالاستفادة من حركة سعر الأصل في هذه الحالة دون الحاجة إلى امتلاك الأصل الأساسي. إنها أداة الاستثمار الأكثر فعالية من حيث التكلفة لأنها منتج هامشي، مما يعني أنك تحتاج فقط إلى الهامش الأولي لفتح مركز - وليس القيمة الاسمية الكاملة للمركز كما هو الحال مع تداول الأسهم القياسي.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={SharesCFDs}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          قائمة المواصفات
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Instruments_SharesCfds_Table />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          شروط التداول
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      textAlign:"right",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(1) {
              background-color: #f1f1f1;
            }

            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(3) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              ابدأ تداول العقود مقابل الفروقات الآن
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ProductsAr />
    </div>
  );
};

export default Instruments_SharesCfdsAr;
