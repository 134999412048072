import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgmt4.jpg";
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import WhyNoor from "./whyNoor";

const { Title } = Typography;

const style = { padding: "50px 0" };

const Metatrader4_MetaTrader4Desktop = () => {
  return (
    <div>
      <PageseMetaTags
        title="Metatrader 4 for PC - Noor Capital"
        description="The MetaTrader 4 web version has all the advantages of the native solution since it is a regular part of the desktop platform. This ensures the web platform’s high reliability and compatibility with the entire MetaTrader 4 ecosystem. The application is safe to use – all transmitted data is securely encrypted."
      />

      <BgBefore
        pageHeadingTitle="We Don't Provide Shortcuts but the Right Track to Trade"
        pageHeadingDescription="Use Noor Capital's MetaTrader 4 (MT4) – A Robust Desktop Platform Built for you – and Trade like a Proffesional."
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="MetaTrader 4 Desktop"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Download Noor Capital's MetaTrader 4 (MT4) on Windows Desktop, And
            Start Trading
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Take your trading experience to the highest level with Noor Capital’s MetaTrader 4 (MT4) desktop platform, preferred and recommended by most highly experienced traders globally. Compatible with Windows, this downloadable platform is a blend of innovation and design to give you an ultimate trading experience.
            </p>
            <p className="parjustify">
            Easy to download and use, the platform is equipped by all the features to make you a better and successful trader. Allowing its traders to access the global market from anywhere, Noor Capital’s MetaTrader 4 (MT4) is a user-friendly platform that gives you full trading control to minimize risk and gain maximum profit.
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-windows"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                
                <h3>Windows</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://download.mql5.com/cdn/web/noor.capital.psc/mt4/noorcapital4setup.exe"
                    target="_blank"
                  >
                    Download
                  </a>
                </p>
              </div>
              
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                Enjoy all the features of Noor Capital's Metatrader 4 (MT4)  on your desktop now
                </Title>
                
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get instant access to the global market with Noor Capital's
                  MetaTrader 4 (MT4) desktop software
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Trade in CFDs, Forex, and Futures, from one platform
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get trading signals from top providers
                </p>
                <br />

                
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 dedicated customer service available to help you with any
                  concerns or advice
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Option to choose your preferred language (English/Arabic)
                </p>
                <br />

                

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 dedicated customer service available to help you with any
                  concerns or advice
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="fdb-block">
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={3}>
                Advanced Benefits
              </Title>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-3">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <Title style={{ color: "#1b75bc" }} level={4}>
                Interactive Charts
              </Title>

              <p className="parjustify">
              Interactive charts track currency rate changes in real time. The application provides three types of charts: bar, candlestick and line charts, which allows you trade directly on them. You can easily scale and scroll charts, as well as switch between nine timeframes — from one minute to one month.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <Title style={{ color: "#1b75bc" }} level={4}>
                Convenience
              </Title>

              <p className="parjustify">
              MetaTrader 4 App for iOS provides outstanding features to enhance your trading activity. The One Tap Trading function allows you to trade on a chart with a single click. Open the quick trading panel and perform a deal in no time! The built-in chat allows you to communicate with any registered user of the MQL5 community — the largest community of traders.
              </p>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <Title style={{ color: "#1b75bc" }} level={4}>
                Orders and Trading Functions
              </Title>

              <p className="parjustify">
              All information regarding  orders and open positions, open prices, volumes and account status can be found in  Trade window, while  History window allows you  view  detailed history of all previously executed  trades. To implement any strategy you need a flexible MetaTrader 4 mobile trading system paired with a full set of orders and trading functions. Instant execution orders allow you  trade Forex continuously, while pending ones refrain  you from constant trading oversight and management.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <Title style={{ color: "#1b75bc" }} level={4}>
                Trading Flexibility
              </Title>

              <p className="parjustify">
              Instant execution of orders allows you trade Forex at any time, while pending orders helps you refrain from continuous oversight and management tour trades.. Just place a pending order and switch to other things. The order will be executed even if the application is offline. Set Take-Profit and Stop-Loss levels to  adjust the  profits you need to achieve or minimize potential  losses. Comprehensive information such as track number, open prices, volumes, state of account of all orders and open positions, are always available in Terminal window. Detailed history of all trades is available on History window.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                Transform The Way You Trade, Download Noor Capital's MetaTrader
                4 (MT4) Desktop Platform Now
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://download.mql5.com/cdn/web/noor.capital.psc/mt4/noorcapital4setup.exe"
                  target="_blank"
                >
                  Download
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoor />

    </div>
  );
};

export default Metatrader4_MetaTrader4Desktop;
