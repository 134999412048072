import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import live from "../../assets/images/pages/tools/live.png";
import img1 from "../../assets/images/bgtools.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  'Keep yourself updated with the latest trends, news, techniques, and strategies with daily live broadcast.',
'An opportunity  to learn from extremely experienced and accredited industry experts through webinars.',
'Take accurate decisions with updated information, trading plans, and weekly previews.',
'Get the latest information related to world politics that may affect the market.',
'Accessible from anywhere in the world.',
];
const { Title } = Typography;

const NoorTrends_DailyBroadCasting = () => {
  return (
    <div>
      <PageseMetaTags
        title="Daily Live Broadcast - Noor Capital"
        description="Live broadcasting of the currency trading markets: It is one of the services of Noor Capital, which seeks to develop and train traders, and through which we discuss the most important opportunities for the most investment tools."
      />

      <BgBefore
        pageHeadingTitle="Plan your investment journey safely with Noor Capital’s Daily Broadcasting"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName="Daily Live Broadcast"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Daily Live Broadcast
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
             <b>Keep yourself up to date with daily broadcasts</b>
            </p>

            <p className="parjustify">
            Here is the chance to keep yourself up to date with the latest market trends, chart patterns, expert analysis, and much more. Noor Capital's Live Broadcasting is accessible from anywhere in the world that will keep you updated with live market coverage, and the latest events, helping you to plan efficiently.
            </p>

            
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={live}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          Features & Benefits
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Get access To The Broadcasting Calendar now
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/daily-broadcasting/"
                  target="_blank"
                >
                  Click Here
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

    
    </div>
  );
};

export default NoorTrends_DailyBroadCasting;
