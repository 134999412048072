import React from "react";
import {Link} from "react-router-dom";
import "./mainPage.css";
import globalstyle from '../../style.module.css';
import mainpage5bg from "../../assets/images/home/bg5.jpg";

const MainPage5 = () => {
  return (
    <>
      
        <section
          className="fdb-block fdb-viewport bg-dark imgbg "
          style={{ backgroundImage: `url(${mainpage5bg})` }}
        >
          <div className=" align-items-left justify-content-left d-flex">
          <div className={globalstyle.container}>
            <div className="row align-items-left text-left">
              <div className="col-12 col-sm-10 col-md-8 col-lg-8">
                <h1 style={{"color":"#1b75bc"}}>Noor Capital Partnership Programs</h1>
                <p className="lead">
                Take Advantage of our different market products and distinctive partnership programs.
                Accelerate your profitability, sales and transform your business to a new level.

                </p>
                <p className="mt-5">
                  <Link to="/partnership/institutional-clients" className="btn btn-light" style={{"width":"150px"}}>
                    Institutional
                  </Link>
                  <Link to="/partnership/marketing-partnership-program" className="btn btn-light ml-4" style={{"width":"150px"}}>
                  Referral Program
                  </Link>
                </p>
              </div>
              </div>
            </div>
          </div>
        </section>
      
    </>
  );
};

export default MainPage5;
