import React from "react";
import {Breadcrumb} from 'antd';
import styleAr from './breadcrumbsAr.module.css';
import globalstyle from '../../style.module.css';
import {Link} from 'react-router-dom';



const BreadcrumbMenuAr = (prop) => {
    const routes = [
        {
            path: prop.pageMainCategoryPath,
            breadcrumbName: prop.pageMainCategoryName,
        },
        {
            path: prop.pageSubCategoryPath,
            breadcrumbName: prop.pageSubCategoryName,
        },
        {
            path: '#',
            breadcrumbName: prop.pageName,
        },
    ];

    const itemRender = (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (
            <span style={{"color":"#fff"}}>{route.breadcrumbName}</span>
        ) : (
            <Link className={styleAr.customBreadcrumbLink} to={paths.join('/')}>
                <span className={styleAr.customBreadcrumbLink}>{route.breadcrumbName}</span></Link>
        );
    };

    return (
        <div className={styleAr.breadcrumbBg}>
            <div className={globalstyle.container}>
                <Breadcrumb  separator="&#8250;" className={styleAr.customBreadcrumb} itemRender={itemRender} routes={routes}/>
            </div>


            <style jsx>{
            `
            .ant-breadcrumb-separator {
            
            margin: 0 12px !important;
    font-weight: 800 !important;
}
            
            `
        }
        </style>

        </div>




    )
};

export default BreadcrumbMenuAr;
