import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgnoortraderAr.jpg";
import { Link } from "react-router-dom";
import WhyNoorAr from "./whyNoorAr";
import NtVideo from "./ntVideoAr.js";


const { Title } = Typography;

const NoorTraderAr = () => {
  return( <div>
          <PageseMetaTags
                title="NoorTrader - Noor Capital"
                description="NoorTrader platform allows you to buy, sell, store, and manage physical bullion in real-time in the global market place."
            />
            <BgBeforeAr 
            pageHeadingTitle="نحن نُحدث ثورة في طريقة تداول الذهب والفضة"
            pageHeadingDescription="نور تريدر تقدم لك حلاً شامًلا لتداول المعادن الثمينة"
            pageHeadingbg={img1}
            />
        <BreadcrumbMenuAr 

pageMainCategoryName="الرئيسية"
pageMainCategoryPath="/"
pageSubCategoryName="منصات التداول"
pageSubCategoryPath="#"
pageName="التداول المادي"
/>



<div className={globalstyle.container}>
<Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
  <Title style={{ color: "#1b75bc" }} level={3}></Title>
</Row>
<Row class="mx-auto" span={24} justify="center">
  <Col
    xs={24}
    sm={24}
    md={16}
    lg={16}
    xl={16}
    style={{
      textAlign: "justify",
      padding: "0px 0px 0px 0px",
      fontSize: "16.5px",
    }}
  >
    <p className="parjustify">
    يعد الذهب والفضة من أكثر المعادن الثمينة والقيمة في جميع أنحاء العالم. إذ تُعد المعادن الثمينة هي الاستثمار التي يجب الرجوع إليه في الأوقات الصعبة. معنا، يمكنك تأمين مستقبلك بثقة أكبر وتداول السبائك المادية عالميًا من خلال منصة نور تريدر.

    </p>
    <br/>
    <p className="parjustify">
    تتيح لك منصة نور تريدر شراء وبيع وتخزين وإدارة السبائك المادية في الوقت الفعلي في السوق العالمية. من خلال الوصول إلى استثماراتهم على مدار الساعة طوال أيام الأسبوع، يمكن للمستثمرين أن يطمئنوا من خلال التخزين والتأمين الآمن للسبائك.

    </p>
    <br/>
    <p className="parjustify">
    بنيت على بيئات مشفرة وآمنة للغاية حيث يمكنك الوصول إلى نور تريدر إما من أي متصفح ويب أو يمكنك تحميل تطبيق نور تريدر على هاتفك الذكي.

    </p>
    

    <br/>

    <Link
          to="/ar/physical-trading-platforms/open-noor-trader-demo"
          className="btn btn-primary btn-lg ml-4"
          style={{ width: "250px", marginTop: "25px"}}
        >
         افتح حساب تجريبي
        </Link>
      
      <Link
          to="/ar/physical-trading-platforms/open-noor-trader-live"
          className="btn btn-success btn-lg"
          style={{ width: "250px" , marginTop: "25px"}}
        >
          افتح حساب حقيقي
        </Link>
        
      

  </Col>

  <Col
    xs={24}
    sm={24}
    md={8}
    lg={8}
    xl={8}
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <NtVideo />
  </Col>
</Row>
</div>
<br />

<section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
<div className={globalstyle.container}>
  <div className="row">
    <div className="col-12 text-center">
      <Title style={{ color: "#1b75bc" }} level={1}>
      الميزات 
      </Title>
      <h5>
      تعتبر نور تريدر منصة تداول ثورية تسمح لك بالتداول في المعادن المادية الثمينة مع تخزين وتسليم من الطراز الرفيع.
        </h5>
    </div>
  </div>
  <div className="row text-right justify-content-center pt-5">
    <div className="col-12 col-md-6 col-lg-5 m-auto">
      <h4 style={{ color: "#1b75bc" }}>
      منصة قوية
      </h4>
      <p className="lead">
      نور تريدر هي منصة كبرى تتيح للمستثمرين شراء وبيع وإدارة المعادن الثمينة في أي وقت وفي أي مكان خلال ضغطة واحدة فقط.
      </p>
    </div>
    <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
    <h4 style={{ color: "#1b75bc" }}>
    يمكن الوصول 
      </h4>
      <p className="lead">
      مع NoorTrader ، يمكنك الوصول إلى استثماراتك على مدار الساعة طوال أيام الأسبوع من أي مكان في العالم ؛ كل ما تحتاجه هو اتصال بالإنترنت.
      </p>
    </div>
  </div>
  <div className="row text-right justify-content-center pt-lg-4">
    <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
    <h4 style={{ color: "#1b75bc" }}>
    أفضل سعر في السوق
      </h4>
      <p className="lead">
      توفر لك نور تريدر سعرًا لا مثيل له في السوق الفوري مع خيار الإيداع الجزئي.
      </p>
    </div>
    <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
    <h4 style={{ color: "#1b75bc" }}>
    متوافق للغاية
      </h4>
      <p className="lead">
      NoorTrader متوافق مع جميع أنظمة التشغيل (Windows و Mac و Linux) وبالنسبة لأولئك الذين يرغبون في الوصول إليه أثناء التنقل ، يمكنك تنزيل تطبيق الهاتف المحمول المتوافق للغاية مع جميع أجهزة Android و iPhone.
      </p>
    </div>
  </div>
</div>
</section>

<br />

<WhyNoorAr />

<section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
<div className="container">
  <div className="row justify-content-center">
    <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
      <Title style={{ color: "#11131b" }} level={3}>
      استثماراتك قيم مثل المعادن الثمينة - اتخذ القرار الصحيح فقط مع نور تريدر
      </Title>

      <p className="mt-5 mt-sm-4">
        <Link
          className="btn btn-outline-primary"
          to="/ar/physical-trading-platforms/open-noor-trader-live"
        >
          أفتح حساب الأن
        </Link>
      </p>
    </div>
  </div>
</div>
</section>








  </div>
  )

};

export default NoorTraderAr;