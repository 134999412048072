import React, { useContext } from "react";
import globalstyleAr from "../../globalstyleAr.module.css";
import UpHeaderAr from "./upHeaderAr";
import MenuBarAr from "./menuBarAr";


const HeaderAr = (props) => {
  
  return (
    <>
      <UpHeaderAr />
      <MenuBarAr />
    </>
  );
};

export default HeaderAr;
