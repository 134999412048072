import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import alsayegh from "../../assets/images/board/alsayegh.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const ChairmanMessageAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Noor Capital Chairman’s Message"
        description="Noor Capital Chairman's message"
      />
      <BgBeforeAr
        pageHeadingTitle="رسالة رئيس مجلس إدارة نور كابيتال"
        pageHeadingDescription=" "
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="رئيس مجلس الإدارة"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div
            className="row text-center justify-content-center"
            style={{ marginBottom: "20px" }}
          >
            <div className="col-10">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
                رسالة رئيس مجلس إدارة نور كابيتال
              </h2>
            </div>
          </div>

          {/* 111111111111 */}

          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "10px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                  <img
                    alt="Mr. Abdul Jabbar Al-Sayegh"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={alsayegh}
                    style={{ marginBottom: "25px" }}
                  />
                  <h5 style={{ color: "#1b75bc", marginBottom: "0px" }}>
                    عبد الجبار الصايغ
                  </h5>
                  <p className="lead"> رئيس مجلس إدارة نور كابيتال </p>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={16}
                  xl={16}
                  style={{ padding: "20px 30px 10px 0px" }}
                >
                  <blockquote class="blockquote">
                    <p
                      class="mb-0"
                      style={{
                        color: "#9f9898",
                        textAlign: "center",
                        lineHeight: "35px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "26px",
                          padding: "9px",
                          color: "#9f9898",
                        }}
                      >
                        <i class="fas fa-quote-right"></i>
                      </span>
                      <span style={{ fontSize: "24px" }}>
                        عندما يتضافر الحلم مع العديد من الأفكار لتقديم الحلول
                        الاستثمارية والاستشارية المالية لعملائنا، هُنا ولدت نور
                        كابيتال
                      </span>
                      <span
                        style={{
                          fontSize: "26px",
                          padding: "9px",
                          color: "#9f9898",
                        }}
                      >
                        <i class="fas fa-quote-left"></i>
                      </span>
                    </p>
                  </blockquote>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    هناك طرق عدة لقياس نجاح أي الشركة. ومع ذلك، فإن النجاح
                    الحقيقي لأي شركة هو عندما تضيف قيمة إلى اقتصاد بلدها وشعبها.
                    تؤمن نور كابيتال دائمًا بإضافة المزيد من القيمة لاستثمارات
                    عملائها، وتوجيههم نحو اتخاذ القرارات المالية الصحيحة
                    والمساهمة في اقتصاد دولة الإمارات العربية المتحدة.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    تأسست شركة نور كابيتال في ديسمبر عام ۲۰۰٥ لتقديم حلول مالية
                    وفرص استثمارية سلسة مع خدمة لا مثيل لها وتكنولوجيا مبتكرة
                    للغاية.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    إنه لمن دواعي سروري البالغ أن أعلن أننا تابعنا مهمتنا
                    ورؤيتنا وقيمنا بنجاح وحماس وبلا كلل، مما يجعل نور كابيتال
                    إحدى الشركات الرائدة في مجال التمويل والاستثمار في دولة
                    الإمارات العربية المتحدة وعلى مستوى العالم.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    يسعدني أن أكون محاطًا بواحد من أكثر الفرق تحفيزًا وكفاءة
                    والتي تعمل بلا هوادة، ليضيفوا قيمة إلى كل عميل بخبراتهم.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    تحت راية اقتصاد دولة الإمارات العربية المتحدة المتنامي
                    باستمرار بفضل قيادتها الحكيمة، تزدهر نور كابيتال للمساهمة في
                    هذه الدولة العظيمة من خلال حلولنا الاستثمارية القوية.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    أتقدم بالتهنئة لجميع موظفينا وعملائنا ومساهمينا ومجلس
                    إدارتنا الذين لعبوا دور أساسي في جعل نور كابيتال شركة رائدة
                    في مجال التمويل والاستثمار.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
كما أود أن أغتنم هذه الفرصة لأعبر عن فائق الامتنان والتقدير لقيادتنا، صاحب السمو الشيخ محمد بن زايد آل نهيان، رئيس دولة الإمارات العربية المتحدة وصاحب السمو الشيخ محمد بن راشد آل مكتوم، رئيس مجلس الوزراء وحاكم دبي
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>

      <style jsx>
        {`
          .imgshadow {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19);
          }
        `}
      </style>
    </div>
  );
};

export default ChairmanMessageAr;
