import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import img1 from "../../assets/images/bgtools.jpg";
import tcimage from "../../assets/images/pages/tools/tc-indicator.png";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  "Analyst Views – Direction and key levels at your fingertips ",
  "Adaptive Candlesticks – A quick way to see important changes in supply and demand lines displayed as candlesticks patterns.",
  "Adaptive Divergence Convergence (ADC) – Useful and timely analytics for short-term trading while guarding against sideways movements",
  "Trading Signals is accessible from anywhere in the world",
];
const { Title } = Typography;

const Tools_TradingSignals = () => {
  return (
    <div>
      <PageseMetaTags
        title="Trading signals for forex, stocks and indices - Noor Capital"
        description="Trading Signals: Get actionable 'buy' and 'sell' suggestions, based on technical or fundamental analysis."
      />

      <BgBefore
        pageHeadingTitle="Spot Potential Trades with Noor Capital Trading Signals"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName="Trading signals"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Trading signals
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              <b>Trade With Ease And Confidence With Our Trading Signal</b>
            </p>

            <p className="parjustify">
              At Noor Capital, we know the importance of regular and constant
              price indicators for our traders. Noor Capital Trading Signal is a
              unique and upgraded tool that will help you follow the latest
              market trends and turn opportunities into trades.
            </p>
            <p className="parjustify">
              Noor Capital TC MT4 Indicator is designed to spot new trading
              opportunities and entry/exit points that will help you enhance
              your trading performance. There are three innovative indicators
              that are best represent the market action and it has improved user
              experience, analytics focused, and automatic software updates.
            </p>

            <Divider orientation="left">
              <Title level={3} style={{ color: "#1b75bc" }}>
                Features & Benefits
              </Title>
            </Divider>

            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={tcimage}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div class="alert alert-warning" role="alert">
              The information provided does not constitute, in any way, a
              solicitation or inducement to buy or sell securities and similar
              products. Comments and analysis reflect the views of TRADING
              CENTRAL at any given time and are subject to change at any time.
              Moreover, they can not constitute a commitment or guarantee on the
              part of TRADING CENTRAL. The recipient acknowledges and agrees
              that by their very nature any investment in a financial instrument
              is of a random nature and therefore any such investment
              constitutes a risky investment for which the recipient is solely
              responsible. It is specified that the past performance of a
              financial product does not prejudge in any way their future
              performance. The foreign exchange market and financial derivatives
              such as futures, CFDs (Contracts for Difference), warrants, turbos
              or certificates involve a high degree of risk. They require a good
              level of financial knowledge and experience. TRADING CENTRAL
              recommends the consultation of a financial professional who would
              have a perfect knowledge of the financial and patrimonial
              situation of the recipient of this message and would be able to
              verify that the financial products mentioned are adapted to the
              said situation and the financial objectives pursued. TRADING
              CENTRAL recommends reading the "risk factors" section of the
              prospectus for any financial product mentioned.
            </div>
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                Open a Trading Account and access new and improved Trading
                Indicators
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Open Live Account
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Tools_TradingSignals;
