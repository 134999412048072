import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import globalimg from "../../assets/images/pages/services/global-financial-advisory.jpg";
import img1 from "../../assets/images/bgservicesbanner2Ar.jpg";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  "تحسين التسعير وعقود التمويل الجديدة.",
  "تسريع عملية جمع الأموال.",
  "جلب التمويل أو تحسين الرافعة المالية على الاستثمار الحالي.",
  "تعامل بحذر مع البنوك أو المؤسسات المالية التي ليس للشركة علاقات راسخة معها حتى الآن، ولا تعرض العلاقات القائمة للخطر؛  ",
  "تأمين التمويل القادم من بلد (في دول مجلس التعاون الخليجي أو في الخارج)، سواء كان التمويل قادم من خارج الوطن / البلد الأساسي؛",
  "استكشاف العديد من خيارات التمويل المماثل، مثل القروض المجمعة وطرح السندات، أو الاكتتاب المبدئي العام والاكتتاب العام.",
];
const { Title } = Typography;

const GlobalFinancialAdvisoryAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Global Financial Advisory Service | Noor Capital"
        description="Noor Capital is the best financial advisory service that offers financial planning and investment management for a low fee."
      />

      <BgBeforeAr
        pageHeadingTitle="ابق في صدارة المنافسة مع خدمات الاستشارات المالية العالمية من نور كابيتال"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الخدمات"
        pageSubCategoryPath="#"
        pageName="الإستشارات المالية العالمية"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            الإستشارات المالية العالمية
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              تفخر نور كابيتال بأنها واحدة من أبرز المستشارين الماليين العالميين
              في الإمارات العربية المتحدة وفي منطقة الشرق الأوسط وشمال إفريقيا.
              نحن نقدم لك مجموعة شاملة من الاستشارات الإستراتيجية والمالية في
              عمليات الدمج والاستحواذ وإعادة الهيكلة وغيرها من مجالات تمويل
              الشركات من خلال فريقنا المحترف وذوي الخبرة العالية.
            </p>

            <p className="parjustify">
              مع تركيزنا على سوق الإمارات العربية المتحدة والمملكة العربية
              السعودية، نجحنا في توسيع أجنحتنا الاستشارية لتشمل مناطق أخرى في
              الشرق الأوسط وشمال إفريقيا، مما يجعلنا أكثر الشركات المالية ثقة في
              الخليج.
            </p>

            <p className="parjustify">
              نحن نقدم حلولًا قوية ونصائح متميزة للإدارة ومجالس إدارة الشركات
              الكبيرة والمتوسطة البارزة في المعاملات التي عادةً ما تكون ذات
              أهمية إستراتيجية ومالية لتلك الشركات.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={globalimg}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            الخدمات
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Title style={{ color: "#11131b", textAlign: "right" }} level={4}>
              الاستشارات المتعلقة بالديون
            </Title>

            <p className="parjustify text-right">
              نقدم لك في نور كابيتال حلاً عمليًا وفوريًا في زيادة تمويل الديون
              لكل من التقليدي والإسلامي. تعتبر خدمة الاستشارات المتعلقة بالديون
              مثالية لكلاً من المجموعة، أو عملية استحواذ محددة، أو مشروع معين،
              أو إعادة تمويل لمشروع قائم، أو التنازل عن أصل أو شركة أو حصة حيث
              نقوم بجمع التمويل المستهدف من البنوك أو المؤسسات المالية أو
              الكيانات الحكومية ذات الصلة.
            </p>

            <p className="parjustify text-right">
              تقدم نور كابيتال للشركات خدمات استشارية سريعة خاصة بالديون، خاصة
              إذا كنت تبحث عن:
            </p>

            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />

            <Title style={{ color: "#11131b", textAlign: "right" }} level={4}>
              أسواق رأس المال
            </Title>

            <p className="parjustify text-right">
              تهدف نور كابيتال إلى أن تصبح رائدة في سوق الاكتتاب فيما يخص طرح
              الأسهم الجديدة في البورصة المحلية. نحن نؤمن بشدة بضرورة زيادة
              السيولة التجارية في المنطقة وجذب رؤوس الأموال حيث يعتمد طموحنا في
              اكتساب الريادة على العروض العامة الأولية الواسعة من خلال التزامنا
              بتطوير خبرتنا الفريدة في المعاملات المعقدة، وقوة البحث، وبناء سجل
              حافل وزيادة قدرات التوزيع، وسيكون أحد العوامل الحاسمة في جهود
              الاكتتاب في طرح الأسهم لدينا هو علاقة العمل الوثيقة بين مستشاري
              الاستثمار ومحللي الأبحاث وطاقم المبيعات المعتمد من قبل قسم الخدمات
              المصرفية الاستثمارية لدينا.
            </p>
          </Col>
        </Row>
        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                دع المحترفين يوجهونك لاتخاذ القرارات المالية الصحيحة
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/ar/about/contact-us"
                >
                  اتصل بنا
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block">
        <div className="row">
          <div className={globalstyle.container}>
            <div className="col" style={{ padding: "15px" }}>
              <h1
                className="display-5 text-center"
                style={{ color: "#11131b" }}
              >
                خدمات أخرى
              </h1>
            </div>

            <div className="row text-right">
              <div className="col-12 col-md-8 m-auto col-lg-4">
                <div className="fdb-box fdb-touch">
                  <h4>إدارة الأصول</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    نحن نقدم حلًا سلسًا لإدارة الأصول للمؤسسات والأفراد ذوي
                    الثروة المالية الكبيرة والمستثمرين الأفراد من خلال قنوات
                    مباشرة وأطراف أخرى
                  </p>
                  <p>
                    <Link to="/ar/services/asset-management">قراءة المزيد</Link>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>الاستثمار المباشر</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    نقدم مجموعة واسعة من الفرص المالية، بما في ذلك الاستثمارات
                    وإدارة الأصول والاستشارات المالية وإدارة الصناديق والوساطة
                    في الأسواق العالمية
                  </p>
                  <p>
                    <Link to="/ar/services/investment">قراءة المزيد</Link>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>إدارة صناديق الإستثمار</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      توفر لك نور كابيتال حلاً قويًا وسلسًا لإدارة صناديق
                      الاستثمار، خاصةً لشركة إدارة الأصول وصناديق الاستثمار. نحن
                      نقدم حلاً جاهزًا لحساب صافي قيمة الأصول
                    </p>
                  <p>
                    <Link to="/ar/services/funds-administration">
                      قراءة المزيد
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GlobalFinancialAdvisoryAr;
