import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgtradingbanner3.jpg";
import precious_metals from "../../assets/images/pages/instruments/precious-metals.jpg";
import Products from "./products.js";
import ProductsAr from "./productsAr";
import Instruments_Bullions_Table from "./trading_Instruments_Bullions_table";

const style = { padding: "50px 0" };
const { Title } = Typography;

const Instruments_PreciousMetalsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Precious Metals Trading - Start Trading Gold & Silver"
        description="Noor Capital allows trading the spot price for metals including Gold or Silver against the US Dollar or Euro and the metals Platinum or Palladium against the US Dollar."
      />

      <BgBeforeAr
        pageHeadingTitle="استثمارك قيم مثل المعادن الثمينة - استثمر الآن"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="التداول"
        pageMainCategoryPath="#"
        pageSubCategoryName="الأسواق"
        pageSubCategoryPath="#"
        pageName="المعادن الثمينة"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          تداول المعادن النفيسة
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            تعد المعادن الثمينة من أكثر أدوات التداول شهرة، كما تمتاز بأنها ذات قيمة عالية، ويُعد الذهب والبلاتين والفضة من بين أكثر المعادن الثمينة تداولًا يليها الإيريديوم والبلاديوم. مع نور كابيتال، يمكنك تداول المعادن النفيسة من خلال الاستثمار في العقود الآجلة وشراء حصة من الشركات العامة المتداولة التي تعمل في مجال إنتاج واستكشاف المعادن الثمينة. ونظرًا لكون العقود الآجلة وسيلة مضاربة، يستفيد مستثمرينا من الرافعة المالية حيث يمكنك شراء وبيع المعادن الثمينة في وقت محدد في المستقبل.
            </p>

            <p className="parjustify">
            يعتبر الذهب هو أكثر المعادن النفيسة تداولا في جميع أنحاء العالم. وتُحدد قيمة الذهب وتتأثر 24 ساعة في اليوم وسبعة أيام في الأسبوع بالأحداث المختلفة في العالم والسياسات الحكومية. ويمكن للمستثمرين تداول الذهب في العقود مقابل الفروقات مثل الذهب مقابل الدولار الأمريكي، وكذلك الأمر بالنسبة لعقود الفضة والبلاتين.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={precious_metals}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        
        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          قائمة المواصفات
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Instruments_Bullions_Table />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              ابدأ تداول المعادن الثمينة الآن
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ProductsAr />
    </div>
  );
};

export default Instruments_PreciousMetalsAr;
