import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";

import img1 from "../../assets/images/technical.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import RequestCallBackFormAr from "../forms/requestCallBackFormAr";

const style = { padding: "50px 0" };

const CallBackRequestAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Request Call Back from Noor Capital"
        description="Have A question or inquiries related to Noor Capital's products or services, request a call back now and our specialist will be in touch"
      />
      <BgBeforeAr
        pageHeadingTitle="طلب معاودة الاتصال"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الدعم"
        pageSubCategoryPath="#"
        pageName="طلب معاودة الاتصال"
      />

      <section
        className="fdb-block pt-0"
        style={{ paddingBottom: "10px", backgroundColor: "#fff" }}
      >
        <br />

        <div className={globalstyle.container}>
          <div className="row-100" />
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <p className={globalstyle.parjustify}>
                هل لديك سؤال أو استفسارات تتعلق بمنتجات نور كابيتال أو خدماتها؟
              </p>

              {/* General inquiries */}
              <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> الاستفسارات العامة </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:info@noorcapital.ae">info@noorcapital.ae</a>
              </p>

              {/* Customer Services */}
              <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> خدمة العملاء </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:cs@noorcapital.ae">cs@noorcapital.ae</a>
              </p>
              <p className="text-right">
                <i
                  class="fas fa-phone text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">0097142795400</a>
              </p>

              {/* Onboarding */}
              <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> فتح الحسابات </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:onboarding@noorcapital.ae">
                  onboarding@noorcapital.ae
                </a>
              </p>
              <p className="text-right">
                <i
                  class="fas fa-phone text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">0097142795400</a>
              </p>
            </div>

            <div className="col-12 col-md-6 ml-auto text-right">
              <h6 style={{ color: "#1b75bc" }}>
                يرجى ملء نموذج معاودة الاتصال أدناه، وسيتصل بك أحد وكلائنا في
                غضون 48 ساعة
              </h6>
              <br />

              <RequestCallBackFormAr />
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <br />
    </div>
  );
};

export default CallBackRequestAr;
