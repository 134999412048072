import React from "react";
import axios from "axios";
import { BASE_URL, POSTS } from "../../constants";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgblogAr.jpg";

export default function Activities_DetailsAr(props) {
  const [post, setPost] = React.useState(null);
  const {
    match: {
      params: { postId },
    },
  } = props;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "ar",
  };
  React.useEffect(() => {
    axios
      .get(`${BASE_URL}${POSTS}${postId}/`, {
        headers: headers,
      })
      .then((post) => {
        console.log(post.data);
        setPost(post.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (!post) return null;
  return (
    <div>
      <PageseMetaTags
       title="نور كابيتال / الأنشطة"
       description="نور كابيتال / الأنشطة"
      />

      <BgBeforeAr
        pageHeadingTitle="نور كابيتال / الأنشطة"
        pageHeadingDescription={post.title}
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName={post.main_cat.name}
        pageSubCategoryPath="#"
        pageName={post.title}
      />

      <br />

      <section className="fdb-block">
        <div className="container">
          <div className="row justify-content-center">
            <div className="row justify-content-center mb-5">
              <div className="col text-center">
                <h1 style={{ color: "#1b75bc" }}>{post.title}</h1>

                <div className="row no-gutters">
                  <div className="col-12 col-sm-8 col-md-3 m-auto">
                    <p style={{ textAlign: "center" }}>بواسطة: {post.author}</p>
                    <p style={{ textAlign: "center" }}>
                      {post.main_cat.name} / {post.sub_cat.name}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <img
                  alt="Noor Capital Blog"
                  className="img-fluid mt-6"
                  // Note he we're using the backend url to get the images
                  src={`${BASE_URL}${post.body_image}`}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="row justify-content-center mb-5">
              <div className="col-12">
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "justify",
                    lineHeight: "30px",
                    padding: "15px",
                  }}
                  dangerouslySetInnerHTML={{ __html: post.description }}
                />
              </div>
            </div>
          </div>

          <br />
        </div>
      </section>

      <br />
    </div>
  );
}
