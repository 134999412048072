import React from "react";
import { Row, Col, Typography } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtools.jpg";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const NCforms = () => (
  <Tabs defaultActiveKey="1" centered size="large">
    <TabPane tab="FX Trading (MT4) Desktop " key="1">
      <section className="fdb-block section1">
        <div>
          {/* 333333333 */}
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-12 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              Download FX Trading (MT4)
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/jeSfCV1FbUg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
              Log in on FX Trading (MT4)
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/nRkbPBR8Osk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
              Change chart color on FX Trading (MT4)
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/ZSYYZJdih7s"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <br />
          
          {/* 333333333 */}
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              Familiarize toolbars on FX Trading (MT4)
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/PzauQLEkgVE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            Place a market order on FX Trading (MT4)
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/REAeh_wsIo0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            Place a pending order on FX Trading (MT4)
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/mvcTRv_nrcI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>


          <br />
          
          {/* 333333333 */}
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              Set SL and TP points on FX Trading (MT4)
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/7DxkFaFkxLU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
              Close a trade on FX Trading (MT4)
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/6dWQ2NZ3IBI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
              Add chart indicators on FX Trading (MT4)
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/GQo8_UnGYsU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>


        </div>
      </section>
    </TabPane>


    <TabPane tab="FX Trading (MT4) Mobile " key="2">
      <section className="fdb-block">
        <div>
          {/* 333333333 */}
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              Download FX Trading Mobile App
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/fKpGquSSn_I"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
              Log in to FX Trading Mobile App
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/rG2Tm8_MpgY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
              Change chart colors on FX Trading Mobile App
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/P8AcwVtPJuQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <br />
          
          {/* 333333333 */}
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              Place a market order on FX Trading Mobile App
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/eaYU7_h_67I"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            Place a pending order on FX Trading Mobile App
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/ewvXtFT297s"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
              Set SL and TP points on FX Trading Mobile App
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/thJD9V0Pefc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>


          <br />
          
          {/* 333333333 */}
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              Close a trade on FX Trading Mobile App
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/VzMnWPCmjfU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
              Add chart indicators on FX Trading Mobile App
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/2QBK-IhOXC4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            
            </div>
          </div>


        </div>
      </section>
    </TabPane>


  </Tabs>
);
const { Title } = Typography;

const Tools_VideoTutorials = () => {
  return (
    <div>
      <PageseMetaTags title="Video Tutorials - Noor Capital" description="" />

      <BgBefore
        pageHeadingTitle="Video Tutorials"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName="Video Tutorials"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <p className="parjustify" style={{ fontSize: "16.5px" }}>
            Noor Capital always strives to train and develop traders in the
            financial markets to be eligible for trading activity and has
            curated video tutorials, these video tutorials will help on how to
            use Noor Capital's FX Trading Mobile App, manage your account, and
            access all the features to help you trade easily.
          </p>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <NCforms />
          </Col>
        </Row>
      </div>
      <br />

      <br />

      <style jsx>
        {`
          .mt-5,
          .my-5 {
            margin-top: 2rem !important;
          }
          a {
            color: #9ea0a2;
           
          }
          .section1{
          padding: 0px 0;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          
          padding: 20px;
      }
        `}
      </style>
    </div>
  );
};

export default Tools_VideoTutorials;
