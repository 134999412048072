import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgnoortraderAr.jpg";
import { Link } from "react-router-dom";
import ntplatformmobile from "../../assets/images/pages/trading/ntplatformmobile.png";
import WhyNoorAr from "./whyNoorAr";


const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader_NoorTraderMobileAr = () => {
  return( <div>
          <PageseMetaTags
                title="NoorTrader Mobile App - Noor Capital"
                description="Buying and selling precious metals has never been so easy and innovative."
            />
            <BgBeforeAr 
            pageHeadingTitle="تداول المعادن الثمينة مُخصص للمستثمرين الأذكياء"
            pageHeadingDescription="ابدأ رحلة تداول المعادن الخاصة بك أينما كنت "
            pageHeadingbg={img1}
            />
        <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="تطبيق نورتريدر للهواتف"
      />



<div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          قم بتنزيل تطبيق نورتريدر الآن ، وابدأ بالتداول على المعادن الثمينة أثناء التنقل
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            شراء وبيع المعادن الثمينة لم يكن أبدًا بهذه السهولة والابتكار، مع تطبيق نور تريدر للهاتف المحمول فأنت أمام تطبيق مثالي من التكنولوجيا السلسة والميزات الفريدة والواجهة سهلة الاستخدام.
            </p>
            <p className="parjustify text-right">
            يمكنك الآن شراء وبيع وإدارة استثمارك في المعادن الثمينة أينما كنت باستخدام تطبيق نور تريدر للهاتف المحمول. متوفر على جوجل بلاي ستور لنظام أندرويد وأب استور للأيفون، سيساعدك التطبيق في تغيير الطريقة التي تتداول بها، مما يجعل تداولك سهل الإدارة والتأمين.
            </p>
            
            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-android"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>Android</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://play.google.com/store/apps/details?id=com.noorindex.noortraderv1&hl=en&gl=US"
                    target="_blank"
                  >
                    تحميل
                  </a>
                </p>
              </div>
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 ml-sm-auto mr-sm-auto mt-5 mt-md-0">
                <i
                  class="fab fa-apple"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>iPhone</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://apps.apple.com/ae/app/noortrader/id1388986392"
                    target="_blank"
                  >
                    تحميل
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                الميزات 
                </Title>
                <h5>
                لماذا تحتاج إلى تحميل تطبيق نور تريدر للهاتف المحمول - على الفور؟
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الوصول الفوري إلى سوق السبائك العالمية.

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                 ابدأ في شراء وبيع وإدارة معادنك الثمينة بنقرة واحدة.

                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                 البرنامج متاح 24/7 حيث يمكنك الوصول إلى استثمارك طوال الوقت.

                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  متوافق مع جميع متصفحات الويب والهواتف الذكية.

                </p>

                <br />
               

              </div>

              <div className="col-12 col-sm-6 col-lg-3  text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على سعر لا مثيل له في السوق الفوري مع خيار الإيداع الجزئي.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  وصول آمن ومشفّر بنسبة 100٪.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  متوافق مع جميع الهواتف الذكية.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  متاح للتنزيل على بلاي ستور و أب ستور.
                </p>

                <br />
                

              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="ntplatformmobile" className="img-fluid" src={ntplatformmobile} />
              </div>
            </div>
          </div>
        </section>
      </div>


      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              ابدأ رحلة التداول في سوق السبائك أينما كنت </Title>

              <p className="mt-5 mt-sm-4">
              <Link
                  className="btn btn-outline-primary"
                  to="/physical-trading-platforms/open-noor-trader-live"
                  
                >
                  تسجيل حساب حقيقي
                </Link>

              </p>
            </div>
          </div>
        </div>
      </section>

  </div>
  )

};

export default NoorTrader_NoorTraderMobileAr;