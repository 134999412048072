import React from "react";
import { Link } from "react-router-dom";
import "./mainPage.css";
import globalstyle from "../../style.module.css";
import fx from "../../assets/images/home/forex.svg";
import metal from "../../assets/images/home/metal.svg";
import energies from "../../assets/images/home/energies.svg";
import indices from "../../assets/images/home/indices.svg";
import share from "../../assets/images/home/shares.svg";
import AOS from "aos"

AOS.init(
  {duration: 2500,
    delay: 700,
  }
);

const MainPage2 = () => {
  return (
    <>
      {/* START ROW 2222222 */}

      <div>
        <section className="fdb-block">
          <div className="row">
            <div className={globalstyle.container}>
              <div className="row justify-content-center">
                <div className="col text-left">
                  <h2 className="display-5" style={{ color: "#1b75bc" }}>
                    Why Noor Capital ?
                  </h2>
                  <p className="lead">
                    Noor Capital P.S.C is a Private Joint Stock Company,
                    established in 2005, registered with the Abu Dhabi
                    Department of Economic Development. Being duly regulated by
                    the Securities and Commodities Authority (SCA), we carry out our activities
                    related to investment management, financial advisory, and
                    online trading in a secured environment...
                    <Link
                      to="/about/why-noor-capital"
                      // style={{ paddingLeft: "8px" }}
                    >
                    Read more
                    </Link>
                  </p>
                </div>
              </div>

              <div className="col" style={{ padding: "15px" }}>
                <h1
                  className="display-5 text-center"
                  style={{ color: "#1b75bc" }}
                >
                  Services
                </h1>
              </div>

              <div className="row text-left">
                <div className="col-12 col-md-8 m-auto col-lg-3">
                  <div className="fdb-box fdb-touch">
                    <h4>Financial Advisory</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      With Noor Capital's initial focus being UAE and KSA, we
                      have successfully widened our advisory service across the
                      MENA regions.
                    </p>
                    <p>
                      <Link to="/services/global-financial-advisory">
                        Read more
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8 m-auto col-lg-3 pt-5 pt-lg-0">
                  <div className="fdb-box fdb-touch">
                    <h4>Asset Management</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      Noor Capital provides a solid asset management solution to
                      institutions, high net worth individuals, and retail
                      investors through direct.
                    </p>
                    <p>
                      <Link to="/services/asset-management">Read more</Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8 m-auto col-lg-3 pt-5 pt-lg-0">
                  <div className="fdb-box fdb-touch">
                    <h4>Investment</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      Noor Capital offers exclusive investment opportunities to
                      regional investors, helping them to create and manage
                      their investments safely.
                    </p>
                    <p>
                      <Link to="/services/investment">Read more</Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8 m-auto col-lg-3 pt-5 pt-lg-0">
                  <div className="fdb-box fdb-touch">
                    <h4>Fund Administration</h4>
                    <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      Noor Capital provides you with a highly efficient and
                      all-inclusive Fund Administration solution, especially for
                      setting up asset management.
                    </p>
                    <p>
                      <Link to="/services/funds-administration">Read more</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Feature 24 */}
        <section className="fdb-block" style={{ padding: "10px 0" }}>
          <div className={globalstyle.container}>
            <div className="row align-items-top">
              <div className="col-12 col-md-6 col-xl-6 m-auto">
                <div className="text-left">
                  <h2 className="display-4" style={{ color: "#1b75bc" }}>
                    A world of investment opportunities
                  </h2>
                </div>
                <p style={{ color: "#8892a0", fontSize: "20px" }}>
                  Trade 1000+ of instruments and asset classes worldwide markets through multiple trading platforms.
                </p>
              </div>

              <div className="col-12 col-md-6 pt-5 pt-md-0">
                <div className="row justify-content-left">
                  <div className="col-3 m-auto text-left">
                    <img
                      alt="Forex"
                      className="fdb-icon"
                      src={fx}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/forex">
                        Forex <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Precious Metals"
                      className="fdb-icon"
                      src={metal}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/precious-metals">
                        Precious Metals <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Energies"
                      className="fdb-icon"
                      src={energies}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/energies">
                        Energies <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="row justify-content-left mt-4 mt-xl-5">
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Indices"
                      className="fdb-icon"
                      src={indices}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/indices">
                        Indices <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Share CFDs"
                      className="fdb-icon"
                      src={share}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/shares-cfds">
                        Share CFDs <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* END ROW 2222222 */}
    </>
  );
};

export default MainPage2;
