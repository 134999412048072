import React from "react";
import { Table } from "antd";

const columns2 = [

  {
    title: "Instrument",
    dataIndex: "instrument",
    key: "instrument",
  },

  {
    title: "Trading Hours (NYT)",
    dataIndex: "trading_hours",
    key: "trading_hours",
    responsive: ["md"],
  },

  {
    title: "Contract Size",
    dataIndex: "contract_size",
    key: "contract_size",
    responsive: ["md"],
  },

  {
    title: "Pending Orders Distance",
    dataIndex: "pending_orders_distance",
    key: "pending_orders_distance",
  },

  {
    title: "Minimum Trade Size",
    dataIndex: "minimum",
    key: "minimum",
    responsive: ["md"],
  },
  
  {
    title: "Maximum Trade Size",
    dataIndex: "maximum",
    key: "maximum",
    responsive: ["md"],
  },

  {
    title: "Leverage",
    dataIndex: "leverage",
    key: "leverage",
    
  },

];


// Table Content ///////////////////////////////////////////////

const data2 = [
  {
    key: "1",

    instrument: "AUDCADx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  AUD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "2",

    instrument: "AUDCHFx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  AUD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "3",

    instrument: "AUDNZDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  AUD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "4",

    instrument: "AUDUSDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  AUD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "5",

    instrument: "CHFJPYx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  CHF",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "6",

    instrument: "CADJPYx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  CAD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "7",

    instrument: "EURAUDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  EUR",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "8",

    instrument: "EURCADx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  EUR",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "9",

    instrument: "EURCHFx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  EUR",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "10",

    instrument: "EURGBPx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  EUR",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "11",

    instrument: "EURJPYx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  EUR",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "12",

    instrument: "EURNZDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  EUR",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "13",

    instrument: "EURUSDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  EUR",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "14",

    instrument: "GBPAUDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  GBP",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "15",

    instrument: "GBPCADx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  GBP",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "16",

    instrument: "GBPCHFx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  GBP",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "17",

    instrument: "GBPJPYx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  GBP",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "18",

    instrument: "GBPNZDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  GBP",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "19",

    instrument: "GBPUSDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  GBP",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "20",

    instrument: "NZDUSDx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  NZD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "21",

    instrument: "USDCADx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  USD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "22",

    instrument: "USDJPYx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  USD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "23",

    instrument: "USDCHFx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  USD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "24",

    instrument: "AUDJPYx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  AUD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "25",

    instrument: "NZDJPYx",

    trading_hours: "Sunday 17:00 - Friday 17:00",

    contract_size: "100000  NZD",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  
];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const Instruments_Fx_Table = () => {
  return (
    <div>
      <Table 
      columns={columns2} 
      dataSource={data2} 
      onChange={onChange}
      pagination={{ position: 'topLeft' }}
      />

      <style jsx>{`
        .ant-table-thead > tr > th {
          font-size: 15px;
        }
        .ant-table-thead > tr > td {
        }
      `}</style>
    </div>
  );
};

export default Instruments_Fx_Table;
