import React from "react";
import globalstyle from "../../globalstyle.module.css";
import UpHeader from "./upHeader";
import MenuBar from "./menuBar";


const Header = (props) => {
  
  return (
    <>
      <UpHeader />
      <MenuBar />
    </>
  );
};

export default Header;
