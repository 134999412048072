import React from "react";
import { Row, Col } from "antd";

import globalstyle from "../../style.module.css";

import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };

const CookiesPolicyAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Cookies Policy - Noor Capital"
        description="Noor Capitals' Cookies Policy"
      />

      <BgBeforeAr
        pageHeadingTitle="ملفات تعريف الارتباط"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الموقع الإلكتروني"
        pageSubCategoryPath="#"
        pageName="ملفات تعريف الارتباط"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>ملفات تعريف الارتباط</h4>
              <p className={globalstyle.parjustify}>
                ملفات تعريف الارتباط: يستخدم موقع نور كابيتال "ملفات تعريف
                الارتباط" وغيرها من التقنيات المماثلة المرسلة من خلال متصفحك
                لتتبع وتعزيز تجربة الزوار على موقعنا. يقتصر استخدام ملفات تعريف
                الارتباط بشكل صارم على أغراض التسويق والتحليل فقط، وهو ما يسمح
                لنا بجمع بعض المعلومات حول نوع المتصفح والموقع وعنوان بروتوكول
                الإنترنت IP، والمزيد من ملفات تعريف الارتباط. على الرغم من أن
                معظم المتصفحات معدة لقبول ملفات تعريف الارتباط، إلا أنه يمكنك
                ضبط متصفحك على رفض ملفات تعريف الارتباط إذا كنت تفضل ذلك. يرجى
                ملاحظة أن عدم قبول ملفات تعريف الارتباط قد يؤثر على تجربتك مع
                الموقع.
              </p>
             
                <a
                  href="#"
                  className="btn btn-success ml-4"
                  style={{ width: "250px","marginLeft": "35px" }}
                >
                  قبول ملفات تعريف الارتباط
                </a>
              
              <a
                  href="#"
                  className="btn btn-secondary "
                  style={{ width: "250px" }}
                >
                  رفض ملفات تعريف الارتباط
                </a>

            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CookiesPolicyAr;
