import React from "react";
import { Row, Col } from 'antd';

import globalstyle from '../../style.module.css';

import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = { padding: "50px 0", alignSelf: "justify" };

const PrivacyPolicy = () => {
  return( <div>
          <PageseMetaTags
                title="Privacy Policy - Noor Capital"
                description="Noor Capital is committed to protecting your privacy and maintaining the security of any personal information that we receive from you."
            />

            <BgBefore 
            pageHeadingTitle="Privacy Policy"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Privacy Policy"
      />

<div className={globalstyle.container}>
       
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Privacy Policy</h4>
              <p className={globalstyle.parjustify}>
              At Noor Capital, we believe in protecting our client's privacy and maintaining the highest standard of security of our clients' personal information. Please read our Privacy Policy until the end to understand what information we collect and how we use it.
              </p>
            </div>
          </div>

          {/* 22222222222222222 */}

          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>
              Personal information
              </h4>
              <p className={globalstyle.parjustify}>
              The personal information we may collect from our clients includes (but not limited to) your full name, mailing address, telephone number, mobile number, e-mail address, and other information that is mandatory for verification purpose. The personal information of our clients can be used to open, administer and maintain your Account with us.
              </p>
            </div>
          </div>

          {/* 3333333333333333333 */}

          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>
              Cookies
              </h4>
              <p className={globalstyle.parjustify}>
              Noor Capital website uses "Cookies" and other similar technologies sent through your browser to track and enhance our visitor's experience on our website. The cookies are strictly used for marketing and analysis purposes only. It allows us to collect some information about your browser type, location, IP address, and more cookies enabled by enabling cookies. Although most browsers are set to accept the cookies, you can set your browser to refuse the cookies if you prefer. Please note that not accepting cookies might affect your experience with the website.
              </p>
            </div>
          </div>

          {/* 44444444444444444444 */}

          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Google Analytics</h4>
              <p className={globalstyle.parjustify}>
              We may also use Google Analytics Advertising Features, which allows us to collect information on our website visitor demographics. You may opt-out of this by contacting Google at any time.
              </p>
            </div>
          </div>

          {/* 5555555555555555555555 */}

          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Security measures</h4>
              <p className={globalstyle.parjustify}>
              We practice strict security procedures while storing and disclosing information given to us. Any access to your personal information is protected with electronic and procedural security to safeguard any loss, damage, or misuse.
              </p>
            </div>
          </div>

           {/* 6666666666666666 */}

           <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Information collection</h4>
              <p className={globalstyle.parjustify}>
              We use the collected information provided by our client only to provide you with the service request, identification, and update on the latest news and offerings. The access is only given to our employees or authorized agents.
              </p>
            </div>
          </div>

           {/* 77777777777777777 */}

           <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Direct marketing by Noor Capital PSC Group members</h4>
              <p className={globalstyle.parjustify}>
              We may also use your Personal Data like name, telephone number, home address, and e-mail to inform you about the latest news, new and existing financial services related products, services, and promotions that may be of interest to you for Direct Marketing purposes. We may also use online tracking information for Direct Marketing. We will not use any personal information collected via online tracking information for Direct Marketing. If you do not wish us to use your Data for Direct Marketing purposes, you can always opt-out by contacting us through the contact information given in the website.
              </p>
            </div>
          </div>

          {/* 8888888888888888888 */}

          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Third parties</h4>
              <p className={globalstyle.parjustify}>
              Please note that we may disclose some of your personal information to our associates for business purposes and the third-party suppliers we engage that process data on our behalf. All these parties need to keep your data confidential. We may disclose this information to the government and enforcement authorities if there is a request from them to investigate any breach of law or regulatory rules by their procedures.
              </p>
            </div>
          </div>

          {/* 9999999999999999999 */}

          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Account closure</h4>
              <p className={globalstyle.parjustify}>
              If you want to close your Account with us, Noor Capital PSC will retain all your personal information, but we will only use it to comply with our regulatory requirements. Noor Capital will only keep such data for a period that is no longer than necessary and permitted by the relevant local laws. We will also periodically contact you to notify you of new products, services, and promotions we may offer from time to time, but you can opt-out if you wish. 
              </p>
            </div>
          </div>
        
        
        </section>

  </div>

  </div>
  )

};

export default PrivacyPolicy;