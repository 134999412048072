import React, { useEffect } from "react";

const Tools_EconomicCalendarWidgetAr = () => {
  useEffect(() => {
    if (document.getElementById("chart")) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        colorTheme: "dark",
        isTransparent: false,
        displayMode: "adaptive",
        locale: "ar",
        width: "100%",
        height: 600,
        importanceFilter: "-1,0,1",
      });

      document.getElementById("chart").appendChild(script);
    }
  });

  return <div id="chart"></div>;
};

export default Tools_EconomicCalendarWidgetAr;
