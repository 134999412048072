import React from "react";
import { Row, Col } from 'antd';
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import { Link } from "react-router-dom";

import underconstruction from "../../assets/images/underconstruction.jpg"



const UnderConstruction = () => {
  return( <div>
            <PageseMetaTags 
            title="Section is Under Construction"
            description=""
            />
        

    <div className={globalstyle.container}>

    <br/>
        <br/>
        
    <Row>
            <Col span={24} className="gutter-row" style={{"textAlign":"center"}}>
            <Link to="/" class="btn btn-primary" btn-lg>Go to Home Page</Link>
            </Col>
        </Row>


        <Row>
            <Col span={24} className="gutter-row">
            <img alt="underconstruction" className="img-fluid"  src={underconstruction}  />
            </Col>
        </Row>

        


        
        <br/>
        <br/>

    </div>

  </div>
  )

};

export default UnderConstruction;