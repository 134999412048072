import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL, POSTS } from "../../constants";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgblogAr.jpg";

export default function AwardsAr() {
  const [posts, setPosts] = React.useState([{}]);
  const mainCat = 1;
  const subCat = 2;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "ar",
  };
  React.useEffect(() => {
    // Note here we set the main_cat and sub_cat manually
    axios
      .get(
        `${BASE_URL}${POSTS}?main_cat=${mainCat || ""}&sub_cat=${
          subCat || ""
        }`,
        {
          headers: headers,
        }
      )
      .then((posts) => {
        console.log(posts.data);
        setPosts(posts.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <PageseMetaTags
        title="نور كابيتال / الجوائز"
        description="نور كابيتال / الجوائز"
      />

      <BgBeforeAr
        pageHeadingTitle="نور كابيتال "
        pageHeadingDescription="الجوائز"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="المركز الاعلامي"
        pageSubCategoryPath="#"
        pageName="الجوائز"
      />

      <br />

      <section className="fdb-block">
        <div className="container">
          {posts.map((post) => (
            <>
              <div className="row justify-content-center">
                <div className="row justify-content-center mb-5">
                  <div className="col-12">
                    <Link to={`/ar/awards/${post.id}`}>
                      <img
                        alt="Noor Capital Blog"
                        className="img-fluid mt-6"
                        // Note he we're using the backend url to get the images
                        src={`${BASE_URL}${post.featured_image}`}
                      />
                    </Link>
                  </div>
                </div>

                <div className="col text-right">
                  <h1 style={{ color: "#1b75bc" }}>{post.title}</h1>

                  
<p
                    className="lead"
                    style={{"textAlign":"justify"}}
                    dangerouslySetInnerHTML={{
                      __html: post.description && post.description.substr(0, 350) ,
                    }}
                  />

                  

                  <div className="row no-gutters">
                    <div className="col-12 col-sm-8 col-md-3 m-auto">
                      <p style={{ textAlign: "right" }}>بواسطة: {post.author}</p>
                    </div>
                    <div className="col-12 col-sm-8 col-md-3 m-auto pt-3 pt-md-0">
                      <p style={{ textAlign: "left" }}></p>
                    </div>
                    <div className="col-12 col-sm-8 col-md-3 m-auto pt-3 pt-md-0">
                      <p style={{ textAlign: "left" }}>
                        <Link to={`/ar/awards/${post.id}`}>
                          قراءة المزيد <i className="fas fa-angle-left" />
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <br />
            </>
          ))}
        </div>
      </section>

      <br />
    </div>
  );
}
