import React from "react";
import { Row, Col } from "antd";

import globalstyle from "../../style.module.css";

import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = { padding: "50px 0", alignSelf: "justify" };

const Accounts_TradingConditions = () => {
  return (
    <div>
      <PageseMetaTags
        title="Forex Trading Conditions - Noor Capital"
        description="Learn about Noor Capital forex trading conditions by reviewing our Spreads, Swap Rates, available Currency pairs and Metals."
      />

      <BgBefore
        pageHeadingTitle="Start your trading journey by understanding the Trading Conditions"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Trading"
        pageMainCategoryPath="/"
        pageSubCategoryName="Accounts"
        pageSubCategoryPath="#"
        pageName="Trading Connditions"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-left">
              <p className={globalstyle.parjustify}>
                Before you decide to invest and trade with Noor Capital’s
                various products, please read the terms and conditions carefully
                till the end.
                <br />
                Since Forex, Currencies CFD, Commodities, Indices, and Equities
                are margin traded products that contain a high risk of losing
                your initial deposits, please read the full risk warning before
                opening an account with Noor Capital.
              </p>
            </div>
          </div>

          {/* 22222222222222222 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Market Information Sheets</h4>
              <p className={globalstyle.parjustify}>
                Find information about opened and closed trades in our Market
                Information Sheets (M.I.S.), which are available online. Please
                note that not all trades can be opened or closed 24 hours daily.
                Most of them are subject to strict opening and closing times,
                which can fluctuate depending on the market. The orders and
                positions are based on Noor Capital prices and not on an
                exchange. Based on the market, our prices will usually be based
                on an exchange price but can fluctuate away from the underlying
                fees due to various reasons.
              </p>
            </div>
          </div>

          {/* 33333333333333 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Online Trading Systems</h4>
              <p className={globalstyle.parjustify}>
                Online trading systems are subject to risks, including, but not
                limited to, software and hardware failure and internet
                disconnection. Noor Capital has no control over signal power,
                its reception or routing via the internet, the configuration of
                your equipment, reliability of its connection, and inability to
                be responsible for distortions, communication failures, or
                delays when trading via the internet.
              </p>
            </div>
          </div>

          {/* 4444444444444 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Margin Trading</h4>
              <p className={globalstyle.parjustify}>
                Based on leverage, margin trading helps you to place large
                trades with just a small amount of money as a margin. If the
                price goes up in your favor, you can significantly increase your
                profits. However, even a slight movement in price against you
                can lead to a significant loss, and you may need to deposit
                additional margin immediately to keep the position open. You are
                100% liable for any losses that may occur once your position is
                closed.
              </p>
            </div>
          </div>

          {/* 55555555555555 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Trading Schedules</h4>
              <p className={globalstyle.parjustify}>
                <ol>
                  <li>
                    1 - Noor Capital provides you with execution services 24/5
                    from .
                  </li>
                  <li>
                    2 - Some trading instruments are exempted from these
                    services that halt several times.
                  </li>
                  <li>
                  3 - Find below the guide of exact information about each traded Instrument.
                  </li>
                  <li>
                    4 - Noor Capital has full rights to close trading early,
                    modify or delay session opening. This ensures and provides
                    you with the best execution and trading conditions,
                    ultimately protecting our client's investment from the
                    market movement.
                  </li>
                  <li>
                    5 - Any changes to the regular trading hours of each
                    Instrument will be announced through the registered e-mail
                    address.
                  </li>
                </ol>
              </p>
            </div>
          </div>

          {/* 888888888888 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Limitation of Liability</h4>
              <p className={globalstyle.parjustify}>
                Noor Capital P.S.C. does not claim any liability for any claims,
                losses, costs, expenses including attorneys' fees, and damages
                of whatever kind or nature, including without limitation
                general, special, incidental, consequential, punitive, exemplary
                or multiple damages based on any theory of liability, in
                connection with any use of Noor Capital P.S.C. s website and the
                information contained therein, even if Noor Capital P.S.C. has
                been advised of the possibility of such damages, defects or
                delay in operation or transmission, computer viruses or system
                failures, and in the case of the emergence of such loss, damage,
                expenses or compensation.
              </p>
              <p className={globalstyle.parjustify}>
                The use of hyperlinks to third-party websites accessed from Noor
                Capital P.S.C. is at the user's discretion and responsibility.
                It is understood and agreed that Noor Capital P.S.C. did not
                examine, check or control the adoption of content or cannot be
                held responsible for the accuracy of the information provided or
                the opinions expressed by these sources.
              </p>
            </div>
          </div>

          {/* 77777777777777 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Swap Free Accounts</h4>
              <p className={globalstyle.parjustify}>
                Noor Capital PSC DOES NOT charge any Swaps or Interest on ALL
                Trading Accounts. The no-charge is applicable on all Open
                Trades. However, Storage Fees shall be applied to positions
                held open overnight, and for all open Spot CFD positions, at the
                end of each business day, daily, throughout the transaction
                period. The Storage Fees schedule is as follows:
              </p>
            </div>
          </div>

          {/* 9999999999999 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Storage Fees Schedule</h4>
              <p className={globalstyle.parjustify}>
                <ol>
                  <li>
                    <b>1 - CFDs Type 1: </b>
                    <br />
                    AUDJPY, EURCAD, EURHUF, NZDCAD, NZDUSD, USDCHF, USDDKK,
                    USDSEK, AUDCAD, AUDUSD, CADCHF, EURCHF, EURGBP, EURUSD,
                    GBPCAD, GBPJPY, GBPSGD, USDCZK, CHFJPY, EURCZK, EURDKK,
                    EURSEK, GBPUSD, USDJPY, USDNOK, USDPLN, USDSGD, AUDNZD,
                    CADJPY, EURJPY, USDHUF, USDCAD, XAGUSD
                    <br />- Storage Fees: $4.5 per Day/per Lot
                  </li>
                  <li>
                    <b>2 - CFDs Type 2:</b>
                    <br />
                    USDMXN, EURNZD, GBPNZD, EURAUD, GBPAUD, GBPCHF, NZDCHF,
                    AUDCHF, EURNOK, EURSGD, NZDJPY, XAUUSD
                    <br />- Storage Fees: $30 per Day/per Lot
                  </li>
                  <li>
                    <b>3: CFDs Type 3 : </b>
                    <br />
                    USDTRY, USDZAR, EURMXN
                    <br />- Storage Fees: $24 per Day/per Lot
                  </li>

                  <li>
                    <b>
                      4 – Spot/Rolling Indices: No grace period, where fees
                      applies daily
                    </b>
                    <br />
                    <br />
                    <table className="table table-striped table-bordered table-hover table-sm">
                      <tbody>
                        <tr>
                          <td>DE30Rollx</td>
                          <td>-5 USD/DAY</td>
                        </tr>
                        <tr>
                          <td>US30Rollx </td>
                          <td>-5 USD/DAY</td>
                        </tr>
                        <tr>
                          <td>US100Rollx</td>
                          <td>-5 USD/DAY</td>
                        </tr>
                        <tr>
                          <td>UK100Rollx</td>
                          <td>-5 USD/DAY</td>
                        </tr>
                        <tr>
                          <td>Chin50Rollx</td>
                          <td>-5 USD/DAY</td>
                        </tr>
                        <tr>
                          <td>HKD33Rollx</td>
                          <td>-5 USD/DAY</td>
                        </tr>
                        <tr>
                          <td>XCUUSDRollx</td>
                          <td>-10 USD/DAY</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li>
                    <b>
                      5 – Spot/Rolling Energies: No grace period, where fees
                      applies daily
                    </b>
                    <br />
                    <br />
                    <table className="table table-striped table-bordered table-hover table-sm">
                      <tbody>
                        <tr>
                          <td>USOUSD!</td>
                          <td>-30 USD/DAY</td>
                        </tr>
                        <tr>
                          <td>UKOUSD!</td>
                          <td>-55 USD/DAY</td>
                        </tr>
                        <tr>
                          <td>XNGUSD!</td>
                          <td>-25 USD/DAY</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li>
                    <b>
                      6 – Cryptos: No grace period, where fees applies daily
                    </b>
                    <br />
                    <br />
                    <table className="table table-striped table-bordered table-hover table-sm">
                      <tbody>
                        <tr>
                          <th rowspan="2" style={{"textAlign":"center","verticalAlign":"middle"}}>Item</th>

                          <th colspan="2" style={{"textAlign":"center","verticalAlign":"middle"}}>Storage Fees (USD/Lot/Day)</th>
                        </tr>

                        <tr>
                          <th style={{"textAlign":"center","verticalAlign":"middle"}} >Buy / Long</th>

                          <th style={{"textAlign":"center","verticalAlign":"middle"}}>Sell / Short</th>
                        </tr>

                        <tr>
                          <td>BTCUSD ( 10 units contract )</td>
                          <td>450 USD/Lot/Day</td>
                          <td>100 USD/Lot/Day</td>
                        </tr>
                        <tr>
                          <td>BTCUSD ( 1 unit contract )</td>
                          <td>45 USD/Lot/Day</td>
                          <td>10 USD/Lot/Day</td>
                        </tr>
                        <tr>
                          <td>ETHUSD</td>
                          <td>210 USD/Lot/Day</td>
                          <td>80 USD/Lot/Day</td>
                        </tr>
                        <tr>
                          <td>LTCUSD</td>
                          <td>25 USD/Lot/Day</td>
                          <td>10 USD/Lot/Day</td>
                        </tr>
                        <tr>
                          <td>XRPUSD</td>
                          <td>10 USD/Lot/Day</td>
                          <td>4 USD/Lot/Day</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li>
                    <b>Notes:</b>
                    <br />

                    <p className={globalstyle.parjustify}>
                      * Above fees are subject to change without any prior
                      notice.
                      <br />
                      * Noor Capital retains the discretionary rights to revert
                      swap free accounts to regular trading accounts.
                      <br />* Non-Listed Instruments in the above list will be
                      subject to Storage Fees as per each instrument settings
                      on the trading system
                      <br />* FX Lot is 100K standard contract.
                    </p>
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </section>
        <style jsx>{`
        td {
         
          text-align: center;
        }
        `}</style>
      </div>
    </div>
  );
};

export default Accounts_TradingConditions;
