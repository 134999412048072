import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import img1 from "../../assets/images/bgtools.jpg";
import { Collapse } from "antd";

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const glossary1 = `When traders place an opening and closing trade on the same stock on the same day, they are making a day trade and are subject to special rules.`;

const glossary2 = `A professional day trader can informally be considered somebody who day trades for a living.`;

const glossary3 = `Swing traders will hold stocks for at least one night. These are very short-term investments.`;

const glossary4 = `This term refers to a strong market of stocks moving up.`;

const glossary5 = `This term refers to a weak market; this means traders think the price of stocks or a specific stock will be going down.`;

const glossary6 = `When a company floats an IPO, they sell a fixed number of shares of a particular company in the open market to raise money.`;

const glossary7 = `Float refers to the number of outstanding shares available for trade.`;

const glossary8 = `A Share Buy Back program is when a company buys back shares that were sold during the IPO. By doing this, they are reducing the number of shares available for trading purposes in the market.`;

const glossary9 = `A secondary offering is an offering that is given after the Initial Public Offering. A secondary offering will raise money for the company by selling more shares.`;

const glossary10 = `Beta is a measure of the volatility—or systematic risk—a security or portfolio compared to the market as a whole.`;

const glossary11 = `A crossed market refers to a temporary situation where the bid price associated with a particular asset or security is higher than the ask price.`;

const glossary12 = `Dividends are  the money paid out to shareholders who hold shares of the company for a certain period of time as a way of sharing the company's success.`;

const glossary13 = `In day trading, divergence is trading concept that forms on your trading bar chart and results from the price action of security moving in opposite directions.`;

const glossary14 = `Earnings Per Share (EPS) is a portion of a company's profit allocated to a person's stock share and is an essential metric for analysts.`;

const glossary15 = `Market Capitalization is a measurement used to classify a company's size and is based on the market value of the total shares outstanding.`;

const glossary16 = `Mergers are deals that unite two separate companies into a single new business entity.`;

const glossary17 = `A penny stock typically refers to the stock of a small companies.`;

const glossary18 = `Return on investment (ROI) is a metric that measures profit or loss generated by an investment about the invested funds.`;

const glossary19 = `Shares outstanding refers to the stock of a company currently held by all shareholders, including restricted and institutional shares.`;

const glossary20 = `Market trend represents the general direction in a market or security over a given period of time and can last from a couple of days to many months or years.`;

const glossary21 = `This refers to the level of uncertainty surrounding price fluctuations of financial instruments. Volatility is usually characterized by rapid, random price movements.`;

const glossary22 = `A professional day trader can informally be considered somebody who day trades for a living.`;

const glossary23 = `A resistance level is the price level at which the selling of a security is deemed healthy enough to eliminate the price increase.`;

const glossary24 = `Stock is a type of asset that gives you ownership in a company, allowing you to claim the company's assets and earnings.`;

const glossary25 = `A price target is the projected price of a financial instrument provided by an analyst and used to determine under and overvalued stocks.`;

const glossary26 = `Recession refers to when a country's economy experiences a decline due to different factors over a specific period.`;

const glossary27 = `The OTC or over-the-counter market allows for the trading of assets without the formal structure of official exchange and is considered risky.`;

const glossary28 = `The Hard to borrow list refers to an inventory of securities the brokerage firm cannot provide for short selling and is only available for buying.`;

const glossary29 = `Derivatives are securities with prices dependent on, or derived from, one or more separate underlying assets such as options or futures contracts.`;

const glossary30 = `Equity refers to the ownership of assets after liabilities and debts have been settled, or it can refer to stock or ownership of shares in a public company.`;

const glossary31 = `Blue-chip stocks are companies that are often worth billions of dollars, pay dividends, and have a long history of reliable operations.`;

const glossary32 = `A bond is a debt investment where investors loan funds to a corporate or government for a particular period of time at a variable or fixed interest rate.`;

const glossary33 = `When traders are "long" a stock, they are buying shares and expect the stock price to go up.`;

const glossary34 = `Price average is the average price of the stock that you have paid.`;

const glossary35 = `glossTraders who are "short" on a stock are short-selling shares. As soon as they sell the shares, they profit from the sale; however, they must buy back the shares.ary5`;

const glossary36 = `You must borrow shares from your broker to short. If your broker doesn't have shares available for you to borrow, you can't short the stock.`;

const glossary37 = `To close a short position, a trader must "cover" their position, this is, buying stocks to cover the shares they borrowed from their broker.`;

const glossary38 = `Short interest refers to the number of shares all traders worldwide are currently holding as a short position against the stock.`;

const glossary39 = `This is when a stock suddenly starts moving up, and traders holding short positions start buying to cover their positions.`;

const glossary40 = `When a trader opens a broker account, they are given Margin. In addition to allowing you to trade on borrowed money, they also extend a line of credit to your account for trading.`;

const glossary41 = `The rate that your cash deposit will be multiplied to give you total buying power.`;

const glossary42 = `The money an investor has for purchasing securities, the cash balance plus the margin.`;

const glossary43 = `The percentage a trader has to pay their broker in exchange for borrowing money.`;

const glossary44 = `Traders who are issued a margin call are in debt to their broker. The broker will require you to repay the debt and force you to sell other assets to return the money.`;

const glossary45 = `Bid Price is the price at which traders are currently bidding on stock.`;

const glossary46 = `Ask price is the price at which traders are currently asking to sell the stock. `;

const glossary47 = `Spread is the difference between the Bid price and Ask price.`;

const glossary48 = `Market Makers create the spread. They are large institutional players that are both buyers and sellers of a stock.`;

const glossary49 = `Volume is a measure of the number of shares traded.`;

const glossary50 = `Relative volume shows how much volume a stock has compared to its average volume for the same period.`;

const glossary51 = `Thin market means a low number of traders actively trade a particular stock. In a thin market, prices tend to be volatile.`;

const glossary52 = `Thickly-traded markets and stocks will be crowded with traders.`;

const glossary53 = `Stocks can be halted and paused from trading for several reasons. During circuit-breaker halts, traders cannot trade the stock in any way.`;

const glossary54 = `Slippage is the difference between the price you thought you would trade at and the price at which the trade went through.`;

const glossary55 = `A limit order is when you ask your broker to buy you shares and state the most you are willing to pay.`;

const glossary56 = `Stop orders are a versatile order used for getting in and out of trades. When you place a stop order, you are saying that you want to get in or out of a trade when prices hit your stop price.`;

const glossary57 = `This is Fill or Kill, which means either you get your entire order filled or the order won't fill at all; this prevents partial orders.`;

const glossary58 = `This is a Good Till Cancelled order. That means the order will stay on the broker's servers until you cancel it.`;

const glossary59 = `Trailing stop order is a stop order that allows setting of the value as a percentage usually below the current market price and will move as prices move.`;

const glossary60 = `A Good Till Cancelled (GTC) order refers to a buy or sell request designed to last until the request is executed or canceled by the trader or broker.`;

const glossary61 = `Fundamental Analysis is when a trader (or more often, an investor) looks at a company's fundamental metrics. This includes their annual and quarterly earnings per share, their book value (total value of company assets), their sector's strength, and the growth potential.`;

const glossary62 = `Technical Analysis, in contrast to fundamental analysis, focuses solely on the stock price.`;

const glossary63 = `Line charts simply plot a line. This can give a good understanding of price action over long periods of time, but it doesn't provide the necessary insight that traders require for shorter time periods.`;

const glossary64 = `Bar charts show the open price and the close price for any given period. `;

const glossary65 = `Candlestick charts include four pieces of information. The open price, the close price, the high of the period price, and the low of the period price.`;

const glossary66 = `Technical indicators, or studies, help us interpret current price action.`;

const glossary67 = `Moving averages are a technical indicator that tells us the average price of a stock over time.`;

const glossary68 = `Simple Moving Average (SMA) is an average price calculation on the closing price of a security over a period of time divided by the number of periods.`;

const glossary69 = `The volume profile refers to an advanced charting study used to show trading activity where volume is displayed at prices instead of over a specific time.`;

const glossary70 = `Standard deviation is a statistical measure representing the rate of divergence from the mean in a data set and is used a lot in trading.`;

const glossary71 = `On Balance Volume, a momentum indicator helps measure the buying and selling pressure in a stock or any other financial security with volume.`;

const glossary72 = `An opening range breakout is a relatively simple strategy that involves taking a position when price breaks above or below the previous candle, high or low.`;

const glossary73 = `The Keltner Channel is a technical indicator that shows a central line for a moving average and channel lines below and above.`;

const glossary74 = `Average Directional Index is a technical indicator used to signify the strength of a trend and works best when a stock is trending.`;

const style = { padding: "50px 0", alignSelf: "justify" };

const { Title } = Typography;

const Tools_TradersGlossary = () => {
  return (
    <div>
      <PageseMetaTags
        title="Traders Glossary - List of Common Trading Terms and Definitions"
        description="The world of trading is full of jargon. In this section of our website, we will take you through some of the main terms used in trading and explain what they mean."
      />

      <BgBefore
        pageHeadingTitle="Trader's Glossary"
        pageHeadingDescription="Here are some terms every trader should be familiar with."
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName="Trader's Glossary"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            TRADER'S GLOSSARY
          </Title>
        </Row>
        <Row>
          <Title style={{ color: "#1b75bc" }} level={4}>
            Here are some terms every trader should be familiar with
          </Title>
        </Row>

        <Collapse defaultActiveKey={["1"]} onChange={callback}>
          <Panel header="Day Trade" key="1">
            <p>{glossary1}</p>
          </Panel>
          <Panel header="Professional Day Trader" key="2">
            <p>{glossary2}</p>
          </Panel>

          <Panel header="Swing Trading" key="3">
            <p>{glossary3}</p>
          </Panel>

          <Panel header="Bull or Bullish" key="4">
            <p>{glossary4}</p>
          </Panel>

          <Panel header="Bear or Bearish" key="5">
            <p>{glossary5}</p>
          </Panel>

          <Panel header="Initial Public Offering (IPO)" key="6">
            <p>{glossary6}</p>
          </Panel>

          <Panel header="Float" key="7">
            <p>{glossary7}</p>
          </Panel>

          <Panel header="Share Buy Back" key="8">
            <p>{glossary8}</p>
          </Panel>

          <Panel header="Secondary Offering" key="9">
            <p>{glossary9}</p>
          </Panel>

          <Panel header="Beta" key="10">
            <p>{glossary10}</p>
          </Panel>
          
          <Panel header="Crossed Market" key="11">
            <p>{glossary11}</p>
          </Panel>

          <Panel header="Dividend" key="12">
            <p>{glossary12}</p>
          </Panel>

          <Panel header="Divergence" key="13">
            <p>{glossary13}</p>
          </Panel>

          <Panel header="Earnings Per Share" key="14">
            <p>{glossary14}</p>
          </Panel>

          <Panel header="Market Cap" key="15">
            <p>{glossary15}</p>
          </Panel>

          <Panel header="Merger" key="16">
            <p>{glossary16}</p>
          </Panel>

          <Panel header="Penny Stocks" key="17">
            <p>{glossary17}</p>
          </Panel>

          <Panel header="Return on Investment (ROI)" key="18">
            <p>{glossary18}</p>
          </Panel>

          <Panel header="Shares Outstanding" key="19">
            <p>{glossary19}</p>
          </Panel>

          <Panel header="Market Trend" key="20">
            <p>{glossary20}</p>
          </Panel>
          <Panel header="Volatility" key="21">
            <p>{glossary21}</p>
          </Panel>

          <Panel header="Support Level" key="22">
            <p>{glossary22}</p>
          </Panel>

          <Panel header="Resistance Level" key="23">
            <p>{glossary23}</p>
          </Panel>

          <Panel header="Stock" key="24">
            <p>{glossary24}</p>
          </Panel>

          <Panel header="Price Target" key="25">
            <p>{glossary25}</p>
          </Panel>

          <Panel header="Recession" key="26">
            <p>{glossary26}</p>
          </Panel>

          <Panel header="OTC Market" key="27">
            <p>{glossary27}</p>
          </Panel>

          <Panel header="Hard To Borrow" key="28">
            <p>{glossary28}</p>
          </Panel>

          <Panel header="Derivative" key="29">
            <p>{glossary29}</p>
          </Panel>

          <Panel header="Equity" key="30">
            <p>{glossary30}</p>
          </Panel>
          <Panel header="Blue Chip" key="31">
            <p>{glossary31}</p>
          </Panel>

          <Panel header="Bond" key="32">
            <p>{glossary32}</p>
          </Panel>

          <Panel header="Long Side Trading" key="33">
            <p>{glossary33}</p>
          </Panel>

          <Panel header="Price Average" key="34">
            <p>{glossary34}</p>
          </Panel>

          <Panel header="Short Side Trading" key="35">
            <p>{glossary35}</p>
          </Panel>

          <Panel header="Borrowing" key="36">
            <p>{glossary36}</p>
          </Panel>

          <Panel header="Covering" key="37">
            <p>{glossary37}</p>
          </Panel>

          <Panel header="Short Interest" key="38">
            <p>{glossary38}</p>
          </Panel>

          <Panel header="Short Squeeze" key="39">
            <p>{glossary39}</p>
          </Panel>

          <Panel header="Margin" key="40">
            <p>{glossary40}</p>
          </Panel>
          <Panel header="Leverage Rate" key="41">
            <p>{glossary41}</p>
          </Panel>

          <Panel header="Buying Power" key="42">
            <p>{glossary42}</p>
          </Panel>

          <Panel header="Margin Rate" key="43">
            <p>{glossary43}</p>
          </Panel>

          <Panel header="Margin Call" key="44">
            <p>{glossary44}</p>
          </Panel>

          <Panel header="Bid Price" key="45">
            <p>{glossary45}</p>
          </Panel>

          <Panel header="Ask Price" key="46">
            <p>{glossary46}</p>
          </Panel>

          <Panel header="Spread" key="47">
            <p>{glossary47}</p>
          </Panel>

          <Panel header="Market makers" key="48">
            <p>{glossary48}</p>
          </Panel>

          <Panel header="Volume" key="49">
            <p>{glossary49}</p>
          </Panel>

          <Panel header="Relative volume" key="50">
            <p>{glossary50}</p>
          </Panel>
          <Panel header="A Thin Market" key="51">
            <p>{glossary51}</p>
          </Panel>

          <Panel header="A Thick Market" key="52">
            <p>{glossary52}</p>
          </Panel>

          <Panel header="Circuit Breaker" key="53">
            <p>{glossary53}</p>
          </Panel>

          <Panel header="Slippage" key="54">
            <p>{glossary54}</p>
          </Panel>

          <Panel header="Limit Orders" key="55">
            <p>{glossary55}</p>
          </Panel>

          <Panel header="Stop Orders" key="56">
            <p>{glossary56}</p>
          </Panel>

          <Panel header="FOK Order" key="57">
            <p>{glossary57}</p>
          </Panel>

          <Panel header="GTC Order" key="58">
            <p>{glossary58}</p>
          </Panel>

          <Panel header="Trailing Stop" key="59">
            <p>{glossary59}</p>
          </Panel>

          <Panel header="Good Till Cancelled" key="60">
            <p>{glossary60}</p>
          </Panel>
          <Panel header="Fundamental Analysis" key="61">
            <p>{glossary61}</p>
          </Panel>

          <Panel header="Technical Analysis" key="62">
            <p>{glossary62}</p>
          </Panel>

          <Panel header="Line Charts" key="63">
            <p>{glossary63}</p>
          </Panel>

          <Panel header="Bar Charts" key="64">
            <p>{glossary64}</p>
          </Panel>

          <Panel header="Candlestick Charts" key="65">
            <p>{glossary65}</p>
          </Panel>

          <Panel header="Technical Indicators" key="66">
            <p>{glossary66}</p>
          </Panel>

          <Panel header="Moving Averages" key="67">
            <p>{glossary67}</p>
          </Panel>

          <Panel header="Simple Moving Average" key="68">
            <p>{glossary68}</p>
          </Panel>

          <Panel header="Volume Profile" key="69">
            <p>{glossary69}</p>
          </Panel>

          <Panel header="Standard Deviation" key="70">
            <p>{glossary70}</p>
          </Panel>

          <Panel header="On Balance Volume" key="71">
            <p>{glossary71}</p>
          </Panel>

          <Panel header="Opening Range Breakout" key="72">
            <p>{glossary72}</p>
          </Panel>

          <Panel header="Keltner Channel" key="73">
            <p>{glossary73}</p>
          </Panel>

          <Panel header="Average Directional Index (ADX)" key="74">
            <p>{glossary74}</p>
          </Panel>

        </Collapse>

        <br />
        <br />

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
            .ant-collapse-header{
              font-size: 18px;
              padding: 18px 16px !important;
              color: #1b75bc !important;
            }
          `}
        </style>
      </div>

   
    </div>
  );
};

export default Tools_TradersGlossary;
