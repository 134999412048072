import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import marketupdate from "../../assets/images/pages/tools/market-update.png";
import img1 from "../../assets/images/bgtoolsAr.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
'تحديثات السوق والأخبار والمعلومات اليومية التي ستساعدك على التخطيط لاستثمارك بثقة.',
'يمكن الوصول إليها من أي مكان في العالم.',
'قلل المخاطر إلى الحد الأدنى من خلال اتخاذ القرار الصحيح مع تحديثات السوق اليومية من نور كابيتال.',
];
const { Title } = Typography;

const NoorTrends_MarketUpdateAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Forex Market Updates: Latest Forex News | Noor Capital"
        description="View all upcoming global economic news events and their importance in this forex calendar."
      />

      <BgBeforeAr
        pageHeadingTitle="احصل على تحديثات السوق اليومية أينما كنت "
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="ادوات التداول"
        pageSubCategoryPath="#"
        pageName="مستجدات الأسواق"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          تحديثات سوق الفوركس
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right"><b>
            لا يهم أين أنت، يمكنك التداول بثقة مع تحديثات السوق اليومية باستمرار 
            </b></p>

            <p className="parjustify text-right">
            توفر نور كابيتال أخبار السوق والتمويل والأعمال باستمرار مع تحديثات السوق اليومية، وهي المنصة الأكثر تقدمًا حيث يمكنك الآن البقاء في عالم التجارة مع آخر التحديثات من الإمارات العربية المتحدة وجميع أنحاء العالم.
            </p>

            <p className="parjustify text-right">
            احصل على أخبار التداول وتحديثات سوق الأوراق المالية والاستشارات المالية وأخبار الشركة والمزيد مع نور كابيتال حيث تؤثر الأحداث العالمية بشكل عميق على القطاع المالي، وهذا سيتيح لك مواكبة تحديثات السوق اليومية واتخاذ قرارات دقيقة والتخطيط بشكل أفضل.
            </p>

            <p className="parjustify text-right">
            ستزودك تحديثات السوق اليومية من نور كابيتال بأخبار ومعلومات ونصائح دقيقة حتى تتمكن من التداول بثقة.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={marketupdate}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          المميزات والفوائد
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              className="text-right"
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              احصل على تحديثات سوق نور كابيتال اليومية الآن!
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/en/category/market-updates/"
                  target="_blank"
                >
                  انقر هنا
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default NoorTrends_MarketUpdateAr;
