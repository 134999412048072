import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgtradingbanner3.jpg"
import indices from "../../assets/images/pages/instruments/indices.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import ProductsAr from "./productsAr";
import Instruments_Indexes_Table from "./trading_Instruments_Indexes_table";

const style = {padding: '50px 0' };
const { Title } = Typography;


const Instruments_IndicesAr = () => {
  return( <div>
          <PageseMetaTags
                title="Indices Trading with Noor Capital | Forex Broker"
                description="Noor Capital offers competitive spreads across all of our cash Indices, including the E-mini S&P 500 Index from 0.4 points, the FTSE 100 Index from 1 point, Xetra DAX Index from 1 point and S&P 200 Index from 1 point."
            />
            <BgBeforeAr 
            pageHeadingTitle="تداول المؤشرات مع نور كابيتال "
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenuAr
        pageMainCategoryName="التداول"
        pageMainCategoryPath="#"
        pageSubCategoryName="الأسواق"
        pageSubCategoryPath="#"
        pageName="المؤشرات"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          تداول المؤشرات مع نور كابيتال
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            مع المؤشرات، يمكنك الاستثمار بالقيمة النقدية الحالية وفروق الأسعار الضيقة والرافعة المالية الجيدة على عكس سوق المشتقات، تمتاز المؤشرات بأن ليس لها تاريخ انتهاء صلاحية حيث يمكنك استخدامها للاستراتيجيات قصيرة وطويلة المدى.
            </p>

            <p className="parjustify text-right">
            في المؤشرات النقدية، هناك تنفيذ فوري للصفقة لأنها تنتمي إلى السوق الفوري. يمكن أن تتم المعاملة في البورصات المنظمة أو خارج البورصة (OTC).
            </p>

            <p className="parjustify text-right">
            تُعد المؤشرات هي أفضل أداة لمراقبة وقياس اتجاهات السوق وتقلبات الأسعار التي يمكن أن تساعد المستثمرين على فهم رد فعل المؤشرات واتخاذ القرار الصحيح للاستثمار المربح. نظرًا لوجود أطنان من الأسهم المتداولة في جميع أنحاء العالم، تساعد المؤشرات النقدية في تتبع وقياس مجموعة من الأسهم حيث تُعد بمثابة نافذة فيما يخص تقلبات السوق.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={indices}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        
        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          قائمة المواصفات
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Instruments_Indexes_Table />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              ابدأ تداول المؤشرات الخاصة بك الآن
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ProductsAr />

  </div>
  )

};

export default Instruments_IndicesAr;