import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import webinar from "../../assets/images/pages/tools/webinar.png";
import img1 from "../../assets/images/bgtoolsAr.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };
const { Title } = Typography;

const NoorTrends_OnlineWebinarAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Online Webinars - Noor Capital"
        description="Noor Capital offers 100% free live online webinars for both beginner and advanced traders."
      />

      <BgBeforeAr
        pageHeadingTitle="تعلم من خبراء الصناعة في أي مكان في العالم"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="ادوات التداول"
        pageSubCategoryPath="#"
        pageName="النداوت التعليمية"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          النداوت التعليمية
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            تمنحك نور كابيتال فرصة للتعلم من الخبراء والارتقاء بمعرفة التداول إلى المستوى التالي. من خلال محاضراتنا عبر الإنترنت، يمكنك اغتنام هذه الفرصة لاكتشاف أكثر الأسرار المذهلة لتصبح متداولًا بارزًا وصقل مهاراتك في التداول والحصول على إجابات لأسئلتك والتواصل مع خبراء الصناعة المشهورين.
            </p>

            <p className="parjustify text-right">
            إذا فاتتك المحاضرات عبر الإنترنت، فلا يزال بإمكان الأعضاء المسجلين لدينا الوصول إلى المحاضرات السابقة من مكتبتنا اللانهائية عبر الإنترنت.
            </p>

            <p className="parjustify text-right">
            تركز محاضرتنا عبر الإنترنت على موضوعات التداول الحالية والاتجاهات والاستراتيجيات والتحليلات التي ستساعدك على توسيع فهمك و مهاراتك في التداول. بداية من التدريب الأساسي إلى التحليل المتعمق، صُممت محاضرتنا على الإنترنت لتلبية كل مستوى من متطلبات المتداولين.
            </p>

            <p className="parjustify text-right">
            من خلال محاضرتنا على الانترنت، ستتعلم أساسيات التداول وتتلقى آخر التحديثات وتفهم سيكولوجية السوق وتكتشف استراتيجيات تداول فريدة وأيضًا وتحصل على مشورة الخبراء.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={webinar}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                <br />
                انضم إلى محاضرتنا المجانية عبر الإنترنت حول التداول واكتشف أسرار النجاح في السوق
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/category/learn-forex/%d8%a7%d9%84%d9%86%d8%af%d9%88%d8%a7%d8%aa-%d8%a7%d9%84%d8%aa%d8%b9%d9%84%d9%8a%d9%85%d9%8a%d8%a9/"
                  target="_blank"
                >
                  سجل الآن للحصول على محاضرة الكترونية
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NoorTrends_OnlineWebinarAr;
