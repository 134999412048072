import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgmt4Ar.jpg";
import mt4mobile from "../../assets/images/pages/trading/mt4platform.png";
import WhyNoorAr from "./whyNoorAr";


const { Title } = Typography;

const Metatrader4_MetaTrader4MobileAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="MT4 Mobile Trading - Noor Capital"
        description="The MetaTrader 4 mobile applications allow trading on financial markets via iOS and Android powered smartphones and tablets."
      />

      <BgBeforeAr
        pageHeadingTitle="تطبيق التداول الذكي للمتداولين الأذكياء"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="منصة ميتاتريدر4 للهواتف"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          حمل تطبيق ميتاتريدر MT4 من نور كابيتال، وابدأ التداول أينما كنت
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify  text-right">
            مع التكنولوجيا المبتكرة الناشئة باستمرار في صناعة التداول، يزداد الطلب على حل تداول ذكي متوافق للغاية مع جميع الأنظمة حيث يوفر لك تطبيق ميتاتريدر MT4 من نور كابيتال التداول أينما كنت من هواتفك الذكية.
            </p>
            <p className="parjustify  text-right">
            مع تطبيق ميتاتريدر MT4 للهاتف المحمول من نور كابيتال، يمكنك الآن الاستمتاع بوصول فوري ومرن إلى السوق العالمية مع تنفيذ سريع. احصل على جميع ميزات منصتنا بين يديك لمساعدتك على التداول بسهولة؛ سواء كنت ترغب في فتح وإدارة وتحرير وإغلاق المراكز، يمكنك القيام بكل ذلك من أي مكان وفي أي وقت.
            </p>

            <br />

            
            
            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-android"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>هواتف الاندرويد</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en"
                    target="_blank"
                  >
                    تحميل
                  </a>
                </p>
              </div>
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 ml-sm-auto mr-sm-auto mt-5 mt-md-0">
                <i
                  class="fab fa-apple"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>هواتف الايفون</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://apps.apple.com/us/app/metatrader-4/id496212596"
                    target="_blank"
                  >
                    تحميل
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                الميزات 
                </Title>
                <h5>
                مع تطبيق ميتاتريدر MT4 من نور كابيتال، عالم التداول أصبح بين يديك.
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تحميل فوري وسرعة الوصول إلى السوق العالمية.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الوصول إلى الرسوم البيانية التفاعلية والمؤشرات الفنية والتحليلية.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  راقب حسابك وتتبع الصفقات واشتري وبيع واحصل على آخر تحديث للصناعة بنقرة واحدة.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  سهولة الدردشة والتفاعل مع زملائك المتداولين.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على إشارات التداول من كبار المزودين.
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الوصول إلى حسابك على أي هاتف ذكي من أي مكان في العالم.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تداول أينما كنت باستخدام تطبيق ميتاتريدر MT4 الخاص بنور كابيتال.
                  app
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على ميتاتريدر MT4 وكذلك نصائح من المستشارين الخبراء من نور كابيتال لمساعدتك على التداول بدون مخاطر.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  خدمة عملاء مخصصة 24/5 متاحة لمساعدتك في أي مخاوف أو نصائح.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  يُمكنك اختيار لغتك المفضلة (انجليزي / عربي)
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4mobile} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="fdb-block">
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={2}>
                Advanced Benefits
              </Title>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-3">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
            <Title style={{ color: "#1b75bc" }} level={3}>
            Interactive Charts
              </Title>
              
              <p className="parjustify text-right">
                Interactive charts track currency rate changes in real time. The
                application provides three types of charts: bars, candlesticks
                and lines allowing you to trade directly on them. You can easily
                scale and scroll charts, as well as switch between nine
                timeframes — from one minute to one month.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              
              <Title style={{ color: "#1b75bc" }} level={3}>
                  Convenience
                  </Title>
              
              <p className="parjustify  text-right">
                The MetaTrader 4 App for iOS provides outstanding features to
                enhance your trading activity. The One Tap Trading function
                allows you to trade on a chart with a single click. Open the
                quick trading panel and perform a deal in no time! The built-in
                chat allows you to communicate with any registered user of the
                MQL5 community — the largest community of traders.
              </p>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              
              <Title style={{ color: "#1b75bc" }} level={3}>
                  Orders and Trading Functions
                  </Title>
              
              <p className="parjustify text-right">
                All information regarding the amount of orders and open
                positions, open prices, volumes and account status can be found
                in the Trade window, while the History window allows you to view
                the detailed history of all previously performed trades. To
                implement any strategy you need a flexible MetaTrader 4 mobile
                trading system paired with a full set of orders and trading
                functions. Instant execution orders allow you to trade Forex
                continuously, while pending ones relieve you from the necessity
                to constantly manage your trading.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              
              <Title style={{ color: "#1b75bc" }} level={3}>
                  Trading Flexibility
                  </Title>
              
              <p className="parjustify text-right">
                Instant execution orders allow you to trade Forex at any time,
                while pending orders relieve you from the necessity to
                constantly check your device. Just place a pending order and
                switch to other things. The order will be executed even if the
                application is offline. Set Take Profit and Stop Loss levels to
                set your profit or minimize your losses. Comprehensive
                information such as track number, open prices, volumes, state of
                account of all orders and open positions, are always available
                in the Terminal window. Detailed history of all trades is
                available from the History window.
              </p>
            </div> 
          </div>
        </div>
      </section>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              رحلتك في عالم التداول كمحترف تبدأ من هنا 
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoorAr />
    </div>
  );
};

export default Metatrader4_MetaTrader4MobileAr;
