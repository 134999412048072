import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import leadinginvestment from "../../assets/images/pages/whynoor/leading-investment.svg";
import license from "../../assets/images/pages/whynoor/license.svg";
import funding from "../../assets/images/pages/whynoor/funding.svg";
import execution from "../../assets/images/pages/whynoor/execution.svg";
import platform from "../../assets/images/pages/whynoor/platform.svg";
import instruments from "../../assets/images/pages/whynoor/instruments.svg";
import research from "../../assets/images/pages/whynoor/research.svg";
import support from "../../assets/images/pages/whynoor/support.svg";

const style = { padding: "50px 0", alignSelf: "justify" };

const WhyNoorCapital = () => {
  return (
    <div>
      <PageseMetaTags
        title="Why Choose Noor Capital"
        description="Noor Capital has developed an exclusive asset management approach to advise its clients on making Investments across various Asset Classes."
      />
      <BgBefore
        pageHeadingTitle="Add Value To Your Investments With Noor Capital - A Brand Built On Trust"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Why Noor Capital"
      />

      <section className="fdb-block">
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <h2 style={{ color: "#1b75bc" }}>
              What Makes Noor Capital the best  Choice Globally?
              </h2>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h4 style={{ color: "#1b75bc" }}>
                <i
                  class="fas fa-arrow-circle-right"
                  style={{ paddingRight: "15px" }}
                ></i>
                Duly Regulated
              </h4>
              <p className={globalstyle.parjustify}>
              Established in 2005, Noor Capital P.S.C. is a Private Joint Stock Company, regulated by the Department of Economic Development Abu Dhabi, and the Securities and Commodities Authority (SCA).
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <h4 style={{ color: "#1b75bc" }}>
                <i
                  class="fas fa-arrow-circle-right"
                  style={{ paddingRight: "15px" }}
                ></i>
                Multiple Financial & Investment Solutions
              </h4>
              <p className={globalstyle.parjustify}>
              With an  exceptional combination of an extremely experienced team and highly efficient trading platforms like NoorTrader, Noor Capital Stocks Trading Platform (GTN), and Noor Capital’s MetaTrader 4 (MT4), Noor Capital provides you by a turnkey solution for all your trading and financial needs.
              </p>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>
                <i
                  class="fas fa-arrow-circle-right"
                  style={{ paddingRight: "15px" }}
                ></i>
                Global Access
              </h4>
              <p className={globalstyle.parjustify}>
              Noor Capital gives you global access to its trading platform, multiple trading tools, and educational resources - anytime and from  anywhere.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>
                <i
                  class="fas fa-arrow-circle-right"
                  style={{ paddingRight: "15px" }}
                ></i>
                Excellent Investment Advice
              </h4>
              <p className={globalstyle.parjustify}>
              Several years of experience and highly certified professionals made Noor Capital  well equipped to guide you  to the right investment decisions. We have successfully helped individuals, corporates, and institutional investors with insightful investment advice and exclusive investment opportunities globally.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Feature 22 */}
      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>
          <div className="row text-left">
            <div className="col-12">
              <h4 style={{ color: "#1b75bc" }}>
              Noor Capital is a leading investment and brokerage firm in UAE And Globally because of the following
              </h4>
            </div>
          </div>

          <div className="row text-left mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={leadinginvestment}
                    width="70px"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                    }}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                  One of the leading investment and brokerage firm
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={license}
                    width="70px"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                    }}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                  Regulated by the Securities and Commodities Authority (SCA)
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={funding}
                    width="70px"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                    }}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                  Local deposits and withdrawals with reputable UAE banks
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={execution}
                    width="70px"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                    }}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                  Instant execution through tier-1 interbank liquidity
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-left mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={platform}
                    width="70px"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                    }}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                  Ultimate and advanced trading platforms
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={instruments}
                    width="70px"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fff",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                    }}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    Wide range of global financial instruments
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={research}
                    width="70px"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                    }}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    Specialized education tutorials and trading tools
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={support}
                    width="70px"
                    style={{
                      marginBottom: "9px",
                      backgroundColor: "#fbfbfb",
                      padding: "13px",
                      border: "1px solid #d8d8d5",
                    }}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{ lineHeight: "26px" }}>
                    24/5 multilingual customer support
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyNoorCapital;
