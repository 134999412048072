import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgnoortrader.jpg";

import noortreaderdesktop from "../../assets/images/pages/trading/noortreaderdesktop.png";
import WhyNoor from "./whyNoor";
import OpenNoorTraderDemoForm from "../forms/openNoorTraderDemoForm.js";
import NtVideo from "./ntVideo.js";

const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader_OpenDemoAccount = () => {
  return (
    <div>
      <PageseMetaTags
        title="NoorTrader Demo Account - Noor Capital"
        description="We provide you with an opportunity to test our platform before you open a live account."
      />

      <BgBefore
        pageHeadingTitle="Learn To Trade Before You Go Live"
        pageHeadingDescription="Register Now For The Noor Trader Demo Account And Boost your Trading Experience."
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="NoorTrader Demo Account"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Sign-Up For NoorTrader Demo Account - Physical Gold & Silver
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              <b>
              New to NoorTrader's Platform? We provide you with an opportunity to test our platform before you open a live account.
              </b>
            </p>
            <p className="parjustify">
            With NoorTrader, you will enter the world of possibilities with access to the bullion market 24/5 and trade with virtual money.
            </p>

            <p className="parjustify">
            NoorTrader demo account gives you the opportunity to test before you trade, with unlimited access to the real-time market, the latest updates, and customer support. Open a demo account now and use all the features that will take you one step towards becoming a professional trader.
            </p>

            <OpenNoorTraderDemoForm />
            
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <NtVideo />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                Get instant access to the global bullion market
                </Title>
                
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Providing Storage & Delivery.

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 access to real time market updates.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Compatible with all web browsers and smart phones.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
Deal in any size (Kg, Oz & Grams).
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
Learn to buy, sell and manage your bullion with just a click with virtual money.
                </p>

               
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Demo is Risk-Free trading.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Learn trading like a professional with real-time data.
                </p>
                <br />

               
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Spot prices of physical trading in Gold, Silver, etc.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Flexibility by using Multi-Functional System.
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4desktop" className="img-fluid" src={noortreaderdesktop} />
              </div>
            </div>
            
          </div>
        </section>
      </div>

      <br />

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Register NoorTrader Live Account And Escalate Your Trading Experience
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="/physical-trading-platforms/open-noor-trader-live"
                  target="_blank"
                >
                  Register Live Account
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default NoorTrader_OpenDemoAccount;
