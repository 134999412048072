import React, { Component, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import classNames from "classnames";
import { useCookies } from "react-cookie";
import LanguageContext from "../../language-context";

import {
  Drawer,
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent,
  List,
  ListItem,
  Button,
  SimpleListItem,
  CollapsibleList,
} from "rmwc";
import "@rmwc/icon/styles";
// import { chevronRight } from '@rmwc/icon';
import "rmwc/dist/styles";
import classnames from "classnames";
import menuStyle from "./menuBar.module.css";

function MobileNavAr() {
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  const languageSegment = location.pathname;

  let enLink = languageSegment.replace("/ar/", "/");
  const { language, setLanguage } = useContext(LanguageContext);
  const [cookies, setCookie, removeCookie] = useCookies(["ar"]);
  const setLan = (value) => {
    setCookie("language", value, { path: "/" });
    setLanguage(value);
  };

  return (
    <>
      <Drawer
        dir="rtl"
        modal
        open={open}
        onClose={() => setOpen(false)}
        style={{ position: "fixed", zIndex: "99999999999999", top: 0 }}
      >
        {/* <DrawerHeader>
          <DrawerTitle>DrawerHeader</DrawerTitle>
          <DrawerSubtitle>Subtitle</DrawerSubtitle>
        </DrawerHeader> */}
        <DrawerContent style={{ zIndex: "99999999999999" }}>
          <List style={{ zIndex: "99999999999999", top: 0 }}>
            <CollapsibleList handle={<SimpleListItem text="من نحن" />}>
              <Link to="/ar/about/about-us" onClick={() => setOpen(!open)}>
                <SimpleListItem text="نظرة عامة" />
              </Link>
              <Link
                to="/ar/about/chairman-message"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="رسالة رئيس مجلس الإدارة" />
              </Link>
              <Link to="/ar/about/board-members" onClick={() => setOpen(!open)}>
                <SimpleListItem text="أعضاء مجلس الإدارة" />
              </Link>
              <Link
                to="/ar/about/management-team"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="الإدارة التنفيذية" />
              </Link>
              <Link
                to="/ar/about/license-regulations"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="التراخيص واللوائح" />
              </Link>
              <Link
                to="/ar/about/why-noor-capital"
                onClick={() => setOpen(!open)}
              >
                {" "}
                <SimpleListItem text="لماذا تختارنا" />
              </Link>
              <Link to="/ar/terms-conditions" onClick={() => setOpen(!open)}>
                <SimpleListItem text="الشروط والأحكام" />
              </Link>
              <Link
                to="/ar/trading-tools/risk-disclosure"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="تحذير المخاطر" />
              </Link>
              <Link to="/ar/about/careers" onClick={() => setOpen(!open)}>
                <SimpleListItem text="الوظائف" />
              </Link>
              <Link to="/ar/about/contact-us" onClick={() => setOpen(!open)}>
                <SimpleListItem text="تواصل معنا" />
              </Link>
            </CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="الخدمات" />}>
              <Link
                to="/ar/services/global-financial-advisory"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="الإستشارات المالية العالمية" />
              </Link>
              <Link
                to="/ar/services/asset-management"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="إدارة الأصول" />
              </Link>
              <Link
                to="/ar//services/investment"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="إدارة الأصول" />
              </Link>
              <Link
                to="/ar/services/funds-administration"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="إدارة صناديق الإستثمار" />
              </Link>
            </CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="التداول" />}>
              <CollapsibleList handle={<SimpleListItem text="الأسواق" />}>
                <Link
                  to="/ar/trading/instruments/forex"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="العملات" />
                </Link>
                <Link
                  to="/ar/trading/instruments/precious-metals"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="المعادن الثمينة" />
                </Link>
                <Link
                  to="/ar/trading/instruments/energies"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="الطاقة" />
                </Link>
                <Link
                  to="/ar/trading/instruments/indices"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="المؤشرات" />
                </Link>
                <Link
                  to="/ar/trading/instruments/shares-cfds"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="أسواق الأسهم" />
                </Link>
                <Link
                  to="/ar//trading/instruments/bullion"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="السبائك" />
                </Link>
                <Link
                  to="/ar/trading/instruments/cash-equities"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="الأسهم النقدية" />
                </Link>
              </CollapsibleList>
              <CollapsibleList
                handle={<SimpleListItem text="تداول العملات والسلع" />}
              >
                <Link
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="فتح حساب حقيقي" />
                </Link>
                <Link
                  href="/trading-platforms/mt4-open-forex-demo"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="فتح حساب تجريبي" />
                </Link>
                <Link
                  to="/ar/trading-platforms/metatrader4/mt4-mobile"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="منصة ميتاتريدر 4 لللهواتف" />
                </Link>
                <Link
                  to="/ar/trading-platforms/metatrader4/mt4-desktop"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="منصة ميتاتريدر 4 للحاسوب" />
                </Link>
                <Link
                  to="/ar/trading-platforms/metatrader4/mt4-mac"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="منصة ميتاتريدر 4 ماك" />
                </Link>
                <Link
                  to="/ar/trading-platforms/metatrader4/mt4-web-trader"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="ميتاتريدر للويب" />
                </Link>
              </CollapsibleList>
              <CollapsibleList
                handle={<SimpleListItem text="تداول الأسهم العالمية" />}
              >
                <Link
                  to="/ar/stocks-trading-platforms/open-gtn-live"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="فتح حساب حقيقي" />
                </Link>
                <Link
                  to="/ar/stocks-trading-platforms/gtn-mobile"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="منصة GTN للهواتف" />
                </Link>
                <Link
                  to="/ar/stocks-trading-platforms/gtn-web"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="منصة GTN للويب" />
                </Link>
              </CollapsibleList>


              {/* NT */}

              {/* <CollapsibleList
                handle={<SimpleListItem text="التداول المادي" />}
              >
                <Link
                  to="/ar/physical-trading-platforms/open-noor-trader-live"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="فتح حساب حقيقي" />
                </Link>
                <Link
                  to="/ar/physical-trading-platforms/open-noor-trader-demo"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="فتح حساب تجريبي" />
                </Link>
                <Link
                  to="/ar/physical-trading-platforms/noor-trader-mobile"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="منصة نور تريدر للهواتف" />
                </Link>
                <Link
                  to="/ar/physical-trading-platforms/noor-trader-web"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="نور تريدر للويب" />
                </Link>
              </CollapsibleList> */}

              {/* NT END*/}

              <CollapsibleList
                handle={<SimpleListItem text="عملاء المؤسسات" />}
              >
                <Link
                  to="/ar/institutional/request-demo"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="فتح حساب تجريبي" />
                </Link>
                <Link
                  to="/ar/institutional/request-live"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="فتح حساب حقيقي" />
                </Link>
              </CollapsibleList>
              <CollapsibleList handle={<SimpleListItem text="الحسابات" />}>
                <Link
                  to="/ar/trading/accounts/account-funding"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="تمويل الحساب" />
                </Link>
                <Link
                  to="/ar/trading/accounts/account-forms"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="مستندات فتح الحسابات" />
                </Link>
                <Link
                  to="/ar/trading/accounts/trading-conditions"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="شروط التداول" />
                </Link>
              </CollapsibleList>
            </CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="الشراكة" />}>
              <CollapsibleList
                handle={<SimpleListItem text="عملاء المؤسسات" />}
              >
                <Link
                  to="/ar/partnership/white-label-solutions"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="برنامج العلامة البيضاء" />
                </Link>
                <Link
                  to="/ar/partnership/institutional-clients"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="عملاء المؤسسات" />
                </Link>
                <Link
                  to="/ar/partnership/asset-managers"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="برامج مدراء الأصول" />
                </Link>
              </CollapsibleList>
              <CollapsibleList
                handle={<SimpleListItem text="برنامج الشراكة " />}
              >
                <Link
                  to="/ar/partnership/marketing-partnership-program"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="برنامج الشراكة التسويقية" />
                </Link>
              </CollapsibleList>
            </CollapsibleList>

            <CollapsibleList
              open
              handle={
                <Link
                  to="areducation/noor-academy"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="اكاديمية نور" />
                </Link>
              }
            ></CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="ادوات التداول" />}>
              <CollapsibleList handle={<SimpleListItem text="نور تريندز" />}>
                <Link
                  to="/ar/trading-tools/noor-trends-online-webinar"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="النداوت التعليمية" />
                </Link>
                <Link
                  to="/ar/trading-tools/noor-trends-market-update"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="مستجدات الأسواق" />
                </Link>
                <Link
                  to="/ar/trading-tools/noor-trends-technical-analysis"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="التحليلات والتقارير الفنية" />
                </Link>
                <Link
                  to="/ar/trading-tools/noor-trends-economic-reports"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="التقارير الاقتصادية" />
                </Link>
                <Link
                  to="/ar/trading-tools/noor-trends-daily-broadcasting"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="غرفة البث المباشر" />
                </Link>
              </CollapsibleList>
              <CollapsibleList handle={<SimpleListItem text="الأدوات" />}>
                <Link
                  to="/ar/trading-tools/economic-calendar"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="الأجندة الاقتصادية" />
                </Link>
                <Link
                  to="/ar/trading-tools/trading-signals"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="إشارات التداول" />
                </Link>
                <Link
                  to="/ar/trading-tools/traders-glossary"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="قاموس المصطحات" />
                </Link>
                <Link
                  to="/ar/trading-tools/video-tutorials"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="فيديوهات تعليمية" />
                </Link>
                <Link to="/ar/support/faqs" onClick={() => setOpen(!open)}>
                  <SimpleListItem text="الأسئلة المتكررة" />
                </Link>
              </CollapsibleList>
            </CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="المركز الإعلامي" />}>
              <Link
                to="/ar/media-center/press-release"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="الأخبار الصحفية" />
              </Link>
              <Link
                to="/ar/media-center/activities"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="الأنشطة" />
              </Link>
              <Link to="/ar/media-center/awards" onClick={() => setOpen(!open)}>
                <SimpleListItem text="الجوائز" />
              </Link>
            </CollapsibleList>

            <CollapsibleList
              open
              handle={
                <Link to="/ar/blog" onClick={() => setOpen(!open)}>
                  {" "}
                  <SimpleListItem text="المدونة" />
                </Link>
              }
            ></CollapsibleList>
          </List>
        </DrawerContent>

        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <span>
            <a
              href="https://mynoor.noorcap.ae/en/traders/login"
              target="_blank"
            >
              <Button
                style={{
                  backgroundColor: "#0f75bc",
                  color: "#fff",
                  border: "0",
                  fontWeight: "600",
                  borderRadius: "0",
                  marginLeft: "20px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                منطقة العملاء
              </Button>
            </a>
          </span>
          <br />
          <span>
            <a href="https://agent.noorcap.ae/en/agent/login" target="_blank">
              <Button
                style={{
                  backgroundColor: "#0f75bc",
                  color: "#fff",
                  border: "0",
                  fontWeight: "600",
                  borderRadius: "0",
                  marginLeft: "20px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                منطقة الوسطاء
              </Button>
            </a>
          </span>
          <br />
          <span>
            <Link to="/ar/trading/accounts/account-funding">
              <Button
                style={{
                  backgroundColor: "#0f75bc",
                  color: "#fff",
                  border: "0",
                  fontWeight: "600",
                  borderRadius: "0",
                  marginLeft: "20px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                الإيداع والسحب
              </Button>
            </Link>
          </span>
        </div>
        <br />
        <br />
        <br />
      </Drawer>

      <div
        style={{ left: "18px", top: "158px" }}
        className={classnames(menuStyle["burger-menu"])}
        onClick={() => setOpen(!open)}
        raised
      >
        <div
          className={classnames(
            menuStyle["line-menu"],
            menuStyle["line-half"],
            menuStyle["first-line"]
          )}
        />

        <div className={classnames(menuStyle["line-menu"])} />

        <div
          style={{ left: "8px" }}
          className={classnames(
            menuStyle["line-menu"],
            menuStyle["line-half"],
            menuStyle["last-line"]
          )}
        />
      </div>
    </>
  );
}
export default MobileNavAr;
