import React from "react";
import classnames from 'classnames';
import bgBeforeStyle from './bgBeforeAr.module.css';

const BgBeforeAr = (prop) => {
return (
<>
<div className={classnames(bgBeforeStyle['jumbotron'], bgBeforeStyle['jumbotron-fluid'], bgBeforeStyle['Jumbotron-bg-trading'])}  style={{backgroundImage: `url(${prop.pageHeadingbg})` }}>
  <div className={classnames(bgBeforeStyle['jumbotroncontainer'])}>
    <h1 className={classnames(bgBeforeStyle['Jumbotron-h1'],bgBeforeStyle['display-4'])}>{prop.pageHeadingTitle}</h1>
    <br/>
    <p class="lead Jumbotron-p">{prop.pageHeadingDescription}</p>
  </div>
</div>

{/* </div> */}
</>
)

};

export default BgBeforeAr;
