import React, { useEffect } from "react";

const StockPrice1 = () => {
  useEffect(() => {
    if (document.getElementById("price1")) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-tickers.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [
          {
            description: "Amazon",
            proName: "NASDAQ:AMZN",
          },
          {
            description: "Tesla",
            proName: "NASDAQ:TSLA",
          },
          {
            description: "Pfizer",
            proName: "NYSE:PFE",
          },
          {
            description: "Netflix",
            proName: "NASDAQ:NFLX",
          },
          {
            description: "Apple",
            proName: "NASDAQ:AAPL",
          },
          {
            description: "Microsoft",
            proName: "NASDAQ:MSFT",
          },

          {
            description: "Zoom",
            proName: "NASDAQ:ZM",
          },
          // {
          //   description: "Walt Disney",
          //   proName: "NYSE:DIS",
          // },
        ],

        showSymbolLogo: true,
        colorTheme: "light",
        isTransparent: false,
        largeChartUrl: "https://mynoor.noorcap.ae/en/traders/login",
        locale: "ar_AE",
      });

      document.getElementById("price1").appendChild(script);
    }
  }, []);

  return <p id="price1"></p>;
};

export default StockPrice1;
