import React, { Component } from "react";
import { Link } from "react-router-dom";
import slide1 from "../../assets/images/slides/Sliders1ar.jpg";
import slide2 from "../../assets/images/slides/Sliders2ar.jpg";
import slide3 from "../../assets/images/slides/Sliders3ar.jpg";
import slide4 from "../../assets/images/slides/Sliders4ar.jpg";
import slide5 from "../../assets/images/slides/Sliders5ar.jpg";

// Eid Slider 1
// import slide0 from "../../assets/images/slides/Sliders0Ar.jpg";

import "./sliderAr.css";

const btn1 = "/ar/about/why-noor-capital";
const btn2 = "/ar/trading-platforms/mt4-open-forex-demo";
const btn3 = "/stocks-trading-platforms/open-gtn-live";
const btn4 = "/physical-trading-platforms/open-noor-trader-demo";
const btn5 = "/ar/education/noor-academy";
const btn6 = "/ar/activities/36";

const SliderAr = () => {
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {slides.map((item, index) => (
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to={item.id}
              className={index === 0 ? "active" : " "}
            ></li>
          ))}
        </ol>
        <div className="carousel-inner">
          {slides.map((item, index) => (
            <div
              className={
                "carousel-item position-relative " +
                (index === 0 ? "active" : " ")
              }
            >
              <img
                className="d-block w-100 slideronmobileAr"
                src={item.img}
                alt={item.mainHeading}
              />
              <div className="carousel-caption" style={{ textAlign: "right" }}>
                {/* <h2 className="display-5" style={{"color":"#fff"}}>{item.mainHeading}</h2> */}
                <h4
                  style={{ marginRight: "-80px" }}
                  className="sliderheadingonmobileAr"
                >
                  {item.mainHeading}
                </h4>
                <p
                  className="lead slidersubheadingonmobileAr"
                  style={{ marginRight: "-80px" }}
                >
                  {item.description}
                </p>
                <Link
                  style={{ marginRight: "-80px" }}
                  class="btn btn-success px-3 carousel-btnAr"
                  to={item.link}
                >
                  {item.btnLabel}
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Eid Slider 2 */}
        {/* <style jsx>
          {`
            #carouselExampleIndicators > div > div:nth-child(1) > div > a {
              display: none;
            }
          `}
        </style> */}
{/* 
<style jsx>
        {`
          #carouselExampleIndicators > div > div:nth-child(1) > div > a{
            display: none;
          }
        `}
      </style> */}

      </div>
    </>
  );
};

const slides = [

  // {
  //   id: "0",
  //   description:
  //     "",
  //   mainHeading: "اجتماع الهيئة العامة للسنة المالية 2021-2022",
  //   btnLabel: "اطلع على المزيد",
  //   img: slide5,
  //   link: btn6,
  // },

  {
    id: "0",
    description:
      "نحن نقدم لك حلول استثمارية مبتكرة  تلائم كافة احتياجاتك المالية",
    mainHeading: "أرتق باستثماراتك مع نور كابيتال",
    btnLabel: "اطلع على المزيد",
    img: slide1,
    link: btn1,
  },

  // {
  //   id: "0",
  //   description:
  //     "",
  //   mainHeading: "",
  //   btnLabel: "",
  //   img: slide1,
  //   link: btn1,
  // },


  {
    id: "1",
    description:
      "تداول على منصة ميتاتريدر4، منصة متطورة تمكنك من التداول بسهولة وأمان",
    mainHeading: "تداول بثقة على منصة تداول العملات والأسهم الرائدة عالميا",
    btnLabel: "افتح حساب تجريبي",
    img: slide2,
    link: btn2,
  },
  {
    id: "2",
    description:
      "منصة نور كابيتال لتداول الأسهم  مسؤولة عن الأسهم الخاصة بك حيث تتيح لك التداول أينما كنت",
    mainHeading: "طور طريقتك في تداول الأسهم",
    btnLabel: "سجل الآن",
    img: slide3,
    link: btn3,
  },
 
  {
    id: "3",
    description:
      "انضم إلى أفضل دورة لاحتراف التداول، مادة تعليمية صممت من أجلك لتكون متداول محترف",
    mainHeading: "هل تريد أن تصبح محترفًا في عالم التداول؟",
    btnLabel: "سجل الآن",
    img: slide5,
    link: btn5,
  },
];
export default SliderAr;
