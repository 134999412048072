import React from "react";
import {Link} from "react-router-dom";
import { Tooltip } from 'antd';
import stickyButtons from "./stickyButtons.module.css";

const tooltipposition = "left"

const StickyButtons = () => {
  return( 

<div className={stickyButtons.stickyButtons} style={{"zIndex":"999","opacity":"0.8"}}>
    
<Tooltip placement={tooltipposition} title={"Technical Support"}><Link to="/support/technical-support" className={stickyButtons.support}><i class="fa fa-question"></i></Link> </Tooltip>
<Tooltip placement={tooltipposition} title={"Call Back Request"}><Link to="/support/call-back-request" className={stickyButtons.call}><i class="fa fa-phone"></i></Link> </Tooltip>
<Tooltip placement={tooltipposition} title={"Blog"}><Link to="/blog" className={stickyButtons.blog}><i class="fa fa-blog"></i></Link> </Tooltip>
</div>

    )
    
  };
  
  export default StickyButtons;