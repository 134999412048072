import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import img1 from "../../assets/images/bgservicesbanner2Ar.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  "تحديثات منتظمة للفرص الاستثمارية.",
  "وضع مصلحة المستثمر في المقام الأول بنسبة 100% بأعلى مستوى من الأمن والسرية.",
  "تساعدك نور كابيتال على اتخاذ قرارات مالية دقيقة وسريعة.",
  "تقديم حلول تتناسب مع أهداف ومتطلبات كل عميل.",
  "اختيار إنشاء حساب لدى نور كابيتال سواء إلكتروني أو من خلال مقر الشركة.",
  "يُعد قسم التوظيف الاستثماري متاح باستمرار للمستثمرين في دول مجلس التعاون الخليجي، بما في ذلك الإمارات العربية المتحدة والمملكة العربية السعودية وقطر وعمان والكويت والبحرين والشرق الأوسط.",
  "خلق ميزات تنافسية تعد الأفضل في السوق.",
  "تُقدم المعلومات الهامة للمستثمرين بانتظام، مما يؤمن  المستثمرين ضد عمليات الاحتيال.",
  "فريق معتمد ومحترف يمتلك سنوات من الخبرة في السوق.",
  "خدمة عملاء متاحة24 ساعة يوميًا / 5 أيام أسبوعيًا متعددة اللغات.",
];
const { Title } = Typography;

const Investment = () => {
  return (
    <div>
      <PageseMetaTags
        title="Investment Service - Better investment decisions with Noor Capital"
        description="The Investment Placement Division’s mandate is to serve the network of regional investors with superior unique investment solutions recommended from Noor Capitals team"
      />
      <BgBeforeAr
        pageHeadingTitle="كًون ثروة لا تنتهي مع خدمات نور كابيتال الاستثمارية"
        pageHeadingDescription="اتصلالآن!"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الخدمات"
        pageSubCategoryPath="#"
        pageName="الاستثمار المباشر"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          الاستثمار المباشر

          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify" >
            نور كابيتال هي شركة استثمار ووساطة رائدة في الشرق الأوسط، حيث تقدم فرصًا استثمارية حصرية لمستثمريها الإقليميين وتساعدهم على إنشاء وإدارة استثماراتهم بأمان وسلاسة.
            </p>

            <p className="parjustify">
            نحن نقدم مجموعة واسعة من الفرص المالية، بما في ذلك الاستثمارات وإدارة الأصول والاستشارات المالية وإدارة الصناديق والوساطة في الأسواق العالمية لتداول الفوركس عبر الإنترنت وصناديق الذهب وسبائك الذهب والفضة والعملات.
            </p>

            <p className="parjustify">
            نؤمن في نور كابيتال بتوفير حل استثماري قوي بقيادة فريق معتمد وذوي خبرة في مختلف المجالات. خدماتنا متاحة للأفراد والمؤسسات والمستثمرين من الشركات في جميع أنحاء الخليج.
            </p>

            <p className="parjustify">
            توفر نور كابيتال للاستثمار حلول تمويل لا مثيل لها لكل عميل وفقًا لأهدافه وتفضيلاته، بداية من منتجاتنا ذات الجودة العالية وتوفير جهات أخرى ذات ريادة في الصناعة والأفضل في فئتهم.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={investmentimg}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          لماذا تختار نور كابيتال لخدمات الاستثمار؟
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              طًور من أدائك المالي مع خدمات نور كابيتال الاستثمارية
              </Title>
              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/ar/about/contact-us"
                >
                  اتصل بنا
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block">
        <div className="row">
          <div className={globalstyle.container}>
            <div className="col" style={{ padding: "15px" }}>
              <h1
                className="display-5 text-center"
                style={{ color: "#11131b" }}
              >
                خدمات أخرى
              </h1>
            </div>

            <div className="row text-right">
              <div className="col-12 col-md-8 m-auto col-lg-4">
                <div className="fdb-box fdb-touch">
                  <h4>الإستشارات المالية العالمية</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                  مع تركيزنا على سوق الإمارات العربية المتحدة والمملكة العربية السعودية، نجحنا في توسيع أجنحتنا الاستشارية لتشمل مناطق أخرى في الشرق الأوسط وشمال
                  </p>
                  <p>
                    <Link to="/ar/services/global-financial-advisory">
                    قراءة المزيد
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>إدارة الأصول</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                  نحن نقدم حلًا سلسًا لإدارة الأصول للمؤسسات والأفراد ذوي الثروة المالية الكبيرة والمستثمرين الأفراد من خلال قنوات مباشرة وأطراف أخرى
                  </p>
                  <p>
                    <Link to="/ar/services/asset-management">قراءة المزيد</Link>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>إدارة صناديق الإستثمار</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                      توفر لك نور كابيتال حلاً قويًا وسلسًا لإدارة صناديق
                      الاستثمار، خاصةً لشركة إدارة الأصول وصناديق الاستثمار. نحن
                      نقدم حلاً جاهزًا لحساب صافي قيمة الأصول
                    </p>
                  <p>
                    <Link to="/ar/services/funds-administration">قراءة المزيد</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Investment;
