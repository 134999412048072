import React from "react";
import globalstyleAr from "../../globalstyleAr.module.css";
import globalstyle from "../../style.module.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./footer.css";
import a1 from "../../assets/images/a1.png";
import a2 from "../../assets/images/a2.png";
import a3 from "../../assets/images/a3.png";
import a4 from "../../assets/images/a4.png";
import a5 from "../../assets/images/a5.png";
import a6 from "../../assets/images/a6.png";

const Footer = () => {
  return (
    <>
      <footer className="site-footer">
        <div className={globalstyle.container}>
          <div className="row px-auto pt-3">
            <div className="col-md-12">
              <p>
                <strong>تحذير المخاطر: </strong>
                <span style={{ fontSize: "14px" }}>
                  {" "}
                  يتم تداول العملات الأجنبية (فوركس، سوق العملات الأجنبية) وعقود
                  الفروقات (CFD) على العملات أو السلع أو المؤشرات أو الأسهم
                  بنظام الهامش. وقد ينطوي التداول في هذه المنتجات على درجة عالية
                  من المخاطرة التي قد تتناسب مع جميع المستثمرين. قبل أن تقرر
                  البدء في تداول أدوات الفوركس / عقود الفروقات التي تقدمها شركة
                  نور كابيتال ش.م.ع، يجب أن تضع في الاعتبار أهدافك ووضعك المالي
                  الحالي واحتياجاتك ومستوى خبرتك. بالكامل
                  <Link
                    to="/ar/trading-tools/risk-disclosure"
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    لإفصاح عن المخاطر
                  </Link>{" "}
                  لمزيد من التفاصيل.
                </span>
              </p>
            </div>
          </div>
          <div className="smallSeperator"></div>

          <hr />

          <div className="row mx-auto text-center">
            <div className="col-md-3">
              <h6>الخدمات</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/ar/services/global-financial-advisory">
                    الإستشارات المالية العالمية
                  </Link>
                </li>
                <li>
                  <Link to="/ar/services/asset-management">إدارة الأصول</Link>
                </li>
                <li>
                  <Link to="/ar/services/investment">المباشر الاستثمار</Link>
                </li>
                <li>
                  <Link to="/ar/services/funds-administration">
                    إدارة الأموال
                  </Link>
                </li>
                <li>
                  <Link to="/ar/about/why-noor-capital">لماذا تختارنا؟</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h6>الأسواق</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/ar/trading/instruments/forex">العملات</Link>
                </li>
                <li>
                  <Link to="/ar/trading/instruments/precious-metals">
                    المعادن الثمينة
                  </Link>
                </li>
                <li>
                  <Link to="/ar/trading/instruments/energies">الطاقة</Link>
                </li>
                <li>
                  <Link to="/ar/trading/instruments/indices">المؤشرات</Link>
                </li>
                <li>
                  <Link to="/ar/trading/instruments/shares-cfds">
                    أسواق الأسهم
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h6>التداول</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/ar/trading-platforms/metatrader4-platform">
                    تداول العملات والسلع
                  </Link>
                </li>
                
                <li>
                  <Link to="/ar/stocks-trading-platforms/gtn">
                    منصة الأسهم العالمية
                  </Link>
                </li>
                <li>
                  <Link to="/ar/institutional/request-demo">
                    عملاء المؤسسات
                  </Link>
                </li>
                <li>
                  <Link to="/ar/trading/accounts/account-forms">
                    مستندات فتح الحسابات
                  </Link>
                </li>

                <li>
                  <Link to="/ar/trading/accounts/trading-conditions">
                  شروط وأحكام التداول
                                    </Link>
                </li>

              </ul>
            </div>
            <div className="col-md-3">
              <h6>ادوات التداول</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/ar/trading-tools/economic-calendar">
                    الأجندة الاقتصادية
                  </Link>
                </li>
                <li>
                  <Link to="/ar/trading-tools/trading-signals">
                    إشارات التداول
                  </Link>
                </li>
                <li>
                  <Link to="/ar/trading-tools/traders-glossary">
                    قاموس المصطحات
                  </Link>
                </li>
                <li>
                  <Link to="/ar/trading-tools/risk-disclosure">
                    تحذير المخاطر
                  </Link>
                </li>
                <li>
                  <Link to="/ar/trading-tools/video-tutorials">
                    فيديوهات تعليمية
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mx-auto">
            <div className="col-md-12">
              <br />
            </div>
          </div>
          <div className="row mx-auto">
            <div
              className="col-md-8"
              style={{
                textAlign: "right",
                paddingTop: "20px",
                lineHeight: "35px",
              }}
            >
              <span>

              <b style={{ paddingLeft: "5px" }}>تواصل معنا  : </b>


                البريد الإلكتروني: {""}
                <a href="mailto:info@noorcapital.ae">info@noorcapital.ae</a>
                

                <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  |
                </span>


                أبو ظبي : {""} <a href="tel:+97126266888">0097126266888</a>

                <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  |
                </span>


                دبي : {""}  <a href="tel:+97142795400">0097142795400</a>
              </span>

              


            </div>

            <div className="col-md-4">
              <div className="social-buttons">
                <b>تابعنا : </b>
                <a href="https://twitter.com/noor_capital" target="_blank">
                  <i className="fab fa-twitter" target="_blank"></i>
                </a>
                <a
                  href="https://www.facebook.com/noorcapitaluae"
                  target="_blank"
                >
                  <i className="fab fa-facebook" target="_blank"></i>
                </a>
                <a
                  href="https://www.instagram.com/noorcapital/"
                  target="_blank"
                >
                  <i className="fab fa-instagram" target="_blank"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/noorcapitaluae/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in" target="_blank"></i>
                </a>
                <a
                  href="https://www.youtube.com/NoorCapitaluae"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <hr />

          <div className="smallSeperator"></div>

          <div className="row mx-auto">
            
          <div className="col-md-2  p-3">
              <Link to="/">
                {" "}
                <img src={logo} width="100px" />
              </Link>
            </div>

            <div className="col-md-4 p-2">
              <div
                id="blogCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                {/* <ol className="carousel-indicators">
                <li data-target="#blogCarousel" data-slide-to={0} className="active" />
                <li data-target="#blogCarousel" data-slide-to={1} />
              </ol> */}

                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-3">
                        <Link to="/ar/awards/22">
                          <img
                            src={a1}
                            alt="Awards 2021"
                            style={{ maxWidth: "70%" }}
                          />
                        </Link>
                      </div>
                      <div className="col-md-3">
                      <Link to="/ar/awards/21">
                          <img
                            src={a2}
                            alt="Awards 2020"
                            style={{ maxWidth: "70%" }}
                          />
                        </Link>
                      </div>
                      <div className="col-md-3">
                      <Link to="/ar/awards/20">
                          <img
                            src={a3}
                            alt="Awards 2019"
                            style={{ maxWidth: "70%" }}
                          />
                        </Link>
                      </div>
                      <div className="col-md-3">
                      <Link to="/ar/awards/19">
                          <img
                            src={a4}
                            alt="Awards 2019"
                            style={{ maxWidth: "70%" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-3">
                      <Link to="/ar/awards/17">
                          <img
                            src={a5}
                            alt="Awards 2017"
                            style={{ maxWidth: "70%" }}
                          />
                        </Link>
                      </div>
                      <div className="col-md-3">
                      <Link to="/ar/awards/16">
                          <img
                            src={a6}
                            alt="Awards 2016"
                            style={{ maxWidth: "70%" }}
                          />
                        </Link>
                      </div>
                      <div className="col-md-3">
                      <Link to="/ar/awards/22">
                          <img
                            src={a1}
                            alt="Awards 2021"
                            style={{ maxWidth: "70%" }}
                          />
                        </Link>
                      </div>
                      <div className="col-md-3">
                      <Link to="/ar/awards/21">
                          <img
                            src={a2}
                            alt="Awards 2020"
                            style={{ maxWidth: "70%" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
               
               
                  
                </div>
              </div>
            </div>

            <div className="col-md-6 pt-6">
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "26px",
                  fontSize: "12px",
                }}
              >
                <Link to="/ar/about/license-regulations">
                  التراخيص واللوائح
                </Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>
                <Link to="/ar/fraud-warning">تحذير من الإحتيال</Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>{" "}
                <Link to="/ar/terms-conditions">الشروط والأحكام</Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>{" "}
                <Link to="/ar/privacy-policy">سياسة الخصوصية</Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>{" "}
                <Link to="/ar/cookies-policy">سياسة الكوكيز</Link>{" "}
                <span style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                  |
                </span>{" "}
                <Link to="/ar/site-map">خريطة الموقع </Link>
              </div>
            </div>
          
          
          </div>
          <hr style={{ marginTop: "20px" }} />
          <div className="row mx-auto">
            <div
              className="col-md-12 pt-12"
              style={{ textAlign: "center", fontSize: "12px" }}
            >
              <p>حقوق النشر &copy; 2005-2021 كل الحقوق محفوظة</p>

              <a href="//www.dmca.com/Protection/Status.aspx?ID=6e7409fe-74b6-41e1-a5fe-1af3077b9d5d" title="DMCA.com Protection Status" class="dmca-badge">
                <img src ="https://images.dmca.com/Badges/_dmca_premi_badge_5.png?ID=6e7409fe-74b6-41e1-a5fe-1af3077b9d5d"  alt="DMCA.com Protection Status" /></a> 
                <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>

                
            </div>
          </div>
        </div>
      </footer>

      <style jsx>{`
        ul {
          padding: 0;
          list-style-type: none;
        }
        ul li {
          margin-bottom: 6px;
        }
        .social-buttons {
          text-align: left;
        }
        .disclaimer {
          font-size: 13px;
          text-align: justify;
          background-color: #ffffff;
          color: #bbb;
          padding: 20px 0 0px 0px;
          line-height: 25px;
        }
        .icon-size {
          font-size: 2.5em;
        }

        .footer-links li a:hover {
          color: #1b75bc !importanr;
        }

        .footerBar {
          background-color: #1d8df0;
          padding: 11px;
          border-radius: 5px;
          margin-bottom: 15px;
          text-align: center;
          transition: top ease 0.8s;
          top: 0;
          position: relative;
        }

        .footerBar > span {
          color: #ffffff;
          font-size: 18px;
          padding: 10px;
        }

        .footerBar :hover {
          background-color: #0b68d4;
          cursor: pointer;
          top: -4px;
        }

        .site-footer {
          background-color: #11131b !important;
          padding: 45px 0 45px 0;
          color: #f6f6f6;
          text-align: right;
        }

        .footer-logo-section {
          display: flex;
          height: 90%;
          flex-direction: column;
          justify-content: space-around;
        }

        .site-footer hr {
          border-top-color: #bbb;
          opacity: 0.5;
        }

        .site-footer hr.small {
          margin: 20px 0;
        }

        .site-footer h6 {
          color: #f6f6f6;
          font-size: 17px;
          font-weight: 500;
          // text-transform:uppercase;
          margin-top: 5px;

          text-align: right;
        }

        .site-footer a {
          color: #f6f6f6;
        }

        .site-footer a:hover {
          color: #1a86da;
          text-decoration: none;
        }

        .footer-links {
          padding-left: 0;
          list-style: none;
          text-align: right;
        }

        .footer-links .item {
          display: block;
          text-align: right;
          margin-top: 7px;
          font-size: 13px;
        }

        .footer-links a {
          color: #f6f6f6;
          text-align: right;
        }

        .footer-links a:active,
        .footer-links a:focus,
        .footer-links a:hover {
          color: #1b75bc !important;
          text-decoration: none;
        }

        .footer-links.inline li {
          display: inline-block;
        }

        .site-footer .social-icons {
          margin-top: 10px;
        }

        .site-footer .social-icons a {
          width: 40px;
          height: 40px;
          line-height: 40px;
          margin-left: 7px;
          margin-right: 0;
          border-radius: 100%;
          background-color: #f6f6f6;
        }

        .copyright-text {
          margin: 0;
          text-align: center;
        }

        @media (max-width: 991px) {
          .site-footer [className^="col-"] {
            margin-bottom: 30px;
          }
        }

        @media (max-width: 767px) {
          .site-footer {
            padding-bottom: 0;
          }

          .site-footer .copyright-text,
          .site-footer .social-icons {
            text-align: center;
          }
        }

        .social-icons {
          margin-bottom: 0;
          list-style: none;
          padding: 0;
          list-style-type: none;
        }

        .social-icons li {
          display: inline-block;
          margin-bottom: 4px;
        }

        .social-icons li.title {
          margin-right: 15px;
          text-transform: uppercase;
          color: #96a2b2;
          font-weight: 700;
          font-size: 13px;
        }

        .social-icons a {
          background-color: #eceeef;
          color: #818a91;
          font-size: 16px;
          display: inline-block;
          line-height: 44px;
          width: 44px;
          height: 44px;
          text-align: center;
          margin-right: 8px;
          border-radius: 100%;
          -webkit-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }

        .social-icons a:active,
        .social-icons a:focus,
        .social-icons a:hover {
          color: #fff;
          background-color: #29aafe;
        }

        .social-icons.size-sm a {
          line-height: 34px;
          height: 34px;
          width: 34px;
          font-size: 14px;
        }

        .social-icons a.facebook:hover {
          background-color: #3b5998;
        }

        .social-icons a.twitter:hover {
          background-color: #00aced;
        }

        .social-icons a.linkedin:hover {
          background-color: #007bb6;
        }

        .social-icons a.dribbble:hover {
          background-color: #ea4c89;
        }

        @media (max-width: 767px) {
          .social-icons li.title {
            display: block;
            margin-right: 0;
            font-weight: 600;
          }
        }
      `}</style>
    </>
  );
};

export default Footer;
