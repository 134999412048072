import React from "react";
import { Row, Col } from 'antd';

import globalstyle from '../../style.module.css';

import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = { padding: "50px 0", alignSelf: "justify" };

const FraudWarning = () => {
  return(
    
    <div>
          <PageseMetaTags
                title="Fraud Warning - Noor Capital"
                description="We encourage our customers to validate the identity of any institution or individual claiming to represent Noor Capital."
            />

            <BgBefore 
            pageHeadingTitle="Fraud Warning"
            pageHeadingDescription="Protect Yourself And Your Data From Fraudulent Activities."
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Fraud Warning"
      />

    
    <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Protect Yourself And Your Data From Fraudulent Activities.</h4>
              <p className={globalstyle.parjustify}>
              With the internet part of our daily lives, we can witness online fraud, especially with data and money. The following page will help our customers protect themselves from fraudulent activities by institutions or individuals claiming to be a Noor Capital representative.
              </p>
              <p className={globalstyle.parjustify}>
              Noor Capital staff conducts business through Noor Capital's managed email services under the same domain. We do not use social media platforms or personal emails to contact customers or conduct business. Noor Capital's representative doesn't communicate with the customers through social media or private emails, asking them to disclose their data or account details.
              </p>
              <p className={globalstyle.parjustify}>
              We encourage our customers to validate the identity of any institution or individual claiming to represent Noor Capital. You should proceed with caution, especially while transferring funds or disclosing personal details. If you feel the institution or individual offering various financial products is engaged in fraud, you should immediately alert the local authority.
              </p>
              <p className={globalstyle.parjustify}>
              Beware of the fraudulent using Noor Capital name, clone website, claim to be an employee, and request personal details, account details, or money transfer.
              </p>
              <p className={globalstyle.parjustify}>
              Noor Capital is committed to protecting our customer's data and money. Although we don't take any responsibility for the individual falling into fraud schemes, we take every step possible to minimize the fraud.
              </p>
              <p className={globalstyle.parjustify}>
              Alert: If you suspect your data or money is at risk by frauds, please contact Noor Capital immediately or contact your nearest police station. 
              </p>
            </div>
          </div>

      
        </section>






    </div>

  </div>
  )

};

export default FraudWarning;