import React, { useEffect } from "react";

const PriceTickerAr = () => {
  useEffect(() => {
    if (document.getElementById("chart")) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [
          {
            proName: "FOREXCOM:NSXUSD",
            title: "Nasdaq 100"
          },
          {
            proName: "FX_IDC:EURUSD",
            title: "EUR/USD"
          },
          {
            proName: "BITSTAMP:BTCUSD",
            title: "BTC/USD"
          },
          {
            description: "JPY/USD",
            proName: "FX_IDC:JPYUSD"
          },
          {
            description: "GBP/USD",
            proName: "OANDA:GBPUSD"
          },
          {
            description: "USD/CHF",
            proName: "KRAKEN:USDCHF"
          },
          {
            description: "AUD/USD",
            proName: "OANDA:AUDUSD"
          },
          {
            description: "XAU/USD",
            proName: "OANDA:XAUUSD"
          },
          {
            description: "XAG/USD",
            proName: "OANDA:XAGUSD"
          }


        ],
        colorTheme: "dark",
        isTransparent: false,
        displayMode: "adaptive",
        largeChartUrl: "https://mynoor.noorcap.ae/en/traders/login",
        locale: "ar_AE",
      });

      document.getElementById("chart").appendChild(script);
    }
  }, []);

  return <div id="chart"></div>;
};

export default PriceTickerAr;
