import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from "rmwc";

import { BASE_URL, COUNTRIES, DEMO_FX } from "../../constants";
import { getStorage } from "../../utils/helpers";

import { emiratesAr, hearaboutAr } from "./options";

export default function OpenFxDemoFormAr() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "AE",
    emirate: "",
    hearabout: "",
    agree: false,
  };

  const [state, setState] = React.useState(initialState);

  const [countries, setCountries] = React.useState([{ name: "", code: "" }]);

  const [requestSent, setRequestSent] = React.useState(false);

  let queryParams = {};
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "ar",
  };

  React.useEffect(() => {
    axios
      .get(BASE_URL + COUNTRIES, { headers: headers })
      .then((countries) => {
        setCountries(countries.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleCountryChange(event) {
    const newState = { ...state, country: event.target.value };
    setState(newState);
  }

  function handleHearAboutChange(event) {
    const newState = { ...state, hearabout: event.target.value };
    setState(newState);
  }

  function handleFormChange(event) {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  }

  function handleAgreeChange(event) {
    const newState = { ...state, agree: event.target.checked };
    setState(newState);
  }

  function submitOpenFxDemo(event) {
    event.preventDefault();

    const data = {
      first_name: state.firstName,
      last_name: state.lastName,
      phone: state.phoneNumber,
      email: state.email,
      country: state.country,
      emirate: state.emirate,
      hearabout: state.hearabout,
      agree: state.agree,
    };
    let newData = data;
    if (getStorage('queryParams')) {
      queryParams = getStorage('queryParams');
      newData = {
        ...data,
        refrence_sp: queryParams.SP,
        refrence_ib: queryParams.IB,
        refrence_p: queryParams.P,
      };
    }
    axios
      .post(BASE_URL + DEMO_FX, newData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          setRequestSent(true);
          setState(initialState);
        }
      });
  }

  return (
    <div>
      
      <form onSubmit={submitOpenFxDemo}>
        <div className="row">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="الاسم الأول"
              name="firstName"
              value={state.firstName}
              onChange={handleFormChange}
            />
          </div>

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="اسم العائلة"
              name="lastName"
              value={state.lastName}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="email"
              required
              className="form-control"
              placeholder="البريد الإلكتروني"
              name="email"
              value={state.email}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="رقم الهاتف"
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleFormChange}
            />

            <small className="form-text text-muted  text-right">
              مثال: 97150xxxxxxx+
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              onChange={handleCountryChange}
              value={state.country}
            >
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
            <small className="form-text text-muted text-right">الجنسية</small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="emirate"
              onChange={handleFormChange}
              value={state.emirate}
            >
              {emiratesAr.map((emirate) => (
                <option key={emirate.emirate_code} value={emirate.emirate_code}>
                  {emirate.emirate_name}
                </option>
              ))}
            </select>
            <small className="form-text text-muted  text-right">
              للمقيمين في دولة الإمارات فقط
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="hearabout"
              required
              onChange={handleHearAboutChange}
              value={state.hearabout}
            >
              {hearaboutAr.map((hear) => (
                <option key={hear.hearabout_code} value={hear.hearabout_code}>
                  {hear.hearabout_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div
            className="col"
            style={
              {
                // padding: "0px 0px 0px 35px",
              }
            }
          >
            <input
              type="checkbox"
              className="form-check-input pull-right"
              name="agree"
              required
              onChange={handleAgreeChange}
              defaultChecked={state.agree}
              checked={state.agree}
              style={{ display: "block" }}
            ></input>
            <small
              className="form-text text-muted form-check-label text-right"
              htmlFor="gridCheck"
              style={{ paddingRight: "20px" }}
            >
              أوافق على تلقي معلومات من نور كابيتال حول منتجاتها وخدماتها .
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button type="submit" className="btn btn-primary">
              إرسال
            </button>
          </div>
        </div>
      </form>



      <Dialog
        body="Your request is sent successfully."
        open={requestSent}
        onClose={(event) => {
          setRequestSent(false);
        }}
      >
        <DialogTitle>تم!</DialogTitle>
        <DialogContent>
          تم إستقبال طلبك بنجاح. <br />
          سيتم التواصل معك قريبا.
          <br/>
          ملاحظة: للحصول على اسم المستخدم والرقم السري، يرجى مراجعة البريد الإلكتروني الوارد/ البريد العشوائي
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">إغلاق</DialogButton>
        </DialogActions>
      </Dialog>

      <style jsx>{`

        .blog .carousel-indicators {
          left: 0;
          top: auto;
          bottom: -40px;
        }
        #root > div > div > div:nth-child(4) > div.style_container__uC_8Q > div.ant-row.ant-row-center > div.ant-col.ant-col-xs-24.ant-col-sm-24.ant-col-md-16.ant-col-lg-16.ant-col-xl-16 > div > form > div:nth-child(4) > div > select > option:nth-child(110) {
          display: none;
          overflow: hidden;
        }

      `}
      </style>

      
    </div>
  );
}
