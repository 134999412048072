import React from "react";
import "./mainPage.css";
import { Link } from "react-router-dom";
import platform from "../../assets/images/home/platform.png";
import mt4 from "../../assets/images/home/mt4.png";
import gtn from "../../assets/images/home/gtn.png";
import noortrader from "../../assets/images/home/noortrader.png";

import AOS from "aos";

AOS.init({ duration: 2500, delay: 700 });

const MainPage3 = () => {
  return (
    <>
      {/* START ROW 33333333 */}
      <div style={{ backgroundColor: "#f2f4f7" }}>
      {/* <div style={{ backgroundImage: `url(${sectionbg})` ,"height":"100%","backgroundPosition":"center","backgroundRepeat":"no-repeat","backgroundSize":"cover"}}> */}
        <section className="fdb-block">
          <div className="container">
            <div className="row align-items-center pb-xl-5">
              <div className="col-12 col-md-7 col-xl-5">
                <h1 style={{ color: "#1b75bc" }}>
                  Multiple Innovative Platforms
                </h1>
                <p className="lead">
                  We provide our clients with a wide range of trading platforms
                  for desktop, web, and mobile to always connect with their
                  investments
                  {/* We provide our clients with a wide range of desktop, web and mobile trading platforms including MetaTrader 4, GTN and NoorTrader. */}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-6 m-sm-auto mr-md-0 ml-md-auto pt-4 pt-md-0">
                <img
                  alt="Platforms"
                  className="img-fluid img-fluid-onmobile"
                  
                  src={platform}
                />
              </div>
            </div>
            <div className="row pt-5" style={{ textAlign: "center" }}>
              <div className="col-12 col-sm-6 col-md-4" >
                <img alt="Platforms" className="img-fluid" data-aos="fade-right" src={mt4} />
                <Link
                  to="/trading-platforms/metatrader4-platform"
                  className="effect-underline"
                >
                  <h3 style={{ color: "#1b75bc" }}>
                    Forex Trading{" "}
                    <i
                      class="fas fa-angle-right"
                      style={{ fontSize: "16px", marginLeft: "4px" }}
                    ></i>
                  </h3>{" "}
                </Link>
              </div>

              <div className="col-12 col-sm-6 col-md-4 pt-4 pt-sm-0">
                <img alt="Platforms" className="img-fluid" data-aos="flip-up" src={gtn} />
                <Link
                  to="/stocks-trading-platforms/gtn"
                  className="effect-underline"
                >
                  <h3 style={{ color: "#1b75bc" }}>
                    Stock Trading{" "}
                    <i
                      class="fas fa-angle-right"
                      style={{ fontSize: "16px", marginLeft: "4px" }}
                    ></i>
                  </h3>
                </Link>
              </div>
              <div className="col-12 col-sm-6 col-md-4 pt-4 pt-md-0">
                <img alt="Platforms" className="img-fluid" data-aos="fade-left" src={noortrader} />
                <a
                  href="https://noorindex.com/"
                  className="effect-underline"
                  target="_blank"
                >
                  <h3 style={{ color: "#1b75bc" }}>
                    Physical Trading Solutions
                    <i
                      class="fas fa-angle-right"
                      style={{ fontSize: "16px", marginLeft: "4px" }}
                    ></i>
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* End ROW 33333333 */}
    </>
  );
};

export default MainPage3;
