import React from "react";
import ntmobile from "../../assets/images/pages/trading/nt-mobile.mp4"


const NtVideo = () => {
    return (
        <>
        
<video  id="video" className="fx-lazy loaded" style={{"height":"550px"}} autoPlay loop muted>
      <source src={ntmobile} type="video/mp4"/>
  Your browser does not support the video tag.
</video>
  
<style jsx>
        {`
          @media all and (max-width: 600px) {
            #video {display: none;}
            
        }
        `}
      </style>
            
</>
    );
};

export default NtVideo;
