import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import { Link } from "react-router-dom";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgaccounts.jpg";

import IndividualAgreement from "../../assets/forms/forex-accounts/individual-account/noor-capital-client-agreement-en-ar.pdf";
import IndividualAgreementSample from "../../assets/forms/forex-accounts/individual-account/noor-capital-client-agreement-en-ar-sample.pdf";

import ApplicationForm from "../../assets/forms/forex-accounts/kyc.pdf";
import CorporateAgreement from "../../assets/forms/forex-accounts/corporate-account/noor-capital-corporate-agreement-english.pdf";
import IBAgreement from "../../assets/forms/forex-accounts/ib/Noor_Capital_IB_Agreement_English.pdf";
import IndividualIntroducingBrokerAgreement from "../../assets/forms/forex-accounts/ib/version-ib-ind.pdf";
import ScheduleA from "../../assets/forms/forex-accounts/ib/Noor_Capital_Schedule_A.pdf";
import InformationUpdate from "../../assets/forms/miscellaneous/noor-capital-info-update-english.pdf";
import WithdrawalRequest from "../../assets/forms/miscellaneous/funds-withdrawal-request-english.pdf";
import SubAccountRequest from "../../assets/forms/miscellaneous/additional-account.pdf";
import InterAccountTransferRequest from "../../assets/forms/miscellaneous/noor-capital-inter-account-transfer-request.pdf";
import isAgreement from "../../assets/forms/International-stock/International_Markets_Agreement_Version_Int_Stock.pdf";
import Appendix1 from "../../assets/forms/international-markets/appendix-1-version-int-stock.pdf";
import Appendix2 from "../../assets/forms/international-markets/appendix-2-version-int-stock.pdf";
import Appendix3 from "../../assets/forms/international-markets/appendix-3-version-int-stock.pdf";
import Appendix4 from "../../assets/forms/international-markets/appendix-4-version-int-stock.pdf";
import Appendix5 from "../../assets/forms/international-markets/appendix-5-version-int-stock.pdf";
import Appendix6 from "../../assets/forms/international-markets/appendix-5-version-mt5.pdf";
import IndAuthorisationLetter from "../../assets/forms/international-markets/individual-ksa-forms/ind-authorization-letter.pdf";
import IndCRS from "../../assets/forms/international-markets/individual-ksa-forms/Ind-crs-and-fatca-for-individual.pdf";
import IndRCCustomer from "../../assets/forms/international-markets/individual-ksa-forms/ind-rc-customer-information-form.pdf";
import IndSetupLocal from "../../assets/forms/international-markets/individual-ksa-forms/ind-setup-local-share-portfolio-application.pdf";
import InstituteAuthorizationLetter from "../../assets/forms/international-markets/institutions-ksa-forms/institute-authorization-letter.pdf";
import InstituteFATCASelfCertification from "../../assets/forms/international-markets/institutions-ksa-forms/institute-fatca-self-certification-form.pdf";
import InstituteRCCustomerInformation from "../../assets/forms/international-markets/institutions-ksa-forms/institute-rc-customer-information-form.pdf";
import InstituteSetupLocalSharePortfolioApplication from "../../assets/forms/international-markets/institutions-ksa-forms/institute-setup-local-share-portfolio-application.pdf";
// import phgold from "../../assets/forms/physical-gold/Version-Metal04-09.2020-14.09.2020.pdf";

// Profile
import ncprofile from "../../assets/files/nc-company-profile-m.pdf";


import { Tabs } from "antd";

const { Title } = Typography;

const style = { padding: "50px 0" };

const { TabPane } = Tabs;

const NCforms = () => (
  <Tabs defaultActiveKey="1" centered size="large">
    <TabPane tab="FX Trading" key="1">
      <section className="fdb-block">
        <div className="container">
          {/* 222222222 */}
          <div className="row text-left">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Individual Account</h3>
              </Divider>
            </div>
          </div>

          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndividualAgreement} target="_blank">
                      Individual Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          {/* 222222222 */}
          <div className="row text-left">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Corporate Account</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={CorporateAgreement} target="_blank">
                      Corporate Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
         


          {/* 222222222 */}
          {/* <div className="row text-left">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Joint Account</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={CorporateAgreement} target="_blank">
                      Joint Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div> */}

          <br />
          <br />
          {/* 444444444 */}
          <div className="row text-left">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Referral Account</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IBAgreement} target="_blank">
                    Referral Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndividualIntroducingBrokerAgreement} target="_blank">
                       Marketing Partnership Program
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={ScheduleA} target="_blank">
                      Schedule A
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          {/* 222222222 */}
          <div className="row text-left">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Miscellaneous</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InformationUpdate} target="_blank">
                      Information Update
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={WithdrawalRequest} target="_blank">
                      Withdrawal Request
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={SubAccountRequest} target="_blank">
                      Sub Account Request
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InterAccountTransferRequest} target="_blank">
                      Inter-Account Transfer Request
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* 4444444444 */}
        </div>
      </section>
    </TabPane>

    <TabPane tab="International Stocks" key="2">
      <section className="fdb-block">
        <div className="container">
          {/* 222222222 */}
          <div className="row text-left"></div>
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={isAgreement} target="_blank">
                      Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          {/* 444444444 */}
          <div className="row text-left">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Appendix</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-2">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix1} target="_blank">
                      Appendix 1
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix2} target="_blank">
                      Appendix 2
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix3} target="_blank">
                      Appendix 3
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix4} target="_blank">
                      Appendix 4
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix5} target="_blank">
                      GTN-Appendix 5
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          


          <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix6} target="_blank">
                    MT5-Appendix 6
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          {/* 444444444 */}
          <div className="row text-left">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Individual KSA Forms</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndAuthorisationLetter} target="_blank">
                      Ind Authorisation Letter
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndCRS} target="_blank">
                      Ind CRS & FATCA for Individuals
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndRCCustomer} target="_blank">
                      Ind RC Customer Information Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndSetupLocal} target="_blank">
                      Ind Setup Local Share Portfolio Application
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* 444444444 */}
          <div className="row text-left">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Institutions KSA Forms</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InstituteAuthorizationLetter} target="_blank">
                      Institute Authorization Letter
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InstituteFATCASelfCertification} target="_blank">
                      Institute FATCA Self Certification Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InstituteRCCustomerInformation} target="_blank">
                      Institute RC Customer Information Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InstituteSetupLocalSharePortfolioApplication} target="_blank">
                      {" "}
                      Institute Setup Local Share Portfolio Application
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabPane>


    {/* Physical */}

    {/* <TabPane tab="Physical Gold" key="3">
      <section className="fdb-block">
        <div className="container">
          

          <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={phgold} target="_blank">
                      Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabPane> */}

    {/* END Physical */}


  </Tabs>
);

const Accounts_AccountForms = () => {
  return (
    <div>
      <PageseMetaTags
        title="Trading Account Forms - Noor Capital"
        description="Download the Noor Capital Trading Account Form"
      />

      <BgBefore
        pageHeadingTitle="Trading Account Forms"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading"
        pageSubCategoryPath="#"
        pageName="Account Forms"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <p className="parjustify" style={{ fontSize: "16.5px" }}>
            Noor capital provides all required
            documents for individual and joint trading accounts, the client
            agreements for the establishment of an account, authorization to
            trade, margin and deposit requirements, pricing, and market pending
            orders and widened spreads.
          </p>
        </Row>



        <div className="row text-left mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndividualAgreementSample} target="_blank">
                      Individual Agreement - Sample
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            
          </div>


          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <p className="parjustify" style={{ fontSize: "16.5px" }}>
          <b>For any other required agreements and forms, kindly access the MyNoor Area or get in touch with the operations team at ops@noorcapital.ae or call +97142795400.
</b>
          </p>
        </Row>



        {/* 444444444444 */}
        <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Privacy Policy</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 555555555555 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Trading Conditions</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/trading/accounts/trading-conditions">
                    Trading Conditions
                  </Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 6666666666 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Risk Disclosure</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/trading-tools/risk-disclosure">
                    Risk Disclosure
                  </Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 7777777777 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Fraud Warning</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/fraud-warning">Fraud Warning</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 7777777777 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Cookies Policy</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/cookies-policy">Cookies Policy</Link>
                </b>
              </p>
            </div>
          </div>






        




{/* 
        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <NCforms />
          </Col>
        </Row> */}



      </div>
      <br />

      <br />

      <style jsx>
        {`
          .mt-5,
          .my-5 {
            margin-top: 2rem !important;
          }
          a {
            color: #9ea0a2;
            text-decoration: underline;
          }
        `}
      </style>
    </div>
  );
};

export default Accounts_AccountForms;
