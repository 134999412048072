import React from "react";
import { Row, Col, Table } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const columns = [
  {
    title: "المسمى الوظيفي",
    dataIndex: "jobtitle",
    key: "jobtitle",
  },
  {
    title: "نوع الوظيفة",
    dataIndex: "type",
    key: "type",
    responsive: ["md"],
  },
  {
    title: "الدور الوظيفي",
    dataIndex: "role",
    key: "role",
    responsive: ["md"],
  },
  {
    title: "فرع الشركة",
    dataIndex: "branch",
    key: "branch",
    responsive: ["md"],
  },
];

const dataSource = [
  {
    key: "1",
    jobtitle: "تنفيذي مبيعات",
    type: "دوام كامل",
    role: "مبيعات",
    branch: "الإمارات العربية المتحدة / دبي",
  },
];

const CareersAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Careers - Noor Capital"
        description="Noor Capital aim to provide a challenging and rewarding working environment."
      />
      <BgBeforeAr
        pageHeadingTitle="وظائف نور كابيتال"
        pageHeadingDescription="تهدف نور كابيتال إلى توفير بيئة عمل  مجزية ومليئة بالتحديات"
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="الوظائف"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div className="row">
            <div className="col text-right">
              <p className={globalstyle.parjustify}>
                إن النجاح الهائل لشركة نور كابيتال هو نتيجة لموظفينا المعتمدين
                وذوي الخبرة، فهم يعملون بجد وبلا كلل سعيًا من أجل التطوير الشامل
                للشركة. عندما تنضم إلى عائلتنا رفيعة المستوى، فإنك تغوص في عالم
                الاحتمالات والفرص التي تشكل مهاراتك القيادية وتساعدك في رحلة
                نموك المهني.
              </p>

              <p className={globalstyle.parjustify}>
                في نور كابيتال، نحن نبحث دائمًا عن الأفراد ذوي الدافع الذاتي و
                المتحمسين والمستعدين لتطوير حياتهم المهنية معنا. إذا كنت عاملًا
                شغوفًا وذكيًا وشخصًا حازمًا يقبل جميع التحديات بابتسامة، فنحن
                على يقين من أنك ستكون إضافة ممتازة للشركة.
              </p>
              <br />
              <h4 style={{ color: "#1b75bc", textAlign: "right" }}>
                فرص العمل الحالية :
              </h4>
              <br />
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={{ position: "none" }}
              />
              <p className="text-right">
                <a href="mailto:hr@noorcapital.ae" className="btn btn-primary">
                  أرسل سيرتك الذاتية
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CareersAr;
