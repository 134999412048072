import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgmt4.jpg";
import mt4mobile from "../../assets/images/pages/trading/mt4platform.png";
import WhyNoor from "./whyNoor";


const { Title } = Typography;

const Metatrader4_MetaTrader4Mobile = () => {
  return (
    <div>
      <PageseMetaTags
        title="MT4 Mobile Trading - Noor Capital"
        description="The MetaTrader 4 mobile applications allow trading on financial markets via iOS and Android powered smartphones and tablets."
      />

      <BgBefore
        pageHeadingTitle="Smart Trading App For Smart Traders"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="MetaTrader 4 mobile"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Download Noor Capital's MetaTrader 4 (MT4) Mobile App, And Start
            Trading On The Go
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            With the ever-emerging innovative technology in trading industry, demand for a smart trading solution is rising. Noor Capital's MetaTrader 4 (MT4) Mobile App provides you with a highly compatible trading app that helps you trade on the go from your smartphone.
            </p>
            <p className="parjustify">
            With Noor Capital's MetaTrader 4 (MT4) Mobile App, now you can enjoy instant and flexible access to global markets with fast execution. Get all the features of our platform in your palm to help you trade easily; whether you want to open, manage, edit, and close positions, you can do it all from anywhere and anytime you want.
            </p>

            <br />

            
            
            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-android"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>Android</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en"
                    target="_blank"
                  >
                    Download
                  </a>
                </p>
              </div>
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 ml-sm-auto mr-sm-auto mt-5 mt-md-0">
                <i
                  class="fab fa-apple"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>IPhone</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://apps.apple.com/us/app/metatrader-4/id496212596"
                    target="_blank"
                  >
                    Download
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                With Noor Capital's MT4 Mobile App, trading world is in your palm.
                </Title>
                
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Instant download and access to the global market
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Access interactive charts, technical indicators, and
                  analytical objects
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Monitor your account, track trades, buy/sell, and get the
                  latest industry update with a single click.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Facility to chat and interact with fellow traders
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get trading signals from top providers
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Access your account on any smart mobile from anywhere in the
                  world
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Trade on the go with Noor Capital's MetaTrader 4 (MT4) mobile
                  app
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Noor Capital's MetaTrader 4 (MT4) EAs (Expert Advisors)
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 dedicated customer service available to help you with any
                  concerns or advice
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Option to choose your preferred language (English/Arabic)
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4mobile} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="fdb-block">
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={3}>
                Advanced Benefits
              </Title>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-3">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
            <Title style={{ color: "#1b75bc" }} level={4}>
            Interactive Charts
              </Title>
              
              <p className="parjustify">
              Interactive charts track currency rate changes in real time. The application provides three types of charts: bars, candlesticks and line charts, which  allows you trade directly on them. You can easily scale and scroll charts, as well as switch between nine timeframes — from one minute to one month.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              
              <Title style={{ color: "#1b75bc" }} level={4}>
                  Convenience
                  </Title>
              
              <p className="parjustify">
              The MetaTrader 4 App for iOS provides outstanding features to enhance your trading activity. The One Tap Trading function allows you to trade on a chart with a single click. Open the quick trading panel and perform a deal in no time! The built-in chat allows you to communicate with any registered user of the MQL5 community — the largest community of traders.
              </p>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              
              <Title style={{ color: "#1b75bc" }} level={4}>
                  Orders and Trading Functions
                  </Title>
              
              <p className="parjustify">
              All information regarding the amount of orders and open positions, open prices, volumes and account status can be found in Trade window, while History window allows you to view the detailed history of all previously performed trades. To implement any strategy you need a flexible MetaTrader 4 mobile trading system paired with a full set of orders and trading functions. Instant execution of orders allow you  trade Forex continuously, while pending ones relieve you from the necessity to constantly manage your trading.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              
              <Title style={{ color: "#1b75bc" }} level={4}>
                  Trading Flexibility
                  </Title>
              
              <p className="parjustify">
              Instant execution of orders allows you to trade Forex at any time, while pending orders relieve you from the necessity to constantly check your device. Just place a pending order and switch to other things. The order will be executed even if the application is offline. Set Take Profit and Stop Loss levels, then you can maximize  your profit or minimize your losses. Comprehensive information such as track number, open prices, volumes, state of account of all orders and open positions, are always available in Terminal window. Detailed history of all trades is available from  History window.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                Your Journey As Smart Traders Begins Here
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Register Live Account
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoor />
    </div>
  );
};

export default Metatrader4_MetaTrader4Mobile;
