import React from "react";
import { Row, Col, Table } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = { padding: "50px 0", alignSelf: "justify" };

const columns = [
  {
    title: "Job Title",
    dataIndex: "jobtitle",
    key: "jobtitle",
  },
  {
    title: "Employment Type",
    dataIndex: "type",
    key: "type",
    responsive: ["md"],
  },
  {
    title: "Job Role",
    dataIndex: "role",
    key: "role",
    responsive: ["md"],
  },
  {
    title: "Branch",
    dataIndex: "branch",
    key: "branch",
    responsive: ["md"],
  },
];

const dataSource = [
  {
    key: "1",
    jobtitle: "Sales Executive",
    type: "Full Time",
    role: "Sales",
    branch: "UAE/Dubai",
  },
];

const Careers = () => {
  return (
    <div>
      <PageseMetaTags
        title="Careers - Noor Capital"
        description="Noor Capital aim to provide a challenging and rewarding working environment."
      />
      <BgBefore
        pageHeadingTitle="Careers at Noor Capital"
        pageHeadingDescription="Noor Capital Aim to Provide a Challenging and Rewarding Working Environment."
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Careers"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div className="row">
            <div className="col text-left">
              <p className={globalstyle.parjustify}>
                The success of Noor Capital is a result of our highly certified
                and experienced employees who work tirelessly for the overall
                development of the company.
              </p>
              <p className={globalstyle.parjustify}>
                At Noor Capital, we believe in providing our customers with the
                most reliable, advanced, and tailor-made financial solutions
                that reflect their goals and equip them with a secure future.
                Our employees reflect our customer-centric approach, presenting
                a suite of customer services around the clock
              </p>
              <p className={globalstyle.parjustify}>
                When you join our diverse family, you dive into the world of
                possibilities and opportunities that shape your leadership
                skills and help your professional growth.
              </p>

              <p className={globalstyle.parjustify}>
                At Noor Capital, we are always on the lookout for self
                motivated and eager individuals who are ready to elevate their
                careers with us. If you are a passionate, smart and
                determined person that accepts all challenges with a smile,
                we are sure you would be an excellent addition to the company.
              </p>
              <br />
              <h4 style={{ color: "#1b75bc" }}>Current job openings : </h4>
              <br />
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={{ position: "none" }}
              />
              <a href="mailto:hr@noorcapital.ae" className="btn btn-primary">
                Submit your CV
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Careers;
