import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtools.jpg";
import Tools_EconomicCalendarWidget from "./tradingTools_Tools_EconomicCalendarWidget.js";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  'Keep yourself updated with the latest trends, news, techniques, and strategies.',

'Take accurate decisions with updated information, trading plans, and weekly previews.',
'Get the latest information related to world politics that may affect the market.',
'Accessible from anywhere in the world.',

];
const { Title } = Typography;

const Tools_EconomicCalendar = () => {
  return( <div>
          <PageseMetaTags
                title="Economic Calendar - Noor Capital"
                description="Check our fast-updating economic calendar to get indicators in real-time as economic events are announced and see the immediate global market impact."
            />

            <BgBefore 
            pageHeadingTitle="Plan your investment journey safely with Noor Capital’s economic calendar"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName="Economic Calendar"
      />


      
<div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Economic Calendar
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify"><b>
            Keep yourself updated with our robust economic calendar
            </b></p>

            <p className="parjustify">
            To make it big in the financial market, you have to be at the highest level of skill with the right information to make a profitable decision.  Noor Capital's Economic Calendar provides you with transparent information that affects stocks, commodities, and currencies. 
            </p>

            <p className="parjustify">
            Stay ahead of the game with indicators and analysis to help you make the correct trading decision. We bring you the global economic news, government policies, and events that directly affect the market.
            </p>

            <p className="parjustify">
            With Noor Capital's Economic Calendar you will never miss any market opportunity.
            </p>
          </Col>

          
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          Features & Benefits
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>


        
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tools_EconomicCalendarWidget />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Contact us if you have Economic Calendar question
              </Title>
              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/about/contact-us"
                  
                >
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>


  </div>
  )

};

export default Tools_EconomicCalendar;