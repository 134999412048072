import React from "react";
import { Row, Col } from "antd";

import globalstyle from "../../style.module.css";

import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const Accounts_TradingConditionsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Forex Trading Conditions - Noor Capital"
        description="Learn about Noor Capital forex trading conditions by reviewing our Spreads, Swap Rates, available Currency pairs and Metals."
      />

      <BgBeforeAr
        pageHeadingTitle="ابدأ رحلة التداول الخاصة بك مع فهم شروط وأحكام التداول"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="التداول"
        pageSubCategoryPath="#"
        pageName="شروط وأحكام التداول"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-right">
              <p className={globalstyle.parjustify}>
                قبل اتخاذ قرار الاستثمار والتداول في أسواق المال من خلال منتجات
                نور كابيتال، يرجى قراءة الشروط والأحكام بعناية.
                <br />
                بما إن الفوركس وعقود الفرقات على العملات أو السلع أو المؤشرات أو
                الأسهم هي منتجات يتم تداولها بنظام الهامش وتحتوي على مخاطرة
                عالية قد تؤدي إلى فقدان رأس المال ، ذلك يرجى قراءة تحذير المخاطر
                الكامل قبل فتح حساب مع نور كابيتال. من المهم أيضًا أن يكون لديك
                فهم واضح لأهدافك من عملية التداول وضعك المالي واحتياجاتك ومستوى
                خبراتك في الأسواق.
                <br />
              </p>
            </div>
          </div>

          {/* 22222222222222222 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>بيانات السوق</h4>
              <p className={globalstyle.parjustify}>
                يمكنك الحصول على معلومات حول ساعات العمل أو أوقات التداول
                والإغلاق عبر ملفات بيانات الأسواق"M.I.S" المتاحة من خلالنا عبر
                الإنترنت. ويرجى العلم أنه لا يمكن لجميع التداولات أن تظل متاحة
                أو مغلقة طوال 24 ساعة بشكل يومي. حيث أن الغالبية تكون محكومة
                بساعات عمل وأوقات محددة للإغلاق، ويمكن أن تتباين باختلاف السوق.
                وتتحدد أوامر التداول بناء على الأسعار التي تقدمها شركة نور
                كابيتال وليس البورصة. وباختلاف الأسواق، فإن أسعارنا عادة ما يتم
                تحديدها بناء على سعر البورصة ولكن يمكن أن تتباين بعيدًا عن
                الأتعاب الأساسية كنتيجة لأسباب متعددة.
              </p>
            </div>
          </div>

          {/* 33333333333333 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>أنظمة التداول عبر الإنترنت</h4>
              <p className={globalstyle.parjustify}>
                تكون نظم التداول عبر الإنترنت عرضة لعدة مخاطر تتضمن من بينها،
                ولكنها ليست مقتصرة على، أعطال البرمجيات أو الحواسب أو انقطاع
                الاتصال بالإنترنت. ولا تتحكم شركة نور كابيتال في أي من مقدار قوة
                الإشارة أو الاستقبال أو التوجيه عبر الإنترنت، أو تعريفات ما
                لديكم من معدات، أو اعتمادية الاتصال، كما أنها لا تتحمل مسؤولية
                التشويش، أو فشل الاتصالات، أو التأخير في حالة التداول عبر
                الإنترنت.
              </p>
            </div>
          </div>

          {/* 4444444444444 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>التداول بالهامش</h4>
              <p className={globalstyle.parjustify}>
                بناء على فوارق الأسعار، يمكنك التداول بالهامش من تنفيذ عمليات
                تداول كبيرة بمقدار محدود من الأموال بدفع هامش من إجمالي القيمة.
                وفي حال ارتفاع الأسعار في صالحك، يمكنك تحقيق زيادة كبيرة في
                الأرباح. ولكن يمكن لتحركات سلبية في الأسعار، حتى وإن كانت
                محدودة، أن تؤدي إلى خسائر كبيرة، وقد تكون مضطرًا إلى إيداع مبالغ
                إضافية بشكل آني لكي تحافظ على إتاحة التداول. وتتحمل أنت
                المسؤولية بنسبة 100% عن أية خسائر يمكن أن تحدث بمجرد إغلاق
                الصفقات.
              </p>
            </div>
          </div>

          {/* 55555555555555 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>جداول التداول</h4>
              <p className={globalstyle.parjustify}>
                <ol>
                  <li>
                    1- توفر لك شركة نور كابيتال خدمات التنفيذ 24 ساعة يوميًا/5
                    أيام أسبوعيًا من الأثنين إلى الجمعة.
                  </li>
                  <li>
                    2- بعض أدوات التداول معفاة من هذه الخدمات التي تتوقف عدة
                    مرات.
                  </li>
                  <li>
                    3- ابحث أدناه عن الصورة / الدليل الذي يحتوي على معلومات
                    دقيقة حول كل أداة يتم تداولها.
                  </li>
                  <li>
                    4- لنور كابيتال كامل الحقوق في إلاغلاق التداول مبكرًا أو
                    تعديل أو تأخير افتتاح الجلسة. هذا يضمن ويوفر لك أفضل شروط
                    التنفيذ والتداول، مما يحمي استثمار عملائنا في النهاية من
                    حركة السوق.
                  </li>
                  <li>
                    5- يتم الإعلان عن أي تغييرات تطرأ على ساعات التداول العادية
                    لكل أداة من خلال عنوان البريد الإلكتروني المسجل.
                  </li>
                </ol>
              </p>
            </div>
          </div>

          {/* 888888888888 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>المسؤولية المحدودة</h4>
              <p className={globalstyle.parjustify}>
                لا تتحمل شركة نور كابيتال أي مسؤولية عن أي مطالبات أو خسائر أو
                تكاليف، بما في ذلك أتعاب المحاماة، أو أي أضرار من أي نوع أو
                طبيعة، بما يتضمن بشكل شامل وبلا أي استثناء، الأضرار العامة
                والخاصة والطارئة والتبعية والجزائية والتأديبية والمتعددة، بناء
                على أي من نظريات المسؤولية أو تقييم الالتزامات، فيما يخص أي
                استخدام لموقع شركة نور كابيتال وما يحتويه من معلومات، حتى في
                حالة ما إذا تم تقديم مشورات لشركة نور كابيتال حول إمكانية حدوث
                هذا النوع من الأضرار، أو عيوب أو تأخير في تنفيذ العمليات أو
                الإرسال، أو فيروسات الحاسب الآلي أو فشل أو أعطال الأنظمة، حال
                نشوء أي خسارة أو ضرر أو تكاليف أو تعويض.
              </p>
              <p className={globalstyle.parjustify}>
                كما أن استخدام روابط لمواقع إلكترونية أخرى يتم الوصول إليها عبر
                موقع شركة نور كابيتال يكون على بناء اختيار ومسؤولية المستخدم.
                ومن المفهوم والمتفق عليه أن شركة نور كابيتال لم تفحص أو تتحقق أو
                تتحكم في تبني المحتوى ولا يمكن أن تكون مسؤولة عن دقة البيانات
                والآراء التي يتم تقديمها أو التعبير عنها من خلال تلك المصادر.
              </p>
            </div>
          </div>

          {/* 77777777777777 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>الحسابات الخالية من الفوائد:</h4>
              <p className={globalstyle.parjustify}>
                لا تفرض شركة نور كابيتال مقايضات أو فوائد على جميع حسابات
                التداول. لا توجد رسوم مطبقة على جميع التداولات المفتوحة. ومع ذلك
                ، يجب تطبيق رسوم الإدارة على المراكز المفتوحة طوال الليل
                (المبيته) ، وعلى جميع مراكز العقود مقابل الفروقات الفورية
                المفتوحة ، في نهاية كل يوم عمل ، يوميًا ، طوال فترة المعاملة.
                جدول رسوم التخزين هو كما يلي:
              </p>
            </div>
          </div>

          {/* 9999999999999 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>جدول رسوم التخزين :</h4>
              <p className={globalstyle.parjustify}>
                <ol>
                  <li>
                    <b>1 - أزواج العملات (CFD) النوع الاول :</b>
                    <br />
                    الدولار الأسترالي مقابل الين الياباني (AUDJPY)، اليورو مقابل
                    الدولار الكندي (EURCAD)، اليورو مقابل الفورنت المجري
                    (EURHUF)، الدولار النيوزلاندي مقابل الدولار الكندي (NZDCAD)،
                    الدولار النيوزلاندي مقابل الدولار الأمريكي (NZDUSD)، الدولار
                    الأمريكي مقابل الفرنك السويسري (USDCHF)، الدولار الأمريكي
                    مقابل الكرونة الدينماركية (USDDKK)، الدولار الأمريكي مقابل
                    الكرونة السويدية (USDSEK) الدولار الأسترالي مقابل الدولار
                    الكندي (AUDCAD)، الدولار الأسترالي مقابل الدولار الكندي
                    (AUDUSD)، الدولار الكندي مقابل الفرنك السويسري (CADCHF)،
                    اليورو مقابل الفرنك السويسري (EURCHF)، اليورو مقابل الجنيه
                    الاسترليني (EURGBP)، اليورو مقابل الدولار الأمريكي (EURUSD)،
                    الجنيه الاسترليني مقابل الدولار الكندي (GBPCAD)، الجنيه
                    الاسترليني مقابل الين الياباني (GBPJPY)، الجنيه الاسترليني
                    مقابل الدولار السنغافوري (GBPSGD)، الدولار الأمريكي مقابل
                    الكرونة التشيكية (USDCZK)، الفرنك السويسري مقابل الين
                    الياباني (CHFJPY)، اليورو مقابل الكرونة التشيكية (EURCZK)،
                    اليورو مقابل الكرونة الدينماركية (EURDKK)، اليورو مقابل
                    الكرونة السويدية (EURSEK)، الجنيه الاسترليني مقابل الدولار
                    الأمريكي (GBPUSD), الدولار الأمريكي مقابل الين الياباني
                    (USDJPY)، الدولار الأمريكي مقابل الكرونة النرويجية (USDNOK),
                    الدولار الأمريكي مقابل الزلوتي البولندي (USDPLN)، الدولار
                    الأمريكي مقابل الدولار السنغافوري (USDSGD)، الدولار
                    الأسترالي مقابل الدولار النيوزلندي (AUDNZD)، الدولار الكندي
                    مقابل الين الياباني (CADJPY)، اليورو مقابل الين الياباني
                    (EURJPY)، الدولار الأمريكي مقابل الفورنت المجري (USDHUF)،
                    الدولار الأمريكي مقابل الدولار الكندي (USDCAD)، XAGUSD
                    <b>
                      <br />- رسوم التخزين : 4.5$ عن كل يوم لكل عقد قياسي.
                    </b>
                  </li>
                  <li>
                    <br />
                    <b>2 - أزواج العملات (CFD) النوع الثاني :</b>
                    <br />
                    الدولار الأمريكي مقابل البيزو الميكسيكي (USDMXN)، اليورو
                    مقابل الدولار النيوزلندي (EURNZD)، الجنيه الاسترليني مقابل
                    الدولار النيوزلندي (GBPNZD)، اليورو مقابل الدولار الأسترالي
                    (EURAUD)، الجنيه الاسترليني مقابل الدولار الأسترالي
                    (GBPAUD)، الجنيه الاسترليني مقابل الفرنك السويسري (GBPCHF)،
                    الدولار النيوزلندي مقابل الفرنك السويسري (NZDCHF)، الدولار
                    الأسترالي مقابل الفرنك السويسري (AUDCHF)، اليورو مقابل
                    الكرونة النرويجية (EURNOK)، اليورو مقابل الدولار السنغافوري
                    (EURSGD)، الدولار النيوزلندي مقابل الين الياباني (NZDJPY)،
                   ، سعر
                    الذهب مقابل الدولار الأمريكي (XAUUSD).
                    <b>
                      <br />- رسوم التخزين : 30$ عن كل يوم لكل عقد قياسي
                    </b>
                  </li>
                  <li>
                    <br />
                    <b>3: أزواج العملات (CFD) النوع الثالث :</b>
                    <br />
                    الدولار الأمريكي مقابل الليرة التركية (USDTRY)، الدولار
                    الأمريكي مقابل الراند الجنوب أفريقي (USDZAR)، اليورو مقابل
                    البيزو الميكسيكي (EURMXN).
                    <br />
                    <b>- رسوم التخزين : 24$ عن كل يوم لكل عقد قياسي</b>
                  </li>

                  <li>
                    <br />
                    <b>
                      4 – المؤشرات الفورية : لايوجد فترة سماح، حيث تطبق الرسوم
                      يومياً :
                    </b>
                    <br />
                    <br />
                    <table className="table table-striped table-bordered table-hover table-sm text-left">
                      <tbody>
                        <tr>
                          <td>USD-5 /DAY</td>
                          <td>DE30Rollx</td>
                        </tr>
                        <tr>
                          <td>USD-5 /DAY</td>
                          <td>US30Rollx </td>
                        </tr>
                        <tr>
                          <td>USD-5 /DAY</td>
                          <td>US100Rollx</td>
                        </tr>
                        <tr>
                          <td>USD-5 /DAY</td>
                          <td>UK100Rollx</td>
                        </tr>
                        <tr>
                          <td>USD-5 /DAY</td>
                          <td>Chin50Rollx</td>
                        </tr>
                        <tr>
                          <td>USD-5 /DAY</td>
                          <td>HKD33Rollx</td>
                        </tr>
                        <tr>
                          <td>USD-10 /DAY</td>
                          <td>XCUUSDRollx</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li>
                    <b>
                      5 – مؤشرات الطاقة الفورية : لايوجد فترة سماح، حيث تطبق
                      الرسوم يومياً :
                    </b>
                    <br />
                    <br />
                    <table className="table table-striped table-bordered table-hover table-sm text-left">
                      <tbody>
                        <tr>
                          <td> USD-30/DAY</td>
                          <td>!USOUSD</td>
                        </tr>
                        <tr>
                          <td> USD-55/DAY</td>
                          <td>!UKOUSD</td>
                        </tr>
                        <tr>
                          <td> USD-25/DAY</td>
                          <td>!XNGUSD</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li>
                    <b>
                      6 – العملات الافتراضية : لايوجد فترة سماح، حيث تطبق الرسوم
                      يومياً :
                    </b>
                    <br />
                    <br />
                    <table className="table table-striped table-bordered table-hover table-sm text-left">
                      <tbody>
                        <tr>
                          <th
                            rowspan="2"
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            العملة
                          </th>

                          <th
                            colspan="2"
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            رسوم التخزين (USD/Lot/Day)
                          </th>
                        </tr>

                        <tr>
                          <th
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Buy / Long
                          </th>

                          <th
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Sell / Short
                          </th>
                        </tr>

                        <tr>
                          <td style={{textAlign: "center"}}>BTCUSD ( 10 units contract )</td>
                          <td>USD450/Lot/Day</td>
                          <td>USD100/Lot/Day</td>
                        </tr>
                        <tr>
                          <td>BTCUSD ( 1 unit contract )</td>
                          <td>USD45/Lot/Day</td>
                          <td>USD10/Lot/Day</td>
                        </tr>
                        <tr>
                          <td>ETHUSD</td>
                          <td>USD210/Lot/Day</td>
                          <td>USD80/Lot/Day</td>
                        </tr>
                        <tr>
                          <td>LTCUSD</td>
                          <td>USD25/Lot/Day</td>
                          <td>USD10/Lot/Day</td>
                        </tr>
                        <tr>
                          <td>XRPUSD</td>
                          <td>USD10/Lot/Day</td>
                          <td>USD4/Lot/Day</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li>
                    <b>ملاحظات : </b>
                    <br />

                    <p className={globalstyle.parjustify}>
                      * الرسوم المذكورة أعلاه قابلة للتغيير دون أي إشعار مسبق
                      <br />
                      * تحتفظ نور كابيتال بالحق التقديري لإعادة الحسابات الخالية
                      من الفوائد إلى حسابات التداول العادية
                      <br />
                      * سيتم فرض رسوم التخزين بقيمة 9.5 دولارًا أمريكيًا لكل قطعة
                      / يوم على الأدوات غير المدرجة إذا لم يتم ذكرها في القائمة.
                      <br />* العقد قياسي للفوركس هو 100K
                    </p>
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </section>
        <style jsx>{`
        td {
         
          text-align: center;
        }
        `}</style>
      </div>
    </div>
  );
};

export default Accounts_TradingConditionsAr;
