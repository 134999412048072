import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgpartnershipbanner4.jpg";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import WhyNoor from "./whyNoor.js";

const style = { padding: "50px 0" };

const notes = [
  "A unique reward model for every customer introduced to our product.",
  "Noor Capital will provide you with 100% support to commence and expand your business.",
  "Access to our robust investment solutions.",
  "Quick and easy to join.",
  "Commission earned will be directly transferred to your Noor Capital Referral account.",
  "A dedicated manager to help you with any information related to our products.",
];
const { Title } = Typography;

const IBProgram_Ib = () => {
  return (
    <div>
      <PageseMetaTags
        title="Marketing Partnership Program - Forex Trading | Noor Capital"
        description="Noor Capital Marketing Partnership Program is designed to remunerate introducers with no limits on the number of referred accounts or trading volumes"
      />

      <BgBefore
        pageHeadingTitle="Expand Endless Opportunities - Join Noor Capital's Marketing Partnership Program"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Partnership"
        pageSubCategoryPath="#"
        pageName="Marketing Partnership Program"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Marketing Partnership Program
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              Noor Capital believes in boosting profit for 
              businesses who associate with us. With this aim in mind, we
              encourage you to join our Marketing Partnership Program, wherein you
              get a reward for referring new customers to our forex trading
              platform.
            </p>

            <p className="parjustify">
              With the Marketing Partnership Program, you will receive 100% support
              from us to commence and expand your business, and gain access to
              our award-winning products and investment solutions to offer your
              customers.
            </p>

            <p className="parjustify">
              Noor Capital's Marketing Partnership Program is the most transparent
              and straightforward process with no upfront investment
              requirement. Hundreds of partners are taking advantage of this
              lucrative program, expanding their profit-making possibilities.
            </p>
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Features & Benefits
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                Apply For The Referral Program Now
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/ib-signup"
                  target="_blank"
                >
                  Register
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoor />
    </div>
  );
};

export default IBProgram_Ib;
