import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtradingbanner3.jpg";
import cashequities from "../../assets/images/pages/instruments/cash-equities.jpg";
import Products from "./products.js";

const { Title } = Typography;

const Instruments_CashEquities = () => {
  return (
    <div>
      <PageseMetaTags
        title="Cash Equities Trading - Noor Capital"
        description="Cash equities refer to the portion of an investment that can be compensated with cash. In terms of investing, it represents common stocks issued to investors or institutions trading that stock. Noor Capital's goal is to equip you with various financial instruments and provide updated information so that when you trade equities with us you find a productive and straightforward trading environment."
      />
      <BgBefore
        pageHeadingTitle="Discover Thousands of Stocks from Global Market"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Trading"
        pageMainCategoryPath="#"
        pageSubCategoryName="Instruments"
        pageSubCategoryPath="#"
        pageName="Cash Equities"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Cash Equities
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Cash equities refer to the portion of an investment that can be compensated with cash. In terms of investing, it represents common stocks issued to investors or institutions trading that stock. Noor Capital's goal is to equip you with various financial instruments and provide updated information so that when you trade equities with us you find a productive and straightforward trading environment.
            </p>

            <p className="parjustify">
            Equities represent partial ownership in the issuing company. The shares can be bought for long-duration holdings as a part of long-term investment tools, or if you have your eye on quick profits, they can be traded for shorter terms. As a trader, you can earn great profits due to market fluctuations. 
            </p>


            <p className="parjustify">
            If you are an equity player looking for the right platform to launch safe and secure trades, Noor Capital can serve you well. We offer a variety of Equity shares. Our highly competitive trading platform Metatrader can be accessed through a smartphone, desktop anywhere at any time.
            </p>

           

            
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={cashequities}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Cash Equities Trading"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
                Trading Cash Equities is Now Easier Than Ever
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Sign-Up
                  </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Products />
    </div>
  );
};

export default Instruments_CashEquities;
