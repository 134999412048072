import React from "react";
import { Row, Col, Typography, Divider, Table } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgaccounts.jpg";

const style = { padding: "50px 0" };
const { Title } = Typography;

const columnsTable1 = [
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Account Number",
    dataIndex: "accountnumber",
    key: "accountnumber",
  },
  {
    title: "IBAN",
    dataIndex: "iban",
    key: "iban",
  },
  {
    title: "SWIFT",
    dataIndex: "swift",
    key: "swift",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Intermediary Bank",
    dataIndex: "intermediarybank",
    key: "intermediarybank",
  },
  {
    title: "Intermediary SWIFT",
    dataIndex: "intermediaryswift",
    key: "intermediaryswift",
  },
];

const columnsTable2 = [
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Account Number",
    dataIndex: "accountnumber",
    key: "accountnumber",
  },
  {
    title: "IBAN",
    dataIndex: "iban",
    key: "iban",
  },
  {
    title: "SWIFT",
    dataIndex: "swift",
    key: "swift",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Intermediary/ Correspondent Bank",
    dataIndex: "intermediarybank",
    key: "intermediarybank",
  },

  {
    title: "Intermediary SWIFT",
    dataIndex: "intermediaryswift",
    key: "intermediaryswift",
  },
];

const dataSourceTable1 = [
  {
    key: "1",
    currency: "AED",
    accountnumber: "1002285961",
    iban: "AE810230000001002285961",
    swift: "CBDUAEADXXX",
    address: "Al Ittihad Street, Dubai, UAE",
    intermediarybank: "",
    intermediaryswift: "",
  },
  {
    key: "2",
    currency: "BHD",
    accountnumber: "1006654147",
    iban: "AE980230000001006654147",
    swift: "CBDUAEAD",
    address: "70 - CBD Al Islami - Main Branch",
    intermediarybank: "Ahli United Bank B.S.C, Bahrain",
    intermediaryswift: "AUBBBHBMXXX",
  },
  {
    key: "3",
    currency: "USD",
    accountnumber: "1008371450",
    iban: "AE810230000001008371450",
    swift: "CBDUAEADXXX",
    address: "70 - CBD Al Islami - Main Branch",
    intermediarybank: "J.P. Morgan",
    intermediaryswift: "",
  },
  {
    key: "4",
    currency: "GBP",
    accountnumber: "1001971314",
    iban: "AE960230000001001971314",
    swift: "CBDUAEADXXX",
    address: "70 - CBD Al Islami - Main Branch",
    intermediarybank: "",
    intermediaryswift: "",
  },
  {
    key: "5",
    currency: "EUR",
    accountnumber: "1001971587",
    iban: "AE970230000001001971587",
    swift: "CBDUAEADXXX",
    address: "70 - CBD Al Islami - Main Branch",
    intermediarybank: "",
    intermediaryswift: "",
  }
];

const dataSourceTable2 = [
  {
    key: "1",
    currency: "AED",
    accountnumber: "058520176422001",
    iban: "AE090240058520176422001",
    swift: "DUIBAEAD",
    address: "Main Branch Dubai, UAE",
    intermediarybank: "",
    intermediaryswift: "",
  },
  {
    key: "2",
    currency: "USD",
    accountnumber: "058521176422001",
    iban: "AE610240058521176422001",
    swift: "DUIBAEAD",
    address: "Main Branch Dubai, UAE",
    intermediarybank: "JP Morgan",
    intermediaryswift: "CHASUS33XXX",
  },
  {
    key: "3",
    currency: "GBP",
    accountnumber: "058521176422002",
    iban: "AE340240058521176422002",
    swift: "DUIBAEAD",
    address: "Main Branch Dubai, UAE",
    intermediarybank: "CREDIT SUISSE AG",
    intermediaryswift: "CRESCHZFARS",
  },
  {
    key: "4",
    currency: "EUR",
    accountnumber: "058521176422003",
    iban: "AE070240058521176422003",
    swift: "DUIBAEAD",
    address: "Main Branch Dubai, UAE",
    intermediarybank: "BARCLAYS BANK",
    intermediaryswift: "BARCGB22XXX",
  },
  {
    key: "5",
    currency: "",
    accountnumber: "",
    iban: "",
    swift: "",
    address: "",
    intermediarybank: "DEUTSCHE BANK FRANKFURT",
    intermediaryswift: "DEUTDEFFXXX",
  },
  {
    key: "6",
    currency: "KWD",
    accountnumber: "058521176422008",
    iban: "AE660240058521176422008",
    swift: "DUIBAEADXXX",
    address: "Dubai International City Branch",
    intermediarybank: "",
    intermediaryswift: "",
  },
  {
    key: "7",
    currency: "SAR",
    accountnumber: "058521176422009",
    iban: "AE390240058521176422009",
    swift: "DUIBAEADXXX",
    address: "Dubai International City Branch",
    intermediarybank: "",
    intermediaryswift: "",
  }
];

const Accounts_AccountFunding = () => {
  return (
    <div>
      <PageseMetaTags
        title="Fund Your Account - Deposit - Noor Capital"
        description="Noor Capital offers multiple flexible funding options in 10 different base currencies."
      />
      <BgBefore
        pageHeadingTitle="Grow Your Investment Exponentially"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading"
        pageSubCategoryPath="#"
        pageName="Account Funding"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Fund Your Account
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            You can send funds to your Noor Account via Bank Transfer to our Bank Accounts. Please check Noor Capital Bank account information by logging in to your 
            {" "}
              <a
                href="https://mynoor.noorcap.ae/en/traders/login"
                target="_blank"
              >
                myNoor Private Cabinet{" "}
              </a>{" "}
            
              for bank details.  
            </p>

            <p className="parjustify">
            The transaction itself cannot be done via the Traders Area. You can use your banking facilities to transfer funds. National and International Bank Transfers take between 1-3 business days to be confirmed, therefore your trading account will be credited after funds have cleared at our bank. 
            </p>

            <p className="parjustify">
            Please quote your Noor Capital TAN [Trading Account Number] as a reference, when making payment to us.
            </p>

          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Noor Capital Bank Accounts
          </Title>
        </Divider>

        {/* <section className="fdb-block" style={{"padding":"30px 0"}}>
          <div className="container">
            <div className="row text-left">
              <div className="col-12 col-md-8 m-auto col-lg-6">
                <div className="fdb-box fdb-touch">
                  <Title level={4}>CBD - COMMERCIAL BANK OF DUBAI</Title>

                  <p>
                    <a href="mailto:ops@noorcapital.ae">
                      Request Bank Account Details
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 m-auto col-lg-6 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <Title level={4}>DIB - DUBAI ISLAMIC BANK</Title>

                  <p>
                    <a href="mailto:ops@noorcapital.ae">
                      Request Bank Account Details
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Title level={4} style={{ textAlign: "center" }}>
              CBD - COMMERCIAL BANK OF DUBAI
            </Title>
            <Title level={5} style={{ color: "#615d5d", textAlign: "center" }}>
              ACCOUNT NAME : NOOR CAPITAL PSC
            </Title>
            <br />
            <Table dataSource={dataSourceTable1} columns={columnsTable1} />
          </Col>
        </Row>

        

        <Row>
          
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>

            {/* <Title level={4} style={{ textAlign: "center" }}>
              DIB - DUBAI ISLAMIC BANK
            </Title>
            <Title level={5} style={{ color: "#615d5d", textAlign: "center" }}>
              ACCOUNT NAME : NOOR CAPITAL PSC
            </Title>
            <br />
            <Table dataSource={dataSourceTable2} columns={columnsTable2} /> */}

            
          <Title level={5} >
          Deposit:
          </Title>
        

            <div class="alert alert-warning" role="alert">
            No Cash Deposits via ATM machines will be accepted
            </div>
            <div class="alert alert-warning" role="alert">
            Card payments (credit/debit) conversion rate (AED to USD) is 3.80
            </div>
            <div class="alert alert-warning" role="alert">
            Bank to Bank wire transfer conversion rate (AED to USD) rate is 3.675
            </div>

            <Title level={5} >
          Withdrawals:
          </Title>

            <div class="alert alert-warning" role="alert">
            Bank to Bank wire transfer conversion rate (USD to AED) rate is 3.670
            </div>
            




            <br />
          </Col>
        </Row>





        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Credit / Debit Card
          </Title>
        </Divider>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{
            textAlign: "justify",
            padding: "0px 30px 0px 0px",
            fontSize: "16.5px",
          }}
        >
          <p>
          The quickest and easiest way to deposit funds with Noor Capital is to use a Credit Card, since successfully authorized payments will be credited to your account instantly. To fund your Noor Capital account using a credit card, you need to  login to the secure myNoor Private Cabinet, and select the Credit Card option. You may only use a card in your own name and from a personal account, not a business card or corporate account. Third party payments are prohibited, which also means no family members or friends are to send funds on your behalf.
          </p>
        </Col>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Deposit
          </Title>
        </Divider>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{
            textAlign: "justify",
            padding: "0px 30px 0px 0px",
            fontSize: "16.5px",
          }}
        >
          <p>
          The quickest and easiest way to deposit funds with Noor Capital is to use a credit card, since successfully authorized payments will be credited to your account instantly. To fund your Noor Capital account using a credit card  you need to login to the secure myNoor Private Cabinet, and select the credit card option. You may only use a card in your own name and from a personal account, not a business card or corporate account. Third party payments are prohibited, which also means no family members or friends are to send funds on your behalf.
          </p>
          <p>
            {" "}
            <b>
              To withdraw funds please login to your{" "}
              <a
                href="https://mynoor.noorcap.ae/en/traders/login"
                target="_blank"
              >
                MyNoor Traders Area{" "}
              </a>{" "}
              portal and follow the instructions :{" "}
            </b>
            <ul>
              <li>
                - Process Credit Card withdrawals directly through the portal.
              </li>
              <li>
                - Submit wire request through the portal, process will be done
                via our bank account.
              </li>
            </ul>
          </p>
        </Col>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>
    
    
    </div>
  );
};

export default Accounts_AccountFunding;
