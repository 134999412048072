import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs.js";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import mission from "../../assets/images/pages/mission.svg";
import vision from "../../assets/images/pages/vision.svg";
import objectives from "../../assets/images/pages/objectives.svg";
import security from "../../assets/images/pages/security.svg";
import innovation from "../../assets/images/pages/innovation.svg";
import support from "../../assets/images/pages/support.svg";
import relationships from "../../assets/images/pages/relationship.svg";
import trust from "../../assets/images/pages/trust.svg";
import teamwork from "../../assets/images/pages/teamwork.svg";
import social  from "../../assets/images/pages/social.svg";


const AboutUs = () => {
  return (
    <div>
      <PageseMetaTags
        title="About Noor Capital - Leading Financial Broker in Dubai"
        description="About Noor Capital - Leading Financial Broker in Dubai"
      />

      <BgBefore
        pageHeadingTitle="Financial Freedom is a reality here with Noor Capital"
        pageHeadingDescription="Cutting-edge diversified financial solutions are yours."
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="About Us"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div className="row">
            <div className="col text-left">
              <h2 style={{ color: "#1b75bc" }}>
                Comprehensive Solutions for your Investments
              </h2>
              <p className={globalstyle.parjustify}>
                Noor Capital P.S.C. is one of the leading investment brokerage
                firms known across the globe for its high standards in terms of
                service quality and customer satisfaction. Our state-of-art
                highly efficient trading platforms provide wonderful trading
                experience for our individual and corporate clients.
              </p>
              <p className={globalstyle.parjustify}>
                Established in 2005 and regulated by the Securities and Commodities Authority (SCA), Noor Capital P.S.C. offers you a diverse range of
                premium financial services and investment plans. All our
                financial services are catered to meet your specific trading and
                investment needs.
              </p>
              <p className={globalstyle.parjustify}>
                Noor Capital P.S.C. is registered as a private joint-stock
                company in Abu Dhabi Department of Economic Development. Being
                regulated by the Securities and Commodities Authority (SCA) makes us an your best choice ever for
                all your financial and investment endeavors.
              </p>
              <p className={globalstyle.parjustify}>
                At Noor Capital P.S.C., we strive to provide our customers with
                the most reliable, advanced, financial solutions especially
                tailored to our clients and reflect their goals, equip them by
                the amount of financial security needed to better future. Our
                customer-oriented approach makes your satisfaction our top
                priority. You can count on us to provide you with the best
                financial and investment management services.
              </p>
            </div>
          </div>

          
          <div className="row text-center justify-content-center mt-5">
            <div className="col-12 col-sm-4 col-xl-3 m-md-auto">
              <img
                alt="vision"
                className="fdb-icon"
                src={vision}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Vision</strong>
              </h3>
              <p className="parjustify">
                Our vision is to be the most trusted and leading investment firm
                in the GCC by 2025 and to be the most preferred one in the
                Middle East by 2030.
              </p>
            </div>

            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="mission"
                className="fdb-icon"
                src={mission}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Mission</strong>
              </h3>
              <p className="parjustify">
                Our mission is to create wealth for our clients and shareholders
                by providing creative investment solutions.
                <br />
              </p>
            </div>

            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="objectives"
                className="fdb-icon"
                src={objectives}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Objectives</strong>
              </h3>
              <p className="parjustify">
                To be distinguished by creative products and high-quality
                services. To grow the business and the true value of the company
                by at least 35% within the next 5 years. To create value for
                current shareholders by sustaining a significant gain in company
                value.
              </p>
            </div>
          </div>
       

        </section>
      </div>
      {/* 
      <section
        className="fdb-block bg-dark"
        style={{ backgroundImage: `url(${aboutfiguresbg})` }}
      >
        <div className="row">
          <div className="col text-center">
            <h1 style={{ color: "#1b75bc" }}>Noor Capital Figures</h1>
          </div>
        </div>

        <div className={globalstyle.container}>
          <div className="row text-center justify-content-sm-center no-gutters">
            <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto">
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}> 2005</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Establishment Year
                </p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Padding: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}> Capital</p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Padding: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Total Foreign Investment
                </p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Padding: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Trading Platforms
                </p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Padding: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  International Awards
                </p>
              </div>
            </div>
            <div
              className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-2 m-auto pt-5 pt-xl-0"
              style={{ Margin: "10px" }}
            >
              <div className="fdb-box fdb-touch" style={{ margin: "20px" }}>
                <h2 style={{ color: "#1b75bc" }}>123</h2>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Renowned Brokers{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

 */}


      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>
          <div className="row text-center">
            <div className="col-12">
              <h2 style={{ color: "#1b75bc" }}>Core Values</h2>
            </div>
          </div>

          <div className="row text-center justify-content-center mt-5">
            <div className="col-12 col-sm-4 col-xl-3 m-md-auto">
              <img
                alt="mission"
                className="fdb-icon"
                src={relationships}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Relationships as Foundation</strong>
              </h3>
              <p className="parjustify">
                We will constantly strive to identify opportunities for new
                relationships and strengthen existing relationships with all our
                stakeholders. Relationships would form the bedrock of all our
                business activities.
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="vision"
                className="fdb-icon"
                src={innovation}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Innovation</strong>
              </h3>
              <p className="parjustify">
                We will endeavor to create new opportunities through constant
                innovation in all areas of our work. People who work with us
                will be constantly encouraged to challenge the status quo and
                demonstrate creativity in everything we do.
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="objectives"
                className="fdb-icon"
                src={trust}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Trust</strong>
              </h3>
              <p className="parjustify">
                We greatly respect the trust placed in us by all our
                stakeholders. We, as an organization, will display utmost
                integrity and continuously enhance this trust in every way that
                we conduct our business.
              </p>
            </div>
          </div>

          <div className="row text-center justify-content-center mt-5">
            <div className="col-12 col-sm-4 col-xl-3 m-md-auto">
              <img
                alt="mission"
                className="fdb-icon"
                src={teamwork}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Teamwork</strong>
              </h3>
              <p className="parjustify">
                We place high premium on teamwork as the way of doing business
                and will therefore, constantly team up with clients, business
                partners and employees to achieve common goals and rewards for
                all.
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="vision"
                className="fdb-icon"
                src={support}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Support and Knowledge</strong>
              </h3>
              <p className="parjustify">
                Noor Capital works tirelessly towards protecting our investors
                from making common mistakes. We provide our clients with robust
                tools, technical analysis, and economic reports to help them
                make the correct decision.{" "}
              </p>
            </div>
            <div className="col-12 col-sm-4 col-xl-3 m-auto pt-4 pt-sm-0">
              <img
                alt="objectives"
                className="fdb-icon"
                src={social}
                width="100px"
                style={{ marginBottom: "15px" }}
              />
              <h3>
                <strong>Social Responsibility</strong>
              </h3>
              <p className="parjustify">
                We will constantly look for opportunities to contribute to the
                economic development and improvement in the quality of life of
                our stakeholders as well as that of the society at large.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
