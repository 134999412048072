import React from "react";
import "./mainPage.css";
import globalstyle from "../../style.module.css";
import StockPrice1 from "./stockPrice1.js";

const StockPrices = () => {
  return (
    <>
      <div>
        <section className="fdb-block" style={{ padding: "10px 0" }}>
          <div className={globalstyle.container}>
            <StockPrice1 />
          </div>
        </section>
      </div>

      
    </>
  );
};

export default StockPrices;
