import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";

import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = { padding: "50px 0", alignSelf: "justify" };

const TermsandConditions = () => {
  return (
    <div>
      <PageseMetaTags
        title="Terms and Conditions - Noor Capital "
        description="Noor Capital Terms and Conditions"
      />

      <BgBefore
        pageHeadingTitle="Terms and Conditions"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Terms and Conditions"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Terms and Conditions</h4>
              <p className={globalstyle.parjustify}>
                These website terms and conditions on this page shall manage
                your use of our website.
              </p>
              <p className={globalstyle.parjustify}>
                These terms will be completely implemented and will impact your
                use of the website. By using this website, you agreed to accept
                all terms and conditions. If you disagree with all of the
                website terms and conditions, you may not use it.
              </p>
              <p className={globalstyle.parjustify}>
                This website is not intended for minors or those under the age
                of eighteen.
              </p>
            </div>
          </div>
          <br />

          {/* 2222222222222 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>
                Intellectual Property & Copyrights
              </h4>
              <p className={globalstyle.parjustify}>
                Noor Capital has the right over all the trademarks, trade names,
                and service marks that have been used on the website. No party
                is authorized to use any marks in any manner without prior
                written permission from the owner of the Mark. The Noor Capital
                website's content cannot be transmitted, broadcasted, copied,
                adapted, displayed, or performed without Noor Capital's prior
                permission.
              </p>
            </div>
          </div>
          <br />

          {/* 333333333333333 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Website Content</h4>
              <p className={globalstyle.parjustify}>
                The content of Noor Capital website is subject to change at any
                time without notice. The content is provided for the sole
                purpose of assisting traders in making independent and best
                investment decisions. The information on Noor Capital's website
                is accurate. However, we don't accept any liability for damage
                or loss that may arise from the content or your inability to
                access the website.
              </p>
            </div>
          </div>
          <br />

          {/* 888888888 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Disclaimer</h4>
              <p className={globalstyle.parjustify}>
                You must not rely on any of the website contents and construe
                them as personal advice. Any news, opinions, research, analysis,
                prices, or other information contained on the Noor Capital
                website is designed to provide you with a general market
                commentary and does not constitute any investment advice. We
                don't accept liability for any loss and damage, any loss of
                profit that may arise directly or indirectly from using or
                depending on such information.
              </p>
            </div>
          </div>
          <br />

          {/* 444444444444 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Privacy Policy</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 555555555555 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Trading Conditions</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/trading/accounts/trading-conditions">
                    Trading Conditions
                  </Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 6666666666 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Risk Disclosure</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/trading-tools/risk-disclosure">
                    Risk Disclosure
                  </Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 7777777777 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Fraud Warning</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/fraud-warning">Fraud Warning</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 7777777777 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Cookies Policy</h4>
              <p className={globalstyle.parjustify}>
                Please read our{" "}
                <b>
                  <Link to="/cookies-policy">Cookies Policy</Link>
                </b>
              </p>
            </div>
          </div>

          
          <br />
        </section>
      </div>
    </div>
  );
};

export default TermsandConditions;
