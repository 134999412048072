import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgmt4.jpg";
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import WhyNoor from "./whyNoor";

const style = { padding: "50px 0" };
const { Title } = Typography;

const Metatrader4_WebTrader = () => {
  return (
    <div>
      <PageseMetaTags
        title="Open Live Account - MT4 Web Trader - Noor Capital"
        description="Noor Capital MT4 WebTrader provides instant online access to the global financial markets to trade in all demo and real accounts on both PC and Mac, without rejecting orders and without requotes."
      />

      <BgBefore
        pageHeadingTitle="Flexible Trading For Maximum Profit"
        pageHeadingDescription="With Noor Capital's MetaTrader 4 (MT4), you Can Trade from anywhere, any Browser Anytime"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="MetaTrader 4 WebTrader"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Access your FX Trading Account from WebTrader
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Have you ever wondered about the secret of successful traders? They take the decision and act immediately with whatever available resources in hand. No matter where you are, now you can work smart and transform your trading experience with Noor Capital's MetaTrader 4 (MT4) platform accessible from any browser around the world.
            </p>
            <p className="parjustify">
            The Noor Capital's MetaTrader 4 (MT4) web version allows our traders to access their accounts and trade easily from any web browser and operating systems (Mac, Windows, Linux, etc). All you need is an Internet connection to get started.
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-12 col-md-8 col-lg-4 m-sm-auto">
                <p className="mt-3">
                  <a
                    className="btn btn-primary btn-lg"
                    href="https://metatraderweb.app/trade?servers=NoorCapital-Demo%2cNoorCapital-Live&trade_server=NoorCapital-Live&demo_server=NoorCapital-Demo&startup_mode=open_live&lang=en"
                    target="_blank"
                    
                  >
                    Open WebTrader
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                With Noor Capital's MT4 Web Version, You Are Always Connected And Making Profits.
                </Title>
                <h5>
                  
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Instant access to your account from any web browser and
                  operating system, anywhere in the world.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Access charts, indicators, quotes, news, and much more to help with trading strategies.
                </p>
                <br />

                
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get trading signals from top providers.
                </p>
                <br />
               
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Access your account on any web browser from anywhere in the
                  world.
                </p>
                <br />
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Trade on the go with Noor Capital's MetaTrader 4 (MT4) mobile
                  app.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Noor Capital's MetaTrader 4 (MT4) EAs (Expert Advisors)
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 dedicated customer service available to help you with any
                  concerns or advice.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Option to choose your preferred language (English/Arabic).
                </p>
                <br />
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="fdb-block">
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={3}>
                Advanced Benefits
              </Title>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-3">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <Title style={{ color: "#1b75bc" }} level={4}>
                Interactive Charts
              </Title>

              <p className="parjustify">
              Interactive charts track currency rate changes in real time. The application provides three types of charts: bars, candlesticks and lines allowing you to trade directly on them. You can easily scale and scroll charts, as well as switch between nine timeframes — from one minute to one month.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <Title style={{ color: "#1b75bc" }} level={4}>
                Convenience
              </Title>

              <p className="parjustify">
              The MetaTrader 4 App for iOS provides outstanding features to enhance your trading activity. The One Tap Trading function allows you to trade on a chart with a single click. Open the quick trading panel and perform a deal in no time! The built-in chat allows you to communicate with any registered user of the MQL5 community — the largest community of traders.
              </p>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <Title style={{ color: "#1b75bc" }} level={4}>
                Orders and Trading Functions
              </Title>

              <p className="parjustify">
              All information regarding the amount of orders and open positions, open prices, volumes and account status can be found in the Trade window, while the History window allows you to view the detailed history of all previously performed trades. To implement any strategy you need a flexible MetaTrader 4 mobile trading system paired with a full set of orders and trading functions. Instant execution orders allow you to trade Forex continuously, while pending ones relieve you from the necessity to constantly manage your trading.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <Title style={{ color: "#1b75bc" }} level={4}>
                Trading Flexibility
              </Title>

              <p className="parjustify">
              Instant execution orders allow you to trade Forex at any time, while pending orders relieve you from the necessity to constantly check your device. Just place a pending order and switch to other things. The order will be executed even if the application is offline. Set Take Profit and Stop Loss levels to set your profit or minimize your losses. Comprehensive information such as track number, open prices, volumes, state of account of all orders and open positions, are always available in the Terminal window. Detailed history of all trades is available from the History window.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={4}>
                Escalate your trading experience from anywhere, anytime & any
                browser
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://metatraderweb.app/trade?servers=NoorCapital-Demo%2cNoorCapital-Live&trade_server=NoorCapital-Live&demo_server=NoorCapital-Demo&startup_mode=open_live&lang=en"
                  target="_blank"
                >
                  Open WebTrader
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


      <WhyNoor />
    
    </div>
  );
};

export default Metatrader4_WebTrader;
