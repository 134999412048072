import React from "react";
import { Row, Col, Typography } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgtoolsAr.jpg";
import { Tabs } from "antd";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";

const { TabPane } = Tabs;

const NCformsAr = () => (
  <Tabs defaultActiveKey="1" centered size="large">
    <TabPane tab=" FX منصة تداول العملات (سطح المكتب)" key="1">
      <section className="fdb-block section1">
        <div>
          {/* 333333333 */}
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-12 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              كيفية تحميل منصة تداول نور كابيتال
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/nV35ogB7IZY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية تسجيل الدخول الى حساب التداول MT4
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/H_3C2EvctUM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية تغيير الألوان في الرسم البياني MT4
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/lMNQBPHDlb8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <br />
          
          {/* 333333333 */}
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              كيفية استخدام أشرطة الأدوات MT4
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/Vp8uCDIIo6k"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية فتح صفقة تداول بأمر السوق MT4
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/7vjzNj10nTE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية فتح صفقة تداول بالأمر المحدد سلفاً MT4
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/eJHig0jpiPQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>


          <br />
          
          {/* 333333333 */}
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              كيفية وضع SL و TP في الصفقة MT4
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/5i_k9Z4zUNQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية اغلاق الصفقة MT4
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/lIZSDZG2QH4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية إضافة المؤشرات الى الرسم البياني MT4
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/z94iCdbnhA4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>


        </div>
      </section>
    </TabPane>


    <TabPane tab=" FX منصة تداول العملات (الهواتف)" key="2">
      <section className="fdb-block">
        <div>
          {/* 333333333 */}
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              طريقة تحميل منصة نور كابيتال للتداول عن طريق الهاتف - MT4 MOBILE
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/5kpkjuf6n4w"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            تسجيل الدخول الى حسابك في منصة نور كابيتال للتداول عن طريق الهاتف - MT4 MOBILE
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/_98VnKezPgk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية تغيير الألوان في الرسم البياني ف في منصة نور كابيتال للتداول عن طريق الهاتف - MT4 Mobile
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/3Q4L8V9DbYM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <br />
          
          {/* 333333333 */}
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              لفتح صفقة تداول بأمر السوق Market Order في منصة نور كابيتال للتداول عن طريق الهاتف - MT4 Mobile
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/R2_wnEl-T8g"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية فتح صفقة تداول بالأمر المحدد سلفاً Pending Order - MT4 Mobile
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/dJ3UgxdxOTU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية وضع S/L & T/P في الصفقة في منصة نوركابيتال للتداول عن طريق الهاتف - MT4 Mobile
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/woKIvDE2BL4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>


          <br />
          
          {/* 333333333 */}
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-4">
              
              <Title level={4} style={{ color: "#1b75bc" }}>
              كيفية اغلاق الصفقة في منصة نوركابيتال للتداول عن طريق الهاتف - MT4 Mobile
          </Title>
              <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/nOphEcSQ4Uw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            <Title level={4} style={{ color: "#1b75bc" }}>
            كيفية إضافة المؤشرات الى الرسم البياني في منصة نوركابيتال للتداول عن طريق الهاتف - MT4 Mobile
          </Title>
            <iframe
                width="95%"
                height="270"
                src="https://www.youtube.com/embed/pAoLxp-99MQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-sm-0">
            
            </div>
          </div>


        </div>
      </section>
    </TabPane>


  </Tabs>
);
const { Title } = Typography;

const Tools_VideoTutorialsAr = () => {
  return (
    <div>
      <PageseMetaTags title="Video Tutorials - Noor Capital" description="" />

      <BgBeforeAr
        pageHeadingTitle="فيديوهات تعليمية"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="ادوات التداول"
        pageSubCategoryPath="#"
        pageName="فيديوهات تعليمية"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <p className="parjustify text-right" style={{ fontSize: "16.5px" }}>
          تسعى نور كابيتال دائمًا إلى تدريب وتطوير المتداولين في الأسواق المالية ليكونوا مؤهلين لأنشطة التداول وقد قامت بتنظيم برامج تعليمية بالفيديو ، وستساعد دروس الفيديو هذه في كيفية استخدام تطبيق FX Trading على الهاتف المحمول من نور كابيتال ، وإدارة حسابك ، والوصول إلى جميع الميزات لمساعدتك على التداول بسهولة.
          </p>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px r0px",
              fontSize: "16.5px",
            }}
          >
            <NCformsAr />
          </Col>
        </Row>
      </div>
      <br />

      <br />

      <style jsx>
        {`
          .mt-5,
          .my-5 {
            margin-top: 2rem !important;
          }
          a {
            color: #9ea0a2;
            
          }
          .section1{
          padding: 0px 0;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          
          padding: 20px;
      }
        `}
      </style>
    </div>
  );
};

export default Tools_VideoTutorialsAr;
