import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const LicenseAndRegulationsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Forex Trading License and Regulations | Noor Capital"
        description="As a regulated broker, Noor Capital secures clients' funds in segregated accounts with tier 1 investment grade banking institutions."
      />
      <BgBeforeAr
        pageHeadingTitle="الترخيص واللوائح"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="الترخيص واللوائح "
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>الترخيص واللوائح</h4>
              <p className={globalstyle.parjustify}>
                نور كابيتال مرخصة ومعتمدة من قبل دائرة التنمية الاقتصادية في
                أبوظبي، وهيئة الأوراق المالية والسلع في الإمارات العربية
                المتحدة.
              </p>
            </div>
          </div>
          <br />

          {/* 22222222222222222 */}

          {/* <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>الفصل بين أموال العميل</h4>
              <p className={globalstyle.parjustify}>
                في نور كابيتال، يتم الاحتفاظ بأموالك في حسابات بنكية منفصلة
                (منفصلة عن حساب الشركة) لدى شركائنا الرئيسيين من المؤسسات
                المصرفية من المستوى الأول، مما يجعلها آمنة ومضمونة.
              </p>
            </div>
          </div>
          <br /> */}

          {/* 3333333333333333333 */}

          {/* <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>سياسات وإجراءات صارمة</h4>
              <p className={globalstyle.parjustify}>
                نحن نلتزم بإرشادات صارمة حول الإدارة الرشيدة وقواعد السلوك
                الأخلاقي والمهني؛ يتضمن ذلك اتباع أساليب داخلية صارمة، والحفاظ
                على أعلى مستويات السرية، والامتثال لسياسات مكافحة غسل الأموال
                الدولية، والحفاظ باستمرار على أفضل الممارسات المؤسسية.
              </p>
            </div>
          </div>
          <br /> */}

          {/* 44444444444444444444 */}

          {/* <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>التدقيق المالي</h4>
              <p className={globalstyle.parjustify}>
                تدقق شركة برايس ووتر هاوس كوبرز Pricewaterhouse Cooper)) جميع
                تقاريرنا المالية، وهي شبكة خدمات مهنية عالمية مقرها الرئيسي في
                لندن، المملكة المتحدة.
              </p>
            </div>
          </div>
          <br /> */}

          {/* 5555555555555555555555 */}

          {/* <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>نظام معالجة المظالم</h4>
              <p className={globalstyle.parjustify}>
                في نور كابيتال، نحن ملتزمون تمامًا برعاية عملائنا ولدينا نظام
                قوي لمعالجة المظالم، مما يسمح لك بتقديم الملاحظات والشكاوى
                والاقتراحات. في حالة الشكاوى يقوم قسم خدمة العملاء باستلام
                الشكوى وإصدار رقم التذكرة وإرسال الشكوى إلى الإدارة المختصة.
                يقوم القسم المعني بتسجيل الشكوى مع بيانات العميل. بعد ذلك يتم
                التحقيق في الشكوى من خلال المكالمات والمراسلات المسجلة مع العميل
                للوقوف على مدى جدية الشكوى. في أي وقت خلال هذه العملية، قد يتم
                تسجيل مكالمة العميل عدة مرات لمزيد من التوضيحات. تُرسل النتائج
                إلى قسم خدمة العملاء للحصول على رأي الإدارة حول النتائج وأفضل
                الحلول لشكواهم.
              </p>
            </div>
          </div>
          <br /> */}

          {/* 6666666666666666666 */}


          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>هيئة الأوراق المالية والسلع</h4>
              <p className={globalstyle.parjustify}>
              نور كابيتال مرخصة وخاضعة لإشراف ورقابة هيئة الأوراق المالية والسلع في دولة الإمارات العربية المتحدة، لدينا ترخيص نشط وعامِل لمزاولة الأنشطة التالية:
              <ol>
              <li>- وسيط تداول عقود المشتقات غير المنظمة والعملات في السوق الفوري</li>
              <li>- وسيط تداول في الأسواق العالمية</li>
              <li>- لمزاولة إدارة الاستثمار - إدارة المحافظ</li>
              <li>- لمزاولة نشاط شركة الإدارة - تأسيس وإدارة صناديق الاستثمار</li>
              <li>- لمزاولة نشاط الاستشارات المالية والتحليل المالي</li>
              <li>- لمزاولة نشاط المستشار المالي - مدير الاصدار</li>
            </ol>

          </p>

            </div>
          </div>
          <br />

            
        </section>
      </div>
    </div>
  );
};

export default LicenseAndRegulationsAr;
