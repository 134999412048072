import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from "rmwc";

// not done we need to change Noor_Academy_Enroll to API Module

import { BASE_URL, COUNTRIES, Noor_Academy_Enroll } from "../../constants";

import { emiratesAr, hearaboutAr } from "./options";

// 1 New
import { getStorage } from "../../utils/helpers";

export default function NoorAcademyEnrollFromAr() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "AE",
    emirate: "",
    hearabout: "",
    agree: false,
  };

  const [state, setState] = React.useState(initialState);
  const [countries, setCountries] = React.useState([{ name: "", code: "" }]);
  const [requestSent, setRequestSent] = React.useState(false);

   // 3 New
   let queryParams = {};

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "ar",
  };

  // 4 Need To Check
  React.useEffect(() => {
    axios
      .get(BASE_URL + COUNTRIES, { headers: headers })
      .then((countries) => {
        setCountries(countries.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // END

  function handleCountryChange(event) {
    const newState = { ...state, countries: event.target.value };
    setState(newState);
  }

  // 5 New Function
  function handleHearAboutChange(event) {
    const newState = { ...state, hearabout: event.target.value };
    setState(newState);
  }

  // 6 New Function
  function handleAgreeChange(event) {
    const newState = { ...state, agree: event.target.checked };
    setState(newState);
  }

  function handleFormChange(event) {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  }

  function submitNoorAcademyEnroll(event) {
    event.preventDefault();
     
    const data = {
      first_name: state.firstName,
      last_name: state.lastName,
      phone: state.phoneNumber,
      email: state.email,
      country: state.country,
      emirate: state.emirate,
      hearabout: state.hearabout,
      agree: state.agree,
    };

    // 7 New Data from storage
    let newData = data;
    if (getStorage("queryParams")) {
      queryParams = getStorage("queryParams");
      newData = {
        ...data,
        refrence_sp: queryParams.SP,
        refrence_ib: queryParams.IB,
        refrence_p: queryParams.P,
      };
    }
    // 8 add new data to post

    axios
      .post(BASE_URL + Noor_Academy_Enroll, newData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          setRequestSent(true);
          setState(initialState);
        }
      });
  }

  return (
    <div>
      <form onSubmit={submitNoorAcademyEnroll}>
        <div className="row">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="الاسم الأول"
              name="firstName"
              value={state.firstName}
              onChange={handleFormChange}
            />
          </div>

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="اسم العائلة"
              name="lastName"
              value={state.lastName}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="email"
              required
              className="form-control"
              placeholder="البريد الإلكتروني"
              name="email"
              value={state.email}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="رقم الهاتف"
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleFormChange}
            />
            <small  className="form-text text-muted  text-right">
            مثال: 97150xxxxxxx+
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              onChange={handleCountryChange}
              value={state.country}
            >
              {countries.map((country) => (
                <option value={country.code}>{country.name}</option>
              ))}
            </select>
            <small  className="form-text text-muted text-right">
              الجنسية
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="emirate"
              onChange={handleFormChange}
              // onChange={handleCountryChange}
              // value={state.country}
            >
              {emiratesAr.map((emirate) => (
                <option value={emirate.emirate_code}>
                  {emirate.emirate_name}
                </option>
              ))}
            </select>
            <small  className="form-text text-muted  text-right">
            للمقيمين في دولة الإمارات فقط
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="hearabout"
              onChange={handleHearAboutChange}
              value={state.hearabout}
              // onChange={handleCountryChange}
              // value={state.country}
            >
              {hearaboutAr.map((hear) => (
                <option value={hear.hearabout_code}>
                  {hear.hearabout_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col"  style={{
            // padding: "0px 0px 0px 35px",
          }}>
            <input
              type="checkbox"
              className="form-check-input pull-right"
              name="agree"
              style={{"display":"block"}}
              required
            onChange={handleAgreeChange}
            defaultChecked={state.agree}
            checked={state.agree}
            ></input>
            <small  className="form-text text-muted form-check-label text-right" for="gridCheck" style={{"paddingRight":"20px"}}>
            أوافق على تلقي معلومات من نور كابيتال حول منتجاتها وخدماتها .
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button type="submit" className="btn btn-primary">
            إرسال
            </button>
          </div>
        </div>
      </form>

      <Dialog
        body="Your request is sent successfully."
        open={requestSent}
        onClose={(event) => {
          setRequestSent(false);
        }}
      >
        <DialogTitle>تم!</DialogTitle>
        <DialogContent>
          تم إستقبال طلبك بنجاح. <br />
          سيتم التواصل معك قريبا.
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">إغلاق</DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
