import React, { Component } from "react";
import { Link } from "react-router-dom";
import slide1 from "../../assets/images/slides/Sliders1.jpg";
import slide2 from "../../assets/images/slides/Sliders2.jpg";
import slide3 from "../../assets/images/slides/Sliders3.jpg"; // MT5
import slide4 from "../../assets/images/slides/Sliders4.jpg";
import slide5 from "../../assets/images/slides/Sliders5.jpg";

// Eid Slider 1
// import slide0 from "../../assets/images/slides/Sliders0.jpg";


import "./slider.css";

const btn1 = "/about/why-noor-capital";
const btn2 = "/trading-platforms/mt4-open-forex-demo";
const btn3 = "/trading-platforms/metatrader5-platform#open-demo";
const btn4 = "/physical-trading-platforms/open-noor-trader-demo";
const btn5 = "/education/noor-academy";
const btn6 = "/activities/36";

const Slider = () => {
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {slides.map((item, index) => (
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to={item.id}
              className={index === 0 ? "active" : " "}
            ></li>
          ))}
        </ol>
        <div className="carousel-inner">
          {slides.map((item, index) => (
            <div
              className={
                "carousel-item position-relative " +
                (index === 0 ? "active" : " ")
              }
            >
              <img
                className="d-block w-100 slideronmobile"
                src={item.img}
                alt={item.mainHeading}
              />
              <div className="carousel-caption " style={{textAlign:"left"}}>
                {/* <h2 className="display-5" style={{"color":"#fff"}}>{item.mainHeading}</h2> */}
                <h4 className="sliderheadingonmobile">{item.mainHeading}</h4>
                <p className="lead slidersubheadingonmobile">
                  {item.description}
                </p>
                <Link class="btn btn-success px-3 carousel-btn" to={item.link}>
                  {item.btnLabel}
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Eid Slider 2 */}
        {/* <style jsx>
        {`
          #carouselExampleIndicators > div > div:nth-child(1) > div > a {
            display: none;
          }
        `}
      </style> */}
      
        
      {/* <style jsx>
        {`
          #carouselExampleIndicators > div > div:nth-child(1) > div > a{
            display: none;
          }
        `}
      </style> */}


      </div>
    </>
    
  );
};

const slides = [

  

  // {
  //   id: "0",
  //   description:
  //     "",
  //   mainHeading: "General Assembly Meeting Financial Year 2021-2022",
  //   btnLabel: "Learn More",
  //   img: slide5,
  //   link: btn6,
  // },

  {
    id: "0",
    description:
      "Competitive Commission starting from $0.01/Share",
    mainHeading: "Start Trading U.S. Stocks",
    btnLabel: "Open Demo Account",
    img: slide3,
    link: btn3,
  },
  
  {
    id: "1",
    description:
      "We Make Investments Foster - Bringing You The Most Innovative And Robust unique Solutions For All Your Financial Needs",
    mainHeading: "Elevate your  investment experience with Noor Capital",
    btnLabel: "Learn More",
    img: slide1,
    link: btn1,
  },

  // {
  //   id: "0",
  //   description:
  //     "",
  //   mainHeading: "",
  //   btnLabel: "",
  //   img: slide1,
  //   link: btn1,
  // },

  {
    id: "2",
    description:
      "A Robust FX Trading Platform That Enables You To Trade With Ease And Peace",
    mainHeading:
      "Trade With Confidence On The World's Leading Trading Platform",
    btnLabel: "Open FX Demo",
    img: slide2,
    link: btn2,
  },
  
  
  {
    id: "3",
    description:
      "Join Noor Capital Academy, advanced learning tools to escalate your trading experience",
    mainHeading: "Do You Want To Become a Professional Trader",
    btnLabel: "Enroll Now",
    img: slide5,
    link: btn5,
  },




];
export default Slider;




