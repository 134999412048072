import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from "rmwc";

// not done we need to change liv_GTN to API Module

import { BASE_URL, COUNTRIES, liv_GTN } from "../../constants";

import { emiratesAr, hearaboutAr } from "./options";

export default function OpenGTNLiveFormAr() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "AE",
    emirate: "",
    hearabout: "",
    agree: false,
    checklive: "",
  };

  const [state, setState] = React.useState(initialState);

  const [countries, setCountries] = React.useState([{ name: "", code: "" }]);


  const [requestSent, setRequestSent] = React.useState(false);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "ar",
  };

  React.useEffect(() => {
    axios
      .all([
        axios.get(BASE_URL + COUNTRIES, { headers: headers }),
      ])
      .then(
        axios.spread((countries) => {
          setCountries(countries.data);
        })
      )
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleCountryChange(event) {
    const newState = { ...state, countries: event.target.value };
    setState(newState);
  }

  function handleFormChange(event) {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  }

  function submitOpenGTNLive(event) {
    event.preventDefault();
    
    
    const data = {
      first_name: state.firstName,
      last_name: state.lastName,
      phone: state.phoneNumber,
      email: state.email,
      country: state.country,
      emirate: state.emirate,
      hearabout: state.hearabout,
      agree: state.agree,
      checklive: state.checklive,
    };
    axios
      .post(BASE_URL + liv_GTN, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          setRequestSent(true);
          setState(initialState);
        }
      });
  }

  return (
    
    <div>
      <form onSubmit={submitOpenGTNLive}>
        <div className="row">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="الاسم الأول"
              name="firstName"
              value={state.firstName}
              onChange={handleFormChange}
            />
          </div>

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="اسم العائلة"
              name="lastName"
              value={state.lastName}
              onChange={handleFormChange}
            />
          </div>
        </div>


        <div className="row mt-4">
          <div className="col">
            <label>هل لديك حساب فى نور كابيتال؟</label>

            <br />

            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="checklive"
                value="True"
                id="radioyes"
              />
              <label class="form-check-label" for="radioyes" style={{"paddingRight":"10px"}}>
                نعم
              </label>
            </div>
            <div class="form-check form-check-inline" style={{"paddingRight":"15px"}}>
              <input
                class="form-check-input"
                type="radio"
                name="checklive"
                value="False"
                id="radiono"
                checked
              />
              <label class="form-check-label" for="radiono" style={{"paddingRight":"10px"}}>
                لا
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="email"
              required
              className="form-control"
              placeholder="البريد الإلكتروني"
              name="email"
              value={state.email}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="رقم الهاتف"
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleFormChange}
            />

            <small  className="form-text text-muted  text-right">
            مثال: 97150xxxxxxx+
            </small>
            
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              onChange={handleCountryChange}
              value={state.country}
            >
              {countries.map((country) => (
                <option value={country.code}>{country.name}</option>
              ))}
            </select>
            <small  className="form-text text-muted text-right">
              الجنسية
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="emirate"
              // onChange={handleCountryChange}
              // value={state.country}
            >
              {emiratesAr.map((emirate) => (
                <option value={emirate.emirate_code}>
                  {emirate.emirate_name}
                </option>
              ))}
            </select>
            <small  className="form-text text-muted  text-right">
            للمقيمين في دولة الإمارات فقط
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="hearabout"
              // onChange={handleCountryChange}
              // value={state.country}
            >
              {hearaboutAr.map((hear) => (
                <option value={hear.hearabout_code}>
                  {hear.hearabout_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col"  style={{
            // padding: "0px 0px 0px 35px",
          }}>
            <input
              type="checkbox"
              className="form-check-input pull-right"
              name="agree"
              style={{"display":"block"}}
            ></input>
            <small  className="form-text text-muted form-check-label text-right" for="gridCheck" style={{"paddingRight":"20px"}}>
            أوافق على تلقي معلومات من نور كابيتال حول منتجاتها وخدماتها .
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button type="submit" className="btn btn-primary">
            إرسال
            </button>
          </div>
        </div>
      </form>

      <Dialog
        body="Your request is sent successfully."
        open={requestSent}
        onClose={(event) => {
          setRequestSent(false);
        }}
      >
        <DialogTitle>تم!</DialogTitle>
        <DialogContent>
          تم إستقبال طلبك بنجاح. <br />
          سيتم التواصل معك قريبا.
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">إغلاق</DialogButton>
        </DialogActions>
      </Dialog>
    
    </div>

  );
}
