import React from "react";
import { Link } from "react-router-dom";
// import "./mainPage.css";
import globalstyle from "../../style.module.css";
import fx from "../../assets/images/home/forex.svg";
import metal from "../../assets/images/home/metal.svg";
import energies from "../../assets/images/home/energies.svg";
import indices from "../../assets/images/home/indices.svg";
import share from "../../assets/images/home/shares.svg";

const ProductsAr = () => {
  return (
    <>
      
      
      <div>
    
        <section className="fdb-block" style={{ padding: "70px 0" }}>
          <div className={globalstyle.container}>
            <div className="row align-items-top">
              <div className="col-12 col-md-6 col-xl-6 m-auto">
                <div className="text-right">
                  <h2 className="display-4" style={{ color: "#1b75bc" }}>عالم من الفرص الإستثمارية</h2>
                </div>
                <p style={{ color: "#8892a0", "fontSize":"18px" }} className="text-right"> 
                تداول أكثر من 1000 أداة و مجموعة واسعة من فئات الأصول العالمية من خلال منصات تداول متعددة
                </p>
              </div>

              <div className="col-12 col-md-6 pt-5 pt-md-0">
                <div className="row justify-content-right">
                  <div className="col-3 m-auto text-right">
                    <img
                      alt="Forex"
                      className="fdb-icon"
                      src={fx}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/forex">
                      <i className="fas fa-angle-left" /> العملات 
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Precious Metals"
                      className="fdb-icon"
                      src={metal}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/precious-metals">
                      <i className="fas fa-angle-left" />   المعادن الثمينة 
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Energies"
                      className="fdb-icon"
                      src={energies}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/energies">
                      <i className="fas fa-angle-left" />  الطاقة 
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="row justify-content-right mt-4 mt-xl-5">
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Indices"
                      className="fdb-icon"
                      src={indices}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/indices">
                      <i className="fas fa-angle-left" />  المؤشرات 
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Share CFDs"
                      className="fdb-icon"
                      src={share}
                      style={{ width: "60px", paddingBottom: "10px" }}
                    />
                    <p>
                      <Link to="/ar/trading/instruments/shares-cfds">
                      <i className="fas fa-angle-left" /> أسواق الأسهم 
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    
      </div>

      {/* END ROW 2222222 */}
    </>
  );
};

export default ProductsAr;
