import React from "react";
import { Row, Col, Typography } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgmt4Ar.jpg";
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import OpenFxDemoFormAr from "../forms/openFxDemoFormAr.js";
import Mt4VideoAr from "./mt4videoAr.js";
import WhyNoorAr from "./whyNoorAr";

const { Title } = Typography;

const Metatrader4_OpenDemoAccountAr = (props) => {
  const { location } = props;
  return (
    <div>
      <PageseMetaTags
        title="Open Demo Account - Forex Trading Demo Account | Noor Capital"
        description="The Noor Demo Account allows you to review and test the features of our Noor Trading Platform before funding your account with real money or placing trades."
      />
      <BgBeforeAr
        pageHeadingTitle="اختبر قبل أن تتداول فقط مع ميتاتريدر MT4 من نور كابيتال"
        pageHeadingDescription="سجل الآن للحصول على حساب تجريبي وادخل عالم الفرص اللانهائية"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="فتح حساب تجريبي"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            سجل حساب تجريبي لتداول العملات والسلع
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
              سواء كنت مبتدئًا أو متداوًلا محترفًا ولديك سنوات من الخبرة، فأنت
              بحاجة إلى فهم كامل للمنصة واتجاهات السوق والاستراتيجيات قبل أن
              تتداول مباشرة.
            </p>
            <p className="parjustify text-right">
              ندعوك في نور كابيتال للاختبار قبل أن تتداول من خلال تسجيل حساب
              تجريبي مجاني لميتاتريدر MT4 الخاص بشركة نور كابيتال. تعتبر منصتنا
              التجريبية سهلة الاستخدام مثل التداول المباشر، باستثناء أنك ستتداول
              في الوقت الفعلي بأموال افتراضية.
            </p>

            <OpenFxDemoFormAr location={location} />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Mt4VideoAr />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                  المميزات
                </Title>
                <h5>
                  طوًر خبرتك التجارية واختبرها قبل أن تقوم بالتداول المباشر
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على وصول فوري إلى السوق في الوقت الفعلي من خلال حسابنا
                  التجريبي
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تعلم التداول كمحترف باستخدام بيانات فورية وشركات في الوقت
                  الفعلي
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  مؤشرات ورسوم بيانية متقدمة للمساعدة في استراتيجيات التداول
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على إشارات التداول من كبار المزودين
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الوصول إلى حسابك على أي متصفح ويب من أي مكان في العالم
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تداول خالي من المخاطر
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تداول أينما كنت باستخدام تطبيق ميتاتريدر MT4 الخاص بنور
                  كابيتال
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  اختبر خاصية ميتاتريدر MT4 (المستشارين الخبراء) من نور كابيتال
                  لمساعدتك على التداول بأقل قدر من المخاطر
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  خدمة عملاء مخصصة 24/5 متاحة لمساعدتك في أي استشارات أو نصائح
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  جرب منصة التداول الأكثر أمانًا وسهولة في الاستخدام حول العالم
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4desktop" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
            <div
              style={{
                paddingTop: "35px",
                textAlign: "center",
                fontSize: "14.5px",
              }}
            >
              <p>
                من خلال الحساب التجريبي، احصل على وصول فوري وخالي من المخاطر
                ومباشر إلى السوق في الوقت الفعلي، حيث يمكنك ممارسة عمليات
                التداول والحصول على آخر التحديثات والتعلم من الخبراء واستخدام
                جميع الميزات التي ستأخذك خطوة واحدة نحو أن تصبح متداوًلا
                محترفًا. من خلال حسابنا التجريبي، ستحصل على تجربة حية للسوق في
                الوقت الفعلي دون التعرض لخطر الخسارة.
              </p>
            </div>
          </div>
        </section>
      </div>

      <br />

      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                هل أنت جاهز التداول؟
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank" rel="noreferrer"
                >
                  سجل حساب حقيقي
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Metatrader4_OpenDemoAccountAr;
