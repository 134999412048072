import React from "react";
import SliderAr from "./sliderAr";
import PriceTickerAr from "./priceTickerAr";
import MainAr from "./mainPage_MainAr";
import PlatformsAr from "./mainPage_PlatformsAr";
import EducationAr from "./mainPage_EducationAr";
import PartnershipAr from "./mainPage_PartnershipAr";
import StockPricesAr from "./mainPage_StockPricesAr";
import PageseMetaTags from "../metaTags";

const HomeAr = () => {
  return (
    
      <>
      <PageseMetaTags
        title="نور كابيتال"
        description="Noor Capital P.S.C"
      />
        <SliderAr />
        <PriceTickerAr />
        <MainAr />
        <StockPricesAr />
        <PlatformsAr />
        <EducationAr />
        <PartnershipAr />
      </>
    
  );
};

export default HomeAr;
