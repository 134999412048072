import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import economicreport from "../../assets/images/pages/tools/economic-report.png";
import img1 from "../../assets/images/bgtools.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  'Daily, weekly and monthly reports will help you make accurate decisions regarding the current developments that may affect your investments.',
'Get in-depth economic analysis and advice from some of the most experienced and accredited experts in the financial sector.',
'Minimize the risk by taking the right decision with Noor Capital Economic Report.',
'Accessible from anywhere in the world.',
];
const { Title } = Typography;

const NoorTrends_EconomicReport = () => {
  return (
    <div>
      <PageseMetaTags
        title="Daily Economic Report - Noor Capital"
        description="The economic calendar provides useful information on upcoming macroeconomic events by means of pre-scheduled news announcements and government reports on economic indicators that influence the financial markets."
      />

      <BgBefore
        pageHeadingTitle="Stay Informed & Stay Ahead Only With Noor Capital’s Economic Report"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName="Daily Economic Report"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Daily Economic Report
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              <b>
              Make Quick And Accurate Decisions With The Latest News & Information
              </b>
            </p>

            <p className="parjustify">
            Join us daily for the latest news, trends, and developments in the world economy that can directly impact your investment and trading. Noor Capital Economic Report will help you analyze the market and gain a unique perspective with the information related to economic trends and the latest news of the world's financial sector.


            </p>

            <p className="parjustify">
            With Noor Capital Economic Report, get the latest data and technical analysis that will help you plan for a smooth trading experience. Find out how to take the maximum advantage of the market swing, government policies, economic indicators, and world events.
            </p>

            
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={economicreport}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{color: "#1b75bc"}}>
          Features & Benefits
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Get Access To Noor Capital Economic Report Now!!
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/en/category/economic-reports/"
                  target="_blank"
                >
                  Click Here
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default NoorTrends_EconomicReport;
