import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import marketupdate from "../../assets/images/pages/tools/market-update.png";
import img1 from "../../assets/images/bgtools.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  'Daily market updates, news, and information that will help you plan your investment with confidence.',
'Accessible from anywhere in the world.',
"Minimize the risk by taking the right decision with Noor Capital's Daily Market Updates.",
];
const { Title } = Typography;

const NoorTrends_MarketUpdate = () => {
  return (
    <div>
      <PageseMetaTags
        title="Forex Market Updates: Latest Forex News | Noor Capital"
        description="View all upcoming global economic news events and their importance in this forex calendar."
      />

      <BgBefore
        pageHeadingTitle="Get Daily Market Updates On The Go"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName="Market Updates"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Daily Market Updates
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify"><b>
            Trade With Confidence With Daily Market Real-Time Updates From Anywhere
            </b></p>

            <p className="parjustify">
            Noor Capital provides real-time market, finance, and business updates and  in Daily Market Updates, the most advanced platform. Now you can stay ahead in the trading world with the latest updates from the UAE and worldwide.
            </p>

            <p className="parjustify">
            Get trading news, stock market updates, financial consultations, business news, and more. World events have a profound impact on the financial sector, and keeping yourself up to date with the Daily Market Updates will allow you  make accurate decisions and plan better.
            </p>

            <p className="parjustify">
            Noor Capital's Daily Market Updates will provide you with accurate news, and market information so that you can trade with confidence.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={marketupdate}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          Features & Benefits
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Get Access To Noor Capital Daily Market Updates Now
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/en/category/market-updates/"
                  target="_blank"
                >
                  Click Here
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default NoorTrends_MarketUpdate;
