import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import img1 from "../../assets/images/bgservicesbanner2.jpg";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = { padding: "50px 0", alignSelf: "justify" };

const notes = [
  "Regular updates on the latest investment opportunities.",
  "Investor interest is completely safeguarded, with the highest level of security and confidentiality.",
  "Noor Capital helps you make accurate and timely financial decisions.",
  "We provide a perfect solution as per the goals and requirements of each customer.",
  "Choice of creating an electronic or account with the company.",
  "The Investment Placement Division is continually available for investors in the Gulf Cooperation Council (GCC) states, including the United Arab Emirates, Saudi Arabia, Qatar, Oman, Kuwait and Bahrain, and the Middle East.",
  "Accredited and certified team with many years of market experience.",
  "24/5 multi-language customers support.",
];

const { Title } = Typography;

const InvestmentAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Investment Service - Better investment decisions with Noor Capital"
        description="The Investment Placement Division’s mandate is to serve the network of regional investors with superior unique investment solutions recommended from Noor Capitals team"
      />
      <BgBefore
        pageHeadingTitle="Enjoy Long-Lasting Wealth with Noor Capital Investment Services"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Services"
        pageSubCategoryPath="#"
        pageName="Investment"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Investment Services
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Noor Capital, a leading investment and brokerage firm in the Middle East, offers exclusive investment opportunities to its regional investors and helps them create and manage their investments in a safe environment.
            </p>

            <p className="parjustify">
            We offer a wide range of financial opportunities, including Investments, Asset Management, Financial Advisory, Funds Administration, Global Markets Brokerage for Online Forex Trading, Gold Funds, Gold, Silver, and Currencies.
            </p>

            <p className="parjustify">
            At Noor Capital, we believe in providing an efficient and effective investment solution led by the certified and experienced team across the industries. Our services are available for individuals, institutions, and corporate investors across the Gulf.
            </p>

            <p className="parjustify">
            Noor Capital's Investment provides unparalleled financial solutions for each customer according to their goals and preferences, from our custom-made suite of superior products and industry-leading, best-in-class third-party providers.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={investmentimg}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Noor Capital Investment Services
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-left"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Redefine Your Financial Goals with Noor Capital's Investment Services
              </Title>
              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/about/contact-us"
                >
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block">
        <div className="row">
          <div className={globalstyle.container}>
            <div className="col" style={{ padding: "15px" }}>
              <h1
                className="display-5 text-center"
                style={{ color: "#11131b" }}
              >
                Other Services
              </h1>
            </div>

            <div className="row text-left">
              <div className="col-12 col-md-8 m-auto col-lg-4">
                <div className="fdb-box fdb-touch">
                  <h4>Financial Advisory</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                  With Noor Capital's initial focus being UAE and KSA, we have successfully widened our advisory service across the MENA regions.
                  </p>
                  <p>
                    <Link to="/services/global-financial-advisory">
                      Read more
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>Asset Management</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                  Noor Capital provides a solid asset management solution to institutions, high net worth individuals, and retail investors through direct.
                  </p>
                  <p>
                    <Link to="/services/asset-management">Read more</Link>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>Fund Administration</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                  Noor Capital provides you with a highly efficient and all-inclusive Fund Administration solution, especially for setting up asset management.
                  </p>
                  <p>
                    <Link to="/services/funds-administration">Read more</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InvestmentAr;
