import React from "react";
import Slider from "./slider";
import PriceTicker from "./priceTicker";
// import MainPage1 from "./mainPage1";
import Main from "./mainPage_Main";
import Platforms from "./mainPage_Platforms";
import Education from "./mainPage_Education";
import Partnership from "./mainPage_Partnership";
import StockPrices from "./mainPage_StockPrices.js";
import PageseMetaTags from "../metaTags";


const Home = () => {
  return (
    <>
    <PageseMetaTags
        title="Noor Capital PSC - Leading Investment Firm regulated by SCA | UAE"
        description="Noor Capital offers premium financial services and investment plans which include trading in Forex, Stock Market and Bullion (Gold/Silver)"
      />
      <Slider />
      <PriceTicker />
      <Main />
      <StockPrices />
      <Platforms />
      <Education />
      <Partnership />
    </>
  );
};

export default Home;
