import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import alsayegh from "../../assets/images/board/alsayegh.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };

const ChairmanMessage = () => {
  return (
    <div>
      <PageseMetaTags
        title="Noor Capital Chairman’s Message"
        description="Noor Capital Chairman's message"
      />
      <BgBefore
        pageHeadingTitle="Noor Capital Chairman’s Message"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Chairman's Message"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div
            className="row text-center justify-content-center"
            style={{ marginBottom: "20px" }}
          >
            <div className="col-10">
              <h2 className="display-4" style={{ color: "#11131b" }}>
                Chairman's Message
              </h2>
            </div>
          </div>

          {/* 111111111111 */}

          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "10px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                  <img
                    alt="Mr. Abdul Jabbar Al-Sayegh"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={alsayegh}
                    style={{ marginBottom: "25px" }}
                  />
                  <h5 style={{ color: "#1b75bc", marginBottom: "0px" }}>
                    Mr. Abdul Jabbar Al-Sayegh{" "}
                  </h5>
                  <p className="lead">Chairman, Noor Capital</p>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={16}
                  xl={16}
                  style={{ padding: "20px 0px 10px 30px" }}
                >
                  <blockquote class="blockquote">
                    <p
                      class="mb-0"
                      style={{
                        color: "#9f9898",
                        textAlign: "center",
                        lineHeight: "35px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "38px",
                          padding: "9px",
                          color: "#9f9898",
                        }}
                      >
                        <i class="fas fa-quote-left"></i>
                      </span>
                      When a dream of several ideas came together for delivering
                      financial consulting and investment solutions to our
                      clients, Noor Capital was born{" "}
                      <span
                        style={{
                          fontSize: "38px",
                          padding: "9px",
                          color: "#9f9898",
                        }}
                      >
                        <i class="fas fa-quote-right"></i>
                      </span>
                    </p>
                  </blockquote>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    There are several ways to measure a company's success.
                    However, any company's real success is when it adds value to
                    its country's economy and people. Noor Capital has always
                    believed in adding more value to its customers' investments,
                    guiding them towards making the right financial decisions
                    and contributing to the UAE economy.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    Noor captail was found in December 2005 to provide seamless
                    financial solutions and investment opportunities with
                    unmatched service and highly innovative technology.{" "}
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    It is my immense pleasure to announce that we have
                    successfully, passionately, and tirelessly followed our
                    mission, vision, and values, making Noor Capital one of the
                    leading financial and investment solution companies in the
                    UAE and globally.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    I am delighted to be surrounded by one of the most motivated
                    and proficient teams that work relentlessly, adding value to
                    every client with their expertise.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    With the UAE economy ever-growing through its visionary
                    leadership, Noor Capital thrives on contributing to this
                    great nation with our robust investment solutions.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    I extend my congratulations to all our employees, clients,
                    shareholders, and board of directors for playing an
                    essential role in making Noor Capital a leading financial
                    and investment firm.
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    I would also like to take this opportunity to express my utmost gratitude and appreciation to our leaders, H.H. Sheikh Mohammed Bin Zayed Al Nahyan, the President of the UAE, H.H. Sheikh Mohammed Bin Rashed Al Maktoum, Prime Minister and Ruler of Dubai.{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>

      <style jsx>
        {`
          .imgshadow {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19);
          }
        `}
      </style>
    </div>
  );
};

export default ChairmanMessage;
