import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import { Link } from "react-router-dom";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgpartnershipbanner4.jpg"
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import WhyNoor from "./whyNoor.js";

const style = {padding: '50px 0' };
const notes = [
'The advanced solutions combine optimal liquidity with numerous execution methods.',
'Flexible FIX API integration options designed for today’s complex trading environment.',
'Options to trade in several financial assets such as forex futures, commodities, equity indices, and forex.',
'Access to multiple-asset trading.',
'Low-priced liquidity solution – less commission charged per traded volume.',
'Free consultancy and help provided with the integration and setting up of your system.',
 ];
const { Title } = Typography;

const Institutional_InstitutionalClients = () => {
  return( <div>
            <PageseMetaTags
                title="Institutional Clients - Forex Trading | Noor Capital"
                description="Noor Capital Welcomes Institutional Investors, High Net Worth Individuals & Investors with an Interest in Trading."
            />

            <BgBefore 
            pageHeadingTitle="Your Profit is our Success"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName = "Home"
        pageMainCategoryPath = "/"
        
        pageSubCategoryName = "Partnership"
        pageSubCategoryPath = "#"

        pageName = "Institutional Clients"
        />



<div className={globalstyle.container}>
        <br />
        <br />
        <Row>
        <Title style={{ color: "#1b75bc" }} level={3}>Institutional Clients</Title>
          
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            At Noor Capital, we provide our partners with an exceptional platform, trading strategies, and dedicated support to grow in the financial market. Our ultimate aim has been our client's financial welfare, and we offer our institutional investors an unparalleled competitive edge in the international markets.
            </p>
            <p className="parjustify">
            With years of experience and certified professional people, our team is always there to support you in making the right decision and building long-lasting relationships with major liquidity providers.
            </p>
            <p className="parjustify">
            With Noor Capital, you get direct access to the market through FIX API, based on the FIX 4.4 protocol, specially designed for institutional and Algo traders enabling them to access liquidity from Tier-1 banks with the fastest market executions.
            </p>

            

          </Col>

         
        </Row>


        

      <Divider orientation="left"><Title level={3} style={{color: "#1b75bc" }}>Features & Benefits</Title></Divider>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        
    <List
      size="small"
      bordered
      dataSource={notes}
      renderItem={item => 
      <List.Item><i class="fas fa-angle-double-right" style={{ fontSize: "16px", marginRight: "10px" , color: "#1b75bc" }}></i>{item}</List.Item>
    }
    />
    <br/>
        </Col>
      </Row>

<br/>
      


      
      <style jsx>{
            `
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd){
              background-color: #f1f1f1;
          }
            `
        }
        </style>

      </div>  


      <section className="fdb-block" style={{"backgroundColor":"#f2f4f7"}}>
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
            

              <Title style={{"color":"#11131b"}} level={3}>
              Call us now
                </Title>
              <p className="mt-5 mt-sm-4">
                <Link className="btn btn-outline-primary" to="/about/contact-us">Contact Us</Link>
              </p>
            </div>
          </div>
        </div>
      </section>


      <WhyNoor />

  </div>
  )

};

export default Institutional_InstitutionalClients;