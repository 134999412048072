import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { ConfigProvider } from "antd";
import queryString from "query-string";
import ar_EG from "antd/lib/locale/fr_FR";

import "./App.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

// import Page from "./components/pages/page";
// About
import AboutUs from "./components/pages/about_AboutUs.js";
import ChairmanMessage from "./components/pages/about_ChairmanMessage.js";
import ManagementTeam from "./components/pages/about_ManagementTeam.js";
import BoardMembers from "./components/pages/about_BoardMembers.js";
import LicenseAndRegulations from "./components/pages/about_LicenseAndRegulations.js";
import AntiMoneyLaunderingPolicy from "./components/pages/about_AntiMoneyLaunderingPolicy.js";
import WhyNoorCapital from "./components/pages/about_WhyNoorCapital.js";
import ContactUs from "./components/pages/about_ContactUs.js";
import Careers from "./components/pages/about_Careers.js";

// Services
import GlobalFinancialAdvisory from "./components/pages/services_GlobalFinancialAdvisory.js";
import AssetManagement from "./components/pages/services_AssetManagement.js";
import Investment from "./components/pages/services_Investment.js";
import FundsAdministration from "./components/pages/services_FundsAdministration.js";

// Trading Instruments
import Instruments_Forex from "./components/pages/trading_Instruments_Forex.js";
import Instruments_PreciousMetals from "./components/pages/trading_Instruments_PreciousMetals.js";
import Instruments_Energies from "./components/pages/trading_Instruments_Energies.js";
import Instruments_Indices from "./components/pages/trading_Instruments_Indices.js";
import Instruments_SharesCfds from "./components/pages/trading_Instruments_SharesCfds.js";
import Instruments_Bullion from "./components/pages/trading_Instruments_Bullion.js";
import Instruments_CashEquities from "./components/pages/trading_Instruments_CashEquities.js";

import Instruments_ForexAr from "./components/pages/trading_Instruments_ForexAr";
import Instruments_PreciousMetalsAr from "./components/pages/trading_Instruments_PreciousMetalsAr";
import Instruments_EnergiesAr from "./components/pages/trading_Instruments_EnergiesAr.js";
import Instruments_IndicesAr from "./components/pages/trading_Instruments_IndicesAr.js";
import Instruments_SharesCfdsAr from "./components/pages/trading_Instruments_SharesCfdsAr.js";
import Instruments_BullionAr from "./components/pages/trading_Instruments_BullionAr.js";
import Instruments_CashEquitiesAr from "./components/pages/trading_Instruments_CashEquitiesAr.js";

// Trading MT4
import Metatrader4 from "./components/pages/trading_Metatrader4.js";
import Metatrader4_OpenLiveAccount from "./components/pages/trading_Metatrader4_OpenLiveAccount.js";
import Metatrader4_OpenDemoAccount from "./components/pages/trading_Metatrader4_OpenDemoAccount.js";
import Metatrader4_MetaTrader4Mobile from "./components/pages/trading_Metatrader4_MetaTrader4Mobile.js";
import Metatrader4_MetaTrader4Desktop from "./components/pages/trading_Metatrader4_MetaTrader4Desktop.js";
import Metatrader4_MetaTrader4Mac from "./components/pages/trading_Metatrader4_MetaTrader4Mac.js";
import Metatrader4_WebTrader from "./components/pages/trading_Metatrader4_WebTrader.js";


// AR

import Metatrader4_MetaTrader4MobileAr from "./components/pages/trading_Metatrader4_MetaTrader4MobileAr.js";
import Metatrader4_MetaTrader4DesktopAr from "./components/pages/trading_Metatrader4_MetaTrader4DesktopAr.js";
import Metatrader4_MetaTrader4MacAr from "./components/pages/trading_Metatrader4_MetaTrader4MacAr.js";
import Metatrader4_WebTraderAr from "./components/pages/trading_Metatrader4_WebTraderAr.js";

import Campaign_OpenDemoAccount from "./components/pages/campaign_OpenDemoAccount.js";

// Trading MT5 EN
import Metatrader5 from "./components/pages/trading_Metatrader5.js";


// Trading GTN
import GTN from "./components/pages/trading_GTN.js";

import GTN_OpenDemoAccount from "./components/pages/trading_GTN_OpenDemoAccount.js";
import GTN_OpenLiveAccount from "./components/pages/trading_GTN_OpenLiveAccount.js";

import GTN_GTNMobile from "./components/pages/trading_GTN_GTNMobile.js";
import GTN_GTNWebtrader from "./components/pages/trading_GTN_GTNWebtrader.js";

import GTNAr from "./components/pages/trading_GTNAr.js";
import GTN_OpenLiveAccountAr from "./components/pages/trading_GTN_OpenLiveAccountAr.js";
import GTN_OpenDemoAccountAr from "./components/pages/trading_GTN_OpenDemoAccountAr.js";

import GTN_GTNMobileAr from "./components/pages/trading_GTN_GTNMobileAr.js";
import GTN_GTNWebtraderAr from "./components/pages/trading_GTN_GTNWebtraderAr.js";

//Trading NoorTreader
import NoorTrader from "./components/pages/trading_NoorTrader.js";
import NoorTrader_OpenLiveAccount from "./components/pages/trading_NoorTrader_OpenLiveAccount.js";
import NoorTrader_OpenDemoAccount from "./components/pages/trading_NoorTrader_OpenDemoAccount.js";
import NoorTrader_NoorTraderMobile from "./components/pages/trading_NoorTrader_NoorTraderMobile.js";
import NoorTrader_Webtrader from "./components/pages/trading_NoorTrader_Webtrader.js";

import NoorTraderAr from "./components/pages/trading_NoorTraderAr.js";
import NoorTrader_OpenLiveAccountAr from "./components/pages/trading_NoorTrader_OpenLiveAccountAr.js";
import NoorTrader_OpenDemoAccountAr from "./components/pages/trading_NoorTrader_OpenDemoAccountAr.js";
import NoorTrader_NoorTraderMobileAr from "./components/pages/trading_NoorTrader_NoorTraderMobileAr.js";
import NoorTrader_WebtraderAr from "./components/pages/trading_NoorTrader_WebtraderAr.js";

// Institutional
import Institutional_RequestLive from "./components/pages/trading_Institutional_RequestLive.js";
import Institutional_RequestDemo from "./components/pages/trading_Institutional_RequestDemo.js";

import Institutional_RequestLiveAr from "./components/pages/trading_Institutional_RequestLiveAr.js";
import Institutional_RequestDemoAr from "./components/pages/trading_Institutional_RequestDemoAr.js";

//Trading Accounts
import Accounts_AccountFunding from "./components/pages/trading_Accounts_AccountFunding.js";
import Accounts_TradingConditions from "./components/pages/trading_Accounts_TradingConditions.js";
import Accounts_AccountForms from "./components/pages/trading_Accounts_AccountForms.js";

import Accounts_AccountFundingAr from "./components/pages/trading_Accounts_AccountFundingAr.js";
import Accounts_TradingConditionsAr from "./components/pages/trading_Accounts_TradingConditionsAr.js";
import Accounts_AccountFormsAr from "./components/pages/trading_Accounts_AccountFormsAr.js";

// Partnership
import Institutional_WhiteLabelSolutions from "./components/pages/partnership_Institutional_WhiteLabelSolutions.js";
import Institutional_InstitutionalClients from "./components/pages/partnership_Institutional_InstitutionalClients.js";
import Institutional_AssetManagers from "./components/pages/partnership_Institutional_AssetManagers.js";
import IBProgram_Ib from "./components/pages/partnership_IBProgram_Ib.js";

// Partnership AR
import Institutional_WhiteLabelSolutionsAr from "./components/pages/partnership_Institutional_WhiteLabelSolutionsAr.js";
import Institutional_InstitutionalClientsAr from "./components/pages/partnership_Institutional_InstitutionalClientsAr.js";
import Institutional_AssetManagersAr from "./components/pages/partnership_Institutional_AssetManagerAr.js";
import IBProgram_IbAr from "./components/pages/partnership_IBProgram_IbAr.js";

// Noor Acadmey
import NoorAcademy from "./components/pages/education_NoorAcademy.js";

import NoorAcademyAr from "./components/pages/education_NoorAcademyAr.js";

// BLog
import BLog from "./components/pages/blog.js";
import Blog_Details from "./components/pages/blog_Details.js";

import BLogAr from "./components/pages/blogAr.js";
import Blog_DetailsAr from "./components/pages/blog_DetailsAr.js";

// Media Cneter - Press Release EN
import PressRelease from "./components/pages/mediaCenter_PressRelease.js";
import PressRelease_Details from "./components/pages/mediaCenter_PressRelease_Details.js";
// Media Cneter - Press Release AR
import PressReleaseAr from "./components/pages/mediaCenter_PressReleaseAr.js";
import PressRelease_DetailsAr from "./components/pages/mediaCenter_PressRelease_DetailsAr.js";


// Media Cneter - Awards EN
import Awards from "./components/pages/mediaCenter_Awards.js";
import Awards_Details from "./components/pages/mediaCenter_Awards_Details.js";
// Media Cneter - Awards AR
import AwardsAr from "./components/pages/mediaCenter_AwardsAr.js";
import Awards_DetailsAr from "./components/pages/mediaCenter_Awards_DetailsAr.js";


// Media Cneter - Activities EN
import Activities from "./components/pages/mediaCenter_Activities.js";
import Activities_Details from "./components/pages/mediaCenter_Activities_Details.js";
// Media Cneter - Activities AR
import ActivitiesAr from "./components/pages/mediaCenter_ActivitiesAr.js";
import Activities_DetailsAr from "./components/pages/mediaCenter_Activities_DetailsAr.js";



// Trading Tools - Noor Trends
import NoorTrends_OnlineWebinar from "./components/pages/tradingTools_NoorTrends_OnlineWebinar.js";
import NoorTrends_MarketUpdate from "./components/pages/tradingTools_NoorTrends_MarketUpdate.js";
import NoorTrends_TechnicalAnalysis from "./components/pages/tradingTools_NoorTrends_TechnicalAnalysis.js";
import NoorTrends_EconomicReport from "./components/pages/tradingTools_NoorTrends_EconomicReport.js";
import NoorTrends_DailyBroadCasting from "./components/pages/tradingTools_NoorTrends_DailyBroadCasting.js";
import Tools_VideoTutorials from "./components/pages/tradingTools_Tools_VideoTutorials.js";

import NoorTrends_TechnicalAnalysisAr from "./components/pages/tradingTools_NoorTrends_TechnicalAnalysisAr.js";

import NoorTrends_OnlineWebinarAr from "./components/pages/tradingTools_NoorTrends_OnlineWebinarAr.js";
import NoorTrends_MarketUpdateAr from "./components/pages/tradingTools_NoorTrends_MarketUpdateAr.js";
import NoorTrends_EconomicReportAr from "./components/pages/tradingTools_NoorTrends_EconomicReportAr.js";
import NoorTrends_DailyBroadCastingAr from "./components/pages/tradingTools_NoorTrends_DailyBroadCastingAr.js";
import Tools_VideoTutorialsAr from "./components/pages/tradingTools_Tools_VideoTutorialsAr.js";

// Trading Tools - Tools
import Tools_EconomicCalendar from "./components/pages/tradingTools_Tools_EconomicCalendar.js";
import Tools_TradingSignals from "./components/pages/tradingTools_Tools_TradingSignals.js";
import Tools_TradersGlossary from "./components/pages/tradingTools_Tools_TradersGlossary.js";
import Tools_RiskDisclosure from "./components/pages/tradingTools_Tools_RiskDisclosure.js";

import Tools_EconomicCalendarAr from "./components/pages/tradingTools_Tools_EconomicCalendarAr.js";

// Support
import Faqs from "./components/pages/support_Faqs.js";
import TechnicalSupport from "./components/pages/support_TechnicalSupport.js";
import CallBackRequest from "./components/pages/support_CallBackRequest.js";

//Website
import TermsandConditions from "./components/pages/website_TermsandConditions.js";
import PrivacyPolicy from "./components/pages/website_PrivacyPolicy.js";
import CookiesPolicy from "./components/pages/website_CookiesPolicy.js";
import FraudWarning from "./components/pages/website_FraudWarning.js";
import SiteMap from "./components/pages/website_SiteMap.js";
import Page404 from "./components/pages/website_404.js";
import UnderConstruction from "./components/pages/website_UnderConstruction.js";

import SocialShare from "./components/extras/socialShare.js";
import StickyButtons from "./components/extras/stickyButtons.js";
import UpFooter from "./components/footer/upFooter.js";

import LanguageContext from "./language-context";
import LanguageSwitcher from "./LanguageSwitcher";

import DirectionProvider, {
  DIRECTIONS,
} from "react-with-direction/dist/DirectionProvider";

// ARABIC ARABIC ARABIC ARABIC

import HomeAr from "./components/home/homeAr";
import HeaderAr from "./components/header/headerAr";
import AboutUsAr from "./components/pages/about_AboutUsAr";
import StickyButtonsAr from "./components/extras/stickyButtonsAr";
import FooterAr from "./components/footer/footerAr";
import UpFooterAr from "./components/footer/upFooterAr";
import ChairmanMessageAr from "./components/pages/about_ChairmanMessageAr";
import ManagementTeamAr from "./components/pages/about_ManagementTeamAr";
import BoardMembersAr from "./components/pages/about_BoardMembersAr.js";
import LicenseAndRegulationsAr from "./components/pages/about_LicenseAndRegulationsAr";
import WhyNoorCapitalAr from "./components/pages/about_WhyNoorCapitalAr";
import TermsandConditionsAr from "./components/pages/website_TermsandConditionsAr";
import SiteMapAr from "./components/pages/website_SiteMapAr";
import PrivacyPolicyAr from "./components/pages/website_PrivacyPolicyAr";
import CookiesPolicyAr from "./components/pages/website_CookiesPolicyAr";
import UnderConstructionAr from "./components/pages/website_UnderConstructionAr";
import ContactUsAr from "./components/pages/about_ContactUsAr";
import CareersAr from "./components/pages/about_CareersAr";
import AssetManagementAr from "./components/pages/services_AssetManagementAr";
import FundsAdministrationAr from "./components/pages/services_FundsAdministrationAr";
import InvestmentAr from "./components/pages/services_InvestmentAr.js";
import GlobalFinancialAdvisoryAr from "./components/pages/services_GlobalFinancialAdvisoryAr";
import Tools_TradersGlossaryAr from "./components/pages/tradingTools_Tools_TradersGlossaryAr";
// import NoorTrends_TechnicalAnalysisAr from "./components/pages/tradingTools_NoorTrends_TechnicalAnalysisAr.js";
import Metatrader4_OpenDemoAccountAr from "./components/pages/trading_Metatrader4_OpenDemoAccountAr";
import Tools_TradingSignalsAr from "./components/pages/tradingTools_Tools_TradingSignalsAr.js";

import Metatrader4Ar from "./components/pages/trading_Metatrader4Ar";
import AntiMoneyLaunderingPolicyAr from "./components/pages/about_AntiMoneyLaunderingPolicyAr";
// import BoardMembersAr from "./components/pages/about_BoardMembersAr";
import Tools_RiskDisclosureAr from "./components/pages/tradingTools_Tools_RiskDisclosureAr.js";

// Support
// import FaqsAr from "./components/pages/support_FaqsAr.js";
import FaqsAr from "./components/pages/support_FaqsAr";
import TechnicalSupportAr from "./components/pages/support_TechnicalSupportAr.js";
import CallBackRequestAr from "./components/pages/support_CallBackRequestAr.js";

import FraudWarningAr from "./components/pages/website_FraudWarningAr.js";
import { setStorage } from "./utils/helpers";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["en"]);

  const pathArray = window.location.pathname.split("/");

  const languageSegment = pathArray[1];

  const [language, setLanguage] = useState(languageSegment);

  const setLan = (value) => {
    setCookie("language", value, { path: "/" });
    setLanguage(value);
  };

  if (languageSegment === "ar") {
    setCookie("language", "ar", { path: "/" });
  }
  if (languageSegment != "ar") {
    setCookie("language", "en", { path: "/" });
  }

  const value = { language, setLanguage };

  const { search } = window.location;
  let queryParams = {};
  if (search !== "") {
    queryParams = queryString.parse(search);
    setStorage("queryParams", queryParams);
  }

  return (
    <LanguageContext.Provider value={value}>
      <div>
        <Router>
          <ScrollToTop>
            {languageSegment != "ar" ? (
              <DirectionProvider direction={DIRECTIONS.LTR}>
                <>
                  <Header />
                  <Switch>
                    <Route exact path="/" component={Home} />

                    {/* About */}
                    <Route path="/about/about-us" component={AboutUs} />

                    <Route
                      path="/about/chairman-message"
                      component={ChairmanMessage}
                    />
                    <Route
                      path="/about/management-team"
                      component={ManagementTeam}
                    />
                    <Route
                      path="/about/board-members"
                      component={BoardMembers}
                    />
                    <Route
                      path="/about/license-regulations"
                      component={LicenseAndRegulations}
                    />
                    <Route
                      path="/about/anti-money-laundering-policy"
                      component={AntiMoneyLaunderingPolicy}
                    />
                    <Route
                      path="/about/why-noor-capital"
                      component={WhyNoorCapital}
                    />
                    <Route path="/about/contact-us" component={ContactUs} />
                    <Route path="/about/careers" component={Careers} />

                    {/* Services */}
                    <Route
                      path="/services/global-financial-advisory"
                      component={GlobalFinancialAdvisory}
                    />
                    <Route
                      path="/services/asset-management"
                      component={AssetManagement}
                    />
                    <Route path="/services/investment" component={Investment} />
                    <Route
                      path="/services/funds-administration"
                      component={FundsAdministration}
                    />

                    {/* Trading Instruments */}
                    <Route
                      path="/trading/instruments/forex"
                      component={Instruments_Forex}
                    />
                    <Route
                      path="/trading/instruments/precious-metals"
                      component={Instruments_PreciousMetals}
                    />
                    <Route
                      path="/trading/instruments/energies"
                      component={Instruments_Energies}
                    />
                    {/* Old Energies URL   */}
                    {/* <Route
                      path="/markets/en/energies/"
                      component={Instruments_Energies}
                    /> */}

                    <Route
                      path="/trading/instruments/indices"
                      component={Instruments_Indices}
                    />
                    <Route
                      path="/trading/instruments/shares-cfds"
                      component={Instruments_SharesCfds}
                    />

                    <Route
                      path="/trading/instruments/bullion"
                      component={Instruments_Bullion}
                    />

                    <Route
                      path="/trading/instruments/cash-equities"
                      component={Instruments_CashEquities}
                    />

                    {/* Trading MT4 */}
                    <Route
                      path="/trading-platforms/metatrader4-platform"
                      component={Metatrader4}
                    />
                    <Route
                      path="/trading-platforms/mt4-open-forex-live"
                      component={Metatrader4_OpenLiveAccount}
                    />
                    <Route
                      path="/trading-platforms/mt4-open-forex-demo"
                      component={Metatrader4_OpenDemoAccount}
                    />

                    {/* CAMPAIGN PAGE 1 */}
                    <Route
                      path="/open-forex-trading-demo"
                      component={Campaign_OpenDemoAccount}
                    />


                    <Route
                      path="/trading-platforms/metatrader4/mt4-mobile"
                      component={Metatrader4_MetaTrader4Mobile}
                    />
                    <Route
                      path="/trading-platforms/metatrader4/mt4-desktop"
                      component={Metatrader4_MetaTrader4Desktop}
                    />
                    <Route
                      path="/trading-platforms/metatrader4/mt4-mac"
                      component={Metatrader4_MetaTrader4Mac}
                    />
                    <Route
                      path="/trading-platforms/metatrader4/mt4-web-trader"
                      component={Metatrader4_WebTrader}
                    />

                    {/* Trading MT5 */}
                    <Route
                      path="/trading-platforms/metatrader5-platform"
                      component={Metatrader5}
                    />

                    {/* Trading GTN */}
                    <Route
                      path="/stocks-trading-platforms/gtn"
                      component={GTN}
                    />
                    <Route
                      path="/stocks-trading-platforms/open-stocks-demo"
                      component={GTN_OpenDemoAccount}
                    />

                    <Route
                      path="/stocks-trading-platforms/open-gtn-live"
                      component={GTN_OpenLiveAccount}
                    />

                    <Route
                      path="/stocks-trading-platforms/gtn-mobile"
                      component={GTN_GTNMobile}
                    />
                    <Route
                      path="/stocks-trading-platforms/gtn-web"
                      component={GTN_GTNWebtrader}
                    />

                    {/* Trading NoorTreader*/}
                    <Route
                      path="/physical-trading-platforms/noor-trader"
                      component={NoorTrader}
                    />
                    <Route
                      path="/physical-trading-platforms/open-noor-trader-live"
                      component={NoorTrader_OpenLiveAccount}
                    />
                    <Route
                      path="/physical-trading-platforms/open-noor-trader-demo"
                      component={NoorTrader_OpenDemoAccount}
                    />
                    <Route
                      path="/physical-trading-platforms/noor-trader-mobile"
                      component={NoorTrader_NoorTraderMobile}
                    />
                    <Route
                      path="/physical-trading-platforms/noor-trader-web"
                      component={NoorTrader_Webtrader}
                    />

                    {/* Institutional */}
                    <Route
                      path="/institutional/request-live"
                      component={Institutional_RequestLive}
                    />

                    <Route
                      path="/institutional/request-demo"
                      component={Institutional_RequestDemo}
                    />

                    {/* Trading Accounts*/}
                    <Route
                      path="/trading/accounts/account-funding"
                      component={Accounts_AccountFunding}
                    />
                    <Route
                      path="/trading/accounts/trading-conditions"
                      component={Accounts_TradingConditions}
                    />
                    <Route
                      path="/trading/accounts/account-forms"
                      component={Accounts_AccountForms}
                    />

                    {/* Partnership */}
                    <Route
                      path="/partnership/white-label-solutions"
                      component={Institutional_WhiteLabelSolutions}
                    />
                    <Route
                      path="/partnership/institutional-clients"
                      component={Institutional_InstitutionalClients}
                    />
                    <Route
                      path="/partnership/asset-managers"
                      component={Institutional_AssetManagers}
                    />
                    <Route
                      path="/partnership/marketing-partnership-program"
                      component={IBProgram_Ib}
                    />

                    {/* Noor Acadmey */}
                    <Route
                      path="/education/noor-academy"
                      component={NoorAcademy}
                    />

                    {/* Blog EN*/}
                    <Route path="/blog" component={BLog} />

                    <Route path="/nc-blog/:postId" component={Blog_Details} />

                    {/* Media Center Press Release EN*/}
                    <Route
                      path="/media-center/press-release"
                      component={PressRelease}
                    />
                    <Route
                      path="/press-release/:postId"
                      component={PressRelease_Details}
                    />

                    {/* Media Center Awards EN*/}
                    <Route path="/media-center/awards" component={Awards} />
                    <Route path="/awards/:postId" component={Awards_Details} />

                    {/* Media Center Activities EN*/}
                    <Route
                      path="/media-center/activities"
                      component={Activities}
                    />
                    <Route
                      path="/activities/:postId"
                      component={Activities_Details}
                    />

                    {/* Trading Tools - Noor Trends */}
                    <Route
                      path="/trading-tools/noor-trends-online-webinar"
                      component={NoorTrends_OnlineWebinar}
                    />
                    <Route
                      path="/trading-tools/noor-trends-market-update"
                      component={NoorTrends_MarketUpdate}
                    />
                    <Route
                      path="/trading-tools/noor-trends-technical-analysis"
                      component={NoorTrends_TechnicalAnalysis}
                    />
                    <Route
                      path="/trading-tools/noor-trends-economic-reports"
                      component={NoorTrends_EconomicReport}
                    />
                    <Route
                      path="/trading-tools/noor-trends-daily-broadcasting"
                      component={NoorTrends_DailyBroadCasting}
                    />
                    {/* Trading Tools - Tools */}
                    <Route
                      path="/trading-tools/video-tutorials"
                      component={Tools_VideoTutorials}
                    />
                    <Route
                      path="/trading-tools/economic-calendar"
                      component={Tools_EconomicCalendar}
                    />
                    <Route
                      path="/trading-tools/trading-signals"
                      component={Tools_TradingSignals}
                    />
                    <Route
                      path="/trading-tools/traders-glossary"
                      component={Tools_TradersGlossary}
                    />
                    <Route
                      path="/trading-tools/risk-disclosure"
                      component={Tools_RiskDisclosure}
                    />

                    {/* Website  */}
                    <Route
                      path="/terms-conditions"
                      component={TermsandConditions}
                    />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/cookies-policy" component={CookiesPolicy} />
                    <Route path="/fraud-warning" component={FraudWarning} />
                    <Route path="/site-map" component={SiteMap} />

                    <Route
                      path="/under-construction"
                      component={UnderConstruction}
                    />

                    {/* Support */}
                    <Route path="/support/faqs" component={Faqs} />
                    <Route
                      path="/support/technical-support"
                      component={TechnicalSupport}
                    />
                    <Route
                      path="/support/call-back-request"
                      component={CallBackRequest}
                    />

                    <Route exact path="" component={Page404} />
                  </Switch>

                  <SocialShare />
                  <StickyButtons />
                  <UpFooter />
                  <Footer />
                </>
              </DirectionProvider>
            ) : (
              // Arabic
              <ConfigProvider locale={ar_EG}>
                <DirectionProvider direction={DIRECTIONS.RTL}>
                  <>
                    <HeaderAr />
                    <Switch>
                      <Route exact path="/ar" component={HomeAr} />
                      {/* AR */}

                      {/* About */}
                      <Route path="/ar/about/about-us" component={AboutUsAr} />

                      <Route
                        path="/ar/about/chairman-message"
                        component={ChairmanMessageAr}
                      />

                      <Route
                        path="/ar/about/management-team"
                        component={ManagementTeamAr}
                      />
                      <Route
                        path="/ar/about/board-members"
                        component={BoardMembersAr}
                      />

                      <Route
                        path="/ar/about/license-regulations"
                        component={LicenseAndRegulationsAr}
                      />
                      <Route
                        path="/ar/about/anti-money-laundering-policy"
                        component={AntiMoneyLaunderingPolicy}
                      />
                      <Route
                        path="/ar/about/why-noor-capital"
                        component={WhyNoorCapitalAr}
                      />
                      <Route
                        path="/ar/about/contact-us"
                        component={ContactUsAr}
                      />
                      <Route path="/ar/about/careers" component={CareersAr} />

                      {/* Services */}
                      <Route
                        path="/ar/services/global-financial-advisory"
                        component={GlobalFinancialAdvisoryAr}
                      />
                      <Route
                        path="/ar/services/asset-management"
                        component={AssetManagementAr}
                      />
                      <Route
                        path="/ar/services/investment"
                        component={InvestmentAr}
                      />
                      <Route
                        path="/ar/services/funds-administration"
                        component={FundsAdministrationAr}
                      />

                      {/* Trading Instruments */}
                      <Route
                        path="/ar/trading/instruments/forex"
                        component={Instruments_ForexAr}
                      />
                      <Route
                        path="/ar/trading/instruments/precious-metals"
                        component={Instruments_PreciousMetalsAr}
                      />
                      <Route
                        path="/ar/trading/instruments/energies"
                        component={Instruments_EnergiesAr}
                      />
                      <Route
                        path="/ar/trading/instruments/indices"
                        component={Instruments_IndicesAr}
                      />
                      <Route
                        path="/ar/trading/instruments/shares-cfds"
                        component={Instruments_SharesCfdsAr}
                      />

                      <Route
                        path="/ar/trading/instruments/bullion"
                        component={Instruments_BullionAr}
                      />

                      <Route
                        path="/ar/trading/instruments/cash-equities"
                        component={Instruments_CashEquitiesAr}
                      />

                      {/* Trading MT4 */}
                      <Route
                        path="/ar/trading-platforms/metatrader4-platform"
                        component={Metatrader4Ar}
                      />
                      <Route
                        path="/ar/trading-platforms/mt4-open-forex-live"
                        component={Metatrader4_OpenLiveAccount}
                      />
                      <Route
                        path="/ar/trading-platforms/mt4-open-forex-demo"
                        component={Metatrader4_OpenDemoAccountAr}
                      />
                      <Route
                        path="/ar/trading-platforms/metatrader4/mt4-mobile"
                        component={Metatrader4_MetaTrader4MobileAr}
                      />
                      <Route
                        path="/ar/trading-platforms/metatrader4/mt4-desktop"
                        component={Metatrader4_MetaTrader4DesktopAr}
                      />
                      <Route
                        path="/ar/trading-platforms/metatrader4/mt4-mac"
                        component={Metatrader4_MetaTrader4MacAr}
                      />
                      <Route
                        path="/ar/trading-platforms/metatrader4/mt4-web-trader"
                        component={Metatrader4_WebTraderAr}
                      />

                      {/* Trading GTN */}
                      <Route
                        path="/ar/stocks-trading-platforms/gtn"
                        component={GTNAr}
                      />

                      <Route
                        path="/ar/stocks-trading-platforms/open-stocks-demo"
                        component={GTN_OpenDemoAccountAr}
                      />

                      <Route
                        path="/ar/stocks-trading-platforms/open-gtn-live"
                        component={GTN_OpenLiveAccountAr}
                      />
                      <Route
                        path="/ar/stocks-trading-platforms/gtn-mobile"
                        component={GTN_GTNMobileAr}
                      />
                      <Route
                        path="/ar/stocks-trading-platforms/gtn-web"
                        component={GTN_GTNWebtraderAr}
                      />

                      {/* Trading NoorTreader*/}
                      <Route
                        path="/ar/physical-trading-platforms/noor-trader"
                        component={NoorTraderAr}
                      />
                      <Route
                        path="/ar/physical-trading-platforms/open-noor-trader-live"
                        component={NoorTrader_OpenLiveAccountAr}
                      />
                      <Route
                        path="/ar/physical-trading-platforms/open-noor-trader-demo"
                        component={NoorTrader_OpenDemoAccountAr}
                      />
                      <Route
                        path="/ar/physical-trading-platforms/noor-trader-mobile"
                        component={NoorTrader_NoorTraderMobileAr}
                      />
                      <Route
                        path="/ar/physical-trading-platforms/noor-trader-web"
                        component={NoorTrader_WebtraderAr}
                      />

                      {/* Institutional */}
                      <Route
                        path="/ar/institutional/request-live"
                        component={Institutional_RequestLiveAr}
                      />

                      <Route
                        path="/ar/institutional/request-demo"
                        component={Institutional_RequestDemoAr}
                      />

                      {/* Trading Accounts*/}
                      <Route
                        path="/ar/trading/accounts/account-funding"
                        component={Accounts_AccountFundingAr}
                      />
                      <Route
                        path="/ar/trading/accounts/trading-conditions"
                        component={Accounts_TradingConditionsAr}
                      />
                      <Route
                        path="/ar/trading/accounts/account-forms"
                        component={Accounts_AccountFormsAr}
                      />

                      {/* Partnership */}
                      <Route
                        path="/ar/partnership/white-label-solutions"
                        component={Institutional_WhiteLabelSolutionsAr}
                      />
                      <Route
                        path="/ar/partnership/institutional-clients"
                        component={Institutional_InstitutionalClientsAr}
                      />
                      <Route
                        path="/ar/partnership/asset-managers"
                        component={Institutional_AssetManagersAr}
                      />
                      <Route
                        path="/ar/partnership/marketing-partnership-program"
                        component={IBProgram_IbAr}
                      />

                      {/* Trading Tools - Noor Trends */}
                      <Route
                        path="/ar/trading-tools/noor-trends-online-webinar"
                        component={NoorTrends_OnlineWebinarAr}
                      />
                      <Route
                        path="/ar/trading-tools/noor-trends-market-update"
                        component={NoorTrends_MarketUpdateAr}
                      />
                      <Route
                        path="/ar/trading-tools/noor-trends-technical-analysis"
                        component={NoorTrends_TechnicalAnalysisAr}
                      />
                      <Route
                        path="/ar/trading-tools/noor-trends-economic-reports"
                        component={NoorTrends_EconomicReportAr}
                      />
                      <Route
                        path="/ar/trading-tools/noor-trends-daily-broadcasting"
                        component={NoorTrends_DailyBroadCastingAr}
                      />
                      {/* Trading Tools - Tools */}
                      <Route
                        path="/ar/trading-tools/video-tutorials"
                        component={Tools_VideoTutorialsAr}
                      />
                      <Route
                        path="/ar/trading-tools/economic-calendar"
                        component={Tools_EconomicCalendarAr}
                      />
                      <Route
                        path="/ar/trading-tools/trading-signals"
                        component={Tools_TradingSignalsAr}
                      />
                      <Route
                        path="/ar/trading-tools/traders-glossary"
                        component={Tools_TradersGlossaryAr}
                      />
                      <Route
                        path="/ar/trading-tools/risk-disclosure"
                        component={Tools_RiskDisclosureAr}
                      />

                      {/* Noor Acadmey */}
                      <Route
                        path="/ar/education/noor-academy"
                        component={NoorAcademyAr}
                      />

                      {/* Blog AR */}
                      <Route path="/ar/blog" component={BLogAr} />
                      <Route
                        path="/ar/nc-blog/:postId"
                        component={Blog_DetailsAr}
                      />

                      {/* Media Center Press Release AR*/}
                      <Route
                        path="/ar/media-center/press-release"
                        component={PressReleaseAr}
                      />
                      <Route
                        path="/ar/press-release/:postId"
                        component={PressRelease_DetailsAr}
                      />

                      {/* Media Center Awards AR*/}
                      <Route
                        path="/ar/media-center/awards"
                        component={AwardsAr}
                      />
                      <Route
                        path="/ar/awards/:postId"
                        component={Awards_DetailsAr}
                      />

                      {/* Media Center Activities AR */}
                      <Route
                        path="/ar/media-center/activities"
                        component={ActivitiesAr}
                      />
                      <Route
                        path="/ar/activities/:postId"
                        component={Activities_DetailsAr}
                      />

                      {/* Website  */}
                      <Route
                        path="/ar/terms-conditions"
                        component={TermsandConditionsAr}
                      />
                      <Route
                        path="/ar/privacy-policy"
                        component={PrivacyPolicyAr}
                      />
                      <Route
                        path="/ar/cookies-policy"
                        component={CookiesPolicyAr}
                      />
                      <Route
                        path="/ar/fraud-warning"
                        component={FraudWarningAr}
                      />
                      <Route path="/ar/site-map" component={SiteMapAr} />
                      <Route
                        path="/ar/under-construction"
                        component={UnderConstructionAr}
                      />

                      {/* Support */}
                      <Route path="/ar/support/faqs" component={FaqsAr} />
                      <Route
                        path="/ar/support/technical-support"
                        component={TechnicalSupportAr}
                      />
                      <Route
                        path="/ar/support/call-back-request"
                        component={CallBackRequestAr}
                      />

                      <Route exact path="" component={Page404} />
                    </Switch>

                    <SocialShare />
                    <StickyButtonsAr />
                    <UpFooterAr />
                    <FooterAr />
                  </>
                </DirectionProvider>
              </ConfigProvider>
            )}
          </ScrollToTop>
        </Router>
      </div>
    </LanguageContext.Provider>
  );
}

export default App;
