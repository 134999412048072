import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/technical.jpg";
import RequestCallBackForm from "../forms/requestCallBackForm";



const style = { padding: "50px 0" };

const CallBackRequest = () => {
  return (
    <div>
      <PageseMetaTags
        title="Request Call Back from Noor Capital"
        description="Have A question or inquiries related to Noor Capital's products or services, request a call back now and our specialist will be in touch"
      />
      <BgBefore
        pageHeadingTitle="Request a Call Back"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Support"
        pageSubCategoryPath="#"
        pageName="Request a Call Back"
      />

      <section
        className="fdb-block pt-0"
        style={{ paddingBottom: "10px", backgroundColor: "#fff" }}
      >
        <br />

        <div className={globalstyle.container}>
          <div className="row-100" />
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <p className={globalstyle.parjustify}>
                Do You Have A Question or inquiries related to Noor Capital's
                products or services?
              </p>

              {/* General inquiries */}
              <p className="h3 mt-4 mt-lg-6">
                <h3 style={{ color: "#1b75bc" }}> General Inquiries </h3>
              </p>
              <p>
                <i
                  class="far fa-envelope"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="mailto:info@noorcapital.ae">info@noorcapital.ae</a>
              </p>

              {/* Customer Services */}
              <p className="h3 mt-4 mt-lg-6">
                <h3 style={{ color: "#1b75bc" }}> Customer Services </h3>
              </p>
              <p>
                <i
                  class="far fa-envelope"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="mailto:cs@noorcapital.ae">cs@noorcapital.ae</a>
              </p>
              <p>
                <i
                  class="fas fa-phone-volume"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">+97142795400</a>
              </p>
             
              {/* Onboarding */}
              <p className="h3 mt-4 mt-lg-6">
                <h3 style={{ color: "#1b75bc" }}> Onboarding </h3>
              </p>
              <p>
                <i
                  class="far fa-envelope"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="mailto:onboarding@noorcapital.ae">
                  onboarding@noorcapital.ae
                </a>
              </p>
              <p>
                <i
                  class="fas fa-phone-volume"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">+97142795400</a>
              </p>
              
            </div>
            <div className="col-12 col-md-6 ml-auto">
              <h6 style={{ color: "#1b75bc" }}>
                Please fill up the Call Back Form Below, And One of our agents
                will get in touch within 48 hours
              </h6>
              <br />
              
<RequestCallBackForm />

            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <br />
    </div>
  );
};

export default CallBackRequest;
