import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgpartnershipbanner4Ar.jpg"
import OpenNoorClearingDemoFormAr from "../forms/openNoorClearingDemoFormAr.js";

const { Title } = Typography;

const notes = [
  'الوسطاء والبنوك',
 'صناديق التحوط ومديري الأصول',
 'أصحاب بيوت التجارة',
 'حسابات الصناديق والشركات',
 ];

const style = {padding: '50px 0' };

const Institutional_RequestDemo = () => {
  return( <div>
          <PageseMetaTags
                title="Noor Clearing Tier 1 liquidity - request demo"
                description="Empowering You to Deliver Superior Trading Services"
            />

            <BgBeforeAr 
            pageHeadingTitle="نمكنك من تقديم خدمات تداول فائقة"
            pageHeadingDescription="سيولة من الدرجة الأولى بين البنوك"
            pageHeadingbg={img1}
            
            
            />
        <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="المؤسسات"
        pageSubCategoryPath="#"
        pageName="فتح حساب تجريبي"
      />



<div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          طلب حساب تجريبي للمؤسسات
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              
            }}
          >
            <p className="parjustify">
            تقدم نور كليرنيك أفضل إستفادة وذلك من خلال خبرة وتقنية نور كابيتال الإقليمية لتزويدك بأحدث حلول السيولة والوساطة المالية.
            </p>
            <p className="parjustify">
            رسالتنا هي أن نصبح وسيطًا رئيسيًا إقليميًا من خلال تقديم مجمعات السيولة بين البنوك من المستوى 1 الحائزة على جوائز عبر أحدث التقنيات الخاصة بنا إلى عملاء المؤسسات، بدءًا من وسطاء الفوركس إلى البنوك ومديري الصناديق.
            </p>

            <Divider orientation="left"><Title level={3} style={{color: "#1b75bc" }}>مجموعة واسعة من الحلول</Title></Divider>

            <List
      size="small"
      bordered
      className="text-right"
      dataSource={notes}
      renderItem={item => 
        <List.Item><i class="fas fa-angle-double-left" style={{ fontSize: "16px", marginLeft: "10px" , color: "#1b75bc" }}></i>{item}</List.Item>
    }
    />

          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            
          >
             <OpenNoorClearingDemoFormAr />
          </Col>
        </Row>
      </div>
      
      
      <br/>
      <br/>
      
      <style jsx>{
            `
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd){
              background-color: #f1f1f1;
          }

          
            
            `
        }
        </style>



  </div>
  )

};

export default Institutional_RequestDemo;

