import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import technical from "../../assets/images/pages/tools/technical.png";
import img1 from "../../assets/images/bgtoolsAr.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
'يُطبق التحليل الفني من قبل فريق من الخبراء المعتمدين والمحترفين للغاية من جميع أنحاء العالم.',
'فرصة للتواصل مع كبير المحللين.',
'التحليل الفني للتداول المركزي هو أداة أساسية لصنع القرار تسمح لك بتحديد فرص الاستثمار الأصلية.',
'برنامج تحليلي قوي.',
'فرصة لزيادة الربح وتقليل المخاطر مع مجموعتنا الواسعة من الأدوات المستخدمة في التحليل الفني.',
];
const { Title } = Typography;

const NoorTrends_TechnicalAnalysisAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Forex Technical Analysis - Noor Capital For Trading"
        description="The Noor Capital market research team of professionals provides clients with the latest updates and news on market price movements and trends by posting daily technical analysis and charts on the major currency pairs."
      />

      <BgBeforeAr
        pageHeadingTitle="احصل على دراسات السوق من محلل معتمد ومحترف"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="ادوات التداول"
        pageSubCategoryPath="#"
        pageName=" التحليلات والتقارير الفنية"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          فوركس التحليل الفني
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            تقدم نور كابيتال نفسها لخدمة مستثمريها بأفضل الأدوات الممكنة لتطوير خبرتهم التداولية وجعلهم في صدارة المنافسة. ومن أجل تحقيق هذا الهدف، نستخدم طريقة التحليل الفني لتقييم الاستثمار وفرص التداول الفوري.
            </p>

            <p className="parjustify text-right">
            التحليل الفني هو دراسة البيانات التاريخية للأصول المالية التي تتنبأ بحركة الأسعار المستقبلية. بهذه الطريقة، تحصل على فرصة لزيادة أرباحك وتقليل المخاطر.
            </p>

            
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={technical}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          الميزات والفوائد 
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              احصل على التحليل الفني الآن
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/en/category/tech-analysis/"
                  target="_blank"
                >
                  انقر هنا
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


    
    </div>
  );
};

export default NoorTrends_TechnicalAnalysisAr;
