import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from "rmwc";

// not done >>> need to change to Request Call Back API 
import { BASE_URL, COUNTRIES, DEPARTMENTS, REQUEST_CALL_BACK } from "../../constants";

export default function RequestCallBackFormAr() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "AE",
    department: "",
    message: "",
  };

  const [state, setState] = React.useState(initialState);

  const [countries, setCountries] = React.useState([{ name: "", code: "" }]);

  const [departments, setDepartments] = React.useState([]);

  const [requestSent, setRequestSent] = React.useState(false);
  
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "ar",
  };

  React.useEffect(() => {
    axios
      .all([
        axios.get(BASE_URL + COUNTRIES, { headers: headers }),
        axios.get(BASE_URL + DEPARTMENTS, { headers: headers }),
      ])
      .then(
        axios.spread((countries, departments) => {
          setCountries(countries.data);
          setDepartments(departments.data);
        })
      )
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleCountryChange(event) {
    const newState = { ...state, countries: event.target.value };
    setState(newState);
  }

  function handleDepartmentChange(event) {
    const newState = { ...state, department: event.target.value };
    setState(newState);
  }

  function handleFormChange(event) {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  }

  function submitRequestCallBack(event) {
    event.preventDefault();
    if (state.department === "") {
      const departmentsEl = document.getElementById("departments");
      departmentsEl.autofocus();
      departmentsEl.style.borderColor = "#bb0000";
      return;
    }

    const data = {
      first_name: state.firstName,
      last_name: state.lastName,
      phone: state.phoneNumber,
      email: state.email,
      country: state.country,
      department_id: state.department,
      message: state.message,
    };
    axios
      .post(BASE_URL + REQUEST_CALL_BACK, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          setRequestSent(true);
          setState(initialState);
        }
      });
  }

  return (
    <div>
      <form onSubmit={submitRequestCallBack}>
        <div className="row">

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="الاسم الأول"
              name="firstName"
              value={state.firstName}
              onChange={handleFormChange}
            />
          </div>

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="اسم العائلة"
              name="lastName"
              value={state.lastName}
              onChange={handleFormChange}
            />
          </div>

        </div>


        <div className="row mt-4">
          <div className="col">
            <input
              type="email"
              required
              className="form-control"
              placeholder="البريد الإلكتروني"
              name="email"
              value={state.email}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="رقم الهاتف"
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              onChange={handleCountryChange}
              value={state.country}
            >
              {countries.map((country) => (
                <option value={country.code}>{country.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              id="departments"
              onChange={handleDepartmentChange}
              value={state.department}
            >
              <option value="">حدد القسم</option>
              {departments.map((department) => (
                <option value={department.id}>{department.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <textarea
              className="form-control"
              name="message"
              required
              rows={5}
              placeholder="كيف يمكننا المساعدة؟"
              value={state.message}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>

      </form>

      <Dialog
        body="Your request is sent successfully."
        open={requestSent}
        onClose={(event) => {
          setRequestSent(false);
        }}
      >
        <DialogTitle>تم!</DialogTitle>
        <DialogContent>
          تم إستقبال طلبك بنجاح. <br />
          سيتم التواصل معك قريبا.
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">إغلاق</DialogButton>
        </DialogActions>
      </Dialog>

    </div>
  );
}
