import React from "react";
import { Row, Col } from "antd";

import globalstyle from "../../style.module.css";

import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const PrivacyPolicyAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Privacy Policy - Noor Capital"
        description="Noor Capital is committed to protecting your privacy and maintaining the security of any personal information that we receive from you."
      />

      <BgBeforeAr
        pageHeadingTitle="سياسة الخصوصية"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="سياسة الخصوصية"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>سياسة الخصوصية</h4>
              <p className={globalstyle.parjustify}>
                في نور كابيتال، نؤمن بحماية خصوصية عملائنا والحفاظ على أعلى
                معايير الأمان للمعلومات الشخصية لعملائنا. يرجى قراءة سياسة
                الخصوصية الخاصة بنا حتى النهاية لفهم المعلومات التي نجمعها وكيف
                نستخدمها.
              </p>
            </div>
          </div>

          {/* 22222222222222222 */}

          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>المعلومات الشخصية</h4>
              <p className={globalstyle.parjustify}>
                تتضمن المعلومات الشخصية التي قد نجمعها من عملائنا (على سبيل
                المثال لا الحصر) الاسم الكامل والعنوان البريدي ورقم الهاتف ورقم
                الهاتف المحمول وعنوان البريد الإلكتروني والمعلومات الأخرى التي
                تكون إلزامية لغرض التحقق. يمكن استخدام البيانات الشخصية لعملائنا
                لفتح حسابك معنا وإدارته ومتابعته.
              </p>
            </div>
          </div>

          {/* 3333333333333333333 */}

          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>ملفات تعريف الارتباط</h4>
              <p className={globalstyle.parjustify}>
                يستخدم موقع نور كابيتال "ملفات تعريف الارتباط" وغيرها من
                التقنيات المماثلة المرسلة من خلال متصفحك لتتبع وتعزيز تجربة
                الزوار على موقعنا. يقتصر استخدام ملفات تعريف الارتباط بشكل صارم
                على أغراض التسويق والتحليل فقط، وهو ما يسمح لنا بجمع بعض
                المعلومات حول نوع المتصفح والموقع وعنوان بروتوكول الإنترنت IP،
                والمزيد من ملفات تعريف الارتباط. على الرغم من أن معظم المتصفحات
                معدة لقبول ملفات تعريف الارتباط، إلا أنه يمكنك ضبط متصفحك على
                رفض ملفات تعريف الارتباط إذا كنت تفضل ذلك. يرجى ملاحظة أن عدم
                قبول ملفات تعريف الارتباط قد يؤثر على تجربتك مع الموقع.
              </p>
            </div>
          </div>

          {/* 44444444444444444444 */}

          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>جوجل أناليتكس</h4>
              <p className={globalstyle.parjustify}>
                قد نستخدم أيضًا ميزات جوجل أناليتكس الإعلانية، والتي تسمح لنا
                بجمع المعلومات حول التركيبة السكانية لزوار موقعنا. يمكنك إلغاء
                الاشتراك عن طريق الاتصال بـ Google في أي وقت.
              </p>
            </div>
          </div>

          {/* 5555555555555555555555 */}

          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>الإجراءات الأمنية</h4>
              <p className={globalstyle.parjustify}>
                نحن نمارس إجراءات أمنية صارمة أثناء تخزين وكشف المعلومات المعطاة
                لنا. أي وصول إلى معلوماتك الشخصية محمي بالأمان الإلكتروني
                والمادي والإجرائي لحماية أي خسارة أو ضرر أو سوء استخدام.
              </p>
            </div>
          </div>

          {/* 6666666666666666 */}

          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>جمع المعلومات</h4>
              <p className={globalstyle.parjustify}>
                نحن نستخدم المعلومات التي تم جمعها المقدمة من عملائنا فقط
                لتزويدك بطلب الخدمة، وتحديد الهوية، وتحديث آخر الأخبار والعروض.
                يتم منح الوصول فقط لموظفينا أو وكلائنا المعتمدين.
              </p>
            </div>
          </div>

          {/* 77777777777777777 */}

          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>
                التسويق المباشر من قبل أعضاء مجموعة نور كابيتال ش.م.ع
              </h4>
              <p className={globalstyle.parjustify}>
                قد نستخدم أيضًا بياناتك الشخصية مثل الاسم ورقم الهاتف وعنوان
                المنزل والبريد الإلكتروني لإبلاغك بآخر الأخبار والمنتجات
                والخدمات والعروض الترويجية الجديدة والحالية ذات الصلة بالخدمات
                المالية التي قد تهمك لأغراض التسويق المباشر. قد نستخدم أيضًا
                معلومات التتبع عبر الإنترنت للتسويق المباشر. لن نستخدم أي
                معلومات شخصية تم جمعها عبر معلومات التتبع عبر الإنترنت للتسويق
                المباشر. إذا كنت لا ترغب في استخدام بياناتك لأغراض التسويق
                المباشر، فيمكنك دائمًا إلغاء الاشتراك عن طريق الاتصال بنا من
                خلال معلومات الاتصال الواردة على موقعنا الإلكتروني.
              </p>
            </div>
          </div>

          {/* 8888888888888888888 */}

          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>الأطراف الأخرى</h4>
              <p className={globalstyle.parjustify}>
                يرجى ملاحظة أننا قد نكشف عن بعض معلوماتك الشخصية لشركائنا لأغراض
                تجارية وكذلك الموردين الخارجيين الذين يستخدمون بيانات المعالجة
                نيابة عنا. تحتاج جميع هذه الأطراف إلى الحفاظ على سرية بياناتك.
                قد نفصح عن هذه المعلومات للحكومة والسلطات المعنية إذا كان هناك
                طلب منهم للتحقيق في أي خرق للقانون أو القواعد التنظيمية من خلال
                إجراءاتهم.
              </p>
            </div>
          </div>

          {/* 9999999999999999999 */}

          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>إغلاق الحساب</h4>
              <p className={globalstyle.parjustify}>
                إذا كنت ترغب في إغلاق حسابك معنا، فإن شركة نور كابيتال ش.م.ع سوف
                تحتفظ بجميع معلوماتك الشخصية، لكننا سنستخدمها فقط للامتثال
                لمتطلباتنا التنظيمية. سوف تحتفظ نور كابيتال بهذه البيانات فقط
                لفترة لا تزيد عن المدة المسموح بها بموجب القوانين المحلية ذات
                الصلة. وسنتصل بك أيضًا بشكل دوري لإعلامك بالمنتجات والخدمات
                والعروض الترويجية الجديدة التي قد نقدمها من وقت لآخر، ولكن يمكنك
                إلغاء الاشتراك إذا كنت ترغب في ذلك.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicyAr;
