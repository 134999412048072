import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import "./management.css";
import mohammedghosheh from "../../assets/images/team/mohammed-ghosheh.jpg";
import abdullaalsuwaidi from "../../assets/images/team/abdulla-alsuwaidi.jpg";
import muhammadalzoubi from "../../assets/images/team/muhammad-al-zoubi.jpeg";
import sherali from "../../assets/images/team/sherali.jpg";
import adelelhassan from "../../assets/images/team/adelel-hassan.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";


const ManagementTeamAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Management Team - Noor Capital"
        description="The Management Team work together to grow successfully and continuously our reputation over time, becoming a trusted partner to each one of our clients across the range of products and services we offer."
      />
      <BgBeforeAr
        pageHeadingTitle="في نور كابيتال، نؤمن بروح واحدة، فريق واحد، فوز واحد"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
     <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="الإدارة التنفيذية"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
         
          <div className="row text-center justify-content-center" style={{"marginBottom":"20px"}}>
            <div className="col-10">
              
              <h2 className="display-4" style={{"color":"#11131b"}}>فريق إدارة نور كابيتال</h2>
              <p className="lead">
              يتألف فريق نور كابيتال من أشهر المتخصصين في المجال، والخبرة، والحاصلين على شهادات عالية ويعملون بلا كلل لتزويدك بأفضل الحلول لاستثماراتك. يعمل فريقنا باستمرار على تطوير أنفسهم بأحدث اتجاهات الصناعة التي تمكنهم من البقاء على إطلاع جيد بما يحدث في الأسواق ومجهزين للتعامل مع المواقف المعقدة.
              </p>
            </div>
          </div>

{/* 111111111111 */}

          <div className="row justify-content-center text-right" style={{"marginBottom":"10px"}}>
            <div className="row align-items-center">
              <Row>
                <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Mohammed Ghosheh"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={mohammedghosheh}
                    
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ "padding":"20px 30px 10px 0px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>محمد غوشة</h3>
                  <p className="lead">الرئيس التنفيذي</p>
                  <p style={{ textAlign: "justify", lineHeight: "24px", "fontSize":"16px"}}>
                  من خلال تمتعه بأكثر من 30 عامًا من الخبرة في قطاع التمويل، قاد السيد محمد غوشة شركة نور كابيتال منذ إنشائها في عام 2006. لقد عمل بلا كلل من أجل ازدهار الشركة مع إضافة قيمة للعملاء، باعتباره قائدًا شغوفًا وصاحب رؤية. منذ أن عمل كمراقب مالي حتى أصبح الرئيس التنفيذي، كان السيد محمد غوشة قائد حقيقي لشركة نور كابيتال، مما يجعلها أكبر شركة مالية في المنطقة. خلال رحلته مع نور كابيتال، قاد بنجاح العديد من المشروعات الضخمة، وحصل على جائزة دبي للجودة، وقام بالفصل بين أعمال الشركات والتجزئة، وتنفيذ برنامج إدارة العلاقات مع العملاء CRM وأيضًا الحصول على الآيزو ISO 9001-2000. في السابق، عمل السيد محمد غوشة، الذي يتمتع بخبرة هائلة في مجال الصناعة المالية ،كنائب مدير في البنك التجاري في دبي ورئيس محاسبين في مجموعة الروضان في الكويت. كما أنه شغوف دائم بالقطاع المالي، السيد محمد غوشة حاصل على ماجستير إدارة الأعمال في المالية من جامعة لندن، المملكة المتحدة، ودرجة البكالوريوس في إدارة الأعمال والمصارف والتمويل من جامعة اليرموك، الأردن.
                  </p>
                </Col>
                
              </Row>
              
            </div>
          
          </div>

          <hr width="30%" />



{/* 222222222222 */}

{/* 

          <div className="row justify-content-center text-right" style={{"marginBottom":"25px","marginTop":"25px"}}>
            <div className="row align-items-center">
              <Row>
              <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Eng. Abdulla Al Suwaidi"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={abdullaalsuwaidi}
                  />
                </Col>

                <Col
                 xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ "padding":"20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>عبدالله السويدي</h3>
                  <p className="lead">
                  المدير التنفيذي - التخطيط الاستراتيجي والابتكار
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "24px", "fontSize":"16px" }}>
                  عندما يمتزج التفاني والقيادة والرؤية، ينتج عن ذلك نجاح هائل لأي مؤسسة؛ هذا ما يميز السيد عبد الله السويدي، قائد متفاني حقًا يتمتع بخبرة هائلة تمتد لأكثر من20 عامًا في القطاعين الحكومي والخاص.
من كونه عضوًا في مجلس إدارة البنك التجاري الدولي ش.م.ع، الإمارات العربية المتحدة، إلى المدير التنفيذي للتخطيط الاستراتيجي والابتكار في نور كابيتال، حيث يتميز السيد عبد الله السويدي بعدة مهارات مثل الالتزام المطلق والرؤية الواضحة.
بعد أن خدم بنجاح في مناصب إدارية متعددة في شركة أبوظبي للعمليات البترولية البرية (أدكو)، وبلدية أبوظبي، وهيئة الطرق والمواصلات بدبي، ظل السيد عبدالله السويدي يعمل بشكل مكثف من أجل تقدم نور كابيتال.
السيد عبد الله السويدي، يُصنف كأحد رواد الصناعة المشهورين في الإمارات العربية المتحدة، والخبير الاستراتيجي، حيث يجمع خبرته المحلية والدولية لإفادة العملاء والمؤسسات والموظفين.
السيد عبد الله السويدي، هو أيضًا مؤسس شركة Excellence Target - شركة رائدة في مجال الاستشارات الإدارية والتدريب، وحاصل على بكالوريوس العلوم في الإدارة الهندسية ودرجة الماجستير في إدارة الجودة الشاملة.

                  </p>
                </Col>
              </Row>
            </div>
          </div>
 */}

          {/* <hr width="30%" /> */}

{/* 333333333333 */}
          <div className="row justify-content-center text-right" style={{"marginBottom":"25px","marginTop":"25px"}}>
            <div className="row align-items-center">
              <Row>
              <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Muhammad Al Zoubi"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={muhammadalzoubi}
                  />
                </Col>

                <Col
                 xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ "padding":"20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>محمد الزعبي</h3>
                  <p className="lead">المدير العام - الأسواق العالمية
                   </p>
                  <p style={{ textAlign: "justify", lineHeight: "24px", "fontSize":"16px" }}>
                  كانت رحلة السيد محمد الزعبي ملهمة حقًا، من بداية مسيرته المهنية في الأردن في مجال تكنولوجيا المعلومات في مركز إيداع الأوراق المالية إلى إنشاء قسم للسوق العالمي في نور كابيتال في عام 2010.
مع أكثر من 17 عامًا من الخبرة الهائلة في صناعة التمويل، أظهر السيد محمد الزعبي أن التفاني والشغف والعمل الجاد المطلق سيساعدك على تحقيق نجاح هائل لنفسك ولمؤسستك.
كان يرأس سابقًا قسم كبار الشخصيات في Mac Saraf ويعمل في إدارة الأسهم المحلية في شركة إعمار للخدمات المالية، ويلعب دورًا مهمًا في التطوير الشامل لشركة نور كابيتال.
السيد الزعبي حاصل على شهادة في هندسة الاتصالات من جامعة العلوم والتكنولوجيا الأردنية.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />

{/* 44444444444 */}
          <div className="row justify-content-center text-right" style={{"marginBottom":"25px","marginTop":"25px"}}>
            <div className="row align-items-center">
              <Row>
              <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Sher Ali"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={sherali}
                  />
                </Col>

                <Col
                 xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ "padding":"20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>شير علي</h3>
                  <p className="lead">
                  رئيس الخدمات المصرفية الاستثمارية
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "24px", "fontSize":"16px" }}>
                  هو القائد العاقل والمتفائل والجاد مع أكثر من 17 عامًا من الخبرة في صناعة التمويل هو ما يجعل السيد شير علي قائدًا ثمينًا ومحترمًا يعمل على تعزيز نور كابيتال مع تزويد العملاء بأفضل تجربة.
فبداية من عمله كمدير أول في بنك الدوحة، قطر، إلى العمل كمدير في قسم الهيكلة المالية في كيو انفيست، قطر، قطع السيد شير علي شوطًا طويلاً، حيث أضاف قيمة لنور كابيتال باستمرار بمعرفته وخبرته.
مع سجل حافل في الأعمال المصرفية الاستثمارية والمؤسسات المالية والتمويل المهيكل، فهو أيضًا خبير في كل من هياكل التمويل التقليدية والإسلامية.
السيد شير علي حاصل على ماجستير في إدارة الأعمال في البنوك والتمويل من كلية إدارة الأعمال في برمنغهام.

                  </p>
                </Col>
              </Row>
            </div>
          </div>
<hr width="30%" />
{/* 55555555555 */}

          <div className="row justify-content-center text-right" style={{"marginBottom":"25px","marginTop":"25px"}}>
            <div className="row align-items-center">
              <Row>
              <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Adel M. El Hassan"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={adelelhassan}
                  />
                </Col>

                <Col
                 xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ "padding":"20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>عادل محمد الحسن</h3>
                  <p className="lead">
                  مدير القسم القانوني ومحرر مجلس الإدارة
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "24px", "fontSize":"16px"}}>
                  مع أكثر من 25 عامًا من الخبرة في ممارسة القانون في الإمارات العربية المتحدة كقاضي ومحامي وعمل مستشارًا قانونيًا في الخارج، يعد السيد عادل محمد الحسن المتحكم في جميع الإجراءات القانونية الخاصة بشركة نور كابيتال.
ساهم السيد عادل محمد الحسن، المخضرم في عمليات الدمج والاستحواذ (M & As)، بشكل كبير في شركة نور كابيتال بخبرته في إعداد المذكرات القانونية والعقود والاتفاقيات، بما في ذلك العديد من عمليات إعداد الأعمال وعقود التوظيف وعقود البيع والشراء.
عضو نقابة المحامين السودانيين وحاصل على درجة البكالوريوس في القانون، السيد عادل محمد الحسن لديه معرفة واسعة فيما يتعلق بصياغة القرارات وقرارات الجمعية العمومية ومجلس الإدارة.

                  </p>
                </Col>
              </Row>
            </div>
          </div>

          

          {/* <div className="row-70" /> */}
        </section>
      </div>

      <style jsx>{
            `
            .imgshadow{
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
            
            `
        }
        </style>
    
    </div>





  );
};

export default ManagementTeamAr;
