import React from "react";
import { Link } from "react-router-dom";
import "./mainPage.css";
import globalstyle from "../../style.module.css";
import nooracademy from "../../assets/images/home/nooracademy.jpg";
import noortrends from "../../assets/images/home/noortrends.png";
import toolsimg from "../../assets/images/home/tools.jpg";

const MainPage4Ar = () => {
  return (
    <>
      <section className="fdb-block">
        <div className={globalstyle.container}>
          <div
            className="row justify-content-center pb-5"
            style={{ paddingBottom: "0px !important" }}
          >
            <div className="col-12 text-center">
              <h3 className="display-6">
                قم بتطوير مهارات التداول الخاصة بك من خلال المواد التعليمية و
                أدوات التداول الخاصه بنا
              </h3>
            </div>
          </div>
          <div className="row text-right align-items-center pt-5 pb-md-5">
            <div className="col-10 col-md-5">
              <img
                alt="noor academy"
                className="img-fluid rounded mx-auto d-block bgim"
                src={noortrends}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-5 m-md-auto">
              <h2 style={{ color: "#1b75bc" }}>نور تريندز</h2>
              <p className="lead" style={{ textAlign: "justify" }}>
                موقع نور تريندز هوا موقع تابع لشركة نور كابيتال حيث يوفر تحديثات
                السوق في الوقت الفعلي ، والتحليل الفني ، والتقرير الاقتصادي ،
                والبث اليومي والندوات عبر الإنترنت حتى تتمكن من البقاء في عالم
                التداول. احصل على آخر تحديثات السوق الآن لتبدأ التداول اليومي
              </p>
              <p>
                <Link to="/ar/trading-tools/noor-trends-market-update">
                  <i className="fas fa-angle-left" /> قراءة المزيد
                </Link>
              </p>
            </div>
          </div>

          <div className="row text-right align-items-center pt-5 pb-md-5">
            <div className="col-10 col-sm-6 col-md-5 m-md-auto order-md-5">
              <img
                alt="noor academy"
                className="img-fluid rounded mx-auto d-block bgim"
                src={nooracademy}
              />
            </div>
            <div className="col-12 col-md-5">
              <h2 style={{ color: "#1b75bc" }}>اكاديمية نور</h2>
              <p className="lead" style={{ textAlign: "justify" }}>
                سواء كنت مبتدئًا أو متداولًا متمرسًا، ستحولك أكاديمية نور
                كابتيال بكافة خدماتها التعليمية و دبلومة التداول إلى متداول
                محترف في غضون فترة زمنية قصيرة ابدأ في تعلم سوق الأسهم وتداول
                العملات الأجنبية والسلع اليوم
              </p>
              <p>
                <Link to="/ar/education/noor-academy">
                  <i className="fas fa-angle-left" /> قراءة المزيد
                </Link>
              </p>
            </div>
          </div>

          <div className="row text-right align-items-center pt-5 pb-md-5">
            <div className="col-10 col-md-5">
              <img
                alt="noor academy"
                className="img-fluid rounded mx-auto d-block bgim"
                src={toolsimg}
              />
              {/* <Tool /> */}
            </div>
            <div className="col-12 col-sm-6 col-md-5 m-md-auto">
              <h2 style={{ color: "#1b75bc" }}>ادوات التداول</h2>
              <p className="lead" style={{ textAlign: "justify" }}>
                نحن نتفهم الجزء الحيوي من نجاح المتداول ، وبشكل أساسي القدرة على
                فهم وتحليل بيانات التداول لإبقائك على اطلاع بأحدث اتجاهات السوق
                ، لدينا مجموعة واسعة من أدوات التداول تتراوح من التقويم
                الاقتصادي وإشارات التداول والتحليل الفني إلى تحديثات السوق
                اليومية.
              </p>
              <p>
                <Link to="/ar/trading-tools/economic-calendar">
                  <i className="fas fa-angle-left" /> قراءة المزيد
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <style jsx>
        {`
          .pb-5,
          .py-5 {
            padding-bottom: 0px !important;
          }
        `}
      </style>
    </>
  );
};

export default MainPage4Ar;
