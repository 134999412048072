import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import ContactUsFormAr from "../forms/ContactUsFormAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const ContactUsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Contact Us - Noor Capital"
        description="Want to contact the Noor Capital  team? We'd love to hear from you! Simply enter your details and message into the contact form below."
      />
      <BgBeforeAr
        pageHeadingTitle="نحن على بعد مكالمة أو ضغطة واحدة – اختر أفضل طريقة تناسبك للتواصل معنا"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="تواصل معنا"
      />

      <section
        className="fdb-block pt-0"
        style={{ paddingBottom: "10px", backgroundColor: "#fff" }}
      >
        <br />

        <div className={globalstyle.container}>
          <div className="row-100" />
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <p className={globalstyle.parjustify}>
                نحن نقدم خدمات الوساطة العالمية بما في ذلك الوصول الفوري إلى
                الأدوات المالية في جميع أنحاء العالم مثل الأسهم وصناديق
                الاستثمار المتداولة والمؤشرات والعملات والسلع. أيا كان وأينما
                كان العميل يريد التداول، فإننا نوفر وصولاً لا مثيل له إلى أكثر
                الأدوات المالية سيولة في العالم. جرب دعم العملاء واستفد من خدمات
                العملاء الخاصة بنا.
                <br />
                <br />
                 <b>  أيام العمل : </b> الاثنين إلى الجمعة
                <br />
                 <b> دعم العملاء : </b> 24/5 
              </p>

              {/* General inquiries */}
              <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> الاستفسارات العامة </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:info@noorcapital.ae">info@noorcapital.ae</a>
              </p>

              {/* Customer Services */}
              <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> خدمة العملاء </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:cs@noorcapital.ae">cs@noorcapital.ae</a>
              </p>
              <p className="text-right">
              
                <i
                  class="fas fa-phone text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">0097142795400</a>
              </p>

              {/* Onboarding */}
              <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> فتح الحسابات </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:onboarding@noorcapital.ae">
                  onboarding@noorcapital.ae
                </a>
              </p>
              <p className="text-right">
                <i
                  class="fas fa-phone text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">0097142795400</a>
              </p>
            
            
            </div>


            <div className="col-12 col-md-6 ml-auto text-right">
              <h4 style={{ color: "#1b75bc" }}> يرجى ملء النموذج أدناه، وسيعود مندوبنا للتواصل معكم في غضون 48 ساعة</h4>
              <br/>
              <ContactUsFormAr />
            </div>
          </div>
        </div>
        <br />
      </section>

      <section
        className="fdb-block"
        style={{
          paddingTop: "0",
          backgroundColor: "#f2f4f7,",
          textAlign: "right",
        }}
      >
        <div className={globalstyle.container}>
          <div className="row pt-4">
            <div className="col-12 col-md-6">
              <h3 style={{ color: "#1b75bc" }}>أبو ظبي</h3>

              <p>
                <i
                  class="fas fa-map-marker-alt"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>
                <span style={{ fontSize: "13px" }}>
                 مكتب 203/204 ، الطابق الثاني ،برج المنتزه ب، شارع زايد الأول ، الخالدية 
                </span>
              </p>

              <p>
                <i
                  class="fas fa-phone"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>
                <a href="tel:+97126266888">0097126266888</a>
              </p>

              <iframe
                className="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14525.98175394815!2d54.3408227!3d24.468285!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26eaf243ff76ecdc!2sNoor%20Capital%20PSC!5e0!3m2!1sen!2sae!4v1615429036056!5m2!1sen!2sae"
                style={{ border: 0 }}
                allowFullScreen
                width="100%"
                height={300}
                frameBorder={0}
              />
            </div>

            <div className="col-12 col-md-6 pt-5 pt-md-0">
              <h3 style={{ color: "#1b75bc" }}>دبي</h3>

              <p>
                <i
                  class="fas fa-map-marker-alt"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>
                <span style={{ fontSize: "13px" }}>
                الطابق الأول، مبنى إم إس إم 1، الصفا 1، شارع الشيخ زايد، صندوق بريد 334241
                </span>
              </p>

              <p>
                <i
                  class="fas fa-phone"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>
                <a href="tel:+97142795400">0097142795400</a>
              </p>

              <iframe
                className="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.086313931967!2d55.23299567698985!3d25.166563232937882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f692e8914a2dd%3A0x2121b385b463b601!2sMSM%201%20Building!5e0!3m2!1sen!2sae!4v1683114364751!5m2!1sen!2sae"
                style={{ border: 0 }}
                allowFullScreen
                width="100%"
                height={300}
                frameBorder={0}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUsAr;
