import React from "react";
import { Row, Col , Typography } from 'antd';
import { Link } from "react-router-dom";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bggtn.jpg"
import mt4desktop from "../../assets/images/pages/trading/gtn.png";
import WhyNoor from "./whyNoor";

const { Title } = Typography;

const style = {padding: '50px 0' };

const GTN_GTNWebtrader = () => {
  return( <div>
            <PageseMetaTags
                title="Noor Capital Stocks Trading Platform (GTN)"
                description="A robust trading app that supports our active traders' daily activities, keeping them ahead of the game."
            />
            <BgBefore 
            pageHeadingTitle="Take Charge Of Your Stocks And Trade On The Go With Noor Capital Stocks Trading Platform (GTN)"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
       
       
       <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="Stocks Trading WebTrader"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Access your Cash Equities Account from WebTrader
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            With Noor Capital Stocks Trading Platform (GTN), trading has never been this comfortable and convenient. A robust trading app that supports our active traders' daily activities, keeping them ahead of the game.
            </p>
            <p className="parjustify">
            Noor Capital Stocks Trading Platform (GTN), a unique platform that allows you to buy/sell stock from anywhere and receive important alerts, news, and reports giving you an upper hand in this fast-paced trading world.
            </p>

            <p className="parjustify">
            Noor Capital Stocks Trading Platform (GTN) trading app provides you with an extremely user-friendly interface accessible from the web, desktop, and mobile (Available at PlayStore and AppStore).
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-12 col-md-8 col-lg-4 m-sm-auto">
                <p className="mt-3">
                  <a
                    className="btn btn-primary btn-lg"
                    href="https://noorcapital.globaltradingnetwork.com/web/login"
                    target="_blank"
                    
                  >
                    Open WebTrader
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                Change The Way You Trade – From Anywhere. Anytime. Any Device
                </Title>
                
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Start trading instantly from any web browser.

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Compatible with all major operating systems.

                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                 Get access to 60k+ stocks.

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get instant access to GCC, Europe, and the US market.

                </p>
                <br />
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  
                  Trade on well known companies (Facebook, Microsoft, Tesla, etc.).

                </p>
                <br />
                

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Experience the most secure and user-friendly trading platform around the world.

                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Buy, sell, cancel, and amend orders at a click.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Receive real-time data, charts, reports, and announcements.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Choose your preferred language (English/Arabic).
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Add and track your favorite stocks in the smart watch list.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Manage multiple portfolios.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Manage your account balance and buying limits with an account summary.
                  
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={4}>
              Trading for the first time with Noor Capital Stocks Trading Platform (GTN)? 
              </Title>

              <p className="mt-5 mt-sm-4">
              <Link
                  className="btn btn-outline-primary"
                  to="/stocks-trading-platforms/open-gtn-live"
                >
                  Register Live Account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>


  </div>
  )

};

export default GTN_GTNWebtrader;