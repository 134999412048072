import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgtradingbanner3Ar.jpg"
import energies from "../../assets/images/pages/instruments/energies.jpg";
import ProductsAr from "./productsAr";
import Instruments_Energies_Table from "./trading_Instruments_Energies_table";


const { Title } = Typography;


const Instruments_Energies = () => {
  return( <div>
          <PageseMetaTags
                title="Trade CFDs on Energies Online with Noor Capital at Low Margin"
                description="Noor Capital allows trading of spot energy contracts including Crude Oil, Brent, and Natural Gas from your MetaTrader 4 and 5 platforms against the US Dollar. Trading energy contracts as a spot instrument has many advantages for investors who are only interested in price speculation."
            />
            <BgBeforeAr
            pageHeadingTitle="طًور استثمارك بشكل كبير - تداول في مشتقات الطاقة الآن "
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenuAr
        pageMainCategoryName="التداول"
        pageMainCategoryPath="#"
        pageSubCategoryName="الأسواق"
        pageSubCategoryPath="#"
        pageName="الطاقة"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          العقود مقابل الفروقات التجارة على الطاقات اون لاين
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            يمكن تداول العقود الاشتقاقية التي تعتمد على النفط والغاز الطبيعي والكهرباء في البورصة أو خارج البورصة (OTC). بشكل عام، تختلف قيمة المشتق اعتمادًا على سعر المنتجات الأساسية.
            </p>

            <p className="parjustify text-right">
            يمكن للشركات إما شراء أو بيع مشتقات الطاقة للتحوط من التقلبات في حركة أسعار الطاقة الأساسية. يُستخدم لكل من المضاربة والحماية، حيث يمكن للمضاربين استخدام المشتقات لتحقيق الربح من تغيرات الأسعار.
            </p>

            <p className="parjustify text-right">
            عقود المشتقات المتداولة في البورصة هي أداة مالية تعتمد قيمتها على قيمة الأصول الأخرى وتداولاتها في بورصة منظمة. اكتسبت المشتقات المتداولة في البورصة شعبية متزايدة بسبب مزاياها في سوق المشتقات الفورية (OTC). تُعد العقود الآجلة والخيارات هما من أكثر المشتقات شيوعًا وتداولًا في البورصة.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={energies}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>


        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          قائمة المواصفات
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Instruments_Energies_Table />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              ابدأ التداول في مشتقات الطاقة الآن
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>



      <ProductsAr />


  </div>
  )

};

export default Instruments_Energies;