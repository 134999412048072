import React from "react";
import { Row, Col, Typography } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgmt4.jpg";
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import OpenFxDemoForm from "../forms/openFxDemoForm.js";
import PriceTicker from "../home/priceTicker";
import { Link } from "react-router-dom";

import leadinginvestment from "../../assets/images/pages/whynoor/leading-investment.svg";
import license from "../../assets/images/pages/whynoor/license.svg";
import funding from "../../assets/images/pages/whynoor/funding.svg";
import execution from "../../assets/images/pages/whynoor/execution.svg";
import platform from "../../assets/images/pages/whynoor/platform.svg";
import instruments from "../../assets/images/pages/whynoor/instruments.svg";
import research from "../../assets/images/pages/whynoor/research.svg";
import support from "../../assets/images/pages/whynoor/support.svg";



import fx from "../../assets/images/home/forex.svg";
import metal from "../../assets/images/home/metal.svg";
import energies from "../../assets/images/home/energies.svg";
import indices from "../../assets/images/home/indices.svg";
import share from "../../assets/images/home/shares.svg";



const { Title } = Typography;

const Campaign_OpenDemoAccount = (props) => {
  const { location } = props;
  return (
    <div>
      <PageseMetaTags
        title="Invest with a Trusted Forex Broker"
        description="The Noor Demo Account allows you to review and test the features of our Noor Trading Platform before funding your account with real money or placing trades."
      />
      <BgBefore
        pageHeadingTitle="Invest with a Trusted Forex Broker"
        pageHeadingDescription="Fully Regulated by UAE Securities and Commodities Authority"
        pageHeadingbg={img1}
      />


      <PriceTicker />

      <div className={globalstyle.container} id="demo">
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Sign-Up For FX Demo Account
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            
            <OpenFxDemoForm location={location} />
            <br/>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <h4 style={{ color: "#1b75bc" }}>
            Invest now for a better future!
            </h4>
            <p >
            Choose a broker with years of experience in the online forex trading industry that gives you access to MENA and Global markets which includes FX pairs, Metals, Commodities, Indexes, Shares and Digital Currency.
            </p>
            <p>
            <b>Noor Capital PSC</b> was established in 2005 and fully regulated by the Securities and Commodities Authority in the UAE. 
            </p>

            <p>
            When you join Noor Capital, you can unlock world of opportunities that can help you leverage on your investing. It includes Forex trading course, daily market and technical updates, economic calendar and reports, daily broadcasting, trading signals and more.
            </p>

            <p>
            Start today and let us help you achieve your financial goal.
            </p>
            
          </Col>
        </Row>
      </div>
      <br />

     
      
      {/* Feature 22 */}
      <section className="fdb-block"  style={{"backgroundColor":"#f2f4f7"}}>
        <div className={globalstyle.container}>
          <div className="row text-left">
            <div className="col-12">
              <h4 style={{ color: "#1b75bc" }}>
              Why Forex Investors choose Noor Capital?
              </h4>
            </div>
          </div>


          <div className="row text-left mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={leadinginvestment}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}

                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>One of the leading investment and brokerage firm</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={license}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>Regulated by the Securities and Commodities Authority (SCA)</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={funding}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>Local deposits and withdrawals with reputable UAE banks</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={execution}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>Instant execution through tier-1 interbank liquidity</h6>
                </div>
              </div>
            </div>

          </div>
         


         
          <div className="row text-left mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={platform}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>Ultimate and advanced trading platforms</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={instruments}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fff","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>Wide range of global financial instruments</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={research}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>Specialized education tutorials and trading tools</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={support}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>24/5 multilingual customer support</h6>
                </div>
              </div>
            </div>

          </div>
         


        </div>
      </section>





{/* Feature 24 */}
<section className="fdb-block" style={{ padding: "45px 0" }}>
          <div className={globalstyle.container}>
            <div className="row align-items-top">
              <div className="col-12 col-md-6 col-xl-6 m-auto">
                <div className="text-left">
                  <h2 className="display-4" style={{ color: "#1b75bc" }}>
                    A world of investment opportunities
                  </h2>
                </div>
                <p style={{ color: "#8892a0", fontSize: "20px" }}>
                  Trade 1000+ of instruments and asset classes worldwide markets through multiple trading platforms.
                </p>
              </div>

              <div className="col-12 col-md-6 pt-5 pt-md-0">
                <div className="row justify-content-left">
                  <div className="col-3 m-auto text-left">
                    <img
                      alt="Forex"
                      className="fdb-icon"
                      src={fx}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/forex">
                        Forex <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Precious Metals"
                      className="fdb-icon"
                      src={metal}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/precious-metals">
                        Precious Metals <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Energies"
                      className="fdb-icon"
                      src={energies}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/energies">
                        Energies <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="row justify-content-left mt-4 mt-xl-5">
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Indices"
                      className="fdb-icon"
                      src={indices}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/indices">
                        Indices <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                  <div className="col-3 m-auto text-center">
                    <img
                      alt="Share CFDs"
                      className="fdb-icon"
                      src={share}
                      style={{ width: "60px", paddingBottom: "10px" }}
                      data-aos="zoom-in"
                    />
                    <p>
                      <Link to="/trading/instruments/shares-cfds">
                        Share CFDs <i className="fas fa-angle-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>







     
      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                


                <h4 style={{ color: "#1b75bc" }}>
                Escalate your trading knowledge and test before you trade live
              </h4>

                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Instant access to real-time market with our demo account
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Access your account on any web browser from anywhere in the
                  world
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Learn trading like a professional with real-time data
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Advance indicators and charts to help with trading strategies.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Experience the most secure and user-friendly trading platform
                  around the world
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Demo Account is Risk-Free trading
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Trade on the go with Noor Capital's MetaTrader 4 (MT4) mobile
                  app.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Noor Capital's MetaTrader 4 (MT4) EA (Expert Advisors)
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 dedicated customer service available to help you with any
                  concerns or advice
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get trading signals from top providers
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4desktop" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
            <div
              style={{
                paddingTop: "35px",
                textAlign: "center",
                fontSize: "14.5px",
              }}
            >
              <p>
                Using a demo account, you get instant, risk-free, and live
                access to the real-time market, where you can practice trading
                operations, get the latest updates, learn from experts, and use
                all the features that will take you one step towards becoming a
                professional trader. With our demo account, you will get a live
                experience of the real-time market without getting into the risk
                of loss.
              </p>



              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="#demo"
                >
                  Open Forex Demo Account
                </Link>
              </p>



            </div>
          </div>
        </section>
      </div>

      



      <style jsx>
        {`
          .header_upHeader__2yWsI {
            display: none !important;
          }

          .stickyButtons_stickyButtons__CRvWV {
            display: none !important;
          }

          .socialShare_iconbar__3PD2f {
            display: none !important;
          }

          .header_topHeaderMenu__1R1SM {
            display: none !important;
          }

          .menuBar_burger-menu__oQixT {
            display: none !important;
          }
          
          p{
            font-size: 17px;
          }
        #upfooter1{
            display: none !important;
          }

        #upfooter2{
          display: none !important;
          }
          
        `}
      </style>







      
    </div>
  );
};

export default Campaign_OpenDemoAccount;
