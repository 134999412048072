import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bggtnAr.jpg";

import WhyNoorAr from "./whyNoorAr";
import GtnVideo from "./GtnVideoAr.js";

const { Title } = Typography;
const style = { padding: "50px 0" };

const GTNAr = () => {
  return( <div>
            <PageseMetaTags
                title="Noor Capital Stocks Trading Platform (GTN)"
                description="Take Charge Of Your Stocks And Trade On The Go With Noor Capital Stocks Trading Platform (GTN)"
            />

            <BgBeforeAr 
            pageHeadingTitle="منصة نور كابيتال لتداول الأسهم (GTN) أسهمك مسؤوليتها، حيث تتيح لك التداول أينما كنت. "
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenuAr 
        
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="تداول الأسهم العالمية"
      />

        <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}></Title>
        </Row>
        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            بفضل منصة نور كابيتال لتداول الأسهم (GTN)، أصبحت عملية تداول الأسهم أكثر راحة وسهولة من ذي قبل. فمع تطبيق تداول فعال يدعم الأنشطة اليومية للمتداولين النشطين، ليصبحوا في الصدارة.
            </p>
            <br />
            <p className="parjustify">
            منصة نور كابيتال لتداول الأسهم (GTN)، هي منصة فريدة تتيح لك شراء / بيع الأسهم من أي مكان وتوفر لك التنبيهات والأخبار والتقارير المهمة مما يمنحك اليد العليا في عالم تداول سريع الخطى.
            </p>
            <br />
            <p className="parjustify">
              يوفر لك تطبيق نور كابيتال لتداول الأسهم (GTN) واجهة سهلة الاستخدام، يمكن الوصول إليها من الويب وسطح المكتب والجوال ( متوفر على جوجل بلاي
 و آب ستور ).
            </p>

            <br />


            <Link
              to="/ar/stocks-trading-platforms/open-gtn-live"
              className="btn btn-success btn-lg"
              style={{ width: "250px" }}
            >
              فتح حساب حقيقي
            </Link>

            <div style={{"marginTop":"50px"}}>


            <p className="parjustify">
              <b>
            الآن نور كابيتال تقدم منصة تداول الأسهم لمستخدمي سطح المكتب لنظام تشغيل ويندوز وماك  
            </b>
                        </p>

            <a
                  href="https://download.globaltradingnetwork.com/wrappers/NOOR_WIN.zip"
                  className="btn btn-secondary ml-4"
                  style={{ width: "260px",     "marginRight": "35px" }}
                  target="_blank"
                >
                 تحميل المنصة لنظام تشغيل ويندوز
                </a>
              
              <a
                  href="https://download.globaltradingnetwork.com/wrappers/NOOR_MAC.dmg.zip"
                  className="btn btn-secondary "
                  style={{ width: "270px" }}
                  target="_blank"
                >
                  تحميل المنصة لنظام تشغيل ماك أو إس
                </a>

                </div>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <GtnVideo />
          </Col>
        </Row>
      </div>
      <br />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={1}>
              خصائص منصة نور كابيتال
              </Title>
              <h5>مع منصة نور كابيتال لتداول الأسهم (GTN) فقد تغيرت طريقة تداول الأسهم.</h5>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h4 style={{ color: "#1b75bc" }}>تداول مباشر</h4>
              <p className="lead">
              مع منصة نور كابيتال لتداول الأسهم (GTN)، يمكنك شراء وبيع وإلغاء وتعديل الأوامر على الفور.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <h4 style={{ color: "#1b75bc" }}>البيانات الفورية </h4>
              <p className="lead">
              احصل على الرسوم البيانية في الوقت الفعلي وبيانات السوق ومعلومات الشركة والإعلانات والتقارير التي ستساعدك على البقاء في الصدارة والتداول بثقة.
              </p>
            </div>
          </div>
          
          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>ثنائي اللغة</h4>
              <p className="lead">
              لمساعدة عملائنا على استخدام المنصة بشكل أكثر وفعالية ، تتوفر منصة نور كابيتال لتداول الأسهم (GTN) بأكثر من لغة.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>قائمة المراقبة الذكية </h4>
              <p className="lead">
              لا تفوت الأسهم المفضلة لديك. تتيح لك قائمة المراقبة الذكية تتبع الأسهم التي تريدها وتحليلها ومقارنتها واستعراضها على الفور.
              </p>
            </div>
          </div>
        
          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>إدارة العمليات والأرصدة</h4>
              <p className="lead">
              يمكنك الوصول إلى ملخص حسابك ومحافظك الاستثمارية التي ستساعدك على تحليل وإدارة رصيد حسابك وحدود الشراء.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>آمن وسهل الاستخدام</h4>
              <p className="lead">
              منصة نور كابيتال لتداول الأسهم (GTN) مؤمنة بنسبة 100٪ بتشفير عالي المستوى لمرة واحدة يحمي حسابك من أي احتيال حيث إنها واحدة من أكثر المنصات سهولة في الاستخدام مع إمكانية الوصول إلى الرسوم البيانية في الوقت الفعلي.


              </p>
            </div>
          </div>
        

        </div>
      </section>

      <br />

      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              بإمكانك خوض تجربة التداول لأول مرة مع منصة نور كابيتال لتداول الأسهم (GTN)
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/ar/stocks-trading-platforms/open-gtn-live"
                  
                >
                   التسجيل الآن  
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>



  </div>
  )

};

export default GTNAr;