import React from "react";
import { Layout, Row, Col, Divider, Typography } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import OpenGTNDemoForm from "../forms/openGTNDemoForm";
import img1 from "../../assets/images/bggtn.jpg";

import mt4desktop from "../../assets/images/pages/trading/gtn.png";
import WhyNoor from "./whyNoor";
import GtnVideo from "./GtnVideo.js";

const { Title } = Typography;

const GTN_OpenDemoAccount = () => {
  return (
    <div>
      <PageseMetaTags
        title="Open Demo Account with Noor Capital Stocks Trading Platform (GTN)"
        description="A robust trading app that supports our active traders' daily activities, keeping them ahead of the game."
      />
      <BgBefore
        pageHeadingTitle="Elevate Your Trading Experience To The Next Level With The World's Most Advanced And Robust Trading Platform"
        pageHeadingDescription="Join Noor Capital Stocks Trading Platform (GTN) And Take Control of Your Trading – NOW"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="Cash Equities Trading Demo Account"
      />

      <div className={globalstyle.container} id="main">
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Sign-Up For Demo Account
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              Change the way you trade and take charge of stocks. Join Noor
              Capital Stocks Trading Platform (GTN), the most advanced and
              robust platform built with attention to detail to make your
              trading quick and easy.
            </p>
            <p className="parjustify">
              Now you can trade from anywhere in the world at the touch of a
              finger. With our highly user-friendly and accessible platform, you
              can stay updated with the latest trends, news, alerts, and advice
              to keep you ahead in your trading.
            </p>

            <p className="parjustify">
              Whether you are a new or a pro trader, our platform is for
              everyone, making Noor Capital Stocks Trading Platform (GTN) the
              most preferred platform globally. 100% secured, this platform is
              available on the web, and mobile.
            </p>

            <OpenGTNDemoForm />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <GtnVideo />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                  When You Sign-Up, You Can
                </Title>

                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Start trading instantly.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Buy, sell, cancel, and amend orders at a click.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get instant access to GCC, Europe, and the US market.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Trade on well known companies (Facebook, Microsoft, Tesla,
                  etc.).
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Experience the most secure and user-friendly trading platform
                  around the world.
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get access to 60k+ stocks.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Receive real-time data, charts, reports, and announcements.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Choose your preferred language (English/Arabic).
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Add and track your favorite stocks in the smart watch list.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Manage your account balance and buying limits with an account
                  summary.
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4desktop" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
            <div
              style={{
                paddingTop: "35px",
                textAlign: "center",
                fontSize: "14.5px",
              }}
            ></div>
          </div>
        </section>
      </div>

      <br />

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                Start Your Journey As A Web Trader With Noor Capital Stocks
                Trading Platform (GTN) - NOW!!
              </Title>

              <p className="mt-5 mt-sm-4">
                <a className="btn btn-outline-primary" href="#main">
                  Register Demo Account
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GTN_OpenDemoAccount;
