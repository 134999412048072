import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgpartnershipbanner4Ar.jpg"
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import WhyNoorAr from "./whyNoorAr";

const style = { padding: "50px 0" };
const notes = [
  "تجمع الحلول المتقدمة بين السيولة المثلى وطرق التنفيذ المتعددة.",
  "خيارات تكامل من خلال تطبيق بروتوكول التبادل بالمعلومات المالية FIX API المصممة خصيصًا لبيئة التداول المعقدة اليوم.",
  "خيارات للتداول في العديد من الأصول المالية مثل العقود الآجلة للعملات الأجنبية والسلع الخفيفة ومؤشرات الأسهم والفوركس.",
  "لا يوجد حد أدنى لحجم التداول.",
  "سهولة الوصول إلى تداول الأصول المتعددة.",
  "ميزة إضافية تتمثل في تداول المعادن النفيسة والطاقات.",
  "عمولة منخفضة السعر - يتم خصم عمولة أقل لكل حجم تداول.",
  "استشارات ومساعدة مجانية مقدمة في تكامل وإعداد نظامك.",
  "تقديم وسائل حديثة لتمكين العملاء من التركيز على أعمالهم الأساسية أثناء الانخراط في الأعمال المالية أو الوساطة معنا.",
];
const { Title } = Typography;

const Institutional_InstitutionalClientsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Institutional Clients - Forex Trading | Noor Capital"
        description="Noor Capital Welcomes Institutional Investors, High Net Worth Individuals & Investors with an Interest in Trading."
      />

      <BgBeforeAr
        pageHeadingTitle="ربحك هو نجاحنا"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الشراكة"
        pageSubCategoryPath="#"
        pageName="عملاء المؤسسات"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          عملاء المؤسسات
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            في نور كابيتال، نقدم لشركائنا منصة قوية واستراتيجيات تداول ودعم مخصص للنمو في السوق المالية. لدينا هدف نهائي نريد تحقيقه يتلخص في ربح مستثمرينا وشركائنا حيث نقدم للمستثمرين المؤسسيين لدينا ميزة تنافسية لا مثيل لها في سوق رأس المال الدولي
            </p>

            <p className="parjustify text-right">
            مع سنوات من الخبرة والأشخاص المحترفين المعتمدين، فإن فريقنا موجود دائمًا لدعمك في اتخاذ القرار الصحيح وبناء علاقات طويلة الأمد مع مزودي السيولة الرئيسيين.
            </p>

            <p className="parjustify text-right">
            مع نور كابيتال، يمكنك الوصول المباشر إلى السوق من خلال تطبيق بروتوكول التبادل بالمعلومات المالية FIX API المصمم خصيصًا للمتداولين المؤسسيين والخوارزمين لتمكينهم من الوصول إلى السيولة من بنوك الدرجة الأولى مع أسرع عمليات التنفيذ في السوق.
            </p>
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          الميزات والفوائد 
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              اتصل بنا الآن
              </Title>
              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/ar/about/contact-us"
                >
                  تواصل معنا
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoorAr />
    </div>
  );
};

export default Institutional_InstitutionalClientsAr;
