import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import webinar from "../../assets/images/pages/tools/webinar.png";
import img1 from "../../assets/images/bgtools.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const { Title } = Typography;

const NoorTrends_OnlineWebinar = () => {
  return (
    <div>
      <PageseMetaTags
        title="Online Webinars - Noor Capital"
        description="Noor Capital offers 100% free live online webinars for both beginner and advanced traders."
      />

      <BgBefore
        pageHeadingTitle="Learn From The Industry Experts Anywhere In The World"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Tools"
        pageSubCategoryPath="#"
        pageName="Online Webinars"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Online Webinars
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Noor Capital gives you an opportunity  to learn from experts and take your trading knowledge to the higher levels. With our webinars, you can grab this opportunity to discover the most elusive secrets of becoming a top trader, sharpening your trading skills, getting answers to your questions, and connecting with renowned industry experts.
            </p>

            <p className="parjustify">
              If you miss the live webinars, our registered members can still
              access the past webinars from our infinite online library.
            </p>

            <p className="parjustify">
            Our webinar focuses on the current trading topics, trends, strategies, and analysis that will help you expand your trading understanding and skills. From basic training to in-depth analysis, our webinars are designed to meet all  traders’ requirements.
            </p>

            <p className="parjustify">
              With our webinars, you will learn the fundamentals of trading,
              receive the latest updates, understand market psychology, discover
              unique trading strategies, and get expert advice.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={webinar}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                <br />
                Join Our Free Webinars On Trading And Unlock The Secrets Of
                Success In The Market
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/category/learn-forex/%d8%a7%d9%84%d9%86%d8%af%d9%88%d8%a7%d8%aa-%d8%a7%d9%84%d8%aa%d8%b9%d9%84%d9%8a%d9%85%d9%8a%d8%a9/"
                  target="_blank"
                >
                  Sign Up For Webinar
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NoorTrends_OnlineWebinar;
