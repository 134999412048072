import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtradingbanner3.jpg";
import forex from "../../assets/images/pages/instruments/forex.jpg";
import Products from "./products.js";

import Instruments_Fx_Table from "./trading_Instruments_FX_table";

const { Title } = Typography;

const Instruments_Forex = () => {
  return (
    <div>
      <PageseMetaTags
        title="Online Forex Trading - Noor Capital"
        description="FX stands for Foreign Exchange. The Foreign Exchange Market is the biggest financial market in the world; with a daily turnover of over $5 trillion dollars."
      />
      <BgBefore
        pageHeadingTitle="Take A Leap Towards Financial Freedom With Noor Capital Forex Trading"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Trading"
        pageMainCategoryPath="#"
        pageSubCategoryName="Instruments"
        pageSubCategoryPath="#"
        pageName="Forex"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Forex
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            The days when forex was limited to institutional firms are now from the past. at the present time, large banks now it is more retail-oriented and traders or investors of any holding size can participate. Forex is the central hub of earning great profits these days with a daily trading volume turnover of about $5 Trillion.
            </p>

            <p className="parjustify">
            At Noor capital, we offer advanced charting and patterning tools and trade signals to ease the trading process as much as possible. When you trade forex with the company , you can trade in over 60 Currency pairs. We provide an opportunity for our investors to diversify their portfolios.
            </p>

            <p className="parjustify">
            Flexibility and diversity of the trading platforms offered by Noor Capital is the most significant  reason why to consider forex trading with us. in addition, we offer low operational costs, limited requirements, and all-time accessibility.
            </p>

            <p className="parjustify">
            Noor Capital is committed to providing you with the most reliable and advanced forex trading services. So if you are looking for the easiest yet effective way of exchanging currencies, consider forex trading with Noor Capital as your ultimate option.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={forex}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Online Forex Trading"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Specifications list
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            
            <Instruments_Fx_Table />

          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              Start Trading Forex Now
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Sign-Up
                  </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Products />
    </div>
  );
};

export default Instruments_Forex;
