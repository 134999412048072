import React from "react";
import { Row, Col } from 'antd';
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgmt4.jpg"

const style = {padding: '50px 0' };

const Metatrader4_OpenLiveAccount = () => {
  return( <div>
          <PageseMetaTags
                title="Open Live Account - Forex Trading Live Account | Noor Capital"
                description="Investing has never been easier. Everything you are looking for in an ultimate investment platform — on the device of your choice."
            />

            <BgBefore 
            pageHeadingTitle="Don't Wait For The Right Time - It's Now!"
            pageHeadingDescription="Register With Noor Capital's MetaTrader 4 (MT4) - A Powerful & Leading Trading Platform Globally."
            pageHeadingbg={img1}
            
            />
        <BreadcrumbMenu />

    <div className={globalstyle.container}>

        <Row>
            <Col span={24} className="gutter-row">

            <div style={style}>


      <div>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '24px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#C00000'}}>Slider/Tagline</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', margin: '0cm', textAlign: 'justify'}}><strong><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#0E101A'}}>Don't Wait For The Right Time - It's Now!!</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', margin: '0cm', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#0E101A'}}>&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', margin: '0cm', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#0E101A'}}>Register With Noor Capital's MetaTrader 4 (MT4) - A Powerful &amp; Leading Trading Platform Globally.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', margin: '0cm', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#0E101A'}}>&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', margin: '0cm', textAlign: 'justify'}}><strong><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#0E101A'}}>Sign-Up For A Live Account</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '24px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#C00000'}}>Overview</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>Whether you are a newbie or a pro trader with Noor Capital's MetaTrader 4 (MT4), you can instantly start trading forex, indices, metals, and commodities via mobile app, PC, or directly through any web browser.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>Built on a cutting-edge technology, Noor Capital's MetaTrader 4 (MT4) offers a seamless execution of a trade, manage positions, stop-losses, indicators, charts, and much more for traders to carry out daily trading activities more confidently and conveniently.&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>If you want to experience a whole new way of trading on a platform that is neat, user-friendly, and accessible from anywhere in the world, you are in the right place.&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>Sign-Up Now</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '24px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#C00000'}}>Features</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>Dominate The Trading World, Open A Noor Capital's Metatrader 4 (MT4) Live Account And Unlock The Following Features - INSTANTLY!!</span></strong></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '26px'}}>
          <li><span style={{lineHeight: '150%', fontSize: '19px'}}>Advance indicators and charts to help with trading strategies.</span></li>
          <li><span style={{lineHeight: '150%', fontSize: '19px'}}>Get trading signals from top providers.</span></li>
          <li><span style={{lineHeight: '150%', fontSize: '19px'}}>Access your account on any web browser from anywhere in the world.</span></li>
          <li><span style={{lineHeight: '150%', fontSize: '19px'}}>Trade on the go with Noor Capital's MetaTrader 4 (MT4) mobile app.</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Arial",sans-serif', fontSize: '14.0pt', color: '#222222', background: 'white'}}>Noor Capital's MetaTrader 4 (MT4) platform supports EAs (Expert Advisors) by default, no approvals required</span></li>
          <li><span style={{lineHeight: '150%', fontSize: '19px'}}>24/5 dedicated customer service available to help you with any concerns or advice.</span></li>
        </ul>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '24px', lineHeight: '150%', fontFamily: '"Arial",sans-serif', color: '#C00000'}}>Call To Action</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>Revolutionize Your Trading Experience With Noor Capital's MetaTrader 4 (MT4)</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>Sign-Up For Live Account</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '150%', textAlign: 'justify'}}><strong><span style={{fontSize: '19px', lineHeight: '150%', fontFamily: '"Arial",sans-serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong></p>
      </div>


            <div>
         </div>

 
      </div>


            </Col>
        </Row>

    </div>

  </div>
  )

};

export default Metatrader4_OpenLiveAccount;