import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { Menu, Dropdown, message, Space, Tooltip } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import VideoAr from "./videoAr";
import "./footer.css";
import globalstyle from "../../style.module.css";

const opendemo = (
  <Menu>
    <Menu.Item key="1" style={{ textAlign: "right" }}>
      <Link to="/ar/trading-platforms/mt4-open-forex-demo">
        تداول العملات والسلع
      </Link>
    </Menu.Item>

    <Menu.Item key="2" style={{ textAlign: "right" }}>
    <Link to="/ar/stocks-trading-platforms/open-stocks-demo">
      الأسهم العالمية
      </Link>
    </Menu.Item>


    {/* <Menu.Item key="2" style={{ textAlign: "right" }}>
      <Link to="/ar/physical-trading-platforms/open-noor-trader-demo">
        التداول المادي
      </Link>
    </Menu.Item> */}
    <Menu.Item key="3" style={{ textAlign: "right" }}>
      <Link to="/ar/institutional/request-demo">مزود السيولة</Link>
    </Menu.Item>
    <Menu.Item key="4" style={{ textAlign: "right" }}>
      <Link to="/ar/about/contact-us">تواصل معنا</Link>
    </Menu.Item>
  </Menu>
);

const openlive = (
  <Menu>
    <Menu.Item key="1" style={{ textAlign: "right" }}>
      <a
        href="https://mynoor.noorcap.ae/en/registration/signup"
        target="_blank"
      >
        تداول العملات والسلع
      </a>
    </Menu.Item>
    <Menu.Item key="2" style={{ textAlign: "right" }}>
      <Link to="/ar/stocks-trading-platforms/open-gtn-live">
        الأسهم العالمية
      </Link>
    </Menu.Item>
    {/* <Menu.Item key="3" style={{ textAlign: "right" }}>
      <Link to="/ar/physical-trading-platforms/open-noor-trader-live">
        التداول المادي
      </Link>
    </Menu.Item> */}
    <Menu.Item key="4" style={{ textAlign: "right" }}>
      <Link to="/ar/institutional/request-live">مزود السيولة</Link>
    </Menu.Item>
    <Menu.Item key="5" style={{ textAlign: "right" }}>
      <Link to="/ar/about/contact-us">تواصل معنا</Link>
    </Menu.Item>
  </Menu>
);

const UpFooterAr = () => {
  return (
    <>
      <Row style={{ backgroundColor: "#1C74BC", color: "#fff" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <section
            className="fdb-block"
            style={{ padding: "30px 0", textAlign: "right" }}
          >
            <div className="container">
              <div className="row justify-content-center align-items-center pt-5">
                <div className="col-8 col-sm-3">
                  <VideoAr />
                  {/* <img alt="Noor Capital" className="img-fluid" src={ctaimg} /> */}
                </div>

                <div className="col-12 col-sm-6 m-auto pt-4 pt-sm-0">
                  <h3>
                    <strong
                      style={{
                        color: "#fff",
                        textAlign: "right",
                        fontSize: "30px",
                      }}
                    >
                      ابدأ رحلة التداول الخاصة بك مع نور كابيتال
                    </strong>
                  </h3>
                  <p className="lead">
                    <ul class="list-group list-group-flush">
                      <li
                        className="list-group-item"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <i
                          class="far fa-check-circle"
                          style={{ paddingLeft: "7px" }}
                        ></i>{" "}
                        حلول مالية واستثمارية متعددة
                      </li>
                      <li
                        className="list-group-item ifontonmobile"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <i
                          class="far fa-check-circle "
                          style={{ paddingLeft: "7px" }}
                        ></i>{" "}
                        منصات قوية متطورة ومبتكرة
                      </li>
                      <li
                        className="list-group-item"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <i
                          class="far fa-check-circle"
                          style={{ paddingLeft: "7px" }}
                        ></i>{" "}
                        إستشارات استثمارية ممتازة
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="col-12 col-sm-3 text-center pt-4 pt-sm-0">
                  <p>
                    <Dropdown overlay={opendemo} style={{ zIndex: "1000" }}>
                      <Button
                        className="btn btn-light"
                        style={{
                          color: "#212529",
                          backgroundColor: "#f8f9fa",
                          padding: "18px",
                          lineHeight: "2px",
                          fontWeight: "600",
                          minWidth: "185px",
                        }}
                      >
                        جرّب حساب تجريبي <DownOutlined />
                      </Button>
                    </Dropdown>
                  </p>
                  <p>
                    <Dropdown overlay={openlive} style={{ zIndex: "1000" }}>
                      <Button
                        className="btn btn-light"
                        style={{
                          color: "#212529",
                          backgroundColor: "#f8f9fa",
                          padding: "18px",
                          lineHeight: "2px",
                          fontWeight: "600",
                          minWidth: "185px",
                        }}
                      >
                        افـتح حـساب حـقيقي
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>


          <div className="row align-items-center justify-content-center">
            <div className="col-auto p-2">
              <h3>ابق على اطلاع على آخر أخبار ومقالات مدونة نور كابيتال </h3>
            </div>
            </div>

            <div className="row align-items-center justify-content-center p-2">

            <div className="col-auto mt-5 mt-sm-0 align-items-center justify-content-center">
              <Link
                className="btn btn-primary"
                to="/ar/blog"
                style={{ marginLeft: "30px", width: "135px" }}
              >
                المدونة
              </Link>
              <Link
                className="btn btn-primary"
                to="/ar/media-center/press-release"
                style={{ width: "135px" }}
              >
                الأخبار الصحفية
              </Link>
            </div>

            <div className="col-auto mt-5 mt-sm-0 align-items-center justify-content-center">
              <Link
                className="btn btn-primary"
                to="/ar/media-center/activities"
                style={{ marginLeft: "30px", width: "135px" }}
              >
                الأنشطة
              </Link>
              <Link
                className="btn btn-primary"
                to="/ar/media-center/awards"
                style={{ width: "135px" }}
              >
                الجوائز
              </Link>
            </div>
          
          
          
          </div>


        </div>
      </section>
    </>
  );
};

export default UpFooterAr;
