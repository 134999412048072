import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bggtn.jpg";
import mt4mobile from "../../assets/images/pages/trading/gtnplatformmobile.png";
import WhyNoor from "./whyNoor";

const { Title } = Typography;

const style = { padding: "50px 0" };

const GTN_GTNMobile = () => {
  return (
    <div>
      <PageseMetaTags
        title="Noor Capital Stocks Trading Platform (GTN)"
        description="Noor Capital Stocks Trading Platform (GTN) Mobile is an advanced and innovative trading app that gives you the power to trade from anywhere in the world from your mobile."
      />
      <BgBefore
        pageHeadingTitle="Handy App To Start Stocks Trading"
        pageHeadingDescription="With Noor Capital Stocks Trading Platform (GTN) Mobile App, Trading World Is In Your Palm"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="Stocks Trading Mobile Platform"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Download Noor Capital's Stocks Trading (GTN) Mobile App, And Start
            Trading On The Go
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              Stay ahead of the game and start trading with confidence. Noor
              Capital Stocks Trading Platform (GTN) Mobile is an advanced and
              innovative trading app that gives you the power to trade from
              anywhere in the world from your mobile.
            </p>
            <p className="parjustify">
              We have revolutionized mobile trading, making it easier for our
              traders to carry their daily activities on the go with a
              cutting-edge platform. With just a click on your mobile, now you
              can start buying/selling stocks, get the latest industry news, set
              your favorite stock in the watch list, and much more.
            </p>
            <p className="parjustify">
              Designed to make our trader's life easier, this Noor Capital
              Stocks Trading Platform (GTN) Mobile compatible with Android and
              iPhone, available on Playstore and App Store, respectively.
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 m-sm-auto">
                <i
                  class="fab fa-android"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>Android</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://play.google.com/store/apps/details?id=com.gtn.noor"
                    target="_blank"
                  >
                    Download
                  </a>
                </p>
              </div>
              <div className="col-12 col-sm-10 col-md-6 col-lg-4 ml-sm-auto mr-sm-auto mt-5 mt-md-0">
                <i
                  class="fab fa-apple"
                  style={{ fontSize: "100px", color: "#1b75bc" }}
                ></i>
                <h3>IPhone</h3>

                <p className="mt-3">
                  <a
                    className="btn btn-light sl-1"
                    href="https://apps.apple.com/ae/app/noor-capital-gtn/id1521622979"
                    target="_blank"
                  >
                    Download
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                Why Do You Need To Download Our Mobile Platform – Right Away?
                </Title>
               
                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Start trading on your mobile instantly from anywhere.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Compatible with all major Android phones And iPhones.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get access to 60k+ stocks.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get instant access to GCC, Europe, and the US market.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Trade on well known companies (Facebook, Microsoft, Tesla,
                  etc.).
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                 Experience the most secure and user-friendly trading platform around the world.
                </p>

              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Buy, sell, cancel, and amend orders at a click.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Receive real-time data, charts, reports, and announcements.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Choose your preferred language (English/Arabic).
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Add and track your favorite stocks in the smart watch list.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Manage your account balance and buying limits with an account
                  summary.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Manage multiple portfolios.
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4mobile} />
              </div>
            </div>
          </div>
        </section>
      </div>


      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Start Trading On The Go With Noor Capital Stocks Trading Platform (GTN)? 
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/stocks-trading-platforms/open-gtn-live"
                  
                >
                  Register Live Account
                </Link>

              </p>
            </div>
          </div>
        </div>
      </section>


   
    </div>
  );
};

export default GTN_GTNMobile;
