import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import { Link } from "react-router-dom";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgpartnershipbanner4Ar.jpg"
import investmentimg from "../../assets/images/pages/services/investment.jpg";
 import WhyNoorAr from "./whyNoorAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";

const style = {padding: '50px 0' };
const notes = [
  'نموذج عمل فريد مع دعم كامل لبدء وتوسيع أعمالك',
  'الوصول إلى حلولنا الاستثمارية القوية',
  'سريع وسهل الانضمام',
  'الوصول المباشر إلى السوق للعملاء المؤسسيين والمتداولين الخوارزمين',
  'أسرع تنفيذ وأقل زمن انتقال وأفضل سعر',
  'تمتع بالسيولة المصرفية من الدرجة الأولى مع التنفيذ الفوري للسوق',
 ];
const { Title } = Typography;

const Institutional_WhiteLabelSolutionsAr = () => {
  return( <div>
            <PageseMetaTags
                title="White Label Solutions - Forex Trading | Noor Capital"
                description="White label solutions offer business the ability to grow their business with new assets including forex and CFDs under their brand."
            />

            <BgBeforeAr 
            pageHeadingTitle="ابدأ أعمالك المالية أو الوساطة الخاصة بك على الفور !!"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenuAr
        pageMainCategoryName = "الرئيسية"
        pageMainCategoryPath = "/"
        
        pageSubCategoryName = "الشراكة"
        pageSubCategoryPath = "#"

        pageName = "برنامج العلامة البيضاء"
        />

<div className={globalstyle.container}>
        <br />
        <br />
        <Row>
        <Title style={{ color: "#1b75bc" }} level={3}>برنامج العلامة البيضاء</Title>
          
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
            مع حلول العلامة البيضاء من نور كابيتال، يمكنك بدء أعمالك المالية أو الوساطة بسهولة. نحن نقدم فرص عمل فريدة من خلال فرق وموارد مخصصة لدعمك طوال رحلتك التداولية
            </p>

            <p className="parjustify text-right">
            يمنحك حلول العلامة البيضاء لدينا الوصول إلى السوق المباشر من خلال بروتوكول التبادل بالمعلومات المالية مما يساعدك في توفير السيولة المصرفية من المستوى 1 والتنفيذ الفوري للسوق وقيمة التداول الخوارزمي.
            </p>

            

          </Col>

        </Row>


        

      <Divider orientation="left"><Title level={3} style={{color: "#1b75bc" }}>الفوائد والميزات 

</Title></Divider>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        
    <List
      size="small"
      className="text-right"
      bordered
      dataSource={notes}
      renderItem={item => 
      <List.Item><i class="fas fa-angle-double-left" style={{ fontSize: "16px", marginLeft: "10px" , color: "#1b75bc" }}></i>{item}</List.Item>
    }
    />
    <br/>
        </Col>
      </Row>

<br/>
      


      
      <style jsx>{
            `
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd){
              background-color: #f1f1f1;
          }
            `
        }
        </style>

      </div>  


      <section className="fdb-block" style={{"backgroundColor":"#f2f4f7"}}>
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
            

              <Title style={{"color":"#11131b"}} level={3}>
              دع خبرائنا يساعدونك في حلول العلامة البيضاء
                </Title>
              <p className="mt-5 mt-sm-4">
                <Link className="btn btn-outline-primary" to="/ar/about/contact-us">تواصل معنا</Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="fdb-block" style={{"backgroundColor":"#fff" , "padding": "20px"}}>

      </section> */}

      <WhyNoorAr />
  </div>
  )

};

export default Institutional_WhiteLabelSolutionsAr;