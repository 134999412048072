import React from "react";
import { Row, Col } from 'antd';
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
 import img1 from "../../assets/images/technical.jpg";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import OpenTicketFormAr from "../forms/openTicketFormAr";

const style = {padding: '50px 0' };

const TechnicalSupportAr = () => {
  return( <div>
            <PageseMetaTags
                title="Technical Support - Noor Capital"
                description="Noor Capital offers you robust technical support for all your inquiries related to our products, platform, and services"
            />

            <BgBeforeAr 
            pageHeadingTitle="فريق متخصص من الخبراء الفنيين في خدمتك 24/5"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        
        <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="Support"
        pageSubCategoryPath="#"
        pageName="دعم فني"
      />

      
      <section className="fdb-block pt-0" style={{ paddingBottom: "10px", "backgroundColor":"#fff" }}>
        <br />

        <div className={globalstyle.container}>
          <div className="row-100" />
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <p className={globalstyle.parjustify}>
              تقدم لك نور كابيتال دعمًا فنيًا قويًا لجميع استفساراتك المتعلقة بمنتجاتنا و منصتنا وخدماتنا.
              </p>

              <p className={globalstyle.parjustify}>
              يعمل فريق الخبراء لدينا بلا كلل 24/5 من الاثنين إلى الجمعة لتزويدك بدعم العملاء الكامل وضمان تجربة سلسة ومرضية مع نور كابيتال.
              </p>

                {/* General inquiries */}
                <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> الاستفسارات العامة </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:info@noorcapital.ae">info@noorcapital.ae</a>
              </p>

              {/* Customer Services */}
              <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> خدمة العملاء </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:cs@noorcapital.ae">cs@noorcapital.ae</a>
              </p>
              <p className="text-right">
                <i
                  class="fas fa-phone text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">0097142795400</a>
              </p>

              {/* Onboarding */}
              <p className="h3 mt-4 mt-lg-6 text-right">
                <h3 style={{ color: "#1b75bc" }}> فتح الحسابات </h3>
              </p>
              <p className="text-right">
                <i
                  class="far fa-envelope text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="mailto:onboarding@noorcapital.ae">
                  onboarding@noorcapital.ae
                </a>
              </p>
              <p className="text-right">
                <i
                  class="fas fa-phone text-right"
                  style={{ fontSize: "16px", marginLeft: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">0097142795400</a>
              </p>
            
              
            </div>
            <div className="col-12 col-md-6 ml-auto text-right">
            
             
            <h6 style={{ color: "#1b75bc" }}>املأ نموذج الاتصال أدناه، وسنتواصل معك في غضون 24 ساعة. إذا كنت ترغب في التحدث مع وكلائنا المباشرين.</h6>
            <br/>
            
          <OpenTicketFormAr />

            </div>
          </div>
        </div>
      </section>

<br/>
<br/>
<br/>

  </div>
  )

};

export default TechnicalSupportAr;