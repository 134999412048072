import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgmt4Ar.jpg";
import WhyNoorAr from "./whyNoorAr";
import Mt4Video from "./mt4videoAr.js";

const { Title } = Typography;
const style = { padding: "50px 0" };

const Metatrader4Ar = () => {
  return( <div>
          <PageseMetaTags
                title="MetaTrader 4 (MT4) - Noor Capital"
                description="Explore the whole new world of trading with Noor Capital's MetaTrader 4 (MT4) that offers robust functionality for our Forex and CFD traders with various trading options, custom indicators, trade automation, and technical analysis."
            />

            <BgBeforeAr 
            pageHeadingTitle="تداول بثقة على منصة ميتاتريدر MT4 الرائدة في العالم "
            pageHeadingDescription="منصة ميتاتريدر من نور كابيتال، منصة تداول قوية تمكنك من التداول بسهولة وسلام."
            pageHeadingbg={img1}
            
            />
        <BreadcrumbMenuAr 
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="تداول العملات والسلع"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}></Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            اكتشف عالم التداول الجديد بالكامل مع ميتاتريدر MT4 من نور كابيتال الذي يوفر فرص قوية لمتداولي الفوركس وعقود الفروقات لدينا مع خيارات تداول متنوعة ومؤشرات مخصصة ونظام التداول الآلي والتحليل الفني.
            </p>
            <br/>
            <p className="parjustify">
            لقد أنشأنا بأنفسنا كمنصة ميتاتريدر MT4 رائدة تتيح للمتداول لدينا بشكل دقيق تخصيص أنشطة التداول الخاصة بهم واكتساب ميزة تنافسية. سواء كنت جديدًا في عالم التداول أو محترفًا في السوق، فإن منصتنا المتوافقة للغاية مع كافة الأنظمة وسهلة الاستخدام هي الحل الأمثل لتعزيز تجربة التداول الخاصة بك.
            </p>
            <br/>
            <p className="parjustify">
            تداول على منصة ميتاتريدر MT4 من نور كابيتال وجرب تنفيذًا سريعًا للتداول وفروق أسعار من الدرجة الأولى وأدوات تداول متطورة تساعدك طوال رحلة التداول.
            </p>
            <br/>
            <p className="parjustify">
            متوافق مع جميع الهواتف الذكية الرائدة، يمكنك بسهولة تنزيل التطبيق المتاح على متجر جوجل بلاي ستور Google Playstore لنظام أندرويد Android وأب ستور AppStore لأجهزة الايفون iPhone. علاوة على ذلك، يمكن للمتداولين الآن الوصول إلى منصة ميتاتريدر MT4 الخاصة بشركة نور كابيتال على أجهزة الكمبيوتر الخاصة بهم أو من أي متصفح ويب في أي مكان في العالم.
            </p>

            <br/>

            <Link
                  to="/ar/trading-platforms/mt4-open-forex-demo"
                  className="btn btn-primary btn-lg ml-4"
                  style={{ width: "250px", marginTop: "25px"}}
                >
                 إنشاء حساب تجريبي 
                </Link>
              
              <a
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                  className="btn btn-success btn-lg"
                  style={{ width: "250px", marginTop: "25px"}}
                >
                  إنشاء حساب حقيقي 
                </a>
                
              

          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Mt4Video />
          </Col>
        </Row>
      </div>
      <br />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={1}>
              الميزات
              </Title>
              <h5>
              فإن منصة ميتاتريدر MT4 الخاصة بشركة نور كابيتال سوف تغير طريقة تداولك، مما يساعدك على تحقيق أقصى قدر من الفوائد والبقاء في المقدمة في السوق
                </h5>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h4 style={{ color: "#1b75bc" }}>
              منصة تداول قوية
              </h4>
              <p className="lead">
              تساعد منصة ميتاتريدر MT4 من نور كابيتال المتداولين على إجراء عمليات التداول مهما كانت معقدة بسرعة وسهولة حيث تتيح لك هذه الواجهة الواضحة للغاية والقابلة للتخصيص وسهلة الاستخدام تحديد الأنماط وفرص التداول، وتقليل المخاطر إلى الحد الأدنى ومساعدتك على البقاء في صدارة اللعبة.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
            <h4 style={{ color: "#1b75bc" }}>
            سهلة الوصول إليها ومتوافقة للغاية
              </h4>
              <p className="lead">
              مع منصة ميتاتريدر MT4 من نور كابيتال، يمكنك التداول من أي مكان في العالم؛ كل ما تحتاجه هو اتصال بالإنترنت حيث يمكن الوصول إليها من جميع متصفحات الويب الرائدة، وتحتوي على إصدار سطح مكتب سريع وسهل الإعداد أما بالنسبة لأولئك الذين يحبون التداول أثناء التنقل، يمكنك تنزيل تطبيق الهاتف المحمول المتوافق مع جميع أجهزة الاندرويد Android والايفون iPhone الرائدة.
              </p>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
            <h4 style={{ color: "#1b75bc" }}>
            المستشارون الخبراء
              </h4>
              <p className="lead">
              لمساعدة متداولينا على تحقيق أقصى فائدة على منصتنا، لدينا فريق متخصص من المستشارين الخبراء لمعالجة أي استفسارات أو تقديم نصائح، متوفر على مدار الساعة طوال أيام الأسبوع 24/5 حيث تساعدك ميزة التعليقات الخاصة بالتداول على تحسين قرارات التداول والأداء.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
            <h4 style={{ color: "#1b75bc" }}>
            المؤشرات والرسوم البيانية المخصصة
              </h4>
              <p className="lead">
              تسمح الرسوم البيانية المتقدمة لمنصة ميتاتريدر MT4 الخاصة بشركة نور كابيتال للمتداولين بالتحليل باستخدام مجموعة واسعة من المؤشرات والأطر الزمنية وأدوات الرسم المخصصة لتعزيز تجربة التداول الخاصة بك.
              </p>
            </div>
          </div>
        </div>
      </section>

      <br />

      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              ابدأ رحلة التداول الخاصة بك مع منصة ميتاتريدر MT4 من نور كابيتال - على الفور 
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  الاشتراك في حساب حقيقي
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


  </div>
  )

};

export default Metatrader4Ar;