import React from "react";
import mt4video from "../../assets/images/pages/trading/mt4-whiteAr.mp4"


const Mt4VideoAr = () => {
    return (
        <>
        
<video  id="video" className="fx-lazy loaded" style={{"height":"550px"}} autoPlay loop muted>
      <source src={mt4video} type="video/mp4"/>
  Your browser does not support the video tag.
</video>
  
<style jsx>
        {`
          @media all and (max-width: 600px) {
            #video {display: none;} 
        }
        `}
      </style>

            
</>
    );
};

export default Mt4VideoAr;
