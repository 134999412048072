import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgservicesbanner2.jpg";
import assetimg from "../../assets/images/pages/services/portfolio.jpg";
import alternative from "../../assets/images/pages/services/alternative.jpg";
import pooled from "../../assets/images/pages/services/pooled.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };

const { Title } = Typography;

const AssetManagement = () => {
  return (
    <div>
      <PageseMetaTags
        title="Asset Management Solutions in Dubai - Noor Capital"
        description="If you need assistance with your investments, then delegate your portfolio management to our professionals."
      />

      <BgBefore
        pageHeadingTitle="Noor Capital's Asset Management Service - A 360° Investment Solution"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Services"
        pageSubCategoryPath="#"
        pageName="Asset Management"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Asset Management
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              Noor Capital provides its clients with a 360° Asset Management
              solution that includes a broad array of investment strategies and
              advice across all major asset classes. This includes global
              equity, fixed income, money markets, currency, and alternative
              investment products (investment vehicles with non-traditional
              investment objectives and strategies).
            </p>

            <p className="parjustify">
              We provide a seamless asset management solution to institutions,
              high net worth individuals, and retail investors through direct
              and third-party channels. As institutional investors, we target
              including Sovereign Wealth Funds (SWFs), corporations, insurance
              companies, foundations, and endowments.
            </p>
          </Col>
        </Row>

        <br />

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <Divider orientation="left">
              <Title level={3} style={{ color: "#1b75bc" }}>
                Products & Services
              </Title>
            </Divider>

            <div className="row text-left mt-5">
              <div className="col-12 col-sm-8 col-md-4 col-lg-3 m-sm-auto mr-md-auto ml-md-0">
                <img
                  alt="Pooled Investment Products"
                  className="img-fluid rounded"
                  src={pooled}
                  style={{ marginBottom: "18px" }}
                />
                <br />
                <Title style={{ color: "#1b75bc" }} level={4}>
                  Pooled Investment Products
                </Title>
                <ul>
                  <li className="list">Mutual Funds</li>
                  <li className="list">
                    Real Estate Investment Trusts (REITs)
                  </li>
                  <li className="list">Investment Plans</li>
                </ul>
              </div>
              <div className="col-12 col-sm-8 col-md-4 col-lg-3 m-sm-auto pt-5 pt-md-0">
                <img
                  alt="Alternative Investment Solutions"
                  className="img-fluid rounded"
                  src={alternative}
                  style={{ marginBottom: "18px" }}
                />
                <br />
                <Title style={{ color: "#1b75bc" }} level={4}>
                  Alternative Investment Solutions
                </Title>
                <ul>
                  <li className="list">Structured Products</li>
                  <li className="list">Private Equity</li>
                </ul>
              </div>
              <div className="col-12 col-sm-8 col-md-4 col-lg-3 m-sm-auto ml-md-auto mr-md-0 pt-5 pt-md-0">
                <img
                  alt="Segregated Portfolios"
                  className="img-fluid rounded"
                  src={assetimg}
                  style={{ marginBottom: "18px" }}
                />
                <br />
                <Title style={{ color: "#1b75bc" }} level={4}>
                  Segregated Portfolios
                </Title>
                <ul>
                  <li className="list">Managed Portfolios</li>
                  <li className="list">Non-discretionary Investment Advice</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }

            .list {
              list-style-type: disc !important;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                Providing You With An Unmatched Experience
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/about/contact-us"
                >
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block">
        <div className="row">
          <div className={globalstyle.container}>
            <div className="col" style={{ padding: "15px" }}>
              <h1
                className="display-5 text-center"
                style={{ color: "#11131b" }}
              >
                Other Services
              </h1>
            </div>

            <div className="row text-left">
              <div className="col-12 col-md-8 m-auto col-lg-4">
                <div className="fdb-box fdb-touch">
                  <h4>Financial Advisory</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                  With Noor Capital's initial focus being UAE and KSA, we have successfully widened our advisory service across the MENA regions.
                  </p>
                  <p>
                    <Link to="/services/global-financial-advisory">
                      Read more
                    </Link>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>Investment</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                  Noor Capital offers exclusive investment opportunities to regional investors, helping them to create and manage their investments safely.
                  </p>
                  <p>
                    <Link to="/services/investment">Read more</Link>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>Fund Administration</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                  Noor Capital provides you with a highly efficient and all-inclusive Fund Administration solution, especially for setting up asset management.
                  </p>
                  <p>
                    <Link to="/services/funds-administration">Read more</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssetManagement;
