import React from "react";
import { Row, Col } from 'antd';

import globalstyle from '../../style.module.css';

import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = { padding: "50px 0", alignSelf: "justify" };

const CookiesPolicy = () => {
  return( <div>
          <PageseMetaTags
                title="Cookies Policy - Noor Capital"
                description="Noor Capitals' Cookies Policy"
            />

            <BgBefore 
            pageHeadingTitle="Cookies Policy"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Cookies Policy"
      />

  <div className={globalstyle.container}>
        <section className="fdb-block">

{/* 111111111111111111 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Cookies Policy</h4>
              <p className={globalstyle.parjustify}>
              Noor Capital website uses "Cookies" and other similar technologies sent through your browser to track and enhance our visitor's experience on our website. The cookies are strictly used for marketing and analysis purposes only. It allows us to collect some information about your browser type, location, IP address, and more cookies enabled by enabling cookies. Although most browsers are set to accept the cookies, you can set your browser to refuse the cookies if you prefer. Please note that not accepting cookies might affect your experience with the website.
              </p>

              <a
                  href="#"
                  className="btn btn-success ml-4"
                  style={{ width: "150px",     "marginRight": "35px" }}
                >
                 Accept Cookies
                </a>
              
              <a
                  href="#"
                  className="btn btn-secondary "
                  style={{ width: "150px" }}
                >
                  Reject Cookies
                </a>

             

            </div>
          </div>
          </section>
    </div>

  </div>
  )

};

export default CookiesPolicy;