import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import alsayegh from "../../assets/images/board/alsayegh.jpg";
import zamzam from "../../assets/images/board/zamzam.jpg";
import alhasawi from "../../assets/images/board/hasawi.jpg";
import almeshaal from "../../assets/images/board/meshaal.jpg";
import faisalgaladari from "../../assets/images/board/mr-faisalgaladari.jpg";
import jaberalsuwaidi from "../../assets/images/board/suwaidi.jpg";
import afaleq from "../../assets/images/board/saleh-hassan-al-afaleq.jpg";
import badran from "../../assets/images/board/tariq-khalil-badran.jpg";
import allboard from "../../assets/images/board/allboard.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import abdullahalsaadi from "../../assets/images/board/abdullahalsaadi.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };

const BoardMembersAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="عضو مجلس الإدارةs | Noor Capital"
        description="عضو مجلس الإدارةs | Noor Capital"
      />
      <BgBeforeAr
        pageHeadingTitle="أعضاء مجلس الإدارة"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="أعضاء مجلس الإدارة"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div
            className="row text-center justify-content-center"
            style={{ marginBottom: "60px" }}
          >
            <div className="col-10">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
                أعضاء مجلس الإدارة
              </h2>

              <img
                    alt="Noor Capital Board Members"
                    className="img-fluid rounded imgshadow boardimage"
                    // className="img-fluid rounded-circle imgshadow"
                    src={allboard}
                    
                  />
            </div>
          </div>

          {/* 111111111111 */}

          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "10px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Mohammed Ghosheh"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={alsayegh}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 30px 10px 0px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>السيد. عبد الجبار الصايغ</h3>
                  <p className="lead">رئيس مجلس إدارة نور كابيتال</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >

يتمتع السيد. عبد الجبار الصايغ، رئيس مجلس الإدارة وأحد مؤسسي شركة نور كابيتال، بصفته صاحب رؤية ورائد بارز في المجال وساهم بشكل كبير في جعلنا واحدة من أفضل الشركات الاستثمارية محليًا ودوليًا. فهو رجل أعمال متفاني ومتميز، يلعب أيضًا دورًا مهمًا باعتباره رئيس مجلس إدارة ومدير تنفيذي في مجموعة شركات الصايغ وإخوانه، التي تضم أكثر من 4500 موظف في الإمارات العربية المتحدة، وهو متخصص في الهندسة والبناء والإلكترونيات والمقاولات المدنية وأنظمة الطاقة الشمسية وإدارة مراكز اللغات في بيرلتز الإمارات العربية المتحدة ومدارس مايور والمنيرة الخاصة. مع سنوات من الخبرة والتألق المطلق، لعب السيد. عبد الجبار الصايغ دورًا حيويًا في مناصبه السابقة في العديد من المؤسسات المرموقة بداية من جهاز أبوظبي للاستثمار (ADIA)، وشركة أبوظبي للاستثمار (ADIC) إلى بنك أبو ظبي التجاري (ADCB). السيد. عبد الجبار الصايغ، وهو رجل أعمال مؤثر وشخصية بارزة في دولة الإمارات العربية المتحدة ومسافر شغوف، حاصل على درجة بكالوريوس العلوم في إدارة الأعمال من جامعة دنفر بولاية كولورادو، بسنوات من الخبرة والمعرفة التي أفادته في التنمية الشاملة لشركة نور كابيتال.


                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <hr width="30%" />

          {/* 222222222222 */}
          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Eng. Abdulla Al Suwaidi"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={alhasawi}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>د. حمد الحساوي</h3>
                  <p className="lead">نائب رئيس مجلس الإدارة </p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    يتمتع الدكتور حمد الحساوي، الأمين العام لجمعية البنوك
                    الكويتية، وهو اسم احترافي وذائع الصيت في القطاع المالي
                    والاستثماري، بخبرة هائلة مع العلامات التجارية البارزة مثل
                    شركة الخليج للاستثمار وشركة كيمكو KMCO وشركة الخليج القابضة
                    وشركة رساميل للهيكلة المالية. الدكتور حمد الحساوي، الذي عمل
                    مؤخرًا كأستاذ زائر لدى الجامعة الأمريكية في الكويت، قدم
                    المساعدة إلى معهد الكويت للأبحاث العلمية والمكتب الفني
                    للشراكات التابع لوزارة الدفاع بصفته مستشارًا بخبرته ومعرفته
                    اللتين لا تشوبهما شائبة. الدكتور حمد الحساوي، حاصل على درجة
                    الدكتوراه في العلوم والهندسة والإدارة وساهم الدكتور حمد
                    الحساوي بشكل إيجابي في قطاعي التمويل والاستثمار من خلال
                    دراساته وأبحاثه المختلفة.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />

          {/* 333333333333 */}
          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Muhammad Al Zoubi"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={zamzam}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>د. عبد الله عيسى زمزم</h3>
                  <p className="lead">عضو مجلس الإدارة</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    يتمتع بأكثر من 30 عامًا من الخبرة القوية في مختلف القطاعات،
                    بما في ذلك النفط والغاز والمجال الأكاديمي والعقارات، يشغل
                    الدكتور عبد الله عيسى زمزم منصب مساعد الأمين العام للعمليات
                    في هيئة البيئة - أبوظبي (EAD)، وذلك ساهم بشكل كبير في عمله
                    ومعرفته. عبد الله عيسى زمزم قائد فكري رفيع المستوى حاصل على
                    درجة الدكتوراه في استراتيجيات التنمية الاقتصادية المستدامة
                    من جامعة كوفنتري بالمملكة المتحدة، وكذلك درجة الماجستير في
                    إدارة الأعمال في الإدارة من جامعة وودبري.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />

          {/* 44444444444 */}
          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Sher Ali"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={faisalgaladari}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>السيد. فيصل كلداري</h3>
                  <p className="lead">عضو مجلس الإدارة</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    السيد. فيصل كلداري، الرئيس التنفيذي لبنك المصرف، هو أيضًا عضو في مجموعة شهيرة وناجحة للغاية - مجموعة كلداري إخوان دبي. يدير السيد. فيصل كلداري المعروف أيضًا برؤيته ومهاراته في مجال الأعمال، العديد من المشاريع الناجحة في الإمارات العربية المتحدة وفي جميع أنحاء العالم.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 55555555555 */}

          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Adel M. El Hassan"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={jaberalsuwaidi}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>
                  السيد. راشد سيف جبر السويدي
                  </h3>
                  <p className="lead">عضو مجلس الإدارة</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    يُعرف السيد. راشد سيف جبر السويدي بين رواد الصناعة بتفانيه
                    المطلق وسنوات خبرته، وقد شغل مناصب بارزة عدة وهي: منصب مدير
                    الاستكشاف والإنتاج في شركة بترول أبوظبي الوطنية (ADNOC) إلى
                    منصب رئيس مجلس إدارة شركة الحفر الوطنية (NDC)، وشركة أبوظبي
                    للكيماويات والحفر المحدودة (ADDCAP) وشركة أبوظبي لتشغيل
                    الموانئ البترولية (ADPPOC). لقد تولى كل دور وتحدي بشجاعة
                    وكرامة، وأيضًا قطع شوطًا طويلاً ولديه خبرة لا مثيل لها في
                    قطاع النفط والغاز. كما كان أيضًا المساهم المؤسس وعضو مجلس
                    إدارة شركة آبار للاستثمار. السيد. راشد سيف جبر السويدي هو
                    عضو استثنائي في نور كابيتال الذي يتمتع بسنوات من الخبرة
                    والمشورة لتحسين الشركة.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 6666666666 */}
          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Adel M. El Hassan"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={afaleq}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>السيد. صالح حسن العفالق</h3>
                  <p className="lead">عضو مجلس الإدارة</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >

من الأسماء البارزة في الإمارات العربية المتحدة والمملكة العربية السعودية، السيد. صالح حسن العفالق، عضو مجلس إدارة بنك ابدار ونور كابيتال وهو أيضًا العضو المنتدب لشركة الكفاح القابضة والشركات التابعة لها. مع الخبرة الهائلة التي يتمتع بها، يقود العديد من المشاريع مثل شركة الأحساء للترفيه والسياحة (AHSANA)، حيث يشغل منصب رئيس مجلس الإدارة ونائب رئيس مجلس الإدارة في شركة أرباح كابيتال Arbah Capital. السيد. صالح حسن العفالق حاصل على درجة الماجستير في تنمية الموارد البشرية من جامعة سياتل بالولايات المتحدة الأمريكية، وهو شخصية بارزة في المملكة العربية السعودية. كما أنه عضو في مجلس الشورى السعودي (البرلمان السعودي) ورئيس غرفة الأحساء.

                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 77777777777777 */}
          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Adel M. El Hassan"
                    className="img-fluid rounded imgshadow"
                    // className="img-fluid rounded-circle imgshadow"
                    src={badran}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>السيد. طارق خليل بدران</h3>
                  <p className="lead">عضو مجلس الإدارة</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    مع أكثر من 10 سنوات من الخبرة المحلية والدولية في قطاع
                    التمويل، يقدم السيد. طارق خليل بدران، الرئيس التنفيذي لشركة
                    العين كابيتال، استراتيجيات مبتكرة بفضل معرفته وخبرته الهائلة
                    بالسوق. السيد. طارق خليل بدران، محاسب قانوني معتمد (CPA) في
                    المملكة المتحدة وأيرلندا والولايات المتحدة الأمريكية، شغل
                    منصب المدير العام في أبو ظبي للخدمات المالية وبنك أبوظبي
                    الوطني والمدير التنفيذي في بنك دبي التجاري.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 88888888888 */}
          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Eng. Abdulmajed Almeshaal"
                    className="img-fluid rounded imgshadow"
                    // // className="img-fluid rounded-circle imgshadow"
                    src={almeshaal}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>م. عبد المجيد المشعل</h3>
                  <p className="lead">عضو مجلس الإدارة</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    السيد المهندس عبد المجيد المشعل هو عضو مجلس إدارة في العديد
                    من الشركات العقارية والاستثمارية، ويتمتع بسنوات من الخبرة في
                    قطاع التمويل. السيد المهندس عبد المجيد المشعل حاصل على درجة
                    البكالوريوس في هندسة الكمبيوتر من جامعة ميسوري بالولايات
                    المتحدة الأمريكية، ويتمتع بخلفية قوية في الاستثمار والصندوق
                    الوقائي والملكية الخاصة والتمويل العقاري.
                  </p>
                </Col>
              </Row>
            </div>
          </div>



          <hr width="30%" />
          {/* 88888888888 */}
          <div
            className="row justify-content-center text-right"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                  <img
                    alt="Eng. Abdulmajed Almeshaal"
                    className="img-fluid rounded imgshadow"
                    // // className="img-fluid rounded-circle imgshadow"
                    src={abdullahalsaadi}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 30px 15px 15px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>السيد. عبدالله السعدي</h3>
                  <p className="lead">عضو مجلس الإدارة</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                  مع أكثر من 40 عامًا من الخبرة في القطاع المالي والاستثماري ، يعد السيد عبد الله علي السعدي إضافة رائعة لأعضاء مجلس إدارة نور كابيتال. لقد أظهر تأثيرًا مثيرًا للإعجاب من شركات معروفة جدًا مثل صندوق أبوظبي للتنمية واللجنة الاستشارية العليا للتنمية والمؤسسة الوطنية للاستثمار. يحمل درجة البكالوريوس في إدارة الأعمال وهو أحد أعضاء مجلس الإدارة من دار التمويل وشركة الإمارات للتأمين.
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          {/* <div className="row-70" /> */}
        </section>
      </div>

      <style jsx>
        {`
          .imgshadow {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19);
          }
          .boardimage{
            width: 50%;
          }
          @media only screen and (max-width: 600px) {
            .boardimage{
              width: 100%;
            }
        `}
      </style>
    </div>
  );
};

export default BoardMembersAr;
