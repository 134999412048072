import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import fundsimg from "../../assets/images/pages/services/funds-administration.jpg";
import img1 from "../../assets/images/bgservicesbanner2.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  "Noor Capital's Funds Administration is focused on protecting our investors' interests making the process extremely smooth with independent verification and valuation of funds.",
  "Highly qualified and certified fund administrators offer you complete support.",
  "Noor Capital provides  solutions especially tailored to  individuals, family-run businesses, corporates, and government-owned bodies.",
];
const { Title } = Typography;

const FundsAdministration = () => {
  return (
    <div>
      <PageseMetaTags
        title="Funds Administration Service in Dubai - Noor Capital"
        description="Your global partner for comprehensive fund administration services that enhance international operations."
      />

      <BgBefore
        pageHeadingTitle="Explore Endless potentials with Noor Capital Fund Management services"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Services"
        pageSubCategoryPath="#"
        pageName="Funds Administration"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Funds Administration Services
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Noor Capital provides you with  highly efficient and all-inclusive fund administration solutions, especially for setting up asset management companies and fund houses. We offer a ready-made solution for calculation of Net Asset Value (NAV), offer services to address shareholder concerns, help you in registration procedures, as well as other services related our fund accounting and registrar/transfer agents.
            </p>
            <p className="parjustify">
            Noor Capital provides Funds Administration services in collaboration with AL-PER Solutions, a group of highly skilled and reputable professional people  from different segments of the industry that have come together to build a generative collaboration in the area of Fund administration services.
            </p>

            <p className="parjustify">
            We are not only committed to provide high quality service, but also use our knowledge and experience in the industry to add value to the Investment Manager. Our fully integrated system combines with web-based reporting, to provide competitive advantage, compliance and anti-money laundery  policies.
                        </p>

            <p className="parjustify">
              <b>
                Read more about AL-PER Solutions{" "}
                <a href="http://www.alpersolutions.com/" target="_blank">
                  HERE
                </a>
              </b>
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={fundsimg}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        <br></br>

        <Divider orientation="left">
          <Title style={{ color: "#1b75bc" }} level={3}>
            Noor Capital Fund Administration Services
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-right"
                    style={{
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                Invest confidently With Noor Capital's Funds Administration
                Services.
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/about/contact-us"
                >
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block">
        <div className="row">
          <div className={globalstyle.container}>
            <div className="col" style={{ padding: "15px" }}>
              <h1
                className="display-5 text-center"
                style={{ color: "#11131b" }}
              >
                Other Services
              </h1>
            </div>

            <div className="row text-left">
              <div className="col-12 col-md-8 m-auto col-lg-4">
                <div className="fdb-box fdb-touch">
                  <h4>Financial Advisory</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    With Noor Capital's initial focus being UAE and KSA, we have
                    successfully widened our advisory service across the MENA
                    regions.
                  </p>
                  <p>
                    <Link to="/services/global-financial-advisory">
                      Read more
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>Asset Management</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    Noor Capital provides a solid asset management solution to
                    institutions, high net worth individuals, and retail
                    investors through direct.
                  </p>
                  <p>
                    <Link to="/services/asset-management">Read more</Link>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>Investment</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    Noor Capital offers exclusive investment opportunities to
                    regional investors, helping them to create and manage their
                    investments safely.
                  </p>
                  <p>
                    <Link to="/services/investment">Read more</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FundsAdministration;
