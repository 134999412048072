import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";

import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1Ar.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const TermsandConditionsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Terms and Conditions - Noor Capital "
        description="Noor Capital Terms and Conditions"
      />

      <BgBeforeAr
        pageHeadingTitle="شروط وأحكام الموقع الإلكتروني"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الموقع الإلكتروني"
        pageSubCategoryPath="#"
        pageName="الشروط و الاحكام"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>شروط وأحكام الموقع الإلكتروني</h4>
              <p className={globalstyle.parjustify}>
                تنظم الشروط والأحكام التالية الخاصة بالموقع إدارة استخدامك
                لموقعنا
              </p>
              <p className={globalstyle.parjustify}>
                سيتم تنفيذ هذه الشروط بالكامل وستؤثر على استخدامك للموقع.
                باستخدام هذا الموقع ، فإنك توافق على قبول جميع الشروط والأحكام.
                إذا كنت لا توافق على جميع شروط وأحكام موقع الويب ، فلا يجوز لك
                استخدامه
              </p>
              <p className={globalstyle.parjustify}>
              هذا الموقع ليس مخصصًا للقصر أو لمن تقل أعمارهم عن ثمانية عشر عامًا
              </p>
            </div>
          </div>
          <br />

          {/* 2222222222222 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>
                حقوق الملكية الفكرية والتأليف والنشر
              </h4>
              <p className={globalstyle.parjustify}>
                نور كابيتال تمتلك الحق في جميع العلامات والأسماء التجارية
                وعلامات الخدمة التي تم استخدامها على الموقع. ولا يحق لأي طرف
                استخدام أي من هذه العلامات بأي شكل من الأشكال دون إذن كتابي مسبق
                من مالكها. كما لا يمكن نقل محتوى موقع نور كابيتال أو بثه أو نسخه
                أو استخدامه بشكل أخر أو عرضه أو تنفيذه أو إعادة إنتاجه بأي شكل
                من الأشكال دون إذن مسبق من الشركة.
              </p>
            </div>
          </div>
          <br />

          {/* 333333333333333 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>محتوى الموقع الإلكتروني</h4>
              <p className={globalstyle.parjustify}>
                يخضع محتوى موقع نور كابيتال للتغيير في أي وقت دون إخطار. ويتوفر
                المحتوى من أجل مساعدة المتداولين في اتخاذ قرارات استثمار مستقلة
                وصحيحة. وتتوافر الدقة في المعلومات التي يقدمها موقع نور كابيتال.
                ومع ذلك، فإننا لا نتحمل أي مسؤولية عن الضرر أو الخسارة التي قد
                تحدث بسبب هذا المحتوى أو عدم قدرتك على الوصول إلى الموقع.
              </p>
            </div>
          </div>
          <br />

          {/* 888888888 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>إخلاء المسؤولية</h4>
              <p className={globalstyle.parjustify}>
                يجب ألا تعتمد على أي من محتويات الموقع وأن تفسيرها على أنها
                توصيات شخصية. أي أخبار أو آراء أو بحث أو تحليل أو أسعار أو غيرها
                من المعلومات الواردة على موقع نور كابيتال صُممت لتزويدك بتعليقات
                عامة على السوق ولا تعتبر توصيات استثمارية. كما لا نتحمل مسؤولية
                أي خسارة أو ضرر أو تراجع في الأرباح ينشأ بشكل مباشر أو غير مباشر
                من استخدام أو الاعتماد على هذه المعلومات.
              </p>
            </div>
          </div>
          <br />

          {/* 444444444444 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>سياسة الخصوصية</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/privacy-policy"> سياسة الخصوصية</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 555555555555 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>شروط التداول</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/trading/accounts/trading-conditions">شروط التداول</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 6666666666 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>الإفصاح عن المخاطر</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/trading-tools/risk-disclosure"> الإفصاح عن المخاطر</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 7777777777 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>تحذير من الإحتيال</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/fraud-warning"> تحذير من الإحتيال</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 7777777777 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>سياسة ملفات تعريف الارتباط</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/cookies-policy"> سياسة ملفات تعريف الارتباط</Link>
                </b>
              </p>
            </div>
          </div>

          
          <br />
        </section>
      </div>
    </div>
  );
};

export default TermsandConditionsAr;
