import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import ContactUsForm from "../forms/ContactUsForm";



const ContactUs = () => {
  return (
    <div>
      <PageseMetaTags
        title="Contact Us - Noor Capital"
        description="Want to contact the Noor Capital  team? We'd love to hear from you! Simply enter your details and message into the contact form below."
      />
      <BgBefore
        pageHeadingTitle="We Are Just A Call Or A Click Away - Find The Best Way To Get In Touch With Us"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Contact Us"
      />

      
      <section className="fdb-block pt-0" style={{ paddingBottom: "10px", "backgroundColor":"#fff" }}>
        <br />

        <div className={globalstyle.container}>
          <div className="row-100" />
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <p className={globalstyle.parjustify}>
              We offer Global Brokerage account services including instant access to financial instruments around the world such as Stocks, ETFs, Indices, Currencies and Commodities. We provide unparalleled access to the most liquid financial instruments globally. Experience our Premium Customer Support and benefit from our personalized client services.
                <br />
                <br />
                <b>Operating Days : </b> Monday to Friday
                <br />
                <b>Customer Support : </b> 24/5
              </p>

              {/* General inquiries */}
              <p className="h3 mt-4 mt-lg-6">
                <h3 style={{ color: "#1b75bc" }}> General Inquiries </h3>
              </p>
              <p>
                <i
                  class="far fa-envelope"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="mailto:info@noorcapital.ae">info@noorcapital.ae</a>
              </p>

              {/* Customer Services */}
              <p className="h3 mt-4 mt-lg-6">
                <h3 style={{ color: "#1b75bc" }}> Customer Services </h3>
              </p>
              <p>
                <i
                  class="far fa-envelope"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="mailto:cs@noorcapital.ae">cs@noorcapital.ae</a>
              </p>
              <p>
                <i
                  class="fas fa-phone-volume"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">+97142795400</a>
              </p>
              
              {/* Onboarding */}
              <p className="h3 mt-4 mt-lg-6">
                <h3 style={{ color: "#1b75bc" }}> Onboarding </h3>
              </p>
              <p>
                <i
                  class="far fa-envelope"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="mailto:onboarding@noorcapital.ae">
                  onboarding@noorcapital.ae
                </a>
              </p>
              <p>
                <i
                  class="fas fa-phone-volume"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>{" "}
                <a href="tel:+97142795400">+97142795400</a>
              </p>
              
            </div>
            <div className="col-12 col-md-6 ml-auto">
            
             
            <h4 style={{ color: "#1b75bc" }}>Have a question? Just fill out the form below, and one of our experts will respond to you within 48 hours.</h4>
            <br/>
            <ContactUsForm />

            </div>
          </div>
        </div>
        <br />
        
      </section>

      <section className="fdb-block" style={{ paddingTop: "0", "backgroundColor":"#f2f4f7" }} >
        <div className={globalstyle.container}>
          <div className="row pt-4">
            <div className="col-12 col-md-6">
              <h3 style={{ color: "#1b75bc" }}>Abu Dhabi</h3>

              <p>
                <i
                  class="fas fa-map-marker-alt"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>
                <span style={{"fontSize":"13px"}}>Office 203/204, Second Floor,Al-Montazah Tower B, Zayed the
                First Street, Khalidiyah,</span>
              </p>

              <p>
                <i
                  class="fas fa-phone-volume"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>
                <a href="tel:+97126266888">+97126266888</a>
              </p>

              <iframe
                className="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14525.98175394815!2d54.3408227!3d24.468285!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26eaf243ff76ecdc!2sNoor%20Capital%20PSC!5e0!3m2!1sen!2sae!4v1615429036056!5m2!1sen!2sae"
                style={{ border: 0 }}
                allowFullScreen
                width="100%"
                height={300}
                frameBorder={0}
              />
            </div>

            <div className="col-12 col-md-6 pt-5 pt-md-0">
              <h3 style={{ color: "#1b75bc" }}>Dubai</h3>

              <p>
                <i
                  class="fas fa-map-marker-alt"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>
                <span style={{"fontSize":"13px"}}>1st Floor, MSM 1 Building, Al Safa 1, Sheikh Zayed Road, P.O.Box 334241</span>
              </p>

              <p>
                <i
                  class="fas fa-phone-volume"
                  style={{ fontSize: "16px", marginRight: "10px" }}
                ></i>
                <a href="tel:+97142795400">+97142795400</a>
              </p>

              <iframe
                className="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.086313931967!2d55.23299567698985!3d25.166563232937882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f692e8914a2dd%3A0x2121b385b463b601!2sMSM%201%20Building!5e0!3m2!1sen!2sae!4v1683114364751!5m2!1sen!2sae"
                style={{ border: 0 }}
                allowFullScreen
                width="100%"
                height={300}
                frameBorder={0}
              />
            </div>
          </div>
        </div>
      </section>
   
   
    </div>
  );
};

export default ContactUs;
