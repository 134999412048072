import React from "react";

import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

import img1 from "../../assets/images/bgaboutbanner1.jpg";



const Tools_RiskDisclosureAr = () => {
  return( <div>
          <PageseMetaTags
                title="Risk Disclosure - Noor Capital"
                description="Trading on Margin carries a high level of risk to your capital, and you can lose more than your initial deposit. Please read the entire risk warning before opening an account with Noor Capital."
            />

            <BgBefore
            pageHeadingTitle="Risk Disclosure"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Trading Tools"
        pageMainCategoryPath="/"
        pageSubCategoryName="Tools"
        pageSubCategoryPath="#"
        pageName="Risk Disclosure"
      />

<div className={globalstyle.container}>
        <section className="fdb-block">

{/* 111111111111111111 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>Risk Disclosure</h4>
              <p className={globalstyle.parjustify}>
              <b>Please find below warnings of possible risks and business terms that you need to know before opening an account with Noor Capital:</b>
              </p>
              <p className={globalstyle.parjustify}>
              Trading on margins carries a high level of risk to your capital, and you can lose more than your initial deposit. Please read the full risk warning before opening an account with Noor Capital. Margined trades are trades based on the price movement of an underlying financial product. They are settled based on the respective difference between the opening price and closing price of the trade.
              </p>
              <p className={globalstyle.parjustify}>
              Foreign exchange (forex, FX) and Contracts for Difference (CFDs) on Currencies, Commodities, Indices, or Equities are all margin-traded products and thus inherently carry a high-risk level that may not be suitable for all investors. Before deciding to trade FX/ CFD instruments offered by Noor Capital PSC, you should carefully consider your objectives, financial situation, needs, and experience level. You should not trade any margined product unless you fully understand all the risks involved and possess sufficient resources in case of adverse movement in a product's price. You will then be able to meet the financial obligations required by you concerning margin payments and losses.
              </p>
              <p className={globalstyle.parjustify}>
              There is a possibility that you could sustain a loss of some or all of your deposited funds. Therefore, you should not speculate with capital that you cannot afford to lose. You should be aware of all the risks associated with trading on margins. Noor Capital PSC recommends to seek advice from a separate/independent financial advisor. Margin trading is based on leverage that allows you to execute large trades by only investing a small amount of money as a margin. If the price moves in your favor, you can significantly increase your profits. However, even a tiny movement in the unfavorable price for you can lead to substantial losses. You may then be required to deposit additional margin immediately to keep your positions open. You are liable for any losses that may occur if your positions are closed.
              </p>
              <p className={globalstyle.parjustify}>
              Not all trades can be opened or closed 24 hours a day. Many are subject to strict opening and closing timings that can fluctuate, depending on the market. You can find this information on our Market Information Sheets (MIS), which are available online. Your orders/positions are based on our prices and not those on any exchange. Depending on the market, our prices will usually be based on an exchange price but can fluctuate away from the underlying fees due to various reasons.
              </p>
              <p className={globalstyle.parjustify}>
              There are risks associated with online trading systems, including, but not limited to, software and hardware failure and internet disconnection. Noor Capital does not control signal power, its reception or routing via the internet, the configuration of your equipment or reliability of its connection, and cannot be responsible for communication failures, distortions or delays when trading via the internet.
              </p>
              <p className={globalstyle.parjustify}>
              The content of this website is subject to change at any time without notice and is provided for the sole purpose of assisting traders in making independent investment decisions. Noor Capital has taken reasonable measures to ensure the accuracy of the information on its website. However, we cannot guarantee its accuracy. We will not accept liability for any loss or damage that may arise directly or indirectly from the content or your inability to access the website.
              </p>
              <p className={globalstyle.parjustify}>
              You must not rely on any of the website contents or construe them as personal advice. Any opinions, news, research, analysis, prices, or other information contained on the Noor Capital website is provided as general market commentary and does not constitute any investment advice. We will not accept liability for any loss or damage, <b>including without limitation to</b>, any loss of profit, which may arise directly or indirectly from the use of or reliance on such information.
              </p>


            </div>
          </div>
          </section>
    </div>

  </div>
  )

};

export default Tools_RiskDisclosureAr;