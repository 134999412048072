import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from "rmwc";

// not done we need to change liv_GTN to API Module

// 1 ***
// import { BASE_URL, COUNTRIES, liv_GTN } from "../../constants";
import { BASE_URL, COUNTRIES, Demo_GTN } from "../../constants";

import { emirates, hearabout } from "./options";

// 1 New
import { getStorage } from "../../utils/helpers";
// 2 ***
export default function OpenGTNDemoForm() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "AE",
    emirate: "",
    hearabout: "",
    agree: false,
    checklive: "",
  };

  const [state, setState] = React.useState(initialState);

  const [disable, setDisable] = React.useState(false);

  const [countries, setCountries] = React.useState([{ name: "", code: "" }]);

  const [requestSent, setRequestSent] = React.useState(false);

  // 3 New
  let queryParams = {};


  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  };

  // 4 Need To Check
  React.useEffect(() => {
    axios
      .get(BASE_URL + COUNTRIES, { headers: headers })
      .then((countries) => {
        setCountries(countries.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // END

  function handleFormChange(event) {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  }

  function handleCountryChange(event) {
    const newState = { ...state, country: event.target.value };
    setState(newState);
  }

  // 5 New Function
  function handleHearAboutChange(event) {
    const newState = { ...state, hearabout: event.target.value };
    setState(newState);
  }

  // 6 New Function
  function handleAgreeChange(event) {
    const newState = { ...state, agree: event.target.checked };
    setState(newState);
  }

 // 3 ***
  function submitOpenGTNDemo(event) {
    setDisable(true)
    event.preventDefault();

    const data = {
      first_name: state.firstName,
      last_name: state.lastName,
      phone: state.phoneNumber,
      email: state.email,
      country: state.country,
      emirate: state.emirate,
      hearabout: state.hearabout,
      agree: state.agree,
      checklive: state.checklive,
    };
    // 7 New Data from storage
    let newData = data;
    
    if (getStorage("queryParams")) {
      queryParams = getStorage("queryParams");
      newData = {
        ...data,
        refrence_sp: queryParams.SP,
        refrence_ib: queryParams.IB,
        refrence_p: queryParams.P,
      };
    }
    // 8 add new data to post
    // 4 ***
    axios
      .post(BASE_URL + Demo_GTN, newData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          setRequestSent(true);
          setState(initialState);
        }
      }).finally(()=>setDisable(false));
      
      
  }
// 5 ***
  return (
    <div>
      <form onSubmit={submitOpenGTNDemo}>
        <div className="row">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="First name"
              name="firstName"
              value={state.firstName}
              onChange={handleFormChange}
            />
          </div>

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Last name"
              name="lastName"
              value={state.lastName}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <label>Do you have Noor Capital Account?</label>

            <br />

            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="checklive"
                value="True"
                id="radioyes"
                onChange={handleFormChange}
              />
              <label class="form-check-label" for="radioyes">
                YES
              </label>
            </div>
            <div class="form-check form-check-inline" style={{"paddingLeft":"15px"}}>
              <input
                class="form-check-input"
                type="radio"
                name="checklive"
                value="False"
                id="radiono"
                checked
                onChange={handleFormChange}
              />
              <label class="form-check-label" for="radiono">
                NO
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="email"
              required
              className="form-control"
              placeholder="Email"
              name="email"
              value={state.email}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Phone"
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleFormChange}
            />
            <small className="form-text text-muted">
              Example: +97150xxxxxxx
            </small>
          </div>
        </div>

        

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              onChange={handleCountryChange}
              value={state.country}
            >
              {countries.map((country) => (
                <option value={country.code}>{country.name}</option>
              ))}
            </select>
            <small className="form-text text-muted">Nationality</small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="emirate"
              // onChange={handleCountryChange}
              // value={state.country}
              onChange={handleFormChange}
            >
              {emirates.map((emirate) => (
                <option value={emirate.emirate_code}>
                  {emirate.emirate_name}
                </option>
              ))}
            </select>
            <small className="form-text text-muted">
              For UAE residents only
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="hearabout"
              // 9 Add Handelr for changes and update start
              onChange={handleHearAboutChange}
              value={state.hearabout}
              // onChange={handleCountryChange}
              // value={state.country}
            >
              {hearabout.map((hear) => (
                <option value={hear.hearabout_code}>
                  {hear.hearabout_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div
            className="col"
            style={{
              padding: "0px 0px 0px 35px",
            }}
          >
            <input type="checkbox" className="form-check-input" name="agree" 
            // 10 Add Handelr for changes and update state
            required
            onChange={handleAgreeChange}
            defaultChecked={state.agree}
            checked={state.agree}
            // end
            />
            <small
              className="form-text text-muted form-check-label"
              for="gridCheck"
            >
              I agree to receive information from Noor Capital about its
              products, services.
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button type="submit" className="btn btn-primary" disabled={disable}>
              Submit
            </button>
          </div>
        </div>
      </form>

      <Dialog
        preventOutsideDismiss
        open={requestSent}
        onClose={(event) => {
          setRequestSent(false);
        }}
      >
        <DialogTitle>Success!</DialogTitle>

        <DialogContent>
        Thank you
Your request has been registered  <br />
 and you shall receive your access details within 24 hours / one business day 
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">Close</DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
