import React from "react";
import { Table } from "antd";

const columns1 = [

  {
    title: "Instrument",
    dataIndex: "instrument",
    key: "instrument",
  },

  {
    title: "Trading Hours (NYT)",
    dataIndex: "trading_hours",
    key: "trading_hours",
    responsive: ["md"],
  },

  {
    title: "Contract Size",
    dataIndex: "contract_size",
    key: "contract_size",
    responsive: ["md"],
  },

  {
    title: "Pending Orders Distance",
    dataIndex: "pending_orders_distance",
    key: "pending_orders_distance",
  },

  {
    title: "Minimum Trade Size",
    dataIndex: "minimum",
    key: "minimum",
    responsive: ["md"],
  },
  
  {
    title: "Maximum Trade Size",
    dataIndex: "maximum",
    key: "maximum",
    responsive: ["md"],
  },

  {
    title: "Leverage",
    dataIndex: "leverage",
    key: "leverage",
    
  },

];


// Table Content ///////////////////////////////////////////////

const data1 = [
  {
    key: "1",

    instrument: "XAUUSD!",

    trading_hours: "Sunday 18:00 - Friday 17:00",

    contract_size: "daily halt 17:00 - 18:00",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  {
    key: "2",

    instrument: "XAGUSD!",

    trading_hours: "Sunday 18:00 - Friday 17:00",

    contract_size: "daily halt 17:00 - 18:00",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 25,

    leverage: "1:100",
  },

  
];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const Instruments_Bullions_Table = () => {
  return (
    <div>
      <Table 
      columns={columns1} 
      dataSource={data1} 
      onChange={onChange}
      pagination={{ position: 'topLeft' }}
      />

      <style jsx>{`
        .ant-table-thead > tr > th {
          font-size: 15px;
        }
        .ant-table-thead > tr > td {
        }
      `}</style>
    </div>
  );
};

export default Instruments_Bullions_Table;
