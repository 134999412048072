import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgtradingbanner3Ar.jpg";
import cashequities from "../../assets/images/pages/instruments/cash-equities.jpg";
import ProductsAr from "./productsAr";

const { Title } = Typography;

const Instruments_CashEquitiesAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Cash Equities Trading - Noor Capital"
        description="Cash equities refer to the portion of an investment that can be compensated with cash. In terms of investing, it represents common stocks issued to investors or institutions trading that stock. Noor Capital's goal is to equip you with various financial instruments and provide updated information so that when you trade equities with us you find a productive and straightforward trading environment."
      />
      <BgBeforeAr
        pageHeadingTitle="اكتشاف الآلاف من الأسهم في الأسواق العالمية"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="التداول"
        pageMainCategoryPath="#"
        pageSubCategoryName="الأسواق"
        pageSubCategoryPath="#"
        pageName="الأسهم النقدية"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            الأسهم النقدية
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              تشير الأسهم النقدية إلى جزء من الاستثمار يمكن تعويضه بمبالغ نقدية.
              ومن حيث الاستثمار، تشير إلى الأسهم العامة التي يتم إصدارها
              للمستثمرين من الأفراد أو المؤسسات التي تقوم بتداول ذلك السهم.
              وتهدف شركة نور كابيتال إلى تزويدكم بالعديد من الأدوات المالية
              وتوفير المعلومات المحدثة لكي تتمكن عندما تقوم بتداول الأسهم معنا
              من الحصول على بيئة منتجة وواضحة للاستثمار.
            </p>

            <p className="parjustify">
              وتمثل حيازة الأسهم امتلاك حصة أو جزء من الشركة المصدرة. ويمكن شراء
              تلك الأسهم للاحتفاظ بها لمدة طويلة كجزء من الأدوات الاستثمارية
              طويلة الأجل، أما إذا كنت تبحث عن تحقيق الأرباح السريعة، فيمكن
              تداول الأسهم لوقت قصير. حيث يمكنك كمتداول أن تجني أرباحًا عظيمة
              بناء على تغيرات الأسعار في السوق.
            </p>

            <p className="parjustify">
              وإذا كنت مستثمرًا في الأسهم وتبحث عن المنصة المناسبة لبدء تداولات
              آمنة وسليمة، فإن شركة نور كابيتال تقدم لك الخدمات المناسبة لتحقيق
              ذلك. حيث تقوم الشركة بعرض طيف متنوع من الأسهم. ويمكنك الوصول إلى
              منصتنا التنافسية للتداول "ميتا ترايدر" من خلال الهاتف الذكي أو
              الكمبيوتر الشخصي في أي وقت.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={cashequities}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Cash Equities Trading"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              ابدأ التداول في الأسهم النقدية الآن
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ProductsAr />
    </div>
  );
};

export default Instruments_CashEquitiesAr;
