import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtradingbanner3.jpg"
import energies from "../../assets/images/pages/instruments/energies.jpg";
import Products from "./products.js";
import Instruments_Energies_Table from "./trading_Instruments_Energies_table";


const { Title } = Typography;


const Instruments_Energies = () => {
  return( <div>
          <PageseMetaTags
                title="Trade CFDs on Energies Online with Noor Capital at Low Margin"
                description="Noor Capital allows trading of spot energy contracts including Crude Oil, Brent, and Natural Gas from your MetaTrader 4 and 5 platforms against the US Dollar. Trading energy contracts as a spot instrument has many advantages for investors who are only interested in price speculation."
            />
            <BgBefore 
            pageHeadingTitle="Grow your investment exponentially - Trade in energy derivatives now"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Trading"
        pageMainCategoryPath="#"
        pageSubCategoryName="Instruments"
        pageSubCategoryPath="#"
        pageName="Energies"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Energies
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Beside Forex, energy trading, which includes trading petroleum and natural gas, is another potential source of putting your investments on the right track. Energy markets are highly volatile because of their supply and demand fluctuations. Noor Capital provides you with all the resources you need to trade CFDs on Energies.
            </p>

            <p className="parjustify">
            Much like any other trading, energy trading needs a strategic approach. The strategic plan is a map that determines how you are going to make  profit from trading. This way, you must choose the right brokerage that provides in-time market updates and analysis. Noor Capital aims at providing you with all  useful information  you need to know to choose the right commodity by providing technical and fundamental analysis.
            </p>

          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={energies}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>
        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Specifications list
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Instruments_Energies_Table />
          
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              Trading Energies is Now Possible
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Sign-Up
                  </a>
              </p>
            </div>
          </div>
        </div>
      </section>



      <Products />


  </div>
  )

};

export default Instruments_Energies;