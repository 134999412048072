import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import img1 from "../../assets/images/bgmt4.jpg";
import WhyNoor from "./whyNoor";
import Mt4Video from "./mt4video.js";

const { Title } = Typography;
const style = { padding: "50px 0" };

const Metatrader4 = () => {
  return (
    <div>
      <PageseMetaTags
        title="MetaTrader 4 (MT4) - Noor Capital"
        description="Explore the whole new world of trading with Noor Capital's MetaTrader 4 (MT4) that offers robust functionality for our Forex and CFD traders with various trading options, custom indicators, trade automation, and technical analysis."
      />

      <BgBefore
        pageHeadingTitle="Trade With Confidence On The World's Leading MT4 Platform"
        pageHeadingDescription="Noor Capital's MetaTrader 4 (MT4) - A Trading Platform That Enables You To Trade Easily"
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="FX Trading (MT4)"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}></Title>
        </Row>
        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Explore the whole new world of trading with Noor Capital's MetaTrader 4 (MT4) that offers robust functionality for our Forex and CFDs traders with various trading options, custom indicators, trade automation, and technical analysis.
            </p>
            <br/>
            <p className="parjustify">
            We have established ourselves as one of the leading MT4 platforms that flawlessly allows our trader to customize their trading activities and gain a competitive edge. Whether you are new to the trading world or a market pro, our highly compatible and user-friendly platform is a perfect solution to enhance your trading experience.
            </p>
            <br/>
            <p className="parjustify">
            Trade using  Noor Capital's MetaTrader 4 (MT4) platform and experience a quick trade execution, top-notch spreads, and cutting-edge trading tools be your continuous support throughout your trading journey.
            </p>
            <br/>
            <p className="parjustify">
            Compatible with all leading mobile smartphones, you can easily download the app available on Google PlayStore for Android and AppStore for iPhone. Moreover, traders are now allowed to access Noor Capital’s MetaTrader 4 (MT4) on their PC or from any web browser anywhere in the world.
            </p>

            <br/>

            <Link
                  to="/trading-platforms/mt4-open-forex-demo"
                  className="btn btn-primary btn-lg ml-4"
                  style={{ width: "250px",     "marginRight": "35px" ,  marginTop: "25px" }}
                >
                 Create A Demo Account
                </Link>
              
              <a
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                  className="btn btn-success btn-lg"
                  style={{ width: "250px" ,  marginTop: "25px"}}
                >
                  Create A Live Account
                </a>
                
              

          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Mt4Video />
          </Col>
        </Row>
      </div>
      <br />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <Title style={{ color: "#1b75bc" }} level={3}>
              Here Are Some of the top reasons why Noor Capital’s MetaTrader 4 (MT4) is an Ideal Trading Platform Globally
              </Title>
              
            </div>
          </div>
          <div className="row text-left justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h4 style={{ color: "#1b75bc" }}>
                Powerful Trading Platform
              </h4>
              <p className="lead">
              Noor Capital's MetaTrader 4 (MT4) helps traders conduct trading operations in a trading environment characterized by simplicity, usability, and reasonable speed of execution. . This highly intuitive, customizable, and user-friendly interface allows you to identify the patterns and trading opportunities, minimizing the risk and helping you stay ahead of the game.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
            <h4 style={{ color: "#1b75bc" }}>
                Easy Accessible And Highly Compatible
              </h4>
              <p className="lead">
              With Noor Capital's MetaTrader 4 (MT4), you can trade from anywhere in the world; all you need is an internet connection. Accessible from all leading web browsers, it has a desktop version that is quick and easy to setup. For those who like to trade on the go, you can download the mobile app that is highly compatible with all leading Android and iPhones.
              </p>
            </div>
          </div>
          <div className="row text-left justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
            <h4 style={{ color: "#1b75bc" }}>
            Expert Advisors
              </h4>
              <p className="lead">
              To help our traders gain maximum advantage on our platform, we have a dedicated team of expert advisors to address any concerns or advice, available 24/5. Also, a customized trading feedback feature helps you to improve your trading decisions and performance.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
            <h4 style={{ color: "#1b75bc" }}>
            Custom Indicators and Charts
              </h4>
              <p className="lead">
              Our  MetaTrader 4 (MT4) advanced charts allow the traders to analyze with a vast array of custom indicators, timeframes, and drawing tools, to enhance your trading experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      <br />

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              Start Your Trading Journey With The Noor Capital's MetaTrader 4 (MT4)
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Sign-up For A Live Account
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default Metatrader4;
