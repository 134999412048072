


// export const BASE_URL = "http://localhost:8000";

// export const BASE_URL = 'http://3.14.0.149';

export const BASE_URL = 'https://noorcapital.ae';

export const POSTS = "/api/v1/posts/";

export const COUNTRIES = "/api/v1/submission/countries/";
export const DEPARTMENTS = "/api/v1/submission/departments/";

export const CONTACT_US = "/api/v1/submission/contact-us/";
export const TICKET = "/api/v1/submission/ticket/";
export const REQUEST_CALL_BACK = "/api/v1/submission/request-call-back/";

export const Noor_Academy_Enroll = "/api/v1/submission/noor-academy-enroll/";
export const DEMO_FX = "/api/v1/submission/demo-fx-start/";
export const DEMO_FX_VERIFY = "/api/v1/submission/demo-fx-verify/";
export const DEMO_FX_RESEND = "/api/v1/submission/demo-fx-resend/";
// New form Dec 2023 
export const DEMO_CEUK = "/api/v1/submission/demo-ceuk-start/";
export const DEMO_CEUK_VERIFY = "/api/v1/submission/demo-ceuk-verify/";
export const DEMO_CEUK_RESEND = "/api/v1/submission/demo-ceuk-resend/";

export const liv_GTN = "/api/v1/submission/liv-gtn/";
// 0 - 26072022 ***
export const Demo_GTN = "/api/v1/submission/demo-gtn/";
export const liv_NT = "/api/v1/submission/liv-nt/";
export const DEMO_NT = "/api/v1/submission/demo-nt/";
export const Demo_Noor_Clearing = "/api/v1/submission/demo-noor-clearing/";
export const Live_Noor_Clearing = "/api/v1/submission/live-noor-clearing/";


