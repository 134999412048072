import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgnoortraderAr.jpg";

import noortreaderdesktop from "../../assets/images/pages/trading/noortreaderdesktop.png";
import OpenNoorTraderLiveFormAr from "../forms/openNoorTraderLiveFormAr.js";
import NtVideo from "./ntVideo.js";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import WhyNoorAr from "./whyNoorAr";

const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader_OpenLiveAccountAr = () => {
  return( <div>
          <PageseMetaTags
                title="NoorTrader Live Account - Noor Capital"
                description="When you sign up with NoorTrader, you will unlock the most significant investment opportunity in precious metals."
            />

            <BgBeforeAr 
            pageHeadingTitle="استثمارك الناجح يبدأ من هنا"
            pageHeadingDescription="سجل للحصول على حساب نور تريدر مباشر وابدأ تداول السبائك على الفور"
            pageHeadingbg={img1}
           />
        <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="تسجيل حساب حقيقي / منصة نورتريدر"
      />



<div className={globalstyle.container} id="main">
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          تسجيل حساب حقيقي للتداول على منصة نورتريدر  - الذهب البدنية
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center" >
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            
            <p className="parjustify text-right">
            عندما تُسجل في نور تريدر، ستفتح لك الفرصة الاستثمارية الأكثر أهمية في المعادن الثمينة حيث نوفر لك وصولاً على مدار الساعة طوال أيام الأسبوع إلى سوق المعادن الثمينة العالمية.
            </p>

            <p className="parjustify text-right">
            تسمح لك نور تريدر بشراء وبيع وإدارة الاستثمارات من أي مكان في العالم. للبقاء في صدارة اللعبة، يمكنك الوصول إلى آخر أخبار السبائك والفعاليات والتحديثات التي تغطي الأحداث الهامة والتحليلات المتعلقة بالمعادن الثمينة.
            </p>

            <OpenNoorTraderLiveFormAr />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <NtVideo />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                الميزات 
                </Title>
                <h5>
                لماذا تحتاج إلى فتح حساب مباشر على نور تريدر - على الفور؟
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  توفير التخزين والتوصيل

                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الوصول الفوري إلى سوق السبائك العالمية.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                 ابدأ في شراء وبيع وإدارة معادنك الثمينة بضغطة واحدة.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
تداول بأي حجم (كجم وأوز وغرام)
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
البرنامج متاح 24/7 حيث يمكنك الوصول إلى استثمارك.
                </p>

               
              </div>

              <div className="col-12 col-sm-6 col-lg-3  text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                 وصول آمن ومشفّر بنسبة 100٪.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  احصل على سعر لا مثيل له في السوق الفوري مع خيار الإيداع الجزئي.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
متوافق مع جميع متصفحات الويب والهواتف الذكية.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الأسعار الفورية للتداول المادي في الذهب ، الفضة ، إلخ
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  المرونة باستخدام نظام متعدد الوظائف
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4desktop" className="img-fluid" src={noortreaderdesktop} />
              </div>
            </div>
            
          </div>
        </section>
      </div>

      <br />

      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              ابدأ رحلة تداول السبائك الخاصة بك مع نور تريدر
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="#main"
                  
                >
                  افتح حساب
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>




  </div>
  )

};

export default NoorTrader_OpenLiveAccountAr;