import React, { useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import classnames from "classnames";
import logo from "../../assets/images/logo.png";
import { Tooltip } from "antd";
import menuStyle from "./menuBar.module.css";
import globalstyle from "../../style.module.css";
import img from "../../assets/images/home/tools.jpg";
import globalimg from "../../assets/images/pages/services/global-financial-advisory-menu.jpg";
import assetimg from "../../assets/images/pages/services/portfolio-menu.jpg";
import investmentimg from "../../assets/images/pages/services/investment-menu.jpg";
import fundsimg from "../../assets/images/pages/services/funds-administration-menu.jpg";
import mobile from "../../assets/images/cta.png";
import uaeflag from "../../assets/images/uae-flag.svg";
import ukflag from "../../assets/images/uk-flag.svg";

import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";

import LanguageContext from "../../language-context";
import { Helmet } from "react-helmet";
import MobileNav from "./mobileNav";

const tooltipposition = "top";

// const pathArray = window.location.pathname.split("/");

// const languageSegment = pathArray[1];

const MenuBar = () => {
  const location = useLocation();

  const languageSegment = location.pathname;

  const arLink = "/ar" + `${languageSegment}`;

  const { language, setLanguage } = useContext(LanguageContext);

  const [cookies, setCookie, removeCookie] = useCookies(["en"]);

  const setLan = (value) => {
    setCookie("language", value, { path: "/" });
    setLanguage(value);
  };

  return (
    <div>
      <MobileNav />
      <div
        className={classnames(
          menuStyle["ncmenu-header"],
          menuStyle["header-shadow"],
          menuStyle["ncmenu-mega-menu"],
          menuStyle["mega-menu-fullwidth"]
        )}
      >
        <div className={globalstyle.container}>
          {/* header */}
          <div
            id="tt"
            className={classnames(menuStyle["ncmenu-header-container"])}
          >
            {/*Logo*/}

            {/*Navigation menu*/}
            {/* menu-caret */}

            <nav
              className={classnames(
                menuStyle["ncmenu-menu"],
                menuStyle["separate-line"],
                menuStyle["submenu-top-border"]
              )}
            >
              <ul>
                {/* about */}
                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="/about/about-us">About Us</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* Column */}
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/about/about-us">Overview</Link>
                                </li>
                                <li>
                                  <Link to="/about/chairman-message">
                                    Chairman’s Message
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/about/board-members">
                                    Board Members
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/about/management-team">
                                    Management Team
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/about/license-regulations">
                                    License and Regulations
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/about/why-noor-capital">
                                    Why Noor Capital
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/terms-conditions">
                                    Terms and Conditions
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-tools/risk-disclosure">
                                    Risk Disclosure
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/about/careers">Careers</Link>
                                </li>
                                <li>
                                  <Link to="/about/contact-us">Contact Us</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Services */}
                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="#">Services</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* Column */}
                          <div className="col-md-6 col-lg-3">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <div className="mega-menu-thumbnail">
                                <Link to="/services/global-financial-advisory">
                                  <img
                                    src={globalimg}
                                    alt="Global Financial Advisory"
                                    style={{
                                      width: "auto",
                                      height: "auto",
                                      borderRadius: "5px",
                                    }}
                                    width="600"
                                    height="400"
                                  />
                                </Link>
                              </div>
                              <h5
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                                style={{ paddingTop: "15px" }}
                              >
                                <Link to="/services/global-financial-advisory">
                                  Global Financial Advisory
                                </Link>
                              </h5>
                            </div>
                          </div>
                          {/* Column */}
                          <div className="col-md-6 col-lg-3">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <div className="mega-menu-thumbnail">
                                <Link to="/services/asset-management">
                                  <img
                                    src={assetimg}
                                    alt="Asset Management"
                                    style={{
                                      width: "auto",
                                      height: "auto",
                                      borderRadius: "5px",
                                    }}
                                    width="600"
                                    height="400"
                                  />
                                </Link>
                              </div>
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                                style={{ paddingTop: "15px" }}
                              >
                                <Link to="/services/asset-management">
                                  Asset Management
                                </Link>
                              </h4>
                            </div>
                          </div>
                          {/* Column */}
                          <div className="col-md-6 col-lg-3">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <div className="mega-menu-thumbnail">
                                <Link to="/services/investment">
                                  <img
                                    src={investmentimg}
                                    alt="Investment"
                                    style={{
                                      width: "auto",
                                      height: "auto",
                                      borderRadius: "5px",
                                    }}
                                    width="600"
                                    height="400"
                                  />
                                </Link>
                              </div>
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                                style={{ paddingTop: "15px" }}
                              >
                                <Link to="/services/investment">
                                  Investment
                                </Link>
                              </h4>
                            </div>
                          </div>
                          {/* Column */}
                          <div className="col-md-6 col-lg-3">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <div className="mega-menu-thumbnail">
                                <Link to="/services/funds-administration">
                                  <img
                                    src={fundsimg}
                                    alt="Funds Administration"
                                    style={{
                                      width: "auto",
                                      height: "auto",
                                      borderRadius: "5px",
                                    }}
                                    width="600"
                                    height="400"
                                  />
                                </Link>
                              </div>
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                                style={{ paddingTop: "15px" }}
                              >
                                <Link to="/services/funds-administration">
                                  Funds Administration
                                </Link>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Trading */}
                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="#">Trading</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* instruments */}
                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Tooltip
                                  placement={tooltipposition}
                                  title={
                                    "Discover different trading instruments and learn how to trade on each one"
                                  }
                                >
                                  <Link to="#">Instruments</Link>
                                </Tooltip>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/trading/instruments/forex">
                                    Forex
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading/instruments/precious-metals">
                                    Precious Metals
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading/instruments/energies">
                                    Energies
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading/instruments/indices">
                                    Indices
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading/instruments/shares-cfds">
                                    Shares CFDs
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading/instruments/bullion">
                                    Bullion
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading/instruments/cash-equities">
                                    Cash Equities
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* mt4 */}
                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Tooltip
                                  placement={tooltipposition}
                                  title={
                                    "Uncover our version of advance and easy-to-use MT4 platform"
                                  }
                                >
                                  <Link to="/trading-platforms/metatrader4-platform">
                                    FX Trading
                                  </Link>
                                </Tooltip>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <a
                                    href="https://mynoor.noorcap.ae/en/registration/signup"
                                    target="_blank"
                                  >
                                    Open Live Account
                                  </a>
                                </li>
                                <li>
                                  <Link to="/trading-platforms/mt4-open-forex-demo">
                                    Open Demo Account
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-platforms/metatrader4/mt4-mobile">
                                    Metatrader 4 Mobile
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-platforms/metatrader4/mt4-desktop">
                                    Metatrader 4 Desktop
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-platforms/metatrader4/mt4-mac">
                                    Metatrader 4 Mac
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-platforms/metatrader4/mt4-web-trader">
                                    Metatrader 4 Webtrader
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* GTN */}
                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Tooltip
                                  placement={tooltipposition}
                                  title={
                                    "Take control of your stocks with our MT5 platform"
                                  }
                                >
                                  <Link to="/trading-platforms/metatrader5-platform">
                                    Cash Equities
                                  </Link>
                                </Tooltip>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/trading-platforms/metatrader5-platform">
                                    MetaTrader 5
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/stocks-trading-platforms/open-gtn-live">
                                    Open GTN Live Account
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/stocks-trading-platforms/open-stocks-demo">
                                    Open GTN Demo Account
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/stocks-trading-platforms/gtn-mobile">
                                    GTN Mobile
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/stocks-trading-platforms/gtn-web">
                                    GTN Webtrader
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* NT */}
                          {/* <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Tooltip
                                  placement={tooltipposition}
                                  title={
                                    "Learn how you can efficiently manage your precious metals with NoorTrader"
                                  }
                                >
                                <Link to="/physical-trading-platforms/noor-trader">Physical</Link>
                                </Tooltip>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/physical-trading-platforms/open-noor-trader-live">
                                    Open Live Account
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/physical-trading-platforms/open-noor-trader-demo">
                                    Open Demo Account
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/physical-trading-platforms/noor-trader-mobile">
                                    NoorTrader Mobile
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/physical-trading-platforms/noor-trader-web">
                                    NoorTrader Webtrader
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div> */}

                          {/* Institutional */}

                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Tooltip
                                  placement={tooltipposition}
                                  title={
                                    "Watch your business benefit from our award winning Tier 1 interbank liquidity pools"
                                  }
                                >
                                  <Link to="#">Institutional</Link>
                                </Tooltip>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/institutional/request-demo">
                                    Request Demo
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/institutional/request-live">
                                    Request Live
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* Accounts */}

                          <div className="col-md-6 col-lg-2">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Tooltip
                                  placement={tooltipposition}
                                  title={
                                    "Different types of accounts are available to open at your convenience with Noor Capital"
                                  }
                                >
                                  <Link to="#">Accounts</Link>
                                </Tooltip>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/trading/accounts/account-funding">
                                    Account Funding
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading/accounts/account-forms">
                                    Account Forms
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading/accounts/trading-conditions">
                                    Trading Conditions
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* img */}
                          {/* <div className="col-md-6 col-lg-3">
                            <div className={classnames(menuStyle['mega-menu-box'])}>
                              <div className="mega-menu-thumbnail">
                                <img
                                  src={mobile}
                                  alt=" Open account"
                                  style={{ maxHeight: "280px", "maxWidth":"250px" }}
                                />
                                <p>
                                  <Link
                                    className="btn btn-light"
                                    to="/trading-platforms/mt4-open-forex-demo"
                                    style={{"backgroundColor":"rgb(15, 117, 188)","color":"#fff"}}
                                  >
                                    Open FX Demo account
                                  </Link>
                                </p>
                                <p>
                                  <Link
                                    className="btn btn-light"
                                    to="/trading-platforms/mt4-open-forex-live"
                                    style={{"backgroundColor":"rgb(49, 166, 41)","color":"#fff"}}
                                  >
                                    Register FX Live Account
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        */}
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Partnership */}

                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="#">Partnership</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* Column */}
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">Institutional</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/partnership/white-label-solutions">
                                    White Label Solutions
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/partnership/institutional-clients">
                                    Institutional Clients
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/partnership/asset-managers">
                                    Asset Managers (MAM/PAMM)
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">Partnership</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/partnership/marketing-partnership-program">
                                  Partnership Program
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Noor Acadmey */}
                <li>
                  <Link to="/education/noor-academy">Noor Academy</Link>
                </li>

                {/* Trading Tools */}
                <li className={classnames(menuStyle["mega-menu"])}>
                  <Link to="#">Trading Tools</Link>
                  <ul>
                    <li>
                      {/* Mega menu container */}
                      <div
                        className={classnames(menuStyle["mega-menu-container"])}
                      >
                        <div className="row">
                          {/* Column */}
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="">Noor Trends</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/trading-tools/noor-trends-online-webinar">
                                    Online Webinar
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-tools/noor-trends-market-update">
                                    Market Update
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-tools/noor-trends-technical-analysis">
                                    Technical Analysis
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-tools/noor-trends-economic-reports">
                                    Economic Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-tools/noor-trends-daily-broadcasting">
                                    Daily Broadcasting
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div
                              className={classnames(menuStyle["mega-menu-box"])}
                            >
                              <h4
                                className={classnames(
                                  menuStyle["mega-menu-heading"]
                                )}
                              >
                                <Link to="#">Tools</Link>
                              </h4>
                              <ul
                                className={classnames(
                                  menuStyle["mega-menu-list"]
                                )}
                              >
                                <li>
                                  <Link to="/trading-tools/economic-calendar">
                                    Economic Calendar
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-tools/trading-signals">
                                    Trading Signals
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-tools/traders-glossary">
                                    Traders Glossary
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/trading-tools/video-tutorials">
                                    Video Tutorials
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/support/faqs">FAQ</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                {/* Media Center */}
                <li className="submenu-right">
                  <Link to="#">Media Center</Link>
                  <ul>
                    <li>
                      <Link to="/media-center/press-release">
                        Press Releases
                      </Link>
                    </li>
                    <li>
                      <Link to="/media-center/activities">Activities</Link>
                    </li>
                    <li>
                      <Link to="/media-center/awards">Awards</Link>
                    </li>
                  </ul>
                </li>

                {/* Blog */}
                <li>
                  <Link to="/blog">Blog </Link>
                </li>

                {/* Language */}
                <li
                  className={classnames(
                    menuStyle["nav-item"],
                    menuStyle["submenu-right"]
                  )}
                >
                  <Link
                    onClick={() => setLan("ar")}
                    to={arLink}
                    style={{ color: "#1b75bc" }}
                  >
                    <img
                      src={uaeflag}
                      alt="العربية"
                      width="24"
                      height="24"
                      style={{
                        borderRadius: "2px",
                        verticalAlign: "middle",
                      }}
                    />

                    <span style={{ padding: "5px" }}>العربية</span>
                  </Link>
                </li>
              </ul>
            </nav>

            <div
              className={classnames(menuStyle["logo"])}
              data-mobile-logo={logo}
              data-sticky-logo={logo}
            >
              <Link to="/" style={{ color: "#1b75bc" }}>
                {/* <img src={logo} alt="Noor Capital logo" /> */}
                <img
                  src={logo}
                  alt="Noor Capital logo"
                  width="200" // The original width of your image
                  height="100" // The original height of your image
                  className="responsive-logo"
                />
              </Link>

            </div>
          </div>
        </div>


        <style jsx>
        {`
          .responsive-logo {
            max-width: 100%; 
            height: auto; 
          }
        `}
      </style>
      

      </div>
    </div>

    
  );
};

export default MenuBar;
