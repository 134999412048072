import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgtradingbanner3.jpg"
import indices from "../../assets/images/pages/instruments/indices.jpg";
import Products from "./products.js";

import Instruments_Indexes_Table from "./trading_Instruments_Indexes_table";

const style = {padding: '50px 0' };
const { Title } = Typography;


const Instruments_Indices = () => {
  return( <div>
          <PageseMetaTags
                title="Indices Trading with Noor Capital | Forex Broker"
                description="Noor Capital offers competitive spreads across all of our cash Indices, including the E-mini S&P 500 Index from 0.4 points, the FTSE 100 Index from 1 point, Xetra DAX Index from 1 point and S&P 200 Index from 1 point."
            />
            <BgBefore 
            pageHeadingTitle="Trade Indices with Noor Capital"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Trading"
        pageMainCategoryPath="#"
        pageSubCategoryName="Instruments"
        pageSubCategoryPath="#"
        pageName="Indices"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Indices
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            Trading in Indices  is one of the most popular ways of trading in global financial  markets. We provide you with a diverse financial atmosphere to trade indices. The most commonly traded indices include DJ30, S&P 500, NASDAQ 100, DAX 30, FTSE 100, CAC 40, and NIKKIE 225.
            </p>

            <p className="parjustify">
            Trading indices give traders tight spreads and real-time clear chart patterns as they are highly liquid. The volatility of the indices makes them a great option for making profits. When you trade with Noor Capital, you get a fair and secure trading environment and you are always backed by expert analysis. We help you in risk management and provide you with advanced data protection services.
            </p>

            <p className="parjustify">
            Like any other financial asset, indices are volatile; therefore it is important to have a trustworthy broker at your back to put your trades on  the right track. Professional people at Noor Capital have significant experience in trading indices and we make sure that you have access to advanced tools including live charts, market patterns, equities forecast, economic calendar, and webinars along with other strategies to excel in every trade you make.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={indices}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        

        
        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
            Specifications list
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Instruments_Indexes_Table />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
              Start your Trading Indices now
              </h2>

              <p className="mt-5 mt-sm-4">
              <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  Sign-Up
                  </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Products />

  </div>
  )

};

export default Instruments_Indices;