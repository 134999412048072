import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgpartnershipbanner4.jpg"
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import WhyNoor from "./whyNoor";
import OpenNoorClearingDemoForm from "../forms/openNoorClearingDemoForm.js";
import Mt4Video from "./mt4video.js";

const { Title } = Typography;

const notes = [
  'Brokers & Banks',
 'Hedge Funds & Asset Managers',
 'Proprietary Trading Houses',
 'Funds & Corporate Accounts',
 ];

const style = {padding: '50px 0' };

const Institutional_RequestDemo = () => {
  return( <div>
          <PageseMetaTags
                title="Noor Clearing Tier 1 liquidity - request demo"
                description="Empowering You to Deliver Superior Trading Services"
            />

            <BgBefore 
            pageHeadingTitle="Empowering You to Deliver Superior Trading Services"
            pageHeadingDescription="Tier 1 interbank liquidity"
            pageHeadingbg={img1}
            
            
            />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Institutional"
        pageSubCategoryPath="#"
        pageName="Request Demo"
      />



<div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Request Demo Account for Institutional Offering
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              
            }}
          >
            <p className="parjustify">
            Noor Clearing offers the best of both worlds leveraging Noor Capital’s regional expertise and technology to bring you the cutting edge of liquidity and brokerage solutions.
            </p>
            <p className="parjustify">
            Our mission is to become a regional Prime Broker by delivering our award-winning Tier 1 interbank liquidity pools via our state-of-the-art proprietary technology to institutional clients, ranging from leading Retail FX Brokers to banks and fund managers.
            </p>

            <Divider orientation="left"><Title level={3} style={{color: "#1b75bc" }}>Broad Range of Solutions</Title></Divider>

            <List
      size="small"
      bordered
      dataSource={notes}
      renderItem={item => 
        <List.Item><i class="fas fa-angle-double-right" style={{ fontSize: "16px", marginRight: "10px" , color: "#1b75bc" }}></i>{item}</List.Item>
    }
    />

          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            
          >
             <OpenNoorClearingDemoForm />
          </Col>
        </Row>
      </div>
      
      
      <br/>
      <br/>
      
      <style jsx>{
            `
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd){
              background-color: #f1f1f1;
          }

          
            
            `
        }
        </style>



  </div>
  )

};

export default Institutional_RequestDemo;

