import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from "rmwc";

import { BASE_URL, COUNTRIES, DEMO_FX, DEMO_FX_RESEND, DEMO_FX_VERIFY } from "../../constants";
import { getStorage } from "../../utils/helpers";

import { emirates, hearabout } from "./options";
import EmailVerification from "../extras/EmailVerification";

export default function OpenFxDemoForm() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "AE",
    emirate: "",
    hearabout: "",
    agree: false,
  };

  const [state, setState] = React.useState(initialState);

  const [disable, setDisable] = React.useState(false);

  const [countries, setCountries] = React.useState([{ name: "", code: "" }]);

  const [requestSent, setRequestSent] = React.useState(false);
  const [verifyCode, setVerifyCode] = React.useState(false);

  let queryParams = {};
  const headers = {
    'Content-Type': 'application/json',
    "Accept": "application/json",
    "Accept-Language": "en",
  };

  React.useEffect(() => {
    axios
      .get(BASE_URL + COUNTRIES, { headers: headers, withCredentials: true })
      .then((countries) => {
        setCountries(countries.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleCountryChange(event) {
    const newState = { ...state, country: event.target.value };
    setState(newState);
  }

  function handleHearAboutChange(event) {
    const newState = { ...state, hearabout: event.target.value };
    setState(newState);
  }

  function handleFormChange(event) {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  }

  function handleAgreeChange(event) {
    const newState = { ...state, agree: event.target.checked };
    setState(newState);
  }

  function submitOpenFxDemo(event) {
    setDisable(true)
    event.preventDefault();

    const data = {
      first_name: state.firstName,
      last_name: state.lastName,
      phone: state.phoneNumber,
      email: state.email,
      country: state.country,
      emirate: state.emirate,
      hearabout: state.hearabout,
      agree: state.agree,
    };
    let pathname = window.location.href;
    console.log(pathname)

    let newData = data;
    if (getStorage("queryParams")) {
      queryParams = getStorage("queryParams");
      newData = {
        ...data,
        refrence_sp: queryParams.SP,
        refrence_ib: queryParams.IB,
        refrence_p: queryParams.P,
        source_url: pathname,
      };
    }
    axios
      .post(BASE_URL + DEMO_FX, newData, { headers: headers, withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          switch (response.data.detail) {
            case "Verification code sent.":
              setVerifyCode(true)
              break;
            case "Email already exists and is verified.":
              setRequestSent(true);
              setState(initialState);
              break;

            default:
              break;
          }

        }
      }).finally(() => setDisable(false));
  }

  return (
    <div>
      <form onSubmit={submitOpenFxDemo}>
        <div className="row">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="First name"
              name="firstName"
              value={state.firstName}
              onChange={handleFormChange}
            />
          </div>

          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Last name"
              name="lastName"
              value={state.lastName}
              onChange={handleFormChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="email"
              required
              className="form-control"
              placeholder="Email"
              name="email"
              value={state.email}
              onChange={handleFormChange}
            />
            <small className="form-text text-muted">
            It's necessary to submit a valid email address to receive the Free Demo Credentials
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Phone"
              name="phoneNumber"
              value={state.phoneNumber}
              onChange={handleFormChange}
            />
            <small className="form-text text-muted">
              Example: +97150xxxxxxx
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              required
              onChange={handleCountryChange}
              value={state.country}
            >
              {countries.map((country) => (
                <option value={country.code}>{country.name}</option>
              ))}
            </select>
            <small className="form-text text-muted">Nationality</small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="emirate"
              onChange={handleFormChange}
              value={state.emirate}
            >
              {emirates.map((emirate) => (
                <option value={emirate.emirate_code}>
                  {emirate.emirate_name}
                </option>
              ))}
            </select>
            <small className="form-text text-muted">
              For UAE residents only
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <select
              className="form-control"
              name="hearabout"
              required
              onChange={handleHearAboutChange}
              value={state.hearabout}
            >
              {hearabout.map((hear) => (
                <option value={hear.hearabout_code}>
                  {hear.hearabout_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div
            className="col"
            style={{
              padding: "0px 0px 0px 35px",
            }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              name="agree"
              required
              onChange={handleAgreeChange}
              defaultChecked={state.agree}
              checked={state.agree}
            ></input>
            <small
              className="form-text text-muted form-check-label"
              for="gridCheck"
            >
              I agree to receive information from Noor Capital about its
              products, services.
            </small>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button type="submit" className="btn btn-primary" disabled={disable}>
              Submit
            </button>

          </div>
        </div>
      </form>

      <Dialog
        preventOutsideDismiss
        open={requestSent}
        onClose={(event) => {
          setRequestSent(false);
        }}
      >
        <DialogTitle>Success!</DialogTitle>

        <DialogContent>
          Your request is sent successfully. <br />
          We'll contact you soon.
          <br />
          Note: for demo account credentials please check email inbox/spam.
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">Close</DialogButton>
        </DialogActions>
      </Dialog>
      <EmailVerification verifyLink={DEMO_FX_VERIFY} resendLink={DEMO_FX_RESEND} isOpen={verifyCode} onClose={(is_sucess) => {
        setVerifyCode(false)
        if (is_sucess) {
          setRequestSent(true);
          setState(initialState);
        }

      }} email={state.email}></EmailVerification>


      <style jsx>{`

        .blog .carousel-indicators {
          left: 0;
          top: auto;
          bottom: -40px;
        }
        #root > div > div > div:nth-child(4) > div.style_container__uC_8Q > div.ant-row.ant-row-center > div.ant-col.ant-col-xs-24.ant-col-sm-24.ant-col-md-16.ant-col-lg-16.ant-col-xl-16 > div > form > div:nth-child(4) > div > select > option:nth-child(110) {
          display: none;
          overflow: hidden;
        }

      `}
      </style>

    </div>

  );
}
