import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgeducationbanner6Ar.jpg";

import mt4 from "../../assets/images/mt4.png";
import stock from "../../assets/images/stock.png";
import chart from "../../assets/images/chart.png";
import NoorAcademyEnrollFromAr from "../forms/noorAcademyEnrollFormAr.js";
import WhyNoorAr from "./whyNoorAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const who = [
  "المبتدئين الذين يرغبون في تعلم تداول العملات الأجنبية",
  "التجار ذوي الخبرة الذين يرغبون في تعزيز معرفتهم بالمجال",
  "الوسطاء بالفعل والذين يرغبون في أن يصبحوا وسطاء أكثر احترافًا",
  "الأشخاص الذين يرغبون في الحصول على وظيفة بدوام كامل في تداول الفوركس",
];
const notes = [
  "كيف تفتح حساب تداول مع نور كابيتال؟",
  "استراتيجيات مثبتة لزيادة الأرباح وتقليل المخاطر أثناء الاستثمار.",
  "الوقت المناسب للشراء والبيع.",
  "كيف تستفيد من أي حالة من حالات السوق.",
  "كيفية تنويع محفظتك لتقليل المخاطر.",
  "سر التجار الناجحين.",
  "تجنب الأخطاء الشائعة التي يرتكبها المستثمرون.",
];
const { Title } = Typography;

const NoorAcademyAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Noor Academy - Noor Capital"
        description="Enroll Now And Learn To Invest Skillfully, Smarter, and With Confidence Like The Pros"
      />
      <BgBeforeAr
        pageHeadingTitle="هل تريد أن تصبح متداول محترف"
        pageHeadingDescription="انضم إلى أفضل دورة لاحتراف الفوركس في نور كابيتال وقم بتطوير تجربتك في التداول"
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="التعليم"
        pageSubCategoryPath="#"
        pageName="أكاديمية نور"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            سجل الآن وتعلم كيف تستثمر بمهارة وذكاء وثقة مثل المحترفين
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          
          <Col xs={24} sm={24} md={12} lg={12} xl={12}
          style={{
            textAlign: "justify",
            padding: "0px 0px 0px 30px",
            fontSize: "16.5px",
          }}
          >
            <p className="parjustify text-right">
              سواء كنت مبتدئًا في بداية الطريق أو متداولًا متمرسًا، ستحولك دورة
              أكاديمية نور إلى متداول محترف في غضون فترة زمنية قصيرة.
            </p>

            <p className="parjustify text-right">
              من خلال أكاديميتنا الفنية، ستتعلم من قادة السوق أفضل الطرق
              للاستثمار في سوق الأوراق المالية وتداول العملات الأجنبية والسلع
              بثقة.
            </p>

            <Divider orientation="left">
              <Title level={4} style={{ color: "#1b75bc" }}>
                من يجب عليهم أن يلتحقوا بهذه الدورة؟
              </Title>
            </Divider>

            <List
              size="small"
              className="text-right"
              bordered
              dataSource={who}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />

<br/>
<p>
  <b>ملاحظة:</b> الدخول مجاني للعملاء الذين لديهم حد أدنى للإيداع يبلغ 1500 دولار في حسابهم.
</p>

          </Col>


          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            
          >
            <NoorAcademyEnrollFromAr />
          </Col>


        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className={globalstyle.container}>
            <div className="row text-right align-items-center">
              <div className="col-12 col-lg-6 col-xl-5 m-lg-auto text-right">
                <Title level={3} style={{ color: "#1b75bc" }}>
                  أجزاء الدورة
                </Title>
                <p className="h6 pb-xl-4 text-right">
                  سواء كنت متداولًا متمرسًا أو مبتدئًا، تنقسم دورتنا التدريبية
                  إلى جزئين يكملون بعضهم البعض. فيما يلي الجزءان اللذان يمكنك
                  الاختيار ما بينهم وفقًا لمعرفتك وفهمك لتداول الفوركس.
                </p>
                <div className="row pt-5">
                  <div className="col-3">
                    <img alt="nt" className="img-fluid" src={stock} />
                  </div>
                  <div className="col-9">
                    <Title level={4} style={{ color: "#1b75bc" }}>
                      الجزء الأول: مقدمة في تداول الفوركس
                    </Title>
                    <p className="parjustify text-right">
                      هذا الجزء مخصص بشكل أساسي للمبتدئين الذين يرغبون في
                      استكشاف عالم تداول العملات الأجنبية. ستدرس في هذه الدورة
                      أساسيات تداول العملات الأجنبية، وكيفية عمل السوق، ومقدمة
                      للمصطلحات القياسية المستخدمة في التداول، وإدارة الأموال.
                      في نهاية هذا الجزء، سوف تتقن تداول العملات الأجنبية وستكون
                      جاهزًا للبدء على الفور بثقة.
                    </p>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-9">
                    <Title level={4} style={{ color: "#1b75bc" }}>
                      الجزء الثاني: تداول الفوركس المتقدم
                    </Title>
                    <p className="parjustify text-right">
                      تداول الفوركس المتقدم مخصص للمتداولين ذوي الخبرة
                      والمبتدئين الذين أكملوا الدورة التمهيدية. سيتمكن المشارك
                      في هذا الجزء من الدورة التعرف على أكثر الاستراتيجيات
                      والتقنيات المتقدمة المستخدمة في صناعة التداول والتي
                      ستساعدك على زيادة أرباحك وتقليل المخاطر.
                    </p>
                    <p className="parjustify text-right">
                      وسيتعرف المشارك في هذا الجزء على أفضل ممارسات المتداولين
                      الناجحين وبشروط التداول المتقدمة. وتدور الفكرة الرئيسية
                      لهذا الجزء حول تزويدك بأفضل الأدوات لمساعدتك على البقاء في
                      صدارة اللعبة.
                    </p>
                  </div>
                  <div className="col-3">
                    <img
                      alt="gtn"
                      className="img-fluid"
                      src={chart}
                      style={{ paddingTop: "50px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-7 col-sm-4 m-auto pt-5 pt-md-0">
                <img alt="mt4" className="img-fluid" src={mt4} />
              </div>
            </div>
            <Divider orientation="left">
              <Title level={3} style={{ color: "#1b75bc" }}>
                ماذا ستتعلم؟
              </Title>
            </Divider>

            <List
              size="small"
              bordered
              className="text-right"
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
          </div>
        </section>
      </div>
      <br />

      <section className="fdb-block">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 style={{ color: "#1b75bc" }}>
                <strong>أكاديمية نور كابيتال</strong>
              </h1>
              <p className="parjustify">
              بمجرد أن تصبح جزءًا من أكاديمية نور كابيتال، تأكد من أنك ستصبح متداول محترف تمتلك المعرفة والأدوات المطلوبة لإرشادك خلال رحلة التداول الخاصة بك.
              <br/>
              لدينا مجموعة كاملة من الأدوات أثناء الدورة وبعدها لاطلاعك على آخر أخبار السوق والمعلومات والاستراتيجيات ونصائح الخبراء.
              </p>
            </div>
          </div>

          <div className="row text-right justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h3 style={{ color: "#1b75bc" }}>دورة أكاديمية نور</h3>
              <p className="lead">
              سواء كنت مبتدئًا في بداية الطريق أو متداولًا متمرسًا، ستحولك دورة أكاديمية نور إلى متداول محترف في غضون فترة زمنية قصيرة
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <h3 style={{ color: "#1b75bc" }}>ندوات عبر الإنترنت</h3>
              <p className="lead">
              بغض النظر عن مكان وجودك، حرصنا على تقديم ندواتنا عبر الإنترنت لمساعدتك على التعلم عبر الإنترنت من خلال المتداولين الأكثر اعتمادًا وخبرة.
              </p>
            </div>
          </div>

          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>ورش العمل</h3>
              <p className="lead">
              نقوم بإجراء ورش عمل منتظمة لمساعدة الطلاب في الحصول على تدريب عملي مع خبرائنا المشهورين عالميًا.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>البث اليومي المباشر</h3>
              <p className="lead">
              يمكنك الوصول إلى حلقات البث اليومية والأسبوعية والشهرية التي ستساعدك على البقاء على اطلاع دائم واتخاذ قرارات التداول الصحيحة
              </p>
            </div>
          </div>

          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>تحديثات السوق اليومية</h3>
              <p className="lead">
              تحتوي تحديثات السوق اليومية على آخر أخبار السوق والتحليلات الفنية والمقالات لمساعدتك على التداول بثقة.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>التقرير الاقتصادي</h3>
              <p className="lead">
              ابق على اطلاع من خلال تقريرنا الاقتصادي المنتظم بآخر الأخبار من دولة الإمارات العربية المتحدة وجميع أنحاء العالم التي تؤثر بشكل مباشر على السوق المالية
              </p>
            </div>
          </div>

          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>أدوات التداول</h3>
              <p className="lead">
              لن تفوتك أبدًا أي فرصة في السوق وستظل دائمًا متقدمًا على منافسيك
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h3 style={{ color: "#1b75bc" }}>قاموس المصطلحات</h3>
              <p className="lead">
              يوفر معجمنا جميع المرادفات التي تحتاجها للكلمات المستخدمة في صناعة التداول
              </p>
            </div>
          </div>
        </div>
      </section>

      <br />

      <style jsx>
        {`
          .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
            background-color: #dce0e4;
          }
        `}
      </style>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                إذا كنت جادًا في أن تصبح رائدًا في تجارة الفوركس، فإن رحلتك
                الأولى تبدأ من هنا
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyNoorAr />
    </div>
  );
};

export default NoorAcademyAr;
