import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import img1 from "../../assets/images/bgtoolsAr.jpg";
import { Collapse } from "antd";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const glossary1 = `عندما يقوم المتداول بفتح وإغلاق صفقة على نفس السهم في نفس اليوم، فإنه يقوم بصفقة يومية ويخضع لقواعد خاصة.`;

const glossary2 = `يمكن اعتبار المتداول اليومي المحترف بشكل غير رسمي شخصًا يتداول يوميًا ويعد التداول مصدرًا لدخله.`;

const glossary3 = `سيحتفظ المتداولون المتأرجحون بالأسهم لمدة ليلة واحدة على الأقل حيث تُصنف هذه الاستثمارات على أنها استثمارات قصيرة الأجل للغاية.`;

const glossary4 = `يشير هذا المصطلح إلى أن سوق الأسهم قوية في ارتفاع. على سبيل المثال "السوق في اتجاه الثيران لزوج يورو/دولار" أي نعتقد أن اليورو سيكون أقوى أمام الدولار الأمريكي.`;

const glossary5 = `يشير هذا المصطلح إلى سوق أسهم ضعيفة؛ والدببة، هو المتداول الذي تكون نظرته المستقبلية للسوق أو أداة مالية معينة سلبية، أي متداول يتوقع انخفاض الأسعار.`;

const glossary6 = `عندما تقوم الشركة بطرح أسهم أولية للاكتتاب العام، أي طرح أسهم من الشركة للجمهور.`;

const glossary7 = `يشير مصطلح التعويم إلى عدد الأسهم القائمة المتاحة للتداول.`;

const glossary8 = `برنامج إعادة شراء الأسهم هو عندما تشتري الشركة الأسهم التي تم بيعها خلال الاكتتاب العام. بذلك  يقللون من عدد الأسهم المتاحة لأغراض التداول في السوق.`;

const glossary9 = `الطرح الثانوي هو الطرح الذي يتم تقديمه بعد الطرح العام الأولي. سيؤدي الطرح الثانوي إلى جمع الأموال للشركة عن طريق بيع المزيد من الأسهم.`;

const glossary10 = `بيتا هو مقياس للتقلب - أو المخاطر المنهجية - للسهم أو المحفظة مقارنة بالسوق ككل.`;

const glossary11 = `يشير السوق المغلقة إلى حالة مؤقتة يكون فيها سعر العرض المرتبط بأصل أو ورقة مالية معينة أعلى من سعر الطلب.`;

const glossary12 = `حصص الربح هي الأموال المدفوعة للمساهمين الذين يمتلكون أسهمًا في الشركة كوسيلة لمشاركة نجاح الشركة.`;

const glossary13 = `الانحراف هو مفهوم تداول موجود في التداول اليومي ويتشكل على مخطط شريط التداول الخاص بك وينتج من حركة الأوراق المالية المتحركة في اتجاهين متعاكسين.`;

const glossary14 = `ربحية السهم (EPS) هو جزء من ربح الشركة المخصص لحصة الأسهم للفرد وهو مقياس أساسي للمحللين.`;

const glossary15 = `القيمة السوقية هي مقياس يستخدم لتصنيف حجم الشركة ويستند إلى القيمة السوقية لإجمالي الأسهم القائمة.`;

const glossary16 = `عمليات الاندماج هي صفقات توحد شركتين منفصلتين في شركة واحدة جديدة.`;

const glossary17 = `عادةً ما يشير مصطلح أسهم رخيصة إلى أسهم شركة صغيرة.`;

const glossary18 = `العائد على الاستثمار (ROI) هو مقياس للربح أو الخسارة الناتجة عن الاستثمار، ويتم حسابه بقسمة الربح المكتسب من الاستثمار على تكلفة الاستثمار.`;

const glossary19 = `تشير الأسهم المعلقة إلى أسهم الشركة المملوكة حاليًا لجميع المساهمين، بما في ذلك الأسهم المقيدة والمؤسسية.`;

const glossary20 = `يمثل اتجاه السوق الاتجاه العام في السوق أو الورقة المالية خلال فترة معينة ويمكن أن يستمر من يومين إلى عدة أشهر أو سنوات.`;

const glossary21 = ` يشير هذا إلى مستوى عدم اليقين المحيط بتقلبات أسعار الأدوات المالية. يتميز التقلب عادة بحركات أسعار سريعة وعشوائية.`;

const glossary22 = `تقنية مستخدمة في التحليل الفني تشير إلى حد وسقف محددين للسعر يقوم عندها السعر بتصحيح نفسه تلقائيًا. عكس المقاومة.`;

const glossary23 = `مستوى المقاومة هو مستوى السعر الذي يعتبر فيه بيع الورقة المالية صحيحًا بدرجة كافية للتخلص من ارتفاع السعر.`;

const glossary24 = `السهم عبارة عن استثمار. عندما تشتري أسهم شركة، فأنت تشتري جزءًا صغيرًا من تلك الشركة، يُطلق عليه سهم. يشتري المستثمرون الأسهم في الشركات التي يعتقدون أنها سترتفع قيمتها. إذا حدث ذلك، تزداد قيمة سهم الشركة أيضًا. يمكن بعد ذلك بيع السهم لتحقيق ربح.`;

const glossary25 = `السعر المستهدف هو السعر المتوقع للأداة المالية التي يقدمها المحلل ويستخدم لتحديد الأسهم ذات القيمة المبالغ فيها والمنخفضة.`;

const glossary26 = `يشير الكساد إلى حدوث تراجع في اقتصاد بلد ما بسبب عوامل مختلفة خلال فترة زمنية محددة.`;

const glossary27 = `يسمح سوق التداول خارج البورصة بتداول الأصول دون الهيكل الرسمي للبورصة الرسمية ويعتبر محفوفًا بالمخاطر.`;

const glossary28 = `يشير مصطلح صعوبة الاقتراض إلى مخزون الأوراق المالية التي لا تستطيع شركة الوساطة توفيرها للبيع على المكشوف وتتاح فقط للشراء.`;

const glossary29 = `المشتقات هي أوراق مالية تعتمد أسعارها على أو مشتقة من واحد أو أكثر من الأصول الأساسية المنفصلة مثل عقود الخيارات أو العقود الآجلة.`;

const glossary30 = `تشير حقوق الملكية إلى ملكية الأصول بعد تسوية الالتزامات والديون، أو يمكن أن تشير إلى الأسهم أو ملكية الأسهم في شركة عامة.`;

const glossary31 = `كلها مصطلحات تعبر عن أسهم في شركات تبلغ قيمتها في الغالب مليارات الدولارات، وتدفع أرباحًا ولها تاريخ طويل من العمليات الموثوقة.`;

const glossary32 = `السند هو استثمار في الديون حيث يقرض المستثمرون الأموال لشركة أو حكومة لفترة معينة من الوقت بسعر فائدة متغير أو ثابت.`;

const glossary33 = `عندما يختار المتداولون التداول طويل المدى في الأسهم، فإنهم يشترون الأسهم ويتوقعون ارتفاع سعر السهم.`;

const glossary34 = `مقياس لمقدار ما يدفعه المستثمر لكل سهم من الأسهم ويتم حسابه بقسمة الأسعار المدفوعة على عدد الأسهم.`;

const glossary35 = `عندما يختار المتداولون التداول قصير المدى في الأسهم فإنهم يختارون أسهم قصيرة المدى وسريعة البيع. بمجرد بيع الأسهم، يستفيدون من البيع؛ ومع ذلك، يجب عليهم إعادة شراء الأسهم.`;

const glossary36 = `أي اقتراض الأسهم من وسيطك عند البيع. إذا لم يكن لدى وسيطك أسهم متاحة للاقتراض، فلا يمكنك بيع الأسهم.`;

const glossary37 = `من أجل اتمام عملية البيع، يجب على المتداول "تغطية" مركزه، أي شراء الأسهم لتغطية الأسهم التي اقترضها من الوسيط.`;

const glossary38 = `تشير الفائدة القصيرة إلى عدد الأسهم المباعة على المكشوف ولكن لم يتم شراؤها أو تغطيتها بعد.`;

const glossary39 = `عندما يرتفع سهم ما فجأة، يجبر المتداولين الذين يحتفظون بصفقات قصيرة على البدء في شرائها لتغطية مراكزهم.`;

const glossary40 = `الهامش هو مبلغ المال اللازم لفتح مركز تداول برافعة مالية. هو الفرق بين القيمة الإجمالية لصفقتك والأموال التي يقرضها لك الوسيط. بالإضافة إلى السماح لك بالتداول على الأموال المقترضة، يوفر أيضًا حدًا ائتمانيًا لحسابك للتداول.`;

const glossary41 = `معدل مضاعفة إيداعك النقدي لمنحك القوة الشرائية الإجمالية.`;

const glossary42 = `القوة الشرائية هي رصيدك النقدي بالإضافة إلى الهامش الخاص بك.`;

const glossary43 = `النسبة المئوية التي يجب على المتداول أن يدفعها للوسيط مقابل اقتراض المال.`;

const glossary44 = `المتداولون الذين يصدرون طلب تغطية، يعني أنهم مدينون للوسيط. طلب التغطية هو طلب من الوسيط بأن يقوم المستثمر بإيداع أموال أو أوراق مالية إضافية في الحساب للوصول إلى الحد الأدنى للقيمة.`;

const glossary45 = `سعر العطاء هو السعر الذي يقدمه المتداولون على الأسهم.`;

const glossary46 = `السعر المعروض هو السعر الذي يطلبه المتداولون حاليًا لبيع السهم به.`;

const glossary47 = `هو الفرق بين سعر العرض وسعر الطلب.`;

const glossary48 = `صناع السوق هم من يحركون الفروقات. إنهم لاعبون مخضرمون ويمثّلون مؤسسات ويقومون بشراء وبيع الأسهم.`;

const glossary49 = `الحجم هو مقياس لعدد الأسهم المتداولة.`;

const glossary50 = `الحجم النسبي يوضح حجم السهم مقارنة بمتوسط حجمه لنفس الفترة.`;

const glossary51 = `السوق المحدودة يعني عدم تداول العديد من المتداولين في سهم معين.`;

const glossary52 = `ستزدحم الأسواق والأسهم المتداولة بكثافة مع المتداولين.`;

const glossary53 = `يمكن إيقاف الأسهم عن التداول لعدة أسباب منها أثناء توقف قاطع التداول في هذه الحالة لا يمكن للمتداولين تداول الأسهم بأي شكل من الأشكال.`;

const glossary54 = `الانزلاق السعري هو الفرق بين السعر الذي تعتقد أنك ستتداول به والسعر الذي مرت به الصفقة.`;

const glossary55 = `الأمر المحدد هو عندما تطلب من الوسيط الخاص بك أن يشتري لك الأسهم وتذكر أكبر مبلغ ترغب في دفعه.`;

const glossary56 = `أوامر الإيقاف هي أوامر متعددة الاستخدامات تستخدم للدخول والخروج من الصفقات. عندما تضع أمر إيقاف، فأنت تقول إنك تريد الدخول أو الخروج من صفقة عندما تصل الأسعار إلى سعر التوقف.`;

const glossary57 = `أمر تنفيذ أو إلغاء، مما يعني إما أن يتم تنفيذ طلبك بالكامل أو لن يتم تنفيذ الطلب على الإطلاق؛ هذا يمنع الأوامر الجزئية.`;

const glossary58 = `هذا أمر ساري حتى الإلغاء. هذا يعني أن الأمر سيبقى على خوادم الوسيط حتى تقوم بإلغائه.`;

const glossary59 = `أمر الوقف المتحرك هو أمر إيقاف يسمح بتحديد القيمة كنسبة مئوية أقل من سعر السوق عادة وسوف تتحرك مع تحرك الأسعار.`;

const glossary60 = `يشير الأمر الساري حتى الإلغاء إلى طلب شراء أو بيع مصمم للبقاء حتى يتم تنفيذ الطلب أو إلغاؤه بواسطة المتداول أو الوسيط.`;

const glossary61 = `التحليل الأساسي هو عندما ينظر المتداول (أو في أغلب الأحيان، المستثمر) في المقاييس الأساسية للشركة، ويشمل ذلك أرباحها السنوية والفصلية للسهم الواحد، وقيمتها الدفترية (القيمة الإجمالية لأصول الشركة)، وقوة قطاعها، وإمكانات النمو.`;

const glossary62 = `التحليل الفني، على عكس التحليل الأساسي، يركز فقط على سعر السهم.`;

const glossary63 = `المخططات البيانية ببساطة ترسم خطًا. يمكن أن يعطي هذا فهماً جيداً لحركة السعر على مدى فترات زمنية طويلة، لكنه لا يوفر البصيرة الضرورية التي يحتاجها المتداولون لفترات زمنية أقصر.`;

const glossary64 = `تُظهر المخططات الشريطية سعر الفتح وسعر الإغلاق لأي فترة معينة.`;

const glossary65 = `تتضمن مخططات الشموع أربعة أجزاء من المعلومات. سعر الفتح، وسعر الإغلاق، وأعلى سعر للفترة وأيضًا أدني سعر للفترة.`;

const glossary66 = `تساعدنا المؤشرات أو الدراسات الفنية في تفسير حركة السعر الحالية.`;

const glossary67 = `المتوسطات المتحركة هي مؤشر فني يخبرنا بمتوسط سعر السهم بمرور الوقت.`;

const glossary68 = `المتوسط المتحرك البسيط هو حساب متوسط السعر على سعر إغلاق ورقة مالية خلال فترة زمنية مقسومة على عدد الفترات.`;

const glossary69 = `يشير أتجاه وحجم السوق إلى دراسة الرسوم البيانية المتقدمة المستخدمة لإظهار نشاط التداول حيث يتم عرض السوق بالأسعار بدلاً من عرضه خلال وقت محدد.`;

const glossary70 = `الانحراف المعياري هو مقياس إحصائي يمثل معدل الاختلاف عن المتوسط في مجموعة البيانات ويستخدم كثيرًا في التداول.`;

const glossary71 = `الحجم المتوازن، يساعد مؤشر الزخم في قياس ضغط الشراء والبيع في الأسهم أو أي ضمان مالي آخر مع الحجم المتوازن.`;

const glossary72 = `فتح نطاق الاختراق هي إستراتيجية بسيطة نسبيًا تتضمن اتخاذ مركز عندما يتحرك السعر لأعلى أو أسفل الشمعة السابقة، أي يتحرك أعلى أو أدنى.`;

const glossary73 = `قناة كيلتنر هي مؤشر فني يظهر خطًا مركزيًا لمتوسط متحرك وخطوط قناة أسفل وأعلى.`;

const glossary74 = `مؤشر متوسط الحركة هو مؤشر فني يستخدم للدلالة على قوة الاتجاه ويعمل بشكل أفضل عندما يكون السهم يتجه صعودًا أو هبوطًا.`;

const style = { padding: "50px 0", alignSelf: "justify" };

const { Title } = Typography;

const Tools_TradersGlossaryAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Traders Glossary - List of Common Trading Terms and Definitions"
        description="The world of trading is full of jargon. In this section of our website, we will take you through some of the main terms used in trading and explain what they mean."
      />

      <BgBeforeAr
        pageHeadingTitle="قاموس المتداولين  "
        pageHeadingDescription="فيما يلي بعض المصطلحات التي يجب أن يكون كل متداول على دراية بها"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="أدوات التداول"
        pageSubCategoryPath="#"
        pageName="قاموس المصطلحات "
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          قاموس المصطلحات  
          </Title>
        </Row>
        <Row>
          <Title style={{ color: "#1b75bc" }} level={4}>
          فيما يلي بعض المصطلحات التي يجب أن يكون كل متداول على دراية بها.
          </Title>
          
        </Row>
        <br/>
        <Collapse className="text-right" defaultActiveKey={["1"]} onChange={callback}>
          <Panel header="التداول اليومي" key="1">
            <p>{glossary1}</p>
          </Panel>
          <Panel header="المتداول اليومي المحترف" key="2">
            <p>{glossary2}</p>
          </Panel>

          <Panel header="التداول المتأرجح" key="3">
            <p>{glossary3}</p>
          </Panel>

          <Panel header="السوق الصاعد (سوق الثيران)" key="4">
            <p>{glossary4}</p>
          </Panel>

          <Panel header="السوق الهابط (سوق الدببة)" key="5">
            <p>{glossary5}</p>
          </Panel>

          <Panel header="الطرح الأولي للاكتتاب العام (IPO)" key="6">
            <p>{glossary6}</p>
          </Panel>

          <Panel header="تعويم" key="7">
            <p>{glossary7}</p>
          </Panel>

          <Panel header="إعادة شراء الأسهم" key="8">
            <p>{glossary8}</p>
          </Panel>

          <Panel header="الطرح الثانوي" key="9">
            <p>{glossary9}</p>
          </Panel>

          <Panel header="بيتا" key="10">
            <p>{glossary10}</p>
          </Panel>
          
          <Panel header="السوق المغلقة" key="11">
            <p>{glossary11}</p>
          </Panel>

          <Panel header="حصص الربح" key="12">
            <p>{glossary12}</p>
          </Panel>

          <Panel header="الانحراف" key="13">
            <p>{glossary13}</p>
          </Panel>

          <Panel header="ربحية السهم" key="14">
            <p>{glossary14}</p>
          </Panel>

          <Panel header="القيمة السوقية" key="15">
            <p>{glossary15}</p>
          </Panel>

          <Panel header="الاندماج" key="16">
            <p>{glossary16}</p>
          </Panel>

          <Panel header="أسهم رخيصة" key="17">
            <p>{glossary17}</p>
          </Panel>

          <Panel header="العائد على الاستثمار (ROI)" key="18">
            <p>{glossary18}</p>
          </Panel>

          <Panel header="الأسهم المعلقة" key="19">
            <p>{glossary19}</p>
          </Panel>

          <Panel header="اتجاه السوق" key="20">
            <p>{glossary20}</p>
          </Panel>
          <Panel header="التقلب" key="21">
            <p>{glossary21}</p>
          </Panel>

          <Panel header="مستوى الدعم" key="22">
            <p>{glossary22}</p>
          </Panel>

          <Panel header="مستوى المقاومة Level" key="23">
            <p>{glossary23}</p>
          </Panel>

          <Panel header="الأسهم" key="24">
            <p>{glossary24}</p>
          </Panel>

          <Panel header="السعر المستهدف" key="25">
            <p>{glossary25}</p>
          </Panel>

          <Panel header="الكساد" key="26">
            <p>{glossary26}</p>
          </Panel>

          <Panel header="سوق التداول خارج البورصة" key="27">
            <p>{glossary27}</p>
          </Panel>

          <Panel header="صعوبة الاقتراض" key="28">
            <p>{glossary28}</p>
          </Panel>

          <Panel header="المشتقات" key="29">
            <p>{glossary29}</p>
          </Panel>

          <Panel header="حقوق الملكية" key="30">
            <p>{glossary30}</p>
          </Panel>
          <Panel header="الأسهم المميزة أو الأسهم الزرقاء" key="31">
            <p>{glossary31}</p>
          </Panel>

          <Panel header="السند" key="32">
            <p>{glossary32}</p>
          </Panel>

          <Panel header="التداول طويل المدى (الشراء)" key="33">
            <p>{glossary33}</p>
          </Panel>

          <Panel header="متوسط السعر" key="34">
            <p>{glossary34}</p>
          </Panel>

          <Panel header="التداول قصير المدى (البيع)" key="35">
            <p>{glossary35}</p>
          </Panel>

          <Panel header="الاقتراض" key="36">
            <p>{glossary36}</p>
          </Panel>

          <Panel header="التغطية" key="37">
            <p>{glossary37}</p>
          </Panel>

          <Panel header="الفائدة القصيرة" key="38">
            <p>{glossary38}</p>
          </Panel>

          <Panel header="الضغط القصير (ارتفاع حاد وقصير الأجل في الأسعار)" key="39">
            <p>{glossary39}</p>
          </Panel>

          <Panel header="الهامش" key="40">
            <p>{glossary40}</p>
          </Panel>
          <Panel header="معدل الرافعة المالية" key="41">
            <p>{glossary41}</p>
          </Panel>

          <Panel header="القوة الشرائية" key="42">
            <p>{glossary42}</p>
          </Panel>

          <Panel header="معدل الهامش" key="43">
            <p>{glossary43}</p>
          </Panel>

          <Panel header="طلب تغطية -  Call" key="44">
            <p>{glossary44}</p>
          </Panel>

          <Panel header="سعر العطاء" key="45">
            <p>{glossary45}</p>
          </Panel>

          <Panel header="السعر المعروض" key="46">
            <p>{glossary46}</p>
          </Panel>

          <Panel header="الفرق بين سعر العرض والطلب (الفرق)" key="47">
            <p>{glossary47}</p>
          </Panel>

          <Panel header="صناع السوق -  Makers" key="48">
            <p>{glossary48}</p>
          </Panel>

          <Panel header="الحجم" key="49">
            <p>{glossary49}</p>
          </Panel>

          <Panel header="الحجم النسبي" key="50">
            <p>{glossary50}</p>
          </Panel>
          <Panel header="سوق محدودة" key="51">
            <p>{glossary51}</p>
          </Panel>

          <Panel header="سوق كثيف" key="52">
            <p>{glossary52}</p>
          </Panel>

          <Panel header="قاطع التداول" key="53">
            <p>{glossary53}</p>
          </Panel>

          <Panel header="انزلاق العملات (السعري)" key="54">
            <p>{glossary54}</p>
          </Panel>

          <Panel header="أوامر محددة" key="55">
            <p>{glossary55}</p>
          </Panel>

          <Panel header="أوامر الإيقاف" key="56">
            <p>{glossary56}</p>
          </Panel>

          <Panel header="أمر تنفيذ أو إلغاء" key="57">
            <p>{glossary57}</p>
          </Panel>

          <Panel header="أمر سار حتى إلغاؤه" key="58">
            <p>{glossary58}</p>
          </Panel>

          <Panel header="أمر الوقف المتحرك Stop" key="59">
            <p>{glossary59}</p>
          </Panel>

          <Panel header="أمر سار حتى إلغاؤه" key="60">
            <p>{glossary60}</p>
          </Panel>
          <Panel header="التحليل الأساسي" key="61">
            <p>{glossary61}</p>
          </Panel>

          <Panel header="التحليل الفني Analysis" key="62">
            <p>{glossary62}</p>
          </Panel>

          <Panel header="المخططات (الرسوم) البيانية" key="63">
            <p>{glossary63}</p>
          </Panel>

          <Panel header="المخططات (الرسوم) الشريطية" key="64">
            <p>{glossary64}</p>
          </Panel>

          <Panel header="مخططات الشموع اليابانية" key="65">
            <p>{glossary65}</p>
          </Panel>

          <Panel header="المؤشرات الفنية" key="66">
            <p>{glossary66}</p>
          </Panel>

          <Panel header="المتوسطات المتحركة" key="67">
            <p>{glossary67}</p>
          </Panel>

          <Panel header="المتوسط المتحرك البسيط" key="68">
            <p>{glossary68}</p>
          </Panel>

          <Panel header="لمحة عن السوق (اتجاه وحجم السوق) " key="69">
            <p>{glossary69}</p>
          </Panel>

          <Panel header="الانحراف المعياري" key="70">
            <p>{glossary70}</p>
          </Panel>

          <Panel header="الحجم المتوازن" key="71">
            <p>{glossary71}</p>
          </Panel>

          <Panel header="فتح نطاق الاختراق" key="72">
            <p>{glossary72}</p>
          </Panel>

          <Panel header="قناة كيلتنر" key="73">
            <p>{glossary73}</p>
          </Panel>

          <Panel header="مؤشر متوسط الحركة" key="74">
            <p>{glossary74}</p>
          </Panel>

        </Collapse>

        <br />
        <br />

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
            .ant-collapse-header{
              font-size: 18px;
              padding: 18px 16px !important;
              color: #1b75bc !important;
            }
          `}
        </style>
      </div>

   
    </div>
  );
};

export default Tools_TradersGlossaryAr;
