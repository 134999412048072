import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import img1 from "../../assets/images/bgtoolsAr.jpg";
import { Collapse } from "antd";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}
const style = { padding: "50px 0", alignSelf: "justify" };

const { Title } = Typography;

const FaqsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="FAQs - Noor Capital"
        description="Noor Capital Frequently Asked Questions"
      />

      <BgBeforeAr
        pageHeadingTitle="الأسئلة المتكررة"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الدعم"
        pageSubCategoryPath="#"
        pageName="الأسئلة المتكررة"
      />

      <div className={globalstyle.container} >
        <div className="text-right">
        <br />
        <br />
        <Row>
          <p>
          نور كابيتال هي شركة وساطة استثمارية معتمدة عالميًا. تقدم أحدث المنصات القوية مع تسهيلات للتداول السلس للمستثمرين الأفراد والمؤسسات. تأسست نور كابيتال في عام 2005، وهي مرخصة ومنظمة من قبل هيئة الأوراق المالية والسلع. لدينا حضور عالمي مع فريق ذو خبرة عالية ومعتمد لمساعدتك في استثماراتك.
          </p>
        </Row>
        <Row>
          <Title style={{ color: "#1b75bc" }} level={4}>
          فيما يلي بعض الأسئلة الشائعة:
          </Title>
        </Row>

        <Collapse defaultActiveKey={["1"]} onChange={callback}>
          <Panel header="س. كيف أفتح حساب؟" key="1">
            <p>
              <b>ج. </b>يمكنك ملء استمارة التسجيل بالضغط {" "}
              <a href="https://mynoor.noorcap.ae/en/registration/signup">هنا</a>{" "}
              أو تحميل استمارة فتح الحساب بالضغط هنا.
            </p>
          </Panel>

          <Panel header="س. ما الذي أحتاجه لفتح حساب؟" key="2">
            <p>
              {" "}
              <b>ج. </b>نسخة صالحة موقعة من صفحة بيانات جواز السفر <br />
              بطاقة تعريف الإمارات العربية المتحدة أو بطاقة الهوية [المقيمين في الإمارات العربية المتحدة فقط]<br />
              إثبات العنوان - نسخة من كشف حساب بنكي رسمي، موقع ومختوم حسب الأصول من البنك أو فاتورة مرافق حديثة.
            </p>
          </Panel>

          <Panel header="س. ما هي طرق الدفع المقبولة؟" key="3">
            <p>
              {" "}
              <b>ج. </b>نقبل التحويلات المصرفية ومدفوعات بطاقات الائتمان ومدفوعات المحفظة الإلكترونية بالدولار الأمريكي أو الدرهم الإماراتي. جميع التفاصيل متوفرة في خزانتك الخاصة بمجرد التسجيل لفتح حساب حقيقي. يمكنك أيضًا الاتصال بالوسيط المعين من نور كابيتال لتوفير	 جميع تفاصيل الدفع اللازمة.
            </p>
          </Panel>

          <Panel header="س. هل أموالي آمنة؟" key="4">
            <p>
              {" "}
              <b>ج. </b>تتمتع نور كابيتال برأسمال كبير يزيد عن 200 مليون دولار أمريكي. نور كابيتال شركة مساهمة خاصة مستقلة، مرخصة ومعتمدة من قبل بنك الإمارات العربية المتحدة المركزي. بناءً على متطلباتنا التنظيمية، نحتفظ بجميع أموال عملائنا في حسابات بنكية منفصلة لا تختلط مع حسابات شركتنا. علاوة على ذلك، تحتفظ نور كابيتال بضمان لرأس المال لدى بنك الإمارات العربية المتحدة المركزي يزيد عن 10 ملايين دولار أمريكي لحماية أموال العملاء.
            </p>
          </Panel>

          <Panel header="س. كيف يمكنني سحب أموالي؟" key="5">
            <p>
              {" "}
              <b> </b>استنادًا إلى متطلبات الاتفاقية الدولية لمكافحة غسيل الأموال (AML)، يجب إعادة جميع الأموال إلى المصدر الذي تم إيداعها منه. يمكن إجراء أي عمليات سحب من خلال خزانتك الخاصة. انقر هنا {" "}
              <a href="http://mynoor.noorcap.ae/en/traders/login">
                {" "}
                لتسجيل الدخول إلى خزانتك الخاصة
              </a>
            </p>
            <p>
            خزانة نور كابيتال الخاصة هي حل احترافي متعدد الإمكانات للمتداولين الأفراد إذ توفر العديد من الميزات في الوقت الحقيقي. تتيح لك الخزانة الخاصة إدارة حسابك المباشر والحساب التجريبي في بضع خطوات بسيطة. بمجرد دخولك إلى الخزانة الخاصة، ستتوفر نظرة عامة مفصلة حول أنشطة حسابك مع تقارير التداول المختلفة مع إمكانية الوصول إلى أقسام التعليم وبرامج المكافآت.
            </p>
            <p>
            تُمكّنك خزانة نور كابيتال الخاصة من فتح وإدارة حسابك (حساباتك)، وسحب تقارير الحساب وإجراء عمليات الإيداع والسحب والتحويلات الداخلية واستخدامات أخرى.
            </p>
            <p>
            سيتم إرسال كشوف الحساب تلقائيًا إلى بريدك الإلكتروني يوميًا، طالما أن لديك أنشطة تداول ومراكز مفتوحة. بخلاف ذلك، ستتلقى كشوف حساب بالبريد الإلكتروني شهريًا.
            </p>
          </Panel>

          <Panel header="س. ما هو الفوركس؟" key="6">
            <p>
              <b>ج. </b>سوق الصرف الأجنبي، وتعرف السوق باسم "سوق الفوركس – FX" حيث يحقق المستثمرون أرباحًا من الفروقات في قيمة العملات المتداولة. تُعد أكبر سوق في العالم حتى الآن وذات سيولة عالية وتجذب العديد من المتداولين والمحترفين. يُعد حجم التداول هائلأ في سوق الفوركس إذ يبلغ 5 تريليون دولار في اليوم مقارنة 22.4 مليار دولار فقط في بورصة نيويورك.
            </p>
            <p>
            يغطي هذا الرقم الضخم البالغ 5 تريليون دولار سوق الفوركس العالمي بأكمله، لكن متداولين التجزئة يتداولون في السوق الفورية، وحجمها حوالي 1.49 تريليون دولار.
            </p>
            <p>
            سوق الفوركس مفتوح 24 ساعة في اليوم، خمسة أيام في الأسبوع، ويتم تداول العملات في جميع أنحاء العالم بين المراكز المالية الرئيسية في لندن ونيويورك وطوكيو وزيورخ وفرانكفورت وهونغ كونغ وسنغافورة وباريس وسيدني.
            </p>
            <p>
            تتضمن جميع تداولات الفوركس الشراء المتزامن لعملة ما وبيع أخرى، ولكن يمكن اعتبار زوج العملات نفسه كوحدة واحدة أو أداة يتم شراؤها أو بيعها. إذا اشتريت زوج عملات، فإنك تشتري العملة الأساسية وتبيع عملة التسعير. على سبيل المثال: في زوج اليورو/الدولار الأمريكي، فإن اليورو هو العملة الأساسية، والدولار الأمريكي هو عملة التسعير.
            </p>
          </Panel>

          <Panel header="س. ما هي العقود مقابل الفروقات؟" key="7">
            <p>
              <b>ج. </b>عقد الفروقات (CFD) هو اتفاق بين طرفين لتبادل الفرق بين سعر الافتتاح وسعر إغلاق العقد. العقود مقابل الفروقات هي منتجات مشتقة تسمح لك بالتداول على تحركات أسعار السوق بشكل مباشر دون امتلاك الأداة الأساسية (الأسهم أو المؤشرات أو الأسهم الفردية أو السلع) التي يستند إليها عقدك.
            </p>
            <p>
            تسمح لك العقود مقابل الفروقات بالتداول وفقًا للحركة المستقبلية لأسعار السوق بغض النظر عما إذا كانت الأسواق ترتفع أو تنخفض حيث تسمح للمتداولين بالوصول إلى الأسواق التي لن يتمكنوا عادة من الاستثمار فيها.
            </p>
          </Panel>

          <Panel header="س. ما هو الهامش والمراكز الطويلة والقصيرة؟" key="8">
            <p>
              <b>ج. </b>الهامش هو ضمان المركز. سيطلب الوسيط صانع السوق الخاص بك أموالاً إضافية عن طريق "طلب الهامش (طلب تغطية الهامش)" إذا تحرك السوق عكس مركزك، سيغلق على الفور مراكزك المفتوحة إذا لم يكن هناك أموال كافية في حسابك.
            </p>
            <p>
              <b> </b>المركز الطويل هو المركز الذي تشتري فيه أداة بسعر واحد، وتتوقع بيعها لاحقًا بسعر أعلى. وذلك عند توقع ارتفاع السوق.
            </p>
            <p>
              <b>ج. </b>المركز القصير هو المركز الذي تبيع فيه مع توقع إعادة شرائه بسعر أقل. وهنا، يتوقع انخفاض السوق.
            </p>
          </Panel>

          <Panel header="س. ما هو العرض / الطلب؟" key="9">
            <p>
              <b>ج. </b>العرض (سعر الشراء) يمثل الكم المطلوب من عملة التسعير للحصول على وحدة واحدة من العملة الأساسية. بالمقابل، عندما تبيع زوج العملات، فإنك تبيع العملة الأساسية وتتلقى عملة التسعير. يُعد سعر العرض هو أعلى سعر يعرضه البائع لعملة معينة في الوقت الحالي.
            </p>
            <p>
            يمثل الطلب (سعر البيع) لزوج العملات المبلغ الذي ستحصل عليه بعملة التسعير لبيع وحدة واحدة من العملة الأساسية. يُعد سعر الطلب هو أقل سعر يقبله المشتري.
            </p>
          </Panel>

          <Panel header="س. ما هو السبريد / النقطة أو البيب PIP؟" key="10">
            <p>
              <b>ج. </b>االسبريد هو الفرق بين سعر الشراء وسعر البيع للأداتين. على سبيل المثال، إذا تم تداول زوج العملات اليورو/الدولار الأمريكي عند 1.1167 (شراء) و 1.1166 (بيع) ، فإن السبريد هو 1 نقطة.
            </p>
            <p>في الأسواق المالية، وخاصة في أسواق الفوركس، النقطة أو البيب (النسبة المئوية بالنقطة) هي وحدة تغيير في سعر صرف زوج العملات. تنقسم معظم أزواج العملات الرئيسية إلى أربعة مراتب عشرية، والنقطة أو البيب PIP هي وحدة واحدة من الفاصلة العشرية الرابعة، على سبيل المثال: الدولار: هو 1/100 من سنت.
            </p>
          </Panel>

          <Panel header="س. كيف يمكنني إدارة المخاطر" key="11">
            <p>
              <b>ج. </b>أكثر أدوات إدارة المخاطر شيوعًا في تداول الفوركس هي أوامر وقف الخسارة والطلب المحدد. يوجه أمر وقف الخسارة بتصفية المركز تلقائيًا بسعر محدد للحماية من التغييرات الدراماتيكية ضد المركز. يحدد الطلب المحدد الحد الأقصى للسعر الذي يرغب المستثمر في دفعه في إحدى المعاملات والحد الأدنى للسعر الذي يتم استلامه في المقابل.
            </p>
            <p>يمكنك وضع أوامر تحديد ووقف الخسارة في أي وقت مجانًا.</p>
          </Panel>

          <Panel header="س. كم مرة يجب أن أتداول؟" key="12">
            <p>
              <b>ج. </b>ستحدد ظروف السوق نشاط التداول الخاص بك في أي يوم. يمكن للمتداول الصغير إلى المتوسط أن يتداول حتى 10 مرات في اليوم. ومع ذلك، نظرًا لعدم وجود عمولات عند تداول العقود مقابل الفروقات، يمكنك اتخاذ مراكز طويلة أو قصيرة بقدر ما تريد دون القلق بشأن تكاليف المعاملات المرتفعة.
            </p>
          </Panel>

          <Panel header="س. كم من الوقت يجب أن أحافظ على مراكزي؟" key="13">
            <p>
              <b>ج. </b>بشكل عام، سوف تحتفظ بمركزك حتى،
              <ol>
                <li>تحقق ربحًا كافيًا من مركزك،</li>
                <li>تفعيل وقف الخسارة الخاص بك؛ أو،</li>
                <li>يظهر مركز أخر ذو إمكانات هائلة، وتحتاج إلى تحرير أموال من مركز أخر للاستفادة منها.</li>
              </ol>
            </p>
          </Panel>

          <Panel header="س. كيف يمكنني التغلب على مخاوفي؟" key="14">
            <p>
              <b>ج. </b>لا توجد طريقة أفضل للحصول على خبرة عملية في هذا السوق من فتح حساب تجريبي. بهذه الطريقة، ستشعر بما يشبه التداول دون المخاطرة فعليًا بأي من رأس المال الذي كسبته بشق الأنفس.
            </p>
          </Panel>

          <Panel header="س. لماذا تداول العملات؟" key="15">
            <p>
              <b>ج. </b>يتيح الفوركس للمستثمرين شراء وبيع أزواج العملات والربح من حركة سعر الصرف. ومع ذلك، فإن تحركات أسعار الصرف يمكن أن تعمل ضدك كما تعمل لمصلحتك. من الممكن أن تخسر استثمارك الأولي جزئيًا أو كليًا، وبالتالي فإن الفوركس ليس مناسبًا لجميع المستثمرين.
            </p>
            <p>تقدم نور كابيتال هوامش ضيقة مع رافعة مالية تصل إلى 400: 1. الأسواق مفتوحة 24ساعة/6أيام في الأسبوع من الاثنين إلى الجمعة. تقدم الأسواق المتقلبة العديد من الفرص التجارية في الأسواق الصاعدة والهابطة. حيث إن الفوركس هو الأكثر تداولًا وسيولة على مستوى العالم، ويختار العديد من المتداولين الفوركس بسبب إمكانية العائد المرتفع في فترة وجيزة.
            </p>
          </Panel>

          <Panel header="س. ما هي أزواج العملات الأكثر تداولًا؟" key="16">
            <p>
              <b>ج. </b>اليورو/الدولار الأمريكي هو زوج العملات الأكثر تداولًا في العالم. يمثل قيمة الدولار الأمريكي لكل يورو واحد، اليورو عملة جديدة نسبيًا، وقد تم تأسيسها بموجب أحكام اتفاقية ماستريخت لعام 1992 وتتم إدارتها من قبل البنك المركزي الأوروبي (ECB) ونظام اليورو (الذي يتكون من البنوك المركزية في منطقة اليورو).
            </p>
            <p>كلما زاد الناتج المحلي الإجمالي لبلد ما في منطقة اليورو، زاد تأثيره على اليورو. في هذا الصدد، يمكن أن تؤثر البيانات الاقتصادية والقرارات السياسية في ألمانيا (التي تتمتع بأعلى إجمالي الناتج المحلي في منطقة اليورو) بشكل كبير على اليورو. وأيضًا يمكن أن تؤثر الدول الصغيرة على اليورو، خاصة في أوقات الأزمات التي تهدد الاستقرار الاقتصادي في المنطقة.
            </p>
            <p>تُعد البيانات الأخرى، بما في ذلك بيانات نمو الناتج المحلي الإجمالي والتوظيف والتضخم والميزان التجاري ضرورية حيث تؤثر بيانات سوق العمل بشكل أساسي على بيانات الدولار الأمريكي، وأيضًا الناتج المحلي الإجمالي والتضخم وأسعار الفائدة والتيسير الكمي الفيدرالي أو احتمالية ذلك يؤثروا على بيانات الدولار الأمريكي، وفكرة أن الدولار الأمريكي قد يكون ملاذًا في قرارات المستثمرين عند تداول الدولار الأمريكي.
            </p>
            <p>الجنيه الإسترليني/الدولار الأمريكي (الاسم المستعار "الكابل") يمثل مبلغ الدولار الأمريكي الذي تم شراؤه بالجنيه البريطاني. كان الجنيه الإسترليني مرتبطًا بالدولار الأمريكي في عام 1940 وأصبح جزءًا من نظام بريتون وودز، الذي كان يحكم أسعار الصرف بعد الحرب، ومع انهيار النظام، أصبح الجنيه الإسترليني معومًا بحرية في عام 1971. كان الدولار هو الأداة الأساسية التي قامت العديد من الدول الأخرى من خلالها بتقييم عملاتها.
            </p>
            <p>على الصعيد المحلي، يتأثر الجنيه الإسترليني بالمؤشرات الاقتصادية التي توفر نظرة ثاقبة على نشاط الاقتصاد البريطاني، بما في ذلك أسعار الفائدة والتيسير الكمي الذي يحدده بنك إنجلترا، ونمو الناتج المحلي الإجمالي والتضخم وبيانات سوق العمل. يمكن أن يتأثر الجنيه بأسعار المعادن الأساسية والنفط والسلع الأخرى.
            </p>
            <p>الدولار الأمريكي/الين الياباني يمثل المبلغ الذي يمكن شراؤه بدولار أمريكي واحد. في وقت نظام بريتون وودز، تم تثبيت الين مقابل الدولار الأمريكي عند 360 ين ياباني لكل دولار أمريكي واحد، ولكن استمر التبادل فقط حتى تخلت الولايات المتحدة عن معيار الذهب في عام 1971. ومنذ ذلك الحين، ارتفع الين بشكل كبير مقابل الدولار الأمريكي.
            </p>
            <p>الين هو ثالث أكثر العملات تداولاً على مستوى العالم بعد الدولار الأمريكي واليورو. يُنظر أيضًا إلى الين أحيانًا على أنه ملاذ آمن، وتتأثر العملة أحيانًا بالتدفقات في أوقات الأزمات الاقتصادية. تؤثر البيانات المحلية أيضًا على سعر الين الياباني، وخاصة بيانات الميزان التجاري والتضخم والعمالة والناتج المحلي الإجمالي.
            </p>
            <p>الدولار الاسترالي: (الاسم المستعار "أوسي") نمت شعبية الدولار الاسترالي على مدى السنوات القليلة الماضية نظرًا لأنه يميل إلى تحقيق عائد أعلى من العديد من العملات الأخرى في الأسواق المتقدمة. وبالتالي، فإن الدولار الاسترالي يجعل سوقه جذاب للمتداولين الباحثين عن العائد.
            </p>
            <p>بالإضافة إلى ذلك، تميل أستراليا أيضًا إلى جذب الانتباه نظرًا لارتباطاتها القوية بالسلع، حيث أنها مصدر كبير للسلع الأساسية، وبالتالي تزداد علاقاتها التجارية مع آسيا. على هذا النحو، تُعرف أيضًا باسم "عملة السلع الأساسية".
            </p>
            <p>يمكن أن يتأثر الدولار الأسترالي بالعديد من عوامل الاقتصاد الكلي، مثل قيام البنك الاحتياطي الأسترالي (RBA) برفع أو خفض أسعار الفائدة، والناتج المحلي الإجمالي، وأرقام التوظيف والميزان التجاري وبيانات التضخم. التعليقات من مسؤولي البنك المركزي الأسترالي يمكن أن يكون لها تأثير كبير على الدولار الاسترالي. يميل زوج دولار استرالي / دولار أمريكي أيضًا إلى الحصول على "بيتا" أعلى. وبالتالي فهي أكثر حساسية لارتفاع أو هبوط أسعار الأسهم والسلع. وبالتالي، سيرغب المتداولون في مراقبة إس وبي 500 عن كثب في الولايات المتحدة وأسعار الذهب والنحاس لأن لديهم علاقة إيجابية عالية مع دولار استرالي / دولار أمريكي.
            </p>
          </Panel>

          <Panel header="س. ما هي السلع؟" key="17">
            <p>
              <b>ج. </b>في الأسواق المالية للسلع الأساسية، يتم تداول العقود الآجلة للسلع الأساسية، مثل الذهب والنفط والنحاس والبن والكاكاو. 
 تُعرَّف السلع عادة على أنها سلع من القطاع الاقتصادي الأولي وليست منتجات مصنعة. السلع اللينة هي منتجات زراعية مثل القمح والقهوة والكاكاو والسكر، ويتم تعدين السلع الصلبة مثل الذهب والفضة والنفط. يشمل تداول السلع التداول والمشتقات مثل العقود مقابل الفروقات، باستخدام الأسعار الفورية، والتي توفر خدمات المقاصة والتسوية والتداول خارج البورصة (OTC).

            </p>
          </Panel>

          <Panel header="س. ما هي أكثر السلع تداولا؟" key="18">
            <p>
              <b>ج. </b>الذهب والفضة والنفط الخام هي السلع الأكثر شيوعًا لتداول العقود مقابل الفروقات. تتأثر أسعار سلعهم بالعديد من العوامل، مثل الارتباط السلبي بين الذهب والنفط أو تقييم الدولار الأمريكي. ومع ذلك، فإن التأثير الأكبر هو العرض والطلب. علاوة على ذلك، قد يؤثر الإنتاج أو التغييرات الموسمية أو سعة التخزين على سعر السلع، وبالتالي، تُعرض السلع بالسعر الذي تُقدم به في السوق.
            </p>
          </Panel>

          <Panel header="س. ما هو النفط؟" key="19">
            <p>
              <b>ج. </b>النفط الخام هو منتج بترولي طبيعي يُستخدم بشكل شائع في إنتاج الطاقة وتصنيعها. يُشترى عادةً ليكرر من اجل منتجات الاستخدام اليومي مثل الديزل والبنزين وزيت التدفئة ووقود الطائرات والبلاستيك ومستحضرات التجميل والأدوية والأسمدة وما إلى ذلك، لذلك فإن سعره له تأثير كبير على الاقتصاد العالمي. تميل أسعار النفط المرتفعة إلى تقويض النمو الاقتصادي حيث يؤدي ذلك إلى زيادة نفقات السفر والشحن، مما يزيد من الضغوط التضخمية، وبالتالي يتضاءل الاستهلاك الشخصي عادةً.
            </p>
            <p>هناك اثنان من التصنيفات الرئيسية للنفط الخام هما النفط الأمريكي، ويشار إليهما عادة باسم خام غرب تكساس الوسيط  (WTI)، ونفط المملكة المتحدة، أو خام مزيج برنت. يتم تصنيف كلاهما على أنهما زيت خام "خفيف" و "حلو"، مما يعني أنهما يتميزان بكثافة منخفضة (مما يسهل عملية التكرير والنقل)، مع نسبة قليلة من الكبريت (مما يؤدي إلى تقليل الشوائب، مما يجعل تكريره أرخص). لذلك، يُصنف هذا النوع على إنه أغلى ثمناً من نظائره "الثقيلة" أو "الحامضة" لأنها أقرب إلى المنتجات النهائية المرغوبة المذكورة أعلاه.
            </p>
            <p>تتأثر أسعار النفط بشكل كبير بميزان العرض والطلب نظرًا لأنه يتم استهلاكه بكثافة يوميًا. ينقسم الإنتاج والعرض والطلب ومخزونات النفط إلى اتحادين كبيرين: منظمة البلدان المصدرة للنفط (أوبك) ومنظمة التعاون الاقتصادي والتنمية (OECD) - أوبك هي المجموعة المسؤولة عن إنتاج حوالي 40٪ من إنتاج العالم. في الوقت نفسه، فإن منظمة التعاون الاقتصادي والتنمية مسؤولة عما يزيد قليلاً عن 50٪ من الطلب العالمي على النفط.
            </p>
            <p>إذا تجاوزت مستويات الإنتاج طلب الاستهلاك، يقال إن المخزونات "تتنامى" حيث يمكن تخزين فائض العرض والعكس صحيح. غالبًا ما يقيس المتداولون مستوى طلب المستهلك من خلال النظر إلى القوة أو الضعف النسبي في الاقتصادات العالمية من خلال مراقبة الناتج المحلي الإجمالي ومبيعات التجزئة وإنفاق المستهلكين وما إلى ذلك، ومن ثم رؤية كيف يتراكم هذا مع المخزونات المتوقعة. يميل الشعور العام في الأسواق المالية أيضًا إلى لعب دور مهم في سعر ا لنفط.
            </p>
          </Panel>

          <Panel header="س. ما هو الذهب؟" key="20">
            <p>
              <b>ج. </b>نمت شعبية الذهب خلال السنوات القليلة الماضية كتجارة بديلة للعملات. ينظر العديد من المتداولين إلى المعدن الثمين على أنه وسيلة تحوط ضد التضخم وتخزين القيمة - وبالتالي، غالبًا ما يشار إليه على أنه استثمار "آمن". تاريخيا، تميل أسعار الذهب إلى التحرك عكسيا مع الدولار الأمريكي. ومع ذلك، مع تزايد عدم اليقين الجيوسياسي على مدى السنوات الخمس الماضية، لم يعد هذا الارتباط واضحًا.
            </p>
            <p>يتأثر الذهب بالصحة العامة للاقتصاد العالمي - يتم قياس ذلك من خلال نمو الناتج المحلي الإجمالي والتضخم وبيانات التوظيف وأسعار الفائدة. بالإضافة إلى ذلك، فإن السياسات النقدية لبعض أكبر البنوك المركزية في العالم، وما إذا كانت تشدد أو توسع سياساتها، تؤثر أيضًا بشكل كبير على سعر الذهب. تعد ديناميكيات العرض/الطلب، بالإضافة إلى معنويات السوق المالية، من العوامل الأخرى التي يجب على المستثمرين أخذها في الاعتبار عند تداول المعدن الأصفر.
            </p>
          </Panel>

          <Panel header="س. ما هي الفضة؟" key="21">
            <p>
              <b>ج. </b>الفضة هي معدن آخر من المعادن الثمينة. على هذا النحو، فإنه يجذب الاهتمام كاستثمار “آمن". ومع ذلك، نظرًا لأن الفضة تميل إلى الاستفادة من الطلب والصناعي والنقدي الأكثر قوة من الذهب، فعادة ما يكون أداؤها أقرب إلى تحركات الأصول "البيتا المرتفعة" الأخرى (على سبيل المثال، الأسهم) من الذهب.
            </p>
            <p>وبالتالي، عادة ما تتفوق الفضة في الأداء على الذهب عندما تكون التوقعات الاقتصادية العامة في الصعود ولكن من المحتمل أيضًا أن تعاني من المزيد من الانتكاسات المهمة عندما يتحول السوق إلى الاتجاه الهابط. على هذا النحو، يمكن أن تكون العلاقة بين الفضة والذهب معرضة لـ "المخاطرة" بشكل عام، والتي يتم التعبير عنها من خلال نسبة الذهب/الفضة - فالنسبة المنخفضة تعزز البحث عن المخاطر، وتشير النسبة الأعلى إلى النفور من المخاطرة.
            </p>
            <p>يتأثر سعر الفضة بالعديد من عدة عوامل مثل نظيره الذهب على سبيل المثال: معدل التضخم وتوقعات التضخم ونمو الناتج المحلي الإجمالي العالمي وأسعار الفائدة وكذلك السياسات النقدية لبعض البنوك المركزية الكبرى في العالم والاحتياطي الفيدرالي الأمريكي والبنك المركزي الأوروبي وبنك إنجلترا وبنك اليابان وبنك الشعب الصيني. إلى حد ما، تميل عوامل العرض/الطلب إلى لعب دور أكثر أهمية في تقلبات أسعار الفضة مقارنة بعوامل الذهب بسبب نقص السيولة النسبية في السوق.
            </p>
          </Panel>

          <Panel header="س. ما هي المؤشرات؟" key="22">
            <p>
              <b>ج. </b>في حالة الأسواق المالية، يكون المؤشر عبارة عن محفظة
              تخيلية من الأوراق المالية تمثل سوقًا معينًا أو جزء منه. لكل مؤشر
              منهجيته الحسابية وعادة ما يتم التعبير عنها من حيث التغيير من
              القيمة الأساسية. وبالتالي، فإن النسبة المئوية للتغير أكثر أهمية من
              القيمة الرقمية الفعلية.
            </p>
            <p>
              تُستخدم مؤشرات أسواق الأسهم والسندات لإنشاء صناديق المؤشرات
              المشتركة والصناديق المتداولة في البورصة (ETFs) التي تعكس محافظها
              مكونات المؤشر.
            </p>
          </Panel>

          <Panel header="س. لماذا نتداول المؤشرات؟" key="23">
            <p>
              <b>ج. </b>في نور كابيتال، يمكنك تداول المؤشرات العالمية الرئيسية
              بفروق ضيقة، ورافعة مالية 100: 0 (أو هامش 1٪) وعمليات تنفيذ عالية
              الجودة. يمنحك تداول المؤشرات المالية تعرضًا فوريًا للاقتصادات
              والصناعات العالمية، وتنويعًا سهلًا عبر القطاعات والبلدان ويمكنك
              تحقيق أرباح من الأسواق الصاعدة والهابطة.
            </p>
          </Panel>

          <Panel header="س. ما هي أكثر المؤشرات تداولا؟" key="24">
            <p>
              <b>ج. </b>مؤشر داو جونز هو أكثر مؤشرات أسواق الأسهم شهرة في
              العالم. تاريخيا، أنشئ المؤشر لتتبع تحركات أكبر الشركات الصناعية في
              أمريكا. ومع ذلك، فإنه اليوم يتألف من ثلاثين شركة من جميع القطاعات
              المختلفة. غالبًا ما يواجه المؤشر انتقادات لأنه متوسط مرجح بالسعر،
              مما يعني أن الأسهم الأعلى سعرًا سيكون لها تأثير أكثر أهمية على
              المؤشر من تلك ذات الأسعار المنخفضة وبالتالي لا تأخذ في الاعتبار
              تغيير النسبة المئوية. بالإضافة إلى ذلك، يشعر الكثيرون أن احتوائه
              على 30 سهمًا فقط لا يمثل تمثيلًا دقيقًا لأداء السوق الأمريكي
              الإجمالي.
            </p>
            <p>يتأثر مؤشر داو جونز في الولايات المتحدة بالبيانات الاقتصادية الرئيسية، مثل معدل البطالة أو التضخم، والأحداث الجيوسياسية، واللجنة الفيدرالية الأمريكية للسوق المفتوحة (FOMC)، أو يشار إليها بشكل أكثر شيوعًا باسم الاحتياطي الفيدرالي. نظرًا لأن الولايات المتحدة هي أكبر اقتصاد عالمي والدولار الأمريكي هو العملة الاحتياطية في العالم، فإن قرارات مجلس الاحتياطي الفيدرالي بتغيير سياسته النقدية لها تأثير هائل على الأسواق بشكل عام ولكنها تميل إلى أن يكون لها تأثير أكثر وضوحًا على سوق أسهم الولايات المتحدة بشكل عام.{" "}
            </p>
            <p>
              أخيرًا، تميل حالة عدم اليقين إلى التأثير بشكل كبير على معنويات
              المستثمرين واستعدادهم للاستثمار في سوق الأسهم. بعض أكبر العوامل
              التي تؤثر على المشاعر هي التغير السريع في أسعار الطاقة، والحروب
              والإرهاب والاضطرابات السياسية أو الازمات.{" "}
            </p>
            <p>
            مؤشر ناسداك هو مؤشر سوق للأسهم العادية والأوراق المالية المماثلة المدرجة في سوق ناسداك للأوراق المالية. جنبا إلى جنب مع مؤشر داو جونز وإس آند بي 500، وهو واحد من أكثر ثلاثة مؤشرات متابعة في أسواق الأسهم الأمريكية حيث تميل تركيبة ناسداك بشدة نحو شركات تكنولوجيا المعلومات.{" "}
            </p>
            <p>
              ناسداك هو مؤشر مرجح للقيمة السوقية لأكثر من 3000 سهم عادي مدرج في
              بورصة ناسداك. تشمل أنواع المؤشرات: إيصالات الإيداع الأمريكية
              والأسهم العادية وصناديق الاستثمار العقاري (REITs) وتتبع الأسهم.
              يتكون المؤشر من جميع الأسهم المدرجة في بورصة ناسداك والتي ليست من
              المشتقات أو الأسهم الممتازة أو الصناديق أو الصناديق المتداولة في
              البورصة (ETFs) أو السندات.{" "}
            </p>
            <p>
              على عكس مؤشرات السوق الأخرى، لا يقتصر مؤشر ناسداك المركب على
              الشركات التي يقع مقرها الرئيسي في الولايات المتحدة حيث من المعتاد
              سماع سعر إغلاق مؤشر ناسداك المركب في الصحف المالية أو كجزء من
              الأخبار المسائية.{" "}
            </p>
            
            <p>
            مؤشر إس آند بي 500 المكون من 500 سهم تم اختيارهم حسب حجم السوق والسيولة وتجمع الصناعة، من بين عوامل أخرى. صُمم مؤشر إس آند بي 500 ليكون مؤشرًا رائدًا للأسهم الأمريكية ويعكس خصائص المخاطرة/العائد في عالم الشركات الكبيرة.{" "}
            </p>
            <p>            يتم اختيار الشركات المدرجة في المؤشر من قبل لجنة مؤشر إس وبي، وهي لجنة مكونة من فريق محللين واقتصاديين في ستاندرد آند بورز، يُعد مؤشر إس آند بي 500 مرجح بالقيمة السوقية - يتناسب وزن كل سهم مع قيمته السوقية.{" "}
            </p>
            <p>يُعد مؤشر إس آند بي 500 أحد أكثر المقاييس استخدامًا لسوق الأسهم الأمريكية بشكل عام. كان مؤشر داو جونز الصناعي (DJIA) في وقت من الأوقات المؤشر الأكثر شهرة للأسهم الأمريكية. ومع ذلك، نظرًا لأن مؤشر داو جونز DJIA)) يحتوي على 30 شركة فقط، يتفق معظم الناس على أن إس آند بي 500 يمثل تمثيلًا أفضل لسوق الولايات المتحدة حيث يعتبره الكثيرون تعريف السوق.{" "}
            </p>
            <p>العديد من المنتجات المالية القائمة على مؤشر إس آند بي 500 متاحة للمستثمرين حيث تشمل صناديق المؤشر والصناديق المتداولة في البورصة. ومع ذلك، سيكون من الصعب على المستثمرين الأفراد شراء المؤشر مما يستلزم شراء 500 سهم مختلف.{" "}
            </p>
            <p> مؤشر يورو ستوكس 50 - Euro STOXX 50 هو مؤشر لأسهم منطقة اليورو من ستوكس - STOXX وترجع ملكيته إلى مجموعة البورصة الألمانية ومجموعة سيكس. وفقًا لستوكس، فإن الهدف هو توفير تمثيل ممتاز لقادة القطاع في منطقة اليورو ويتكون المؤشر من 50 سهم من أكبر الأسهم وأكثرهم سيولة. تعد العقود الآجلة للمؤشر والخيارات على يورو ستوكس 50 من بين المنتجات الأكثر سيولة في أوروبا والعالم.{" "}
            </p>
            <p>أنشيء مؤشر يورو 50 في 26 فبراير/شباط 1998. حيث يُستعرض تكوينه سنويًا في سبتمبر. يُشتق مؤشر يورو50 من 19 مؤشرًا للقطاع الإقليمي ويستحوذ المؤشر على حوالي 60٪ من القيمة السوقية للتعويم الحر لمؤشر السوق الإجمالي (EURO STOXX TMI)، والذي يغطي بدوره حوالي 95٪ من القيمة السوقية للتعويم الحر للبلدان الممثلة.{" "}
            </p>
            <p>يعتبر المؤشر أساسًا للعديد من مؤشرات الإستراتيجية مثل مؤشرات التحكم في المخاطر يورو استوكس 50. تُستخدم الخزينة المؤقتة لتحقيق العدد الثابت للمكونات والحفاظ على استقرار المؤشرات عن طريق تقليل تغييرات تكوين المؤشر. تضمن منهجية الاختيار تكوين مؤشر مستقر وحديث. توفر قواعد الدخول السريع والخروج السريع دقة المؤشر وأداء الأسهم الأكبر والأكثر سيولة فقط.{" "}
            </p>
            <p>يتكون مؤشر داكس 30 من أكبر الشركات المتداولة في بورصة فرانكفورت حيث يهيمن على المؤشر حاليًا القطاعات المالية (التأمين) والسيارات والرعاية الصحية والكيماويات. يشير حجمه الهائل إلى أنه مؤشر مسيطر للغاية في أوروبا، أي أنه يمكن أن يؤثر على نظرائه في أوروبا.{" "}
            </p>
            <p>ألمانيا جزء لا يتجزأ من منطقة اليورو. لديها أكبر اقتصاد في أوروبا، على أساس احصائيات التصدير، مما يجعل المؤشر عرضة للمشاعر المحيطة بشركائه التجاريين.{" "}
            </p>
            <p>كما أن هيمنة قطاعات السيارات والرعاية الصحية والمواد الكيميائية تعني أنه يجب مراقبتها عن كثب حيث قد تكون لديها القدرة على قيادة السوق ككل. علاوة على ذلك، يمكن للسياسة الحكومية أن تحرك معنويات المستثمرين.{" "}
            </p>
            <p>يرتبط مؤشر فوتسي 100 ارتباطًا وثيقًا بالاقتصادات في جميع أنحاء أوروبا من خلال التجارة والقرب الجغرافي. وبالتالي يمكن أن يتأثر بمشاعر المستثمرين المحيطة بأسواق الأسهم الكبيرة في أوروبا. علاوة على ذلك، خلال أوقات الأزمة العالمية، يمكن للاقتصاد في بعض الأحيان تجاهل الأساسيات المحلية لصالح معنويات المستثمرين بشكل عام، مع الاستثناء المحتمل وهو قرارات أسعار الفائدة وإعلانات السياسة الصادرة عن بنك إنجلترا. وبشكل أكثر تحديدًا، فإن المؤشر عرضة لمشاعر أسواق البنوك تجاه الأسهم المصرفية ذات الوزن المرتفع في المؤشر.{" "}
            </p>
            <p>مؤشر نيكاي 225 NIKKEI أو أكثر شيوعًا يسمى نيكاي Nikkei هو مؤشر سوق الأسهم لبورصة طوكيو للأوراق المالية حيث يُحسب يوميًا من قبل صحيفة نيهون كيزاي شيمبون Nihon Keizai Shimbun Nikkei منذ عام 1950.{" "}
            </p>
            <p>يُعد مؤشر نيكاي مرجح بالسعر ويُستعرض تكوينه مرة واحدة في السنة. حاليًا، يُعد مؤشر نيكاي من أكثر الأسهم اليابانية انتشارًا على نطاق واسع على غرار مؤشر داو جونز.{" "}
            </p>
          </Panel>
        </Collapse>

        <br />
        <br />

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
            .ant-collapse-header {
              font-size: 18px;
              padding: 18px 16px !important;
              color: #1b75bc !important;
            }
          `}
        </style>
      </div>
      </div>
    </div>
  );
};

export default FaqsAr;
