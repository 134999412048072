import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import "./management.css";
import mohammedghosheh from "../../assets/images/team/mohammed-ghosheh.jpg";
import abdullaalsuwaidi from "../../assets/images/team/abdulla-alsuwaidi.jpg";
import muhammadalzoubi from "../../assets/images/team/muhammad-al-zoubi.jpeg";
import sherali from "../../assets/images/team/sherali.jpg";
import adelelhassan from "../../assets/images/team/adelel-hassan.jpg";

const ManagementTeam = () => {
  return (
    <div>
      <PageseMetaTags
        title="Management Team - Noor Capital"
        description="The Management Team work together to grow successfully and continuously our reputation over time, becoming a trusted partner to each one of our clients across the range of products and services we offer."
      />
      <BgBefore
        pageHeadingTitle="At Noor Capital, We Believe in One Spirit, One Team, One Win."
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="Management Team"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          <div
            className="row text-center justify-content-center"
            style={{ marginBottom: "20px" }}
          >
            <div className="col-10">
              <h2 className="display-4" style={{ color: "#11131b" }}>
                Noor Capital Management Team
              </h2>
              <p className="lead">
              Noor Capital consists of the industry's most renowned, experienced, and highly certified management team that plays a vital role in creating long-lasting value for the clients and employees with their unmatched leadership.
              </p>
            </div>
          </div>

          {/* 111111111111 */}

          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "10px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Mohammed Ghosheh"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={mohammedghosheh}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 0px 10px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Mr. Mohammed Ghosheh</h3>
                  <p className="lead">Chief Executive Officer </p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    Mohammed Ghosheh is the Chief Executive Officer of Noor Capital, with over 30 years of professional experience in Banking and Investment. He has led some of organization's most significant projects, including The Dubai Quality Award, Segregation of the Corporate and Retail Business, CRM Implementation, and the ISO 9001-2000. Formerly he worked as a Deputy Manager - Business Group at Commercial Bank of Dubai. Mohammed Ghosheh holds an MBA in Finance from Lincoln University, UK, and a Bachelor’s Degree in Business Administration, Banking & Finance from Yarmouk University, Jordan.
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <hr width="30%" />




          {/* 222222222222 */}

          {/* <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Eng. Abdulla Al Suwaidi"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={abdullaalsuwaidi}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Eng. Abdulla Al Suwaidi</h3>
                  <p className="lead">
                    Executive Director - Strategic Planning and Innovation
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    Abdulla Al Suwaidi is an Executive Director at Noor Capital, with over 20 years of professional experience in the Government and Private sectors. Formerly he held several leadership positions in Abu Dhabi Company for Onshore Petroleum Operations (ADCO), the Abu Dhabi Municipality, and the Roads and Transport Authority (RTA) Dubai. Abdulla Al Suwaidi holds a Bachelor of Science Degree in Engineering Management and a Master's Degree in Total Quality Management.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
 */}


          <hr width="30%" />

          {/* 333333333333 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Eng. Muhammad Al Zoubi"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={muhammadalzoubi}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Eng. Muhammad Al Zoubi</h3>
                  <p className="lead">General Manager - Global Markets</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    Muhammad Al Zoubi is General Manager at Noor Capital with over 17 years of professional experience in finance. Formerly he was the Head of the VIP Department at Mac Sharaf and worked at Emaar Financial Services in Dubai. Muhammad Al Zoubi holds a Telecommunications Engineering Degree from the Jordan University of Science and Technology.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />

          {/* 44444444444 */}
          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Sher Ali"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={sherali}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Mr. Sher Ali</h3>
                  <p className="lead">Head of Investment Banking</p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    Sher Ali is the Head of Investment Banking at Noor Capital with over 17 years of tremendous experience in Investment Banking, Financial Institutions, and Structured Finance within the GCC. Formerly he worked as a Head of Debt Capital Markets & Structured Finance and Director in the Structured Finance Division of QINVEST, Qatar. Sher Ali has an MBA in Banking and Finance from Birmingham Business School.
                    </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr width="30%" />
          {/* 55555555555 */}

          <div
            className="row justify-content-center text-left"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <Row>
                <Col xs={20} sm={20} md={4} lg={4} xl={4}>
                  <img
                    alt="Mr. Adel M. El Hassan"
                    // className="img-fluid rounded"
                    className="img-fluid rounded-circle imgshadow"
                    src={adelelhassan}
                  />
                </Col>

                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  style={{ padding: "20px 15px 15px 30px" }}
                >
                  <h3 style={{ color: "#1b75bc" }}>Mr. Adel M. El Hassan </h3>
                  <p className="lead">
                    Manager, Legal Department & Reporter for the Board of
                    Directors
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    Adel M. El Hassan is Manager at Legal Department and Reporter for the Board of Directors at Noor Capital. He has over 25 years of experience in the Law Industry of the UAE as well as Magistrate, Advocate, and Legal Advisor globally. Adel M. El Hassan holds a Bachelor’s Degree in Law.
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          {/* <div className="row-70" /> */}
        </section>
      </div>

      <style jsx>
        {`
          .imgshadow {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19);
          }
        `}
      </style>
    </div>
  );
};

export default ManagementTeam;
