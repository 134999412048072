import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import live from "../../assets/images/pages/tools/live.png";
import img1 from "../../assets/images/bgtoolsAr.jpg";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
'ابقَ على اطلاع دائم بأحدث الاتجاهات والأخبار والتقنيات والاستراتيجيات من خلال الفعاليات والندوات الالكترونية.',
'فرصة للتعلم من خبراء الصناعة ذوي الخبرة العالية والمعتمدين من خلال الندوات الالكترونية.',
'اتخاذ قرارات دقيقة مع تحديث المعلومات وخطط التداول والعروض الأسبوعية.',
'احصل على أحدث المعلومات المتعلقة بالسياسات العالمية التي قد تؤثر على السوق.',
'يمكن الوصول إليها من أي مكان في العالم.',
];
const { Title } = Typography;

const NoorTrends_DailyBroadCastingAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Daily Live Broadcast - Noor Capital"
        description="Live broadcasting of the currency trading markets: It is one of the services of Noor Capital, which seeks to develop and train traders, and through which we discuss the most important opportunities for the most investment tools."
      />

      <BgBeforeAr
        pageHeadingTitle="خطط لرحلتك الاستثمارية بأمان مع تقويم البث في نور كابيتال"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="ادوات التداول"
        pageSubCategoryPath="#"
        pageName="غرفة البث المباشر"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
          البث الحي اليومي
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
             <b>ابقَ على اطلاع دائم بأحدث الندوات الإلكترونية والفعاليات</b>
            </p>

            <p className="parjustify text-right">
            هذه هي فرصتك لتظل على اطلاع دائم بأحدث اتجاهات السوق، وأنماط الرسوم البيانية، وتحليل الخبراء، وأكثر من ذلك بكثير فقط من خلال تقويم البث الخاص بنور كابيتال.
تقويم البث من نور كابيتال، هو نظام تفاعلي يمكن الوصول إليه من أي مكان في العالم، سيبقيك على اطلاع بأحدث الندوات الإلكترونية والفعاليات والاستراتيجيات لمساعدتك على التداول بثقة.

            </p>

            
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={live}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Investment Service"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          الميزات والفوائد 
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
              احصل على تقويم البث الآن
              </Title>
              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://noortrends.ae/daily-broadcasting/"
                  target="_blank"
                >
                  انقر هنا
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

    
    </div>
  );
};

export default NoorTrends_DailyBroadCastingAr;
