import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgnoortraderAr.jpg";

import noortreaderdesktop from "../../assets/images/pages/trading/noortreaderdesktop.png";
import WhyNoorAr from "./whyNoorAr";
import OpenNoorTraderDemoFormAr from "../forms/openNoorTraderDemoFormAr.js";
import NtVideo from "./ntVideo.js";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";

const style = { padding: "50px 0" };

const { Title } = Typography;

const NoorTrader_OpenDemoAccountAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="NoorTrader Demo Account - Noor Capital"
        description="We provide you with an opportunity to test our platform before you open a live account."
      />

      <BgBeforeAr
        pageHeadingTitle="تعلم كيفية التداول قبل بدء التجربة المباشرة."
        pageHeadingDescription="سجل الآن للحصول على حساب نور تريدر التجريبي  وطًور تجربة التداول الخاصة بك."
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="منصات التداول"
        pageSubCategoryPath="#"
        pageName="تسجيل حساب تجريبي / منصة نورتريدر"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            التسجيل للحصول على حساب تجريبي للتداول على منصة نورتريدر  - الذهب البدنية
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
              <b>
                هل انت جديد في منصة نور تريدر؟ نوفر لك فرصة لاختبار منصتنا قبل
                أن تفتح حسابًا حقيقيًا.
              </b>
            </p>
            <p className="parjustify text-right">
              مع نور تريدر، ستدخل عالم الاحتمالات من خلال الوصول إلى سوق السبائك
              على مدار الساعة طوال أيام الأسبوع. ويمكنك التداول بأموال افتراضية
              وفي نفس الوقت تعلم استخدام المنصة لتحقيق أقصى ربح بمجرد فتح حساب
              حقيقي.
            </p>

            <p className="parjustify text-right">
              يمنحك حساب نور تريدر التجريبي فرصة للاختبار قبل أن تتداول، مع وصول
              غير محدود وخالي من المخاطر إلى السوق في الوقت الفعلي وآخر
              التحديثات ونصائح الخبراء. افتح حسابًا تجريبيًا الآن واستخدم جميع
              الميزات التي من خلالها ستصبح متداولًا محترفًا.
            </p>

            <OpenNoorTraderDemoFormAr />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <NtVideo />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                  الميزات
                </Title>
                <h5>الوصول الفوري إلى سوق السبائك العالمية.</h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                                    توفير التخزين والتوصيل
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تتيح المنصة الوصول إلى السوق في الوقت الحقيقي، 24 ساعة يوميًا
                  / 5 أيام أسبوعيًا.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  متوافق مع جميع متصفحات الويب والهواتف الذكية.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تداول بأي حجم (كجم وأوز وغرام)
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تعلم كيفية شراء وبيع وإدارة المعادن الثمينة الخاصة بك بضغطة
                  واحدة فقط بأموال افتراضية.
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تداول خالي من المخاطر.
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  تعلم التداول كمحترف باستخدام بيانات وشركات في الوقت الفعلي.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  وصول آمن ومشفّر بنسبة 100٪.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  الأسعار الفورية للتداول المادي في الذهب ، الفضة ، إلخ
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingLeft: "10px", color: "#1b75bc" }}
                  ></i>
                  المرونة باستخدام نظام متعدد الوظائف
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img
                  alt="mt4desktop"
                  className="img-fluid"
                  src={noortreaderdesktop}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <br />

      <WhyNoorAr />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                اختبر حسابنا التجريبي قبل أن تتداول على نور تريدر
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="/physical-trading-platforms/open-noor-trader-live"
                  target="_blank"
                >
                  تسجيل حساب تجريبي
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NoorTrader_OpenDemoAccountAr;
