import React from "react";
import globalstyle from "../../style.module.css";
import leadinginvestment from "../../assets/images/pages/whynoor/leading-investment.svg";
import license from "../../assets/images/pages/whynoor/license.svg";
import funding from "../../assets/images/pages/whynoor/funding.svg";
import execution from "../../assets/images/pages/whynoor/execution.svg";
import platform from "../../assets/images/pages/whynoor/platform.svg";
import instruments from "../../assets/images/pages/whynoor/instruments.svg";
import research from "../../assets/images/pages/whynoor/research.svg";
import support from "../../assets/images/pages/whynoor/support.svg";

const WhyNoorAr = () => {
  return (
    <div>
    

      {/* Feature 22 */}
      <section className="fdb-block"  style={{"backgroundColor":"#fff"}}>
        <div className={globalstyle.container}>
          <div className="row text-right">
            <div className="col-12">
              <h4 style={{ color: "#1b75bc" }}>
              لماذا عليك إختيار نور كابيتال
              </h4>
            </div>
          </div>


          <div className="row text-right mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={leadinginvestment}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}

                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>شركة رائدة في عالم الاستثمار والوساطة المالية</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={license}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>مرخصة من قبل هيئة الأوراق المالية والسلع</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={funding}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>سحب وإيداع في بنوك إماراتية محلية رائدة</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={execution}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>تنفيذ مباشر لأوامر التداول عن طريق أقوي مزودي السيولة</h6>
                </div>
              </div>
            </div>

          </div>
         


         
          <div className="row text-right mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={platform}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>منصات متطورة ومبتكرة</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={instruments}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fff","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>مجموعة واسعة من المنتجات المالية التي تناسب احتياجاتك</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={research}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>مركز أبحاث متخصص وخدمات تدريب وتعليم</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={support}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>دعم  متعدد اللغات 24 ساعة – 6 أيام أسبوعيًا</h6>
                </div>
              </div>
            </div>

          </div>
         


        </div>
      </section>



    </div>
  );
};

export default WhyNoorAr;
