export const emirates = [
  
  {
    emirate_code: "",
    emirate_name: "Select Emirate",
  },
  {
    emirate_code: "Abu Dhabi",
    emirate_name: "Abu Dhabi",
  },
  {
    emirate_code: "Dubai",
    emirate_name: "Dubai",
  },
  {
    emirate_code: "Sharjah",
    emirate_name: "Sharjah",
  },
  {
    emirate_code: "Ajman",
    emirate_name: "Ajman",
  },
  {
    emirate_code: "Ras Al Khaimah",
    emirate_name: "Ras Al Khaimah",
  },
  {
    emirate_code: "Fujairah",
    emirate_name: "Fujairah",
  },
  {
    emirate_code: "Umm Al Quwain",
    emirate_name: "Umm Al Quwain",
  },
  
];


export const hearabout = [
  
  {
    hearabout_code: "",
    hearabout_name: "How did you hear about Noor Capital?",
  },
  {
    hearabout_code: "Employer",
    hearabout_name: "Employer",
  },
  {
    hearabout_code: "Search Engines",
    hearabout_name: "Search Engines",
  },
  {
    hearabout_code: "Twitter",
    hearabout_name: "Twitter",
  },
  {
    hearabout_code: "Facebook",
    hearabout_name: "Facebook",
  },
  {
    hearabout_code: "Instagram",
    hearabout_name: "Instagram",
  },
  {
    hearabout_code: "LinkedIn",
    hearabout_name: "LinkedIn",
  },
  {
    hearabout_code: "Snapchat",
    hearabout_name: "Snapchat",
  },
  {
    hearabout_code: "Email",
    hearabout_name: "Email",
  },
  {
    hearabout_code: "TV",
    hearabout_name: "TV",
  },
  {
    hearabout_code: "Radio",
    hearabout_name: "Radio",
  },
  {
    hearabout_code: "Newspaper",
    hearabout_name: "Newspaper",
  },
  {
    hearabout_code: "Expo or Conferences",
    hearabout_name: "Expo & Conferences",
  },
  
  {
    hearabout_code: "Recommendation from Friends or Family",
    hearabout_name: "Recommendation from Friends or Family",
  },
  {
    hearabout_code: "Others",
    hearabout_name: "Others",
  },
  
  
];



export const emiratesAr = [
  
  {
    emirate_code: "",
    emirate_name: "اختر الإمارة",
  },
  {
    emirate_code: "Abu Dhabi",
    emirate_name: "أبو ظبي",
  },
  {
    emirate_code: "Dubai",
    emirate_name: "دبي",
  },
  {
    emirate_code: "Sharjah",
    emirate_name: "الشارقة",
  },
  {
    emirate_code: "Ajman",
    emirate_name: "عجمان",
  },
  {
    emirate_code: "Ras Al Khaimah",
    emirate_name: "رأس الخيمة",
  },
  {
    emirate_code: "Fujairah",
    emirate_name: "الفجيرة",
  },
  {
    emirate_code: "Umm Al Quwain",
    emirate_name: "أم القيوين",
  },
  
];


export const hearaboutAr = [
  
  {
    hearabout_code: "" ,
    hearabout_name: "كيف سمعت عن نور كابيتال؟",
  } ,
  {
    hearabout_code: "Employer" ,
    hearabout_name: "موظفينا" ,
  } ,
  {
    hearabout_code: "محركات البحث",
    hearabout_name: "محركات البحث",
  } ,
  {
    hearabout_code: "Twitter" ,
    hearabout_name: "تويتر" ,
  } ,
  {
    hearabout_code: "Facebook" ,
    hearabout_name: "فيس بوك" ,
  } ,
  {
    hearabout_code: "Instagram" ,
    hearabout_name: "انستجرام" ,
  } ,
  {
    hearabout_code: "LinkedIn" ,
    hearabout_name: "لينكد ان" ,
  } ,
  {
    hearabout_code: "Snapchat",
    hearabout_name: "سناب شات" ,
  } ,
  {
    hearabout_code: "Email" ,
    hearabout_name: "البريد الإلكتروني" ,
  } ,
  {
    hearabout_code: "TV" ,
    hearabout_name: "تلفزيون" ,
  } ,
  {
    hearabout_code: "Radio" ,
    hearabout_name: "راديو" ,
  } ,
  {
    hearabout_code: "Newspaper",
    hearabout_name: "جريدة",
  } ,
  {
    hearabout_code: "Expo or Conferences" ,
    hearabout_name: "المعرض والمؤتمرات",
  } ,
  
  {
    hearabout_code: "Recommendation from Friends or Family" ,
    hearabout_name: "توصية من صديق أو العائلة" ,
  } ,
  {
    hearabout_code: "Others" ,
    hearabout_name: "الآخرين" ,
  }
];


export const interests = [
  
  {
    interest_code: "not-selected",
    interest_name: "Choose your interest",
  },
  {
    interest_code: "Liquidity",
    interest_name: "Liquidity",
  },
  {
    interest_code: "MT4",
    interest_name: "MT",
  },
  {
    interest_code: "FIX API",
    interest_name: "FIX API",
  },
  {
    interest_code: "Trading Platforms",
    interest_name: "Trading Platforms",
  },
  {
    interest_code: "White Label",
    interest_name: "White Label",
  },
  
  
];


export const interestsAr = [
  
  {
    interest_code: "not-selected",
    interest_name: "إختيار المنتج",
  },
  {
    interest_code: "Liquidity",
    interest_name: "Liquidity",
  },
  {
    interest_code: "MT4",
    interest_name: "MT",
  },
  {
    interest_code: "FIX API",
    interest_name: "FIX API",
  },
  {
    interest_code: "Trading Platforms",
    interest_name: "Trading Platforms",
  },
  {
    interest_code: "White Label",
    interest_name: "White Label",
  },
  
  
];