import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgmt4.jpg";
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import WhyNoorAr from "./whyNoorAr";

const style = { padding: "50px 0" };
const { Title } = Typography;

const Metatrader4_WebTraderAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Open Live Account - MT4 Web Trader - Noor Capital"
        description="Noor Capital MT4 WebTrader provides instant online access to the global financial markets to trade in all demo and real accounts on both PC and Mac, without rejecting orders and without requotes."
      />

      <BgBeforeAr
        pageHeadingTitle="Flexible Trading For Maximum Profit"
        pageHeadingDescription="With Noor Capital's MetaTrader 4 (MT4), You Can Trade From Anywhere, Any Browser, And Anytime"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="MetaTrader 4 WebTrader"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
          Access your FX Trading Account from WebTrader
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify text-right">
              Have you ever wondered the secret of successful traders? They take
              the decision and act immediately with whatever available resources
              in hand. No matter where you are, now you can work smart and
              transform your trading experience with Noor Capital's MetaTrader 4
              (MT4) platform accessible from any browser around the world.
            </p>
            <p className="parjustify text-right">
              The Noor Capital's MetaTrader 4 (MT4) web version allows our
              traders to access their account and trade seamlessly from any web
              browser and operating systems (Mac, Windows, Linux, etc), all you
              need is an Internet connection to get started.
            </p>

            <br />

            <div className="row text-center ">
              <div className="col-12 col-sm-12 col-md-8 col-lg-4 m-sm-auto">
                <p className="mt-3">
                  <a
                    className="btn btn-primary btn-lg"
                    href="https://metatraderweb.app/trade?servers=NoorCapital-Demo%2cNoorCapital-Live&trade_server=NoorCapital-Live&demo_server=NoorCapital-Demo&startup_mode=open_live&lang=en"
                    target="_blank"
                    
                  >
                    Open WebTrader
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={2}>
                  Features
                </Title>
                <h5>
                  Don't Let Your Current Location Or Situation Stop You From
                  Trading. With Noor Capital's Metatrader 4 (MT4) Web Version,
                  You Are Always Connected And Making Profits.
                </h5>
                <br />
              </div>
            </div>

            <div
              className="row text-lg-right align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Instant access to your account from any web browser and
                  operating system, anywhere in the world
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Access charts, indicators, quotes, news, and much more from
                  your web trader account
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Advance indicators and charts to help with trading strategies
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get trading signals from top providers
                </p>
                <br />
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Access your account on any web browser from anywhere in the
                  world
                </p>
                <br />
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-right pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Trade on the go with Noor Capital's MetaTrader 4 (MT4) mobile
                  app
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Noor Capital's MetaTrader 4 (MT4) EAs (Expert Advisors) to
                  help you trade risk-free
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 dedicated customer service available to help you with any
                  concerns or advice
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Option to choose your preferred language (English/Arabic)
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Experience the most secure and user-friendly trading web
                  platform around the world
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4mobile" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={4}>
                Escalate your trading experience from anywhere, anytime & any
                browser
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://metatraderweb.app/trade?servers=NoorCapital-Demo%2cNoorCapital-Live&trade_server=NoorCapital-Live&demo_server=NoorCapital-Demo&startup_mode=open_live&lang=en"
                  target="_blank"
                >
                  Open WebTrader
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>


      <WhyNoorAr />
    
    </div>
  );
};

export default Metatrader4_WebTraderAr;
