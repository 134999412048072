import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import leadinginvestment from "../../assets/images/pages/whynoor/leading-investment.svg";
import license from "../../assets/images/pages/whynoor/license.svg";
import funding from "../../assets/images/pages/whynoor/funding.svg";
import execution from "../../assets/images/pages/whynoor/execution.svg";
import platform from "../../assets/images/pages/whynoor/platform.svg";
import instruments from "../../assets/images/pages/whynoor/instruments.svg";
import research from "../../assets/images/pages/whynoor/research.svg";
import support from "../../assets/images/pages/whynoor/support.svg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };

const WhyNoorCapitalAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Why Choose Noor Capital"
        description="Noor Capital has developed an exclusive asset management approach to advise its clients on making Investments across various Asset Classes."
      />
      <BgBeforeAr
        pageHeadingTitle="أضف قيمة إلى استثماراتك مع نور كابيتال - علامة تجارية مبنية على الثقة."
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="من نحن"
        pageSubCategoryPath="#"
        pageName="لماذا تختارنا"
      />

      <section className="fdb-block">
        <div className={globalstyle.container}>
          <div className="row">
            <div className="col-12 text-center">
              <h2 style={{ color: "#1b75bc" }}>
              ما الذي يجعلك تعتقد أن نور كابيتال اختيارًا ممتازًا عالميًا؟
              </h2>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-5">
            <div className="col-12 col-md-6 col-lg-5 m-auto">
              <h4 style={{ color: "#1b75bc" }}>
                <i
                  class="fas fa-arrow-circle-left"
                  style={{ paddingLeft: "15px" }}
                ></i>
                الخدمات المالية المنظمة
              </h4>
              <p className={globalstyle.parjustify}>
              نور كابيتال هي شركة مساهمة خاصة، تأسست عام 2005، مسجلة لدى دائرة التنمية الاقتصادية في أبوظبي. نظرًا لكوننا مفوضين حسب الأصول من قبل هيئة الأوراق المالية والسلع، فإننا ننفذ أنشطتنا المتعلقة بإدارة الاستثمار والاستشارات المالية والتداول عبر الإنترنت في بيئة آمنة.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-md-0">
              <h4 style={{ color: "#1b75bc" }}>
                <i
                  class="fas fa-arrow-circle-left"
                  style={{ paddingLeft: "15px" }}
                ></i>
               حلول مالية واستثمارية متعددة
              </h4>
              <p className={globalstyle.parjustify}>
              من خلال المزيج الاستثنائي من التكنولوجيا القوية، والفريق المتمرس، والمنصات الشاملة مثل نور تريدر Noor Trade ونور كابيتال لتداول الأسهم GTN، (ومنصة ميتاتريدر 4 MT4). لقد أصبحنا أحد أفضل مزودي الحلول لجميع احتياجاتك المالية والاستثمارية مثل الاستشارات المالية، وإدارة الأصول إلى التداول السلس عبر الإنترنت حيث يحصل مستثمرينا على الخدمات الجاهزة تحت سقف واحد.
              </p>
            </div>
          </div>
          <div className="row text-right justify-content-center pt-lg-4">
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>
                <i
                  class="fas fa-arrow-circle-left"
                  style={{ paddingLeft: "15px" }}
                ></i>
               من أي مكان في العالم
              </h4>
              <p className={globalstyle.parjustify}>
              مع نور كابيتال، يمكنك الآن بدء التداول في أي وقت وفي أي مكان من منصات التداول المختلفة لدينا التي تدعم محافظ متعددة مثل سوق العملات الأجنبية والسلع الخفيفة والطاقات والمزيد.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 m-auto pt-3 pt-lg-0">
              <h4 style={{ color: "#1b75bc" }}>
                <i
                  class="fas fa-arrow-circle-left"
                  style={{ paddingLeft: "15px" }}
                ></i>
               استشارات استثمارية
              </h4>
              <p className={globalstyle.parjustify}>
              مع سنوات من الخبرة ومجموعة من المهنيين المعتمدين ، فإن نور كابيتال مجهزة تجهيزًا عالي المستوي لتوجيه مستثمرينا لاتخاذ القرارات الاستثمارية الصحيحة. لقد نجحنا في مساعدة المستثمرين الأفراد والشركات والمؤسسات من خلال تقديم المشورة الاستثمارية الثاقبة وفرص الاستثمار الحصرية على مستوى العالم.
              </p>
            </div>
          </div>
        </div>
      </section>



      {/* Feature 22 */}
      <section className="fdb-block"  style={{"backgroundColor":"#f2f4f7"}}>
        <div className={globalstyle.container}>
          <div className="row text-right">
            <div className="col-12">
              <h4 style={{ color: "#1b75bc" }}>
              هناك العديد من الأسباب التي تجعل نور كابيتال شركة رائدة في مجال الاستثمار والوساطة في الإمارات العربية المتحدة وعالميًا:
              </h4>
            </div>
          </div>


          <div className="row text-right mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={leadinginvestment}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}

                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>شركة رائدة في عالم الاستثمار والوساطة المالية</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={license}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>مرخصة من قبل هيئة الأوراق المالية والسلع</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={funding}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>سحب وإيداع في بنوك إماراتية محلية رائدة</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={execution}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>تنفيذ مباشر لأوامر التداول عن طريق أقوي مزودي السيولة</h6>
                </div>
              </div>
            </div>

          </div>
         


         
          <div className="row text-right mt-5">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={platform}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>منصات متطورة ومبتكرة</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={instruments}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fff","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>مجموعة واسعة من المنتجات المالية التي تناسب احتياجاتك</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={research}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>مركز أبحاث متخصص وخدمات تدريب وتعليم</h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3 pt-3 pt-sm-3 pt-md-0">
              <div className="row">
                <div className="col-3">
                  <img
                    alt="mission"
                    className="fdb-icon"
                    src={support}
                    width="70px"
                    style={{"marginBottom":"9px","backgroundColor":"#fbfbfb","padding":"13px","border":"1px solid #d8d8d5"}}
                  />
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6 style={{"lineHeight":"26px"}}>دعم  متعدد اللغات 24 ساعة – 6 أيام أسبوعيًا</h6>
                </div>
              </div>
            </div>

          </div>
         


        </div>
      </section>

    
    </div>
  );
};

export default WhyNoorCapitalAr;
