import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgaccountsAr.jpg";
import { Link } from "react-router-dom";

import { Tabs } from "antd";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";


import IndividualAgreementAr from "../../assets/forms/forex-accounts/individual-account/noor-capital-client-agreement-en-ar.pdf";
import IndividualAgreementSample from "../../assets/forms/forex-accounts/individual-account/noor-capital-client-agreement-en-ar-sample.pdf";

import ApplicationForm from "../../assets/forms/forex-accounts/kyc.pdf";
import CorporateAgreement from "../../assets/forms/forex-accounts/corporate-account/noor-capital-corporate-agreement-english.pdf";
import IBAgreement from "../../assets/forms/forex-accounts/ib/Noor_Capital_IB_Agreement_English.pdf";
import IndividualIntroducingBrokerAgreement from "../../assets/forms/forex-accounts/ib/version-ib-ind.pdf";
import ScheduleA from "../../assets/forms/forex-accounts/ib/Noor_Capital_Schedule_A.pdf";
import InformationUpdate from "../../assets/forms/miscellaneous/noor-capital-info-update-english.pdf";
import WithdrawalRequest from "../../assets/forms/miscellaneous/funds-withdrawal-request-english.pdf";
import SubAccountRequest from "../../assets/forms/miscellaneous/additional-account.pdf";
import InterAccountTransferRequest from "../../assets/forms/miscellaneous/noor-capital-inter-account-transfer-request.pdf";
import isAgreement from "../../assets/forms/International-stock/International_Markets_Agreement_Version_Int_Stock.pdf";
import Appendix1 from "../../assets/forms/international-markets/appendix-1-version-int-stock.pdf";
import Appendix2 from "../../assets/forms/international-markets/appendix-2-version-int-stock.pdf";
import Appendix3 from "../../assets/forms/international-markets/appendix-3-version-int-stock.pdf";
import Appendix4 from "../../assets/forms/international-markets/appendix-4-version-int-stock.pdf";
import Appendix5 from "../../assets/forms/international-markets/appendix-5-version-int-stock.pdf";
import Appendix6 from "../../assets/forms/international-markets/appendix-5-version-mt5.pdf";
import IndAuthorisationLetter from "../../assets/forms/international-markets/individual-ksa-forms/ind-authorization-letter.pdf";
import IndCRS from "../../assets/forms/international-markets/individual-ksa-forms/Ind-crs-and-fatca-for-individual.pdf";
import IndRCCustomer from "../../assets/forms/international-markets/individual-ksa-forms/ind-rc-customer-information-form.pdf";
import IndSetupLocal from "../../assets/forms/international-markets/individual-ksa-forms/ind-setup-local-share-portfolio-application.pdf";
import InstituteAuthorizationLetter from "../../assets/forms/international-markets/institutions-ksa-forms/institute-authorization-letter.pdf";
import InstituteFATCASelfCertification from "../../assets/forms/international-markets/institutions-ksa-forms/institute-fatca-self-certification-form.pdf";
import InstituteRCCustomerInformation from "../../assets/forms/international-markets/institutions-ksa-forms/institute-rc-customer-information-form.pdf";
import InstituteSetupLocalSharePortfolioApplication from "../../assets/forms/international-markets/institutions-ksa-forms/institute-setup-local-share-portfolio-application.pdf";
import phgold from "../../assets/forms/physical-gold/Version-Metal04-09.2020-14.09.2020.pdf";

const { Title } = Typography;

const style = { padding: "50px 0" };

const { TabPane } = Tabs;

const NCforms = () => (
  <Tabs defaultActiveKey="1" centered size="large">
    <TabPane tab="تداول العملات والسلع" key="1">
      <section className="fdb-block">
        <div className="container">
          {/* 222222222 */}
          <div className="row text-right">
            <div className="col-12">
              <Divider orientation="left">
                <h3>الحسابات الفردية </h3>
              </Divider>
            </div>
          </div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndividualAgreementAr} target="_blank">
                    اتفاقية الأفراد
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                    نموذج الطلب
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          {/* 222222222 */}
          <div className="row text-right">
            <div className="col-12">
              <Divider orientation="left">
                <h3>الحسابات المشتركة </h3>
              </Divider>
            </div>
          </div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={CorporateAgreement} target="_blank">
                      Corporate Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>


          {/* <br />
          <br />
          
          <div className="row text-right">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Joint Account</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={CorporateAgreement} target="_blank">
                      Joint Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div> */}

          <br />
          <br />
          {/* 444444444 */}
          <div className="row text-right">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Referral Account</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IBAgreement} target="_blank">
                    Referral Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a
                      href={IndividualIntroducingBrokerAgreement}
                      target="_blank"
                    >
                       Marketing Partnership Program
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={ScheduleA} target="_blank">
                      Schedule A
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          {/* 222222222 */}
          <div className="row text-right">
            <div className="col-12">
              <Divider orientation="left">
                <h3>متفرقات</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InformationUpdate} target="_blank">
                      Information Update
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={WithdrawalRequest} target="_blank">
                      Withdrawal Request
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={SubAccountRequest} target="_blank">
                      Sub Account Request
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InterAccountTransferRequest} target="_blank">
                      Inter-Account Transfer Request
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* 4444444444 */}
        </div>
      </section>
    </TabPane>

    <TabPane tab="تداول الأسهم العالمية" key="2">
      <section className="fdb-block">
        <div className="container">
          {/* 222222222 */}
          <div className="row text-right"></div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={isAgreement} target="_blank">
                      Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    {" "}
                    <a href={ApplicationForm} target="_blank">
                      Application Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          {/* 444444444 */}
          <div className="row text-right">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Appendix</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-2">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix1} target="_blank">
                      Appendix 1
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix2} target="_blank">
                      Appendix 2
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix3} target="_blank">
                      Appendix 3
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix4} target="_blank">
                      Appendix 4
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix5} target="_blank">
                      GTN-Appendix 5
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>


          <div className="col-12 col-sm-6 col-lg-2 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={Appendix6} target="_blank">
                    MT5-Appendix 6
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          

          <br />
          <br />

          {/* 444444444 */}
          <div className="row text-right">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Individual KSA Forms</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndAuthorisationLetter} target="_blank">
                      Ind Authorisation Letter
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndCRS} target="_blank">
                      Ind CRS & FATCA for Individuals
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndRCCustomer} target="_blank">
                      Ind RC Customer Information Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndSetupLocal} target="_blank">
                      Ind Setup Local Share Portfolio Application
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* 444444444 */}
          <div className="row text-right">
            <div className="col-12">
              <Divider orientation="left">
                <h3>Institutions KSA Forms</h3>
              </Divider>
            </div>
          </div>
          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InstituteAuthorizationLetter} target="_blank">
                      Institute Authorization Letter
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InstituteFATCASelfCertification} target="_blank">
                      Institute FATCA Self Certification Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={InstituteRCCustomerInformation} target="_blank">
                      Institute RC Customer Information Form
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-3 pt-sm-0">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "35px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a
                      href={InstituteSetupLocalSharePortfolioApplication}
                      target="_blank"
                    >
                      {" "}
                      Institute Setup Local Share Portfolio Application
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabPane>


{/* Physical */}
    {/* <TabPane tab="التداول المادي" key="3">
      <section className="fdb-block">
        <div className="container">
         

          <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={phgold} target="_blank">
                      Agreement
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabPane> */}

{/* END Physical */}


  </Tabs>
);

const Accounts_AccountFormsAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Trading Account Forms - Noor Capital"
        description="Download the Noor Capital Trading Account Form"
      />

      <BgBeforeAr
        pageHeadingTitle="المستندات المطلوبة لحسابات التداول"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="التداول"
        pageSubCategoryPath="#"
        pageName="مستندات الحسابات"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <p className="parjustify text-right" style={{ fontSize: "16.5px" }}>
            توفر نور كابيتال جميع المستندات المطلوبة
            لحسابات التداول الفردية والمشتركة ، واتفاقيات العميل لإنشاء حساب ،
            والتفويض بالتداول ، ومتطلبات الهامش والودائع ، والتسعير ، والأوامر
            المعلقة في السوق وفروق الأسعار الموسعة.
          </p>
        </Row>


        <div className="row text-right mt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="row">
                <div className="col-3">
                  <i
                    class="fas fa-file-signature"
                    style={{ fontSize: "48px", color: "#1b75bc" }}
                  ></i>
                </div>
                <div className="col-9" style={{ margin: "auto" }}>
                  <h6>
                    <a href={IndividualAgreementSample} target="_blank">
                    اتفاقية الأفراد - نموذج
                    </a>
                  </h6>
                </div>
              </div>
              </div>
              </div>

              <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <p className="parjustify text-right" style={{ fontSize: "16.5px" }}>
<b>للحصول على أي اتفاقيات ونماذج أخرى مطلوبة، يرجى الدخول إلى منطقة ماي نور أو التواصل مع فريق العمليات على ops@noorcapital.ae أو الاتصال على الرقم 0097142795400</b>

          </p>





        </Row>



         {/* 444444444444 */}
         <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>سياسة الخصوصية</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/privacy-policy"> سياسة الخصوصية</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 555555555555 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>شروط التداول</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/trading/accounts/trading-conditions">شروط التداول</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 6666666666 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>الإفصاح عن المخاطر</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/trading-tools/risk-disclosure"> الإفصاح عن المخاطر</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 7777777777 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>تحذير من الإحتيال</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/fraud-warning"> تحذير من الإحتيال</Link>
                </b>
              </p>
            </div>
          </div>
          <br />

          {/* 7777777777 */}
          <div className="row">
            <div className="col text-right">
              <h4 style={{ color: "#1b75bc" }}>سياسة ملفات تعريف الارتباط</h4>
              <p className={globalstyle.parjustify}>
                يرجى قراءة
                <b>
                  <Link to="/ar/cookies-policy"> سياسة ملفات تعريف الارتباط</Link>
                </b>
              </p>
            </div>
          </div>




        {/* <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <NCforms />
          </Col>
        </Row> */}
      </div>
      <br />

      <br />

      <style jsx>
        {`
          .mt-5,
          .my-5 {
            margin-top: 2rem !important;
          }
          a {
            color: #9ea0a2;
            text-decoration: underline;
          }

          .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
            padding: 22px 15px;
            font-size: 16px;
        }
        `}
      </style>
    </div>
  );
};

export default Accounts_AccountFormsAr;
