import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
import img1 from "../../assets/images/bgtradingbanner3Ar.jpg";
import bullion from "../../assets/images/pages/instruments/bullion.jpg";
import ProductsAr from "./productsAr";
import Instruments_Bullions_Table from "./trading_Instruments_Bullions_table";

const { Title } = Typography;

const Instruments_BullionAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Bullion Trading - Noor Capital"
        description="Bullion Trading is among the top profit-making trades these days. It is a marketplace where gold and silver are traded."
      />
      <BgBeforeAr
        pageHeadingTitle="استثمر في الذهب والفضة وأكثر"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="التداول"
        pageMainCategoryPath="#"
        pageSubCategoryName="الأسواق"
        pageSubCategoryPath="#"
        pageName="السبائك"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            السبائك
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              يعتبر تداول السبائك من بين أكثر مجالات التداول تحقيقًا للأرباح في
              الوقت الحالي. حيث يمثل سوقًا لتداول الذهب والفضة. وعندما تقوم
              بتداول الذهب والفضة مع شركة نور كابيتال، فإننا نحرص على حصولكم على
              مستويات تنافسية من العمولة والربح. ولذلك فإننا نقدم مستويات
              للأسعار من بين الأفضل في السوق العالمي. كما نقوم بتزويدكم بأكثر
              طرق التداول بساطة لكي تتمكن من التداول لوقت أطول.
            </p>

            <p className="parjustify">
              وتوفر شركة نور كابيتال لعملائها من المستثمرين والمتداولين خدمات
              متقدمة من البيانات المجمعة وأكثر التحليلات تنافسية لتحركات أسعار
              الذهب بشكل يلائم أهداف الاستثمار والتداول المختلفة.
            </p>

            <p className="parjustify">
              وفي شركة نور كابيتال، لا نقوم فقط بتزويدكم بمنصات متطورة للتداول
              بل نقوم كذلك بتقديم المعرفة والتعليم المناسب عبر الندوات
              الإلكترونية والمحاضرات المصورة، لكي تكون مزودًا بالمعلومات الكافية
              وتقوم بحساب خطواتك وقراراتك عندما تقوم بتداول السبائك.
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={bullion}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Online Forex Trading"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Title level={3} style={{ color: "#1b75bc" }}>
          قائمة المواصفات
          </Title>
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Instruments_Bullions_Table />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>{``}</style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <h2 className="display-5" style={{ color: "#1b75bc" }}>
                ابدأ التداول في السبائك الآن
              </h2>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank"
                >
                  سجل الآن
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ProductsAr />
    </div>
  );
};

export default Instruments_BullionAr;
