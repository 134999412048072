import React, { useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import headerStyle from "./header.module.css";
import globalStyle from "../../style.module.css";
import { Row, Col, Button, Menu, Dropdown } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

import uaeflag from "../../assets/images/uae-flag.svg";

import { useCookies } from "react-cookie";

import LanguageContext from "../../language-context";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
const opendemo = (
  <Menu>
    <Menu.Item key="1">
      <Link to="/trading-platforms/mt4-open-forex-demo">FX Trading</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/trading-platforms/metatrader5-platform">MT5 - Cash Equities</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to="/stocks-trading-platforms/open-stocks-demo">GTN - Cash Equities</Link>
    </Menu.Item>
    {/* <Menu.Item key="2">
      <Link to="/physical-trading-platforms/open-noor-trader-demo">
        Physical Trading
      </Link>
    </Menu.Item> */}
    <Menu.Item key="4">
      <Link to="/institutional/request-demo">Institutional Liquidity</Link>
    </Menu.Item>
    <Menu.Item key="5">
      <Link to="/about/contact-us">Contact Us</Link>
    </Menu.Item>
  </Menu>
);

const openlive = (
  <Menu>
    <Menu.Item key="1">
      <a
        href="https://mynoor.noorcap.ae/en/registration/signup"
        target="_blank"
      >
        FX Trading
      </a>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/stocks-trading-platforms/open-gtn-live">Cash Equities</Link>
    </Menu.Item>
    {/* <Menu.Item key="3">
      <Link to="/physical-trading-platforms/open-noor-trader-live">
        Physical Trading
      </Link>
    </Menu.Item> */}
    <Menu.Item key="4">
      <Link to="/institutional/request-live">Institutional Liquidity</Link>
    </Menu.Item>
    <Menu.Item key="5">
      <Link to="/about/contact-us">Contact Us</Link>
    </Menu.Item>
  </Menu>
);

const UpHeader = () => {
  const location = useLocation();

  const languageSegment = location.pathname;

  const arLink = "/ar" + `${languageSegment}`;

  const { language, setLanguage } = useContext(LanguageContext);

  const [cookies, setCookie, removeCookie] = useCookies(["en"]);

  const setLan = (value) => {
    setCookie("language", value, { path: "/" });
    setLanguage(value);
  };
  return (
    <>
      <div className={headerStyle.topHeaderMenu}>
        <Row gutter={[2, 4]} align="middle">
          <Col md={0} xs={12} lg={0}>
            <Link to="/services/global-financial-advisory">
              {" "}
              <i
                class="fas fa-angle-right"
                aria-hidden="true"
                style={{ color: "#1b75bc", paddingRight: "6px" }}
              ></i>{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#303236",
                  paddingRight: "15px",
                }}
              >
                Financial Advisory
              </span>
            </Link>
          </Col>
          <Col md={0} xs={12} lg={0}>
            <Link to="/services/asset-management">
              <i
                class="fas fa-angle-right"
                aria-hidden="true"
                style={{ color: "#303236", paddingRight: "5px" }}
              ></i>{" "}
              <span style={{ fontWeight: "bold", color: "#303236" }}>
                Asset Management
              </span>
            </Link>
          </Col>
        </Row>

        <Row gutter={[2, 4]} align="middle">
          <Col md={0} xs={12} lg={0}>
            <Link to="/services/investment">
              {" "}
              <i
                class="fas fa-angle-right"
                aria-hidden="true"
                style={{ color: "#1b75bc", paddingRight: "6px" }}
              ></i>{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#303236",
                  paddingRight: "15px",
                }}
              >
                Investment
              </span>
            </Link>
          </Col>
          <Col md={0} xs={12} lg={0}>
            <Link to="/services/funds-administration">
              <i
                class="fas fa-angle-right"
                aria-hidden="true"
                style={{ color: "#303236", paddingRight: "5px" }}
              ></i>{" "}
              <span style={{ fontWeight: "bold", color: "#303236" }}>
                Fund Administration
              </span>
            </Link>
          </Col>
        </Row>
      </div>
      
      
      
      <div className={headerStyle.upHeader}>
        <div className={globalStyle.container}>
          <Row gutter={[2, 4]} align="middle">
            {/* Service 1 */}
            <Col md={4} xs={0} lg={5} className={headerStyle.upHeaderSpan}>
              <Link to="/services/global-financial-advisory">
                {" "}
                <i
                  class="fas fa-angle-right"
                  aria-hidden="true"
                  style={{ color: "#1b75bc", paddingRight: "6px" }}
                ></i>{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#ffffff",
                    paddingRight: "15px",
                  }}
                >
                  Financial Advisory
                </span>
              </Link>

              <Link to="/services/asset-management">
                <i
                  class="fas fa-angle-right"
                  aria-hidden="true"
                  style={{ color: "#1b75bc", paddingRight: "5px" }}
                ></i>{" "}
                <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                  Asset Management
                </span>
              </Link>
            </Col>

            {/* Service 2 */}
            <Col md={4} xs={0} lg={5} className={headerStyle.upHeaderSpan}>
              <Link to="/services/investment">
                {" "}
                <i
                  class="fas fa-angle-right"
                  aria-hidden="true"
                  style={{ color: "#1b75bc", paddingRight: "6px" }}
                ></i>{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#ffffff",
                    paddingRight: "15px",
                  }}
                >
                  Investment
                </span>
              </Link>

              <Link to="/services/funds-administration">
                <i
                  class="fas fa-angle-right"
                  aria-hidden="true"
                  style={{ color: "#1b75bc", paddingRight: "5px" }}
                ></i>{" "}
                <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                  Fund Administration
                </span>
              </Link>
            </Col>

            {/* Important Links */}
            <Col
              md={7}
              xs={0}
              lg={7}
              className={headerStyle.upHeaderSpan}
              style={{ textAlign: "center" }}
            >
              <span>
                <a
                  href="https://mynoor.noorcap.ae/en/traders/login"
                  target="_blank"
                >
                  <i
                    class="fa fa-user-alt"
                    style={{
                      fontSize: "13px",
                      color: "#1a86da",
                      paddingRight: "5px",
                    }}
                  ></i>
                  <span style={{ fontSize: "12px", color: "#1a86da" }}>
                    MyNoor
                  </span>
                </a>
              </span>
              <span>
                <a
                  href="https://agent.noorcap.ae/en/agent/login"
                  target="_blank"
                >
                  <i
                    class="fa fa-user-cog"
                    style={{
                      fontSize: "13px",
                      color: "#1a86da",
                      paddingRight: "5px",
                    }}
                  ></i>
                  <span style={{ fontSize: "12px", color: "#1a86da" }}>
                    Agent Area
                  </span>
                </a>
              </span>

              <span>
                <Link to="/trading/accounts/account-funding">
                  <i
                    class="fa fa-credit-card"
                    style={{
                      fontSize: "13px",
                      color: "#1a86da",
                      paddingRight: "5px",
                    }}
                  ></i>
                  <span style={{ fontSize: "12px", color: "#1a86da" }}>
                    Fund Account
                  </span>
                </Link>
              </span>
            </Col>
            <Col md={9} xs={19} lg={7}>
              <Row gutter={[2, 4]} align="middle">
                <Col md={12} xs={9} lg={12} className="ctaupbutton">
                  <Dropdown overlay={opendemo} style={{ zIndex: "1000" }}>
                    <Button
                      style={{
                        backgroundColor: "#0f75bc",
                        color: "#fff",
                        border: "0",
                        fontWeight: "600",
                        marginRight: "20px",
                        width: "100%",
                      }}
                    >
                      Try a Demo
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
                <Col md={12} xs={15} lg={12}>
                  <Dropdown overlay={openlive} style={{ zIndex: "1000" }}>
                    <Button
                      style={{
                        backgroundColor: "#31A629",
                        color: "#fff",
                        border: "0",
                        fontWeight: "600",
                        width: "100%",
                      }}
                    >
                     
                      Open Real Account
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            </Col>

            <Col md={0} xs={5} lg={0}>
              <Link
                onClick={() => setLan("ar")}
                to={arLink}
                style={{ color: "#1b75bc" }}
              >
                <span style={{ padding: "6px" }}>العربية</span>
                <img
                  src={uaeflag}
                  alt="العربية"
                  style={{
                    borderRadius: "2px",
                    width: "25px",
                    verticalAlign: "middle",
                  }}
                />
              </Link>
            </Col>
          </Row>
        </div>

        <style jsx>
          {`
            .ant-row-center {
              justify-content: left;
            }
            .ctaupbutton {
              text-align: right;
            }
            @media only screen and (max-width: 600px) {
              .ctaupbutton {
                text-align: center;
              }
              .marginbottommobile {
                margin-bottom: 7px;
              }
            }
          `}
        </style>

        {/* <Button
              type="primary"
              size="default"
              href="/trading-platforms/mt4-open-forex-demo"
              block
              style={{
                backgroundColor: "#0f75bc",
                color: "#fff",
                border: "0",
                fontWeight: "bold",
              }}
            >
              Open FX Demo
            </Button> */}
      </div>
    </>
  );
};

export default UpHeader;
