import React from "react";
import { Row, Col , Typography, Divider, List} from "antd";
import { Link } from "react-router-dom";
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgpartnershipbanner4.jpg"
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import WhyNoor from "./whyNoor.js";

const style = {padding: '50px 0' };
const notes = [
  'A unique business model with 100% support to commence and expand your business.',
  'Access to our wide range of investment solutions.',
  'Quick and easy to join.',
  'Direct access to the market for Institutional Clients and Algo Traders.',
  'Fastest execution, lowest latency, and best pricing.',
  'Connect to Tier-1 bank liquidity with instant market execution.',
 ];
const { Title } = Typography;

const Institutional_WhiteLabelSolutions = () => {
  return( <div>
            <PageseMetaTags
                title="White Label Solutions - Forex Trading | Noor Capital"
                description="White label solutions offer business the ability to grow their business with new assets including forex and CFDs under their brand."
            />

            <BgBefore 
            pageHeadingTitle="Kickstart your Financial or Brokerage Firm Right Away"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName = "Home"
        pageMainCategoryPath = "/"
        
        pageSubCategoryName = "Partnership"
        pageSubCategoryPath = "#"

        pageName = "White Label Solutions"
        />





<div className={globalstyle.container}>
        <br />
        <br />
        <Row>
        <Title style={{ color: "#1b75bc" }} level={3}>White Label Solutions</Title>
          
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
            With Noor Capital White Label Solutions, you can start your brokerage business easily. We provide unique business opportunities with dedicated teams and resources to support you throughout your journey.
            </p>

            <p className="parjustify">
            Our White Label Solution gives you access to the direct market through a FIX API based on the FIX 4.4 protocol, helping you with Tier-1 bank liquidity, immediate market execution, and low latency pricing.
            </p>

            

          </Col>

        </Row>


        

      <Divider orientation="left"><Title level={3} style={{color: "#1b75bc" }}>Features & Benefits

</Title></Divider>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        
    <List
      size="small"
      bordered
      dataSource={notes}
      renderItem={item => 
      <List.Item><i class="fas fa-angle-double-right" style={{ fontSize: "16px", marginRight: "10px" , color: "#1b75bc" }}></i>{item}</List.Item>
    }
    />
    <br/>
        </Col>
      </Row>

<br/>
      


      
      <style jsx>{
            `
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd){
              background-color: #f1f1f1;
          }
            `
        }
        </style>

      </div>  


      <section className="fdb-block" style={{"backgroundColor":"#f2f4f7"}}>
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
            

              <Title style={{"color":"#11131b"}} level={3}>
              Call us now and let our expert help you with White Label Solution
                </Title>
              <p className="mt-5 mt-sm-4">
                <Link className="btn btn-outline-primary" to="/about/contact-us">Contact Us</Link>
              </p>
            </div>
          </div>
        </div>
      </section>



      <WhyNoor />
  </div>
  )

};

export default Institutional_WhiteLabelSolutions;