import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  Drawer,
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent,
  List,
  ListItem,
  Button,
  SimpleListItem,
  CollapsibleList,
} from "rmwc";
import "@rmwc/icon/styles";
// import { chevronRight } from '@rmwc/icon';
import "rmwc/dist/styles";
import classnames from "classnames";
import menuStyle from "./menuBar.module.css";

function MobileNav() {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Drawer
        modal
        open={open}
        onClose={() => setOpen(false)}
        style={{ position: "fixed", zIndex: "99999999999999", top: 0 }}
      >
        {/* <DrawerHeader>
          <DrawerTitle>DrawerHeader</DrawerTitle>
          <DrawerSubtitle>Subtitle</DrawerSubtitle>
        </DrawerHeader> */}
        <DrawerContent style={{ zIndex: "99999999999999" }}>
          <List style={{ zIndex: "99999999999999", top: 0 }}>
            <CollapsibleList handle={<SimpleListItem text="About Us" />}>
              <Link to="/about/about-us" onClick={() => setOpen(!open)}>
                <SimpleListItem text="Overview" />
              </Link>
              
              <Link to="/about/chairman-message" onClick={() => setOpen(!open)}>
                <SimpleListItem text="Chairman message" />
              </Link>
              <Link to="/about/board-members" onClick={() => setOpen(!open)}>
                <SimpleListItem text="Board Members" />
              </Link>
              <Link to="/about/management-team" onClick={() => setOpen(!open)}>
                <SimpleListItem text="Management Team" />
              </Link>
              <Link
                to="/about/license-regulations"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="Licence ang Regulations" />
              </Link>
              <Link to="/about/why-noor-capital" onClick={() => setOpen(!open)}>
                {" "}
                <SimpleListItem text="Why Noor Capital" />
              </Link>
              <Link to="/terms-conditions" onClick={() => setOpen(!open)}>
                <SimpleListItem text="Terms and conditions" />
              </Link>
              <Link
                to="/trading-tools/risk-disclosure"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="Risk disclosure" />
              </Link>
              <Link to="/about/careers" onClick={() => setOpen(!open)}>
                <SimpleListItem text="Careers" />
              </Link>
              <Link to="/about/contact-us" onClick={() => setOpen(!open)}>
                <SimpleListItem text="Contact Us" />
              </Link>
            </CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="Services" />}>
              <Link
                to="/services/global-financial-advisory"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="Global Financial Advisory" />
              </Link>
              <Link
                to="/services/asset-management"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="Asset Management" />
              </Link>
              <Link to="//services/investment" onClick={() => setOpen(!open)}>
                <SimpleListItem text="Investment" />
              </Link>
              <Link
                to="/services/funds-administration"
                onClick={() => setOpen(!open)}
              >
                <SimpleListItem text="Funds Administration" />
              </Link>
            </CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="Trading" />}>
              <CollapsibleList handle={<SimpleListItem text="Instruments" />}>
                <Link
                  to="/trading/instruments/forex"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Forex" />
                </Link>
                <Link
                  to="/trading/instruments/precious-metals"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Precious Metals" />
                </Link>
                <Link
                  to="/trading/instruments/energies"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Energies" />
                </Link>
                <Link
                  to="/trading/instruments/indices"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Indices" />
                </Link>
                <Link
                  to="/trading/instruments/shares-cfds"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Shares CFDs" />
                </Link>
                <Link
                  to="//trading/instruments/bullion"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Bullion" />
                </Link>
                <Link
                  to="/trading/instruments/cash-equities"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Cash Equities" />
                </Link>
              </CollapsibleList>
              <CollapsibleList handle={<SimpleListItem text="FX Trading" />}>
                <Link
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Open Live Account" />
                </Link>
                <Link
                  href="/trading-platforms/mt4-open-forex-demo"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Open Demo Account" />
                </Link>
                <Link
                  to="/trading-platforms/metatrader4/mt4-mobile"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Metatrader 4 Mobile" />
                </Link>
                <Link
                  to="/trading-platforms/metatrader4/mt4-desktop"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Metatrader 4 Desktop" />
                </Link>
                <Link
                  to="/trading-platforms/metatrader4/mt4-mac"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Metatrader 4 Mac" />
                </Link>
                <Link
                  to="/trading-platforms/metatrader4/mt4-web-trader"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Metatrader 4 Webtrader" />
                </Link>
              </CollapsibleList>
              <CollapsibleList handle={<SimpleListItem text="Cash Equities" />}>

              <Link
                  to="/trading-platforms/metatrader5-platform"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="MetaTrader 5" />
                </Link>
                
                <Link
                  to="/stocks-trading-platforms/open-gtn-live"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Open GTN Live Account" />
                </Link>

                <Link
                  to="/stocks-trading-platforms/gtn-mobile"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="GTN Mobile" />
                </Link>

                <Link
                  to="/stocks-trading-platforms/gtn-web"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="GTN Webtrader" />
                </Link>

              </CollapsibleList>

              {/* NT */}
              {/* <CollapsibleList handle={<SimpleListItem text="Physical" />}>
                <Link
                  to="/physical-trading-platforms/open-noor-trader-live"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Open Live Account" />
                </Link>
                <Link
                  to="/physical-trading-platforms/open-noor-trader-demo"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Open Demo Account" />
                </Link>
                <Link
                  to="/physical-trading-platforms/noor-trader-mobile"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="NoorTrader Mobile" />
                </Link>
                <Link
                  to="/physical-trading-platforms/noor-trader-web"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="NoorTrader Webtrader" />
                </Link>
              </CollapsibleList> */}
              {/* NT END */}

              <CollapsibleList handle={<SimpleListItem text="Institutional" />}>
                <Link
                  to="/institutional/request-demo"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Request Demo" />
                </Link>
                <Link
                  to="/institutional/request-live"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Request Live" />
                </Link>
              </CollapsibleList>
              <CollapsibleList handle={<SimpleListItem text="Accounts" />}>
                <Link
                  to="/trading/accounts/account-funding"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Account Funding" />
                </Link>
                <Link
                  to="/trading/accounts/account-forms"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Account Forms" />
                </Link>
                <Link
                  to="/trading/accounts/trading-conditions"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Trading Conditions" />
                </Link>
              </CollapsibleList>
            </CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="Partnership" />}>
              <CollapsibleList handle={<SimpleListItem text="INSTITUTIONAL" />}>
                <Link
                  to="/partnership/white-label-solutions"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="White Label Solutions" />
                </Link>
                <Link
                  to="/partnership/institutional-clients"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Institutional Clients" />
                </Link>
                <Link
                  to="/partnership/asset-managers"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Asset Managers (MAM/PAMM)" />
                </Link>
              </CollapsibleList>
              <CollapsibleList handle={<SimpleListItem text="Partnership" />}>
                <Link
                  to="/partnership/marketing-partnership-program"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Partnership Program" />
                </Link>
              </CollapsibleList>
            </CollapsibleList>

            <CollapsibleList
              open
              handle={
                <Link
                  to="education/noor-academy"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Noor Academy" />
                </Link>
              }
            ></CollapsibleList>

            <CollapsibleList handle={<SimpleListItem text="Trading Tools" />}>
              <CollapsibleList handle={<SimpleListItem text="NOOR TRENDS" />}>
                <Link
                  to="/trading-tools/noor-trends-online-webinar"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Online Webinar" />
                </Link>
                <Link
                  to="/trading-tools/noor-trends-market-update"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Market Update" />
                </Link>
                <Link
                  to="/trading-tools/noor-trends-technical-analysis"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Technical Analysis" />
                </Link>
                <Link
                  to="/trading-tools/noor-trends-economic-reports"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Economic Report" />
                </Link>
                <Link
                  to="/trading-tools/noor-trends-daily-broadcasting"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Daily Broadcasting" />
                </Link>
              </CollapsibleList>
              <CollapsibleList handle={<SimpleListItem text="TOOLS" />}>
                <Link
                  to="/trading-tools/economic-calendar"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Economic Calendar" />
                </Link>
                <Link
                  to="/trading-tools/trading-signals"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Trading Signals" />
                </Link>
                <Link
                  to="/trading-tools/traders-glossary"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Traders Glossary" />
                </Link>
                <Link
                  to="/trading-tools/video-tutorials"
                  onClick={() => setOpen(!open)}
                >
                  <SimpleListItem text="Video Tutorials" />
                </Link>
                <Link to="/support/faqs" onClick={() => setOpen(!open)}>
                  <SimpleListItem text="FAQ" />
                </Link>
              </CollapsibleList>
            </CollapsibleList>


            <CollapsibleList handle={<SimpleListItem text="Media Center" />}>
            <Link to="/media-center/press-release" onClick={() => setOpen(!open)}>
                  <SimpleListItem text="Press Releases" />
              </Link>
              <Link to="/media-center/activities" onClick={() => setOpen(!open)}>
                  <SimpleListItem text="Activities" />
              </Link>
              <Link to="/media-center/awards" onClick={() => setOpen(!open)}>
              <SimpleListItem text="Awards" />
              </Link>
              
            </CollapsibleList>
 


            <CollapsibleList
              open
              handle={
                <Link to="/blog" onClick={() => setOpen(!open)}>
                  {" "}
                  <SimpleListItem text="Blog" />
                </Link>
              }
            ></CollapsibleList>
          </List>
        </DrawerContent>

 
<div style={{paddingLeft:"10px",paddingRight:"10px"}}>
<span>
  <a href="https://mynoor.noorcap.ae/en/traders/login" target="_blank">
 <Button
  style={{
    backgroundColor: "#0f75bc",
    color: "#fff",
    border: "0",
    fontWeight: "600",
    borderRadius: "0",
    marginRight: "20px",
    marginTop: "10px",
    marginBottom:"10px",
    width: "100%",
  }}
>
MyNoor
</Button>
</a></span>
<br/>

<span>
      <a href="https://agent.noorcap.ae/en/agent/login" target="_blank">
        <Button
          style={{
            backgroundColor: "#0f75bc",
            color: "#fff",
            border: "0",
            fontWeight: "600",
            borderRadius: "0",
            marginRight: "20px",
            marginTop: "10px",
            marginBottom:"10px",        
            width: "100%",
          }}
        >
Agent Area
        </Button>
        </a>
  </span>      
        <br/>
        <span>

<Link to="/trading/accounts/account-funding">

<Button
  style={{
    backgroundColor: "#0f75bc",
    color: "#fff",
    border: "0",
    fontWeight: "600",
    borderRadius: "0",
    marginRight: "20px",
    marginTop: "10px",
    marginBottom:"10px",
    width: "100%",
  }}
>
Fund Account
</Button>
</Link>
</span>
</div> 
<br/>
<br/>
<br/>
      </Drawer>

      <div
        style={{ top:"158px" }}
        className={classnames(menuStyle["burger-menu"])}
        onClick={() => setOpen(!open)}
        raised
      >

        <div
          className={classnames(
            menuStyle["line-menu"],
            menuStyle["line-half"],
            menuStyle["first-line"]
          )}
        />

        <div className={classnames(menuStyle["line-menu"])} />

        <div
          className={classnames(
            menuStyle["line-menu"],
            menuStyle["line-half"],
            menuStyle["last-line"]
          )}
        />
      </div>
      
    </>
  );
}
export default MobileNav;
