import React from "react";
import { Row, Col } from 'antd';
import globalstyle from '../../style.module.css';
import PageseMetaTags from "../metaTags";
import img1 from "../../assets/images/bgothers.jpg"
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";
const style = {padding: '50px 0' };

const SiteMapAr = () => {
  return( <div>
            <PageseMetaTags 
            title="Title"
            description="description"
            />
        <BgBeforeAr 
        pageHeadingbg={img1}
        />
        <BreadcrumbMenuAr />

    <div className={globalstyle.container}>

        <Row>
            <Col span={24} className="gutter-row">

            <div style={style}>

    

            <div>
         </div>

 
      </div>


            </Col>
        </Row>

    </div>

  </div>
  )

};

export default SiteMapAr;