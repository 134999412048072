import React, { useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import headerStyle from "./headerAr.module.css";
import globalStyle from "../../style.module.css";
import { Row, Col, Button } from "antd";
import { Menu, Dropdown, message, Space, Tooltip } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useCookies } from 'react-cookie'
import ukflag from "../../assets/images/uk-flag.svg";


import LanguageContext from "../../language-context";

const opendemo = (
  <Menu>
    <Menu.Item key="1" style={{ textAlign: "right" }}>
      <Link to="/ar/trading-platforms/mt4-open-forex-demo">
        تداول العملات والسلع
      </Link>
    </Menu.Item>

    <Menu.Item key="2" style={{ textAlign: "right" }}>
      <Link to="/ar/stocks-trading-platforms/open-stocks-demo">
      الأسهم العالمية
      </Link>
    </Menu.Item>
    
    {/* <Menu.Item key="2" style={{ textAlign: "right" }}>
      <Link to="/ar/physical-trading-platforms/open-noor-trader-demo">
        التداول المادي
      </Link>
    </Menu.Item> */}
    <Menu.Item key="3" style={{ textAlign: "right" }}>
      <Link to="/ar/institutional/request-demo">مزود السيولة</Link>
    </Menu.Item>
    <Menu.Item key="4" style={{ textAlign: "right" }}>
      <Link to="/ar/about/contact-us">تواصل معنا</Link>
    </Menu.Item>
  </Menu>
);

const openlive = (
  <Menu>
    <Menu.Item key="1" style={{ textAlign: "right" }}>
      <a
        href="https://mynoor.noorcap.ae/en/registration/signup"
        target="_blank"
      >
        تداول العملات والسلع
      </a>
    </Menu.Item>
    <Menu.Item key="2" style={{ textAlign: "right" }}>
      <Link to="/ar/stocks-trading-platforms/open-gtn-live">
        الأسهم العالمية
      </Link>
    </Menu.Item>
    {/* <Menu.Item key="3" style={{ textAlign: "right" }}>
      <Link to="/ar/physical-trading-platforms/open-noor-trader-live">
        التداول المادي
      </Link>
    </Menu.Item> */}
    <Menu.Item key="4" style={{ textAlign: "right" }}>
      <Link to="/ar/institutional/request-live">مزود السيولة</Link>
    </Menu.Item>
    <Menu.Item key="5" style={{ textAlign: "right" }}>
      <Link to="/ar/about/contact-us">تواصل معنا</Link>
    </Menu.Item>
  </Menu>
);

const UpHeaderAr = () => {

  const location = useLocation()

  const languageSegment = location.pathname;
  
    let enLink = languageSegment.replace('/ar/','/');    
    const { language, setLanguage } = useContext(LanguageContext);
    const [cookies, setCookie, removeCookie] = useCookies(["ar"]);
    const setLan = (value) => {
      setCookie("language", value, { path: '/' });
      setLanguage(value);
    };

  return (
    <>

    <div className={headerStyle.topHeaderMenu}>
          <Row gutter={[2, 4]} align="middle">
          <Col md={0} xs={12} lg={0}>
          <Link to="/ar/services/global-financial-advisory">
                  {" "}
                  <i
                    class="fas fa-angle-left"
                    aria-hidden="true"
                    style={{ color: "#1b75bc", paddingRight: "6px" }}
                  ></i>{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#303236",
                      paddingRight: "15px",
                    }}
                  >
                الإستشارات المالية
                  </span>
                </Link>
                </Col>
                <Col md={0} xs={12} lg={0}>
    
                <Link to="/ar/services/asset-management">
                  <i
                    class="fas fa-angle-left"
                    aria-hidden="true"
                    style={{ color: "#303236", paddingRight: "5px" }}
                  ></i>{" "}
                  <span style={{ fontWeight: "bold", color: "#303236" }}>
                  إدارة الأصول
                  </span>
                </Link>
                </Col>
                </Row>
     
    
                <Row gutter={[2, 4]} align="middle">
          <Col md={0} xs={12} lg={0}>
          <Link to="/ar/services/investment">
                  {" "}
                  <i
                    class="fas fa-angle-left"
                    aria-hidden="true"
                    style={{ color: "#1b75bc", paddingRight: "6px" }}
                  ></i>{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#303236",
                      paddingRight: "15px",
                    }}
                  >
                                    الاستثمار المباشر
                  </span>
                </Link>
    
                </Col>
                <Col md={0} xs={12} lg={0}>
    
                <Link to="/ar/services/funds-administration">
                  <i
                    class="fas fa-angle-left"
                    aria-hidden="true"
                    style={{ color: "#303236", paddingRight: "5px" }}
                  ></i>{" "}
                  <span style={{ fontWeight: "bold", color: "#303236" }}>
                  إدارة صناديق الإستثمار
                  </span>
                </Link>
                </Col>
                </Row>
     </div>

    <div className={headerStyle.upHeader}>
      <div className={globalStyle.container}>
        <Row gutter={[2, 4]} align="middle">
          {/* Service 1 */}
          <Col md={4} xs={0} className={headerStyle.upHeaderSpan}>
            <Link to="/ar/services/global-financial-advisory">
              {" "}
              <i
                class="fas fa-angle-left"
                aria-hidden="true"
                style={{ color: "#1b75bc", paddingLeft: "6px" }}
              ></i>{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  paddingRight: "1px",
                }}
              >
                الإستشارات المالية
              </span>
            </Link>

            <Link to="/ar/services/asset-management">
              <i
                class="fas fa-angle-left"
                aria-hidden="true"
                style={{ color: "#1b75bc", paddingRight: "5px" }}
              ></i>{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  paddingRight: "1px",
                }}
              >
                إدارة الأصول
              </span>
            </Link>
          </Col>

          {/* Service 2 */}
          <Col md={4} xs={0} className={headerStyle.upHeaderSpan}>
            <Link to="/ar/services/investment">
              {" "}
              <i
                class="fas fa-angle-left"
                aria-hidden="true"
                style={{ color: "#1b75bc" }}
              ></i>{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  paddingRight: "1px",
                }}
              >
                الاستثمار المباشر
              </span>
            </Link>

            <Link to="/ar/services/funds-administration">
              <i
                class="fas fa-angle-left"
                aria-hidden="true"
                style={{ color: "#1b75bc", paddingRight: "5px" }}
              ></i>{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  paddingRight: "1px",
                }}
              >
                إدارة صناديق الإستثمار
              </span>
            </Link>
          </Col>

          {/* Important Links */}
          <Col
            md={7}
            xs={0}
            className={headerStyle.upHeaderSpan}
            style={{ textAlign: "center" }}
          >
            <span>
              <a
                href="https://mynoor.noorcap.ae/en/traders/login"
                target="_blank"
              >
                <i
                  class="fa fa-user-alt"
                  style={{
                    fontSize: "13px",
                    color: "#1a86da",
                    paddingLeft: "5px",
                  }}
                ></i>
                <span style={{ fontSize: "12px", color: "#1a86da" }}>
                  منطقة العملاء
                </span>
              </a>
            </span>
            <span>
              <a href="https://agent.noorcap.ae/en/agent/login" target="_blank">
                <i
                  class="fa fa-user-cog"
                  style={{
                    fontSize: "13px",
                    color: "#1a86da",
                    paddingLeft: "5px",
                  }}
                ></i>
                <span style={{ fontSize: "12px", color: "#1a86da" }}>
                  منطقة الوسطاء
                </span>
              </a>
            </span>

            <span>
              <Link to="/ar/trading/accounts/account-funding">
                <i
                  class="fa fa-credit-card"
                  style={{
                    fontSize: "13px",
                    color: "#1a86da",
                    paddingLeft: "5px",
                  }}
                ></i>
                <span style={{ fontSize: "12px", color: "#1a86da" }}>
                  الإيداع والسحب
                </span>
              </Link>
            </span>
          </Col>

          {/* Open Live */}
          <Col md={0} xs={6} lg={0} className="ctaupbutton">      
          <Link onClick={() => setLan("en")} to={enLink} style={{ color: "#1b75bc" }}>
                          
                          <span style={{ padding: "6px" }}>English</span>
                          <img
                            src={ukflag}
                            alt="EN"
                            style={{
                              borderRadius: "2px",
                              width: "25px",
                              verticalAlign: "middle",
                            }}
                          />
                          </Link>
          </Col>
          <Col md={9} xs={18} lg={7} className="ctaupbutton">
          <Row gutter={[2, 4]} align="middle"> 
          <Col md={12} xs={12} lg={12} className="ctaupbutton">
            <Dropdown overlay={openlive} style={{ zIndex: "1000" }}>
              <Button
                style={{
                  backgroundColor: "#31A629",
                  color: "#fff",
                  border: "0",
                  fontWeight: "600",
                  marginRight: "20px",
                  float: "left",
                  width: "100%",
                  fontSize: "12.5px"
                }}
              >
                افـتح حـساب حـقيقي
                <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col md={12} xs={12} lg={12} className="ctaupbutton">

            <Dropdown overlay={opendemo} style={{ zIndex: "1000" }}>
              <Button
                style={{
                  backgroundColor: "#0f75bc",
                  color: "#fff",
                  border: "0",
                  fontWeight: "600",
                  marginRight: "20px",
                  float: "left",
                  width: "100%",
                  fontSize: "12.5px"
                }}
              >
                جرّب حساب تجريبي
                <DownOutlined />
              </Button>
            </Dropdown>
            </Col>
            </Row>
            </Col>
        </Row>
      </div>

      <style jsx>
        {`
          .ant-row-center {
            justify-content: left;
          }
          .ctaupbutton {
            text-align: right;
          }
          @media only screen and (max-width: 600px) {
            .ctaupbutton {
              text-align: center;
            }
            .marginbottommobile {
              margin-bottom: 7px;
            }
          }
        `}
      </style>
    </div>
    </>
  );
};

export default UpHeaderAr;
