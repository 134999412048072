import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import investmentimg from "../../assets/images/pages/services/investment.jpg";
import img1 from "../../assets/images/bgtools.jpg";
import { Collapse } from "antd";

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}
const style = { padding: "50px 0", alignSelf: "justify" };

const { Title } = Typography;




const Faqs = () => {
  return( <div>
            <PageseMetaTags
                title="FAQs - Noor Capital"
                description="Noor Capital Frequently Asked Questions"
            />

            <BgBefore 
            pageHeadingTitle="Frequently Asked Questions"
            pageHeadingDescription=""
            pageHeadingbg={img1}
            />
        <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Support"
        pageSubCategoryPath="#"
        pageName="FAQs"
      />




<div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <p>
          Noor Capital is a worldwide accredited brokerage. With state-of-art and robust platforms, we provide seamless trading facilities for our individual and institutional clients. Founded in 2005, Noor Capital is regulated by the Securities and Commodities Authority (SCA). We have global presence with  highly experienced and certified team to support our clients' investments.
          </p>
        </Row>
        <Row>
          <Title style={{ color: "#1b75bc" }} level={4}>
          Here Are Some Of The Frequently Asked Questions:
          </Title>
        </Row>

        <Collapse defaultActiveKey={["1"]} onChange={callback}>
          <Panel header="Q:  How do I open an account?" key="1">
          <p><b>A: </b>Click <a href='https://mynoor.noorcap.ae/en/registration/signup'>here</a> or download the account opening form by clicking here.</p>
          </Panel>

          <Panel header="Q: What do I need to open an account?" key="2">
          <p> <b>A: </b>Copy of valid Passport BioData page 
          UAE ID or Resident ID [UAE Residents only]
          Proof of Address - Copy of official Bank Statement, signed and duly stamped by the Bank, or a recent Utility Bill</p>
          </Panel>

          <Panel header="Q: What payment methods are accepted?" key="3">
          <p> <b>A: </b>We accept Bank Transfers, Credit Card payments, e-wallet payments made in USD or AED. All details are available in your Private Cabinet once you register to open a live account. You may also contact your Noor assigned Broker to provide all necessary payment details.
          </p>
          </Panel>

          <Panel header="Q: Are my funds safe?" key="4">
          <p> <b>A: </b>Noor Capital has a strong capitalization with a paid-up capital of over US$200 million. The company  is an independent, private joint-stock regulated by the Securities and Commodities Authority (SCA). Based on our regulatory requirements, all our client funds are kept in  a separate bank accounts from our corporate accounts. 
          
          </p>
          </Panel>

          <Panel header="Q: How can I withdraw my funds?" key="5">
          <p> <b>A: </b>Based on the International Anti-Money Laundering (AML) requirements, all funds have to be returned to the source from which they were deposited. Any withdrawals can be made through your Private Cabinet. Click <a href='http://mynoor.noorcap.ae/en/traders/login'>here</a> to log in to your Private Cabinet.</p>
          <p>Noor Capital Private Cabinet is a professional all-in-one solution for individual traders offering many features in real-time. The Private Cabinet allows you to manage your Live and Demo  Account in simple steps. Once you access your Private Cabinet, you will have a detailed overview of your account activities with various trading reports, access to education, and bonus programs.

          </p>
          <p>Noor Capital Private Cabinet enables you  open and manage your account(s), pull account reports,  deposit and withdraw funds, make internal transfers, and many other  things.

          </p>
          <p>Account statements will be sent automatically to your email on daily basis as long as you have trading activities and open positions. Otherwise, you will receive account statements by email on monthly basis.

          </p>
          </Panel>

          <Panel header="Q: What is Forex?" key="6">
          <p><b>A: </b>Foreign exchange, more commonly known as Forex or FX, is a market where investors are making  profit from the differences in the value of traded major currencies . As the biggest market in the world by far, larger than  any other market, there is high liquidity inForex market. This market attracts many traders, both beginners and professional traders.
          </p>
          <p>The market in which currencies are traded is called foreign exchange, "FOREX", or "F.X.." It is the largest financial market in the world. Compared to the $22.4 billion daily trading volume of the New York Stock Exchange, foreign exchange market looks enormous with its $5 TRILLION daily trading volume. That huge $5 trillion volume  covers the entire global foreign exchange market, but retail traders work in  spot market whose volume is  about $1.49 trillion.
                      </p>
          <p>There is no central marketplace for currency exchange; trading  is conducted over the counter.  Forex market is open 24/5 a week, and currencies are traded worldwide among the major financial centers of London, New York, Tokyo, Zurich, Frankfurt, Hong Kong, Singapore, Paris, and Sydney.
                     </p>
                     <p>All forex trades are involved in simultaneous buying of one currency and selling another, but the currency pair itself considered   as a single trading unit, an instrument that is bought or sold. If you buy a currency pair, you buy the base currency and sell the quote currency. For instance, in EUR/USD, EUR is the base currency, and USD is the quote currency.</p>
          </Panel>

          <Panel header="Q: What are CFDs?" key="7">
          <p><b>A: </b>CFD or Contract for Difference is an agreement between two parties to exchange the difference between  opening price and  closing price of a contract.
          </p>
          <p>This type of financial instrument allows you to trade an underlying index, share or commodity contract without actually having to own the underlying instrument (shares, indices, single stock, or commodity) on which your contract is based.
                      </p>
                      <p>CFDs allow you to trade  market prices' future movement regardless of whether the underlying markets are rising or falling. They allow traders have access to markets which they would not usually be able to invest in.
                      </p>
          </Panel>

          <Panel header="Q: What are Margin, Long and Short Positions?" key="8">
          <p><b>A: </b>Margin is the collateral for a position. Your market maker broker will request additional funds by way of a "margin call" if market moves against your position. It will immediately close out your open positions if there are insufficient funds in your account.</p>
          <p><b>A: </b>Long position is one in which you buy an instrument at one price, expecting to sell it later on at a higher price based on your view that market will rise.</p>
          <p><b>A: </b>Short position is one in which you sell with the expectation of repurchasing it at a lower price. Here, you expect the market to fall.</p>
          </Panel>

          <Panel header="Q: What is Bid/ Ask?" key="9">
          <p><b>A: </b>Bid (Buy Price) represents how much of the quote currency is needed  to get one unit of the base currency. Conversely, when you sell the currency pair, you sell the base currency and receive the quote currency. Bid Price is the highest price that the seller is offering for a particular currency at the time of selling.</p>
          <p><b>A: </b>The Ask (Sell Price) for the currency pair represents how much you will get of the quote currency for selling one unit of the base currency. The Ask Price is the lowest price acceptable to the buyer.</p>
          </Panel>

          <Panel header="Q: What is Spread/ PIP?" key="10">
          <p><b>A: </b>Spread is the difference between  BUY  SELL prices of two instruments. For example, if the EURUSD is trading at 1.1167 (Buy) and 1.1166 (Sell), then the spread is 1 PIP.</p>
          <p>In financial markets, and especially in the FX (Forex) markets, a PIP (Percentage In Point) is a unit of change in a currency pair exchange rate. Most major currency pairs are priced to four decimal places, and a PIP is one unit of the fourth decimal point: for dollar currencies, this is to 1/ 100th of a Cent.</p>
          </Panel>
          
          <Panel header="Q: How Can I Manage Risk" key="11">
          <p><b>A: </b>The most common risk management tools in Forex trading are stop-loss and limit orders.  Stop-loss order directs that a position be automatically liquidated at a specific price to hedge  against dramatic changes against the position.  Limit order sets the maximum price which the investor is willing to pay in a transaction and a minimum price to be received in exchange.</p>
          <p>You can place Limit and Stop Loss Orders at any time free of charge.</p>
          </Panel>

          <Panel header="Q: How Often Should I Trade?" key="12">
          <p><b>A: </b>Market conditions will dictate your trading activity on any given day. The average small-to-medium trader could conceivably trade up to 10 times a day. However, because there are no commissions when you trade CFDs, you can take long or short positions as often as you like without worrying about high transaction costs.</p>
          </Panel>

          <Panel header="Q: How long should I maintain my positions?" key="13">
          <p><b>A: </b>In general terms, you will keep your position on until,
          <ol>
          <li>you realize sufficient profit from your position; </li>
          <li>your stop-loss is triggered; or, </li>
          <li>another position with tremendous potential comes up, and you need to free up funds from another trade to take advantage of it.</li>
          </ol>
          </p>
          </Panel>

          <Panel header="Q: How can I overcome my fears?" key="14">
          <p><b>A: </b>There is no better way for you to get practical experience in this market than  opening a Demo Account with a market maker broker that we would recommend to you. This way, you will have the  feeling of real trading  in a risk-free trading environment that will keep your capital safe.</p>
          </Panel>

          <Panel header="Q: Why trade currencies?" key="15">
          <p><b>A: </b>Forex allows investors to buy and sell currency pairs and profit from the movement in the exchange rate. However, the exchange rate movements can work against you just like realizing your favor. You may lose your initial investment partially or entirely, and therefore Forex is not suitable for all investors.</p>
          <p>Noor Capital offers tight spreads with leverage up to 400:1. Markets are open 24/5 from Monday to Friday. The volatile markets present many trading opportunities in both bull and bear markets. The FX market is the largest and most liquid market globally, and many traders choose Forex because of the high return potentials in a short period of time.</p>
          </Panel>

          <Panel header="Q: What are the most traded currency pairs?" key="16">
          <p><b>A: </b>EURUSD is the most traded currency pair in the world. It represents the value of USD per One Euro. The Euro is a relatively new currency. It was established by the provisions in the 1992 Maastricht Treaty and is managed by the European Central Bank (ECB) and the Euro-System (comprised of the Central Banks of the Euro-Zone).</p>
          <p>The larger the GDP of a country in the Euro-Zone, the more significant their impact it has on the Euro. In this regard, Germany’s economic data and policy decisions (which has the highest GDP in the Euro-Zone) can significantly impact EUR. Smaller countries can also affect the Euro, especially in times of crisis that threaten the region's economic stability.</p>
          <p>Other data, including GDP Growth, Employment, Inflation, and Trade Balance data are essential. USD is mainly influenced by Labor Market data, GDP and Inflation data, interest rates, Fed's Quantitative Easing.The notion that the U.S. Dollar may be a safe haven also influences investors' decisions when trading USD.</p>
          <p><b>GBPUSD</b> (nicknamed 'Cable') represents the amount of USD purchased with one British Pound. GBP was pegged to the U.S. Dollar in 1940 and became part of the Bretton Woods system, which governed post-war exchange rates, and with the collapse of the system, the Pound became free-floating in 1971. Historically, the Pound and the U.S. dollar have been the primary tool by which many other nations have valued their currencies. Domestically, GBP is affected by economic indicators that provide an insight into the health of the UK Economy, including interest rates and quantitative easing facilities determined by Bank of England, GDP Growth, inflation, and labor market data. The Pound can be affected by the prices of base metals, oil, and other commodities.</p> 
          <p><b>USDJPY</b> represents the number of Japanese Yens that can be purchased with one U.S. Dollar. At the time of Breton Woods System, the Yen was fixed to the U.S. Dollar at JPY 360 per USD 1, but the exchange only lasted until the U.S. abandoned the Gold Standard in 1971. Since then, Yen has appreciated significantly against the U.S. Dollar.</p> 
          <p>Yen is the third most traded currency globally, behind the U.S. Dollar and the Euro. Japanese currency is also sometimes thought of as a safe haven trade, and the currency is sometimes hit with repatriation flows during times of economic crisis. Domestic data also impacts the price of JPY, especially trade balance, inflation, employment and GDP data.</p>
          <p><b>AUD</b>'s (nicknamed 'Aussie')  popularity has grown over the past few years since it tends to have  higher yield than many other currencies in the developed markets. Thus, it makes it attractive for traders seeking profits. Additionally, it also tends to attract attention because of its strong links to commodities, as Australia is a prominent commodity exporter, and consequently their growing trade relations with Asia. As such, it is also known as the significant "commodity currency."

</p>
          <p><b>AUD</b> can be influenced by several macroeconomic factors, such as the Reserve Bank of Australia (RBA) raising or lowering interest rates, GDP, employment figures, trade balance and inflation data. Comments from Australia's Central Bank officials may also have a significant impact on the Aussie. The AUD/USD also tends to have a higher 'beta.' Thus, it is more sensitive to rising or falling equity and commodity prices. Consequently, traders will want to keep a close eye on the S&P500 in the U.S. and the prices of Gold and Copper as they have a high positive correlation with AUDUSD.</p>
          </Panel>

          <Panel header="Q: What are commodities?" key="17">
          <p><b>A: </b>In commodities financial market, futures of basic commodities, including gold, oil, copper, coffee and cocoa are traded.</p>
          <p>On the other hand, in commodities market, buying and selling the goods takes place. </p>
          <p>Commodities are usually defined as goods of the primary economic sector rather than manufactured products. Soft commodities are agricultural products such as wheat, coffee, cocoa, and sugar, and hard commodities  such as gold, silver, and oil. Commodity trading includes derivatives trading such as CFD, using spot prices, which provide clearing and settlement services off-exchange in the over-the-counter (OTC) market.</p>
          </Panel>

          <Panel header="Q: What are the most traded commodities?" key="18">
          <p><b>A: </b>Gold, silver and crude oil are the most popular commodities for CFD trading. Their prices are influenced by numerous factors, such as the negative correlation between Gold and Oil or the valuation of the USD. However, the most significant influence is simple supply and demand correlation. Further,  production levels, seasonal changes, or storage capacity may influence the price of goods, thus, the price at which they are offered in the market.</p>
          </Panel>

          <Panel header="Q: What is Oil?" key="19">
          <p><b>A: </b>Crude Oil is a naturally occurring petroleum product commonly used in energy production and manufacturing. It is typically purchased to be refined into everyday use such as diesel, gasoline, heating oil, jet fuel, plastics, cosmetics, medicines, fertilizers, etc. As such, its price has a dramatic impact on the global economy. Higher oil prices tend to undermine economic growth as this increases travel and shipping cost, which increase inflationary pressures, and thus, personal consumption typically wanes.</p>
          <p>There are two  major types for crude oil are US Oil, commonly referred to as West Texas Intermediate (WTI), and UK. oil referred to as Brent. Both types are characterized bybeing "light" and "sweet" crude oil, meaning they have a low density (making it easier to refine and transport), with lower sulfur content (which results in fewer impurities, making it cheaper to refine). Therefore, they tend to be more expensive than their "heavy" or "sour" counterparts as they are closer to the desired final products referred to  above.
          </p>
          <p>Oil prices are significantly influenced by the balance between supply  and demand since it is so heavily consumed on daily basis. Production, supply, demand, and oil inventories boil down to two significant consortiums: the Organization of Petroleum Exporting Countries (OPEC) and the Organization of Economic Cooperation and Development (OECD) – OPEC is the group responsible for producing around 40% of the world's Oil. At the same time, OECD is accountable for just over 50% of the world's demand for Oil.
          </p>
          <p>If production levels surpass  consumption demand, then inventories are said to "build up" whereby the excess supply can be stored and vice versa. Traders often gauge the level of consumer demand by looking at the relative strength or weakness in global economies via monitoring GDP, retail sales, consumer spending, etc., and then seeing how this stacks up to projected inventories. Sentiment in the financial markets also tends to play a significant role in the price of Oil.
          </p>
          </Panel>

          <Panel header="Q: What Is Gold?" key="20">
          <p><b>A: </b>Gold popularity has grown over the past few years as an alternative of currency trading. Many traders look at  the precious metal as a hedge against inflation and good storage of value – thus, it is often referred to as a "safe haven" asset. Historically, gold prices tend to move inversely with  US D. However, with rising geopolitical uncertainty over the past 5-years, this correlation is no longer as evident.</p>
          <p>Gold is affected by the global economy's overall health – this is measured by GDP Growth, inflation, employment data and interest rates. Additionally, the monetary policy of major  Central Banks all around the  world, and whether they are tightening or expanding their policies, are also considered as major mover of the precious metal price action.. Supply / Demand dynamics, as well as the financial market sentiment, are among other factors investors should take into account when trading the yellow metal.</p>
          </Panel>

          <Panel header="Q: What is Silver?" key="21">
          <p><b>A: </b>Silver is another precious metal. As such, it attracts interest as a "safe-haven" investment. However, since silver tends to benefit from more robust physical, industrial, and monetary demand than Gold, it will usually perform closer to the movements of other "high beta" assets (for instance, Equities) than Gold.</p>
          <p>Thus, silver will usually outperform Gold when the overall economic outlook is bullish, but it is also likely to suffer more significant setbacks when  markets turn bearish. As such, the relationship between silver and gold can be a proxy for 'risk' overall, expressed by the Gold/Silver ratio –  lower ratio promotes risk appetite, while   higher ratio suggests risk aversion.
          </p>
          <p>The price of silver is driven by many factors affecting gold as well such as  inflation readings and inflation Expectations, global GDP growth, and interest rates as well as monetary policies of the largest central banks in the world – U.S. Federal Reserve, European Central Bank, Bank of England, Bank of Japan and the People's Bank of China. To a certain extent, supply / demand balance  tend to play a more significant role in silver price action fluctuations than in gold due to its comparative lack of market liquidity.
          </p>
          </Panel>

          <Panel header="Q: What are indices?" key="22">
          <p><b>A: </b>In  financial markets world, an index is an imaginary portfolio of securities representing a particular market or a portion of it. Each index has its calculation methodology and is usually expressed in terms of a change from a base value. Thus, the percentage change is more important than the actual numeric value.
          </p>
          <p>Stock and Bond Market Indexes are used to construct Index Mutual Funds and Exchange-Traded Funds (ETFs) whose portfolios mirror the Index’s components.</p>
          </Panel>

          <Panel header="Q: Why trade indices?" key="23">
          <p><b>A: </b>At Noor Capital, you can trade major global indices with tight spreads, 100:0 leverage (or 1% margin), and quality executions. Trading financial Indices will give you instant exposure to global economies and industries, easy diversification across sectors and countries, and you can make profits from the rising and falling markets.</p>
          </Panel>

          <Panel header="Q: What are the most traded indices?" key="24">
          <p><b>A: </b><b>Dow Jones Industrial Average</b> is the most widely recognized among stock market indices in the world. Historically, the Index was created to track the movements of the largest industrial companies in USA. However, today, it is comprised of thirty companies from all different sectors. The Index often faces criticism because its price-weighted average, which means a higher-priced stock will have a more significant reflection  over the index than those with a lower price and thus does not consider percentage change. Additionally, many feel its inclusion of merely 30 stocks is not an accurate representation of the overall U.S. market performance.
          </p>
          <p><b>Dow Jones Industrial Average</b> is influenced by major economic data, such as unemployment or inflation readings, geopolitical events, and the Federal Open Market Committee (FOMC), or more commonly referred to as the Fed. Since the United States is the largest global economy and the USD is the reserve currency of the world, the decisions of the Fed to alter their monetary policy wields a tremendous influence on the markets in general but tends to have a more pronounced impact on the US equity market in general.</p>
          <p>Uncertainty in financial markets also tends to have significant impact on investors' sentiment and their willingness to invest in the equity market. Some of the biggest factors influencing sentiment are rapidly changing energy prices, war/terrorism, and political unrest or gridlock.</p>
          <p><b>NASDAQ</b>is one of New York Stock Exchange major  indexs . Along with the D.J. 30 and S&P 500, it is one of the three most-followed indices in US stock markets. The composition of NASDAQ is heavily weighted towards information technology sector.</p>
          <p>NASDAQ is a market-capitalization-weighted index of the more than 3,000 common equities listed on the NASDAQ stock exchange. The Index types in the Index include American depositary receipts, common stocks, real estate investment trusts (REITs), and tracking stocks. The index consists of all NASDAQ listed stocks that are not derivatives, preferred shares, funds, exchange-traded funds (ETFs), or debentures. Unlike other market indices, the NASDAQ composite is not limited to companies that have US headquarters. It is prevalent to hear the Nasdaq Composite Index's closing price reported in the financial press or as part of the evening news.</p>
          <p><b>The Standard & Poor's 500 or S&P 500</b> is an index consists of 500 stocks chosen for market size, liquidity, and industry grouping, among other factors. S&P 500 is designed to be a leading indicator of US equities and reflects  risk/return characteristics of the large-cap universe. Companies included in the Index are selected by the S&P Index Committee, a team of analysts and economists at Standard & Poors. It is a market value-weighted index – each stock's weight is proportionate to its market value.
The S&P 500 is one of the most commonly used benchmarks for the overall US stock market. The Dow Jones Industrial Average (DJIA) was the most renowned index for U.S. stocks. Still, because the DJIA contains only 30 companies, most people agree that the S&P 500 is a better representation of the U.S. Market. Many consider it to be the definition of the market.
</p>
<p>Several financial products based on the S&P 500 are available to investors. These include index funds and exchange-traded funds. However, it would be difficult for individual investors to buy this index, entailing buying 500 different stocks.</p>
        <p><b>EURO STOXX 50 </b>is a stock index of Eurozone equities designed by STOXX, an index provider owned by Deutsche Börse Group and SIX Group. According to STOXX, its goal is to provide a blue-chip representation of sector leaders in the region. It is made up of fifty of the largest and most liquid stocks. The index futures and options on the EURO50, traded on Eurex, are among the most liquid products in Europe and the World.</p>  
        <p>The <b>EURO50</b> was introduced on February 26, 1998. Its composition is reviewed annually in September. The  index is derived from the 19 EURO STOXX regional sector indices and represents the biggest super sector leaders in the Eurozone in terms of free-float market capitalization. Itcaptures about 60% of the free-float market capitalization of the EURO STOXX Total Market Index (TMI), which in turn covers about 95% of the free-float market capitalization of the represented countries.</p> 
        <p>This index serves as an underlay for many strategy indices, such as the EURO STOXX 50 Risk Control Indices. Buffers are used to achieve the fixed number of components and maintain the indices' stability by reducing index composition changes. Selection methodology ensures a stable and up-to-date index composition. Fast-entry and exit rules ensure that the Index accurately represents the performance of only the biggest and most liquid stocks.</p> 
        <p><b>DAX 30 </b>consists of the biggest companies whose stocks are traded in the Frankfurt Stock Exchange. The index is currently dominated by financial (inc. insurance), automotive, healthcare, and chemical sectors. Its sheer size decrees that it is a very dominant index in Europe, i.e., it can influence its counterparts in Europe.</p> 
        <p>Germany is an integral part of the Euro-zone. It has the largest economy in Europe, but its economy is export-based, which makes the index susceptible to sentiment surrounding its trading partners. Also, the dominance of automotive, healthcare and chemical sectors means that they should be watched closely as they may have the ability to lead the overall market. Furthermore, government policy can drive investor sentiment.</p> 
        <p>The <b>UK FTSE100</b> is closely linked to economies all around  Europe through trade and geographical proximity. Thus it can be influenced by investor sentiment surrounding large equity markets in Eurozone . Furthermore, during times of global crisis, the economy can sometimes ignore domestic fundamentals in favor of overall investor sentiment, with the possible exception being the Bank of England's interest rate decisions and policy announcements. More specifically, the index is susceptible to the banking markets' sentiment due to the high weighting banking stocks have on the Index.</p>   
        <p>The <b>NIKKEI 225 </b>or more commonly called the Nikkei, is a stock market index for the Tokyo Stock Exchange. It has been calculated daily by the Nihon Keizai Shimbun (Nikkei) newspaper since 1950.</p>  
         <p>It is a price-weighted index, and the components are reviewed once a year. Currently, the Nikkei is the most widely quoted average of Japanese equities, similar to the D.J. (Dow Jones).</p> 
          
          
          
          </Panel>


        </Collapse>

        <br />
        <br />

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
            .ant-collapse-header{
              font-size: 18px;
              padding: 18px 16px !important;
              color: #1b75bc !important;
            }
          `}
        </style>
      </div>

  </div>
  )

};

export default Faqs;