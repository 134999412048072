import React from "react";
import { Row, Col, Typography } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";
import img1 from "../../assets/images/bgmt4.jpg";
import mt4desktop from "../../assets/images/pages/trading/mt4desktop.png";
import WhyNoor from "./whyNoor";
import OpenFxDemoForm from "../forms/openFxDemoForm.js";
import Mt4Video from "./mt4video.js";

const { Title } = Typography;

const Metatrader4_OpenDemoAccount = (props) => {
  const { location } = props;
  return (
    <div>
      <PageseMetaTags
        title="Open Demo Account - Forex Trading Demo Account | Noor Capital"
        description="The Noor Demo Account allows you to review and test the features of our Noor Trading Platform before funding your account with real money or placing trades."
      />
      <BgBefore
        pageHeadingTitle="Test before You Trade With Noor Capital's MetaTrader 4 (MT4)"
        pageHeadingDescription="Register Now For The Demo Account And Unlock The World Of Endless Opportunities"
        pageHeadingbg={img1}
      />

      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="Trading Platforms"
        pageSubCategoryPath="#"
        pageName="Open Demo Account"
      />

      <div className={globalstyle.container}>
        <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Title style={{ color: "#1b75bc" }} level={3}>
            Sign-Up For FX Demo Account
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              textAlign: "justify",
              padding: "0px 30px 0px 0px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              Whether you are a newbie or a professional trader with years of
              experience, you need to fully understand the platform, market
              trends, and strategies before you trade live.
            </p>
            <p className="parjustify">
              Noor Capital invites you to test before you trade by registering a
              free demo account of Noor Capital's MetaTrader 4 (MT4). Our
              user-friendly demo platform is as good as live trading, except
              that you will trade with virtual money.{" "}
            </p>

            <OpenFxDemoForm location={location} />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Mt4Video />
          </Col>
        </Row>
      </div>
      <br />

      <div style={{ backgroundColor: "#f2f4f7" }}>
        <section className="fdb-block">
          <div className="container">
            <div class="row text-center">
              <div class="col-12">
                <Title style={{ color: "#1b75bc" }} level={3}>
                  Escalate your trading knowledge and test before you trade live
                </Title>

                <br />
              </div>
            </div>

            <div
              className="row text-lg-left align-items-center"
              style={{ fontSize: "14.5px" }}
            >
              <div className="col-12 col-sm-6 col-lg-3">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Instant access to real-time market with our demo account
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Access your account on any web browser from anywhere in the
                  world
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Learn trading like a professional with real-time data
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Advance indicators and charts to help with trading strategies.
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Experience the most secure and user-friendly trading platform
                  around the world
                </p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-left pt-3 pt-sm-0 order-lg-12">
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Demo Account is Risk-Free trading
                </p>
                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Trade on the go with Noor Capital's MetaTrader 4 (MT4) mobile
                  app.
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get Noor Capital's MetaTrader 4 (MT4) EA (Expert Advisors)
                </p>
                <br />

                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  24/5 dedicated customer service available to help you with any
                  concerns or advice
                </p>

                <br />
                <p>
                  <i
                    className="fas fa-check"
                    style={{ paddingRight: "10px", color: "#1b75bc" }}
                  ></i>
                  Get trading signals from top providers
                </p>
              </div>

              <div className="col-7 col-sm-6 col-lg-5 m-auto pt-5 pt-lg-0 order-lg-1">
                <img alt="mt4desktop" className="img-fluid" src={mt4desktop} />
              </div>
            </div>
            <div
              style={{
                paddingTop: "35px",
                textAlign: "center",
                fontSize: "14.5px",
              }}
            >
              <p>
                Using a demo account, you get instant, risk-free, and live
                access to the real-time market, where you can practice trading
                operations, get the latest updates, learn from experts, and use
                all the features that will take you one step towards becoming a
                professional trader. With our demo account, you will get a live
                experience of the real-time market without getting into the risk
                of loss.
              </p>
            </div>
          </div>
        </section>
      </div>

      <br />

      <WhyNoor />

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                If You Are Serious About Becoming The Leader In Forex Trading,
                Your First Journey Starts Here
              </Title>

              <p className="mt-5 mt-sm-4">
                <a
                  className="btn btn-outline-primary"
                  href="https://mynoor.noorcap.ae/en/registration/signup"
                  target="_blank" rel="noreferrer"
                >
                  Register Live Account
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Metatrader4_OpenDemoAccount;
