import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';
import ReactCodeInput from 'react-verification-code-input';
import {
  BASE_URL
} from '../../constants';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Accept-Language': 'en'
};

function EmailVerification({ isOpen, onClose, email, verifyLink, resendLink }) {
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [hideResend, setHideResend] = useState(false);
  const [error, setError] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  const [incorrectCodes, setIncorrectCodes] = useState([]);

  const handleVerificationSubmit = (code) => {
    setLoading(true);
    setHideResend(true)
    if (incorrectCodes.includes(code)) {
      setError('Incorrect verification code.');
      return;
    }
    axios
      .post(
        BASE_URL + verifyLink,
        { email: email, code: code },
        { headers: headers, withCredentials: true }
      )
      .then((response) => {
        setLoading(false);
        setHideResend(false)
        if (response.status === 200) {
          switch (response.data.detail) {
            case 'Verification successful.':
              onClose(true);
              break;
            case 'Incorrect verification code.':
              setIncorrectCodes([...incorrectCodes, code]);
              setError(response.data.detail);
              break;
            case 'Email not found.':
              break;
            default:
              break;
          }
        }
      });
  };

  const handleResendCode = () => {
    if (!resendDisabled) {
      setResendLoading(true);
      axios
        .post(
          BASE_URL + resendLink,
          { email: email },
          { headers: headers, withCredentials: true }
        )
        .then((response) => {
          setResendLoading(false);
          if (response.status === 200) {
            if(response.data.detail === "Verification code sent.")
            {
              setIncorrectCodes([]);
              setResendDisabled(true);
              setTimeout(() => {
                setResendDisabled(false);
              }, 30000); // 60000 milliseconds = 1 minute
            }
            else{
              setError(response.data.detail);
            }
          }
        });      
    }
  };

  useEffect(() => {
    // Clean up the timer when the component is unmounted
    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <Dialog
      preventOutsideDismiss
      open={isOpen}
      onClose={() => {
        onClose(false);
      }}
    >
      <DialogTitle>Email Verification</DialogTitle>
      <DialogContent>
        <p>
          Please check your email {email} and enter the verification code.
        </p>
        <ReactCodeInput
          fields={5}
          onChange={(code) => {
            if (code.length < 5 && error)
              setError("")
          }}
          onComplete={(code) => {
            handleVerificationSubmit(code);
          }}
          loading={loading}
          error={error ? true : false}
        />
        {error && <p style={{ color: 'red', fontStyle: 'italic' }}>{error}</p>}
      </DialogContent>
      <DialogActions className="dialog-actions-container">
        <div className="col-8">
          {!hideResend && !resendLoading && (<button type="button" onClick={handleResendCode} disabled={resendDisabled} className="btn btn-link btn-sm">{resendDisabled?"New Code is sent!":"Resend Code"}</button>)}
          {!hideResend &&resendLoading && (
            <p className="loading-message">Sending code...</p>
          )}
        </div>
        <div className="col-4">
          <DialogButton action="close">Close</DialogButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EmailVerification;
