import React from "react";
import { Row, Col } from "antd";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import BgBefore from "../breadcrumbs/bgBefore";
import img1 from "../../assets/images/bgaboutbanner1.jpg";
import BreadcrumbMenu from "../breadcrumbs/breadcrumbs";

const style = { padding: "50px 0", alignSelf: "justify" };

const LicenseAndRegulations = () => {
  return (
    <div>
      <PageseMetaTags
        title="Forex Trading License and Regulations | Noor Capital"
        description="As a regulated broker, Noor Capital secures clients' funds in segregated accounts with tier 1 investment grade banking institutions."
      />
      <BgBefore
        pageHeadingTitle="License and Regulations"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />
      <BreadcrumbMenu
        pageMainCategoryName="Home"
        pageMainCategoryPath="/"
        pageSubCategoryName="About"
        pageSubCategoryPath="#"
        pageName="License and Regulations"
      />

      <div className={globalstyle.container}>
        <section className="fdb-block">
          {/* 111111111111111111 */}
          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>License & Regulations</h4>
              <p className={globalstyle.parjustify}>
              Noor Capital P.S.C. is regulated by the Department of Economic Development Abu Dhabi, and the Securities and Commodities Authority (SCA).
              </p>
            </div>
          </div>
          <br />

          {/* 1111111111111111111 */}

         
          <br /> 
          
          
          {/* 1111111111111111111 */}

          <div className="row">
            <div className="col text-left">
              <h4 style={{ color: "#1b75bc" }}>
                Securities & Commodities Authority
              </h4>
              <p className={globalstyle.parjustify}>
              Noor Capital PSC is authorised and regulated by the Securities and Commodities Authority (SCA) in the United Arab Emirates, we have an active and functioning license to practice the following activities:
              </p>
              <p className={globalstyle.parjustify}>
              <ol>
                <li>- Trading broker of OTC derivatives and currencies in the spot market</li>
                <li>- Trading broker in the international markets</li>
                <li>- Investment Management - Portfolio Management</li>
                <li>- Management Company - Establishing and managing fund</li>
                <li>- Financial Consultation and Financial Analysis</li>
                <li>- Financial Advisor - IPO Manager</li>
              </ol>
              </p>
            </div>
          </div>
          <br />
        </section>
      </div>
    </div>
  );
};

export default LicenseAndRegulations;
