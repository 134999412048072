import React from "react";
import mt4video from "../../assets/images/pages/mt4.mp4";
import mt4img from "../../assets/images/mt4img.png";


const Video = () => {
    return (
        <>
        
<video  id="video" class="fx-lazy loaded" style={{"height":"300px"}} autoPlay loop muted>
      <source src={mt4video} type="video/mp4"/>
  Your browser does not support the video tag.
</video>
  <div class="img1"> 
<img  src={mt4img} alt="Noor Capital MT4" style={{"height":"300px"}} />
</div>

<style jsx>

        {`
        
        @media all and (max-width: 600px) {
            #video {
                display: none;
            }
        }

        @media all  and (min-width: 600px) {
            .img1 {display: none;}
            
        }
        @media all  and (min-width: 992px) {
            .img1 {display: none;}
            
        }
        @media all  and (min-width: 1200px) {
            .img1 {display: none;}
        }
        `}

      </style>

            
</>
    );
};

export default Video;
