import React from "react";
import "./mainPage.css";
import globalstyle from "../../style.module.css";
import StockPrice1Ar from "./stockPrice1Ar.js";

const StockPricesAr = () => {
  return (
    <>
      <div>
        <section className="fdb-block" style={{ padding: "10px 0" }}>
          <div className={globalstyle.container}>
            <StockPrice1Ar />
          </div>
        </section>
      </div>

      
    </>
  );
};

export default StockPricesAr;
