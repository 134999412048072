import React from "react";
import { Table } from "antd";

const columns3 = [

  {
    title: "Instrument",
    dataIndex: "instrument",
    key: "instrument",
  },

  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    responsive: ["md"],
  },

  {
    title: "Trading Hours (NYT)",
    dataIndex: "trading_hours",
    key: "trading_hours",
    responsive: ["md"],
  },

  {
    title: "Daily Halt",
    dataIndex: "daily_halt",
    key: "daily_halt",
    responsive: ["md"],
  },

  {
    title: "Contract Size",
    dataIndex: "contract_size",
    key: "contract_size",
    responsive: ["md"],
  },

  {
    title: "Pending Orders Distance",
    dataIndex: "pending_orders_distance",
    key: "pending_orders_distance",
  },

  {
    title: "Minimum Trade Size",
    dataIndex: "minimum",
    key: "minimum",
    responsive: ["md"],
  },
  
  {
    title: "Maximum Trade Size",
    dataIndex: "maximum",
    key: "maximum",
    responsive: ["md"],
  },

  {
    title: "Leverage",
    dataIndex: "leverage",
    key: "leverage",
    
  },

];


// Table Content ///////////////////////////////////////////////

const data3 = [
  {
    key: "1",

    instrument: "US30",

    description: "DJ-US 30 INDEX CFD",

    trading_hours: "Sunday 18:00 - Friday 16:00",

    daily_halt: "16:15 - 16:30 / 17:00 - 18:00",

    contract_size: "5",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },

  {
    key: "2",

    instrument: "US100",

    description: "NSDQ-US 100 INDEX CFD",

    trading_hours: "Sunday 18:00 - Friday 16:00",

    daily_halt: "16:15 - 16:30 / 17:00 - 18:00",

    contract_size: "20",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },

  {
    key: "3",

    instrument: "US500",

    description: "S&P-US 500 INDEX CFD",

    trading_hours: "Sunday 18:00 - Friday 16:00",

    daily_halt: "16:15 - 16:30 / 17:00 - 18:00",

    contract_size: "50",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },

  {
    key: "4",

    instrument: "JPN225",

    description: "JAPAN 225 INDEX CFD",

    trading_hours: "Sunday 18:00 - Friday 16:00",

    daily_halt: "16:15 - 16:30 / 17:00 - 18:00",

    contract_size: "5",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },

  {
    key: "5",

    instrument: "UK100",

    description: "FTSE-UK 100 INDEX CFD",

    trading_hours: "Monday 03:00 - Friday 16:00",

    daily_halt: "16:15 - 16:30 / 17:00 - 18:00",

    contract_size: "10",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },

  {
    key: "6",

    instrument: "GER30",

    description: "DAX-GERMANY 30 INDEX CFD",

    trading_hours: "Monday 02:00 - Friday 16:00",

    daily_halt: "16:15 - 16:30 / 17:00 - 18:00",

    contract_size: "15",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },

  {
    key: "7",

    instrument: "EUR50",

    description: "EURO 50 INDEX CFD",

    trading_hours: "Monday 02:00 - Friday 16:00",

    daily_halt: "16:15 - 16:30 / 17:00 - 18:00",

    contract_size: "20",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },

  {
    key: "8",

    instrument: "FRA40",

    description: "FRANCE 40 INDEX CFD ",

    trading_hours: "Monday 02:00 - Friday 16:00",

    daily_halt: "16:15 - 16:30 / 17:00 - 18:00",

    contract_size: "15",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },

  {
    key: "9",

    instrument: "SPA35",

    description: "SPAIN 35 INDEX CFD",

    trading_hours: "Monday 02:00 - Friday 16:00",

    daily_halt: "Monday 03:00 - Friday 14:00",

    contract_size: "5",

    pending_orders_distance: 0,

    minimum: 0.01,

    maximum: 20,

    leverage: "1:100",
  },
  
];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const Instruments_Indexes_Table = () => {
  return (
    <div>
      <Table 
      columns={columns3} 
      dataSource={data3} 
      onChange={onChange}
      pagination={{ position: 'topLeft' }}
      />

      <style jsx>{`
        .ant-table-thead > tr > th {
          font-size: 15px;
        }
        .ant-table-thead > tr > td {
        }
      `}</style>
    </div>
  );
};

export default Instruments_Indexes_Table;
