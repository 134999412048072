import React from "react";
import { Row, Col, Typography, Divider, List } from "antd";
import { Link } from "react-router-dom";
import globalstyle from "../../style.module.css";
import PageseMetaTags from "../metaTags";
import fundsimg from "../../assets/images/pages/services/funds-administration.jpg";
import img1 from "../../assets/images/bgservicesbanner2Ar.jpg";
import BgBeforeAr from "../breadcrumbs/bgBeforeAr";
import BreadcrumbMenuAr from "../breadcrumbs/breadcrumbsAr";

const style = { padding: "50px 0", alignSelf: "justify" };
const notes = [
  "تركز إدارة صناديق الاستثمار مع نور كابيتال على حماية مصالح مستثمرينا، مما يجعل العملية سلسة حقًا مع سهولة التحقق المستقل وتقييم أداء الصندوق.",
  "توفير مديري الصناديق المعتمدين لمساعدتك وتزويدك بالدعم الكامل.",
  "نحن نقدم خدمات إدارة صناديق الاستثمار بشكل محترف في دولة الإمارات العربية المتحدة بالتعاون مع شركة ألبير سوليوشنز.",
  "توفر نور كابيتال حلولاً مصممة خصيصًا للأفراد والشركات التي تديرها العائلات والشركات والهيئات المملوكة للحكومة.",
];
const { Title } = Typography;

const FundsAdministrationAr = () => {
  return (
    <div>
      <PageseMetaTags
        title="Funds Administration Service in Dubai - Noor Capital"
        description="Your global partner for comprehensive fund administration services that enhance international operations."
      />

      <BgBeforeAr
        pageHeadingTitle="اكتشف إمكانيات لا نهاية لها مع خدمة إدارة صناديق الاستثمار مع نور كابيتال"
        pageHeadingDescription=""
        pageHeadingbg={img1}
      />

      <BreadcrumbMenuAr
        pageMainCategoryName="الرئيسية"
        pageMainCategoryPath="/"
        pageSubCategoryName="الخدمات"
        pageSubCategoryPath="#"
        pageName="إدارة صناديق الإستثمار"
      />

      <div className={globalstyle.container}>
        <br />
        <br />
        <Row>
          <Title style={{ color: "#1b75bc" }} level={3}>
            إدارة صناديق الإستثمار
          </Title>
        </Row>

        <Row class="mx-auto" span={24} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "justify",
              padding: "0px 0px 0px 30px",
              fontSize: "16.5px",
            }}
          >
            <p className="parjustify">
              توفر لك نور كابيتال حلاً قويًا وسلسًا لإدارة صناديق الاستثمار،
              خاصةً لشركة إدارة الأصول وصناديق الاستثمار. نحن نقدم حلاً جاهزًا
              لحساب صافي قيمة الأصول (NAV)، وتوفير حل لمشاكل أصحاب الأسهم،
              ومشاكل التسجيل، والمزيد من خلال أنشطة محاسبة التمويل ووكيل السجل
              والتحويل.
            </p>

            <p className="parjustify">
              تقدم نور كابيتال خدمات إدارة صناديق الإستثمار بالتعاون مع AL-PER
              Solutions. <br/> AL-PER Solutions هي مجموعة من المهنيين ذوي المهارات
              العالية وذوي السمعة الطيبة من مختلف قطاعات الصناعة الذين اجتمعوا
              معًا لبناء تعاون عام في مجال خدمات إدارة الصندوق
            </p>

            <p className="parjustify">
              نحن ملتزمون ليس فقط بتقديم خدمة عالية الجودة ولكن أيضًا استخدام
              معرفتنا وخبرتنا في الصناعة لإضافة قيمة إلى مدير الاستثمار. يتحد
              نظامنا المتكامل تمامًا مع التقارير المستندة إلى الويب ، لتوفير
              المزايا التنافسية والامتثال وسياسات مكافحة غسيل الأموال.
            </p>

            <p className="parjustify">
              <b>
                اقرأ المزيد عن   AL-PER Solutions 
                <a href="http://www.alpersolutions.com/" target="_blank"> بالظغط هنا  </a>
              </b>
            </p>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img
              src={fundsimg}
              class="img-fluid rounded mx-auto d-block bgim"
              alt="Shares CFDs"
            />
          </Col>
        </Row>

        <br></br>

        <Divider orientation="left">
          <Title style={{ color: "#1b75bc" }} level={3}>
            لماذا تختار خدمة إدارة صناديق الاستثمار مع نور كابيتال
          </Title>
        </Divider>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              size="small"
              className="text-right"
              bordered
              dataSource={notes}
              renderItem={(item) => (
                <List.Item>
                  <i
                    class="fas fa-angle-double-left"
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "#1b75bc",
                      textAlign: "right",
                    }}
                  ></i>
                  {item}
                </List.Item>
              )}
            />
            <br />
          </Col>
        </Row>

        <br />

        <style jsx>
          {`
            .ant-list-bordered.ant-list-sm .ant-list-item:nth-child(odd) {
              background-color: #f1f1f1;
            }
          `}
        </style>
      </div>

      <section className="fdb-block" style={{ backgroundColor: "#f2f4f7" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center">
              <Title style={{ color: "#11131b" }} level={3}>
                طًور استثماراتك مع خدمات إدارة صناديق الاستثمار مع نور كابيتال
              </Title>

              <p className="mt-5 mt-sm-4">
                <Link
                  className="btn btn-outline-primary"
                  to="/ar/about/contact-us"
                >
                  اتصل بنا
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block">
        <div className="row">
          <div className={globalstyle.container}>
            <div className="col" style={{ padding: "15px" }}>
              <h1
                className="display-5 text-center"
                style={{ color: "#11131b" }}
              >
                خدمات أخرى
              </h1>
            </div>

            <div className="row text-right">
              <div className="col-12 col-md-8 m-auto col-lg-4">
                <div className="fdb-box fdb-touch">
                  <h4>الإستشارات المالية العالمية</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    مع تركيزنا على سوق الإمارات العربية المتحدة والمملكة العربية
                    السعودية، نجحنا في توسيع أجنحتنا الاستشارية لتشمل مناطق أخرى
                    في الشرق الأوسط وشمال
                  </p>
                  <p>
                    <Link to="/ar/services/global-financial-advisory">
                      قراءة المزيد
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>إدارة الأصول</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    نحن نقدم حلًا سلسًا لإدارة الأصول للمؤسسات والأفراد ذوي
                    الثروة المالية الكبيرة والمستثمرين الأفراد من خلال قنوات
                    مباشرة وأطراف أخرى
                  </p>
                  <p>
                    <Link to="/ar/services/asset-management">قراءة المزيد</Link>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-8 m-auto col-lg-4 pt-5 pt-lg-0">
                <div className="fdb-box fdb-touch">
                  <h4>الاستثمار المباشر</h4>
                  <p style={{ color: "#8892a0", fontWeight: "400" }}>
                    نقدم مجموعة واسعة من الفرص المالية، بما في ذلك الاستثمارات
                    وإدارة الأصول والاستشارات المالية وإدارة الصناديق والوساطة
                    في الأسواق العالمية
                  </p>
                  <p>
                    <Link to="/ar/services/investment">قراءة المزيد</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FundsAdministrationAr;
